import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Button from '../../Button.jsx';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

import handlerPopup from '../../../functions/app/handlerPopup';
import Animate from '../../Animate.jsx';
import CodeBox from '../../CodeBox.jsx';
import Loader from '../../Loader.jsx';
import ListScroll from '../../ListScroll.jsx';
import getEndText from '../../../functions/getEndText';
import Table from '../manual/Table.jsx';
import getJoinContracts from '../../../requests/getJoinContracts';

class SignContract extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'main',
            counterScroll: this.stepCounter,
        };

        this.renderStep = this.renderStep.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handlerScroll = this.handlerScroll.bind(this);
        this.close = this.close.bind(this);
        this.send = this.send.bind(this);
        this.sendCode = this.sendCode.bind(this);
        this.saveCode = this.saveCode.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 25;

    stepsOrder = ['main', 'final'];

    getSteps() {
        const { currentStep } = this.state;

        return [{ key: currentStep }];
    }

    renderItem({ item, key }) {
        const { signContractPopup } = this.props;
        const { renderStatus } = signContractPopup;
        const { _id, executorName, name } = item;

        return (
            <div className="paysPopup__item _row" key={_id}>
                <div className="paysPopup__itemCol _col _number">
                    <div className="paysPopup__itemText">{key + 1}</div>
                </div>
                <div className="paysPopup__itemCol _col _name">
                    <div className="paysPopup__itemText">{name || '–'}</div>
                </div>
                <div className="paysPopup__itemCol _col _status">
                    <div className="paysPopup__itemText">
                        {renderStatus({ item, isStatic: true })}
                    </div>
                </div>
                <div className="paysPopup__itemCol _col _executorName">
                    <div className="paysPopup__itemText">{executorName}</div>
                </div>
            </div>
        );
    }

    handlerScroll({ target }) {
        const { scrollTop } = target;
        const maxScroll = target.scrollHeight - target.offsetHeight;

        if (maxScroll - scrollTop < 5 && !this.state.isScrollFull) {
            this.setState({ isScrollFull: true });
        }

        if (maxScroll - scrollTop >= 5 && this.state.isScrollFull) {
            this.setState({ isScrollFull: false });
        }
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    steps = {
        main: {
            render() {
                const {
                    isScrollFull,
                    items = [],
                    isInit,
                    isDisabledScroll,
                    isLimit,
                    isShowLoaderList,
                } = this.state;
                const type = this.getType();

                return (
                    <>
                        <div className="paysPopup__description">
                            <>
                                Вы хотите подписать выбранные{' '}
                                {type === 'userProxy' ? 'доверенности' : 'договоры'} —<br />
                                подтвердите операцию смс-кодом
                            </>
                        </div>
                        <div className={`paysPopup__itemsBox ${isScrollFull ? '_full' : ''}`}>
                            <div className="paysPopup__itemsWrapper" onScroll={this.handlerScroll}>
                                <div className="paysPopup__items">
                                    {isInit && (
                                        <ListScroll
                                            getParent={() =>
                                                this.parent.current?.querySelector(
                                                    '.paysPopup__itemsWrapper',
                                                )
                                            }
                                            callback={this.getMoreItems}
                                            startCounter={this.stepCounter}
                                            stepCounter={this.stepCounter}
                                            maxCounter={Infinity}
                                            lengthCurrent={items.length}
                                            isDisabledScroll={!isInit || isDisabledScroll}
                                            isLimit={isLimit}
                                            handlerLoaderList={this.handlerLoaderList}
                                        >
                                            {items.map((item, key) =>
                                                this.renderItem({ item, key }),
                                            )}
                                        </ListScroll>
                                    )}
                                </div>
                            </div>
                            <Animate
                                className="paysPopup__listLoader _loaderScroll _white"
                                isShow={!!isShowLoaderList}
                            >
                                <div className="paysPopup__listLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                        </div>
                    </>
                );
            },
            renderButton() {
                const { loadingKey, counter } = this.state;

                return (
                    <>
                        <Button
                            icon={{ name: 'arrow-next', type: 'end' }}
                            onClick={this.sendCode}
                            isDisabled={counter === 0}
                            showLoader={loadingKey === 'code'}
                        >
                            Смс-код
                        </Button>
                    </>
                );
            },
        },
        final: {
            render() {
                const { loadingKey, updateCodeKey } = this.state;

                return (
                    <>
                        <div className="paysPopup__description">
                            Мы отправили смс-код на ваш номер
                            <br />
                            телефона — введите код в поле ниже
                        </div>
                        <div className="paysPopup__code _row">
                            <div className="paysPopup__codeBox">
                                <CodeBox
                                    className="_pays"
                                    inputs={[1, 2, 3, 4, 5, 6]}
                                    callback={this.saveCode}
                                    inMoment={true}
                                    updateKey={updateCodeKey}
                                />
                            </div>
                            <div className="paysPopup__codeInfo _col">
                                <div className="paysPopup__codeInfoTitle">Код из смс</div>
                                <div
                                    className="paysPopup__codeInfoBtn _click"
                                    onClick={() => {
                                        this.sendCode(true);
                                    }}
                                >
                                    Отправить повторно
                                    <Animate
                                        className="paysPopup__codeInfoBtnLoader _loader"
                                        isShow={loadingKey === 'codeAgain'}
                                    >
                                        <div className="paysPopup__codeInfoBtnLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
            renderButton() {
                const { loadingKey, authCode, counter } = this.state;
                const type = this.getType();

                return (
                    <>
                        <Button
                            icon={{ name: 'arrow-next', type: 'end' }}
                            onClick={this.send}
                            isDisabled={counter === 0 || !authCode || authCode.length !== 6}
                            showLoader={loadingKey === 'pay'}
                        >
                            <>
                                Подписать {counter}{' '}
                                {getEndText(
                                    counter,
                                    type
                                        ? ['доверенность', 'доверенности', 'доверенностей']
                                        : ['договор', 'договора', 'договоров'],
                                )}
                            </>
                        </Button>
                    </>
                );
            },
        },
    };

    renderStep({ prop: key }) {
        const step = this.steps[key];

        return <div className="paysPopup__step">{step?.render?.call(this)}</div>;
    }

    renderButton({ prop: key }) {
        const step = this.steps[key];

        return (
            <div className={`paysPopup__footButton _${key}`}>{step?.renderButton?.call(this)}</div>
        );
    }

    sendCode(isAgain) {
        const { items = [], loadingKey } = this.state;
        const { signContractPopup } = this.props;
        const { items: startItems } = signContractPopup;
        let ids = items.map((item) => item._id);

        if (startItems[0] === 'all') {
            ids = 'allCompleted';
        }

        const type = this.getType();

        if (!loadingKey) {
            this.handlerLoading(isAgain === true ? 'codeAgain' : 'code').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/join-contract`,
                        {
                            itemsIds: ids,
                            isForce: true,
                            type,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                this.setState({ currentStep: 'final' });

                                if (isAgain === true) {
                                    setNotification({ notification: 'success-send-code' });
                                }
                            } else {
                                const { message } = data;

                                if (message === 'Phone is incorrect') {
                                    setNotification({ notification: 'error-corporation-phone' });
                                } else if (message === 'Corporation not verify') {
                                    handlerPopup({
                                        name: 'verificationPopup',
                                        callback: this.close,
                                    });
                                }
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        }
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    saveCode(authCode) {
        return new Promise((resolve) => {
            this.setState({ authCode }, () => {
                resolve(true);
            });
        });
    }

    send() {
        const { items = [], authCode } = this.state;
        const { signContractPopup } = this.props;
        const { items: startItems, successCallback } = signContractPopup;
        let ids = items.map((item) => item._id);

        if (startItems[0] === 'all') {
            ids = 'allCompleted';
        }

        const type = this.getType();

        this.handlerLoading('pay').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/join-contract`,
                    {
                        itemsIds: ids,
                        code: authCode,
                        isForce: true,
                        type,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            this.close();

                            if (typeof successCallback === 'function') {
                                successCallback();
                            }
                        } else {
                            const { message } = data;

                            if (message === 'Code is incorrect') {
                                setNotification({ notification: 'error-send-code' });

                                this.setState({
                                    updateCodeKey: new Date().getTime(),
                                    authCode: null,
                                });
                            }
                        }

                        this.handlerLoading(null);
                    },
                    () => null,
                );
        });
    }

    close() {
        handlerPopup({ name: 'signContractPopup', isShow: false });
    }

    getQueryForRequest() {
        const { counterScroll } = this.state;
        const stepKey = counterScroll / this.stepCounter;
        const query = super.getQueryForRequest();

        const { signContractPopup } = this.props;
        const { executorId } = signContractPopup;
        const type = this.getType();
        const items = JSON.parse(JSON.stringify(signContractPopup.items || []));

        query.params.push({ key: 'notCompleted', value: true });

        if (items[0] !== 'all') {
            const listIds = items.filter(
                (item, key) =>
                    key >= (stepKey - 1) * this.stepCounter && key < stepKey * this.stepCounter,
            );

            listIds.forEach((id) => {
                query.params.push({ key: 'listIds', value: id });
            });
        }

        if (type) {
            query.params.push({ key: 'type', value: type });
        }

        if (executorId) {
            query.params.push({ key: 'executorId', value: executorId });
        }

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getJoinContracts(query).then(
                ({ joinContracts, isLimit, counter }) => {
                    this.setItems(joinContracts, false, isLimit, counter).then(() => {
                        setTimeout(() => {
                            this.setState({ isInit: true, isShowLoaderList: false }, resolve);
                        }, 300);
                    });
                },
                () => null,
            );
        });
    }

    getType() {
        const { signContractPopup } = this.props;
        const { type } = signContractPopup;

        return type;
    }

    init() {
        setTimeout(() => {
            this.getItems(true).then(() => {
                const wrapper = this.parent.current.querySelector('.paysPopup__itemsWrapper');

                if (wrapper) {
                    wrapper.dispatchEvent(new CustomEvent('scroll'));
                }
            });
        }, 1000);
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const { items = [], updateKey, currentStep, counter = 0, isInit } = this.state;
        const type = this.getType();

        return (
            <div
                ref={this.parent}
                className={`paysPopup _col _signContract ${isInit ? '_init' : ''}`}
            >
                <div className="paysPopup__inner _col">
                    <Animate className="paysPopup__loader _loader" isShow={!isInit}>
                        <div className="paysPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="paysPopup__close _click" onClick={this.close}>
                        <Icon name="close-circle" />
                    </div>
                    <div className="paysPopup__content">
                        <div className="paysPopup__head">
                            <div className="paysPopup__title _row">
                                <>
                                    Подписание{' '}
                                    {type === 'userProxy' ? 'доверенностей' : 'договоров'}:{' '}
                                    <div className="paysPopup__titleItem _child _parent">
                                        <>{counter}</>
                                    </div>
                                </>
                            </div>
                        </div>
                        <ListAbsoluteMain
                            className="paysPopup__steps"
                            items={this.getSteps()}
                            renderItem={this.renderStep}
                            classNameItem="paysPopup__step"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                            keyRender={`${updateKey}${items.length}${isInit}`}
                            isNotNullParentSize={true}
                            currentItemKey={currentStep}
                            allItems={this.stepsOrder}
                            classNames={['_signContract']}
                        />
                        <div className="paysPopup__foot _row">
                            <ListAbsoluteMain
                                className="paysPopup__footButtons"
                                items={this.getSteps()}
                                renderItem={this.renderButton}
                                classNameItem="paysPopup__footButton"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['width', 'height']}
                                isNotParamsItem={true}
                                keyRender={updateKey}
                                isNotNullParentSize={true}
                                currentItemKey={currentStep}
                                allItems={this.stepsOrder}
                            />
                            <div className="paysPopup__footPaginations _row">
                                {this.stepsOrder.map((key) => (
                                    <div
                                        className={`paysPopup__footPaginationsItem ${
                                            currentStep === key ? '_current' : ''
                                        }`}
                                        key={key}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        signContractPopup: state.signContractPopup,
    };
}

export default connect(mapStateToProps)(SignContract);

SignContract.propTypes = {
    signContractPopup: PropTypes.object,
};
