import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getRoles from '../../../requests/getRoles';

import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import Field from '../manual/card/Field.jsx';

import getHeaders from '../../../functions/getHeaders';
import handlerPopup from '../../../functions/app/handlerPopup';
import setNotification from '../../../functions/setNotification';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import handlerWindow from '../../../functions/handlerWindow';
import Windows from '../../Windows.jsx';
import WindowPrompt from '../../WindowPrompt.jsx';

class UserPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerField = this.handlerField.bind(this);
        this.inviteUser = this.inviteUser.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.renderRolePrompt = this.renderRolePrompt.bind(this);

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['name', 'email', 'role'];

    fields = {
        name: {
            keyName: 'fullname',
        },
        email: {
            keyName: 'email',
        },
        role: {
            keyName: 'role',
        },
    };

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const user = JSON.parse(JSON.stringify(newState.user));

                        user[name] = value;

                        newState.user = user;

                        return newState;
                    },
                    () => {
                        this.handlerErrors({ action: 'remove', errors: [name] });

                        resolve();
                    },
                );
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerErrors({ errors, action }) {
        this.setState((state) => {
            const newState = { ...state };
            const stateErrors = [...(newState.errors || [])];

            errors.forEach((error) => {
                const errorIndex = stateErrors.indexOf(error);

                if (action && errorIndex !== -1) {
                    stateErrors.splice(errorIndex, 1);
                }

                if (!action && errorIndex === -1) {
                    stateErrors.push(error);
                }
            });

            newState.errors = stateErrors;

            return newState;
        });
    }

    inviteUser() {
        return this.save(true);
    }

    save(isInvite, isForce) {
        const { user, isNew, inviteUserId } = this.state;
        const { userPopup } = this.props;
        const errors = [];
        const fields = {};
        let props = this.fieldsOrder;

        if (isInvite === true) {
            props = ['role'];
        }

        props.forEach((name) => {
            if (user[name]) {
                if (name === 'name') {
                    const [secondName, firstName, ...thirdNames] = user.name.split(' ');
                    const thirdName = thirdNames.join(' ');

                    fields.firstName = firstName;
                    fields.secondName = secondName;
                    fields.thirdName = thirdName;
                } else {
                    fields[name] = user[name];
                }
            } else {
                errors.push(name);
            }
        });

        return new Promise((resolve, reject) => {
            if (errors.length) {
                this.handlerErrors({ errors });

                setNotification({ notification: 'required-fields-not-complete' });
            } else {
                const formData = {
                    id: user._id,
                    fields,
                };

                if (isInvite === true) {
                    formData.inviteUserId = inviteUserId;
                }

                if (isForce === true) {
                    formData.isForce = isForce;
                }

                this.handlerLoading('save').then(() => {
                    axios[isNew ? 'post' : 'patch'](`${process.env.REACT_APP_API}/user`, formData, {
                        headers: getHeaders(),
                    }).then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { otherUser } = data;

                                if (otherUser) {
                                    this.setState({ inviteUserId: otherUser._id });

                                    setNotification({
                                        notification: 'user-already-create',
                                        description: `${otherUser.name}, <span class="_noWrap">${otherUser.email}</span>`,
                                        callback: this.inviteUser,
                                    });
                                } else {
                                    handlerPopup({
                                        name: 'userPopup',
                                        isShow: false,
                                        user: null,
                                    });

                                    setNotification({ notification: 'success-change-info' });

                                    if (typeof userPopup.callback === 'function') {
                                        userPopup.callback();
                                    }
                                }

                                resolve();
                            } else {
                                const { message } = data;

                                if (message === 'User already in corporation') {
                                    setNotification({
                                        notification: 'user-already-in-corporation',
                                    });

                                    this.handlerErrors({ errors: ['email'] });
                                } else if (message === 'User cannot be invited') {
                                    setNotification({ notification: 'user-cannot-be-invited' });

                                    this.handlerErrors({ errors: ['email'] });
                                } else if (message === 'User lose proxy') {
                                    const parent =
                                        this.parent.current.querySelector('.payPopup__inner');
                                    const target =
                                        this.parent.current.querySelector('.payPopup__footButton');

                                    handlerWindow({
                                        parent,
                                        parentResize: parent,
                                        target,
                                        action: 'show',
                                        name: 'roleUserLoseProxy',
                                        className: '_prompt _roleUserLoseProxy',
                                        uniqKey: 'roleUserLoseProxy',
                                        centers: {
                                            left: 0,
                                            top: 0,
                                        },
                                    });
                                }

                                reject();
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
                });
            }
        });
    }

    getValue({ name }) {
        const { user } = this.state;

        if (!user) {
            return null;
        }

        return user[name];
    }

    getRoles() {
        const { user } = this.props;

        getRoles({
            params: [
                { key: 'forUser', value: 'true' },
                ...(getCurrentCorporation({ user }).role !== 'holder'
                    ? [{ key: 'withoutAdmin', value: true }]
                    : []),
            ],
        }).then(({ mainRoles = [], roles }) => {
            const resultRoles = [
                ...mainRoles.filter((role) => role._id !== 'holder'),
                ...roles,
            ].map((role) => ({ key: role._id, content: role.name }));

            this.setState({ roles: resultRoles });
        });
    }

    setUser() {
        const { userPopup } = this.props;
        const { user } = userPopup;
        const resultUser = {};

        if (user) {
            resultUser._id = user._id;
            resultUser.name = user.fullName;
            resultUser.email = user.email;
            resultUser.role = user.role;
        }

        this.setState({ user: resultUser || {}, isNew: !user });
    }

    renderRolePrompt() {
        const callback = ({ hide, handlerLoader }) => {
            this.save(undefined, true).then(
                () => {
                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return (
            <WindowPrompt className="_bottomLeft" callback={callback} name="roleUserLoseProxy" />
        );
    }

    handlerMissClick(e) {
        const { dateKey } = this.state;

        if (dateKey) {
            const calendar = this.parent.current.querySelector('.payPopup__calendarInner');

            if (calendar && e.target !== calendar && !calendar.contains(e.target)) {
                this.handlerDateState({ dateKey: null });
            }
        }
    }

    componentDidMount() {
        this.setUser();
        this.getRoles();

        document.addEventListener('click', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { loadingKey, user, errors = [], roles = [], isNew } = this.state;

        return (
            <div ref={this.parent} className="payPopup _col">
                <div className="payPopup__inner _col">
                    <Windows name="roleUserLoseProxy" renderContent={this.renderRolePrompt} />

                    <div className={`payPopup__innerBox _col _ready`}>
                        <div className="payPopup__head">
                            <div className="payPopup__headTitle">
                                {isNew ? 'Приглашение' : 'Редактирование'} сотрудника:{' '}
                            </div>
                            <div className="payPopup__headDescription">
                                {isNew ? 'Новый сотрудник' : user?.name}
                            </div>
                            <i
                                className="payPopup__headClose _click"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'userPopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                <Icon name="close-circle" />
                            </i>
                        </div>
                        <div className="payPopup__content _visible">
                            <div className="payPopup__form">
                                {this.fieldsOrder.map((name) => {
                                    const value = this.getValue({ name });
                                    const isDisabled = !isNew && name !== 'role';
                                    const { support } = this.fields[name];
                                    let items = [];

                                    if (name === 'role') {
                                        items = roles;
                                    }

                                    const isError = errors.indexOf(name) !== -1;

                                    return (
                                        <div className="payPopup__formFields" key={name}>
                                            <div
                                                className={`payPopup__formField _col ${
                                                    isError ? '_error' : ''
                                                }`}
                                                key={name}
                                            >
                                                <Field
                                                    className="_heightSize"
                                                    type="userPopup"
                                                    value={value}
                                                    name={name}
                                                    keyName={this.fields[name].keyName}
                                                    isEditmode={false}
                                                    isError={isError}
                                                    group="userPopup"
                                                    isDisabled={isDisabled}
                                                    handler={this.handlerField}
                                                    support={support}
                                                    items={items}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="payPopup__foot _row">
                            <div className="payPopup__footButton">
                                <Button
                                    className="_medium2Size"
                                    onClick={() => {
                                        this.save().then(
                                            () => null,
                                            () => null,
                                        );
                                    }}
                                    showLoader={loadingKey === 'save'}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userPopup: state.userPopup,
        user: state.user,
    };
}

export default connect(mapStateToProps)(UserPopup);

UserPopup.propTypes = {
    userPopup: PropTypes.object,
    user: PropTypes.object,
};
