import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsolute from '../../../../ListAbsolute.jsx';
import ActionChange from '../../../../ActionChange.jsx';
import Animate from '../../../../Animate.jsx';

import removeTransition from '../../../../../functions/removeTransition.ts';
import sortForAlphabet from '../../../../../functions/sortForAlphabet';
import scrollToPosition from '../../../../../functions/scrollToPosition';
import handlerWindow from '../../../../../functions/handlerWindow';

class HeadInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderTab = this.renderTab.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.renderListItem = this.renderListItem.bind(this);

        this.setWidthTabs = this.setWidthTabs.bind(this);
        this.setWidthActions = this.setWidthActions.bind(this);
        this.setParamsActionList = this.setParamsActionList.bind(this);

        this.getSortListItems = this.getSortListItems.bind(this);
        this.sortListItems = this.sortListItems.bind(this);

        this.getSortActions = this.getSortActions.bind(this);
        this.sortActions = this.sortActions.bind(this);

        this.handlerMissClickList = this.handlerMissClickList.bind(this);

        this.parent = React.createRef();
    }

    getActions() {
        const { type, isEdit, name } = this.props;

        if (!isEdit) {
            return [];
        }

        const listArr =
            type === 'car' &&
            +name > 0 &&
            (name === (+name).toFixed(1) || name === (+name).toFixed(2))
                ? [{ key: 'list', content: '+ Тип кузова' }]
                : [];

        return [...listArr, { key: 'delete', content: 'Удалить' }];
    }

    setWidthTabs({ width }) {
        const { serverData } = this.props;
        const { tariffs } = serverData;

        if (width && this.state.widthTabs !== width) {
            this.setState({ widthTabs: width }, () => {
                if (!this.isInit && tariffs) {
                    this.isInit = true;
                    removeTransition({
                        item: '.manualTariffsConfigurationHeadInfo__tab',
                        isCurrent: true,
                    });
                }
            });
        }
    }

    setWidthActions({ width }) {
        const { serverData } = this.props;
        const { tariffs } = serverData;

        if (this.state.widthActions !== width) {
            this.setState({ widthActions: width }, () => {
                if (!this.isInitActions && tariffs) {
                    this.isInitActions = true;
                    removeTransition({
                        item: '.manualTariffsConfigurationHeadInfo__action',
                        isCurrent: true,
                    });
                }
            });
        }
    }

    setParamsActionList({ width, height }) {
        const { paramsActionList } = this.state;

        if (
            !paramsActionList ||
            paramsActionList.width !== width ||
            paramsActionList.height !== height
        ) {
            this.setState({ paramsActionList: { width, height } });
        }
    }

    sortActions(a, b) {
        const weightA = a.key === 'list' ? 1 : 0;
        const weightB = b.key === 'list' ? 1 : 0;

        return weightB - weightA;
    }

    getSortActions(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortActions);
    }

    renderTab({ item, prop: key, key: order, isShow, isLast, params }) {
        const { _id, setCurrentItem, idOfCurrentItem } = this.props;

        const { content } = item;

        return (
            <label
                className={`manualTariffsConfigurationHeadInfo__tab ${isShow ? '_show' : ''} ${
                    isLast ? '_last' : ''
                }`}
                key={key}
                data-key={key}
                style={{ transform: `translate(${-params?.offsetRight}px,0px)`, order }}
            >
                <input
                    type="radio"
                    className="manualTariffsConfigurationHeadInfo__tabInput"
                    name={`tariffTab-${_id}`}
                    onChange={() => setCurrentItem(key)}
                    checked={idOfCurrentItem === key}
                />
                <div className="manualTariffsConfigurationHeadInfo__tabInner _row _click">{content}</div>
            </label>
        );
    }

    sortListItems(a, b) {
        const { items } = this.props;
        const weightA = items.find((itemLoop) => itemLoop.key === a.key) ? 1 : 0;
        const weightB = items.find((itemLoop) => itemLoop.key === b.key) ? 1 : 0;

        return weightB - weightA;
    }

    getSortListItems(itemsCome) {
        const items = JSON.parse(JSON.stringify(itemsCome));

        return sortForAlphabet({ arr: items, prop: 'content' }).sort(this.sortListItems);
    }

    getItems() {
        const { type, items = [] } = this.props;

        return type === 'car' ? items : [];
    }

    renderListItem({ item, prop: key, key: order, isShow, isLast, params }) {
        const { items, handlerConfigurationItems } = this.props;
        const { content } = item;
        const isHas = !!items.find((itemLoop) => itemLoop.key === key);

        return (
            <div
                className={`manualTariffsConfigurationHeadInfo__actionListItem _row _${key} ${
                    isShow ? '_show' : ''
                } ${isLast ? '_last' : ''}`}
                style={{ transform: `translate(0,${params?.offsetTop}px)`, order }}
                key={key}
                data-key={key}
            >
                {content}
                <ActionChange
                    className="manualTariffsConfigurationHeadInfo__actionListItemAction"
                    isShow={true}
                    isSmoothShow={true}
                    isHas={isHas}
                    onClick={() => {
                        handlerConfigurationItems({ action: isHas ? 'delete' : 'add', key }).then(
                            () => {
                                if (!isHas) {
                                    scrollToPosition({
                                        position: 'top',
                                        parent: this.parent.current.querySelector(
                                            '.manualTariffsConfigurationHeadInfo__actionListContent',
                                        ),
                                    });
                                }
                            },
                        );
                    }}
                />
            </div>
        );
    }

    handlerStateList(isShowList = !this.state.isShowList) {
        this.setState({ isShowList });
    }

    handlerMissClickList({ target }) {
        const { isShowList } = this.state;
        const list = this.parent.current.querySelector(
            '.manualTariffsConfigurationHeadInfo__actionList',
        );

        if (isShowList && list && list !== target && !list.contains(target)) {
            this.handlerStateList(false);
        }
    }

    renderAction({ item, prop: key, key: order, isShow, isLast, params }) {
        const { paramsActionList, isShowList, isInitList } = this.state;
        const {
            _id,
            name,
            tabs,
            infoCars,
            items,
            idOfCurrentItem,
            handlerConfiguration,
            isNew,
            parent,
            parentScroll,
            deleteConfiguration,
        } = this.props;
        const itemsAll = this.getSortListItems(
            tabs.filter(
                (tab) =>
                    name &&
                    ((+name >= tab.minWeight && +name < tab.maxWeight) || 1) &&
                    (infoCars.length === 0 ||
                        infoCars.find((car) => tab.bodyworks.indexOf(car.bodywork) !== -1)),
            ),
        );

        return (
            <div
                className={`manualTariffsConfigurationHeadInfo__action _${key} ${
                    isShow ? '_show' : ''
                } ${isLast ? '_last' : ''}`}
                key={key}
                data-key={key}
                style={{ transform: `translate(${-params?.offsetRight}px,0px)`, order }}
            >
                <div
                    className="manualTariffsConfigurationHeadInfo__actionInner _row _click"
                    onClick={({ target }) => {
                        if (key === 'list') {
                            this.handlerStateList();
                        }
                        if (key === 'delete') {
                            if (isNew) {
                                handlerConfiguration({ action: 'delete' });
                            } else {
                                handlerWindow({
                                    parent,
                                    parentScroll,
                                    target: target.closest(
                                        '.manualTariffsConfigurationHeadInfo__action',
                                    ),
                                    action: 'show',
                                    className: '_prompt',
                                    name: 'promptDeleteConfig',
                                    uniqKey: _id,
                                    idOfConfig: _id,
                                    deleteConfiguration,
                                    isHideFromScroll: true,
                                    centers: {
                                        left: 0.5,
                                        top: 1,
                                    },
                                });
                            }
                        }
                    }}
                >
                    {item.content}
                </div>
                {key === 'list' && (
                    <Animate
                        className="manualTariffsConfigurationHeadInfo__actionList _windowWithCursor _topCenter"
                        isShow={isShowList}
                        actionInit={() => {
                            this.setState({ isInitList: true });

                            document.addEventListener('click', this.handlerMissClickList);
                        }}
                        actionPrevRemove={() => {
                            this.setState({ isInitList: false });

                            document.removeEventListener('click', this.handlerMissClickList);
                        }}
                    >
                        <div className="manualTariffsConfigurationHeadInfo__actionListContent">
                            <div className="manualTariffsConfigurationHeadInfo__actionListInner">
                                <div
                                    className="manualTariffsConfigurationHeadInfo__actionListItems"
                                    style={{
                                        width: `${paramsActionList?.width}px`,
                                        height: `${paramsActionList?.height}px`,
                                    }}
                                >
                                    <ListAbsolute
                                        parent={(() =>
                                            this.parent.current?.querySelector(
                                                '.manualTariffsConfigurationHeadInfo__actionListItems',
                                            ))()}
                                        items={itemsAll}
                                        renderItem={this.renderListItem}
                                        classNameItem="manualTariffsConfigurationHeadInfo__actionListItem"
                                        prop="key"
                                        setParamsParent={this.setParamsActionList}
                                        paramsParent={{ width: 'auto' }}
                                        sort={this.getSortListItems}
                                        keyRender={`${isInitList}${idOfCurrentItem}${items.length}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animate>
                )}
            </div>
        );
    }

    componentDidMount() {
        this.setState({ isInit: true });
    }

    render() {
        const { widthTabs = 0, isInitTabs, widthActions = 0 } = this.state;
        const { serverData, idOfCurrentItem } = this.props;
        const { tariffs } = serverData;
        const itemsActions = this.getActions();
        const items = this.getItems();

        return (
            <div ref={this.parent} className="manualTariffsConfigurationHeadInfo _row">
                <div
                    className={`manualTariffsConfigurationHeadInfo__tabs ${
                        isInitTabs ? '_show' : ''
                    }`}
                    style={{ width: `${widthTabs}px` }}
                >
                    <ListAbsolute
                        parent={(() =>
                            this.parent.current?.querySelector(
                                '.manualTariffsConfigurationHeadInfo__tabs',
                            ))()}
                        items={items}
                        renderItem={this.renderTab}
                        classNameItem="manualTariffsConfigurationHeadInfo__tab"
                        prop="key"
                        setParamsParent={this.setWidthTabs}
                        keyUpdateItem={`${!!tariffs}${idOfCurrentItem}`}
                        callback={() => {
                            setTimeout(() => {
                                if (tariffs && !this.state.isInitTabs) {
                                    this.setState({ isInitTabs: true });
                                }
                            }, 10);
                        }}
                        sort={(arr) => sortForAlphabet({ arr, prop: 'content' })}
                        keyRender={items.length}
                        id="tariffHeadTabs"
                    />
                </div>
                <div
                    className={`manualTariffsConfigurationHeadInfo__actions ${
                        itemsActions.length === 0 ? '_empty' : ''
                    }`}
                    style={{ width: `${widthActions}px` }}
                >
                    <ListAbsolute
                        parent={(() =>
                            this.parent.current?.querySelector(
                                '.manualTariffsConfigurationHeadInfo__actions',
                            ))()}
                        items={itemsActions}
                        renderItem={this.renderAction}
                        classNameItem="manualTariffsConfigurationHeadInfo__action"
                        prop="key"
                        setParamsParent={this.setWidthActions}
                        sort={this.getSortActions}
                        id="tariffHeadActions"
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(HeadInfo);

HeadInfo.propTypes = {
    serverData: PropTypes.object,
    _id: PropTypes.string,
    type: PropTypes.string,
    tabs: PropTypes.array,
    infoCars: PropTypes.array,
    items: PropTypes.array,
    idOfCurrentItem: PropTypes.string,
    setCurrentItem: PropTypes.func,
    isNew: PropTypes.bool,
    isEdit: PropTypes.bool,
    name: PropTypes.string,
    handlerConfigurationItems: PropTypes.func,
    handlerConfiguration: PropTypes.func,
    parent: PropTypes.object,
    parentScroll: PropTypes.object,
    deleteConfiguration: PropTypes.func,
};
