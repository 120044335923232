import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

import getTags from '../../../../requests/getTags';

class ManualTagMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getInfo = this.getInfo.bind(this);

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { groups } = this.state;
        const { tag } = this.props;

        if (groups && tag) {
            let description = '';
            const group = groups.find((groupLoop) => groupLoop._id === tag.idOfGroup);

            if (tag.name) {
                description = tag.name;
            }

            if (group) {
                if (tag.name) {
                    description += ', ';
                }

                description += group.name;
            }

            return {
                description,
            };
        }

        return {
            description: '–',
        };
    }

    fields = [{ name: 'name' }, { name: 'idOfGroup' }, { name: 'newGroup' }, { name: 'color' }];

    getInfo() {
        getTags({ params: [{ key: 'type', value: 'groups' }] }).then(
            ({ groups }) => {
                getTags({ params: [{ key: 'type', value: 'colors' }] }).then(
                    ({ colors }) => {
                        this.setState({
                            groups,
                            colors: colors.map((color) => ({ key: color, color })),
                        });
                    },
                    () => null,
                );
            },
            () => null,
        );
    }

    checkChangeGroup() {
        const { tag } = this.props;

        if (tag && this.state.idOfGroup !== tag.idOfGroup) {
            this.setState({ idOfGroup: tag.idOfGroup }, this.getInfo);
        }
    }

    componentDidMount() {
        this.getInfo();
    }

    componentDidUpdate() {
        this.checkChangeGroup();
    }

    render() {
        const { groups = [], colors = [] } = this.state;
        const { type, tag, checkEditmode = () => false, errors = [], changeTag } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`manualCard _tagMain`}>
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            const { [name]: value } = tag;
                            let items;

                            if (name === 'idOfGroup') {
                                items = groups.map((group) => ({
                                    key: group._id,
                                    content: group.name,
                                }));
                            }

                            if (name === 'color') {
                                items = colors;
                            }

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="tag"
                                        name={name}
                                        handler={changeTag}
                                        group="tagMain"
                                        isDisabled={!checkEditmode() || type === 'preview'}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                        items={items}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualTagMain);

ManualTagMain.propTypes = {
    tag: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeTag: PropTypes.func,
    type: PropTypes.string,
};
