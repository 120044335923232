import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from 'axios';

import Link from '../../components/Link.jsx';
import Pages from '../../components/Pages.jsx';
import Main from './order-details/Main.jsx';

import getMaxHeightContentWidget from '../../functions/crm/getMaxHeightContentWidget';
import checkCrewComplete from '../../functions/order-details/checkCrewComplete';

import getRealParams from '../../functions/getRealParams.ts';
import removeTransition from '../../functions/removeTransition.ts';
import getHeaders from '../../functions/getHeaders';
import getFormatedDate from '../../functions/getFormatedDate';

import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';

import Crew from './order-details/Crew.jsx';
import Docs from '../order-details/Docs.jsx';
import ServiceCompanies from '../order-details/ServiceCompanies.jsx';

import Windows from '../../components/Windows.jsx';
import StatusesOrder from '../../components/crm/order-details/StatusesOrder.jsx';
import Back from '../../components/crm/widget/Back.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import FeedbackOrder from '../../components/crm/order-details/FeedbackOrder.jsx';
import TariffsList from '../../components/order-details/TariffsList.jsx';
import handlerErrorRequest from '../../functions/handlerErrorRequest';
import getPage from '../../functions/getPage';
import Icon from '../../components/Icon.jsx';
import handlerPopup from '../../functions/handlerPopup';
import UploadTemplateInfo from '../../components/order/UploadTemplateInfo.jsx';
import changePage from '../../functions/changePage';
import WindowPrompt from '../../components/WindowPrompt.jsx';
import cancelOrder from '../../functions/cancelOrder';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import WindowList from '../../components/WindowList.jsx';
import setOrderResponsible from '../../requests/setOrderResponsible';
import handlerWindow from '../../functions/handlerWindow';

import Responsible from '../../components/crm/Responsible.jsx';
import More from '../../components/crm/widget/More.jsx';
import Gps from '../order-details/Gps.jsx';
import Filter from '../../components/Filter.jsx';
import setNotification from '../../functions/setNotification';
import LogsTable from '../../components/crm/LogsTable.jsx';
import setPermissions from '../../functions/crm/setPermissions';
import getPageLink from '../../functions/getPageLink';

const pages = require('../../redux/pages').default.filter(
    (page) => page.parentName === 'order-details-inner' && page.level === 3,
);

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.setHeightPage = this.setHeightPage.bind(this);

        this.renderWindowsList = this.renderWindowsList.bind(this);
        this.renderWindowsStatuses = this.renderWindowsStatuses.bind(this);
        this.renderWindowsTariff = this.renderWindowsTariff.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilterCallback = this.setFilterCallback.bind(this);

        setPermissions.call(this);

        this.group = React.createRef();
        this.parent = React.createRef();
    }

    pages = {
        'order-details-main': {
            render() {
                const {
                    isReady,
                    order,
                    orderSave,
                    points,
                    isInitOfMap,
                    mapInfo,
                    ymaps,
                    map,
                    newOrder,
                    counterSort,
                    infoRoute,
                    handlerRoute,
                    changeOrder,
                    setOrder,
                    handlerServices,
                    checkServices,
                    getCondForChange,
                    cargoList,
                    checkNew,
                    handlerDate,
                    date,
                    times,
                    getOrder,
                    isProccessOptimization,
                    isOptimize,
                    dateOfOrder,
                    checkRights,
                    handlerSmsService,
                } = this.props;

                const props = {
                    isReady,
                    handlerRoute,
                    order,
                    orderSave,
                    points,
                    mapInfo,
                    isInitOfMap,
                    ymaps,
                    map,
                    newOrder,
                    counterSort,
                    changeOrder,
                    setOrder,
                    handlerServices,
                    checkServices,
                    infoRoute,
                    getCondForChange,
                    cargoList,
                    setHeightPage: this.setHeightPage,
                    getParent: () => this.parent.current,
                    parentScroll: (() =>
                        this.parent.current
                            ?.querySelector('.widget__page._order-details-main')
                            ?.querySelector('.widget__pageBox'))(),
                    checkNew,
                    handlerDate,
                    date,
                    times,
                    getOrder,
                    isProccessOptimization,
                    isOptimize,
                    dateOfOrder,
                    checkRights,
                    handlerSmsService,
                };

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Main {...props} />
                        </div>
                    </div>
                );
            },
        },
        'order-details-serviceCompanies': {
            render() {
                const { order, orderSave, points, changeOrder, setOrder, checkRights } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner _notPadding">
                            <ServiceCompanies
                                order={order}
                                orderSave={orderSave}
                                points={points}
                                setHeightPage={this.setHeightPage}
                                changeOrder={changeOrder}
                                setOrder={setOrder}
                                checkRights={checkRights}
                            />
                        </div>
                    </div>
                );
            },
        },
        'order-details-crew': {
            render() {
                const { order, checkRights } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner _notPadding">
                            <Crew
                                order={order}
                                setHeightPage={this.setHeightPage}
                                getParent={() => this.parent.current}
                                checkRights={checkRights}
                                setFilterCallback={this.setFilterCallback}
                            />
                        </div>
                    </div>
                );
            },
        },
        'order-details-docs': {
            render() {
                const {
                    order,
                    orderSave,
                    handlerFilesOfDoc,
                    docsFormData,
                    handlerDocs,
                    clearDocsForm,
                    checkRights,
                } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Docs
                                order={order}
                                orderSave={orderSave}
                                handlerFilesOfDoc={handlerFilesOfDoc}
                                docsFormData={docsFormData}
                                handlerDocs={handlerDocs}
                                clearDocsForm={clearDocsForm}
                                setHeightPage={this.setHeightPage}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector('.widget__page._order-details-docs')
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={checkRights}
                            />
                        </div>
                    </div>
                );
            },
        },
        'order-details-gps': {
            render() {
                const { order } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Gps
                                order={order}
                                setHeightPage={this.setHeightPage}
                                getParent={() => this.parent.current}
                            />
                        </div>
                    </div>
                );
            },
        },
        'order-details-logs': {
            render() {
                const { isShowLoaderList } = this.state;
                const { order } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <LogsTable
                                    modelName="order"
                                    modelId={order._id}
                                    handlerLoaderList={this.handlerLoaderList}
                                    inPage={true}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    headPages = {
        'order-details-main': {
            render() {
                const { order } = this.props;
                const date = order?.dateOfOrder
                    ? getFormatedDate({
                          date: new Date(order.dateOfOrder),
                          type: 'fullText',
                      })
                    : '–';

                return (
                    <div className="widget__headNameItemContent">
                        <AnimateChange
                            prop={date}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                        >
                            {date}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'order-details-serviceCompanies': {
            render() {
                return <div className="widget__headNameItemContent">Служба доставки</div>;
            },
        },
        'order-details-crew': {
            render() {
                return <div className="widget__headNameItemContent">Экипаж</div>;
            },
        },
        'order-details-docs': {
            render() {
                return <div className="widget__headNameItemContent">Документы</div>;
            },
        },
        'order-details-gps': {
            render() {
                return <div className="widget__headNameItemContent">GPS</div>;
            },
        },
        'order-details-logs': {
            render() {
                return <div className="widget__headNameItemContent">Логи</div>;
            },
        },
    };

    getLinksOrder() {
        const { order } = this.props;

        return order?.systemType === 'service'
            ? [{ key: 'serviceCompanies' }, { key: 'logs' }]
            : [{ key: 'crew' }, { key: 'docs' }, { key: 'more' }];
    }

    links = {
        main: {
            key: 'order-details-main',
        },
        serviceCompanies: {
            key: 'order-details-serviceCompanies',
        },
        crew: {
            key: 'order-details-crew',
        },
        docs: {
            key: 'order-details-docs',
        },
        logs: {
            key: 'order-details-logs',
        },
    };

    getHeadLinks(key) {
        const { checkNew } = this.props;

        // { key: 'import-excel' }

        if (key === 'last') {
            const items = [];

            if (
                !checkNew?.() &&
                this.getPermissions({
                    key: 'orders',
                    items: [{ key: 'main', rules: [], actions: ['setResponsibleUser'] }],
                })
            ) {
                items.push({ key: 'responsible' });
            }

            if (
                !checkNew?.() &&
                this.getPermissions({
                    key: 'orders',
                    items: [{ key: 'main', rules: [], actions: ['cancel'] }],
                })
            ) {
                items.push({ key: 'cancel' });
            }

            return items;
        }

        return [{ key: 'main' }].concat(...(checkNew?.() ? [] : this.getLinksOrder()));
    }

    renderLink({ prop: key }) {
        const { user, order, setOrderFromTemplate, getCondForCancel, checkRights } = this.props;

        if (key === 'import-excel') {
            return (
                <div
                    className="widget__headLink _main"
                    onClick={() => {
                        handlerPopup({
                            action: 'show',
                            name: 'popupOrderUploadTemplate',
                            type: order.type,
                            callback: setOrderFromTemplate,
                        });
                    }}
                >
                    <div className="widget__headLinkInner _click">
                        Импорт из Excel
                        <i className="widget__headLinkInfo">
                            <Icon name="info" />
                        </i>
                    </div>
                    <div className="widget__headLinkAlert">
                        <UploadTemplateInfo />
                    </div>
                </div>
            );
        }

        if (key === 'responsible') {
            return (
                <div
                    className="widget__headLink widget__headAction"
                    onClick={(e) => {
                        if (checkRights()) {
                            handlerWindow({
                                parent: this.parent.current,
                                parentResize: this.parent.current,
                                target: e.target.closest('.widget__headAction'),
                                action: 'show',
                                name: 'listResponsibleOrders',
                                uniqKey: order?._id,
                                idOfResponsible: order?.idOfResponsible,
                                isNotReverse: true,
                                className: '_list',
                                centers: {
                                    left: 0.5,
                                    top: 1,
                                },
                            });
                        }
                    }}
                >
                    <Responsible
                        {...(order?.infoResponsible || {})}
                        type="short"
                        className="_white"
                    />
                </div>
            );
        }

        if (key === 'cancel') {
            return (
                <div
                    className={`widget__headLink _delete ${!getCondForCancel() ? '_disabled' : ''}`}
                    onClick={({ target }) => {
                        if (order.isNew) {
                            changePage({ href: getPageLink({ name: 'orders' }) });
                        } else if (user?._id === order?.idOfResponsible) {
                            cancelOrder({
                                parent: this.parent.current,
                                target,
                                id: order?._id,
                                status: order?.status,
                            });
                        }
                    }}
                >
                    <div className="widget__headLinkInner _click">Отменить заказ</div>
                </div>
            );
        }

        if (key === 'more') {
            let subPages = pages.filter(
                (page) => ['order-details-gps', 'order-details-logs'].indexOf(page.name) !== -1,
            );

            if (!checkRights()) {
                subPages = pages.filter((page) => ['order-details-gps'].indexOf(page.name) !== -1);
            }

            return <More pages={subPages} ids={{ 1: order?._id }} />;
        }

        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        let className = '';
        let childForEmpty;

        if (name === 'order-details-crew') {
            className = '_active';

            if (
                checkCrewComplete({
                    crew: order?.crew,
                    crewTemplate: order?.crewTemplate,
                }) ||
                order?.status === 'cancel'
            ) {
                className += ' _complete';
                childForEmpty = 'final';
            }
        }

        return (
            <Link
                pageName={name}
                className={`widget__headLink ${className}`}
                childForEmpty={childForEmpty}
                ids={{ 1: order?._id }}
            >
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    setParamsParent(params = {}) {
        const { width: widthLinks } = params;

        if (widthLinks !== this.state.widthLinks) {
            this.setState({ widthLinks });
        }
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    handlerChoice({ _id, action }) {
        if (action === 'delete' || !this.checkCompleteCrew()) {
            this.setState((state) => {
                const newState = { ...state };
                const executors = JSON.parse(JSON.stringify(newState.executors));

                const indexExecutor = executors.findIndex((executor) => executor._id === _id);

                executors[indexExecutor].isActive = action === 'add';

                newState.executors = executors;

                return newState;
            }, this.getParams);
        }
    }

    setHeightPage(e) {
        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._show .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isClearStyles: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    renderWindowsList({ idOfResponsible, uniqKey: idOfOrder }) {
        return (
            <WindowList
                name="responsible"
                className="_topCenter"
                keyParent="orders"
                keySection="new"
                callback={({ id }) => setOrderResponsible({ id, idOfOrder })}
                idOfCurrent={idOfResponsible}
            />
        );
    }

    renderWindowsContentCancel({ cancelOrder: callbackCancelOrder }) {
        const callback = ({ hide, handlerLoader }) =>
            new Promise((resolve, reject) => {
                callbackCancelOrder().then(
                    () => {
                        hide();
                        resolve();
                    },
                    () => {
                        handlerLoader(false);

                        reject();
                    },
                );
            });

        return <WindowPrompt className="_topCenter" callback={callback} name="cancel" />;
    }

    renderWindowsStatuses({ statusInit }) {
        const callback = ({ status }) =>
            new Promise((resolve, reject) => {
                const { order } = this.props;
                const { _id: id } = order;

                axios
                    .patch(
                        `${process.env.REACT_APP_API}/order`,
                        { id, type: 'actions', action: 'change-status', status },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                resolve();
                            } else {
                                const { message } = data;

                                if (message === 'Date is not valide') {
                                    setNotification({
                                        notification: 'order-date-not-valide',
                                    });
                                }

                                reject();

                                handlerErrorRequest(res);
                            }
                        },
                        () => null,
                    );
            });

        return <StatusesOrder statusInit={statusInit} callback={callback.bind(this)} />;
    }

    renderWindowsFeedback({ feedback: { rate, key: keyName, comment, file } }) {
        return <FeedbackOrder rate={rate} keyName={keyName} comment={comment} file={file} />;
    }

    renderWindowsTariff({ tariff, changeTariff }) {
        const { order } = this.props;
        const { type } = order;

        return (
            <TariffsList
                type={type}
                className="_window _topRight"
                tariff={tariff}
                changeTariff={changeTariff}
            />
        );
    }

    setFilterCallback(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    checkReady() {
        const { isReady } = this.props;

        if (isReady && !this.isReady) {
            this.isReady = true;
            this.setHeightPage();
        }
    }

    componentDidMount() {
        this.setHeightPage();

        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentDidUpdate() {
        this.checkReady();
    }

    componentWillUnmount() {
        document.removeEventListener('changeHeightWindow', this.setHeightPage);
    }

    render() {
        const { order, isReady, checkNew, counterChangePage } = this.props;

        const titleContent = checkNew?.()
            ? order?.systemType === 'service'
                ? 'Новый сторонний заказ'
                : 'Новый заказ LIVECARGO'
            : order?.systemType === 'service'
            ? 'Сторонний заказ №'
            : 'Заказ LIVECARGO №';
        const titleNumber = checkNew?.() ? '' : order?.number || '–';

        const title = (
            <div className="widget__headNameItemContent _fontBold">
                <AnimateChange
                    prop={titleContent}
                    type="_translateMedium"
                    className="widget__headNameItemContentInner _notMargin"
                >
                    {titleContent}
                </AnimateChange>
                <AnimateChange
                    prop={titleNumber}
                    type="_translateMedium"
                    className="widget__headNameItemContentInner _notMargin"
                >
                    {titleNumber}
                </AnimateChange>
            </div>
        );

        return (
            <div
                ref={this.parent}
                className={`widget _fix ${isReady ? '_ready' : ''} _scroll _parent`}
            >
                <Windows name="listResponsibleOrders" renderContent={this.renderWindowsList} />
                <Windows name="promptCancelOrder" renderContent={this.renderWindowsContentCancel} />
                <Windows name="statusesOrder" renderContent={this.renderWindowsStatuses} />
                <Windows name="feedbackOrder" renderContent={this.renderWindowsFeedback} />
                <Windows name="tariffExecutor" renderContent={this.renderWindowsTariff} />
                <Windows name="filter" renderContent={this.renderFilter} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title={title}
                                className={`${counterChangePage > 0 ? '_withBack' : ''}`}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'order-details-inner' &&
                                        page.level === 2
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <div className="widget__headActionsGroups _row">
                                    <ListAbsoluteMain
                                        className="widget__headActionsGroup _dynamic"
                                        items={this.getHeadLinks()}
                                        renderItem={this.renderLink}
                                        classNameItem="widget__headLink"
                                        prop="key"
                                        itemParams={['offsetRight']}
                                    />
                                    <ListAbsoluteMain
                                        className="widget__headActionsGroup _dynamic"
                                        items={this.getHeadLinks('last')}
                                        renderItem={this.renderLink}
                                        classNameItem="widget__headLink"
                                        prop="key"
                                        itemParams={['offsetRight']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <Animate className="widget__loader _loader" isShow={!isReady}>
                            <div className="widget__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="widget__contentBox">
                            {order && (
                                <Pages
                                    classNamePage="widget__page"
                                    getClassName={(pageName) =>
                                        ['order-details-logs', 'order-details-crew'].includes(
                                            pageName,
                                        )
                                            ? '_full'
                                            : ''
                                    }
                                    filter={(page) =>
                                        page.parentName === 'order-details-inner' &&
                                        page.level === 3
                                    }
                                    pages={this.pages}
                                    context={this}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        pagesStore: state.pages,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(OrderDetails);

OrderDetails.propTypes = {
    user: PropTypes.object,
    isReady: PropTypes.bool,
    order: PropTypes.object,
    orderSave: PropTypes.object,
    points: PropTypes.array,
    isInitOfMap: PropTypes.bool,
    mapInfo: PropTypes.object,
    ymaps: PropTypes.object,
    map: PropTypes.object,
    newOrder: PropTypes.object,
    counterSort: PropTypes.number,
    infoRoute: PropTypes.object,
    handlerFilesOfDoc: PropTypes.func,
    docsFormData: PropTypes.object,
    handlerDocs: PropTypes.func,
    handlerRoute: PropTypes.object,
    changeOrder: PropTypes.func,
    setOrder: PropTypes.func,
    handlerServices: PropTypes.func,
    checkServices: PropTypes.func,
    getCondForChange: PropTypes.func,
    clearDocsForm: PropTypes.object,
    cargoList: PropTypes.array,
    isProccessSetRoute: PropTypes.bool,
    pagesStore: PropTypes.object,
    checkNew: PropTypes.func,
    setOrderFromTemplate: PropTypes.func,
    handlerDate: PropTypes.func,
    date: PropTypes.string,
    times: PropTypes.object,
    getOrder: PropTypes.func,
    getCondForCancel: PropTypes.func,
    isProccessOptimization: PropTypes.bool,
    isOptimize: PropTypes.bool,
    counterChangePage: PropTypes.number,
    dateOfOrder: PropTypes.string,
    checkRights: PropTypes.func,
    handlerSmsService: PropTypes.func,
};
