import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../../../../functions/getPage';
import copyInBuffer from '../../../../functions/copyInBuffer';

import Link from '../../../../components/Link.jsx';

import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Icon from '../../../../components/Icon.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);

        setPermissions.call(this);

        this.group = React.createRef();
    }

    orderLinks = [
        { key: 'main' },
        { key: 'docs' },
        { key: 'cards' },
        // { key: 'users' },
        { key: 'logs' },
    ];

    links = {
        main: {
            key: 'manual-organizations-inner-main',
        },
        docs: {
            key: 'manual-organizations-inner-docs',
            jurType: 'juristic',
            type: 'client',
        },
        cards: {
            key: 'manual-organizations-inner-cards',
            jurType: 'physical',
            type: 'executor',
        },
        users: {
            key: 'manual-organizations-inner-users',
            jurType: 'juristic',
            type: 'client',
        },
        logs: {
            key: 'manual-organizations-inner-logs',
        },
    };

    filterLink({ key }) {
        const { isNew, organization } = this.props;
        const link = this.links[key];

        if (organization) {
            if (link.type) {
                return !isNew && link.type === organization.type;
            }

            if (link.jurType) {
                return !isNew && link.jurType === organization.jurType;
            }
        }

        return !isNew;
    }

    renderLink({ prop: key }) {
        const { levels } = this.props;
        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headLink" ids={{ 2: levels[2] }}>
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    render() {
        const { _id, isNew } = this.props;
        const items = this.orderLinks.filter(this.filterLink).filter(
            (link) =>
                this.getPermissions({
                    key: 'manual',
                    items: [
                        {
                            key: 'organizations',
                            rules: ['update'],
                        },
                    ],
                }) || link.key !== 'logs',
        );

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                <div className="widget__headActionsGroup">
                    {false && (
                        <div className="widget__headLink _delete">
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    )}
                    {!isNew && (
                        <div className="widget__headLink">
                            <div
                                className="widget__headAction _copy"
                                onClick={() => {
                                    copyInBuffer(_id);
                                }}
                            >
                                <div className="widget__headActionInner _col _click">
                                    <i className="widget__headActionIcon">
                                        <Icon name="copy" />
                                    </i>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    organization: PropTypes.object,
    pagesStore: PropTypes.object,
    checkRights: PropTypes.func,
    _id: PropTypes.string,
    levels: PropTypes.array,
};
