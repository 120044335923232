import setServerData from './setServerData';

export default function getCarInfo({ type, icons, icon }) {
    switch (type) {
        case 'icon': {
            setServerData('carBodyworks');

            let path = ``;
            const currentIcon = icons && icon && icons[icon];

            if (currentIcon) {
                path += process.env.REACT_APP_STATIC;
                path += `/car-icons/`;
                path += currentIcon.image;
            }

            return path;
        }
        default:
            return null;
    }
}
