import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Comments from '../../../../classes/crm/Comments';

import getOrganizations from '../../../../requests/getOrganizations';

import Main from './inner/Main.jsx';
import Docs from './inner/Docs.jsx';
import Users from './inner/Users.jsx';
import BankCards from './inner/BankCards.jsx';
import Logs from './inner/Logs.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import { dispatcher } from '../../../../redux/redux';
import getModel from '../../../../requests/getModel';
import setPermissions from '../../../../functions/crm/setPermissions';

class ManualOrganizationsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.setOrganization = this.setOrganization.bind(this);
        this.changeOrganization = this.changeOrganization.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    mainPage = 'manual-organizations';

    targetName = 'organization';

    targetNameSave = 'organizationSave';

    pages = {
        'manual-organizations-inner-main': {
            render() {
                const { organization, organizationSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._manual-organizations-inner-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    organization={organization}
                                    organizationSave={organizationSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeOrganization={this.changeOrganization}
                                    addComment={this.handlerComments.addComment}
                                    deleteComment={this.handlerComments.deleteComment}
                                    changeComment={this.handlerComments.changeComment}
                                    isNew={checkNew()}
                                    getOrganization={this.getOrganization}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                    setOrganization={this.setOrganization}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-organizations-inner-docs': {
            render() {
                const { isInit, organization, organizationSave, filesDocsDelete } = this.state;
                const { getParent } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Docs
                                isInit={isInit}
                                setHeightPage={this.setHeightPage}
                                organization={organization}
                                organizationSave={organizationSave}
                                formDataDocs={this.formDataDocs}
                                changeDoc={this.changeDoc}
                                addDoc={this.addDoc}
                                uploadFile={this.uploadFileDoc}
                                deleteFile={this.deleteFileDoc}
                                deleteDoc={this.deleteDoc}
                                handlerError={this.handlerErrorDoc}
                                backToSaveDocs={this.backToSaveDocs}
                                clearFormData={this.clearFormDataDocs}
                                filesDocsDelete={filesDocsDelete}
                                getParent={getParent}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-organizations-inner-docs',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-organizations-inner-users': {
            render() {
                const { organization, organizationSave, isInit } = this.state;
                const { getParent } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Users
                                    organization={organization}
                                    organizationSave={organizationSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    getParent={getParent}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-organizations-inner-cards': {
            render() {
                const { organization, organizationSave, isInit } = this.state;
                const { getParent, scrollToTop } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <BankCards
                                    organization={organization}
                                    organizationSave={organizationSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    getParent={getParent}
                                    changeOrganization={this.changeOrganization}
                                    backToSave={this.backToSave}
                                    scrollToTop={scrollToTop}
                                    setOrganization={this.setOrganization}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-organizations-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, organization } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    organization={organization}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeOrganization({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const organization = JSON.parse(JSON.stringify(newState.organization));
                    const target = organization;

                    target[name] = value;

                    newState.organization = organization;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    setOrganization({ organization, withSave = true }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.organization = organization;

                    if (withSave) {
                        newState.organizationSave = JSON.parse(JSON.stringify(organization));
                    }

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'organization', value: organization });

                    resolve();
                },
            );
        });
    }

    updateOrganization({ fields }) {
        return new Promise((resolve) => {
            this.setOrganization(
                { organization: { ...this.state.organization, ...fields } },
                resolve,
            );
        });
    }

    getOrganization() {
        const { levels, checkNew, forCompanyExecutorId } = this.props;
        const idOfOrganization = levels[3];

        return new Promise((resolve) => {
            if (checkNew()) {
                dispatcher({ type: 'typeOrganization', data: null }).then(() => {
                    getModel({ name: 'organization' }).then(
                        ({ model }) => {
                            setTimeout(() => {
                                this.setOrganization({
                                    organization: {
                                        ...model,
                                        jurType: idOfOrganization,
                                        isNew: true,
                                        ...(forCompanyExecutorId ? { type: 'executor' } : {}),
                                    },
                                }).then(() => {
                                    resolve();
                                });
                            }, 300);
                        },
                        () => null,
                    );
                });
            } else {
                getOrganizations({ id: idOfOrganization }).then(({ organization }) => {
                    setTimeout(() => {
                        this.setOrganization({ organization }).then(() => {
                            resolve();
                        });
                    }, 300);
                }, this.handlerErrorGetModel);
            }
        });
    }

    handlerSocket({ detail }) {
        const { organization } = this.state;
        const { name, data } = detail;

        if (organization) {
            const { type, fields } = data;

            if (type === 'changeInfo') {
                if (name === 'organization' && organization._id === data.organizationId) {
                    this.updateOrganization({ fields });
                }
            }
        }
    }

    handlerComments = new Comments({ name: 'organization', context: this });

    checkPermissions(blockName) {
        let actions;

        if (blockName === 'organizationInfo') {
            actions = ['changeWallet'];
        }

        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'organizations',
                    rules: ['update'],
                    actions,
                },
            ],
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getOrganization();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'organization', value: undefined });

        dispatcher({ type: 'forCompanyExecutorId', data: null });
    }

    render() {
        const { isInit } = this.state;
        return (
            <div ref={this.parent} className={`widget  ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    [
                                        'manual-organizations-inner-logs',
                                        'manual-organizations-inner-cards',
                                    ].includes(pageName)
                                        ? '_full'
                                        : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-organizations-inner' &&
                                    page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeOrganization: state.typeOrganization,
        forCompanyExecutorId: state.forCompanyExecutorId,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualOrganizationsInner);

ManualOrganizationsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    typeOrganization: PropTypes.string,
    forCompanyExecutorId: PropTypes.string,
    scrollToTop: PropTypes.func,
};
