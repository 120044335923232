import React from 'react';

import Icon from '../Icon.jsx';

class UploadTemplateInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="orderUploadTemplateInfo _col">
                <h4 className="orderUploadTemplateInfo__title">
                    Оформление заказа из&nbsp;одного документа
                </h4>
                <p className="orderUploadTemplateInfo__description">
                    Вы можете заполнить данные в нашем шаблоне, и&nbsp;загрузить их всего
                    одним&nbsp;файлом
                </p>
                <div className="orderUploadTemplateInfo__alert _col">
                    <i className="orderUploadTemplateInfo__alertIcon">
                        <Icon name="idea" />
                    </i>
                    Это удобнее и быстрее, чем вводить вручную
                </div>
            </div>
        );
    }
}

export default UploadTemplateInfo;

UploadTemplateInfo.propTypes = {};
