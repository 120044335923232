import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import { dispatcher } from '../redux/redux';

import OrderDetailsCrm from './crm/OrderDetails.jsx';

import HandlerRoute from '../classes/order/Route';

import getOrders from '../requests/getOrders';

import setServerData from '../functions/setServerData';
import changePage from '../functions/changePage';
import getCargoList from '../requests/getCargoList';
import checkResponsible from '../functions/order/checkResponsible';
import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';
import getModel from '../requests/getModel';
import removeTransition from '../functions/removeTransition.ts';
import getFormatedNumber from '../functions/getFormatedNumber.ts';
import getCurrentCorporation from '../functions/crm/getCurrentCorporation';
import setNotification from '../functions/setNotification';
import checkValueOfEmpty from '../functions/checkValueOfEmpty';
import setPermissions from '../functions/crm/setPermissions';
import getPageLink from '../functions/getPageLink';

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
        };

        this.changeOrder = this.changeOrder.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.handlerServices = this.handlerServices.bind(this);
        this.checkServices = this.checkServices.bind(this);
        this.changeRoute = this.changeRoute.bind(this);
        this.handlerFilesOfDoc = this.handlerFilesOfDoc.bind(this);
        this.handlerDocs = this.handlerDocs.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.getCondForChange = this.getCondForChange.bind(this);
        this.clearDocsForm = this.clearDocsForm.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.setOrderFromTemplate = this.setOrderFromTemplate.bind(this);
        this.handlerDate = this.handlerDate.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.getCondForCancel = this.getCondForCancel.bind(this);
        this.startOrder = this.startOrder.bind(this);
        this.checkRights = this.checkRights.bind(this);
        this.handlerSmsService = this.handlerSmsService.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    pages = {
        crm: {
            render() {
                const {
                    isReady,
                    order,
                    orderSave,
                    points,
                    isInitOfMap,
                    mapInfo,
                    ymaps,
                    map,
                    newOrder,
                    counterSort,
                    infoRoute,
                    cargoList,
                    isProccessSetRoute,
                    isProccessOptimization,
                    date,
                    times,
                    isOptimize,
                    dateOfOrder,
                } = this.state;

                const props = {
                    isReady,
                    order,
                    orderSave,
                    points,
                    isInitOfMap,
                    mapInfo,
                    ymaps,
                    map,
                    newOrder,
                    counterSort,
                    infoRoute,
                    handlerRoute: this.handlerRoute,
                    docsFormData: this.docsFormData,
                    changeOrder: this.changeOrder,
                    setOrder: this.setOrder,
                    handlerServices: this.handlerServices,
                    checkServices: this.checkServices,
                    handlerFilesOfDoc: this.handlerFilesOfDoc,
                    handlerDocs: this.handlerDocs,
                    getCondForChange: this.getCondForChange,
                    clearDocsForm: this.clearDocsForm,
                    cargoList,
                    isProccessSetRoute,
                    isProccessOptimization,
                    checkNew: this.checkNew,
                    setOrderFromTemplate: this.setOrderFromTemplate,
                    handlerDate: this.handlerDate,
                    date,
                    times,
                    getOrder: this.getOrder,
                    getCondForCancel: this.getCondForCancel,
                    isOptimize,
                    dateOfOrder,
                    checkRights: this.checkRights,
                    handlerSmsService: this.handlerSmsService,
                };

                return <OrderDetailsCrm {...props} />;
            },
        },
    };

    checkServices({ services: servicesCome }) {
        return new Promise((resolve) => {
            const services = JSON.parse(JSON.stringify(servicesCome));
            const { serverData } = this.props;
            const { orderServices, orderServiceTariffs } = serverData;

            const servicesInfo =
                this.state.order?.systemType === 'service'
                    ? orderServiceTariffs?.services
                    : orderServices;

            if (servicesInfo) {
                const keysDelete = [];

                services.forEach((service) => {
                    const serviceInfo = servicesInfo.find((item) => item.key === service.key);

                    if (serviceInfo) {
                        if (serviceInfo.type === 'counter' && !+service.value) {
                            keysDelete.push(service.key);
                        }
                        if (
                            serviceInfo.type === 'temperature' &&
                            (!service.otherData ||
                                !+service.otherData.start ||
                                !+service.otherData.end)
                        ) {
                            keysDelete.push(service.key);
                        }
                    }
                });

                // console.log(services);

                keysDelete.forEach((key) => {
                    const index = services.findIndex((service) => service.key === key);

                    services.splice(index, 1);
                });

                this.handlerServices({
                    action: 'delete',
                    values: keysDelete.map((key) => ({ name: key })),
                }).then(() => {
                    resolve(services);
                });
            } else {
                resolve(services);
            }
        });
    }

    handlerSmsService() {
        this.setState((state) => {
            const newState = { ...state };
            const points = JSON.parse(JSON.stringify(newState.points));

            const withSms = points.every((point) => point.withSms);

            points.forEach((point) => {
                point.withSms = !withSms;
            });

            newState.points = points;

            return newState;
        });
    }

    handlerServices({ action, values }) {
        const services = JSON.parse(JSON.stringify(this.state.order.services));

        return new Promise((resolve) => {
            values.forEach(({ name, value, key }) => {
                const indexService = services.findIndex((service) => service.key === name);

                if (action === 'toggle') {
                    if (indexService === -1) {
                        services.push({ key: name });
                    } else {
                        services.splice(indexService, 1);
                    }
                }
                if (action === 'change') {
                    if (indexService !== -1) {
                        services[indexService][key || 'value'] = value;
                    }
                }
                if (action === 'delete') {
                    const indexDelete = services.findIndex((service) => service.key === name);

                    if (indexDelete !== -1) {
                        services.splice(indexDelete, 1);
                    }
                }
            });

            this.changeOrder({ props: { services } }).then(() => {
                resolve();
            });
        });
    }

    hidePoints({ newPoints }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const points = JSON.parse(JSON.stringify(newState.points));

                points.forEach((point, key) => {
                    if (!newPoints.find((item) => point._id && item._id === point._id)) {
                        points[key].isDelete = true;
                        points[key].state = 0;
                    }
                });

                newState.points = points;

                return newState;
            }, resolve);
        });
    }

    updatePoints({ newPoints }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const order = JSON.parse(JSON.stringify(newState.order));
                    const orderSave = JSON.parse(JSON.stringify(newState.orderSave));
                    const points = JSON.parse(JSON.stringify(newState.points));

                    newPoints.forEach((point, key) => {
                        [
                            '_id',
                            'code',
                            'status',
                            'statuses',
                            'feedback',
                            'cargo',
                            'addressatInfo',
                        ].forEach((prop) => {
                            if (checkValueOfEmpty(point[prop], true)) {
                                let result;

                                if (prop === 'cargo') {
                                    result = point[prop].map((cargoItem) => ({
                                        ...cargoItem,
                                        items: cargoItem.items.map((item) => ({
                                            ...item,
                                            stateOfShow: 1,
                                        })),
                                        stateOfShow: 1,
                                    }));
                                } else {
                                    result = point[prop];
                                }

                                if (points[key]) {
                                    points[key][prop] = result;
                                    order.route[key][prop] = result;
                                    orderSave.route[key][prop] = result;
                                }
                            }
                        });
                    });

                    newState.points = points;
                    newState.order = order;
                    newState.orderSave = orderSave;

                    return newState;
                },
                () => {
                    setTimeout(() => {
                        this.setState({ counterSort: this.state.counterSort + 1 });
                    }, 500);
                    resolve();
                },
            );
        });
    }

    docsFormData = new FormData();

    handlerFilesOfDoc({ action, idOfPoint, idOfDoc, files }) {
        return new Promise((resolve) => {
            if (action === 'add') {
                this.setState((state) => {
                    const newState = { ...state };
                    const order = JSON.parse(JSON.stringify(newState.order));
                    const { route: points } = order;
                    const indexOfCurrentPoint = points.findIndex(
                        (point) => point._id === idOfPoint,
                    );

                    if (indexOfCurrentPoint !== -1) {
                        const indexOfCurrentDoc = points[indexOfCurrentPoint].docs.findIndex(
                            (doc) => doc._id === idOfDoc,
                        );

                        if (indexOfCurrentDoc !== -1) {
                            points[indexOfCurrentPoint].docs[indexOfCurrentDoc].files = points[
                                indexOfCurrentPoint
                            ].docs[indexOfCurrentDoc].files.concat(
                                ...files.map((file) => ({ ...file, isNew: true })),
                            );
                        }
                    }

                    order.route = points;

                    newState.order = order;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    showNewDocs() {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const order = JSON.parse(JSON.stringify(newState.order));
                const { route: points } = order;

                points.forEach((point, keyPoint) => {
                    point.docs.forEach((doc, keyDoc) => {
                        if (doc.isHide) {
                            points[keyPoint].docs[keyDoc].isHide = false;
                        }
                    });
                });

                order.route = points;

                newState.order = order;

                return newState;
            }, resolve);
        });
    }

    deleteDocs() {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const order = JSON.parse(JSON.stringify(newState.order));
                const { route: points } = order;
                const idsDeleteDocs = [];

                points.forEach((point, keyPoint) => {
                    point.docs.forEach((doc) => {
                        if (doc.isDelete) {
                            if (!doc.isNew) {
                                let docsDelete =
                                    this.docsFormData.get('docsDelete') &&
                                    JSON.parse(this.docsFormData.get('docsDelete'));

                                if (!docsDelete) {
                                    docsDelete = [];
                                }

                                docsDelete.push(doc._id);

                                this.docsFormData.set('docsDelete', JSON.stringify(docsDelete));
                            }

                            idsDeleteDocs.push({ keyPoint, id: doc._id });
                        }
                    });
                });

                idsDeleteDocs.forEach(({ keyPoint, id }) => {
                    const indexDoc = points[keyPoint].docs.findIndex((item) => item._id === id);

                    points[keyPoint].docs.splice(indexDoc, 1);
                });

                order.route = points;

                newState.order = order;

                return newState;
            }, resolve);
        });
    }

    handlerDocs({ action, idOfPoint, idOfDoc, idsOfDocs, doc, props }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const order = JSON.parse(JSON.stringify(newState.order));
                    const { route: points } = order;
                    const indexOfPoint = points.findIndex((point) => point._id === idOfPoint);

                    if (indexOfPoint !== -1) {
                        if (action === 'add') {
                            points[indexOfPoint].docs.push({ ...doc, isNew: true });
                        }
                        if (action === 'change') {
                            const indexOfDoc = points[indexOfPoint].docs.findIndex(
                                (item) => item._id === idOfDoc,
                            );

                            if (indexOfDoc !== -1) {
                                Object.keys(props).forEach((key) => {
                                    points[indexOfPoint].docs[indexOfDoc][key] = props[key];
                                });
                            }
                        }

                        if (action === 'delete') {
                            let docsDelete =
                                this.docsFormData.get('docsDelete') &&
                                JSON.parse(this.docsFormData.get('docsDelete'));

                            if (!docsDelete) {
                                docsDelete = [];
                            }

                            const resultIds = idsOfDocs || [idOfDoc];

                            resultIds.forEach((id) => {
                                const indexOfDoc = points[indexOfPoint].docs.findIndex(
                                    (item) => item._id === id,
                                );

                                if (indexOfDoc !== -1) {
                                    const deletedDoc = points[indexOfPoint].docs[indexOfDoc];

                                    if (!deletedDoc.isNew) {
                                        docsDelete.push(deletedDoc._id);
                                    }

                                    points[indexOfPoint].docs.splice(indexOfDoc, 1);
                                }
                            });

                            this.docsFormData.set('docsDelete', JSON.stringify(docsDelete));
                        }
                    }

                    order.route = points;

                    newState.order = order;

                    return newState;
                },
                () => {
                    if (action === 'delete') {
                        const contentOfFiles = this.parent.current
                            .querySelector(`.orderDetailsDocs__item[data-id="${idOfPoint}"]`)
                            .querySelector('.orderDetailsDocsOfPoint__content');

                        contentOfFiles.scrollTo({ left: 0, behavior: 'smooth' });
                    }

                    resolve();
                },
            );
        });
    }

    changeRoute({ props, initiatorUserId }) {
        const { points } = this.state;
        const { user } = this.props;

        let isChangeCoords = false;

        if (props.route.length !== points.length) {
            isChangeCoords = true;
        } else {
            points.forEach((point, key) => {
                if (JSON.stringify(point.coords) !== JSON.stringify(props.route[key].coords)) {
                    isChangeCoords = true;
                }
            });
        }

        if (initiatorUserId !== user._id) {
            this.hidePoints({ newPoints: props.route }).then(() => {
                setTimeout(() => {
                    if (isChangeCoords) {
                        this.handlerRoute
                            .init({
                                mapInfo: {
                                    center: [55.755819, 37.617644],
                                    zoom: 10,
                                    controls: [],
                                },
                                isUpdate: true,
                                type: 'details',
                                points: props.route.map((point) => ({
                                    ...(points.find((item) => item._id === point._id) || {}),
                                    ...point,
                                })),
                                isComplete: true,
                            })
                            .then(() => {
                                this.handlerRoute.setRoute({});
                            });
                    } else {
                        this.updatePoints({ newPoints: props.route });
                    }
                }, 500);
            });
        } else {
            this.updatePoints({ newPoints: props.route });
        }
    }

    clearDocsForm() {
        this.docsFormData = new FormData();
    }

    changeOrder({ props, isUpdateRoute = false, isUpdateSave = false, initiatorUserId }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const order = JSON.parse(JSON.stringify(newState.order));

                    Object.keys(props).forEach((prop) => {
                        order[prop] = props[prop];
                    });

                    if (isUpdateSave) {
                        newState.orderSave = JSON.parse(JSON.stringify(order));
                    }

                    newState.order = order;

                    return newState;
                },
                () => {
                    if (isUpdateRoute && props.route) {
                        this.changeRoute({ props, initiatorUserId });
                    }

                    if (props.idOfResponsible) {
                        this.startOrder();
                    }

                    resolve();
                },
            );
        });
    }

    getCondForChange({ name }) {
        const { order } = this.state;
        const { user, levels } = this.props;

        if (['crm', 'service'].includes(levels[1]) || order?.isNew) {
            return true;
        }

        if (order) {
            let cond = true;

            if (['new', 'start-work', 'choice-crew'].indexOf(order.status) === -1) {
                cond = false;
            }

            if (
                name === 'route' &&
                ['wait-pay', 'wait-close', 'complete', 'cancel'].indexOf(order.status) === -1
            ) {
                // const { idOfCurrentPoint } = order;
                // const indexOfCurrentPoint = order.route.findIndex(
                //     (point) => point._id === idOfCurrentPoint,
                // );

                // if (indexOfCurrentPoint < order.route.length - 1) {
                //     cond = true;
                // }

                cond = true;
            }

            if (name === 'map') {
                cond = true;
            }

            if (name === 'services') {
                cond = true;
            }

            if (name === 'tariff' && process.env.REACT_APP_SYSTEM === 'site') {
                cond = false;
            }

            cond = cond && checkResponsible({ user, order });

            if (order.systemType === 'service') {
                cond = false;
            }

            if (!this.checkRights()) {
                cond = false;
            }

            if (
                name === 'tariff' &&
                order.status === 'complete' &&
                this.getPermissions({
                    key: 'orders',
                    items: [{ key: 'main', rules: [], actions: ['changeAmount'] }],
                })
            ) {
                cond = true;
            }

            return cond;
        }

        return true;
    }

    getCargoList() {
        getCargoList().then(({ cargoList }) => {
            this.setState({ cargoList });
        });
    }

    checkRights() {
        return process.env.REACT_APP_SYSTEM === 'crm'
            ? this.getPermissions({
                  key: 'orders',
                  items: [{ key: 'main', rules: ['update'] }],
              })
            : true;
    }

    setOrder({ order, isStatic = false }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                if (isStatic === false) {
                    newState.order = order;
                }

                newState.orderSave = JSON.parse(JSON.stringify(order));

                return newState;
            }, resolve);
        });
    }

    getOrder(idRes) {
        const { copyOrder, user } = this.props;
        const corporation =
            process.env.REACT_APP_SYSTEM === 'crm' && getCurrentCorporation({ user });
        const id = idRes || this.props.levels[2];

        return new Promise((resolve, reject) => {
            if (id === 'service' && corporation?.isTest) {
                reject();
            } else if (['crm', 'service'].includes(id)) {
                getModel({ name: 'order' }).then(
                    ({ model: order }) => {
                        getModel({
                            name: 'point',
                            type: 'schema',
                            len: copyOrder?.route.length || 2,
                        }).then(
                            ({ models: points }) => {
                                const resultOrder = {
                                    isNew: true,
                                    ...order,
                                    type: 'physical',
                                    systemType: id,
                                    ...(copyOrder || {}),
                                    route: points.map((point, pointKey) => ({
                                        ...point.model,
                                        ...(copyOrder?.route[pointKey] || {}),
                                    })),
                                    corporationTariff: getCurrentCorporation({ user })?.tariff,
                                };

                                if (id === 'crm') {
                                    resultOrder.services = resultOrder.services.concat(
                                        ...[{ key: 'forcePointsOrder' }],
                                    );
                                }

                                dispatcher({ type: 'copyOrder', data: null });

                                if (process.env.REACT_APP_ENV === 'local') {
                                    resultOrder.route = resultOrder.route.map((point) => ({
                                        ...point,
                                        addressatInfo: {
                                            ...point.addressatInfo,
                                            name: {
                                                value: 'Федор',
                                            },
                                            phone: {
                                                value: '8-913-174-77-22',
                                            },
                                        },
                                        cargo: point.cargo.map((cargoItem) => ({
                                            ...cargoItem,
                                            items: [
                                                {
                                                    name: 'Пакет',
                                                    long: 20,
                                                    width: 20,
                                                    height: 20,
                                                    weight: 2,
                                                    counter: 1,
                                                },
                                            ],
                                        })),
                                    }));

                                    // resultOrder.route[0].address = 'Москва, Софийская набережная';
                                    // resultOrder.route[0].coords = [55.748073939396, 37.6190172910156];

                                    // resultOrder.route[1].address =
                                    //     'Москва, природно-исторический парк Измайлово';
                                    // resultOrder.route[1].coords = [55.7835461950277, 37.7682077712981];

                                    const dateOfOrder = new Date();

                                    dateOfOrder.setMinutes(dateOfOrder.getMinutes() + 15);

                                    resultOrder.dateOfOrder = dateOfOrder.toString();

                                    const times = {
                                        start: {
                                            hours: getFormatedNumber(dateOfOrder.getHours()),
                                            minutes: getFormatedNumber(dateOfOrder.getMinutes()),
                                        },
                                    };

                                    this.setState({ dateOfOrder: dateOfOrder.toString(), times });
                                }

                                if (id === 'service') {
                                    const dateOfOrder = new Date();

                                    dateOfOrder.setMinutes(dateOfOrder.getMinutes() + 15);

                                    resultOrder.dateOfOrder = dateOfOrder.toString();

                                    const times = {
                                        start: {
                                            hours: getFormatedNumber(dateOfOrder.getHours()),
                                            minutes: getFormatedNumber(dateOfOrder.getMinutes()),
                                        },
                                    };

                                    this.setState({ dateOfOrder: dateOfOrder.toString(), times });
                                }

                                resolve(resultOrder);
                            },
                            () => null,
                        );
                    },
                    () => null,
                );
            } else {
                getOrders({ id, params: [{ key: 'isAllCompany', value: true }] }).then(
                    ({ order }) => {
                        if (idRes) {
                            this.setOrder({ order }).then(() => {
                                this.handlerRoute
                                    .init({
                                        mapInfo: {
                                            center: [55.755819, 37.617644],
                                            zoom: 10,
                                            controls: [],
                                        },
                                        points: order.route,
                                        isUpdate: true,
                                        isSetRoute: true,
                                        isComplete: true,
                                    })
                                    .then(() => {
                                        this.startOrder();

                                        resolve(order);
                                    });
                            });
                        } else {
                            resolve(order);
                        }
                    },
                    ({ data: { message, actionCode } }) => {
                        if (message === 'Id is incorrect' || actionCode === 404) {
                            changePage({ href: getPageLink({ name: 'orders' }) });

                            setNotification({ notification: `error-orders-id` });
                        }
                    },
                );
            }
        });
    }

    getMiddleOrder(isStart) {
        this.getOrder().then((order) => {
            const { route: points } = order;

            this.handlerRoute
                .init({
                    mapInfo: {
                        center: [55.755819, 37.617644],
                        zoom: 10,
                        controls: [],
                    },
                    type: 'details',
                    points,
                    isComplete: true,
                    isSetRoute: !isStart,
                    isUpdate: !isStart,
                })
                .then(() => {
                    this.setOrder({
                        order,
                    }).then(() => {
                        if (isStart) {
                            this.startOrder();

                            setTimeout(() => {
                                this.setState({ isReady: true });
                            }, 500);
                        }

                        // setInterval(() => {
                        //     this.setState({ counterSort: this.state.counterSort + 1 });
                        // }, 1000);
                    });
                });
        });
    }

    startOrder() {
        const { order } = this.state;
        const { user } = this.props;

        if (
            process.env.REACT_APP_SYSTEM === 'crm' &&
            user._id === order.idOfResponsible &&
            order.status === 'new'
        ) {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/order`,
                    {
                        id: order._id,
                        type: 'change-status-order',
                        status: 'start-work',
                    },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    handlerErrorRequest(res);
                });
        }
    }

    handlerDate({ date, times }) {
        this.handlerRoute.setDate({ date, times });

        this.setState((state) => {
            const newState = { ...state };
            const order = JSON.parse(JSON.stringify(newState.order));
            const dateOfOrder = new Date(date);

            order.dateOfOrder = dateOfOrder;

            newState.dateOfOrder = dateOfOrder;

            newState.order = order;

            return newState;
        });
    }

    setOrderFromTemplate({ order }) {
        const { ymaps } = this.state;

        return new Promise((resolve) => {
            this.handlerRoute.setProccess(true).then(() => {
                const { dateOfOrder, times } = order;

                if (dateOfOrder && times) {
                    const dateRes = new Date(order.dateOfOrder);
                    const dateNow = new Date();
                    const dateResult =
                        dateNow.getTime() > dateRes.getTime()
                            ? dateNow.toString()
                            : order.dateOfOrder;

                    this.handlerDate({ date: dateResult, times: order.times });
                }

                Promise.all(
                    order.route.map(
                        (point, key) =>
                            new Promise((resolvePoint) => {
                                if (!point.address) {
                                    resolvePoint();
                                } else {
                                    ymaps.geocode(point.address).then((geoPoint) => {
                                        const coords =
                                            geoPoint.geoObjects.get(0)?.geometry._coordinates;

                                        order.route[key].coords = coords;

                                        resolvePoint();
                                    });
                                }
                            }),
                    ),
                ).then(() => {
                    this.handlerRoute
                        .init({
                            mapInfo: {
                                center: [55.755819, 37.617644],
                                zoom: 10,
                                controls: [],
                            },
                            points: order.route,
                            isUpdate: true,
                            isSetRoute: true,
                            isComplete: true,
                        })
                        .then(() => {
                            removeTransition({ item: '.orderPoints' });
                            removeTransition({ item: '.orderPreview__fields' });

                            this.handlerRoute.setProccess(false);

                            resolve();
                        });
                });
            });
        });
    }

    handlerRoute = new HandlerRoute({
        context: this,
        mapDisabled: !this.checkNew(),
    });

    checkNew() {
        const { levels } = this.props;

        if (this.state?.order) {
            const { order } = this.state;

            return !!order.isNew;
        }

        return ['crm', 'service'].includes(levels[2]);
    }

    getCondForCancel() {
        const { order } = this.state;

        return ['new', 'start-work', 'choice-crew'].indexOf(order?.status) !== -1;
    }

    handlerSocket({ detail }) {
        const { order } = this.state;
        const { user } = this.props;
        const { name, data } = detail;

        if (order && name === 'order') {
            const { idOfOrder, fields, type, initiatorUserId } = data;

            if (type === 'changeInfo' && idOfOrder === order._id) {
                if (process.env.REACT_APP_SYSTEM === 'crm') {
                    this.changeOrder({
                        props: fields,
                        isUpdateRoute: true,
                        isUpdateSave: true,
                        initiatorUserId,
                    });
                } else if (
                    (fields.holder &&
                        user.companies.find((companyId) => companyId === fields.holder)) ||
                    !fields.holder
                ) {
                    this.changeOrder({
                        props: fields,
                        isUpdateRoute: true,
                        isUpdateSave: true,
                        initiatorUserId,
                    });
                } else {
                    changePage({
                        href: 'cabinet-orders',
                    });
                }
            }
        }
    }

    checkChangeId(isStart) {
        const { levels } = this.props;

        if (levels[0] === 'order-details' && levels[1] && levels[1] !== this.orderId) {
            this.orderId = levels[1];

            if (!isStart) {
                this.getMiddleOrder(false);
            }
        }
    }

    componentDidMount() {
        ['orderServices', 'orderServiceTariffs'].forEach((name) => {
            setServerData(name);
        });

        this.checkChangeId(true);
        this.getMiddleOrder(true);

        this.getCargoList();

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentDidUpdate() {
        this.checkChangeId();
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { isReady } = this.state;

        return (
            <div ref={this.parent} className={`orderDetails ${isReady ? '_isReady' : ''}`}>
                {this.pages[process.env.REACT_APP_SYSTEM].render.call(this)}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        user: state.user,
        copyOrder: state.copyOrder,
    };
}

export default connect(mapStateToProps)(OrderDetails);

OrderDetails.propTypes = {
    serverData: PropTypes.object,
    levels: PropTypes.array,
    user: PropTypes.object,
    copyOrder: PropTypes.object,
};
