import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Link from '../../../components/Link.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import More from '../../../components/crm/widget/More.jsx';

import setPermissions from '../../../functions/crm/setPermissions';
import getPage from '../../../functions/getPage';

const pages = require('../../../redux/pages').default;

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);

        setPermissions.call(this);

        this.group = React.createRef();
    }

    getOrderLinks() {
        const { inner } = this.props;

        if (inner) {
            return [
                { key: 'main' },
                { key: 'operations' },
                { key: 'docs' },
                { key: 'users' },
                { key: 'logs' },
            ];
        }

        const user = this.getUser();

        const links = [
            { key: 'main' },
            { key: 'operations' },
            { key: 'docs' },
            { key: 'users' },
            { key: 'more' },
        ];

        if (user?.idOfCurrentCorporation === 'admin') {
            links.splice(2, 1);
        }

        return links;
    }

    links = {
        main: {
            key: 'settings-main',
        },
        operations: {
            key: 'settings-operations',
        },
        reports: {
            key: 'settings-reports',
        },
        docs: {
            key: 'settings-docs',
        },
        users: {
            key: 'settings-users',
        },
        roles: {
            key: 'settings-roles',
        },
        integrations: {
            key: 'settings-integrations',
        },
        logs: {
            key: 'settings-logs',
        },
        proxy: {
            key: 'settings-proxy',
        },
        serviceOrder: {
            key: 'settings-serviceOrder',
        },
    };

    innerLinks = {
        main: {
            key: 'corporations-inner-main',
        },
        operations: {
            key: 'corporations-inner-operations',
        },
        docs: {
            key: 'corporations-inner-docs',
        },
        users: {
            key: 'corporations-inner-users',
        },
        logs: {
            key: 'corporations-inner-logs',
        },
    };

    filterLink({ page, key }) {
        const { inner } = this.props;
        const links = inner ? this.innerLinks : this.links;

        if (page) {
            key = Object.keys(links).find((pageName) => links[pageName].key === page.name);
        }

        const permissions = {
            main: 'main',
            operations: 'balance',
            users: 'users',
            integrations: 'integrations',
            docs: 'docs',
            logs: 'logs',
            reports: 'reports',
        };
        const permissionKey = permissions[key];

        if (key === 'more') {
            return ['roles', 'proxy', 'integrations', 'logs', 'reports'].find((pageName) =>
                this.getPermissions({
                    key: 'settings',
                    items: [
                        {
                            key: pageName,
                            rules: ['read'],
                        },
                    ],
                }),
            );
        }

        if (inner && key === 'logs') {
            return this.getPermissions({
                adminCorporation: true,
                key: 'settings',
                items: [],
            });
        }

        return this.getPermissions({
            key: 'settings',
            items: [
                {
                    key: permissionKey,
                    rules: ['read'],
                },
            ],
        });
    }

    renderLink({ prop: key }) {
        const { inner, corporation } = this.props;
        const links = inner ? this.innerLinks : this.links;
        const link = links[key];

        if (key === 'more') {
            const subPages = pages
                .filter((page) =>
                    [
                        'settings-reports',
                        'settings-roles',
                        'settings-integrations',
                        'settings-logs',
                        'settings-proxy',
                        'settings-serviceOrder',
                    ].includes(page.name),
                )
                .filter((page) => this.filterLink({ page }));

            return <More pages={subPages} />;
        }

        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;
        let className = '';

        if (key === 'docs') {
            className = '_active';

            if (corporation?.verification?.status === 'active') {
                className += ' _complete';
            }
        }

        return (
            <Link pageName={name} className={`widget__headLink ${className}`}>
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    getUser() {
        return this.state.user || this.props.user;
    }

    componentDidMount() {
        this.setState({ user: this.props.user });
    }

    render() {
        const items = this.getOrderLinks().filter(this.filterLink);

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    corporation: PropTypes.object,
    pagesStore: PropTypes.object,
    user: PropTypes.object,
    inner: PropTypes.bool,
};
