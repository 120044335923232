import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from '../AnimateChange.jsx';
import Select from '../Select.jsx';
import DateWindow from '../Date.jsx';
import Animate from '../Animate.jsx';
import Ymaps from '../Ymaps.jsx';

import Status from '../order/Status.jsx';

import getFormatedDate from '../../functions/getFormatedDate';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import getFormatedNumber from '../../functions/getFormatedNumber.ts';
import stopPropagationClick from '../../functions/stopPropagationClick';
import getExecutorMapIcon from '../../functions/getExecutorMapIcon';

const paysInfo = require('../../infos/order/pays.json');
const deliveryTypes = require('../../infos/order/deliveryTypes.json');

class DetailsMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowDateStart: false,
            isShowDateEnd: false,
        };

        this.handlerDate = this.handlerDate.bind(this);
        this.changeOrder = this.changeOrder.bind(this);

        Object.keys(this.refsDate).forEach((key) => {
            this[this.refsDate[key]] = React.createRef();
        });
    }

    name = 'map';

    orderItems = ['deliveryType', 'city', 'timeStart', 'timeEnd', 'distance'];

    condForDate = process.env.REACT_APP_SYSTEM === 'crm';

    items = {
        city: {
            support: 'Город:',
            checkEdit() {
                return false;
            },
        },
        timeStart: {
            support: 'Начало:',
            checkEdit() {
                const { order } = this.props;

                return (
                    order &&
                    this.condForDate &&
                    ['choice-crew', 'in-proccess', 'wait-close', 'complete'].indexOf(
                        order.status,
                    ) !== -1
                );
            },
        },
        timeEnd: {
            support: 'Конец:',
            checkEdit() {
                const { order } = this.props;

                return (
                    order &&
                    this.condForDate &&
                    ['choice-crew', 'in-proccess', 'wait-close', 'complete'].indexOf(
                        order.status,
                    ) !== -1
                );
            },
        },
        distance: {
            support: 'Пробег:',
            checkEdit() {
                return false;
            },
        },
        pay: {
            support: 'Оплата:',
            checkEdit() {
                return true;
            },
        },
        deliveryType: {
            support: 'Тип:',
            checkEdit() {
                const { order } = this.props;

                return order?.isNew;
            },
        },
    };

    changeOrder({ action, name, value }) {
        const { changeOrder } = this.props;

        return new Promise((resolve) => {
            if (action === 'change') {
                changeOrder({ props: { [name]: value } }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    refsDate = {
        timeStart: 'refTimeStart',
        timeEnd: 'refTimeEnd',
    };

    propsDate = {
        timeStart: 'isShowDateStart',
        timeEnd: 'isShowDateEnd',
    };

    timersId = {};

    setStateDate({ name, isShow: isShowRes }) {
        const prop = this.propsDate[name];
        const isShow = checkValueOfEmpty(isShowRes) ? isShowRes : !this.state[prop];

        if (this.timersId[prop]) {
            clearTimeout(this.timersId[prop]);
        }

        this.setState({ [prop]: isShow });
    }

    renderItem(name) {
        const { order, editName, infoRoute } = this.props;
        let content;
        const isCheckEdit = this.items[name].checkEdit?.call(this) || false;

        if (order) {
            if (name === 'deliveryType') {
                const supportView = (
                    <div className="orderDetailsMap__headItemContentInner _select">
                        {this.items[name].support}
                    </div>
                );

                content = (
                    <Select
                        className="_notBack _mediumFontView"
                        name="deliveryType"
                        value={order.deliveryType}
                        items={deliveryTypes}
                        isDisabled={editName !== this.name || !isCheckEdit}
                        supportView={supportView}
                        callback={this.changeOrder}
                        isEditmode={isCheckEdit}
                    />
                );
            }

            if (name === 'city' && order.city) {
                content = order?.city || '–';
            }

            if (['timeStart', 'timeEnd'].indexOf(name) !== -1 && order[name]) {
                content = getFormatedDate({ date: new Date(order[name]), type: 'full' });
            }

            if (name === 'distance') {
                content = `${infoRoute?.distance || order.distance}км`;
            }

            if (name === 'pay') {
                const supportView = (
                    <div className="orderDetailsMap__headItemContentInner _select">
                        {this.items[name].support}
                    </div>
                );

                content = (
                    <Select
                        className="_notBack _mediumFontView"
                        name="pay"
                        value={order.pay}
                        items={paysInfo}
                        isDisabled={editName !== this.name || !isCheckEdit}
                        supportView={supportView}
                        callback={this.changeOrder}
                        isEditmode={isCheckEdit}
                    />
                );
            }

            if (!content) {
                content = 'Нет данных';
            }
        } else {
            content = '–';
        }

        return ['pay', 'deliveryType'].includes(name) ? (
            <div className="orderDetailsMap__headItemContent">
                <div className="orderDetailsMap__headItemContentInner _row">{content}</div>
            </div>
        ) : (
            <AnimateChange
                className="orderDetailsMap__headItemContent"
                classNameParent="_parent"
                type="_translateMedium"
                prop={content}
            >
                <div className="orderDetailsMap__headItemContentInner">{content}</div>
            </AnimateChange>
        );
    }

    handlerDate({ date, name }) {
        if (this.condForDate) {
            this.changeOrder({ action: 'change', name, value: date }).then(() => {
                this.setStateDate({ name, isShow: false });
            });
        }
    }

    renderStatusesList() {
        const { order } = this.props;
        const statuses = [...order.statuses];
        const itemInProccess = statuses.find((item) => item.name === 'in-proccess');
        const keyInProccess = statuses.indexOf(itemInProccess);
        let statusesOfPoints = [];
        order.route.forEach((point) => {
            statusesOfPoints = statusesOfPoints.concat(
                ...point.statuses.map((item, key) => ({
                    ...item,
                    type: 'point',
                    idPoint: point._id,
                    statusKey: key,
                })),
            );
        });

        if (itemInProccess) {
            statuses.splice(keyInProccess, 1, ...statusesOfPoints);
        }

        return (
            <div className="orderDetailsMap__statusInfoList">
                {statuses.map((status, key) => (
                    <div className={`orderDetailsMap__statusInfoListItem`} key={key}>
                        <Status order={order} status={status} date={status.date} />
                    </div>
                ))}
            </div>
        );
    }

    checkCrewCoords() {
        const { order, ymaps, map } = this.props;

        if (order && ymaps && map) {
            const { crewCoords } = order;
            const mapIcon = getExecutorMapIcon({ order });

            if (crewCoords) {
                const { lat, long } = crewCoords;

                if (!this.crewCoords) {
                    this.crewCoords = {};
                }

                if (lat && long && (this.crewCoords.lat !== lat || this.crewCoords.long !== long)) {
                    this.crewCoords.lat = lat;
                    this.crewCoords.long = long;

                    if (this.crewPoint) {
                        map.geoObjects.remove(this.crewPoint);
                    }

                    this.crewPoint = new ymaps.Placemark(
                        [this.crewCoords.lat, this.crewCoords.long],
                        {},
                        {
                            iconLayout: 'default#image',
                            iconImageHref: require(`../../img/${mapIcon}`),
                            iconImageSize: [32, 32],
                            iconImageOffset: [-16, -16],
                        },
                    );

                    map.geoObjects.add(this.crewPoint);
                }
            }
        }
    }

    componentDidMount() {
        // setServerData('cities').then(() => {
        //     removeTransition({ item: '.orderDetailsMap__headItem._city' });
        // });

        this.checkCrewCoords();
    }

    componentDidUpdate() {
        this.checkCrewCoords();
    }

    componentWillUnmount() {
        const { handlerRoute } = this.props;

        if (handlerRoute) {
            handlerRoute.clear();
        }
    }

    render() {
        const { isInitOfMap, handlerRoute, mapInfo, editName, order } = this.props;

        return (
            <div className="orderDetailsMap">
                <div className="orderDetailsMap__head">
                    <div className="orderDetailsMap__headItems _row">
                        {this.orderItems
                            .filter(
                                (name) =>
                                    order?.systemType === 'crm' ||
                                    !['pay', 'deliveryType'].includes(name),
                            )
                            .map((name) => {
                                const props = {};

                                if (
                                    order &&
                                    ['timeStart', 'timeEnd'].indexOf(name) !== -1 &&
                                    order[name]
                                ) {
                                    const date = new Date(order[name]);

                                    props.times = {
                                        start: {
                                            hours: getFormatedNumber(date.getHours()),
                                            minutes: getFormatedNumber(date.getMinutes()),
                                        },
                                    };
                                }

                                return (
                                    <div
                                        className={`orderDetailsMap__headItem _${name} ${
                                            this.items[name].checkEdit.call(this) ? '_isEdit' : ''
                                        } ${editName === this.name ? '_edit' : ''}`}
                                        key={name}
                                        onClick={(e) => {
                                            if (
                                                this.items[name].checkEdit.call(this) &&
                                                editName === this.name &&
                                                ['timeStart', 'timeEnd'].indexOf(name) !== -1
                                            ) {
                                                stopPropagationClick(e);

                                                this.setStateDate({ name });
                                            }
                                        }}
                                    >
                                        <div className="orderDetailsMap__headItemInner _row">
                                            {['timeStart', 'timeEnd'].indexOf(name) !== -1 && (
                                                <div
                                                    ref={this[this.refsDate[name]]}
                                                    className="orderDetailsMap__headItemDate"
                                                >
                                                    <Animate
                                                        className="orderDetailsMap__headItemDateInner"
                                                        isShow={this.state[this.propsDate[name]]}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <DateWindow
                                                            type="oneTime"
                                                            className="_topCenter"
                                                            parent={
                                                                this[this.refsDate[name]]?.current
                                                            }
                                                            callback={({ date }) =>
                                                                this.handlerDate({ date, name })
                                                            }
                                                            hide={() => {
                                                                this.timersId[
                                                                    this.propsDate[name]
                                                                ] = setTimeout(
                                                                    () =>
                                                                        this.setStateDate({
                                                                            name,
                                                                            isShow: false,
                                                                        }),
                                                                    10,
                                                                );
                                                            }}
                                                            date={
                                                                order?.[name] &&
                                                                new Date(order[name])
                                                            }
                                                            withPast={true}
                                                            {...props}
                                                        />
                                                    </Animate>
                                                </div>
                                            )}
                                            {!['pay', 'deliveryType'].includes(name) &&
                                                this.items[name].support}
                                            {this.renderItem(name)}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="orderDetailsMap__content">
                    {process.env.REACT_APP_SYSTEM === 'crm' && order && (
                        <div className="orderDetailsMap__status">
                            <Status status={{ name: order.status }} order={order} type="full" />
                        </div>
                    )}
                    {isInitOfMap && (
                        <div className="orderDetailsMap__contentMap">
                            <Ymaps {...mapInfo} callback={handlerRoute.bootstrap} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(DetailsMap);

DetailsMap.propTypes = {
    theme: PropTypes.string,
    serverData: PropTypes.object,
    order: PropTypes.object,
    handlerRoute: PropTypes.object,
    mapInfo: PropTypes.object,
    isInitOfMap: PropTypes.bool,
    editName: PropTypes.string,
    changeOrder: PropTypes.func,
    infoRoute: PropTypes.object,
    ymaps: PropTypes.object,
    map: PropTypes.object,
};
