import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import setNotification from '../functions/setNotification';

export default function deletePaysItems({ payId, items }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_HARD_API}/pays`, {
                    id: payId,
                    action: 'delete-pays',
                    itemsIds: items,
                }, { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-change-info' });

                        resolve();
                    } else {
                        reject();
                    }
                },
                () => null,
            );
    });
}