import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import AuthForm from './Form.jsx';

import Button from '../../Button.jsx';

import { inputValidate } from '../../../functions/inputValidate';
import { dispatcher } from '../../../redux/redux';
import { getCookie } from '../../../functions/handlerCookies';

import setAnimate from '../../../functions/setAnimate';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import CodeBox from '../../CodeBox.jsx';
import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

class AuthForget extends AuthForm {
    constructor(props) {
        super(props);

        let currentStep = 'email';

        if (getCookie(process.env.REACT_APP_FORGET_LOGIN)) {
            currentStep = 'code';
        }

        if (getCookie(process.env.REACT_APP_FORGET_CODE)) {
            currentStep = 'passwords';
        }

        // currentStep = 'success';

        this.state = {
            currentStep,
        };

        this.renderTitle = this.renderTitle.bind(this);
        this.checkCode = this.checkCode.bind(this);

        this.progress = React.createRef();
        this.parent = React.createRef();
    }

    name = 'forget';

    orderGroups = ['forget-email', 'forget-passwords'];

    allSteps = ['email', 'code', 'passwords', 'success'];

    blocks = {
        email: {
            groups: ['forget-email'],
            title: (
                <>
                    Давайте
                    <br />
                    сбросим пароль
                </>
            ),
            render() {
                const { loadingKey } = this.state;

                return (
                    <>
                        <div className="authForm__contentGroups">
                            {['forget-email'].map((nameGroup) =>
                                this.renderGroup({ name: nameGroup }),
                            )}
                        </div>
                        <div className="authForm__contentButton">
                            <Button
                                className="_mainNotBorder _medium2Size"
                                onClick={() => {
                                    this.handlerStep({ currentStep: 'code' });
                                }}
                                showLoader={loadingKey === 'fresh'}
                            >
                                Сбросить пароль
                            </Button>
                        </div>
                    </>
                );
            },
        },
        code: {
            title: (
                <>
                    Проверьте
                    <br />
                    вашу почту
                </>
            ),
            render() {
                const { loadingKey } = this.state;
                const { freshPasswordValue } = this.props;

                return (
                    <>
                        <p className="authForm__contentDescription">
                            На почту <b>{freshPasswordValue}</b> отправлено сообщение с кодом.
                            <br />
                            Введите его в поле ниже:
                        </p>
                        <div className="authForm__contentCode">
                            <CodeBox
                                className="_forget"
                                inputs={[1, 2, 3, 4, 5]}
                                callback={this.checkCode}
                            />
                        </div>
                        <div className="authForm__contentLinks _row">
                            <div
                                className={`authForm__contentLink _click ${
                                    loadingKey === 'fresh' ? '_loading' : ''
                                }`}
                                onClick={() => {
                                    this.freshPassword({});
                                }}
                            >
                                Отправить код повторно
                                <Animate
                                    className="authForm__contentLinkLoader"
                                    isShow={loadingKey === 'fresh'}
                                >
                                    <Loader className="_main" />
                                </Animate>
                            </div>
                        </div>
                    </>
                );
            },
        },
        passwords: {
            groups: ['forget-passwords'],
            title: (
                <>
                    Придумайте
                    <br />
                    новый пароль
                </>
            ),
            render() {
                return (
                    <>
                        <div className="authForm__contentGroups">
                            {['forget-passwords'].map((nameGroup) =>
                                this.renderGroup({ name: nameGroup }),
                            )}
                        </div>
                        <div className="authForm__contentButton">
                            <Button
                                className="_mainNotBorder _medium2Size"
                                onClick={() => {
                                    this.handlerStep({ currentStep: 'success' });
                                }}
                            >
                                Сохранить пароль
                            </Button>
                        </div>
                    </>
                );
            },
        },
        success: {
            title: (
                <>
                    Пароль успешно сброшен
                    <i className="authForm__headTitleIcon">
                        <Icon name="forget-password-complete" />
                    </i>
                </>
            ),
            render() {
                return (
                    <>
                        <p className="authForm__contentDescription">
                            Вы успешно изменили свой пароль от&nbsp;личного кабинета LIVECARGO.
                            <br />
                            Используйте его для входа
                        </p>
                        <Link className="authForm__contentButton" pageName="login">
                            <Button className="_mainNotBorder _medium2Size">Войти в кабинет</Button>
                        </Link>
                    </>
                );
            },
        },
    };

    progressResult = 0;

    getProgress() {
        const { fields, currentStep } = this.state;
        let progressResult = 0;

        if (fields) {
            if (currentStep === 'email') {
                if (inputValidate({ name: 'email', value: fields.email.value })) {
                    progressResult = 33;
                }
            }

            if (currentStep === 'code') {
                progressResult = 33;
            }

            if (currentStep === 'passwords') {
                if (
                    ['password', 'passwordAgain'].every(
                        (nameFieldPassword) =>
                            fields[nameFieldPassword].value &&
                            inputValidate({
                                name: 'password',
                                value: fields[nameFieldPassword].value,
                            }),
                    ) &&
                    fields.password.value === fields.passwordAgain.value
                ) {
                    progressResult = 99;
                } else {
                    progressResult = 66;
                }
            }

            if (currentStep === 'success') {
                progressResult = 100;
            }
        }

        return progressResult;
    }

    checkProgress(isForce = false) {
        if (this.progress?.current) {
            const { fields } = this.state;
            const progressBox = this.progress.current;
            const allFields = this.getAllFields();
            let { length: lengthValidateFields } = allFields.filter((nameField) =>
                inputValidate({ name: nameField, value: fields[nameField].value }),
            );

            if (
                fields?.password.value &&
                fields?.passwordAgain.value &&
                fields?.password.value === fields?.passwordAgain.value
            ) {
                lengthValidateFields += 1;
            }

            const prevProgressResult = this.progressResult;
            const progressResult = this.getProgress();

            this.prevLengthValidateFields = lengthValidateFields;

            if (this.idProgressAnimate) {
                cancelAnimationFrame(this.idProgressAnimate);
            }

            if (progressResult !== prevProgressResult) {
                if (isForce) {
                    this.progressResult = progressResult;

                    progressBox.innerHTML = this.progressResult;
                } else {
                    this.idProgressAnimate = setAnimate({
                        draw: (progress) => {
                            this.progressResult = +(
                                prevProgressResult +
                                (progressResult - prevProgressResult) * progress
                            ).toFixed(0);

                            progressBox.innerHTML = this.progressResult;
                        },
                        duration: 300,
                    });
                }
            }
        }
    }

    getProgressStatus() {
        const progress = this.getProgress();

        if (progress === 0) {
            return '';
        }

        if (progress < 50) {
            return '_low';
        }

        if (progress < 100) {
            return '_medium';
        }

        if (progress === 100) {
            return '_high';
        }

        return '';
    }

    checkCode(code) {
        const { freshPasswordValue } = this.props;

        return new Promise((resolve) => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/forget-password`,
                    {
                        login: freshPasswordValue,
                        action: 'checkCode',
                        code,
                    },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    const { success } = res.data;

                    if (success) {
                        dispatcher({
                            type: process.env.REACT_APP_FORGET_CODE,
                            data: code,
                            cookies: { isSet: true, maxAge: 60 * 15 },
                        });

                        this.handlerStep({ currentStep: 'passwords' });
                    } else {
                        handlerErrorRequest(res);
                    }

                    resolve(success);
                });
        });
    }

    freshPassword() {
        const { fields } = this.state;
        const { freshPasswordValue } = this.props;
        const login = fields.email.value || freshPasswordValue;

        return new Promise((resolve) => {
            this.handlerLoading('fresh').then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/forget-password`,
                        { action: 'fresh', login },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                dispatcher({
                                    type: process.env.REACT_APP_FORGET_LOGIN,
                                    data: login,
                                    cookies: { isSet: true, maxAge: 60 * 15 },
                                });

                                resolve();
                            } else {
                                const { message } = data;

                                if (message === 'Many request') {
                                    setNotification({
                                        notification: 'forget-password-many-request',
                                    });
                                }

                                if (message === 'User not found') {
                                    setNotification({ notification: 'user-not-found' });

                                    this.handlerField({
                                        action: 'error',
                                        name: 'email',
                                        value: 'userNotFound',
                                    });
                                }

                                handlerErrorRequest(res);
                            }

                            this.handlerLoading(null);
                        },
                        () => {
                            this.handlerLoading(null);
                        },
                    );
            });
        });
    }

    handlerStep({ currentStep }) {
        const { fields } = this.state;
        const { freshPasswordValue, freshPasswordCode } = this.props;

        const prevActions = () =>
            new Promise((resolve) => {
                if (currentStep === 'code') {
                    const orderFields = this.getAllFields(this.blocks.email.groups);
                    const { isSuccess, errorFieldName } = this.checkValidateFields(orderFields);

                    if (!isSuccess) {
                        if (errorFieldName) {
                            const input = this.parent.current.querySelector(
                                `#input-auth-forget-${errorFieldName}`,
                            );

                            if (input) {
                                input.focus();
                            }
                        }
                    } else {
                        this.freshPassword().then(resolve, () => null);
                    }
                } else if (currentStep === 'success') {
                    const orderFields = this.getAllFields(this.blocks.passwords.groups);
                    const { isSuccess, errorFieldName } = this.checkValidateFields(orderFields);

                    if (!isSuccess) {
                        if (errorFieldName) {
                            const input = this.parent.current.querySelector(
                                `#input-auth-forget-${errorFieldName}`,
                            );

                            if (input) {
                                input.focus();
                            }
                        }
                    } else {
                        this.handlerLoading('save').then(() => {
                            axios
                                .post(
                                    `${process.env.REACT_APP_API}/forget-password`,
                                    {
                                        login: freshPasswordValue,
                                        code: freshPasswordCode,
                                        password: fields.password.value,
                                    },
                                    { headers: getHeaders() },
                                )
                                .then(
                                    (res) => {
                                        const { success } = res.data;

                                        if (success) {
                                            dispatcher({
                                                type: process.env.REACT_APP_FORGET_LOGIN,
                                                data: null,
                                                cookies: { isSet: false },
                                            });
                                            dispatcher({
                                                type: process.env.REACT_APP_FORGET_CODE,
                                                data: null,
                                                cookies: { isSet: false },
                                            });

                                            resolve();
                                        } else {
                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoading(null);
                                    },
                                    () => {
                                        this.handlerLoading(null);
                                    },
                                );
                        });
                    }
                } else {
                    resolve();
                }
            });

        prevActions().then(() => {
            this.setState({ currentStep }, () => {
                this.checkProgress();
            });
        });
    }

    renderTitle({ prop: id }) {
        const block = this.blocks[id];

        return <div className="authForm__headTitleInner">{block.title}</div>;
    }

    componentDidMountOther() {
        this.checkProgress(true);
    }

    render() {
        const { currentStep, counterUpdateError } = this.state;

        return (
            <div ref={this.parent} className="authForm">
                <div className="authForm__inner">
                    <div className="authForm__head">
                        <Animate className="authForm__headBack" isShow={currentStep !== 'success'}>
                            <Link className="authForm__headBackInner _col" pageName="login">
                                <i className="authForm__headBackIcon">
                                    <Icon name="arrow-prev-2" />
                                </i>
                            </Link>
                        </Animate>
                        <div className="authForm__headBox">
                            <div className="authForm__headInner">
                                <ListAbsoluteMain
                                    className="authForm__headTitle _dynamic"
                                    items={this.getOrderBlocks()}
                                    renderItem={this.renderTitle}
                                    classNameItem="authForm__headTitleInner"
                                    prop="id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    resizeParent={document.querySelector('.body')}
                                    currentItemKey={currentStep}
                                    allItems={this.allSteps}
                                />
                                {this.renderPagination(currentStep !== 'success')}
                                <div
                                    className={`authForm__headProgress ${this.getProgressStatus()}`}
                                >
                                    <span
                                        ref={this.progress}
                                        className="authForm__headProgressResult"
                                    >
                                        0
                                    </span>
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                    <ListAbsoluteMain
                        className="authForm__content _dynamic"
                        items={this.getOrderBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="authForm__contentBlock"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        resizeParent={document.querySelector('.body')}
                        keyRender={counterUpdateError}
                        currentItemKey={currentStep}
                        allItems={this.allSteps}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        freshPasswordValue: state[process.env.REACT_APP_FORGET_LOGIN],
        freshPasswordCode: state[process.env.REACT_APP_FORGET_CODE],
    };
}

export default connect(mapStateToProps)(AuthForget);

AuthForget.propTypes = {
    setSuccessLogin: PropTypes.func,
    freshPasswordValue: PropTypes.string,
    freshPasswordCode: PropTypes.string,
};
