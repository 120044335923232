import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';

import Animate from '../../../Animate.jsx';
import Icon from '../../../Icon.jsx';
import Select from '../../../Select.jsx';
import Loader from '../../../Loader.jsx';

import setServerData from '../../../../functions/setServerData';
import getEndText from '../../../../functions/getEndText';
import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import Field from '../../../Field.jsx';

class ManualCarsServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderService = this.renderService.bind(this);
        this.filterService = this.filterService.bind(this);

        this.parent = React.createRef();
    }

    orderActions = ['add', 'delete'];

    getDescriptionInfo() {
        const { car } = this.props;

        if (car) {
            const servicesActive = this.getServicesActive();
            const { length: counterActive } = servicesActive;

            if (servicesActive.length) {
                return {
                    description: `${counterActive} ${getEndText(counterActive, [
                        'услуга',
                        'услуги',
                        'услуг',
                    ])}`,
                };
            }
        }

        return {
            description: '–',
        };
    }

    getServicesActive() {
        const { car = {} } = this.props;
        const { services = [] } = car;

        return services;
    }

    filterService(service) {
        const { checkEditmode } = this.props;
        const servicesActive = this.getServicesActive();
        const isEdit = checkEditmode();

        return isEdit || servicesActive.find((serviceLoop) => serviceLoop.key === service.key);
    }

    getServicesArr() {
        const { serverData } = this.props;
        const { carServices = {} } = serverData;

        return Object.keys(carServices).map((key) => ({ key }));
    }

    checkServiceActive({ key }) {
        const servicesActive = this.getServicesActive();

        return servicesActive.find((serviceLoop) => serviceLoop.key === key);
    }

    renderService({ prop: key, key: keyOrder }) {
        const servicesActive = this.getServicesActive();
        const { handlerServices, checkEditmode } = this.props;
        const { serverData } = this.props;
        const { carServices = {} } = serverData;
        const service = carServices[key];
        const { support, type } = service;
        const stateAction = this.checkServiceActive({ key }) ? 'delete' : 'add';
        const isEdit = checkEditmode();
        let value;
        let date;
        let items;

        if (type !== 'radio' && this.checkServiceActive({ key })) {
            const serviceActive = servicesActive.find((serviceLoop) => serviceLoop.key === key);

            value = serviceActive.value;
            date = serviceActive.date;
        }

        if (key === 'brand') {
            items = service.items;
        }

        return (
            <div
                className={`manualCard__service _${type} ${isEdit ? '_active' : ''}`}
                style={{
                    zIndex: keyOrder,
                }}
            >
                <div className="manualCard__serviceInner _row">
                    <p className="manualCard__serviceSupport">{support}</p>
                    <div className="manualCard__serviceContent">
                        {type === 'text' && (
                            <>
                                <Animate
                                    className="manualCard__serviceAction"
                                    onClick={() => handlerServices({ key })}
                                    isShow={isEdit}
                                    isSmoothShow={true}
                                >
                                    <div className={`action _col _animate _${stateAction}`}>
                                        {this.orderActions.map((action) => (
                                            <Animate
                                                key={action}
                                                className={`action__icon _${action}`}
                                                isShow={action === stateAction}
                                            >
                                                <Icon name={action} />
                                            </Animate>
                                        ))}
                                    </div>
                                </Animate>
                                <Animate
                                    className="manualCard__serviceValue _row"
                                    isShow={this.checkServiceActive({ key })}
                                >
                                    <Field
                                        keyName="numberWeight"
                                        name={key}
                                        value={value}
                                        callback={({ action, value: updateValue }) =>
                                            new Promise((resolve) => {
                                                if (action === 'change') {
                                                    handlerServices({
                                                        key,
                                                        values: [
                                                            { prop: 'value', value: updateValue },
                                                        ],
                                                    }).then(resolve);
                                                } else {
                                                    resolve();
                                                }
                                            })
                                        }
                                        className={`_manualServices _grey _center`}
                                        // group={service.key}
                                        isDisabled={!isEdit}
                                        isEditmode={isEdit}
                                    />
                                </Animate>
                            </>
                        )}
                        {type === 'radio' && (
                            <>
                                <Animate
                                    className="manualCard__serviceAction"
                                    onClick={() => handlerServices({ key })}
                                    isShow={isEdit}
                                    isSmoothShow={true}
                                >
                                    <div className={`action _col _animate _${stateAction}`}>
                                        {this.orderActions.map((action) => (
                                            <Animate
                                                key={action}
                                                className={`action__icon _${action}`}
                                                isShow={action === stateAction}
                                            >
                                                <Icon name={action} />
                                            </Animate>
                                        ))}
                                    </div>
                                </Animate>
                                <Animate
                                    className="manualCard__serviceValue _row"
                                    isShow={this.checkServiceActive({ key }) && !isEdit}
                                >
                                    Да
                                </Animate>
                            </>
                        )}
                        {type === 'select' && (
                            <>
                                <div className="manualCard__serviceSelect">
                                    <Select
                                        items={items}
                                        support="–"
                                        type="date"
                                        name="dateAction"
                                        value={value}
                                        className="_manualServices _center _fullWidthList _reverse"
                                        id={key}
                                        callback={async ({ ...props }) => {
                                            const values = [];

                                            if (props.name === 'dateAction') {
                                                values.push({ prop: 'value', value: props.value });
                                            }
                                            if (props.name === 'date') {
                                                values.push({
                                                    prop: 'date',
                                                    value: props.value,
                                                });
                                            }

                                            return handlerServices({
                                                key,
                                                values,
                                            });
                                        }}
                                        otherField={{
                                            keyInput: 'date',
                                            date,
                                        }}
                                        isDisabled={!isEdit}
                                        isEditmode={true}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        setServerData('carServices').then(() => {
            this.setState({ isGetServices: true });
        });
    }

    render() {
        const { isGetServices } = this.state;
        const { title } = this.props;
        const { description } = this.getDescriptionInfo();
        const services = this.getServicesArr().filter(this.filterService);

        return (
            <div ref={this.parent} className="manualCard _carParams">
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                </div>
                <div className="manualCard__content">
                    <Animate className="manualCard__contentLoader _loader" isShow={!isGetServices}>
                        <div className="manualCard__contentLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <Animate
                        className="manualCard__contentEmpty"
                        isShow={isGetServices && services.length === 0}
                    >
                        <div className="empty _col _block _notBack">
                            <div className="empty__inner">
                                <div className="empty__title">Услуги не выбраны</div>
                                <p className="empty__content">
                                    Вы можете добавить их перейдя <br />в режим редактирования
                                </p>
                            </div>
                        </div>
                    </Animate>
                    <Animate className="manualCard__services" isShow={isGetServices}>
                        <ListAbsoluteMain
                            className="manualCard__servicesInner"
                            items={services}
                            renderItem={this.renderService}
                            classNameItem="manualCard__service"
                            prop="key"
                            paramsParent={{ width: true }}
                        />
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualCarsServices);

ManualCarsServices.propTypes = {
    serverData: PropTypes.object,
    car: PropTypes.object,
    title: PropTypes.string,
    handlerServices: PropTypes.func,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
};
