import React from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

import PopupCorporation from '../../../components/crm/popups/PopupCorporation.jsx';
import Head from '../../../components/crm/manual/card/Head.jsx';
import Edit from '../../../components/Edit.jsx';
import Link from '../../../components/Link.jsx';

import Editmode from '../../../classes/Editmode';

import setPdf from '../../../requests/setPdf';
import setPermissions from '../../../functions/crm/setPermissions';
import getFormatPrice from '../../../functions/getFormatPrice';
import handlerAppPopup from '../../../functions/app/handlerPopup';

import getHeaders from '../../../functions/getHeaders';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';
import setNotification from '../../../functions/setNotification';
import getUpdateFormData from '../../../functions/getUpdateFormData';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import Icon from '../../../components/Icon.jsx';
import getFormatedDate from '../../../functions/getFormatedDate';
import handlerSupportCursor from '../../../functions/handlerSupportCursor';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import handlerPopup from '../../../functions/crm/handlerPopup';
import getPageLink from '../../../functions/getPageLink';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

class SettingsMain extends PopupCorporation {
    constructor(props) {
        super(props);
        this.state = {
            currentLimit: 'month',
        };

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.renderLimit = this.renderLimit.bind(this);
        this.renderLimitHead = this.renderLimitHead.bind(this);

        setPermissions.call(this);
    }

    name = 'settingsMain';

    fieldsGroups = {
        main: ['inn', 'bic', 'kpp', 'fullName', 'bankAccount'],
        foot: ['website', 'shortName'],
    };

    checkChangeProp({ value, valueWas }) {
        return value !== valueWas;
    }

    checkChange(name) {
        const { fields, logo } = this.state;
        const { corporation } = this.props;
        const changedProps = this.fieldsGroups[name];
        let isChange = false;
        const changedFields = {};

        changedProps.forEach((prop) => {
            const target = fields;
            const targetWas = corporation;

            if (
                this.checkChangeProp({ prop, value: target[prop].value, valueWas: targetWas[prop] })
            ) {
                isChange = true;
                changedFields[prop] = target[prop].value;
            }
        });

        if (logo.isLocal) {
            isChange = true;
        }

        return { isChange, fields: changedFields };
    }

    handlerEditmode({ editName }) {
        const { corporation, setEditName } = this.props;

        const checkEdit = () =>
            new Promise((resolve, reject) => {
                if (this.state.fields.inn.isLoading || this.state.fields.bic.isLoading) {
                    reject();
                } else if (editName) {
                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors } = this.checkValidateFields(
                            this.fieldsGroups[this.state.editName],
                        );

                        if (!isSuccess) {
                            this.setErrors(errors);
                        } else {
                            this.handlerLoading(this.state.editName).then(() => {
                                this.formData.set('id', corporation._id);
                                this.formData.set('fields', JSON.stringify(fields));

                                axios
                                    .patch(
                                        `${process.env.REACT_APP_API}/corporation`,
                                        getUpdateFormData(this.formData),
                                        {
                                            headers: getHeaders(),
                                        },
                                    )
                                    .then((res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'corporation-update',
                                            });

                                            resolve();
                                        } else {
                                            this.handlerErrorRequest(data);

                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoading(null);
                                    });
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName }).then(() => {
                if (typeof setEditName === 'function') {
                    setEditName({ editName });
                }
            });
        });
    }

    renderEdit({ name }) {
        const { editName, loadingKey } = this.state;

        return this.getPermissions({
            key: 'settings',
            items: [
                {
                    key: 'main',
                    rules: ['update'],
                },
            ],
        }) ? (
            <Edit
                name={name}
                className="manualContent__cardEdit"
                editName={editName}
                handlerEditmode={this.handlerEditmode}
                isLoader={loadingKey === name}
            />
        ) : null;
    }

    getSubInfo() {
        const { corporation } = this.props;

        let resultString = '';

        if (corporation.shortName) {
            resultString += corporation.shortName;
        }

        if (!resultString) {
            resultString = '–';
        }

        return resultString;
    }

    editmode = new Editmode({
        context: this,
    });

    getLimits() {
        const { currentLimit } = this.state;
        const { corporation } = this.props;

        return [
            {
                key: currentLimit,
                ...corporation.limits[currentLimit],
                remains: corporation.balanceRemains[currentLimit],
            },
        ];
    }

    renderLimit({ item }) {
        return (
            <div className="settingsMain__optionsBlock">
                <div className="settingsMain__option _row">
                    <div className="settingsMain__optionText _support">Самозанятым</div>
                    <div className="settingsMain__optionText">{getFormatPrice(item.SMZ)} ₽</div>
                </div>
                <div className="settingsMain__option _row">
                    <div className="settingsMain__optionText _support">Физ. лицам</div>
                    <div className="settingsMain__optionText">{getFormatPrice(item.FL)} ₽</div>
                </div>
                <div className="settingsMain__option _row">
                    <div className="settingsMain__optionText _support">ИП</div>
                    <div className="settingsMain__optionText">{getFormatPrice(item.IP)} ₽</div>
                </div>
            </div>
        );
    }

    renderLimitHead({ item }) {
        return (
            <div className="settingsMain__optionsBlock">
                <div className="settingsMain__title">{getFormatPrice(item.all)} ₽</div>
                <div className="settingsMain__description">
                    Остаток: {getFormatPrice(item.remains)} ₽
                </div>
            </div>
        );
    }

    handlerLoading(loadingKey) {
        const { setLoadingKey } = this.props;

        return new Promise((resolve) => {
            this.setState({ loadingKey }, () => {
                if (typeof setLoadingKey === 'function') {
                    setLoadingKey(loadingKey);
                }

                resolve();
            });
        });
    }

    downloadJoinsReport() {
        const { loadingKey } = this.state;
        const { user } = this.props;

        const corporation = getCurrentCorporation({ user });

        if (!loadingKey) {
            this.handlerLoading('download').then(() => {
                setPdf({
                    id: `joinsReport-${user._id}`,
                    key: 'joinsReport',
                    name: `Оферта подключений ${corporation.opfName || corporation.fullName}.pdf`,
                }).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    getTariffString({ name, short }) {
        const { corporation } = this.props;
        const tariff = corporation.tariff[name];

        if (tariff.current === 'percent') {
            if (short) {
                return `${tariff.percent}%`;
            }

            return `${tariff.percent}%, мин. ${getFormatPrice(tariff.min)}₽`;
        }

        return `${getFormatPrice(tariff.amount)}₽`;
    }

    getUser() {
        return this.state.user || this.props.user;
    }

    componentDidMount() {
        const { setHandlerEditmode } = this.props;

        super.componentDidMount();

        if (typeof setHandlerEditmode === 'function') {
            setHandlerEditmode(this.handlerEditmode);
        }
    }

    render() {
        const { editName, currentLimit } = this.state;
        const { corporation, inner, getCorporation, updatedCorporationKey } = this.props;
        const { lastReplenishmentDate } = corporation;
        const lastPay = lastReplenishmentDate
            ? getFormatedDate({ date: new Date(lastReplenishmentDate), isShortYear: true })
            : '–';
        const user = this.getUser();
        const isAdmin = user?.idOfCurrentCorporation === 'admin';
        const isAdminSettings = isAdmin && !inner;
        const isMarginCard =
            (!inner ||
                !this.getPermissions({
                    key: 'corporations',
                    items: [
                        {
                            key: 'main',
                            rules: ['update'],
                        },
                    ],
                })) &&
            !corporation.isAdmin;

        return (
            <div
                ref={this.parent}
                className={`settingsMain _parentForEdits ${editName ? '_edit' : ''} ${
                    isMarginCard ? '_marginCard' : ''
                } ${isAdminSettings ? '_admin' : ''}`}
            >
                <div className="settingsMain__inner">
                    <div className="settingsMain__cards _row">
                        <div className="settingsMain__card _balance">
                            <div className="settingsMain__cardInner _editBack">
                                <div className="manualCard">
                                    <div className="manualCard__content _fullHeight">
                                        <Link
                                            className="settingsMain__balance _col"
                                            href={
                                                inner
                                                    ? getPageLink({
                                                          name: 'corporations-inner-operations',
                                                          ids: { 2: corporation._id },
                                                      })
                                                    : getPageLink({
                                                          name: 'settings-operations',
                                                      })
                                            }
                                        >
                                            <i className="settingsMain__balanceIcon">
                                                <Icon name="corporation-balance" />
                                            </i>
                                        </Link>
                                        <div className="settingsMain__support">Баланс компании</div>
                                        <div className="settingsMain__title">
                                            {getFormatPrice(corporation?.balance)} ₽
                                        </div>
                                        <div className="settingsMain__description">
                                            Последнее пополнение: {lastPay}
                                        </div>
                                        {!isAdminSettings && (
                                            <div
                                                className="settingsMain__button _row _main _click"
                                                onClick={() => {
                                                    handlerPopup({
                                                        name: 'isCorporationPayPopupShow',
                                                        isShow: true,
                                                    });
                                                }}
                                            >
                                                Пополнить Баланс
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`settingsMain__cardInner _subInfo _parentForEdit _editBack ${
                                    editName === 'foot' ? '_current' : ''
                                }`}
                            >
                                {this.renderEdit({ name: 'foot' })}
                                <div className="manualCard">
                                    <div className="manualCard__content _fullHeight">
                                        <div className="settingsMain__support">Общие данные</div>
                                        <div className="settingsMain__foot">
                                            {this.renderFoot()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isAdminSettings && (
                            <>
                                <div className="settingsMain__card _tariff _editBack">
                                    <div className="manualCard">
                                        <div className="manualCard__content _fullHeight">
                                            {false && (
                                                <i
                                                    className="settingsMain__cardInfo _click"
                                                    onMouseEnter={(e) => {
                                                        handlerSupportCursor({
                                                            action: 'enter',
                                                            content: `<b class="_title">Что такое тариф?</b>Тариф устанавливает комиссию (%)<br/> в системе за каждую выплату, <br/>сделанную из вашей корпорации`,
                                                            e,
                                                            data: {
                                                                className:
                                                                    '_normalCase _info _wide',
                                                            },
                                                        });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        handlerSupportCursor({
                                                            action: 'leave',
                                                            e,
                                                        });
                                                    }}
                                                >
                                                    <Icon name="info" />
                                                </i>
                                            )}
                                            <div className="settingsMain__support">Тариф</div>
                                            <div className="settingsMain__title">
                                                {this.getTariffString({
                                                    name: 'pay',
                                                    short: true,
                                                })}
                                            </div>
                                            <div className="settingsMain__description">
                                                {corporation.tariff.pay.current === 'percent' ? (
                                                    <>Минимум — {corporation.tariff.pay.min}₽</>
                                                ) : (
                                                    <>Минимум не установлен</>
                                                )}
                                            </div>
                                            <div className="settingsMain__cardContent">
                                                <div className="settingsMain__option _row">
                                                    <div className="settingsMain__optionText _support">
                                                        Процент комиссии
                                                    </div>
                                                    <div className="settingsMain__optionText">
                                                        {corporation.tariff.pay.percent}%
                                                    </div>
                                                </div>
                                                <div className="settingsMain__option _row">
                                                    <div className="settingsMain__optionText _support">
                                                        Мин. комиссия
                                                    </div>
                                                    <div className="settingsMain__optionText">
                                                        {getFormatPrice(corporation.tariff.pay.min)}₽
                                                    </div>
                                                </div>
                                            </div>
                                            {isAdmin &&
                                                !corporation.isAdmin &&
                                                this.getPermissions({
                                                    key: 'corporations',
                                                    items: [
                                                        {
                                                            key: 'main',
                                                            rules: ['update'],
                                                        },
                                                    ],
                                                }) && (
                                                    <div className="settingsMain__cardFoot _col">
                                                        <div
                                                            className="settingsMain__button _row _click"
                                                            onClick={() => {
                                                                handlerAppPopup({
                                                                    name: 'corporationTariffPopup',
                                                                    isShow: true,
                                                                    corporationId: corporation._id,
                                                                    corporationName:
                                                                        corporation.shortName ||
                                                                        corporation.fullName,
                                                                    tariff: corporation.tariff,
                                                                    callback: getCorporation,
                                                                });
                                                            }}
                                                        >
                                                            <div className="settingsMain__buttonIcon">
                                                                <Icon name="edit-2" />
                                                            </div>
                                                            Редактировать тариф
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="settingsMain__card _limit _editBack">
                                    <div className="manualCard _hidden">
                                        <div className="manualCard__content _fullHeight">
                                            <i
                                                className="settingsMain__cardInfo _click"
                                                onMouseEnter={(e) => {
                                                    handlerSupportCursor({
                                                        action: 'enter',
                                                        content: `<b class="_title">Что такое лимит?</b>Лимит — максимальная сумма, на которую <br/>корпорация может совершать денежные <br/>операции в установленный период`,
                                                        e,
                                                        data: {
                                                            className: '_normalCase _info _wide',
                                                        },
                                                    });
                                                }}
                                                onMouseLeave={(e) => {
                                                    handlerSupportCursor({ action: 'leave', e });
                                                }}
                                            >
                                                <Icon name="info" />
                                            </i>
                                            <div className="settingsMain__support">
                                                Лимит{' '}
                                                <label className="settingsMain__supportTab">
                                                    <input
                                                        type="checkbox"
                                                        className="settingsMain__supportTabInput"
                                                        checked={currentLimit === 'month'}
                                                        onChange={() => {
                                                            this.setState({
                                                                currentLimit: 'month',
                                                            });
                                                        }}
                                                    />
                                                    <div className="settingsMain__supportTabContent">
                                                        в месяц
                                                    </div>
                                                </label>
                                                <span>{' / '}</span>
                                                <label className="settingsMain__supportTab">
                                                    <input
                                                        type="checkbox"
                                                        className="settingsMain__supportTabInput"
                                                        checked={currentLimit === 'year'}
                                                        onChange={() => {
                                                            this.setState({ currentLimit: 'year' });
                                                        }}
                                                    />
                                                    <div className="settingsMain__supportTabContent">
                                                        в год
                                                    </div>
                                                </label>
                                            </div>
                                            <ListAbsoluteMain
                                                className="settingsMain__options"
                                                items={this.getLimits()}
                                                renderItem={this.renderLimitHead}
                                                classNameItem="settingsMain__optionsBlock"
                                                prop="key"
                                                paramsParent={{ width: true }}
                                                styles={['height']}
                                                isNotParamsItem={true}
                                                currentItemKey={currentLimit}
                                                allItems={['month', 'year']}
                                                keyUpdateItem={updatedCorporationKey}
                                            />

                                            <div className="settingsMain__cardContent _dynamic">
                                                <ListAbsoluteMain
                                                    className="settingsMain__options"
                                                    items={this.getLimits()}
                                                    renderItem={this.renderLimit}
                                                    classNameItem="settingsMain__optionsBlock"
                                                    prop="key"
                                                    paramsParent={{ width: true }}
                                                    styles={['height']}
                                                    isNotParamsItem={true}
                                                    currentItemKey={currentLimit}
                                                    allItems={['month', 'year']}
                                                    classNames={isMarginCard ? ['_marginCard'] : []}
                                                    keyUpdateItem={updatedCorporationKey}
                                                />
                                            </div>
                                            {isAdmin &&
                                                !corporation.isAdmin &&
                                                this.getPermissions({
                                                    key: 'corporations',
                                                    items: [
                                                        {
                                                            key: 'main',
                                                            rules: ['update'],
                                                        },
                                                    ],
                                                }) && (
                                                    <div className="settingsMain__cardFoot _col">
                                                        <div
                                                            className="settingsMain__button _row _click"
                                                            onClick={() => {
                                                                handlerAppPopup({
                                                                    name: 'corporationLimitsPopup',
                                                                    isShow: true,
                                                                    corporationId: corporation._id,
                                                                    corporationName:
                                                                        corporation.shortName ||
                                                                        corporation.fullName,
                                                                    limits: corporation.limits,
                                                                    callback: getCorporation,
                                                                });
                                                            }}
                                                        >
                                                            <div className="settingsMain__buttonIcon">
                                                                <Icon name="corporation-limit" />
                                                            </div>
                                                            Редактировать лимиты
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="settingsMain__card _verification _editBack">
                                    <div className="manualCard">
                                        <div className="manualCard__content _fullHeight">
                                            <div
                                                className={`settingsMain__verification _col _${corporation.verification.status}`}
                                            >
                                                <div className="settingsMain__verificationIcon">
                                                    <Icon name="corporation-verify" />
                                                </div>
                                                <AnimateChangeUp
                                                    className="settingsMain__verificationTitle"
                                                    renderKey={corporation.verification.status}
                                                    parentStyles={['height']}
                                                >
                                                    {corporation.verification.status ===
                                                    'active' ? (
                                                        <>Верификация пройдена</>
                                                    ) : (
                                                        <>Верификация не пройдена</>
                                                    )}
                                                </AnimateChangeUp>
                                                <AnimateChangeUp
                                                    className="settingsMain__verificationDescription"
                                                    renderKey={corporation.verification.status}
                                                    parentStyles={['height']}
                                                >
                                                    {corporation.verification.status ===
                                                    'active' ? (
                                                        <>
                                                            {getFormatedDate({
                                                                date: new Date(
                                                                    corporation.verification.date,
                                                                ),
                                                                type: 'full',
                                                            })}
                                                        </>
                                                    ) : corporation.verification.status ===
                                                      'wait' ? (
                                                        <>Корпорация на проверке</>
                                                    ) : (
                                                        <>
                                                            На доработке
                                                            {!isAdmin && (
                                                                <div className="settingsMain__verificationLink">
                                                                    <Link
                                                                        className="settingsMain__button _row _click _detail"
                                                                        pageName={
                                                                            inner
                                                                                ? 'corporations-inner-docs'
                                                                                : 'settings-docs'
                                                                        }
                                                                    >
                                                                        К деталям
                                                                        <div className="settingsMain__buttonIcon">
                                                                            <Icon name="arrow-next-2" />
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </AnimateChangeUp>
                                                {isAdmin && (
                                                    <div
                                                        className="settingsMain__button _row _click"
                                                        onClick={() => {
                                                            handlerAppPopup({
                                                                name: 'logsPopup',
                                                                isShow: true,
                                                                modelName:
                                                                    'corporationVerification',
                                                                models: [
                                                                    {
                                                                        name: 'userVerification',
                                                                        id: corporation.holderId,
                                                                    },
                                                                    {
                                                                        name: 'corporationVerification',
                                                                        id: corporation._id,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    >
                                                        <div className="settingsMain__buttonIcon">
                                                            <Icon name="corporation-history" />
                                                        </div>
                                                        Журнал логов
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div
                            className={`settingsMain__card _main _parentForEdit _editBack ${
                                editName === 'main' ? '_current' : ''
                            }`}
                        >
                            {(corporation.verification.status !== 'active' ||
                                user?.idOfCurrentCorporation === 'admin') &&
                                this.renderEdit({ name: 'main' })}
                            <div className="manualCard">
                                <div className="manualCard__head _row">
                                    <Head title="Юр. данные" description={this.getMainInfo()} />
                                </div>
                                <div className="manualCard__content">
                                    <div className="settingsMain__fieldsWrapper">
                                        {this.renderMain()}
                                        {this.renderSub({
                                            fieldsOrder: [
                                                'kpp',
                                                'fullName',
                                                'bankName',
                                                'corrAccount',
                                                'bankAccount',
                                            ],
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SettingsMain;

SettingsMain.propTypes = {
    corporation: PropTypes.object,
    user: PropTypes.object,
    inner: PropTypes.bool,
    getCorporation: PropTypes.func,
    updatedCorporationKey: PropTypes.any,
};
