import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import BorderDashed from '../BorderDashed.jsx';
import AnimateChange from '../AnimateChange.jsx';

import getUserInfo from '../../functions/getUserInfo';
import Avatar from '../Avatar.jsx';
import handlerSupportCursor from '../../functions/handlerSupportCursor';

class Responsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { _id, firstName, secondName, thirdName, icon, className = '', type } = this.props;
        const name = getUserInfo({
            type: 'name',
            user: {
                firstName,
                secondName,
                thirdName,
            },
        });

        return (
            <div className={`responsible _row _click ${className} ${type ? `_${type}` : ''}`}>
                <AnimateChange
                    className="responsible__inner"
                    prop={_id}
                    type="_translateMedium"
                    isNotParams={true}
                >
                    {!_id ? (
                        <div className="responsible__innerItem _row">
                            <div className="responsible__preview">
                                <div className="responsible__previewBack">
                                    <BorderDashed className="_minSize" rx={8} ry={8} />
                                </div>
                                <i className="responsible__previewIcon">
                                    <Icon name="plus" />
                                </i>
                            </div>
                            {type !== 'short' && (
                                <div className="responsible__content">Ответственный</div>
                            )}
                        </div>
                    ) : (
                        <div className="responsible__innerItem _row _set">
                            <div
                                className="responsible__preview"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: 'Ответственный',
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <Avatar
                                    holder={{
                                        firstName,
                                        secondName,
                                        thirdName,
                                        icon,
                                    }}
                                />
                            </div>

                            {type !== 'short' && <div className="responsible__content">{name}</div>}
                        </div>
                    )}
                </AnimateChange>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Responsible);

Responsible.propTypes = {
    _id: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    thirdName: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
};
