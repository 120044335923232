import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import Animate from '../../Animate.jsx';

import getCurrentPage from '../../../functions/getCurrentPage';

class WidgetMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowList: false,
        };

        this.handlerList = this.handlerList.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    handlerList(isShowList = !this.state.isShowList) {
        this.setState({ isShowList });
    }

    handlerMissClick({ target }) {
        const { isShowList } = this.state;
        const link = this.parent.current.querySelector('.widget__headLinkInner');
        const list = this.parent.current.querySelector('.widget__headLinkList');

        if (
            isShowList &&
            list !== target &&
            !list.contains(target) &&
            link !== target &&
            !link.contains(target)
        ) {
            this.handlerList(false);
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { isShowList = false } = this.state;
        const {
            pagesStore,
            pages = [],
            className = '',
            organizationId,
            ids,
            otherData,
            isActions,
            ...props
        } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => pages.find((pageProps) => pageProps.name === page.name),
        });

        return (
            <div
                ref={this.parent}
                className={`widget__headLink _more ${className}`}
                data-current={currentPage}
                {...props}
            >
                <div
                    className="widget__headLinkInner _click"
                    onClick={() => {
                        this.handlerList();
                    }}
                >
                    Ещё
                    <i className="widget__headLinkMore">
                        <Icon name="more-vertical" />
                    </i>
                </div>
                <Animate
                    className={`widget__headLinkList ${isActions ? '_actions' : ''}`}
                    isShow={isShowList}
                >
                    <div className="widget__headLinkListInner">
                        {pages.map((page) => {
                            const { name, contentOfLink } = page;
                            const resultName = name;
                            const ItemTag = isActions ? 'div' : Link;
                            const itemProps = isActions
                                ? {
                                      onClick: () => {
                                          page.callback();
                                          this.handlerList(false);
                                      },
                                  }
                                : {
                                      pageName: resultName,
                                      callback: () => this.handlerList(false),
                                      ids,
                                  };

                            if (
                                name === 'manual-executors-inner-organization' &&
                                !['OOO', 'corporation'].includes(otherData?.executor?.organization)
                            ) {
                                itemProps.pageName = 'manual-organizations-inner';
                                itemProps.ids = { 3: organizationId };
                            }

                            return (
                                <ItemTag
                                    className={`widget__headLinkListLink _click ${
                                        isActions ? '_actions' : ''
                                    }`}
                                    {...itemProps}
                                    key={resultName}
                                >
                                    {contentOfLink}
                                </ItemTag>
                            );
                        })}
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(WidgetMore);

WidgetMore.propTypes = {
    pages: PropTypes.array,
    pagesStore: PropTypes.object,
    className: PropTypes.string,
    organizationId: PropTypes.string,
    otherData: PropTypes.object,
    isActions: PropTypes.bool,
    ids: PropTypes.object,
};
