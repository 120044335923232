import React from 'react';
import { connect } from 'react-redux';

import Top from '@components/crm/public/Top.jsx';

import About from './components/about/About.tsx';
import Advantages from './components/advantages/Advantages.tsx';
import Cases from './components/cases/Cases.tsx';
import Header from './components/header/Header.tsx';

import BusinessI from './types.ts';

class Business
    extends React.Component<BusinessI['props'], BusinessI['state']>
    implements BusinessI
{
    parent: BusinessI['parent'];

    constructor(props: BusinessI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <>
                <div className="public__pageInner _col">
                    <div className="public__section">
                        <Header />
                    </div>
                    <div className="public__section">
                        <About />
                    </div>
                    <div className="public__section">
                        <Cases />
                    </div>
                    <div className="public__section">
                        <Advantages />
                    </div>
                    <div className="public__section _footer">
                        <Top pageName="business" isFooter={true} />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Business);
