import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import AnimateChange from '../AnimateChange.jsx';
import ServiceCompany from './ServiceCompany.jsx';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';
import getCurrentCorporation from '../../functions/crm/getCurrentCorporation';

const orderServiceCompanies = require('../../infos/crm/orderServiceCompanies.json');

class DetailsServicesCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAlertShow: false,
        };

        this.renderService = this.renderService.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
        this.setInfo = this.setInfo.bind(this);
        this.getSortItems = this.getSortItems.bind(this);
        this.updateInfo = this.updateInfo.bind(this);
        this.setTimer = this.setTimer.bind(this);
        this.filterService = this.filterService.bind(this);
    }

    getItems() {
        const { isInit } = this.state;
        const { order, points, isInner } = this.props;

        if (order) {
            if (
                (points.filter((point) => point.isComplete).length > 1 && order.tariff.id) ||
                isInit ||
                isInner
            ) {
                return [{ key: 'card' }];
            }
        }

        return [];
    }

    getBestService(items) {
        const { order } = this.props;
        const resultItems = items || order?.serviceCompany?.items || [];
        const result = {
            price: Infinity,
            key: null,
        };

        resultItems
            .filter((item) => item.price)
            .forEach((item) => {
                if (+item.price < result.price) {
                    result.price = +item.price;
                    result.key = item.key;
                }
            });

        return result.key;
    }

    renderService({ item, prop: key }) {
        if (key === 'info') {
            return (
                <div className="orderDetailsServiceCompanies__card _info">
                    <div className="empty _col _block">
                        <div className="empty__inner">
                            {item.isEmpty ? (
                                <>
                                    <div className="empty__title">Службы доставки не выбраны</div>
                                    <p className="empty__content">
                                        Для оформления заказа выберите службы
                                        <br />
                                        доставки в настройках системы
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className="empty__title">Это все службы доставки</div>
                                    <p className="empty__content">
                                        Пока это все доступные службы доставки
                                        <br />
                                        для вашего заказа
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        const { order, points, isInner, infoRoute } = this.props;
        const info = order?.serviceCompany?.items.find((innerItem) => innerItem.key === key) || {};
        const isCurrent = order?.serviceCompany?.id === key;

        return (
            <div className={`orderDetailsServiceCompanies__card ${isCurrent ? '_current' : ''}`}>
                <ServiceCompany
                    name={key}
                    setCurrent={(id) => {
                        if (!info.isError) {
                            this.setCurrent(id);
                        }
                    }}
                    setInfo={this.setInfo}
                    isCurrent={isCurrent}
                    price={info.price}
                    duration={info.duration}
                    isError={info.isError}
                    isBest={this.getBestService() === key}
                    isInner={isInner}
                    order={order}
                    points={points}
                    city={infoRoute?.city || order?.city}
                />
            </div>
        );
    }

    getSortItems(items) {
        const { order } = this.props;
        const orderItems = order?.serviceCompany?.items || [];

        return JSON.parse(JSON.stringify(items))
            .sort((a, b) => {
                const weightA = this.getBestService() === a.key ? 1 : 0;
                const weightB = this.getBestService() === b.key ? 1 : 0;

                return weightB - weightA;
            })
            .sort((a, b) => {
                const aPrice = orderItems.find((item) => item.key === a.key)?.price || Infinity;
                const bPrice = orderItems.find((item) => item.key === b.key)?.price || Infinity;

                return aPrice - bPrice;
            });
    }

    setCurrent(id) {
        const { order, changeOrder } = this.props;

        if (order.isNew) {
            changeOrder({
                props: {
                    serviceCompany: {
                        ...order.serviceCompany,
                        id,
                    },
                },
            });
        }
    }

    setInfo({ name, price, duration, isError, serviceOtherInfo = {} }) {
        const { order, changeOrder } = this.props;
        const items = JSON.parse(JSON.stringify(order.serviceCompany.items));
        let { id } = order.serviceCompany;
        let item = items.find((loopItem) => loopItem.key === name);

        if (!item) {
            items.push({ key: name });
        }

        item = items.find((loopItem) => loopItem.key === name);

        item.price = price;
        item.duration = duration;

        if (id !== this.getBestService(items)) {
            id = this.getBestService(items);
        }

        item.isError = isError;

        return new Promise((resolve) => {
            changeOrder({
                props: {
                    serviceCompany: {
                        ...order.serviceCompany,
                        id,
                        items,
                    },
                    serviceOtherInfo: {
                        ...(order.serviceOtherInfo || {}),
                        ...serviceOtherInfo,
                    },
                },
            }).then(() => {
                this.setState(
                    {
                        updateKey: new Date().getTime(),
                    },
                    resolve,
                );
            });
        });
    }

    filterService({ key }, isAll) {
        const { order, isInner } = this.props;
        const item = order?.serviceCompany.items.find((loopItem) => loopItem.key === key);

        return (isAll || !item || item?.isError !== true) && (isInner ? +item?.price > 0 : true);
    }

    getServices(isAll) {
        const { user, isInner } = this.props;
        const currentCorporation = getCurrentCorporation({ user });
        const { serviceOrder } = currentCorporation;
        const { items } = serviceOrder;
        const resultServiceCompanies = orderServiceCompanies.filter((company) =>
            items.find((item) => item.key === company.key),
        );

        return [
            ...this.getSortItems(resultServiceCompanies).filter((item) =>
                this.filterService(item, isAll),
            ),
            ...((items.length % 5 !== 0 || items.length === 0) && !isInner
                ? [{ key: 'info', isEmpty: items.length === 0 }]
                : []),
        ];
    }

    renderContent() {
        const { updateKey, isAlertShow } = this.state;
        const { order } = this.props;
        const status = order?.serviceCompany.id
            ? orderServiceCompanies.find((item) => item.key === order?.serviceCompany.id)?.name
            : 'Идёт поиск';

        return (
            <div className="orderDetailsServiceCompanies__item">
                <div className="orderDetailsCard _serviceCompanies">
                    <div className="orderDetailsCard__inner">
                        <div className="orderDetailsCard__head _col">
                            <div className="orderDetailsCard__title">
                                Результаты подбора
                                <Animate
                                    className="orderDetailsServiceCompanies__alert"
                                    isShow={isAlertShow}
                                >
                                    <div className="orderDetailsServiceCompanies__alertInfo _windowWithCursor _bottomLeft _col">
                                        <i className="orderDetailsServiceCompanies__alertInfoIcon">
                                            <Icon name="alert" />
                                        </i>
                                        <p className="orderDetailsServiceCompanies__alertInfoTitle">
                                            Цены могли измениться
                                        </p>
                                        <p className="orderDetailsServiceCompanies__alertInfoDescription">
                                            С момента последнего обновления прошло много времени —
                                            цены на вашу перевозку могли измениться
                                        </p>
                                        <div
                                            className="orderDetailsServiceCompanies__alertInfoBtn _click"
                                            onClick={this.updateInfo}
                                        >
                                            Обновить цены
                                        </div>
                                    </div>
                                </Animate>
                            </div>
                            <div className="orderDetailsCard__description _row">
                                Выбран:
                                <AnimateChange
                                    className="orderDetailsCard__descriptionItem"
                                    prop={status}
                                    type="_translateMedium"
                                >
                                    <>{status}</>
                                </AnimateChange>
                            </div>
                            {/* <div className="orderDetailsServiceCompanies__autoSearch _row">
                                <div className="orderDetailsServiceCompanies__autoSearchSwitch">
                                    <Switch
                                        className="_size3"
                                        value={order?.serviceCompany.isAutoSearch}
                                        handler={({ value }) => {
                                            changeOrder({
                                                props: {
                                                    serviceCompany: {
                                                        ...order.serviceCompany,
                                                        isAutoSearch: value,
                                                    },
                                                },
                                            });
                                        }}
                                        isDisabled={isDisabled}
                                    />
                                </div>
                                <p className="orderDetailsServiceCompanies__autoSearchName">
                                    Автозамена
                                </p>
                                <div className="orderDetailsServiceCompanies__autoSearchInfo">
                                    <i className="orderDetailsServiceCompanies__autoSearchInfoIcon _click">
                                        <Icon name="info" />
                                    </i>
                                    <div
                                        className={`orderDetailsServiceCompanies__autoSearchInfoWindow _windowWithCursor _col ${
                                            isInner ? '_topRight' : '_bottomRight'
                                        }`}
                                    >
                                        <div className="orderDetailsServiceCompanies__autoSearchInfoWindowTitle">
                                            Автозамена
                                            <br />
                                            службы доставки
                                        </div>
                                        <p className="orderDetailsServiceCompanies__autoSearchInfoWindowDescription">
                                            Если в течение 15 минут подходящий исполнитель
                                            не&nbsp;будет найден — система автоматически выберет
                                            другого
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="orderDetailsCard__content">
                            <ListAbsoluteMain
                                className="orderDetailsServiceCompanies__cards"
                                items={this.getServices(true)}
                                renderItem={this.renderService}
                                classNameItem="orderDetailsServiceCompanies__card"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                itemParams={['offsetLeft', 'offsetTop', 'width', 'height']}
                                sort={this.getSortItems}
                                propsForUpdate={['price', 'duration', 'isBest', 'isCurrent']}
                                keyRender={`${this.getBestService()}${
                                    order?.serviceCompany?.id
                                }${updateKey}`}
                                callback={() => {
                                    setTimeout(() => {
                                        this.setState({ updateKey: new Date().getTime() });
                                    }, 10);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.timerId = setTimeout(() => {
            this.setState({ isAlertShow: true });
        }, 60_000);
    }

    updateInfo() {
        if (this.getItems().length === 1) {
            document.dispatchEvent(new CustomEvent('serviceCompanyUpdateInfo'));

            // console.log('update');

            if (this.timerId) {
                clearTimeout(this.timerId);
            }

            this.setState({ isAlertShow: false });
        }
    }

    saveOrder = {};

    checkChange() {
        const { isInit } = this.state;
        const { order, points } = this.props;
        let isServicesChange = false;
        let isCargoChange = false;

        const cargo = points
            ?.reduce((prev, cur) => prev.concat(...cur.cargo), [])
            .reduce((prev, cur) => prev.concat(...cur.items), [])
            .filter((item) => item.counter > 0)
            .map((item) => ({
                long: item.long,
                width: item.width,
                height: item.height,
                weight: item.weight,
                counter: item.counter,
            }));

        if (this.saveOrder.cargo) {
            if (this.saveOrder.cargo.length !== cargo.length) {
                isCargoChange = true;
            } else {
                cargo.forEach((item, key) => {
                    const saveItem = this.saveOrder.cargo[key];

                    ['long', 'width', 'height', 'weight', 'counter'].forEach((prop) => {
                        if (item[prop] !== saveItem[prop]) {
                            isCargoChange = true;
                        }
                    });
                });
            }
        }

        if (order?.services) {
            if (order.services.length && !this.saveOrder.services) {
                isServicesChange = true;
            } else {
                order?.services.forEach((service) => {
                    if (service.key === 'workers') {
                        const saveService = this.saveOrder.services.find(
                            (loopService) => loopService.key === service.key,
                        );

                        if (!saveService && service.value) {
                            isServicesChange = true;
                        } else if (
                            (service.value && service.value !== saveService?.value) ||
                            (!service.value && saveService?.value)
                        ) {
                            isServicesChange = true;
                        }
                    }
                });

                this.saveOrder.services?.forEach((saveService) => {
                    if (saveService.key === 'workers') {
                        const service = order?.services.find(
                            (loopService) => loopService.key === saveService.key,
                        );

                        if (!service) {
                            isServicesChange = true;
                        }
                    }
                });
            }
        }

        if (
            order &&
            points?.every((point) => point.coords) &&
            (order?.tariff.id !== this.saveOrder.tariffId ||
                (this.saveOrder.route && points.length !== this.saveOrder.route.length) ||
                !!points?.find(
                    (point, key) =>
                        !this.saveOrder.route?.[key] ||
                        !!['first', 'second'].find(
                            (item, coordKey) =>
                                this.saveOrder.route?.[key]?.coords?.[coordKey] !==
                                point.coords?.[coordKey],
                        ),
                ) ||
                isServicesChange ||
                isCargoChange)
        ) {
            this.saveOrder.tariffId = order?.tariff.id;
            this.saveOrder.dateOfOrder = order?.dateOfOrder;
            this.saveOrder.route = (points ? JSON.parse(JSON.stringify(points)) : []).map(
                (point) => ({ coords: point.coords }),
            );
            this.saveOrder.services = order.services;
            this.saveOrder.cargo = cargo;

            if (isInit) {
                this.updateInfo();
            }
        }
    }

    componentDidMount() {
        this.checkChange();

        document.addEventListener('serviceCompanySetInfo', this.setTimer);
    }

    componentDidUpdate() {
        this.checkChange();
    }

    componentWillUnmount() {
        document.removeEventListener('serviceCompanySetInfo', this.setTimer);
    }

    render() {
        const { updateKey } = this.state;
        const { order, scrollToCard, isInner, setHeightPage } = this.props;

        return (
            <div className={`orderDetailsServiceCompanies ${isInner ? '_inner' : ''}`}>
                <ListAbsoluteMain
                    className={`orderDetailsServiceCompanies__inner ${isInner ? '_inner' : ''}`}
                    items={this.getItems()}
                    renderItem={this.renderContent}
                    classNameItem="orderDetailsServiceCompanies__item"
                    prop="key"
                    paramsParent={{ width: true, height: 'auto' }}
                    styles={['height']}
                    keyRender={`${this.getBestService()}${order?.serviceCompany?.id}${updateKey}${
                        this.getServices().length
                    }`}
                    callback={() => {
                        if (this.getItems().length === 1 && !this.state.isInit && !isInner) {
                            this.setState({ isInit: true }, () => {
                                scrollToCard('servicesCompanies');
                            });
                        }

                        if (setHeightPage) {
                            setHeightPage();
                        }
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(DetailsServicesCompanies);

DetailsServicesCompanies.propTypes = {
    order: PropTypes.object,
    points: PropTypes.array,
    changeOrder: PropTypes.func,
    scrollToCard: PropTypes.func,
    isInner: PropTypes.bool,
    setHeightPage: PropTypes.func,
    isDisabled: PropTypes.bool,
    infoRoute: PropTypes.object,
    user: PropTypes.object,
};
