import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

import MainPreview from '../card/car/MainPreview.jsx';

import Icon from '../../../Icon.jsx';
import Link from '../../../Link.jsx';
import ListAbsolute from '../../../ListAbsolute.jsx';
import Animate from '../../../Animate.jsx';
import Loader from '../../../Loader.jsx';

class ManualCarsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderAction = this.renderAction.bind(this);
        this.filterAction = this.filterAction.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.sortActions = this.sortActions.bind(this);
        this.getSortActions = this.getSortActions.bind(this);

        this.parent = React.createRef();
    }

    fields = [
        { name: 'type', isReadOnly: !this.props.isNew },
        { name: 'vin' },
        { name: 'perm-mass' },
        { name: 'status' },
        { name: 'color' },
        { name: 'full-mass' },
        { name: 'sts' },
        { name: 'year' },
        { name: 'diff-mass', isReadOnly: true },
    ];

    actions = [
        {
            key: 'set-current',
            order: 0,
        },
        {
            key: 'delete',
            order: 2,
        },
        {
            key: 'link',
            order: 1,
        },
    ];

    filterAction({ key }) {
        const { car = {}, executor = {}, checkEditmode } = this.props;

        return checkEditmode()
            ? key === 'delete'
            : key === 'link' || (key === 'set-current' && executor.car !== car._id);
    }

    getDescriptionInfo() {
        const { car, modelsOfCar, marksOfCar } = this.props;

        if (car) {
            let description = '';

            if (marksOfCar && car.mark) {
                const mark = marksOfCar.find((markLoop) => markLoop._id === car.mark);

                description += mark?.name;
            }

            if (modelsOfCar && car.model) {
                const model = modelsOfCar.find((modelLoop) => modelLoop._id === car.model);

                if (description) {
                    description += ', ';
                }

                description += model?.name;
            }

            if (description) {
                return {
                    description,
                };
            }
        }

        return {
            description: '–',
        };
    }

    setParamsParent(params = {}) {
        const { width: widthActions } = params;

        if (widthActions !== this.state.widthActions) {
            this.setState({ widthActions });
        }
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    setCarCurrent() {
        const { setCarCurrent, car } = this.props;

        this.handlerLoadingKey('current').then(() => {
            setCarCurrent({ idOfCar: car._id }).then(
                () => {
                    this.handlerLoadingKey(null);
                },
                () => null,
            );
        });
    }

    deleteCar() {
        const { deleteCar, car } = this.props;

        this.handlerLoadingKey('delete').then(() => {
            deleteCar({ idOfCar: car._id }).then(
                () => {
                    this.handlerLoadingKey(null);
                },
                () => null,
            );
        });
    }

    sortActions(a, b) {
        return a.order - b.order;
    }

    getSortActions(actions) {
        return JSON.parse(JSON.stringify(actions)).sort(this.sortActions);
    }

    renderAction({ prop: key, key: order, isShow, params }) {
        const { loadingKey } = this.state;
        const { car = {} } = this.props;

        return (
            <div
                className={`manualCard__headAction ${isShow ? '_show' : ''}`}
                data-key={key}
                key={key}
                style={{
                    transform: `translate(${-params?.offsetRight}px,0)`,
                    order,
                }}
            >
                {key === 'set-current' && (
                    <div className="manualCard__headLink _row _click" onClick={() => this.setCarCurrent()}>
                        <Animate
                            className="manualCard__headLinkLoader _loader"
                            isShow={loadingKey === 'current'}
                        >
                            <i className="manualCard__headLinkLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </i>
                        </Animate>
                        Сделать основным
                        <i className="manualCard__headLinkIcon _best">
                            <Icon name="best" />
                        </i>
                    </div>
                )}
                {key === 'link' && (
                    <Link
                        pageName="manual-cars-inner"
                        className="manualCard__headLink _row"
                        ids={{ 3: car._id }}
                    >
                        На страницу
                        <i className="manualCard__headLinkIcon">
                            <Icon name="arrow-next" />
                        </i>
                    </Link>
                )}
                {key === 'delete' && (
                    <div className="manualCard__headLink _row _click _alert" onClick={() => this.deleteCar()}>
                        <Animate
                            className="manualCard__headLinkLoader _loader"
                            isShow={loadingKey === 'delete'}
                        >
                            <i className="manualCard__headLinkLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </i>
                        </Animate>
                        Отвязать машину
                    </div>
                )}
            </div>
        );
    }

    componentDidMount() {
        this.setState({ isUpdate: true });
    }

    render() {
        const { widthActions = 0 } = this.state;
        const {
            type,
            title,
            executor = {},
            car = {},
            checkEditmode,
            getParent,
            changeCar,
            modelsOfCar,
            marksOfCar,
            errors = [],
            setStateLoader,
            stateLoader,
            forCarExecutorId,
            forTrailerCarId,
        } = this.props;
        const { description } = this.getDescriptionInfo();
        const parentList = this.parent.current?.querySelector('.manualCard__headActions');
        const actionsFilter = this.actions.filter(this.filterAction);

        return (
            <div ref={this.parent} className="manualCard _carMain">
                <div className="manualCard__head _row">
                    <Head title={title} description={description}>
                        <Animate
                            className="manualCard__headBest"
                            isShow={modelsOfCar && marksOfCar && executor.car === car._id}
                        >
                            <Icon name="best" />
                        </Animate>
                    </Head>
                    {type === 'preview' && (
                        <div className="manualCard__headInfo">
                            <div
                                className="manualCard__headActions"
                                style={{ width: `${widthActions}px` }}
                            >
                                <ListAbsolute
                                    parent={parentList}
                                    items={actionsFilter}
                                    renderItem={this.renderAction}
                                    classNameItem="manualCard__headAction"
                                    prop="key"
                                    setParamsParent={this.setParamsParent}
                                    sort={this.getSortActions}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__main _row">
                        <div className="manualCard__mainItem _preview">
                            <MainPreview
                                car={car}
                                handler={changeCar}
                                isDisabled={!checkEditmode() || type === 'preview'}
                                getParent={getParent}
                                modelsOfCar={modelsOfCar}
                                marksOfCar={marksOfCar}
                                errors={errors}
                                setStateLoader={setStateLoader}
                                stateLoader={stateLoader}
                            />
                        </div>
                        <div className="manualCard__mainItem _fields">
                            <div className="manualCard__fields _row">
                                {this.fields.map((field) => {
                                    const { name } = field;
                                    let { isReadOnly } = field;
                                    let { [name]: value } = car;

                                    if (
                                        name === 'diff-mass' &&
                                        car['perm-mass'] &&
                                        car['full-mass']
                                    ) {
                                        value = car['perm-mass'] - car['full-mass'];
                                    }

                                    if (name === 'type') {
                                        isReadOnly = !this.props.isNew;
                                    }

                                    if ((forCarExecutorId || forTrailerCarId) && name === 'type') {
                                        isReadOnly = true;
                                    }

                                    return (
                                        <div className={`manualCard__field _${name}`} key={name}>
                                            <Field
                                                value={value}
                                                type="car"
                                                name={name}
                                                handler={changeCar}
                                                group="carMain"
                                                isDisabled={!checkEditmode() || type === 'preview'}
                                                isReadOnly={isReadOnly}
                                                isError={errors.indexOf(name) !== -1}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forCarExecutorId: state.forCarExecutorId,
        forTrailerCarId: state.forTrailerCarId,
    };
}

export default connect(mapStateToProps)(ManualCarsMain);

ManualCarsMain.propTypes = {
    type: PropTypes.string,
    executor: PropTypes.object,
    car: PropTypes.object,
    title: PropTypes.string,
    changeExecutor: PropTypes.func,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    getParent: PropTypes.func,
    changeCar: PropTypes.func,
    modelsOfCar: PropTypes.array,
    marksOfCar: PropTypes.array,
    deleteCar: PropTypes.func,
    setCarCurrent: PropTypes.func,
    errors: PropTypes.array,
    setStateLoader: PropTypes.func,
    stateLoader: PropTypes.any,
    forCarExecutorId: PropTypes.string,
    forTrailerCarId: PropTypes.string,
};
