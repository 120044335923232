import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import handlerCarForExecutor from '../../../requests/handlerCarForExecutor';

import Main from './executors/Main.jsx';
import Inner from './executors/Inner.jsx';

import Pages from '../../../components/Pages.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';
import setAnimate from '../../../functions/setAnimate';

import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import HeadInner from './executors/HeadInner.jsx';
import Windows from '../../../components/Windows.jsx';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import WindowList from '../../../components/WindowList.jsx';
import Filter from '../../../components/Filter.jsx';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import getFilter from '../../../requests/getFilter';

import getQueryFilter from '../../../functions/getQueryFilter';
import deletePaysItems from '../../../requests/deletePaysItems';

import getHeaders from '../../../functions/getHeaders';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';

import PaysMainTemplate from '../../../components/crm/pays/Main.jsx';
import setPermissions from '../../../functions/crm/setPermissions';
import setContractsParent from '../../../functions/crm/setContractsParent';
import getPageLink from '../../../functions/getPageLink';

class ManualExecutors extends PaysMainTemplate {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.renderWindowsPromptDelete = this.renderWindowsPromptDelete.bind(this);
        this.renderWindowsPromptDeleteDoc = this.renderWindowsPromptDeleteDoc.bind(this);
        this.renderWindowsListCars = this.renderWindowsListCars.bind(this);
        this.renderWindowsPromptPays = this.renderWindowsPromptPays.bind(this);
        this.renderPaysFilter = this.renderPaysFilter.bind(this);
        this.delete = this.delete.bind(this);
        this.renderWindowsDeleteInfo = this.renderWindowsDeleteInfo.bind(this);

        this.renderFilter = this.renderFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.initCallbackPaysFilter = this.initCallbackPaysFilter.bind(this);

        this.setFilter = this.setFilter.bind(this);

        this.scrollToTop = this.scrollToTop.bind(this);

        setPermissions.call(this);
        setContractsParent.call(this);

        this.parent = React.createRef();
    }

    headPages = {
        'manual-executors-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Исполнители{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'manual-executors-inner': {
            render() {
                const { infoHead } = this.state;
                const { executor = {} } = infoHead;
                const { id, isNew } = executor;

                return (
                    <div className="widget__headNameItemContent">
                        Исполнитель
                        <AnimateChange
                            prop={`${id}${isNew}`}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {id ? `ID${id}` : isNew ? ' новый' : '–'}
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfExecutor = levels[3];

        if (levels[1] === 'manual' && levels[2] === 'executors' && idOfExecutor !== 'main') {
            this.isNew = idOfExecutor === 'new';
        }

        return this.isNew;
    }

    headActions = {
        'manual-executors-main': {
            render() {
                const { filter, infoHead } = this.state;
                const { counter = 0 } = infoHead;
                const filterQuery = getQueryFilter({ filter });

                return (
                    <TableHead
                        name="executors"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        counter={counter}
                        filterQuery={filterQuery}
                        checkRights={() =>
                            this.getPermissions({
                                key: 'manual',
                                items: [
                                    {
                                        key: 'executors',
                                        rules: ['create'],
                                    },
                                ],
                            })
                        }
                    />
                );
            },
        },
        'manual-executors-inner': {
            render() {
                const { infoHead } = this.state;
                const { executor = {} } = infoHead;
                const { balance, type } = executor;

                return (
                    <HeadInner
                        balance={balance}
                        isNew={this.checkNew()}
                        type={type}
                        executor={executor}
                        delete={this.delete}
                    />
                );
            },
        },
    };

    pages = {
        'manual-executors-main': {
            render() {
                const { filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-executors-inner': {
            render() {
                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner _notPadding">
                            <Inner
                                setHeightPage={this.setHeightPage}
                                setInfoHead={this.setInfoHead}
                                getParent={() => this.parent.current}
                                checkNew={this.checkNew}
                                scrollToTop={this.scrollToTop}
                                initCallbackPaysFilter={this.initCallbackPaysFilter}
                                initCallbackFilter={this.initCallbackFilter}
                            />
                        </div>
                    </div>
                );
            },
        },
    };

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    initCallbackPaysFilter(callbackFilter) {
        this.callbackPaysFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'executors' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    scrollToTop() {
        const pageCurrent = this.parent.current.querySelector('.widget__page._show');
        const pageBoxCurrent = pageCurrent.querySelector('.widget__pageBox._scroll');
        const { scrollTop } = pageBoxCurrent;

        if (pageBoxCurrent) {
            setAnimate({
                draw: (progress) => {
                    pageBoxCurrent.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    renderWindowsListCars({ idOfExecutor, cars }) {
        return (
            <WindowList
                name="cars"
                className="_topCenter"
                query={cars.map((car) => ({ key: 'itemsNotResponse', value: car }))}
                callback={({ id: idOfCar }) =>
                    new Promise((resolve) => {
                        handlerCarForExecutor({ idOfExecutor, idOfCar }).then(
                            () => {
                                handlerWindow({
                                    action: 'hide',
                                    name: 'listCars',
                                });
                                resolve();
                            },
                            () => null,
                        );
                    })
                }
                idOfExecutor={idOfExecutor}
            />
        );
    }

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { executor } = infoHead;
        const id = executor?._id;
        const windowId = `delete-executor-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/executor?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;
                            const { message } = data;

                            const windowName =
                                message === 'You dont delete this model'
                                    ? 'deleteExecutorInfo'
                                    : 'promptDelete';

                            if (!isForce) {
                                if (message === 'You dont delete this model' || success) {
                                    handlerWindow({
                                        parent: this.parent.current,
                                        parentResize: this.parent.current,
                                        target: this.parent.current.querySelector(
                                            '.widget__headLink._delete',
                                        ),
                                        action: 'show',
                                        name: windowName,
                                        className: '_prompt _center',
                                        uniqKey: windowId,
                                        watchesProps: { left: true },
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });
                            }

                            if (success) {
                                if (isForce) {
                                    setNotification({ notification: 'success-change-info' });
                                }

                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            document.dispatchEvent(
                                new CustomEvent('changeNavCounter', {
                                    detail: { name: 'joins-invites' },
                                }),
                            );

                            changePage({
                                href: getPageLink({
                                    name: 'manual-executors-main',
                                }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    renderWindowsDeleteInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteExecutorInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    renderWindowsPromptDeleteDoc({ deleteDoc }) {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteDoc"
                callback={({ hide, handlerLoader }) => {
                    deleteDoc();
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    renderWindowsPromptPays({ payId, items, successDelete }) {
        const deleteItems = ({ hide, handlerLoader }) => {
            deletePaysItems({ id: payId, items }).then(
                () => {
                    if (successDelete) {
                        successDelete();
                    }

                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return <WindowPrompt className="_topCenter" callback={deleteItems} name="delete" />;
    }

    renderDeleteWallet({ deleteWallet }) {
        const callback = ({ hide, handlerLoader }) => {
            deleteWallet().then(
                () => {
                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return <WindowPrompt className="_bottomRight" callback={callback} name="delete" />;
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderPaysFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackPaysFilter}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: `filter-executors`, filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="paysFilter" renderContent={this.renderPaysFilter} />
                <Windows name="listCars" renderContent={this.renderWindowsListCars} />
                <Windows name="promptDelete" renderContent={this.renderWindowsPromptDelete} />
                <Windows name="promptDeleteDoc" renderContent={this.renderWindowsPromptDeleteDoc} />
                <Windows name="promptDeletePays" renderContent={this.renderWindowsPromptPays} />
                <Windows name="deleteExecutorInfo" renderContent={this.renderWindowsDeleteInfo} />
                <Windows name="editActions" renderContent={this.renderWindowsEditActions} />
                <Windows name="contractActions" renderContent={this.renderContractActions} />
                <Windows name="contractPrompt" renderContent={this.renderContractPrompt} />
                <Windows name="deleteWallet" renderContent={this.renderDeleteWallet.bind(this)} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Справочник"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-executors' && page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-executors' && page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === 'manual-executors' && page.level === 3
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualExecutors);

ManualExecutors.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    windows: PropTypes.object,
};
