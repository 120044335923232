import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import Main from './cars/Main.jsx';
import HeadInner from './cars/HeadInner.jsx';
import Inner from './cars/Inner.jsx';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Windows from '../../../components/Windows.jsx';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import Filter from '../../../components/Filter.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';

import ListBodyworks from '../../../components/crm/manual/card/car/ListBodyworks.jsx';
import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import setAnimate from '../../../functions/setAnimate';
import WindowList from '../../../components/WindowList.jsx';
import handlerTrailerForCar from '../../../requests/handlerTrailerForCar';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';
import getHeaders from '../../../functions/getHeaders';
import setPermissions from '../../../functions/crm/setPermissions';
import getPageLink from '../../../functions/getPageLink';

class ManualCars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.renderListBodyworks = this.renderListBodyworks.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.renderWindowsListCars = this.renderWindowsListCars.bind(this);
        this.renderWindowsPromptDelete = this.renderWindowsPromptDelete.bind(this);
        this.renderWindowsDeleteInfo = this.renderWindowsDeleteInfo.bind(this);
        this.renderWindowsDeleteTrailerInfo = this.renderWindowsDeleteTrailerInfo.bind(this);

        this.delete = this.delete.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    headPages = {
        'manual-cars-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Автомобили{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'manual-cars-inner': {
            render() {
                const { infoHead } = this.state;
                const { car = {} } = infoHead;
                const { id, isNew } = car;

                return (
                    <div className="widget__headNameItemContent">
                        Автомобиль
                        <AnimateChange
                            prop={`${id}${isNew}`}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {id ? `ID${id}` : isNew ? ' новый' : '–'}
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    headActions = {
        'manual-cars-main': {
            render() {
                const { filter } = this.state;

                return (
                    <TableHead
                        name="cars"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        checkRights={() =>
                            this.getPermissions({
                                key: 'manual',
                                items: [
                                    {
                                        key: 'cars',
                                        rules: ['create'],
                                    },
                                ],
                            })
                        }
                    />
                );
            },
        },
        'manual-cars-inner': {
            render() {
                const { infoHead } = this.state;

                const { car } = infoHead;

                return <HeadInner isNew={this.checkNew()} car={car} delete={this.delete} />;
            },
        },
    };

    pages = {
        'manual-cars-main': {
            render() {
                const { filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-cars-inner': {
            render() {
                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner _notPadding">
                            <Inner
                                setHeightPage={this.setHeightPage}
                                setInfoHead={this.setInfoHead}
                                getParent={() => this.parent.current}
                                checkNew={this.checkNew}
                                scrollToTop={this.scrollToTop}
                            />
                        </div>
                    </div>
                );
            },
        },
    };

    scrollToTop() {
        const pageCurrent = this.parent.current.querySelector('.widget__page._show');
        const pageBoxCurrent = pageCurrent.querySelector('.widget__pageBox._scroll');
        const { scrollTop } = pageBoxCurrent;

        if (pageBoxCurrent) {
            setAnimate({
                draw: (progress) => {
                    pageBoxCurrent.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'cars' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfCar = levels[3];

        if (levels[1] === 'manual' && levels[2] === 'cars' && idOfCar !== 'main') {
            this.isNew = idOfCar === 'new';
        }

        return this.isNew;
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    renderListBodyworks({ handler, bodyworkDefault }) {
        return <ListBodyworks handler={handler} bodyworkDefault={bodyworkDefault} />;
    }

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { car } = infoHead;
        const id = car?._id;
        const windowId = `delete-car-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/car?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;
                            const { message } = data;

                            const windowName =
                                message === 'You dont delete this model'
                                    ? car.type === 'car'
                                        ? 'deleteCarInfo'
                                        : 'deleteTrailerInfo'
                                    : 'promptDelete';

                            if (!isForce) {
                                if (message === 'You dont delete this model' || success) {
                                    handlerWindow({
                                        parent: this.parent.current,
                                        parentResize: this.parent.current,
                                        target: this.parent.current.querySelector(
                                            '.widget__headLink._delete',
                                        ),
                                        action: 'show',
                                        name: windowName,
                                        className: '_prompt _center',
                                        uniqKey: windowId,
                                        watchesProps: { left: true },
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });
                            }

                            if (success) {
                                if (isForce) {
                                    setNotification({ notification: 'success-change-info' });
                                }

                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            changePage({
                                href: getPageLink({
                                    name: 'manual-cars-main',
                                }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    renderWindowsDeleteInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteCarInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    renderWindowsDeleteTrailerInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteTrailerInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    renderWindowsPromptDeleteDoc({ deleteDoc }) {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteDoc"
                callback={({ hide, handlerLoader }) => {
                    deleteDoc();
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    renderWindowsListCars({ idOfCar, cars }) {
        return (
            <WindowList
                name="trailers"
                className="_topCenter"
                query={cars.map((car) => ({ key: 'itemsNotResponse', value: car }))}
                callback={({ id: idOfTrailer }) =>
                    new Promise((resolve) => {
                        handlerTrailerForCar({ idOfCar, idOfTrailer }).then(() => {
                            handlerWindow({
                                action: 'hide',
                                name: 'listCars',
                            });
                            resolve();
                        });
                    })
                }
                idOfCar={idOfCar}
            />
        );
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: `filter-cars`, filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="promptDeleteDoc" renderContent={this.renderWindowsPromptDeleteDoc} />
                <Windows name="listTrailers" renderContent={this.renderWindowsListCars} />
                <Windows name="listBodyworks" renderContent={this.renderListBodyworks} />
                <Windows name="promptDelete" renderContent={this.renderWindowsPromptDelete} />
                <Windows name="deleteCarInfo" renderContent={this.renderWindowsDeleteInfo} />
                <Windows
                    name="deleteTrailerInfo"
                    renderContent={this.renderWindowsDeleteTrailerInfo}
                />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Справочник"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-cars' && page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-cars' && page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) => page.parentName === 'manual-cars' && page.level === 3}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualCars);

ManualCars.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    windows: PropTypes.object,
};
