import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getCargoList() {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/google-vision?type=listWords`, {
                headers: getHeaders(),
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success) {
                        const { list: cargoList = [] } = data;

                        resolve({ cargoList });
                    } else {
                        handlerErrorRequest(res);

                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
