import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';

class ArrowBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    icons = {
        default: {
            prev: 'arrow-prev',
            next: 'arrow-next',
        },
        short: {
            prev: 'arrow-prev-short',
            next: 'arrow-next-short',
        },
    };

    render() {
        const { callback, name, type = 'default' } = this.props;
        const icon = this.icons[type][name];

        return (
            <div
                className={`arrowBtn _click _${type} _${name}`}
                onClick={() => {
                    if (callback && typeof callback === 'function') {
                        callback(name);
                    }
                }}
            >
                <div className="arrowBtn__icon">
                    <Icon name={icon} />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ArrowBtn);

ArrowBtn.propTypes = {
    callback: PropTypes.func,
    name: PropTypes.string,
    type: PropTypes.string,
};
