import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getTariffs from '../../../../requests/getTariffs';
import getModel from '../../../../requests/getModel';

import Main from './inner/Main.jsx';
import Logs from './inner/Logs.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';
import setServerData from '../../../../functions/setServerData';
import Inner from '../../../../components/crm/manual/Inner.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class ManualTariffsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {
            infoConfigs: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getTariff = this.getTariff.bind(this);
        this.setTariff = this.setTariff.bind(this);

        this.initTariff = this.initTariff.bind(this);
        this.changeTariff = this.changeTariff.bind(this);

        this.handlerConfiguration = this.handlerConfiguration.bind(this);
        this.handlerConfigurationItems = this.handlerConfigurationItems.bind(this);
        this.handlerConfigurationItem = this.handlerConfigurationItem.bind(this);
        this.setCurrentItem = this.setCurrentItem.bind(this);
        this.addConfiguration = this.addConfiguration.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    mainPage = 'manual-tariffs';

    pages = {
        'manual-tariffs-inner-main': {
            render() {
                const { isShowLoaderList, tariff, tariffSave, isInitShow, infoConfigs } =
                    this.state;
                const { getParent } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Main
                                    tariff={tariff}
                                    tariffSave={tariffSave}
                                    setHeightPage={this.setHeightPage}
                                    parent={getParent()}
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._manual-tariffs-inner-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    initTariff={this.initTariff}
                                    isInit={isInitShow}
                                    handlerConfigurationItem={this.handlerConfigurationItem}
                                    handlerConfigurationItems={this.handlerConfigurationItems}
                                    handlerLoaderList={this.handlerLoaderList}
                                    addConfiguration={this.addConfiguration}
                                    handlerConfiguration={this.handlerConfiguration}
                                    setCurrentItem={this.setCurrentItem}
                                    infoConfigs={infoConfigs}
                                    setTariff={this.setTariff}
                                    changeTariff={this.changeTariff}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'manual-tariffs-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, tariff } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    tariff={tariff}
                                    handlerLoaderList={this.handlerLoaderList}
                                    initTariff={this.initTariff}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    initTariff() {
        const { tariff } = this.state;
        const { setInfoHead } = this.props;

        this.setState({ isInitShow: true }, () => {
            this.setHeightPage();

            if (this.removeTransition) {
                this.removeTransition();
            }

            setInfoHead({ key: 'tariff', value: tariff });
        });
    }

    addConfiguration() {
        return new Promise((resolve) => {
            getModel({ name: 'tariffConfig', type: 'schema' }).then(({ model: config }) => {
                getModel({ name: 'tariffConfigItem', type: 'schema' }).then(({ model: item }) => {
                    this.setState(
                        (state) => {
                            const newState = { ...state };
                            const tariff = JSON.parse(JSON.stringify(newState.tariff));
                            const configNew = { ...config, isNew: true };

                            if (tariff.categories.length === 1) {
                                const [categoryInfo] = tariff.categories;

                                configNew.category = categoryInfo.key;
                                configNew.type = categoryInfo.type;
                                configNew.name = '0.0';

                                if (categoryInfo.key === 'workers') {
                                    configNew.name = 'Грузчик';
                                    configNew.items = [
                                        {
                                            ...item,
                                            type: 'worker',
                                            typeConfig: 'worker',
                                        },
                                    ];
                                    configNew.tabs = [];
                                }

                                if (categoryInfo.key === 'couriers') {
                                    configNew.name = 'Курьер';
                                    configNew.items = [
                                        {
                                            ...item,
                                            type: 'courier',
                                            typeConfig: 'courier',
                                        },
                                    ];
                                    configNew.tabs = [];
                                }
                            }

                            tariff.configurations.push(configNew);

                            newState.tariff = tariff;

                            return newState;
                        },
                        () => {
                            resolve(config._id);
                        },
                    );
                });
            });
        });
    }

    setCurrentItem({ id, key }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const infoConfigs = JSON.parse(JSON.stringify(newState.infoConfigs));

                infoConfigs[id] = key;

                newState.infoConfigs = infoConfigs;

                return newState;
            }, resolve);
        });
    }

    changeTariff({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change' || !action) {
                this.setState((state) => {
                    const newState = { ...state };
                    const tariff = JSON.parse(JSON.stringify(newState.tariff));

                    tariff[name] = value;

                    newState.tariff = tariff;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    handlerConfiguration({ idOfConfig, action, name, value }) {
        const { serverData } = this.props;
        const { tariffs } = serverData;

        const actionPrev = () =>
            new Promise((resolve) => {
                if (action === 'change') {
                    if (name === 'category') {
                        getModel({ name: 'tariffConfigItem', type: 'schema' }).then(({ model }) => {
                            resolve({ model });
                        });
                    } else {
                        resolve({});
                    }
                } else {
                    resolve({});
                }
            });

        return new Promise((resolve) => {
            actionPrev().then(({ model }) => {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const tariff = JSON.parse(JSON.stringify(newState.tariff));
                        const indexConfig = tariff.configurations.findIndex(
                            (configLoop) => configLoop._id === idOfConfig,
                        );
                        const config = tariff.configurations[indexConfig];

                        if (action === 'change') {
                            config[name] = value;

                            if (name === 'category') {
                                const categories = tariffs?.categories || [];
                                const category = categories.find(
                                    (categoryLoop) => categoryLoop.key === value,
                                );

                                if (category) {
                                    config.type = category.type;

                                    if (category.type === 'worker') {
                                        config.name = 'Грузчик';
                                        config.items = [{ ...model, typeConfig: 'worker' }];
                                    }

                                    if (category.type === 'courier') {
                                        config.name = 'Курьер';
                                        config.items = [{ ...model, typeConfig: 'courier' }];
                                    }

                                    if (category.type === 'car') {
                                        config.name = '0.0';
                                        config.items = [];
                                    }
                                }
                            }

                            if (name === 'name' && config.isNew) {
                                config.items = [];
                            }
                        } else if (action === 'delete') {
                            tariff.configurations.splice(indexConfig, 1);
                        }

                        newState.tariff = tariff;

                        return newState;
                    },
                    () => {
                        setTimeout(() => {
                            document.dispatchEvent(
                                new CustomEvent('updateListAbsolute', {
                                    detail: {
                                        id: 'configurations',
                                        props: {
                                            clearStyleElems: [
                                                '.manualTariffsConfigurationItems__cards',
                                            ],
                                        },
                                    },
                                }),
                            );
                        }, 10);
                        resolve();
                    },
                );
            });
        });
    }

    handlerConfigurationItems({ action, idOfConfig, key }) {
        const actionPrev = () =>
            new Promise((resolve, reject) => {
                if (action === 'add') {
                    getModel({ name: 'tariffConfigItem', type: 'schema' }).then(({ model }) => {
                        resolve({ model });
                    });
                } else if (action === 'delete') {
                    const { tariff } = this.state;
                    const config = tariff.configurations.find(
                        (configLoop) => configLoop._id === idOfConfig,
                    );
                    if (config.items.length === 1 && !config.isNew) {
                        reject();
                    } else {
                        resolve({});
                    }
                }
            });

        return new Promise((resolve) => {
            actionPrev().then(({ model }) => {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const infoConfigs = { ...newState.infoConfigs };
                        const tariff = JSON.parse(JSON.stringify(newState.tariff));
                        const config = tariff.configurations.find(
                            (configLoop) => configLoop._id === idOfConfig,
                        );

                        if (action === 'add') {
                            const params = {};

                            if (config.items.length) {
                                params.parameters = config.items[0].parameters;
                                params.services = config.items[0].services;
                            }

                            config.items.push({ ...model, typeConfig: 'car', key, ...params });

                            infoConfigs[idOfConfig] = key;
                        }
                        if (action === 'delete') {
                            const indexItem = config.items.findIndex((item) => item.key === key);

                            config.items.splice(indexItem, 1);

                            if (infoConfigs[idOfConfig] === key && config.items[0]) {
                                infoConfigs[idOfConfig] = config.items[0].key;
                            } else if (!config.items[0]) {
                                infoConfigs[idOfConfig] = null;
                            }
                        }

                        newState.infoConfigs = infoConfigs;
                        newState.tariff = tariff;

                        return newState;
                    },
                    () => {
                        setTimeout(() => {
                            document.dispatchEvent(
                                new CustomEvent('updateListAbsolute', {
                                    detail: {
                                        id: 'configurations',
                                        props: {
                                            clearStyleElems: [
                                                '.manualTariffsConfigurationItems__cards',
                                            ],
                                        },
                                    },
                                }),
                            );
                        }, 10);

                        resolve();
                    },
                );
            });
        });
    }

    handlerConfigurationItem({ action, idOfConfig, idOfItem, type, name, value }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const tariff = JSON.parse(JSON.stringify(newState.tariff));
                const config = tariff.configurations.find(
                    (configLoop) => configLoop._id === idOfConfig,
                );
                const item = config.items.find((itemLoop) => itemLoop._id === idOfItem);

                newState.tariff = tariff;

                if (type === 'parameters') {
                    if (action === 'change') {
                        item.parameters[name] = value ? +value : '';

                        // console.log(item.parameters)
                    }
                } else if (type === 'services') {
                    if (action === 'add') {
                        item.services.push({
                            key: name,
                            value: value ?? null,
                            otherInfo: {},
                        });
                    } else {
                        const index = item.services.findIndex((service) => service.key === name);

                        if (action === 'delete') {
                            item.services.splice(index, 1);
                        } else if (action === 'change') {
                            item.services[index].value = value ? +value : '';
                        }
                    }
                } else {
                    resolve();
                }

                return newState;
            });
        });
    }

    setTariff({ tariff: tariffTarget, isInit = false }) {
        const tariff = {
            ...tariffTarget,
            configurations: tariffTarget.configurations.map((config) => ({
                ...config,
                isNew: undefined,
            })),
        };

        return new Promise((resolve) => {
            if (isInit) {
                const { styleComponent } = removeTransition({
                    item: '.manualTariffs',
                    isNotRemove: true,
                });

                this.removeTransition = () => {
                    if (styleComponent.parentNode) {
                        styleComponent.parentNode.removeChild(styleComponent);
                    }
                };
            }

            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.tariff = tariff;
                    newState.tariffSave = JSON.parse(JSON.stringify(tariff));

                    return newState;
                },
                () => {
                    setServerData('tariffs').then(() => {
                        this.setState({ isInit: true });
                    });

                    this.initTariff();

                    resolve();
                },
            );
        });
    }

    getTariff() {
        const { levels } = this.props;
        const idOfTariff = levels[3];

        return new Promise((resolve) => {
            getTariffs({ id: idOfTariff }).then(({ tariff }) => {
                setTimeout(() => {
                    this.setTariff({ tariff, isInit: true }).then(() => {
                        resolve();
                    });
                }, 300);
            }, this.handlerErrorGetModel);
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'tariffs',
                    rules: ['update'],
                },
            ],
        });
    }

    checkTraining() {
        this.timerTrainingId = setTimeout(() => null, 5000);
    }

    handlerSocket({ detail }) {
        const { tariff } = this.state;
        const { name, data } = detail;

        if (tariff && name === 'tariff') {
            const { idOfTariff, fields } = data;

            if (tariff._id === idOfTariff) {
                this.setTariff({ tariff: fields.tariff });
            }
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getTariff();

        this.checkTraining();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'tariff', value: undefined });

        if (this.timerTrainingId) {
            clearTimeout(this.timerTrainingId);
        }
    }

    render() {
        const { isInit, isInitShow } = this.state;

        return (
            <div ref={this.parent} className={`widget  ${isInitShow ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInitShow}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    ['manual-tariffs-inner-logs'].includes(pageName) ? '_full' : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-tariffs-inner' && page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualTariffsInner);

ManualTariffsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
};
