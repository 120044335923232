import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../Field.jsx';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';
import PointContacts from './PointContacts.jsx';
import PointCargos from './PointCargos.jsx';
import StatusPoint from './StatusPoint.jsx';

import Drag from '../../classes/Drag';
import MobileDeletePoint from '../../classes/order/MobileDeletePoint';

import getWidthText from '../../functions/getWidthText';
import setSpacesInText from '../../functions/setSpacesInText';
import handlerPopup from '../../functions/handlerPopup';
import shortText from '../../functions/shortText';
import handlerWindow, { updateWindow } from '../../functions/handlerWindow';
import getPositionFromParent from '../../functions/getPositionFromParent';
import setAnimate from '../../functions/setAnimate';
import checkResponsible from '../../functions/order/checkResponsible';
import PointDetails from './PointDetails.jsx';
import removeTransition from '../../functions/removeTransition.ts';
import handlerSupportCursor from '../../functions/handlerSupportCursor';

const infoSelects = require('../../infos/selects.json');

class Point extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDrop: false,
        };

        this.handlerField = this.handlerField.bind(this);
        this.handlerFieldContacts = this.handlerFieldContacts.bind(this);
        this.handlerFieldCargo = this.handlerFieldCargo.bind(this);
        this.initDrag = this.initDrag.bind(this);
        this.initDelete = this.initDelete.bind(this);
        this.handlerDrop = this.handlerDrop.bind(this);
        this.handlerDisabledDrag = this.handlerDisabledDrag.bind(this);
        this.showContacts = this.showContacts.bind(this);
        this.setContacts = this.setContacts.bind(this);
        this.handlerWindowStatuses = this.handlerWindowStatuses.bind(this);
        this.handlerWindowFeedback = this.handlerWindowFeedback.bind(this);
        this.changePage = this.changePage.bind(this);
        this.updateWindowsPosition = this.updateWindowsPosition.bind(this);
        this.setPointAddress = this.setPointAddress.bind(this);

        this.parent = React.createRef();
    }

    handlerDrop(showDrop = !this.props.point.showDrop) {
        const { handlerRoute, setHeightItems, number } = this.props;
        if (this.parent.current) {
            const dropInner = this.parent.current.querySelector('.orderPoint__dropInner');
            const { clientHeight: heightDrop } = dropInner;

            handlerRoute.changePoint({ key: number, values: { showDrop, heightDrop } }).then(() => {
                setHeightItems();
            });
        }
    }

    handlerFieldContacts({ action, name, value }) {
        const { handlerRoute, number, point, handlerErrors } = this.props;
        const { addressatInfo } = point;

        return new Promise((resolve) => {
            if (action === 'change') {
                if (name === 'withSms') {
                    handlerRoute
                        .changePoint({ key: number, values: { withSms: !value } })
                        .then(resolve);
                } else {
                    addressatInfo[name].value = value;

                    if (handlerErrors) {
                        handlerErrors({
                            errors: [`route-${point._id}-addressatInfo-${name}`],
                            isRemove: true,
                        });
                    }

                    handlerRoute
                        .changePoint({ key: number, values: { addressatInfo } })
                        .then(resolve);
                }
            } else {
                resolve();
            }
        });
    }

    handlerFieldCargo({ action, name, value, key }) {
        const { handlerRoute, number, point } = this.props;
        const { cargo } = point;

        return new Promise((resolve) => {
            if (action === 'change') {
                cargo[key][name] = value;

                handlerRoute.changePoint({ key: number, values: { cargo } }).then(() => {
                    if (name === 'type') {
                        this.handlerDrop(point.showDrop);
                    }

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    handlerField({ action, name, value }) {
        const { point, handlerRoute, handlerErrors } = this.props;

        return new Promise((resolve) => {
            if (action === 'change') {
                if (handlerErrors) {
                    handlerErrors({
                        errors: [`route-${point._id}-address`],
                        isRemove: true,
                    });
                }

                removeTransition({ item: '.orderPoint__addressInput' });

                handlerRoute.changePoint({ key: name, values: { address: value } }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    initDrag() {
        const { handlerRoute, number, offset, indexCurrentItem } = this.props;

        this.handlerPoint = new Drag({
            name: 'order-block',
            key: number,
            card: this.parent.current.closest('.orderPoints__item'),
            dragBtn: this.parent.current.querySelector('.orderPoint__drag'),
            classCard: '.orderPoints__item',
            classParent: '.orderPoints__inner',
            getNewOrder: handlerRoute.getNewOrder,
            callback: handlerRoute.setOrderPoints,
            offset,
            indexCurrentItem,
            callbackMove: (isProccessDrag) => this.setState({ isProccessDrag }),
            getCondForMove: () => !this.props.isProccessSetRoute,
        });

        this.handlerPoint.init();
    }

    handlerDisabledDrag(state) {
        if (state === true && !this.state.isDisabledDrag) {
            this.setState({ isDisabledDrag: true });
        }
        if (state === false && this.state.isDisabledDrag) {
            this.setState({ isDisabledDrag: false });
        }
    }

    initDelete() {
        const { handlerRoute, point } = this.props;

        this.handlerDeletePoint = new MobileDeletePoint({
            key: point._id || point.uniqKey,
            point: this.parent.current,
            dragArea: this.parent.current.querySelector('.orderPoint__box'),
            deleteArea: this.parent.current.querySelector('.orderPoint__deleteBox'),
            moreBtnClass: '.orderPoint__more',
            getCondForDelete: () =>
                !this.state.isProccessDrag &&
                !this.props.isDisabled &&
                handlerRoute.checkCondForDelete(),
            callbackMove: this.handlerDisabledDrag,
        });
    }

    setContacts(contacts) {
        const { handlerRoute, number, point } = this.props;
        const { addressatInfo } = point;

        Object.keys(contacts).forEach((key) => {
            addressatInfo[key] = contacts[key];
        });

        handlerRoute.changePoint({ key: number, values: { addressatInfo } }).then(() => {
            this.handlerDrop(point.showDrop);
        });
    }

    showContacts({ point }) {
        const { device, number } = this.props;
        const { isComplete, addressatInfo } = point;

        const address = isComplete
            ? `«${shortText({ str: point.address, stop: 5, type: 'word' })}»`
            : `№${number + 1}`;

        if (device === 'mobile') {
            handlerPopup({
                action: 'show',
                name: 'popupContactsPoint',
                address,
                startFields: addressatInfo,
                callback: this.setContacts,
                numberOfPoint: number,
            });
        }
    }

    getContactsSupportItem({ name }) {
        const { point } = this.props;
        const { addressatInfo } = point;
        const item = addressatInfo[name];

        if (name === 'time') {
            const timeInfo = infoSelects.times.items.find((elem) => elem.key === item.value);

            if (item.value === 'any') {
                return timeInfo.content;
            }

            const { timeStart, timeEnd } = addressatInfo;

            return timeInfo.content
                .replace('__:__', timeStart.value)
                .replace('__:__', timeEnd.value);
        }

        if (name === 'phone') {
            return `<span class="_noWrap">${item.value}</span>`;
        }

        return item.value;
    }

    renderDetailsSupport() {
        const { device, point } = this.props;
        const { addressatInfo } = point;

        if (device === 'mobile') {
            const fieldsContacts = ['name', 'phone', 'time'];

            if (fieldsContacts.find((name) => addressatInfo[name].value)) {
                return fieldsContacts
                    .filter((name) => addressatInfo[name].value)
                    .map((name) => this.getContactsSupportItem({ name }))
                    .join(', ');
            }

            return 'Указать детали';
        }

        return 'Детали:';
    }

    renderContactsSupport() {
        const { device, point } = this.props;
        const { addressatInfo } = point;

        if (device === 'mobile') {
            const fieldsContacts = ['name', 'phone', 'time'];

            if (fieldsContacts.find((name) => addressatInfo[name].value)) {
                return fieldsContacts
                    .filter((name) => addressatInfo[name].value)
                    .map((name) => this.getContactsSupportItem({ name }))
                    .join(', ');
            }

            return 'Указать контактное лицо';
        }

        return 'Контакт:';
    }

    getCondForChangeStatus() {
        const { point, idOfCurrentPoint, statusOrder } = this.props;
        const { _id } = point;

        return idOfCurrentPoint === _id && statusOrder === 'in-proccess';
    }

    handlerWindowStatuses({ target }) {
        const { user, order } = this.props;

        if (process.env.REACT_APP_SYSTEM === 'crm' && checkResponsible({ user, order })) {
            const { point, getParent } = this.props;
            const { _id, status: statusInit } = point;
            const id = this.getWindowId({ name: 'statuses' });

            if (this.getCondForChangeStatus()) {
                const parent = getParent();
                const parentScroll = parent
                    .querySelector('.widget__page._order-details-main._current')
                    .querySelector('.widget__pageBox._scroll');

                const card = parent.querySelector(`.orderPoints__item[data-id="${_id}"]`);
                const { offsetHeight: sizeCards, scrollTop: scrollCards } = parentScroll;
                const { offsetHeight: sizeCard } = card;
                const offsetCard = getPositionFromParent({ target: card, parent: parentScroll })[1];
                const scrollCard = Math.round(sizeCards / 2 - sizeCard / 2 - offsetCard);

                setAnimate({
                    draw: (progress) => {
                        parentScroll.scrollTo({
                            top: scrollCards - progress * scrollCard,
                        });
                    },
                    duration: 300,
                    callback: () => {
                        setTimeout(() => {
                            updateWindow({ key: id, isHideFromScroll: true });
                        }, 100);
                    },
                });

                handlerWindow({
                    parent,
                    parentScroll,
                    target,
                    action: 'show',
                    name: 'statusesOrder',
                    className: '_statusesOrder',
                    uniqKey: id,
                    watchesProps: { top: true },
                    statusInit,
                    centers: {
                        left: 1,
                        top: 0.5,
                    },
                });
            }
        }
    }

    getWindowId({ name }) {
        const { point } = this.props;
        const { _id } = point;

        if (name === 'feedback') {
            return `${_id}-feedback`;
        }

        if (name === 'statuses') {
            return `${_id}-statuses`;
        }

        return '';
    }

    updateWindowsPosition() {
        const { windows } = this.props;

        ['feedback', 'statuses'].forEach((name) => {
            const id = this.getWindowId({ name });

            if (windows[id]) {
                windows[id].updatePosition({ watchesProps: { left: true } });
            }
        });
    }

    handlerWindowFeedback({ target }) {
        if (process.env.REACT_APP_SYSTEM === 'crm') {
            const { point, getParent } = this.props;
            const { _id, feedback } = point;
            const id = this.getWindowId({ name: 'feedback' });

            const parent = getParent();
            const parentScroll = parent
                .querySelector('.widget__page._order-details-main._current')
                .querySelector('.widget__pageBox._scroll');

            const card = parent.querySelector(`.orderPoints__item[data-id="${_id}"]`);
            const { offsetHeight: sizeCards, scrollTop: scrollCards } = parentScroll;
            const { offsetHeight: sizeCard } = card;
            const offsetCard = getPositionFromParent({ target: card, parent: parentScroll })[1];
            const scrollCard = Math.round(sizeCards / 2 - sizeCard / 2 - offsetCard);

            setAnimate({
                draw: (progress) => {
                    parentScroll.scrollTo({
                        top: scrollCards - progress * scrollCard,
                    });
                },
                duration: 300,
                callback: () => {
                    setTimeout(() => {
                        updateWindow({ key: id, isHideFromScroll: true });
                    }, 100);
                },
            });

            handlerWindow({
                parent,
                parentScroll,
                target,
                action: 'show',
                name: 'feedbackOrder',
                className: '_feedbackOrder',
                uniqKey: id,
                watchesProps: { top: true },
                feedback,
                centers: {
                    left: 0,
                    top: 0.5,
                },
            });
        }
    }

    checkChangeEdit() {
        const { point } = this.props;

        if (this.props.isDisabled !== this.state.isDisabled) {
            this.setState({ isDisabled: this.props.isDisabled }, () => {
                setTimeout(() => {
                    this.handlerDrop(point.showDrop);
                }, 10);
            });
        }
    }

    setPointAddress({ detail: { key } }) {
        const { number } = this.props;

        if (number === key) {
            this.handlerDrop(true);
        }
    }

    changePage({ detail: { group } }) {
        if (group === 'order-details') {
            setAnimate({
                draw: () => {
                    this.updateWindowsPosition();
                },
                duration: 300,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('changePage', this.changePage);
        document.addEventListener('setPointAddress', this.setPointAddress);
    }

    componentDidUpdate() {
        this.checkChangeEdit();
    }

    componentWillUnmount() {
        document.removeEventListener('changePage', this.changePage);
        document.removeEventListener('setPointAddress', this.setPointAddress);

        if (this.handlerPoint) {
            this.handlerPoint.remove();
        }
    }

    render() {
        const { isDisabledDrag } = this.state;
        const {
            handlerRoute,
            point,
            number,
            setHeightItems,
            points,
            type,
            cargoList,
            device,
            formDataImages,
            isNew,
            order,
            errors = [],
            isCurrent,
            isDisabled,
        } = this.props;
        const {
            address,
            showDrop,
            heightDrop,
            list,
            sk,
            ttk,
            cargo,
            status,
            code,
            addressatInfo,
            isComplete,
            feedback,
        } = point;
        let support =
            number === 0 ? 'Введите здесь адрес погрузки (склад)' : 'Введите здесь адрес доставки';

        if (device === 'mobile') {
            support = number === 0 ? 'Откуда везём' : 'Куда везём';
        }

        const isOtherDisabled = isDisabled || isCurrent;

        const pointsFilters = points?.filter((item) => !item.isDelete);

        const condForDrag = !isOtherDisabled && handlerRoute.checkCondForDrag();
        const condForDelete = !isOtherDisabled && handlerRoute.checkCondForDelete();
        const stateForAdd = !isOtherDisabled && !pointsFilters?.find((item) => !item.isComplete);

        const stateOfActions =
            !isOtherDisabled &&
            ((stateForAdd && condForDelete && '_actions') ||
                (!condForDrag &&
                    ((stateForAdd && '_actionAdd') || (condForDelete && '_actionDelete'))));

        let counterActions = 0;

        if (stateForAdd) {
            counterActions += 1;
        }

        if (device === 'desktop' && condForDelete) {
            counterActions += 1;
        }

        if (condForDrag) {
            counterActions += 1;
        }

        const condForContacts = !!Object.keys(addressatInfo).find(
            (key) => addressatInfo[key].value,
        );

        return (
            <div
                ref={this.parent}
                className={`orderPoint _editBack ${showDrop ? '_showDrop' : ''} ${
                    sk ? '_sk' : ''
                } ${ttk ? '_ttk' : ''} ${stateOfActions || ''} ${type ? `_${type}` : ''} ${
                    isDisabled ? '_isDisabled' : ''
                } ${counterActions ? `_actions${counterActions}` : ''}`}
            >
                {device === 'mobile' && (
                    <Animate
                        className="orderPoint__deleteBox"
                        isShow={condForDelete}
                        actionInit={this.initDelete}
                    >
                        <div
                            className="orderPoint__deleteArea _col"
                            onClick={() => {
                                handlerRoute.deletePoint({ key: number });
                            }}
                        >
                            <i className="orderPoint__deleteAreaIcon">
                                <Icon name="delete-point" />
                            </i>
                            <p className="orderPoint__deleteAreaContent">
                                Удалить
                                <br />
                                адрес
                            </p>
                        </div>
                        <div className="orderPoint__more"></div>
                    </Animate>
                )}
                <div className="orderPoint__box">
                    <div className="orderPoint__inner">
                        <div
                            className="orderPoint__btn _col _click"
                            onClick={() => this.handlerDrop()}
                        />

                        <div className="orderPoint__innerBox">
                            <div className="orderPoint__head _row">
                                <div className="orderPoint__headInner _row">
                                    <div className="orderPoint__address">
                                        <div
                                            className="orderPoint__addressInput"
                                            style={
                                                type === 'details'
                                                    ? {
                                                          width: `${
                                                              getWidthText({
                                                                  text: address || support,
                                                                  size: 16.5,
                                                              }) + (isOtherDisabled ? 0 : 16)
                                                          }px`,
                                                      }
                                                    : {}
                                            }
                                        >
                                            <Field
                                                support={support}
                                                name={number}
                                                value={address}
                                                callback={this.handlerField}
                                                className={`_orderField _light `}
                                                group="points"
                                                isDisabled={isOtherDisabled}
                                                setStateComplete={device === 'mobile'}
                                                isRun={isComplete}
                                                isEditmode={type === 'details'}
                                                error={errors.includes(
                                                    `route-${point._id}-address`,
                                                )}
                                            />
                                        </div>
                                        {!isOtherDisabled && list && list.state > -1 && (
                                            <div
                                                className={`orderPoint__addressDrop ${
                                                    list.state === 1 ? '_show' : ''
                                                }`}
                                            >
                                                {list.items.map((item, key) => (
                                                    <div
                                                        className="orderPoint__addressDropItem _click"
                                                        key={key}
                                                        onClick={() => {
                                                            handlerRoute.choiceFromList({
                                                                key: number,
                                                                address: item,
                                                                callback: () => {
                                                                    this.handlerDrop(true);
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {type === 'details' && order?.systemType !== 'service' && (
                                        <>
                                            <div
                                                className={`orderPoint__status`}
                                                onClick={({ target }) =>
                                                    this.handlerWindowStatuses({
                                                        target: target.closest(
                                                            '.orderPoint__status',
                                                        ),
                                                    })
                                                }
                                            >
                                                <StatusPoint
                                                    status={status}
                                                    lastStatus={
                                                        point.statuses[point.statuses.length - 1]
                                                    }
                                                    className={
                                                        this.getCondForChangeStatus()
                                                            ? '_withActive'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <Animate
                                                className={`orderPoint__info _row`}
                                                isShow={
                                                    isOtherDisabled &&
                                                    !feedback?.key &&
                                                    order.deliveryType === 'delivery'
                                                }
                                            >
                                                <div className="orderPoint__code _row">
                                                    <p className="orderPoint__codeSupport">
                                                        Код закрытия:
                                                    </p>
                                                    <div className="orderPoint__codeInner _row">
                                                        <div className="orderPoint__codeContent">
                                                            {code}
                                                        </div>
                                                        <i
                                                            className="orderPoint__codeInfo _click"
                                                            onMouseEnter={(e) => {
                                                                handlerSupportCursor({
                                                                    action: 'enter',
                                                                    content:
                                                                        'Сообщите данный код водителю<br/>в случае проблем закрытия точки<br/>со стороны приложения',
                                                                    e,
                                                                    data: {
                                                                        className:
                                                                            '_normalCase _center',
                                                                    },
                                                                });
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                handlerSupportCursor({
                                                                    action: 'leave',
                                                                    e,
                                                                });
                                                            }}
                                                        >
                                                            <Icon name="info" />
                                                        </i>
                                                    </div>
                                                </div>
                                            </Animate>
                                            <Animate
                                                className={`orderPoint__feedback ${
                                                    feedback?.rate > 3 ? '_good' : '_bad'
                                                }`}
                                                isShow={feedback?.key}
                                                onClick={({ target }) =>
                                                    this.handlerWindowFeedback({
                                                        target: target.closest(
                                                            '.orderPoint__feedback',
                                                        ),
                                                    })
                                                }
                                            >
                                                <div className="orderPoint__feedbackInner _row _click">
                                                    <i className="orderPoint__feedbackIcon">
                                                        <Icon name="star" />
                                                    </i>
                                                    <div className="orderPoint__feedbackRate">
                                                        {feedback?.rate}
                                                    </div>
                                                </div>
                                            </Animate>
                                        </>
                                    )}
                                    {device === 'desktop' && (
                                        <Animate
                                            className="orderPoint__delete _click"
                                            isShow={condForDelete}
                                            isSmoothShow={true}
                                            onClick={() => {
                                                handlerRoute.deletePoint({ key: number });
                                            }}
                                        >
                                            <Icon name="delete-circle" />
                                        </Animate>
                                    )}

                                    <Animate
                                        className="orderPoint__add _click"
                                        isShow={stateForAdd}
                                        isSmoothShow={true}
                                        onClick={() => {
                                            handlerRoute.addPoint({ key: number + 1 });
                                        }}
                                    >
                                        <Icon name="add-circle" />
                                    </Animate>
                                    <Animate
                                        className={`orderPoint__drag ${
                                            isDisabledDrag ? '_disabled' : '_click'
                                        }`}
                                        isShow={condForDrag}
                                        isSmoothShow={true}
                                        actionInit={this.initDrag}
                                        actionPrevRemove={() => {
                                            this.handlerPoint?.remove();
                                        }}
                                    >
                                        <Icon name="move-point" />
                                    </Animate>
                                </div>
                            </div>
                            <div
                                className={`orderPoint__details _row ${
                                    condForContacts ? '_active' : ''
                                }`}
                            >
                                <div
                                    className="orderPoint__support _row"
                                    onClick={() => this.showContacts({ point })}
                                >
                                    <i className="orderPoint__supportIcon">
                                        <Icon name="order-point-details" />
                                    </i>
                                    <div
                                        className="orderPoint__supportInner"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(this.renderDetailsSupport()),
                                        }}
                                    ></div>
                                    {device === 'mobile' && (
                                        <Animate
                                            className="orderPoint__supportEdit"
                                            isShow={condForContacts}
                                        >
                                            <Icon name="edit" />
                                        </Animate>
                                    )}
                                </div>
                                {device === 'desktop' && (
                                    <PointDetails
                                        point={point}
                                        handler={this.handlerFieldContacts}
                                        numberOfPoint={number}
                                        isDisabled={isDisabled}
                                        isEditmode={type === 'details'}
                                    />
                                )}
                            </div>
                            <div
                                className="orderPoint__drop"
                                style={{ height: `${showDrop ? heightDrop : 0}px` }}
                            >
                                <div
                                    className="orderPoint__dropBox"
                                    style={{ height: `${showDrop ? heightDrop : 0}px` }}
                                >
                                    <div className="orderPoint__dropInner">
                                        {device === 'desktop' && (
                                            <div
                                                className={`orderPoint__contacts _row ${
                                                    condForContacts ? '_active' : ''
                                                }`}
                                            >
                                                <div
                                                    className="orderPoint__support _row"
                                                    onClick={() => this.showContacts({ point })}
                                                >
                                                    <i className="orderPoint__supportIcon">
                                                        <Icon name="order-point-contacts" />
                                                    </i>
                                                    <div
                                                        className="orderPoint__supportInner"
                                                        dangerouslySetInnerHTML={{
                                                            __html: setSpacesInText(
                                                                this.renderContactsSupport(),
                                                            ),
                                                        }}
                                                    ></div>
                                                    {device === 'mobile' && (
                                                        <Animate
                                                            className="orderPoint__supportEdit"
                                                            isShow={condForContacts}
                                                        >
                                                            <Icon name="edit" />
                                                        </Animate>
                                                    )}
                                                </div>
                                                <PointContacts
                                                    order={order}
                                                    point={point}
                                                    handler={this.handlerFieldContacts}
                                                    numberOfPoint={number}
                                                    isDisabled={isDisabled}
                                                    isOtherDisabled={isOtherDisabled}
                                                    isEditmode={type === 'details'}
                                                    errors={errors}
                                                />
                                            </div>
                                        )}

                                        <PointCargos
                                            order={order}
                                            cargo={cargo}
                                            points={points}
                                            point={point}
                                            numberOfPoint={number}
                                            handler={this.handlerFieldCargo}
                                            handlerRoute={handlerRoute}
                                            handlerDrop={this.handlerDrop}
                                            setHeightItems={setHeightItems}
                                            isDisabled={isDisabled}
                                            cargoList={cargoList}
                                            formDataImages={formDataImages}
                                            type={type}
                                            isEditmode={type === 'details'}
                                            isNew={isNew}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Point);

Point.propTypes = {
    order: PropTypes.object,
    points: PropTypes.array,
    point: PropTypes.object,
    number: PropTypes.number,
    handlerRoute: PropTypes.object,
    setHeightItems: PropTypes.func,
    offset: PropTypes.number,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    indexCurrentItem: PropTypes.number,
    fakeCallback: PropTypes.func,
    cargoList: PropTypes.array,
    device: PropTypes.string,
    formDataImages: PropTypes.object,
    isProccessSetRoute: PropTypes.bool,
    getParent: PropTypes.func,
    idOfCurrentPoint: PropTypes.string,
    statusOrder: PropTypes.string,
    windows: PropTypes.object,
    user: PropTypes.object,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    handlerErrors: PropTypes.func,
    isCurrent: PropTypes.bool,
};
