import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilter from '../../classes/Filter';

import Back from '../../components/crm/widget/Back.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import TableHead from '../../components/crm/manual/TableHead.jsx';
import Table from '../../components/crm/manual/Table.jsx';

import Windows from '../../components/Windows.jsx';
import Filter from '../../components/Filter.jsx';
import handlerWindow from '../../functions/handlerWindow';
import getFilter from '../../requests/getFilter';
import WindowActions from '../../components/WindowActions.jsx';

import getMailings from '../../requests/getMailings';

import getHeaders from '../../functions/getHeaders';
import EmodziList from '../../components/EmodziList.jsx';
import TableInfo from '../../components/crm/manual/TableInfo.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import handlerPopup from '../../functions/app/handlerPopup';
import setPermissions from '../../functions/crm/setPermissions';
import changePage from '../../functions/changePage';
import getPageLink from '../../functions/getPageLink';
import { dispatcher } from '../../redux/redux';
import setNotification from '../../functions/setNotification';

class Mailings extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderWindowsEditActions = this.renderWindowsEditActions.bind(this);
        this.setSetEdit = this.setSetEdit.bind(this);
        this.getItems = this.getItems.bind(this);
        this.renderEmodziList = this.renderEmodziList.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.start = this.start.bind(this);
        this.handlerMore = this.handlerMore.bind(this);
        this.getActionsItems = this.getActionsItems.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    name = 'chatTemplates';

    stepCounter = 10;

    getItems(isForce = false) {
        const query = this.getQueryForRequest();

        if (isForce === true) {
            query.params.find((item) => item.key === 'limit').value =
                this.state.items?.length || this.stepCounter;
            query.params.find((item) => item.key === 'skip').value = 0;
        }

        return new Promise((resolve) => {
            getMailings(query).then(
                ({ mailings, isLimit, counter }) => {
                    this.setItems(mailings, isForce, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    getFilter() {
        getFilter({ name: 'mailings' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter.bind(this);
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setSetEdit(setEdit) {
        this.setEdit = setEdit;
    }

    renderWindowsEditActions({ items, mailing }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'mailing',
                        modelId: mailing._id,
                    });

                    resolve();
                } else if (key === 'repeat') {
                    dispatcher({ type: 'repeatMailingId', data: mailing._id }).then(() => {
                        changePage({
                            href: getPageLink({ name: 'mailing-inner', ids: { 2: 'new' } }),
                        });
                    });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                    });
                }}
            />
        );
    }

    renderEmodziList({ addEmodzi, area, startPosition }) {
        return <EmodziList addEmodzi={addEmodzi} input={area} startPosition={startPosition} />;
    }

    start({ id, action }) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${process.env.REACT_APP_API}/mailing`,
                    { id, action },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        resolve();
                    } else {
                        const { message } = data;

                        if (message === 'Executor zero counter') {
                            setNotification({ notification: 'mailing-executor-zero-counter' });
                        }

                        reject();
                    }
                }, reject);
        });
    }

    getParent() {
        return this.parent.current;
    }

    getParentForScroll() {
        return this.parent.current?.querySelector('.tableInfo__scroll');
    }

    getActionsItems() {
        const items = ['logs'];

        if (
            this.getPermissions({
                key: 'chat',
                items: [
                    {
                        key: 'mailing',
                        rules: ['create'],
                    },
                ],
            })
        ) {
            items.unshift('repeatMailing');
        }

        return items;
    }

    handlerMore(mailing, { target }) {
        const parent = this.getParent();

        const actionsItems = this.getActionsItems();

        const uniqKey = `mailingActions-${mailing._id}`;

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: this.getParentForScroll(),
            target: target.closest('.tableInfo__more'),
            action: 'show',
            name: 'mailingActions',
            className: '_actions _right',
            uniqKey,
            items: actionsItems,
            mailing,
            centers: {
                left: 1,
                top: 0,
            },
            isHideFromScroll: true,
            isCheckScrollPosition: false,
        });
    }

    handlerSocket({ detail }) {
        const { items } = this.state;
        const { name, data } = detail;

        if (items && name === 'mailing') {
            const { id } = data;

            if (items.find((item) => item._id === id)) {
                delete data.id;

                this.updateItem({ id, fields: data });
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.getFilter();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('updateMailing', this.updateItems);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('updateMailing', this.updateItems);
    }

    render() {
        const {
            filter,
            items = [],
            isLimit,
            isReady,
            isLoadingFilter = false,
            isDisabledScroll = false,
            counter = 0,
            updatedItemKey,
        } = this.state;
        const { counterChangePage, checkRights } = this.props;
        // const filteredItems = items.filter(this.filterItem);

        return (
            <div ref={this.parent} className={`widget _fix _parent _ready`}>
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="mailingActions" renderContent={this.renderWindowsEditActions} />
                <Windows name="emodziList" renderContent={this.renderEmodziList} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Чат"
                                className={`${counterChangePage > 0 ? '_withBack' : ''} _forceShow`}
                            >
                                <div className="widget__headNameItemContent">
                                    Рассылки
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                    >
                                        <>({counter})</>
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="mailings"
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    getParent={() => this.parent.current}
                                    checkRights={checkRights}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__contentBox">
                        <div className="widget__page _deep1 _full _show _wide">
                            <div className="widget__pageBox">
                                <div className="widget__pageInner _notPadding">
                                    <TableInfo
                                        isReady={isReady}
                                        items={items}
                                        name="mailings"
                                        getMoreItems={this.getMoreItems}
                                        isLimit={isLimit}
                                        stepCounter={this.stepCounter}
                                        isDisabledScroll={isDisabledScroll}
                                        isLoading={!isReady || isLoadingFilter}
                                        isWrap={true}
                                        isRowHeight={true}
                                        keyUpdateItem={updatedItemKey}
                                        otherData={{ start: this.start }}
                                        handlerMore={this.handlerMore}
                                        getActionsItems={this.getActionsItems}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        counterChangePage: state.counterChangePage,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Mailings);

Mailings.propTypes = {
    counterChangePage: PropTypes.number,
    user: PropTypes.object,
    checkRights: PropTypes.func,
};
