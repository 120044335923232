import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../functions/setServerData';
import getCarInfo from '../../../functions/getCarInfo';

import Button from '../../Button.jsx';
import ImageLazy from '../../ImageLazy.jsx';
import Animate from '../../Animate.jsx';

import CarsInCrewList from './car/List.jsx';
import Loader from '../../Loader.jsx';
import ListDynamic from '../../ListDynamic.jsx';

class CarInCrew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerShowList = this.handlerShowList.bind(this);
        this.setCurrentCar = this.setCurrentCar.bind(this);

        this.renderCar = this.renderCar.bind(this);
    }

    orderParams = ['bodywork', 'params', 'volume', 'pallets', 'pass', 'tail'];

    params = {
        bodywork: {
            support: 'Тип кузова:',
        },
        params: {
            support: 'Д*Ш*В кузова:',
        },
        volume: {
            support: 'Объём кузова:',
            end: 'м³',
        },
        pallets: {
            support: 'Кол-во паллет:',
            end: 'шт',
        },
        pass: {
            support: 'Пропуск в центр:',
        },
        tail: {
            support: 'Гидроборт:',
        },
    };

    renderParam({ car, name }) {
        const { serverData } = this.props;
        const { bodywork, long, width, height, services } = car;
        const { carBodyworks, carServices } = serverData;
        let param;

        switch (name) {
            case 'bodywork':
                param = carBodyworks && carBodyworks[bodywork].short;
                break;
            case 'params':
                param = `${long}*${width}*${height}`;
                break;
            case 'volume':
                param = `${+((long * width * height) / 1000000).toFixed(1)}`;
                break;
            case 'tail':
                param = services.find((service) => service.key === 'tail') ? 'Да' : 'Нет';
                break;
            case 'pass':
                {
                    let content = '';
                    const servicesPass = services.filter(
                        (service) => ['ttk', 'sk'].indexOf(service.name) !== -1,
                    );

                    if (servicesPass.length) {
                        if (carServices) {
                            servicesPass.forEach((service) => {
                                content += carServices[service.name].short;
                                content += ` + `;
                            });

                            content = content.slice(0, -2);
                        }
                    } else {
                        content += 'Нет';
                    }

                    param = content;
                }
                break;
            default:
                param = car[name];
                break;
        }

        if (!param) {
            param = '–';
        } else if (this.params[name].end) {
            param += this.params[name].end;
        }

        return param;
    }

    handlerShowList(isShowList = !this.state.isShowList) {
        this.setState(
            (state) => {
                const newState = { ...state };

                newState.isShowList = isShowList;

                return newState;
            },
            () => {
                const { handlerEditmode } = this.props;

                handlerEditmode({ editName: isShowList ? 'car' : null });
            },
        );
    }

    renderCar({ item, prop: _id, isShow }) {
        const { serverData, getCondForChange } = this.props;
        const car = this.props.car || item;
        const { nameMark, nameModel, number, bodywork } = car;
        const { carBodyworks } = serverData;

        const icon = getCarInfo({ type: 'icon', icons: carBodyworks, icon: bodywork });

        return (
            <div className={`orderDetailsCrewCar__inner ${isShow ? '_show' : ''}`} key={_id}>
                <div className="orderDetailsCrewCar__head _row">
                    <div className="orderDetailsCrewCar__headPreview _col">
                        <ImageLazy src={icon} className="orderDetailsCrewCar__headPreviewIcon" />
                    </div>
                    <div className="orderDetailsCrewCar__headContent _col">
                        <p className="orderDetailsCrewCar__headSupport">Машина:</p>
                        <div className="orderDetailsCrewCar__headDescription">
                            {nameMark} {nameModel} {number}
                        </div>
                    </div>
                </div>
                <div className="orderDetailsCrewCar__content">
                    <div className="orderDetailsCrewCar__params">
                        {this.orderParams.map((param) => (
                            <div className="orderDetailsCrewCar__param _row" key={param}>
                                <p className="orderDetailsCrewCar__paramSupport">
                                    {this.params[param].support}
                                </p>
                                <div className="orderDetailsCrewCar__paramValue">
                                    {this.renderParam({ car, name: param })}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="orderDetailsCrewCar__button">
                        <Button
                            className="_blue _normalSize _weightNormal"
                            icon={{ name: 'arrow-next-2', type: 'end' }}
                            onClick={() => this.handlerShowList(true)}
                            isDisabled={!getCondForChange()}
                        >
                            Сменить машину
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    setCurrentCar(id) {
        const { setCurrentCar } = this.props;

        setCurrentCar(id).then(() => {
            this.handlerShowList(false);
        });
    }

    componentDidMount() {
        ['carBodyworks', 'carServices'].forEach((name) => {
            setServerData(name);
        });
    }

    render() {
        const { isShowList } = this.state;
        const { idOfCurrentCar, car, cars, isEmpty, editName, isShowLoading } = this.props;
        const carList = car ? [car] : [];

        return (
            <div
                className={`orderDetailsCrewCar _editBack ${isShowList ? '_isShowList' : ''} ${
                    isEmpty ? '_empty' : ''
                } ${editName === 'car' ? '_current' : editName ? '_disabled' : ''}`}
            >
                <Animate className="orderDetailsCrewCar__loader _loader" isShow={isShowLoading}>
                    <div className="orderDetailsCrewCar__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="orderDetailsCrewCar__empty" isShow={!isShowLoading && isEmpty}>
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">Машина не выбрана</div>
                            <p className="empty__content">Вернитесь назад для выбора</p>
                        </div>
                    </div>
                </Animate>
                <Animate className="orderDetailsCrewCar__list" isShow={isShowList}>
                    <CarsInCrewList
                        idOfCurrentCar={idOfCurrentCar}
                        cars={cars}
                        setCurrentCar={this.setCurrentCar}
                        handlerShowList={this.handlerShowList}
                    />
                </Animate>

                <ListDynamic
                    items={carList}
                    renderItem={this.renderCar}
                    kyeRender={carList.length}
                    prop="_id"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(CarInCrew);

CarInCrew.propTypes = {
    serverData: PropTypes.object,
    car: PropTypes.object,
    cars: PropTypes.array,
    idOfCurrentCar: PropTypes.string,
    setCurrentCar: PropTypes.func,
    isEmpty: PropTypes.bool,
    handlerEditmode: PropTypes.func,
    editName: PropTypes.string,
    getCondForChange: PropTypes.func,
    isShowLoading: PropTypes.bool,
};
