import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import ExecutorLimitsPopup from './ExecutorLimitsPopup.jsx';

class ExecutorLimitsPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { executorLimitsPopup } = this.props;

        return (
            <Animate
                className="body__corporationTariffPopup"
                isShow={executorLimitsPopup?.isShow}
            >
                <ExecutorLimitsPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        executorLimitsPopup: state.executorLimitsPopup,
    };
}

export default connect(mapStateToProps)(ExecutorLimitsPopupWrapper);

ExecutorLimitsPopupWrapper.propTypes = {
    executorLimitsPopup: PropTypes.object,
};
