import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import Icon from '../../../Icon.jsx';
import Select from '../../../Select.jsx';
import Field from '../../../Field.jsx';
import Switch from '../../../Switch.jsx';
import Button from '../../../Button.jsx';
import handlerWindow from '../../../../functions/handlerWindow';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import setNotification from '../../../../functions/setNotification';
import getHeaders from '../../../../functions/getHeaders';
import copyInBuffer from '../../../../functions/copyInBuffer';
import Animate from '../../../Animate.jsx';

const selects = require('../../../../infos/selects.json');

class BankCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderBlock = this.renderBlock.bind(this);
        this.deleteCard = this.deleteCard.bind(this);

        this.parent = React.createRef();
    }

    getView(number) {
        const re = new RegExp('^4');

        if (number.match(re) != null) {
            return 'Visa';
        }

        if (
            /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                number,
            )
        ) {
            return 'Mastercard';
        }

        return '–';
    }

    blocks = {
        info: {
            render() {
                const { _id, number, type, handlerEditmode, isCurrent, isDisabled } = this.props;
                const textType = selects.bankCardTypes.items.find(
                    (item) => item.key === type,
                )?.content;
                const lastNumber =
                    number && (number.length > 4 ? number.slice(number.length - 4) : number);
                const view = this.getView(number);

                return (
                    <>
                        <div className="manualOrganizationsBankCard__col _row _lastNumber">
                            <div className="manualOrganizationsBankCard__number">*{lastNumber}</div>
                        </div>
                        <div className="manualOrganizationsBankCard__col _row _type">{textType}</div>
                        <div className="manualOrganizationsBankCard__col _row _view">{view}</div>
                        <div className="manualOrganizationsBankCard__col _row _number">
                            {number}
                            <div
                                className="manualOrganizationsBankCard__copy _col _click"
                                onClick={() => {
                                    copyInBuffer(number, 'success-copy-cardNumber');
                                }}
                            >
                                <i className="manualOrganizationsBankCard__copyIcon">
                                    <Icon name="copy" />
                                </i>
                            </div>
                        </div>
                        <div className="manualOrganizationsBankCard__actions _row">
                            <Animate
                                className="manualOrganizationsBankCard__currentInfo"
                                isShow={isCurrent}
                            >
                                Основной счёт
                            </Animate>
                            {!isDisabled && (
                                <div
                                    className="manualOrganizationsBankCard__action _click"
                                    onClick={() => {
                                        handlerEditmode({ editName: _id });
                                    }}
                                >
                                    <Icon name="actions-edit" />
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        edit: {
            render() {
                const {
                    _id,
                    number,
                    type,
                    changeCard,
                    isCurrent,
                    isSaveCurrent,
                    saveCard,
                    loadingKey,
                    errors,
                } = this.props;

                return (
                    <>
                        <div className="manualOrganizationsBankCard__col _row _selectType">
                            <Select
                                name="type"
                                value={type}
                                type="bankCardTypes"
                                className="_bankCard"
                                isDisabled={false}
                                setStateComplete={true}
                                isEditmode={true}
                                callback={changeCard}
                                error={!!errors?.type}
                            />
                        </div>
                        <div className="manualOrganizationsBankCard__col _row _inputNumber">
                            <Field
                                keyName="numberAnyCard"
                                name="number"
                                value={number}
                                className="_bankCard"
                                isDisabled={false}
                                isEditmode={true}
                                callback={changeCard}
                                error={!!errors?.number}
                            />
                        </div>
                        <Animate
                            className="manualOrganizationsBankCard__col _row _setCurrent"
                            isShow={!isSaveCurrent}
                        >
                            <label className="manualOrganizationsBankCard__current _row">
                                Сделать основным счётом
                                <div className="manualOrganizationsBankCard__currentSwitch">
                                    <Switch
                                        value={isCurrent}
                                        name="idOfCurrentBankCard"
                                        handler={changeCard}
                                    />
                                </div>
                            </label>
                        </Animate>
                        <div className="manualOrganizationsBankCard__actions _row">
                            <div className="manualOrganizationsBankCard__actionsButton">
                                <Button
                                    className="_crm"
                                    onClick={() => {
                                        saveCard({ id: _id });
                                    }}
                                    showLoader={loadingKey === _id}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    getBlocks() {
        const { isEdit, isNew } = this.props;

        return !isEdit && !isNew ? [{ key: 'info' }] : [{ key: 'edit' }];
    }

    renderBlock({ prop: key }) {
        const block = this.blocks[key];

        return (
            <div className={`manualOrganizationsBankCard__block _row _${key}`}>
                {block.render.call(this)}
            </div>
        );
    }

    deleteCard() {
        const { isNew, idOfClient, parent, _id: idOfDeleteBankCard, deleteSaveCard } = this.props;
        const target = this.parent.current.querySelector('.manualOrganizationsBankCard__delete');

        if (isNew) {
            deleteSaveCard();
        } else {
            const deleteCard = () =>
                new Promise((resolve, reject) => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/organization`,
                            {
                                action: 'changeInfo',
                                fields: {
                                    idOfDeleteBankCard,
                                },
                                id: idOfClient,
                            },
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then(
                            (res) => {
                                const { success } = res.data;

                                if (success) {
                                    setNotification({
                                        notification: 'success-delete-bank-card',
                                    });

                                    deleteSaveCard(idOfDeleteBankCard);

                                    resolve();
                                } else {
                                    handlerErrorRequest(res);

                                    reject();
                                }
                            },
                            () => null,
                        );
                });

            handlerWindow({
                parent,
                parentResize: this.parent.current,
                target,
                action: 'show',
                name: 'promptBankCardDelete',
                className: '_prompt _userAdd',
                uniqKey: `prompt-${idOfDeleteBankCard}`,
                deleteCard,
                centers: {
                    left: 0.5,
                    top: 1,
                },
            });
        }

        // setNotification({ notification: 'prompt-delete-client', callback });
    }

    render() {
        const { isProccess } = this.state;
        const { isCurrent, isDisabled } = this.props;

        return (
            <div
                ref={this.parent}
                className={`manualOrganizationsBankCard ${isCurrent ? '_current' : ''} ${
                    isProccess ? '_isProccess' : ''
                } ${isDisabled ? '_disabled' : ''}`}
            >
                <ListAbsoluteMain
                    className="manualOrganizationsBankCard__inner"
                    items={this.getBlocks()}
                    renderItem={this.renderBlock}
                    classNameItem="manualOrganizationsBankCard__block"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={[]}
                    isNotParamsItem={true}
                    proccessCallback={({ type }) => {
                        if (!this.state.isProccess && type === 'add') {
                            this.setState({ isProccess: true });
                        }
                        if (this.state.isProccess && type === 'delete') {
                            this.setState({ isProccess: false });
                        }
                    }}
                />
                {!isDisabled && (
                    <i className="manualOrganizationsBankCard__delete _click" onClick={this.deleteCard}>
                        <Icon name="actions-delete" />
                    </i>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(BankCard);

BankCard.propTypes = {
    _id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
    isEdit: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isSaveCurrent: PropTypes.bool,
    handlerEditmode: PropTypes.func,
    idOfClient: PropTypes.string,
    parent: PropTypes.object,
    changeCard: PropTypes.func,
    deleteSaveCard: PropTypes.func,
    isNew: PropTypes.bool,
    saveCard: PropTypes.func,
    loadingKey: PropTypes.string,
    errors: PropTypes.array,
    isDisabled: PropTypes.bool,
};
