import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';
import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import Link from '../../../Link.jsx';
import Icon from '../../../Icon.jsx';
import { inputValidate } from '../../../../functions/inputValidate';

class ManualOrganizationsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderAction = this.renderAction.bind(this);
        this.changeOrganization = this.changeOrganization.bind(this);
    }

    getDescriptionInfo() {
        const { organization } = this.props;
        const description = organization.bankName || '–';

        return {
            description,
        };
    }

    fields = [
        { name: 'bic' },
        { name: 'bankAccount' },
        { name: 'addressatName' },
        { name: 'bankName', isReadOnly: true },
    ];

    actions = [
        {
            key: 'link',
            order: 0,
        },
    ];

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    changeOrganization({ ...props }) {
        const { changeOrganization, getCompanyInfo } = this.props;

        return new Promise((resolve) => {
            if (
                props.name === 'bic' &&
                props.action === 'change' &&
                inputValidate({ name: 'bic', value: props.value })
            ) {
                this.handlerLoadingKey('bic').then(() => {
                    getCompanyInfo({ bic: props.value }).then(
                        () => {
                            this.handlerLoadingKey(null);
                        },
                        () => null,
                    );
                });
            }

            changeOrganization({ ...props }).then(resolve);
        });
    }

    renderAction({ prop: key }) {
        const { organization } = this.props;

        return (
            <div className="manualCard__headAction">
                {key === 'link' && (
                    <Link
                        pageName="manual-organizations-inner"
                        className="manualCard__headLink _row"
                        ids={{ 2: organization._id }}
                    >
                        На страницу
                        <i className="manualCard__headLinkIcon">
                            <Icon name="arrow-next" />
                        </i>
                    </Link>
                )}
            </div>
        );
    }

    render() {
        const { loadingKey } = this.state;
        const { organization, checkEditmode = () => false, errors = [], type } = this.props;

        const { description } = this.getDescriptionInfo();

        return (
            <div className={`manualCard _organizationInfo ${type ? `_${type}` : ''}`}>
                <div className="manualCard__head _row">
                    <Head title="Платёжная информация" description={description} />
                    {type === 'preview' && organization?.isCorporation !== true && (
                        <div className="manualCard__headInfo">
                            <ListAbsoluteMain
                                className="manualCard__headActions"
                                items={this.actions}
                                renderItem={this.renderAction}
                                classNameItem="manualCard__headAction"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['width']}
                            />
                        </div>
                    )}
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            const { [name]: value } = organization;

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="organization"
                                        name={name}
                                        handler={this.changeOrganization}
                                        group="organizationMain"
                                        isDisabled={!checkEditmode()}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                        isLoading={name === 'bic' && loadingKey === 'bic'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualOrganizationsInfo);

ManualOrganizationsInfo.propTypes = {
    organization: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeOrganization: PropTypes.func,
    type: PropTypes.string,
    getCompanyInfo: PropTypes.func,
};
