import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ImageLazy from '../../ImageLazy.jsx';
import Link from '../../Link.jsx';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import StatusModel from '../StatusModel.jsx';
import ListScroll from '../../ListScroll.jsx';
import Icon from '../../Icon.jsx';
import CorporationInfo from './CorporationInfo.jsx';

import getUserInfo from '../../../functions/getUserInfo';
import getFormatPrice from '../../../functions/getFormatPrice';
import sortForAlphabet from '../../../functions/sortForAlphabet';
import setServerData from '../../../functions/setServerData';
import getFormatedDate, { namesMonths } from '../../../functions/getFormatedDate';
import shortText from '../../../functions/shortText';
import handlerSupportCursor from '../../../functions/handlerSupportCursor';
import copyInBuffer from '../../../functions/copyInBuffer';
import setSpacesInText from '../../../functions/setSpacesInText';

import Switch from '../../Switch.jsx';
import ExecutorOrg from '../ExecutorOrg.jsx';
import removeTransition from '../../../functions/removeTransition.ts';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import getDiffTime from '../../../functions/getDiffTime';
import getEndText from '../../../functions/getEndText';
import setPermissions from '../../../functions/crm/setPermissions';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import handlerPopup from '../../../functions/app/handlerPopup';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import MailingProgressCircle from './MailingProgressCircle.jsx';
import Checkbox from '../../Checkbox.jsx';
import Avatar from '../../Avatar.jsx';
import getExecutorIcon from '../../../functions/getExecutorIcon';
import FileItem from '../../chat/File.jsx';

const selects = require('../../../infos/selects.json');
const selectsInfo = require('../../../infos/selects.json');

class ManualTableInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };

        this.renderItem = this.renderItem.bind(this);
        this.renderTag = this.renderTag.bind(this);
        this.renderService = this.renderService.bind(this);
        this.sortItems = this.sortItems.bind(this);
        this.getSortItems = this.getSortItems.bind(this);
        this.handlerLoadingKey = this.handlerLoadingKey.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getParent = this.getParent.bind(this);
        this.getParentForScroll = this.getParentForScroll.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    getInfoEmpty() {
        const { name, items = [], isActiveFilter } = this.props;

        if (isActiveFilter?.length) {
            return {
                title: 'По вашему фильтру ничего не найдено',
                description: 'Попробуйте изменить критерии',
            };
        }

        if (items.length === 0) {
            if (name === 'executors') {
                return {
                    title: 'Исполнителей пока нет',
                    description: 'Вы можете добавить нового по кнопке справа',
                };
            }

            if (name === 'cars') {
                return {
                    title: 'Автомобилей пока нет',
                    description: 'Вы можете добавить нового по кнопке справа',
                };
            }

            if (name === 'tags') {
                return {
                    title: 'Тегов пока нет',
                    description: 'Вы можете добавить новый по кнопке справа',
                };
            }

            if (name === 'modelsOfCar') {
                return {
                    title: 'Моделей пока нет',
                    description: 'Вы можете добавить новую по кнопке справа',
                };
            }

            if (name === 'joinDocs') {
                return {
                    title: 'Документов пока нет',
                    description: 'Вы можете добавить новый по кнопке справа',
                };
            }

            if (name === 'joinScripts') {
                return {
                    title: 'Сценариев пока нет',
                    description: 'Вы можете добавить новый по кнопке справа',
                };
            }

            if (name === 'users') {
                return {
                    title: 'Сотрудников пока нет',
                    description: 'Вы можете пригласить нового по кнопке справа',
                };
            }

            if (name === 'corporationReports') {
                return {
                    title: 'Отчётов пока нет',
                    description: 'Они формируются каждое 1-е число месяца',
                };
            }

            if (name === 'paysLimits') {
                return {
                    title: 'Лимиты не найдены',
                    description: 'При создании исполнителей, они будут отображаться здесь',
                };
            }

            if (name === 'mailings') {
                return {
                    title: 'Рассылок нет',
                    description: 'Вы можете добавить новую рассылку по кнопке справа',
                };
            }

            if (name === 'chatTemplates') {
                return {
                    title: 'Шаблонов нет',
                    description: 'Вы можете добавить новый шаблон по кнопке справа',
                };
            }

            if (name === 'payGroups') {
                return {
                    title: 'Реестр актов пуст',
                    description: 'Здесь будут отображаться все реестры',
                };
            }

            if (name === 'logs' || name === 'modelLogs') {
                return {
                    title: 'Логов пока нет',
                    description: 'Здесь будут отображаться все логи',
                };
            }

            return {
                title: 'Список пуст',
                description: 'Вы можете добавить новую сущность по кнопке справа',
            };
        }

        return {};
    }

    orderCols = {
        executors: ['fullname', 'organization', 'type', 'phone', 'balance', 'status', 'contract'],
        cars: [
            'id',
            'status',
            'bodywork',
            'mark',
            'model',
            'loadings',
            'number',
            'size',
            'tonnage',
            'services',
        ],
        tariffs: ['id', 'status', 'type', 'category', 'typeOfHolder', 'nameOfHolder', 'name'],
        tags: ['name', 'nameOfGroup', 'color', 'dateOfCreate'],
        modelsOfCar: ['id', 'name', 'nameOfMark', 'dateOfCreate'],
        joinDocs: ['name', 'filesCounter', 'dateOfCreate'],
        joinScripts: ['isActive', 'name', 'templateName', 'docs', 'joinCounter'],
        joinTemplates: ['name', 'dateOfCreate'],
        executorMvds: ['name', 'status', 'date', 'number', 'mvdStatus', 'comment'],
        users: ['name', 'email', 'status', 'role', 'proxy'],
        paysTax: ['fullName', 'status', 'amount', 'prevAmount', 'debt'],
        corporationReports: ['period', 'name', 'amount', 'status'],
        corporations: ['id', 'name', 'inn', 'bic', 'users', 'balance', 'status'],
        paysLimits: [
            'name',
            'yearAmount',
            'yearLimit',
            'yearAmountRemains',
            'monthAmount',
            'monthLimit',
            'monthAmountRemains',
        ],
        mailings: ['number', 'date', 'tags', 'content', 'files', 'actions'],
        chatTemplates: ['name', 'tags', 'files', 'content'],
        chatTemplatesPopup: ['name', 'content'],
        executorsBalance: ['name', 'amountMove', 'amount', 'user', 'date'],
        payGroups: ['name', 'counter', 'amount', 'date', 'signed', 'completed'],
        modelLogs: ['content', 'user', 'date'],
    };

    cols = {
        executors: {
            status: { support: 'Статус' },
            type: { support: 'Категория' },
            fullname: { support: 'ФИО' },
            balance: { support: 'Баланс' },
            organization: { support: 'ОПФ' },
            phone: { support: 'Телефон' },
            car: { support: 'Автомобиль (габариты+тоннаж)' },
            tags: { support: 'Теги' },
            contract: { support: 'Договор' },
        },
        cars: {
            id: { support: 'ID' },
            status: { support: 'Статус' },
            bodywork: { support: 'Тип' },
            mark: { support: 'Марка' },
            model: { support: 'Модель' },
            loadings: { support: 'Погруз.' },
            number: { support: 'Гос. номер' },
            size: { support: 'Габариты' },
            tonnage: { support: 'Грузоподъём.' },
            services: { support: 'Доп. услуги' },
        },
        tariffs: {
            id: { support: 'ID' },
            status: { support: 'Статус' },
            type: { support: 'Тип' },
            category: { support: 'Категория' },
            typeOfHolder: { support: 'Тип контрагента' },
            nameOfHolder: { support: 'Контрагент' },
            name: { support: 'Название тарифа' },
        },
        contracts: {
            id: { support: 'ID' },
            userName: { support: 'ФИО' },
            type: { support: 'Тип' },
            opf: { support: 'ОПФ' },
            name: { support: 'Наименование' },
            inn: { support: 'ИНН' },
            idEdo: { support: 'Договор' },
            contract: { support: 'Статус' },
            counterOrders: { support: 'Заказы' },
        },
        tags: {
            id: { support: 'ID' },
            name: { support: 'Название' },
            nameOfGroup: { support: 'Группа' },
            color: { support: 'Цвет' },
            dateOfCreate: { support: 'Дата создания' },
        },
        modelsOfCar: {
            id: { support: 'ID' },
            name: { support: 'Название' },
            nameOfMark: { support: 'Марка' },
            dateOfCreate: { support: 'Дата создания' },
        },
        joinDocs: {
            name: { support: 'Название' },
            filesCounter: { support: 'Кол-во файлов' },
            dateOfCreate: { support: 'Дата создания' },
        },
        joinScripts: {
            isActive: { support: 'Активен' },
            name: { support: 'Название сценария' },
            templateName: { support: 'Шаблон договора' },
            docs: { support: 'Документы' },
            joinCounter: { support: 'Регистраций' },
        },
        joinTemplates: {
            name: { support: 'Название шаблона' },
            dateOfCreate: { support: 'Дата создания' },
        },
        executorMvds: {
            name: { support: 'ФИО исполнителя' },
            country: { support: 'Гражданство' },
            status: { support: 'Статус' },
            date: { support: 'Дата подачи' },
            number: { support: '№ заявления' },
            mvdStatus: { support: 'Заявление' },
            comment: { support: 'Комментарий' },
        },
        users: {
            name: { support: 'ФИО' },
            email: { support: 'E-mail' },
            role: { support: 'Роль' },
            status: { support: 'Статус' },
            proxy: { support: 'Доверенность' },
        },
        paysTax: {
            fullName: { support: 'ФИО исполнителя' },
            status: { support: 'Статус копилки' },
            amount: { support: 'Отложено' },
            prevAmount: { support: () => `За ${this.props.otherData?.monthName}` },
            debt: { support: 'Задолженность' },
        },
        corporationReports: {
            period: { support: 'Период' },
            name: { support: 'Документ' },
            amount: { support: 'Сумма' },
            status: { support: 'Статус' },
        },
        corporations: {
            id: { support: 'ID' },
            name: { support: 'Название' },
            inn: { support: 'ИНН' },
            bic: { support: 'БИК' },
            users: { support: 'Сотрудники' },
            balance: { support: 'Баланс' },
            status: { support: 'Статус' },
        },
        paysLimits: {
            name: { support: 'ФИО исполнителя' },
            yearAmount: { support: 'Общий доход' },
            yearLimit: { support: 'Общий лимит' },
            yearAmountRemains: { support: 'Остаток' },
            monthAmount: { support: 'Доход в месяц' },
            monthLimit: { support: 'Лимит в месяц' },
            monthAmountRemains: { support: 'Остаток в месяц' },
        },
        mailings: {
            number: { support: '№' },
            date: { support: 'Дата' },
            tags: { support: 'Теги' },
            content: { support: 'Текст рассылки' },
            files: { support: 'Вложения' },
            actions: { support: 'Действия' },
        },
        chatTemplates: {
            name: { support: 'Название шаблона' },
            tags: { support: 'Теги' },
            content: { support: 'Текст шаблона' },
            files: { support: 'Вложения' },
        },
        chatTemplatesPopup: {
            name: { support: 'Название шаблона' },
            content: { support: 'Текст шаблона' },
        },
        executorsBalance: {
            name: { support: 'Наименование операции' },
            amountMove: { support: 'Сумма' },
            amount: { support: 'Доступно' },
            user: { support: 'Пользователь' },
            date: { support: 'Дата и время' },
        },
        payGroups: {
            name: { support: 'Название' },
            counter: { support: 'Акты' },
            amount: { support: 'Общая сумма' },
            date: { support: 'Дата загрузки' },
            signed: { support: 'Подписаны' },
            completed: { support: 'Оплачены' },
        },
        modelLogs: {
            content: { support: 'Действие' },
            user: { support: 'Пользователь' },
            date: { support: 'Дата и время' },
        },
    };

    renderTag({ item, full, fullText }) {
        const { colorOfGroup, name } = item;

        return (
            <div
                className="tableInfo__tag"
                onMouseEnter={(e) => {
                    if (!fullText) {
                        handlerSupportCursor({
                            action: 'enter',
                            content: name,
                            e,
                            data: { className: '_normalCase _center' },
                        });
                    }
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                <div
                    className={`tag ${full ? '_full' : ''}`}
                    style={
                        full
                            ? {
                                  background: colorOfGroup,
                              }
                            : {
                                  borderColor: colorOfGroup,
                                  color: colorOfGroup,
                              }
                    }
                >
                    {fullText ? name : name?.slice(0, 2)}
                </div>
            </div>
        );
    }

    renderService({ item }) {
        const { contentShort, content } = item;

        return (
            <div
                className={`tableInfo__service _${item.key}`}
                onMouseEnter={(e) => {
                    handlerSupportCursor({
                        action: 'enter',
                        content,
                        e,
                        data: { className: '_normalCase _center' },
                    });
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                {contentShort}
            </div>
        );
    }

    renderOpf({ organization }) {
        return (
            <div
                className={`tableInfo__opf ${
                    organization.organization === 'SMZ' && !organization.isJurStatusActive
                        ? '_error'
                        : ''
                }`}
                onMouseEnter={(e) => {
                    if (organization.organization === 'SMZ' && !organization.isJurStatusActive) {
                        handlerSupportCursor({
                            action: 'enter',
                            content: 'Исполнитель не подключен<br/>к LIVECARGO в «Мой налог»',
                            e,
                            data: {
                                className: '_normalCase _center',
                            },
                        });
                    }
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({
                        action: 'leave',
                        e,
                    });
                }}
            >
                {organization.opf || '–'}
            </div>
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    renderCol({ name: nameOfCol, item, corporation, corporationIndex }) {
        const { user, loadingKey } = this.state;
        const { serverData, name, isBlock, handlerMore, getActionsItems, updateItems, otherData } =
            this.props;
        let content = null;
        let colChildren = null;
        let className = '';

        if (name === 'executors') {
            const executor = item;

            if (nameOfCol === 'type' && corporationIndex === 0) {
                content = executor.typeText || '–';
            }

            if (nameOfCol === 'fullname' && corporationIndex === 0) {
                className = '_visible';

                if (user?.idOfCurrentCorporation === 'admin') {
                    content = (
                        <>
                            <Link
                                className="tableInfo__colText _link _darkLink"
                                pageName="manual-executors-inner-main"
                                ids={{ 3: item._id }}
                                isStopPropagation={true}
                            >
                                {getUserInfo({ type: 'name', user: executor }) || '–'}
                            </Link>
                            , <span className="_noWrap">{executor.phone}</span>
                        </>
                    );
                } else {
                    content = (
                        <Link
                            className="tableInfo__colText _link _darkLink"
                            pageName="manual-executors-inner-main"
                            ids={{ 3: item._id }}
                            isStopPropagation={true}
                        >
                            {getUserInfo({ type: 'name', user: executor }) || '–'}
                        </Link>
                    );
                }
            }

            if (nameOfCol === 'organization' && corporationIndex === 0) {
                className = '_visible';

                content = this.renderOpf({
                    organization: {
                        organization: executor.organization,
                        isJurStatusActive: executor.isJurStatusActive,
                        opf: executor.opf,
                    },
                });
            }

            if (nameOfCol === 'phone' && corporationIndex === 0) {
                content = executor.phone;
            }

            if (nameOfCol === 'status') {
                className = '_visible';

                let statusClassName = '_wait';

                if (corporation.status === 'active') {
                    statusClassName = '_active';
                }

                if (corporation.status === 'inactive') {
                    statusClassName = '_wait';
                }

                if (corporation.status === 'reject') {
                    statusClassName = '_alert';
                }

                if (corporation.status === 'process') {
                    statusClassName = '';
                }

                content = (
                    <div className="tableInfo__colInner">
                        <div className={`tableInfo__status _block ${statusClassName}`}>
                            {corporation.statusText}
                        </div>
                    </div>
                );
            }

            if (nameOfCol === 'contract') {
                let statusClassName = '_default';
                let contractText;

                if (!corporation.contract || corporation.contract.status === 'inactive') {
                    statusClassName = '_alert';
                }

                if (corporation.contract) {
                    contractText = `№${
                        corporation.contract?.number
                    }, ${corporation.contract.statusText?.toLowerCase()}   ${
                        corporation.contract.status && corporation.contract.date
                            ? ` ${getFormatedDate({
                                  date: new Date(corporation.contract.date),
                                  isShortYear: true,
                              })}`
                            : ''
                    }`;
                }

                content = (
                    <div className="tableInfo__colInner">
                        <div className={`tableInfo__status ${statusClassName}`}>
                            {corporation.contract ? (
                                <>
                                    <div
                                        className="tableInfo__statusInner"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(contractText),
                                        }}
                                    ></div>
                                </>
                            ) : (
                                <>Не заключён</>
                            )}
                        </div>
                    </div>
                );
            }

            if (nameOfCol === 'balance') {
                content = (
                    <div
                        className={`tableInfo__balance ${corporation.balance < 0 ? '_minus' : ''}`}
                    >
                        {`${getFormatPrice(corporation.balance)}₽`}
                    </div>
                );
            }
        }

        if (name === 'cars') {
            const car = item;

            if (nameOfCol === 'id') {
                content = car.id;
            }

            if (nameOfCol === 'status') {
                const { carStatuses } = selectsInfo;

                className = '_visible';

                content = (
                    <div className="tableInfo__colInner">
                        <StatusModel name="car" statuses={carStatuses.items} status={car.status} />
                    </div>
                );
            }

            if (nameOfCol === 'bodywork') {
                let src = `${process.env.REACT_APP_STATIC}/car-icons/${car.icon}`;
                const support = car.bodyworkName;

                if (car?.type === 'trailer') {
                    src = require('../../../img/crm/trailer-icon.png');
                }

                content = (
                    <div className="tableInfo__colInner">
                        <div className="tableInfo__car _row">
                            <div
                                className="tableInfo__carPreview _col"
                                onMouseEnter={(e) => {
                                    if (support) {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: support,
                                            e,
                                            data: { className: '_normalCase _center' },
                                        });
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (support) {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }
                                }}
                            >
                                <ImageLazy src={src} className="tableInfo__carPreviewIcon" />
                            </div>
                        </div>
                    </div>
                );
            }

            if (nameOfCol === 'mark') {
                if (user?.idOfCurrentCorporation === 'admin') {
                    content = `${car.nameMark}, ${car.nameModel}, ${car.number}`;
                } else {
                    content = car.nameMark;
                }
            }

            if (nameOfCol === 'model') {
                content = car.nameModel;
            }

            if (nameOfCol === 'loadings') {
                const { loadingsCar } = selectsInfo;
                const { items: itemsLoadings } = loadingsCar;
                const itemsLoadingsActive = itemsLoadings.filter((itemLoadings) =>
                    car.loadings?.find((itemLoop) => itemLoop.name === itemLoadings.key),
                );

                content = itemsLoadingsActive
                    .map((itemLoadings) => itemLoadings.content)
                    .join(', ');

                if (itemsLoadingsActive.length === 0) {
                    content = '–';
                }
            }

            if (nameOfCol === 'number') {
                content = car.number;
            }

            if (nameOfCol === 'size') {
                const { long, width, height, heightWithFloor } = car;
                const sizeResult = `${long}*${width}*${height}см</br>Общая высота: ${heightWithFloor}см`;

                content = (
                    <div
                        className="tableInfo__colInner"
                        dangerouslySetInnerHTML={{ __html: sizeResult }}
                    ></div>
                );
            }

            if (nameOfCol === 'tonnage') {
                const { tonnage, pallets } = car;
                const tonnageResult = `${tonnage}кг</br>(${pallets} пал.)`;

                content = (
                    <div
                        className="tableInfo__colInner"
                        dangerouslySetInnerHTML={{ __html: tonnageResult }}
                    ></div>
                );
            }

            if (nameOfCol === 'services') {
                const { services = [] } = car;
                const sortServices = sortForAlphabet({ arr: services, prop: 'content' });

                content = (
                    <div className="tableInfo__colInner _full">
                        {sortServices.length ? (
                            <div className="tableInfo__services _row">
                                {sortServices.map((service) =>
                                    this.renderService({ item: service }),
                                )}
                            </div>
                        ) : (
                            '–'
                        )}
                    </div>
                );
            }
        }

        if (name === 'tariffs') {
            const { tariffs } = serverData;
            const tariff = item;
            let typeOfHolder;

            if (tariff.holder === 'admin-client' || tariff.typeOfHolder === 'client') {
                typeOfHolder = 'Клиент';
            } else if (tariff.holder === 'admin-executor' || tariff.typeOfHolder === 'executor') {
                typeOfHolder = 'Исполнитель';
            } else {
                typeOfHolder = '–';
            }

            if (nameOfCol === 'id') {
                content = tariff.id;
            }

            if (nameOfCol === 'status') {
                content = (
                    <Animate className="tableInfo__colInner _animate" isShow={!!tariffs}>
                        <StatusModel
                            name="tariff"
                            statuses={tariffs?.statuses}
                            status={tariff.status.name}
                            date={tariff.status.date}
                        />
                    </Animate>
                );
            }

            if (nameOfCol === 'type') {
                const type = tariffs?.types.find(
                    (innerType) => innerType.key === tariff.type,
                )?.content;

                if (user?.idOfCurrentCorporation === 'admin') {
                    content = `${type}, ${typeOfHolder}`;
                } else {
                    content = type;
                }
            }

            if (nameOfCol === 'category') {
                let description;

                if (tariff.typeOfHolder === 'admin') {
                    description = 'Базовый';
                } else {
                    description = 'Персональный';
                }

                content = (
                    <div
                        className={`tableInfo__tariffCategory _row ${
                            tariff.typeOfHolder === 'admin' ? '_admin' : ''
                        }`}
                    >
                        {tariff.typeOfHolder === 'admin' && (
                            <i className="tableInfo__tariffCategoryLock">
                                <Icon name="lock" />
                            </i>
                        )}
                        {description}
                    </div>
                );
            }

            if (nameOfCol === 'typeOfHolder') {
                content = typeOfHolder;
            }

            if (nameOfCol === 'nameOfHolder') {
                content = tariff.nameOfHolder || '–';
            }

            if (nameOfCol === 'name') {
                const description = tariff.name || '–';

                content = (
                    <div
                        className="tableInfo__colInner"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                );
            }
        }

        if (name === 'tags') {
            const tag = item;

            if (nameOfCol === 'name') {
                // if (user?.idOfCurrentCorporation === 'admin') {
                //     content = `${tag.name}, ${tag.nameOfGroup}`;
                // } else {
                //     content = tag.name;
                // }

                content = tag.name;
            }

            if (nameOfCol === 'nameOfGroup') {
                content = tag.nameOfGroup;
            }

            if (nameOfCol === 'color') {
                content = (
                    <div className="tableInfo__colInner">
                        <div
                            className="tableInfo__color"
                            style={{ background: tag.colorOfGroup }}
                        ></div>
                    </div>
                );
            }

            if (nameOfCol === 'dateOfCreate') {
                content = getFormatedDate({ date: new Date(tag.createdDate) });
            }
        }

        if (name === 'modelsOfCar') {
            const modelOfCar = item;

            if (nameOfCol === 'id') {
                content = modelOfCar.id;
            }

            if (nameOfCol === 'name') {
                if (user?.idOfCurrentCorporation === 'admin') {
                    content = `${modelOfCar.name}, ${modelOfCar.nameOfMark}`;
                } else {
                    content = modelOfCar.name;
                }
            }

            if (nameOfCol === 'nameOfMark') {
                content = modelOfCar.nameOfMark;
            }

            if (nameOfCol === 'dateOfCreate') {
                content = getFormatedDate({ date: new Date(modelOfCar.createdDate) });
            }
        }

        if (name === 'joinDocs') {
            if (nameOfCol === 'name') {
                content = item.name;
            }

            if (nameOfCol === 'filesCounter') {
                content = (
                    <>
                        {item.filesCounter}{' '}
                        {getEndText(item.filesCounter, ['файл', 'файла', 'файлов'])}
                    </>
                );
            }

            if (nameOfCol === 'dateOfCreate') {
                content = getFormatedDate({ date: new Date(item.createdDate) });
            }
        }

        if (name === 'joinScripts') {
            className = '_visible';

            if (nameOfCol === 'isActive') {
                const { handlerSwitch } = this.props;

                content = (
                    <>
                        <div className="tableInfo__joinScripts _col">
                            <div
                                className="tableInfo__joinScriptsSwitch"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onMouseEnter={(e) => {
                                    if (item.isDisabled) {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: 'В данный момент<br/>проводятся подключения',
                                            e,
                                            data: { className: '_normalCase _center' },
                                        });
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (item.isDisabled) {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }
                                }}
                            >
                                <Switch
                                    value={item.isActive}
                                    className="_green _size2"
                                    // isDisabled={item.isDisabled}
                                    handler={() => {
                                        if (!loadingKey && !item.isDisabled) {
                                            this.handlerLoadingKey(item._id).then(() => {
                                                handlerSwitch({
                                                    id: item._id,
                                                    isActive: !item.isActive,
                                                }).then(
                                                    () => {
                                                        this.handlerLoadingKey(null);
                                                    },
                                                    () => null,
                                                );
                                            });
                                        }
                                    }}
                                />
                                <Animate
                                    className="tableInfo__joinScriptsSwitchLoader"
                                    isShow={loadingKey === item._id}
                                >
                                    <Loader className="_main" />
                                </Animate>
                            </div>
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'name') {
                content = (
                    <>
                        <div className="tableInfo__joinScripts _col">
                            <div className="tableInfo__joinScriptsName">{item.name}</div>
                            <div className="tableInfo__joinScriptsLink">
                                /join/{item.link}
                                <div
                                    className="tableInfo__joinScriptsLinkCopy _col _click"
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        copyInBuffer(
                                            `${process.env.REACT_APP_APP_DOMEN}/join/${item.link}`,
                                        );
                                    }}
                                >
                                    <i className="tableInfo__joinScriptsLinkCopyIcon">
                                        <Icon name="copy" />
                                    </i>
                                </div>
                            </div>
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'templateName') {
                if (user?.idOfCurrentCorporation === 'admin') {
                    content = (
                        <>
                            {item.templateName}, документы:{' '}
                            {item.docs.map((doc) => doc.name).join(', ')}
                        </>
                    );
                } else {
                    content = <>{item.templateName}</>;
                }
            }

            if (nameOfCol === 'docs') {
                content = <>{item.docs.map((doc) => doc.name).join(', ')}</>;
            }

            if (nameOfCol === 'joinCounter') {
                content = item.joinCounter || 0;
            }
        }

        if (name === 'joinTemplates') {
            if (nameOfCol === 'name') {
                content = item.name;
            }

            if (nameOfCol === 'dateOfCreate') {
                content = getFormatedDate({ date: new Date(item.createdDate) });
            }
        }

        if (name === 'executorMvds') {
            if (nameOfCol === 'name') {
                className = '_visible';

                content = (
                    <>
                        <Link
                            pageName="manual-executors-inner-main"
                            ids={{ 3: item.executorId }}
                            className="tableInfo__colText _click _darkLink"
                        >
                            {item.fullName || '–'}
                        </Link>
                        , {item.country}
                        <div
                            className={`tableInfo__colText _block ${
                                item.contractInactive && item.type !== 'reject' ? '_grey' : ''
                            }`}
                        >
                            {item.type === 'reject' ? (
                                <>На расторжение договора №{item.contractNumber}</>
                            ) : (
                                <>
                                    Договор №{item.contractNumber}{' '}
                                    {item.contractInactive ? '(Расторгнут)' : ''}
                                </>
                            )}
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'country') {
                content = item.country || '–';
            }

            if (nameOfCol === 'status') {
                let statusText;
                let statusClassName = '';

                if (item.executorStatus === 'active') {
                    statusText = 'Работает';
                    statusClassName = '_active';
                }

                if (item.executorStatus === 'inactive') {
                    statusText = 'Уволен';
                    statusClassName = '_alert';
                }

                className = '_visible';

                content = (
                    <div className="tableInfo__colInner">
                        <div className={`tableInfo__status ${statusClassName}`}>
                            {statusText}
                            {item.executorStatus === 'inactive' && item.inactiveDate && (
                                <i
                                    className="tableInfo__statusInfo _click"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: getFormatedDate({
                                                date: new Date(item.inactiveDate),
                                                type: 'full',
                                            }),
                                            e,
                                            data: {
                                                className: '_normalCase _center',
                                            },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({
                                            action: 'leave',
                                            e,
                                        });
                                    }}
                                >
                                    <Icon name="info" />
                                </i>
                            )}
                        </div>
                    </div>
                );
            }

            if (nameOfCol === 'date') {
                if (user?.idOfCurrentCorporation === 'admin') {
                    if (!item.date && !item.number) {
                        content = '–';
                    } else {
                        content = (
                            <>
                                {item.date ? (
                                    <>
                                        Дата{' '}
                                        {getFormatedDate({
                                            date: new Date(item.date),
                                            isShortYear: true,
                                        })}
                                    </>
                                ) : null}
                                {item.number ? (
                                    <>
                                        {item.date ? ',' : ''} №&nbsp;{item.number}
                                    </>
                                ) : null}
                            </>
                        );
                    }
                } else {
                    content = item.date
                        ? getFormatedDate({ date: new Date(item.date), isShortYear: true })
                        : '–';
                }
            }

            if (nameOfCol === 'number') {
                content = item.number || '–';
            }

            if (nameOfCol === 'mvdStatus') {
                let statusText;
                let statusClassName = '';

                if (item.status === 'notsent') {
                    statusText = (
                        <>
                            Не подано
                            {!item.contractInactive || item.type === 'reject' ? (
                                <>
                                    <br />
                                    (Осталось {getDiffTime(item.alarmDate)})
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    );

                    statusClassName =
                        !item.contractInactive || item.type === 'reject' ? '_alert' : '';
                }

                if (item.status === 'sent') {
                    statusText = 'Подано';
                    statusClassName = '_active';
                }

                if (item.status === 'complete') {
                    statusText = 'Получено';
                    statusClassName = '_active';
                }

                if (item.status === 'inactive') {
                    statusText = 'Не актуально';
                    statusClassName = '';
                }

                if (item.status === 'reject') {
                    statusText = 'Отказ';
                    statusClassName = '';
                }

                className = '_visible';

                content = (
                    <>
                        <div className="tableInfo__colInner">
                            <div className={`tableInfo__status ${statusClassName}`}>
                                {statusText}
                                {item.statusDate && (
                                    <i
                                        className="tableInfo__statusInfo _click"
                                        onMouseEnter={(e) => {
                                            handlerSupportCursor({
                                                action: 'enter',
                                                content: getFormatedDate({
                                                    date: new Date(item.statusDate),
                                                    type: 'full',
                                                }),
                                                e,
                                                data: {
                                                    className: '_normalCase _center',
                                                },
                                            });
                                        }}
                                        onMouseLeave={(e) => {
                                            handlerSupportCursor({
                                                action: 'leave',
                                                e,
                                            });
                                        }}
                                    >
                                        <Icon name="info" />
                                    </i>
                                )}
                            </div>
                        </div>
                        <>
                            <div className={`tableInfo__colText _block _grey`}>
                                {item.files.length === 0 ? (
                                    'Файлов нет'
                                ) : (
                                    <>
                                        {item.files.length}{' '}
                                        {getEndText(item.files.length, ['файл', 'файла', 'файлов'])}
                                    </>
                                )}
                            </div>
                        </>
                    </>
                );
            }

            if (nameOfCol === 'comment') {
                className = '_visible';

                content = (
                    <>
                        {handlerMore && getActionsItems?.({ item }).length > 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    handlerMore(item, e);
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}

                        <div className="tableInfo__colInner _col">
                            <div className="tableInfo__comment _empty _info">
                                {item.comment || '–'}
                                {item.commentCreaterName && (
                                    <i
                                        className="tableInfo__commentInfo _click"
                                        onMouseEnter={(e) => {
                                            handlerSupportCursor({
                                                action: 'enter',
                                                content: item.commentCreaterName,
                                                e,
                                                data: {
                                                    className: '_normalCase _center',
                                                },
                                            });
                                        }}
                                        onMouseLeave={(e) => {
                                            handlerSupportCursor({
                                                action: 'leave',
                                                e,
                                            });
                                        }}
                                    >
                                        <Icon name="info" />
                                    </i>
                                )}
                            </div>
                        </div>
                    </>
                );
            }
        }

        if (name === 'users') {
            if (corporationIndex === 0) {
                if (nameOfCol === 'name') {
                    content = (
                        <>
                            {item.fullName}
                            {user?.idOfCurrentCorporation === 'admin' ? `, ${item.email}` : ''}
                        </>
                    );
                }

                if (nameOfCol === 'email') {
                    content = item.email;
                }

                if (nameOfCol === 'status') {
                    let lastAuthDateStr;

                    if (item.lastAuthDateTime) {
                        const lastAuthDateInst = new Date(item.lastAuthDateTime);
                        const dateDiff =
                            (new Date().getTime() - lastAuthDateInst.getTime()) / 1_000;

                        if (dateDiff < 60) {
                            lastAuthDateStr = 'Только что';
                        } else if (dateDiff < 60 * 60) {
                            lastAuthDateStr = `${Math.round(dateDiff / 60)} мин. назад`;
                        } else if (dateDiff < 60 * 60 * 24) {
                            lastAuthDateStr = `${Math.round(dateDiff / (60 * 60))} ч. назад`;
                        } else if (dateDiff < 60 * 60 * 24 * 30) {
                            lastAuthDateStr = `${Math.round(dateDiff / (60 * 60 * 24))} д. назад`;
                        } else {
                            lastAuthDateStr = 'давно';
                        }
                    }

                    content = (
                        <>
                            <div
                                className={`tableInfo__status ${
                                    item.status === 'online'
                                        ? '_active'
                                        : item.status === 'offline'
                                        ? '_wait'
                                        : ''
                                }`}
                            >
                                {item.statusText}
                                {item.status === 'offline' && item.lastAuthDateTime > 0 && (
                                    <>
                                        <br />({lastAuthDateStr})
                                    </>
                                )}
                            </div>
                        </>
                    );
                }
            }

            if (nameOfCol === 'role') {
                content = (
                    <div className="tableInfo__colInner">
                        <div
                            className={`tableInfo__colText  ${
                                !corporation.roleName ? '_grey' : ''
                            }`}
                        >
                            {corporation.roleName || 'Нет роли'}
                        </div>
                    </div>
                );
            }

            if (nameOfCol === 'proxy') {
                const signContract = () => {
                    handlerPopup({
                        name: 'signContractPopup',
                        isShow: true,
                        items: [corporation.proxy.id],
                        renderStatus: () => (
                            <div
                                className={`tableInfo__proxyStatus _popup _${corporation.proxy?.status}`}
                            >
                                {corporation.proxy.statusText}
                            </div>
                        ),
                        successCallback: updateItems,
                        type: 'userProxy',
                    });
                };

                const showContractPopup = () => {
                    handlerPopup({
                        name: 'joinContractPopup',
                        isShow: true,
                        type: 'userProxy',
                        userName: item.fullName,
                        userId: item._id,
                        successCallback: updateItems,
                    });
                };

                content = (
                    <>
                        {handlerMore && corporationIndex === 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    handlerMore(item, e);
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}
                        <div className="tableInfo__colInner">
                            <div className="tableInfo__proxy _col">
                                <div
                                    className={`tableInfo__proxyStatus _${corporation.proxy?.status}`}
                                >
                                    {corporation.proxy?.statusText}
                                </div>
                                {corporation.proxy?.status === 'complete' && (
                                    <>
                                        <div className="tableInfo__proxyDescription">
                                            Действует:
                                            <br /> до{' '}
                                            {getFormatedDate({
                                                date: new Date(corporation.proxy.actionDate),
                                            })}
                                        </div>
                                    </>
                                )}
                                {corporation._id === user.idOfCurrentCorporation &&
                                    getCurrentCorporation({ user })?.role === 'holder' &&
                                    !getCurrentCorporation({ user })?.isAdmin && (
                                        <>
                                            {['required', 'inactive'].includes(
                                                corporation.proxy?.status,
                                            ) && (
                                                <>
                                                    <div className="tableInfo__proxyButton">
                                                        <div
                                                            className="tableInfo__button _row _click"
                                                            onClick={showContractPopup}
                                                        >
                                                            Создать
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {['executorSign', 'wait'].includes(
                                                corporation.proxy?.status,
                                            ) && (
                                                <>
                                                    <div className="tableInfo__proxyButton">
                                                        <div
                                                            className="tableInfo__button _row _click"
                                                            onClick={signContract}
                                                        >
                                                            Подписать
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                {['corporationSign', 'wait'].includes(corporation.proxy?.status) &&
                                    item._id === user._id &&
                                    user.idOfCurrentCorporation === corporation._id && (
                                        <>
                                            <div className="tableInfo__proxyButton">
                                                <div
                                                    className="tableInfo__button _row _click"
                                                    onClick={signContract}
                                                >
                                                    Подписать
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                    </>
                );
            }
        }

        if (name === 'paysTax') {
            if (corporationIndex === 0) {
                if (nameOfCol === 'fullName') {
                    className = '_visible';

                    content = (
                        <>
                            <Link
                                pageName="manual-executors-inner-main"
                                ids={{ 3: item._id }}
                                className="tableInfo__colText _link _darkLink"
                                isStopPropagation={true}
                            >
                                {item.fullName || '–'}
                            </Link>
                        </>
                    );
                }

                if (nameOfCol === 'status') {
                    let status;

                    if (item.fnsTax === 'active') {
                        status = '_active';
                    } else if (item.fnsTax === 'inactive') {
                        status = '_alert';
                    } else if (item.fnsTax === 'inactive-process') {
                        status = '_alert';
                    } else if (item.fnsTax === 'withdraw-process') {
                        status = '_medium';
                    } else {
                        status = '_wait';
                    }

                    content = (
                        <div className={`tableInfo__status ${status}`}>
                            {item.statusText}
                            {item.fnsTaxDate && (
                                <i
                                    className="tableInfo__statusInfo _click"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: getFormatedDate({
                                                date: new Date(item.fnsTaxDate),
                                                type: 'full',
                                            }),
                                            e,
                                            data: {
                                                className: '_normalCase _center',
                                            },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({
                                            action: 'leave',
                                            e,
                                        });
                                    }}
                                >
                                    <Icon name="info" />
                                </i>
                            )}
                        </div>
                    );
                }
            }

            if (nameOfCol === 'amount') {
                content = <>{getFormatPrice(corporation.amount || 0)} ₽</>;
            }

            if (nameOfCol === 'prevAmount') {
                content = <>{getFormatPrice(corporation.prevAmount || 0)} ₽</>;
            }

            if (nameOfCol === 'debt') {
                content = (
                    <>
                        {corporationIndex === 0 && getActionsItems({ item })?.length > 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => handlerMore(item, e)}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}
                        {getFormatPrice(corporation.debt || 0)} ₽
                    </>
                );
            }
        }

        if (name === 'corporationReports') {
            if (nameOfCol === 'period') {
                content = (
                    <>
                        <div className="tableInfo__colInner">
                            <b>
                                {namesMonths[item.month][0]} {item.year}
                            </b>
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'name') {
                content = (
                    <>
                        <div className="tableInfo__colInner _col">
                            {item.fileName ? (
                                <>
                                    <a
                                        className="tableInfo__colText _click _link _darkLink"
                                        target="_blank"
                                        href={`${process.env.REACT_APP_STATIC}/corporations-reports/${item.fileName}`}
                                        rel="noreferrer"
                                    >
                                        Отчёт №{item.number} CRM LIVECARGO
                                    </a>
                                </>
                            ) : (
                                <>
                                    <div className="tableInfo__colText _loading">
                                        Формируем отчёт
                                        <div className="tableInfo__colTextLoader">
                                            <Loader className="_grey" />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'amount') {
                content = <>{getFormatPrice(item.amount)} ₽</>;
            }

            if (nameOfCol === 'status') {
                const { signReport } = otherData;

                const preSignReport = async () => {
                    await this.handlerLoadingKey(item._id);

                    await signReport({ id: item._id });

                    await this.handlerLoadingKey(null);

                    updateItems();
                };

                let statusClassName = '_wait';

                if (item.status === 'sign') {
                    statusClassName = '_active';
                }

                className = '_visible';

                content = (
                    <>
                        <div className="tableInfo__colInner _row">
                            <div className={`tableInfo__status ${statusClassName}`}>
                                {item.statusText}
                            </div>
                            {item.status === 'notSign' &&
                                this.getPermissions({
                                    notAdminCorporation: true,
                                    key: 'settings',
                                    items: [{ key: 'reports', rules: ['sign'] }],
                                }) && (
                                    <div className="tableInfo__reportButton">
                                        <div
                                            className="tableInfo__button _row _click"
                                            onClick={preSignReport}
                                        >
                                            <Animate
                                                className="tableInfo__buttonLoader _loader"
                                                isShow={loadingKey === item._id}
                                            >
                                                <div className="tableInfo__buttonLoaderItem _loaderItem">
                                                    <Loader />
                                                </div>
                                            </Animate>
                                            Подписать
                                        </div>
                                    </div>
                                )}
                        </div>
                        {handlerMore && getActionsItems?.({ item }).length > 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    handlerMore(item, e);
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}
                    </>
                );
            }
        }

        if (name === 'corporations') {
            if (nameOfCol === 'id') {
                content = <>{item.id}</>;
            }

            if (nameOfCol === 'name') {
                className = '_visible';

                content = (
                    <>
                        <div className="tableInfo__colInner _col">
                            <div className="tableInfo__corporation">
                                <CorporationInfo logo={item.logo} name={item.name} />
                            </div>
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'inn') {
                content = item.inn;
            }

            if (nameOfCol === 'bic') {
                content = item.bic;
            }

            if (nameOfCol === 'users') {
                content = item.usersCounter;
            }

            if (nameOfCol === 'balance') {
                content = <>{getFormatPrice(item.balance)} ₽</>;
            }

            if (nameOfCol === 'status') {
                const status = item.statusText;
                const date = item.statusDate;
                let statusClassName = '';

                if (item.status === 'active') {
                    statusClassName = '_active';
                } else {
                    statusClassName = '_wait';
                }

                content = (
                    <div className="tableInfo__colInner _col">
                        <div className={`tableInfo__status ${statusClassName}`}>
                            {status}{' '}
                            {date && (
                                <i
                                    className="tableInfo__statusInfo _click"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: getFormatedDate({
                                                date: new Date(date),
                                                type: 'full',
                                            }),
                                            e,
                                            data: {
                                                className: '_normalCase _center',
                                            },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({
                                            action: 'leave',
                                            e,
                                        });
                                    }}
                                >
                                    <Icon name="info" />
                                </i>
                            )}
                        </div>
                    </div>
                );
            }
        }

        if (name === 'paysLimits') {
            if (nameOfCol === 'name') {
                className = '_visible';

                if (corporationIndex === 0) {
                    content = (
                        <>
                            <div className="tableInfo__colInner">
                                <Link
                                    className="tableInfo__colText _marginRight _link _darkLink"
                                    pageName="manual-executors-inner"
                                    ids={{ 3: item._id }}
                                >
                                    {item.fullName}
                                </Link>
                                <div className="tableInfo__executorOrg">
                                    <ExecutorOrg className="_min _grey" name={item.organization} />
                                </div>
                            </div>
                        </>
                    );
                }
            } else if (nameOfCol !== 'corporationInfo') {
                const value = item.corporations[corporationIndex]?.[nameOfCol];
                let textClass = '';

                if (nameOfCol === 'yearAmountRemains' || nameOfCol === 'monthAmountRemains') {
                    const mediumVal = nameOfCol === 'yearAmountRemains' ? 500_000 : 100_000;
                    const lowVal = nameOfCol === 'yearAmountRemains' ? 150_000 : 30_000;
                    let backClass = '';

                    if (value < mediumVal) {
                        backClass = '_medium';
                        textClass = '_mediumColor';
                    }

                    if (value < lowVal) {
                        backClass = '_low';
                        textClass = '_lowColor';
                    }

                    colChildren = (
                        <>
                            <div className={`tableInfo__colBack ${backClass}`} />
                        </>
                    );
                }

                const textProps = {};

                if (
                    nameOfCol === 'yearAmount' &&
                    item.corporations[corporationIndex].isRealyAmount
                ) {
                    textClass = '_successColor _medium';

                    textProps.onMouseEnter = (e) => {
                        handlerSupportCursor({
                            action: 'enter',
                            content: 'Подтвержденный<br/>доход ФНС',
                            e,
                            data: { className: '_normalCase _center' },
                        });
                    };

                    textProps.onMouseLeave = (e) => {
                        handlerSupportCursor({ action: 'leave', e });
                    };
                }

                content = (
                    <>
                        {nameOfCol === 'monthAmountRemains' && (
                            <>
                                {handlerMore &&
                                    getActionsItems({ corporationIndex }).length > 0 && (
                                        <i
                                            className="tableInfo__more _click"
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                handlerMore(
                                                    item,
                                                    item.corporations[corporationIndex],
                                                    corporationIndex,
                                                    e,
                                                );
                                            }}
                                        >
                                            <Icon name="more-vertical" />
                                        </i>
                                    )}
                            </>
                        )}
                        <div className="tableInfo__colInner">
                            <div className={`tableInfo__colText ${textClass}`} {...textProps}>
                                {getFormatPrice(value)} ₽
                            </div>
                        </div>
                    </>
                );
            }
        }

        if (name === 'mailings') {
            if (nameOfCol === 'number') {
                content = <>{item.number}</>;
            }

            if (nameOfCol === 'date') {
                content = <>{getFormatedDate({ date: new Date(item.createdDate) })}</>;
            }

            if (nameOfCol === 'tags') {
                const { tagsInfo = [] } = item;
                const sortTags = sortForAlphabet({ arr: tagsInfo });

                className = '_visible';

                content = (
                    <>
                        {sortTags.length ? (
                            <div className="tableInfo__tags _row _show">
                                {sortTags.map((tag) =>
                                    this.renderTag({ item: tag, full: true, fullText: true }),
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="tableInfo__colText _grey">–</div>
                            </>
                        )}
                    </>
                );
            }

            if (nameOfCol === 'content') {
                content = <>{shortText({ str: item.content, stop: 200 })}</>;
            }

            if (nameOfCol === 'files') {
                content = (
                    <>
                        {item.files.length ? (
                            <>
                                {item.files.length}{' '}
                                {getEndText(item.files.length, ['файл', 'файла', 'файлов'])}
                            </>
                        ) : (
                            <>
                                <div className="tableInfo__colText _grey">Нет</div>
                            </>
                        )}
                    </>
                );
            }

            if (nameOfCol === 'actions') {
                className = '_visible';

                let status = 'wait';
                let title = 'Запустить рассылку';
                let description = `${item.allCounter} исполнитель`;

                if (item.isComplete) {
                    title = 'Отправлено';
                    description = `${item.allCounter} исполнитель`;
                    status = 'completed';
                } else if (item.inPause) {
                    title = 'Продолжить';
                    description = `Отправлено: ${item.completedCounter} из ${item.allCounter}`;
                    status = 'pause';
                } else if (item.inProcess) {
                    title = 'Прервать рассылку';
                    description = `Отправлено: ${item.completedCounter} из ${item.allCounter}`;
                    status = 'process';
                }

                const progress = item.completedCounter / item.allCounter;

                content = (
                    <>
                        {handlerMore && getActionsItems?.({ item }).length > 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    handlerMore(item, e);
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}
                        <div
                            className={`tableInfo__mailingAction ${
                                !item.isComplete ? '_click' : ''
                            } _${status}`}
                            onClick={(e) => {
                                if (!item.isComplete) {
                                    e.stopPropagation();

                                    if (!loadingKey) {
                                        const action =
                                            !item.inProcess || item.inPause ? 'start' : 'pause';

                                        this.handlerLoadingKey(item._id).then(() => {
                                            otherData.start({ id: item._id, action }).then(
                                                () => {
                                                    this.handlerLoadingKey(null);
                                                },
                                                () => {
                                                    this.handlerLoadingKey(null);
                                                },
                                            );
                                        });
                                    }
                                }
                            }}
                        >
                            <div className="tableInfo__mailingActionTitle">
                                <AnimateChangeUp
                                    className="tableInfo__mailingActionTitleInner"
                                    renderKey={status}
                                    parentStyles={['height']}
                                >
                                    {title}
                                </AnimateChangeUp>
                                <div
                                    className={`tableInfo__mailingActionBtn _col ${
                                        loadingKey === item._id ? '_loading' : ''
                                    } _${status}`}
                                >
                                    <Animate
                                        className="tableInfo__mailingActionBtnLoader"
                                        isShow={loadingKey === item._id}
                                    >
                                        <Loader className="_main" />
                                    </Animate>
                                    <Animate
                                        className="tableInfo__mailingActionBtnProgress"
                                        isShow={status === 'process' || status === 'pause'}
                                    >
                                        <MailingProgressCircle progress={progress} />
                                    </Animate>
                                    <Animate
                                        className="tableInfo__mailingActionBtnIcon"
                                        isShow={status === 'pause' || status === 'wait'}
                                    >
                                        <Icon name="mailing-play" />
                                    </Animate>
                                    <Animate
                                        className="tableInfo__mailingActionBtnIcon"
                                        isShow={status === 'process'}
                                    >
                                        <Icon name="mailing-pause" />
                                    </Animate>
                                    <Animate
                                        className="tableInfo__mailingActionBtnIcon"
                                        isShow={status === 'completed'}
                                    >
                                        <Icon name="mailing-completed" />
                                    </Animate>
                                </div>
                            </div>
                            <AnimateChangeUp
                                className="tableInfo__mailingActionDescription"
                                renderKey={status}
                                parentStyles={['height']}
                            >
                                {description}
                            </AnimateChangeUp>
                            <div className="tableInfo__mailingActionProgress">
                                <div
                                    className="tableInfo__mailingActionProgressLine"
                                    style={{
                                        width: `${progress * 100}%`,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                );
            }
        }

        if (name === 'chatTemplates' || name === 'chatTemplatesPopup') {
            if (nameOfCol === 'name') {
                if (name === 'chatTemplatesPopup') {
                    content = (
                        <>
                            <div className="tableInfo__choice _row">
                                <div className="tableInfo__checkbox">
                                    <Checkbox
                                        className="_square _blue"
                                        name={item._id}
                                        value={otherData.currentId === item._id}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="tableInfo__colText">{item.name}</div>
                            </div>
                        </>
                    );
                } else {
                    content = <>{item.name}</>;
                }
            }

            if (nameOfCol === 'tags') {
                const { tagsInfo = [] } = item;
                const sortTags = sortForAlphabet({ arr: tagsInfo });

                className = '_visible';

                content = (
                    <>
                        {sortTags.length ? (
                            <div className="tableInfo__tags _row _show">
                                {sortTags.map((tag) =>
                                    this.renderTag({ item: tag, full: true, fullText: true }),
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="tableInfo__colText _grey">–</div>
                            </>
                        )}
                    </>
                );
            }

            if (nameOfCol === 'files') {
                content = (
                    <>
                        {item.files.length ? (
                            <>
                                {item.files.length}{' '}
                                {getEndText(item.files.length, ['файл', 'файла', 'файлов'])}
                            </>
                        ) : (
                            <>
                                <div className="tableInfo__colText _grey">Нет</div>
                            </>
                        )}
                    </>
                );
            }

            if (nameOfCol === 'content') {
                content = (
                    <>
                        {handlerMore && getActionsItems?.({ item }).length > 0 && (
                            <i
                                className="tableInfo__more _click"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    handlerMore(item, e);
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        )}
                        <div className="tableInfo__colText _block">{item.content}</div>
                        {name === 'chatTemplatesPopup' && (
                            <>
                                <div className="tableInfo__files _row">
                                    {item.files.map((file) => (
                                        <div className="tableInfo__file" key={file._id}>
                                            <FileItem
                                                className="_white"
                                                withOpen={true}
                                                file={{
                                                    ...file,
                                                    src: `${process.env.REACT_APP_STATIC}/chat-template/${file.path}`,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                );
            }
        }

        if (name === 'executorsBalance') {
            if (nameOfCol === 'name') {
                content = <>{item.content}</>;
            }

            if (nameOfCol === 'amountMove') {
                content = (
                    <>
                        <div
                            className={`tableInfo__colText _medium ${
                                item.amount > 0 ? '_successColor' : '_alertColor'
                            }`}
                        >
                            {item.amount > 0 ? '+' : ''}
                            {getFormatPrice(item.amount)} ₽
                        </div>
                    </>
                );
            }

            if (nameOfCol === 'amount') {
                content = (
                    <>
                        <div className="tableInfo__colText">{getFormatPrice(item.total)} ₽</div>
                    </>
                );
            }

            if (nameOfCol === 'user') {
                content = (
                    <>
                        <div className="tableInfo__colInner">{this.renderUser({ item })}</div>
                    </>
                );
            }

            if (nameOfCol === 'date') {
                content = (
                    <>
                        <div
                            className="tableInfo__colText"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    getFormatedDate({
                                        date: new Date(item.createdDate),
                                        type: 'full',
                                    }),
                                ),
                            }}
                        />
                    </>
                );
            }
        }

        if (name === 'payGroups') {
            if (nameOfCol === 'name') {
                content = (
                    <div
                        className="tableInfo__colInner"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(item.name) }}
                    ></div>
                );
            }

            if (nameOfCol === 'counter') {
                const { counter, amount, createdDate } = item;

                if (user?.idOfCurrentCorporation === 'admin') {
                    content = (
                        <div className="tableInfo__colInner">
                            {counter} {getEndText(counter, ['акт', 'акта', 'актов'])},{' '}
                            <span className="_noWrap">{getFormatPrice(+amount.toFixed(2))} ₽</span>,{' '}
                            {getFormatedDate({ date: new Date(createdDate) })}
                        </div>
                    );
                } else {
                    content = <div className="tableInfo__colInner">{counter}</div>;
                }
            }

            if (nameOfCol === 'amount') {
                const { amount } = item;

                content = (
                    <div className="tableInfo__colInner">
                        {getFormatPrice(+amount.toFixed(2))} ₽
                    </div>
                );
            }

            if (nameOfCol === 'date') {
                content = (
                    <div className="tableInfo__colInner">
                        {getFormatedDate({ date: new Date(item.createdDate) })}
                    </div>
                );
            }

            if (nameOfCol === 'signed' || nameOfCol === 'completed') {
                const { counter } = item;
                const resultCounter =
                    nameOfCol === 'signed' ? item.signedCounter : item.completedCounter;
                const completePercent = (resultCounter / counter) * 100;

                content = (
                    <>
                        <div
                            className={`tableInfo__progress ${
                                counter === resultCounter ? '_complete _full' : ''
                            }`}
                        >
                            <div className="tableInfo__progressInner">
                                <div
                                    className="tableInfo__progressItem"
                                    style={{ width: `${completePercent}%` }}
                                >
                                    <div className="tableInfo__progressItemInner" />
                                </div>
                            </div>
                            <div className="tableInfo__progressDescription">
                                {resultCounter} из {counter}
                            </div>
                        </div>
                    </>
                );
            }
        }

        if (name === 'modelLogs') {
            if (nameOfCol === 'content') {
                content = (
                    <>
                        <div
                            className="tableInfo__colInner"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(item.content) }}
                        />
                    </>
                );
            }

            if (nameOfCol === 'user') {
                content = <>{this.renderUser({ item })}</>;
            }

            if (nameOfCol === 'date') {
                content = (
                    <>
                        {getFormatedDate({
                            date: new Date(item.createdDate),
                            type: 'full',
                            withSeconds: true,
                        })}
                    </>
                );
            }
        }

        if (nameOfCol === 'corporationInfo') {
            className = '_visible';

            if (isBlock) {
                content = (
                    <>
                        <div className="tableInfo__colInner _col">
                            {corporation?.name ? (
                                <div
                                    className={`tableInfo__corporation ${
                                        corporationIndex === 0 && item.corporations.length > 1
                                            ? '_list'
                                            : ''
                                    }`}
                                >
                                    <CorporationInfo {...corporation} />
                                </div>
                            ) : (
                                <>–</>
                            )}
                        </div>
                    </>
                );
            } else {
                content = item.corporationInfo?.length ? (
                    <>
                        {item.corporationInfo.map((innerItem, key) => (
                            <div className="tableInfo__corporation" key={key}>
                                <CorporationInfo {...innerItem} />
                            </div>
                        ))}
                    </>
                ) : (
                    '–'
                );
            }
        }

        return (
            <div className={`tableInfo__col _col _${nameOfCol} ${className}`} key={nameOfCol}>
                {colChildren}
                <div className="tableInfo__colContent">{content}</div>
            </div>
        );
    }

    getOrderCols() {
        const { user } = this.state;
        const { name } = this.props;
        const orderCols = [...this.orderCols[name]];

        if (
            user?.idOfCurrentCorporation === 'admin' &&
            ![
                'corporations',
                'mailings',
                'chatTemplates',
                'chatTemplatesPopup',
                'executorsBalance',
            ].includes(name)
        ) {
            let adminIndex = 1;

            if (name === 'executors') {
                adminIndex = 4;
            }

            if (name === 'executorMvds') {
                adminIndex = 3;
            }

            if (name === 'users') {
                adminIndex = 3;
            }

            if (name === 'paysTax') {
                adminIndex = 2;
            }

            if (name === 'payGroups') {
                adminIndex = 0;
            }

            orderCols.splice(adminIndex, 0, 'corporationInfo');

            let deletesCols = [];

            if (name === 'executors') {
                deletesCols = ['phone'];
            }

            if (name === 'cars') {
                deletesCols = ['model', 'number'];
            }

            if (name === 'tariffs') {
                deletesCols = ['typeOfHolder'];
            }

            if (name === 'executorMvds') {
                deletesCols = ['country', 'number'];
            }

            if (name === 'modelsOfCar') {
                deletesCols = ['nameOfMark'];
            }

            if (name === 'joinScripts') {
                deletesCols = ['docs'];
            }

            if (name === 'users') {
                deletesCols = ['email'];
            }

            if (name === 'payGroups') {
                deletesCols = ['amount', 'date'];
            }

            deletesCols.forEach((key) => {
                const index = orderCols.indexOf(key);

                if (index !== -1) {
                    orderCols.splice(index, 1);
                }
            });
        }

        return orderCols;
    }

    renderUser({ item, type, withLink = false }) {
        let user = item.user || item.userInfo;

        if (!user) {
            user = {
                secondName: 'Удалённый аккаунт',
            };
        }

        const { user: globalUser } = this.state;
        let username = user && getUserInfo({ type: 'name', user });
        let nameOfCreater = '';
        let src;
        let avatarText = user.type === 'clientOnPoint' ? user.secondName[0] : null;

        if (user.type === 'executor') {
            const role = user.crewInfo?.role || user.nameOfRole;

            nameOfCreater = selects.executorTypes.items.find(
                (innerType) => innerType.key === role,
            )?.content;
        } else if (user.type === 'clientOnPoint') {
            nameOfCreater = 'Клиент на точке';
        } else {
            nameOfCreater = user.nameOfRole || user.roleName;
        }

        if (type === 'short') {
            nameOfCreater += '<br/>';
            nameOfCreater += username;
        }

        if (user.crewInfo) {
            src = `${process.env.REACT_APP_STATIC}/images/${getExecutorIcon({
                type: user.crewInfo.role,
            })}`;
        }

        let iconType = '';

        if (user.type === 'system') {
            const currentCorporation = getCurrentCorporation({ user: globalUser });

            if (currentCorporation) {
                if (currentCorporation.isAdmin) {
                    src = require('../../../img/logo-short-area.svg').default;
                    username = 'CRM LIVECARGO';

                    iconType = 'image/svg+xml';
                } else {
                    const corporationName =
                        currentCorporation.shortName || currentCorporation.fullName;

                    username = `CRM ${corporationName || ''}`;

                    if (currentCorporation.logo?.path) {
                        src = `${process.env.REACT_APP_STATIC}/corporations/${currentCorporation.logo.path}`;

                        iconType = currentCorporation.logo.type;
                    } else {
                        avatarText = (corporationName || ' ')[0];
                    }
                }
            }
        }

        const TagAvatar = withLink ? Link : 'div';
        const propAvatar = withLink ? { href: 'manual-executors-inner', keyUniq: user._id } : {};

        return (
            <div
                className={`tableInfo__colUser _row ${user.type === 'system' ? '_system' : ''} ${
                    withLink ? '_withLink' : ''
                }`}
            >
                <TagAvatar
                    className="tableInfo__colUserAvatar"
                    onMouseEnter={(e) => {
                        if (user.type !== 'system' && nameOfCreater) {
                            handlerSupportCursor({
                                action: 'enter',
                                content: nameOfCreater,
                                e,
                                data: { className: '_normalCase _center' },
                            });
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (user.type !== 'system') {
                            handlerSupportCursor({ action: 'leave', e });
                        }
                    }}
                    callback={(e) => {
                        if (user.type !== 'system') {
                            handlerSupportCursor({ action: 'leave', e });
                        }
                    }}
                    {...propAvatar}
                >
                    <Avatar
                        className="_circle"
                        type={user.type}
                        src={src}
                        iconType={iconType}
                        holder={{
                            ...user,
                            text: avatarText,
                        }}
                    />
                </TagAvatar>
                {type !== 'short' && <p className="tableInfo__colUserName">{username}</p>}
            </div>
        );
    }

    renderItem({ item: itemCome, prop: _id, key, isLast }) {
        const infoHeight = this.getInfoHeight();
        const { activeBlockId } = this.state;
        const { items = [], name, isNotLink, isBlock, otherData } = this.props;
        const item = items.find((itemLoop) => itemLoop._id === _id) || itemCome;
        let className = '';

        const LinkTag = isNotLink ? 'div' : Link;
        const propsLink = {
            pageName: `manual-${name}-inner`,
            ids: { 3: item._id },
        };

        if (name === 'contracts') {
            if (item.typeContract === 'executor') {
                propsLink.pageName = 'manual-executors-inner-organization';
            }
        }

        if (name === 'joinDocs') {
            propsLink.pageName = 'joins-docs-inner';
        }

        if (name === 'joinScripts') {
            propsLink.pageName = 'joins-scripts-inner';
        }

        if (name === 'joinTemplates') {
            propsLink.pageName = 'joins-templates-inner';
        }

        if (name === 'executorMvds' && item.contractInactive && 0) {
            className = '_opacityHide';
        }

        if (name === 'corporations') {
            propsLink.pageName = 'corporations-inner';
            propsLink.ids = {
                2: item._id,
            };
        }

        if (name === 'mailings') {
            propsLink.pageName = 'mailing-inner';
            propsLink.ids = {
                2: item._id,
            };
        }

        if (name === 'chatTemplates') {
            propsLink.pageName = 'chat-template-inner';
            propsLink.ids = {
                2: item._id,
            };
        }

        if (name === 'chatTemplatesPopup') {
            className = '_clickForce';

            if (item._id === otherData.currentId) {
                className += ' _active';
            }

            propsLink.onClick = () => {
                otherData.setCurrent(item._id);
            };
        }

        if (name === 'payGroups') {
            propsLink.pageName = 'pays-groups-inner';
            propsLink.ids = {
                3: item._id,
            };
        }

        if (isBlock && item.corporations.length > 1) {
            propsLink.onClick = () => {
                this.setState({
                    activeBlockId: this.state.activeBlockId === _id ? null : _id,
                });
            };
        }

        const orderCols = this.getOrderCols();

        return (
            <LinkTag
                className={`tableInfo__row ${key % 2 === 0 ? '_odd' : ''} ${
                    infoHeight > 1 && isLast ? '_border' : ''
                } ${isBlock ? '_block' : ''} ${
                    isBlock && item.corporations.length > 1 ? '_click' : ''
                } ${activeBlockId === _id ? '_blockShow' : ''} ${className}`}
                {...propsLink}
                data-id={_id}
            >
                <div className="tableInfo__rowInner _row">
                    {isBlock ? (
                        <>
                            {item.corporations.map((corporation, corporationIndex) => (
                                <div
                                    className={`tableInfo__rowBlock _row ${
                                        corporationIndex === 0 ? '_main' : ''
                                    } ${['executorMvds'].includes(name) ? '_visible' : ''} ${
                                        item.corporations.length === 1 ? '_one' : ''
                                    }`}
                                    key={corporation._id}
                                >
                                    {orderCols.map((nameCol) =>
                                        this.renderCol({
                                            name: nameCol,
                                            item,
                                            corporation,
                                            corporationIndex,
                                        }),
                                    )}
                                </div>
                            ))}
                        </>
                    ) : (
                        <>{orderCols.map((nameCol) => this.renderCol({ name: nameCol, item }))}</>
                    )}
                </div>
            </LinkTag>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getHeadRowHeight() {
        const headRowDom = this.parent.current?.querySelector('.tableInfo__row._head');

        return headRowDom?.offsetHeight || 0;
    }

    getInfoHeight() {
        const { contentHeight = 0 } = this.state;
        const contentDom = this.parent.current;

        if (contentDom) {
            return contentDom.offsetHeight - this.getHeadRowHeight() - contentHeight;
        }

        return 0;
    }

    sortItems(a, b) {
        const { name } = this.props;

        if (name === 'tariffs') {
            const weightA = a.typeOfHolder === 'admin' ? 1 : 0;
            const weightB = b.typeOfHolder === 'admin' ? 1 : 0;

            return weightB - weightA;
        }

        return true;
    }

    getSortItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    items = [];

    getDatas() {
        const { name } = this.props;

        if (name === 'tariffs') {
            ['tariffs'].forEach((key) => {
                setServerData(key);
            });
        }
    }

    checkItems() {
        const { items } = this.props;

        if (items && !this.isCheckItems) {
            this.isCheckItems = true;

            const inner = this.parent.current.querySelector('.tableInfo__inner');
            const { offsetHeight } = inner;

            this.setState({ contentHeight: offsetHeight });
        }
    }

    getParent() {
        return this.parent.current;
    }

    getParentForScroll() {
        return this.parent.current?.querySelector('.tableInfo__scroll');
    }

    componentDidMount() {
        const { user } = this.props;

        this.getDatas();

        this.setState({ user });

        this.checkItems();
    }

    componentDidUpdate() {
        this.checkItems();
    }

    render() {
        const { user, contentHeight = 0, activeBlockId, isShowLoaderList = false } = this.state;
        let { heightContent } = this.state;
        const {
            name = '',
            isReady = false,
            getMoreItems,
            isLimit,
            keyUpdateList,
            isDisabledScroll,
            stepCounter,
            isLoading = false,
            isWrap,
            heightWindow,
            isRowHeight,
            isBlock,
            inTable,
            disabled,
            keyUpdateItem,
            isBlockSkinny,
        } = this.props;
        const orderCols = this.getOrderCols();
        const items = this.getSortItems(this.props.items || []);

        if (!heightContent || isLoading || !isReady || this.props.items.length === 0) {
            heightContent = 300;
        }

        const infoEmpty = this.getInfoEmpty();

        const infoHeight = this.getInfoHeight();

        return (
            <div
                ref={this.parent}
                className={`tableInfo _${name} ${isWrap ? '_wrapContent' : ''} ${
                    isLoading ? '_isLoading' : ''
                } ${isRowHeight ? '_rowHeight' : ''} ${isBlock ? '_rowBlock' : ''} ${
                    isBlockSkinny ? '_blockSkinny' : ''
                } ${user?.idOfCurrentCorporation === 'admin' ? '_corporationAdmin' : ''} ${
                    inTable ? '_inTable' : ''
                }`}
            >
                <Animate className="tableInfo__loader _loader" isShow={isLoading}>
                    <div className="tableInfo__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate
                    className={`tableInfo__scrollLoader _loaderScroll ${inTable ? '_white' : ''}`}
                    isShow={isShowLoaderList}
                >
                    <div className="tableInfo__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="tableInfo__scroll">
                    <div className="tableInfo__inner">
                        <div className="tableInfo__row _head">
                            <div className="tableInfo__rowInner _row">
                                {orderCols.map((nameOfCol) => {
                                    const col = this.cols[name][nameOfCol];
                                    let support =
                                        typeof col?.support === 'function'
                                            ? col?.support()
                                            : col?.support;

                                    if (user?.idOfCurrentCorporation === 'admin') {
                                        if (nameOfCol === 'corporationInfo') {
                                            support = 'Корпорация';
                                        }

                                        if (name === 'executors' && nameOfCol === 'fullname') {
                                            support = 'ФИО, телефон';
                                        }

                                        if (name === 'executorMvds' && nameOfCol === 'name') {
                                            support = 'ФИО, гражданство';
                                        }

                                        if (name === 'executorMvds' && nameOfCol === 'date') {
                                            support = 'Дата, № заявления';
                                        }

                                        if (name === 'modelsOfCar' && nameOfCol === 'name') {
                                            support = 'Название, марка';
                                        }

                                        if (
                                            name === 'joinScripts' &&
                                            nameOfCol === 'templateName'
                                        ) {
                                            support = 'Шаблон договора, документы';
                                        }

                                        if (name === 'users' && nameOfCol === 'name') {
                                            support = 'ФИО, E-mail';
                                        }
                                    }

                                    return (
                                        <div
                                            className={`tableInfo__col _col _${nameOfCol}`}
                                            key={nameOfCol}
                                        >
                                            {support}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <Animate
                            className="tableInfo__empty"
                            isShow={!isLoading && items.length === 0}
                        >
                            <div className="empty _col _block _notBack">
                                <AnimateChange
                                    className="empty__inner"
                                    type="_translate _transFast"
                                    prop={infoEmpty.title}
                                    isDisabled={items.length > 0}
                                >
                                    <div className="empty__innerItem">
                                        <div className="empty__title">{infoEmpty.title}</div>
                                        <p
                                            className="empty__content"
                                            dangerouslySetInnerHTML={{
                                                __html: infoEmpty.description,
                                            }}
                                        ></p>
                                    </div>
                                </AnimateChange>
                            </div>
                        </Animate>
                        {items.length !== 0 && (
                            <ListScroll
                                getParent={this.getParentForScroll}
                                callback={getMoreItems}
                                startCounter={stepCounter}
                                stepCounter={stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={items.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                keyUpdate={keyUpdateList}
                                isDisabledScroll={isDisabledScroll}
                            >
                                <ListAbsoluteMain
                                    className={`tableInfo__content _col ${
                                        isWrap ? '_wrapContent' : ''
                                    } ${inTable ? '_inTable' : ''}`}
                                    items={items}
                                    renderItem={this.renderItem}
                                    classNameItem="tableInfo__row"
                                    classNames={[
                                        `_${name}`,
                                        ...(isRowHeight ? ['_rowHeight'] : []),
                                        ...(user?.idOfCurrentCorporation === 'admin'
                                            ? ['_corporationAdmin']
                                            : []),
                                        ...(isBlock ? ['_rowBlock'] : []),
                                        ...(inTable ? ['_inTable'] : []),
                                        ...(isBlockSkinny ? ['_blockSkinny'] : []),
                                    ]}
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    itemParams={['offsetTop', 'height']}
                                    keyRender={`${heightWindow}${activeBlockId}`}
                                    keyUpdateItem={keyUpdateItem}
                                    callback={({ type, params }) => {
                                        if (type === 'parent') {
                                            this.setState({ contentHeight: params.height });
                                        }
                                        if (!this.isInit) {
                                            this.isInit = true;

                                            removeTransition({
                                                item: '.tableInfo__info',
                                                isCurrent: true,
                                            });
                                        }
                                    }}
                                    disabled={disabled}
                                />
                            </ListScroll>
                        )}
                        <Animate
                            className="tableInfo__info JSTransVertical"
                            style={{
                                height: infoHeight,
                                transform: `translate(0,${
                                    contentHeight + this.getHeadRowHeight()
                                }px)`,
                            }}
                            isShow={infoHeight >= 100 && !isLoading && items.length > 0}
                        >
                            <div className="empty _col _block _notBack">
                                <div className="empty__inner">
                                    <div className="empty__title">
                                        Это все записи
                                        <br />
                                        на данный момент
                                    </div>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        heightWindow: state.heightWindow,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualTableInfo);

ManualTableInfo.propTypes = {
    serverData: PropTypes.object,
    isReady: PropTypes.bool,
    items: PropTypes.array,
    name: PropTypes.string,
    isLoading: PropTypes.bool,
    filter: PropTypes.func,
    getMoreItems: PropTypes.func,
    isLimit: PropTypes.bool,
    keyUpdateList: PropTypes.any,
    isDisabledScroll: PropTypes.bool,
    stepCounter: PropTypes.number,
    isActiveFilter: PropTypes.bool,
    handlerLoaderList: PropTypes.func,
    heightWindow: PropTypes.number,
    isWrap: PropTypes.bool,
    isRowHeight: PropTypes.bool,
    handlerSwitch: PropTypes.func,
    user: PropTypes.object,
    isNotLink: PropTypes.bool,
    popupCallback: PropTypes.func,
    isBlock: PropTypes.bool,
    inTable: PropTypes.bool,
    handlerMore: PropTypes.func,
    otherData: PropTypes.object,
    isShowLoaderList: PropTypes.bool,
    getActionsItems: PropTypes.func,
    updateItems: PropTypes.func,
    disabled: PropTypes.bool,
    keyUpdateItem: PropTypes.any,
    isBlockSkinny: PropTypes.bool,
};
