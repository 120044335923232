import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import setNotification from '../functions/setNotification';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function handlerCarForExecutor({
    idOfExecutor,
    idOfCar,
    isDelete,
    isCurrent = false,
}) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/executor`,
                { action: 'handler-cars', id: idOfExecutor, idOfCar, isCurrent },
                { headers: getHeaders() },
            )
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success } = res.data;

                    if (success) {
                        if (isCurrent) {
                            setNotification({
                                notification: 'success-change-info',
                            });
                        } else {
                            setNotification({
                                notification: isDelete
                                    ? 'success-delete-car-to-executor'
                                    : 'success-add-car-to-executor',
                            });
                        }
                        resolve();
                    } else {
                        handlerErrorRequest(res);

                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
