import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ActionChange from '../../ActionChange.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

class ColsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderItem = this.renderItem.bind(this);
        this.getSortedOrder = this.getSortedOrder.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    getOrder() {
        const { order = [], cols, activeOrder = [], otherOrder = [] } = this.props;

        return order.concat(...otherOrder).map((key) => ({
            key,
            content: cols[key],
            isActive: activeOrder.includes(key),
        }));
    }

    getSortedOrder(order) {
        return JSON.parse(JSON.stringify(order)).sort((a, b) => {
            const weightA = a.isActive ? 1 : 0;
            const weightB = b.isActive ? 1 : 0;

            return weightB - weightA;
        });
    }

    renderItem({ item, prop: key }) {
        const { handler } = this.props;
        const { content, isActive } = item;

        return (
            <div className="reportsColsList__item _row" key={key}>
                {content}
                <ActionChange
                    className="reportsColsList__itemAction _click"
                    isShow={true}
                    isSmoothShow={true}
                    isHas={isActive}
                    onClick={() => {
                        handler(key);
                    }}
                />
            </div>
        );
    }

    handlerMissClick({ target, detail }) {
        const { stateHandler } = this.props;

        if (detail?.name) {
            if (detail?.name !== 'registryColsList') {
                stateHandler(false);
            }
        } else if (this.parent.current !== target && !this.parent.current.contains(target)) {
            stateHandler(false);
        }
    }

    componentDidMount() {
        setTimeout(() => {
            document.addEventListener('mousedown', this.handlerMissClick);
            document.addEventListener('continuePropagationClick', this.handlerMissClick);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handlerMissClick);
        document.removeEventListener('continuePropagationClick', this.handlerMissClick);
    }

    render() {
        const { activeOrder } = this.props;

        return (
            <div ref={this.parent} className="reportsColsList _windowWithCursor _topCenter">
                <div className="reportsColsList__inner">
                    <div className="reportsColsList__title">Выберите колонки:</div>
                    <div className="reportsColsList__content">
                        <div className="reportsColsList__contentInner">
                            <ListAbsoluteMain
                                className="reportsColsList__items _col"
                                items={this.getSortedOrder(this.getOrder())}
                                renderItem={this.renderItem}
                                classNameItem="reportsColsList__item"
                                prop="key"
                                paramsParent={{ width: 'auto' }}
                                styles={['width', 'height']}
                                propsForUpdate={['isActive']}
                                sort={this.getSortedOrder}
                                keyRender={activeOrder?.length}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ColsList);

ColsList.propTypes = {
    order: PropTypes.array,
    otherOrder: PropTypes.array,
    cols: PropTypes.object,
    activeOrder: PropTypes.array,
    handler: PropTypes.func,
    stateHandler: PropTypes.func,
};
