import React from 'react';

import setSpacesInText from '@functions/setSpacesInText.js';

import BusinessAboutI from '../types.ts';

const renderInfoBlock: BusinessAboutI['renderInfoBlock'] = function () {
    return (
        <div className="publicBusinessAbout__info _COL">
            <div className="publicBusinessAbout__infoHead _COL">
                <h3 className="publicBusinessAbout__infoTitle _TITLE _PUBLIC">
                    <span>Мы предлагаем</span> умное&nbsp;решение
                </h3>
                <div
                    className="publicBusinessAbout__infoDescription _TEXT _PUBLIC"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(
                            'Вместо ручных выплат и бумажной волокиты — автоматизация процессов от и до. Подключайте, формируйте, подписывайте и выплачивайте в несколько кликов.',
                        ),
                    }}
                ></div>
            </div>
            <div className="publicBusinessAbout__infoContent">
                {this.infoCards.map((card, cardKey) => {
                    const { title, content } = card;

                    return (
                        <div
                            className={`publicBusinessAbout__infoCardWrapper _${cardKey + 1}`}
                            key={cardKey}
                        >
                            <div className="publicBusinessAbout__infoCard">
                                <div className="publicBusinessAbout__infoCardNumber">
                                    {cardKey + 1}
                                </div>
                                <div className="publicBusinessAbout__infoCardTitle">{title}</div>
                                <div
                                    className="publicBusinessAbout__infoCardContent"
                                    dangerouslySetInnerHTML={{ __html: setSpacesInText(content) }}
                                ></div>
                            </div>
                        </div>
                    );
                })}
                <div className="publicBusinessAbout__infoSupport">
                    <b>Наш онлайн-сервис помогает&nbsp;бизнесу</b> упростить процесс выплат и
                    документо-оборота со всеми видами внештатного персонала.
                </div>
            </div>
        </div>
    );
};

export default renderInfoBlock;
