import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getFormatedDate from '../functions/getFormatedDate';
import removeTransition from '../functions/removeTransition.ts';
import getFormatedNumber from '../functions/getFormatedNumber.ts';

import Icon from './Icon.jsx';
import Calendar from './Calendar.jsx';
import Animate from './Animate.jsx';

class DateClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 'calendar',
            times: {
                start: {
                    hours: '',
                    minutes: '',
                },
                end: {
                    hours: '',
                    minutes: '',
                },
            },
        };

        this.setHeightBlocks = this.setHeightBlocks.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.handlerTime = this.handlerTime.bind(this);
        this.handlerDate = this.handlerDate.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    nowDate = new Date();

    blurTime({ name, key }) {
        const { times } = this.state;
        const time = times[name];
        let value = time[key];

        if (value.length === 1) {
            value = `0${value}`;
        }

        this.handlerTime({ name, key, value });
    }

    focusInput(name, key) {
        this.parent.current.querySelector(`input[name="${name}-${key}"]`).focus();
    }

    checkCurrentDate() {
        const { currentDate } = this.state;
        const CurrentDate = new Date(currentDate);
        let isCurrent = false;

        const nowDate = new Date();

        nowDate.setMinutes(nowDate.getMinutes() + 15);

        if (currentDate) {
            if (
                nowDate.getFullYear() === CurrentDate.getFullYear() &&
                nowDate.getMonth() === CurrentDate.getMonth() &&
                nowDate.getDate() === CurrentDate.getDate()
            ) {
                isCurrent = true;
            }
        }

        return { isCurrent, nowDate, CurrentDate };
    }

    handlerTime({ action, name, key, value }) {
        const { type, withPast } = this.props;
        const { times } = this.state;
        const updatesInfo = [];
        let valueCheck;

        const { isCurrent, nowDate } = this.checkCurrentDate();

        let isPastDisabled = false;

        if (!withPast && isCurrent) {
            isPastDisabled = true;
        }

        if (action === 'blur') {
            if (times[name][key] && times[name][key].length !== 2) {
                valueCheck = getFormatedNumber(times[name][key]);
            } else {
                valueCheck = times[name][key];
            }
        } else {
            valueCheck = value.replace(/[^0-9]/g, '');

            if (valueCheck.length > 2) {
                valueCheck = valueCheck.slice(-2);
            }

            if (key === 'minutes' && +valueCheck > 59) {
                valueCheck = '59';
            }

            if (key === 'hours' && +valueCheck > 23) {
                valueCheck = '23';
            }

            if (isPastDisabled && valueCheck.length === 2) {
                if (key === 'hours' && +valueCheck <= nowDate.getHours()) {
                    valueCheck = getFormatedNumber(nowDate.getHours());

                    if (
                        times[name].minutes.toString().length === 2 &&
                        +times[name].minutes < nowDate.getMinutes()
                    ) {
                        updatesInfo.push({
                            name,
                            key: 'minutes',
                            value: nowDate.getMinutes(),
                        });
                    }
                }
                if (key === 'minutes' && +times[name].hours === nowDate.getHours()) {
                    if (+valueCheck < nowDate.getMinutes()) {
                        valueCheck = getFormatedNumber(nowDate.getMinutes());
                    }
                }
            }

            if (name === 'start') {
                if (key === 'hours') {
                    if (
                        type !== 'oneTime' &&
                        valueCheck.length === 2 &&
                        times.end.hours.length === 2 &&
                        +valueCheck > +times.end.hours
                    ) {
                        valueCheck = times.end.hours;
                    }
                    if (valueCheck.length === 2) {
                        this.focusInput('start', 'minutes');
                    }

                    if (
                        type !== 'oneTime' &&
                        times.end.minutes.length === 1 &&
                        +valueCheck >= +times.end.hours &&
                        +times.start.minutes >= +times.end.minutes
                    ) {
                        updatesInfo.push({
                            name: 'start',
                            key: 'minutes',
                            value: times.end.minutes,
                        });
                    }
                }

                if (key === 'minutes') {
                    if (
                        type !== 'oneTime' &&
                        valueCheck.length === 2 &&
                        times.end.minutes &&
                        +valueCheck > +times.end.minutes &&
                        +times.start.hours >= +times.end.hours
                    ) {
                        valueCheck = times.end.minutes;
                    }
                    if (valueCheck.length === 0) {
                        this.focusInput('start', 'hours');
                    }
                    // console.log(valueCheck, valueCheck.length);
                    if (valueCheck.length === 2 && type !== 'oneTime') {
                        this.focusInput('end', 'hours');
                    }
                }
            }

            if (name === 'end') {
                if (key === 'hours') {
                    if (
                        valueCheck.length === 2 &&
                        times.start.hours.length === 2 &&
                        +valueCheck < +times.start.hours
                    ) {
                        valueCheck = times.start.hours;
                    }
                    if (valueCheck.length === 0) {
                        this.focusInput('start', 'minutes');
                    }
                    if (valueCheck.length === 2) {
                        this.focusInput('end', 'minutes');
                    }

                    if (
                        valueCheck.length === 2 &&
                        times.end.minutes.length === 2 &&
                        +valueCheck <= +times.start.hours &&
                        +times.end.minutes <= +times.start.minutes
                    ) {
                        updatesInfo.push({
                            name: 'end',
                            key: 'minutes',
                            value: times.start.minutes,
                        });
                    }
                }

                if (key === 'minutes') {
                    if (
                        valueCheck.length === 2 &&
                        times.start.minutes &&
                        +valueCheck < +times.start.minutes &&
                        +times.start.hours >= +times.end.hours
                    ) {
                        valueCheck = times.start.minutes;
                    }
                    if (valueCheck.length === 0) {
                        this.focusInput('end', 'hours');
                    }
                }
            }
        }

        this.setState((state) => {
            const newState = { ...state };
            const timesUpdate = { ...newState.times };

            timesUpdate[name][key] = valueCheck;

            updatesInfo.forEach((item) => {
                timesUpdate[item.name][item.key] = item.value;
            });

            newState.times = timesUpdate;

            return newState;
        });
    }

    getTime() {
        const { type } = this.props;
        const { times } = this.state;
        const { start, end } = times;
        let timeString = ``;

        if (start.hours.length === 2 && start.minutes.length === 2) {
            if (type !== 'oneTime') {
                timeString += 'с ';
            } else {
                timeString += 'в ';
            }

            timeString += start.hours;
            timeString += ':';
            timeString += start.minutes;
        }

        if (type !== 'oneTime' && end.hours.length === 2 && end.minutes.length === 2) {
            if (start.hours.length === 2 && start.minutes.length === 2) {
                timeString += ' ';
            }
            timeString += 'до ';
            timeString += end.hours;
            timeString += ':';
            timeString += end.minutes;
        }

        return timeString;
    }

    renderTimeInput({ name, key }) {
        const { times } = this.state;

        return (
            <input
                name={`${name}-${key}`}
                type="text"
                className="date__timeInput"
                onChange={(e) =>
                    this.handlerTime({
                        name,
                        key,
                        value: e.target.value,
                    })
                }
                onBlur={() => {
                    this.handlerTime({
                        action: 'blur',
                        name,
                        key,
                    });
                }}
                value={times[name][key]}
                autoComplete="off"
            />
        );
    }

    renderTime({ name }) {
        return (
            <div className="date__timeInputs _row">
                {this.renderTimeInput({ name, key: 'hours' })}
                <div className="date__timeDelimeter">:</div>
                {this.renderTimeInput({ name, key: 'minutes' })}
            </div>
        );
    }

    changeStep(currentPage) {
        const { type } = this.props;

        this.setState({ currentPage }, () => {
            this.setHeightBlocks();
        });

        if (currentPage === 'time') {
            setTimeout(() => {
                const nameInput = type !== 'oneTime' ? 'end' : 'start';

                this.parent.current.querySelector(`input[name="${nameInput}-minutes"]`).focus();
            }, 500);
        }
    }

    handlerDate({ currentDate }) {
        this.setState({ currentDate }, () => {
            const { isCurrent, nowDate } = this.checkCurrentDate();

            if (isCurrent) {
                this.setState((state) => {
                    const newState = { ...state };
                    const times = { ...newState.times };

                    if (+times.start.hours <= nowDate.getHours()) {
                        times.start.hours = getFormatedNumber(nowDate.getHours());
                    }

                    if (
                        +times.start.hours === nowDate.getHours() &&
                        +times.start.minutes <= nowDate.getMinutes()
                    ) {
                        times.start.minutes = getFormatedNumber(nowDate.getMinutes());
                    }

                    newState.times = times;

                    return newState;
                });
            }
        });
    }

    getResultDate() {
        const { currentDate, times } = this.state;
        const { start } = times;
        const { hours, minutes } = start;

        currentDate.setHours(+hours, +minutes, 0, 0);

        return { date: currentDate.toString(), times };
    }

    checkCompleteTime() {
        const { times } = this.state;
        const { type } = this.props;
        let findEmpty = false;

        const keys = ['start'];

        if (type !== 'oneTime') {
            keys.push('end');
        }

        keys.forEach((name) => {
            ['hours', 'minutes'].forEach((key) => {
                if (!findEmpty && !times[name][key]) {
                    this.focusInput(name, key);

                    findEmpty = true;
                }
            });
        });

        return !findEmpty;
    }

    handlerButton() {
        const { currentPage } = this.state;
        const { callback, hide } = this.props;

        if (currentPage === 'calendar') {
            this.changeStep('time');
        } else if (callback && typeof callback === 'function' && this.checkCompleteTime()) {
            callback({ ...this.getResultDate() });
            hide();
        }
    }

    setHeightBlocks() {
        const block = this.parent.current.querySelector('.date__block._current');

        if (block) {
            const { clientHeight: heightBlocks } = block;

            this.setState((state) => {
                const newState = { ...state };

                newState.heightBlocks = heightBlocks;

                return newState;
            });
        }
    }

    init() {
        const { type } = this.props;
        let { times } = this.props;

        if (!times) {
            times = {
                start: {
                    hours: '09',
                    minutes: '00',
                },
            };

            if (type !== 'oneTime') {
                times.end = {
                    hours: '17',
                    minutes: '00',
                };
            }
        }

        const checkStartDate = () =>
            new Promise((resolve) => {
                this.setState((state) => {
                    const newState = { ...state };

                    newState.times = times;

                    return newState;
                }, resolve);
            });

        checkStartDate().then(() => {
            removeTransition({ item: '.date__blocks' });
        });
    }

    handlerMissClick({ target, detail }) {
        const { parent, hide, btn } = this.props;

        const resultTarget = detail?.target || target;

        if (
            parent &&
            hide &&
            typeof hide === 'function' &&
            resultTarget !== parent &&
            !parent.contains(resultTarget) &&
            (!btn || (resultTarget !== btn && !btn.contains(resultTarget)))
        ) {
            hide();
        }
    }

    componentDidMount() {
        this.init();

        this.setHeightBlocks();

        const observerBlocks = new MutationObserver(() => {
            this.setHeightBlocks();
        });

        observerBlocks.observe(this.parent.current, {
            childList: true,
            subtree: true,
            characterDataOldValue: true,
        });

        document.addEventListener('click', this.handlerMissClick);
        document.addEventListener('continuePropagationClick', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
        document.removeEventListener('continuePropagationClick', this.handlerMissClick);
    }

    render() {
        const { heightBlocks, currentDate, currentPage } = this.state;
        const { date, className, type, withPast = false } = this.props;

        return (
            <div ref={this.parent} className={`date ${className || ``}`}>
                <div className="date__blocks" style={{ height: `${heightBlocks}px` }}>
                    <Animate
                        isShow={currentPage === 'calendar'}
                        className={`date__block _calendar date__calendar ${
                            currentPage === 'calendar' ? '_current' : ''
                        }`}
                    >
                        <Calendar
                            withPast={withPast}
                            startDate={date}
                            setHeightBlocks={this.setHeightBlocks}
                            handlerDate={this.handlerDate}
                        />
                    </Animate>
                    <Animate
                        isShow={currentPage === 'time'}
                        className={`date__block _time date__time ${
                            currentPage === 'time' ? '_current' : ''
                        }`}
                    >
                        <div className="date__timeHead _row">
                            <p
                                className="date__timeBack _row _click"
                                onClick={() => this.changeStep('calendar')}
                            >
                                <i className="date__timeBackIcon">
                                    <Icon name="arrow-prev" />
                                </i>
                                Назад
                            </p>
                            <p className="date__timeDate">
                                {currentDate &&
                                    getFormatedDate({ date: currentDate, isShortYear: true })}
                            </p>
                        </div>
                        <div className="date__timeContent _row">
                            {this.renderTime({ name: 'start' })}
                            {type !== 'oneTime' && (
                                <>
                                    <div className="date__timeLine"></div>
                                    {this.renderTime({ name: 'end' })}
                                </>
                            )}
                        </div>
                    </Animate>
                </div>
                <div className="date__foot">
                    <div className="date__button _click" onClick={() => this.handlerButton()}>
                        <Animate
                            isShow={currentPage === 'calendar'}
                            className={`date__buttonContent _col _calendar`}
                        >
                            <p className="date__buttonTitle">Выбрать время</p>
                            <p className="date__buttonText">
                                Дата: {currentDate && getFormatedDate({ date: currentDate })}
                            </p>
                        </Animate>
                        <Animate
                            isShow={currentPage === 'time'}
                            className={`date__buttonContent _col _time`}
                        >
                            <p className="date__buttonTitle">Сохранить</p>
                            <p className="date__buttonText">
                                {currentDate && getFormatedDate({ date: currentDate })}{' '}
                                {this.getTime()}
                            </p>
                        </Animate>
                        <div className="date__buttonPoints _row">
                            {['calendar', 'time'].map((name) => (
                                <div
                                    key={name}
                                    className={`date__buttonPoint ${
                                        currentPage === name ? '_current' : ''
                                    }`}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(DateClass);

DateClass.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.object,
    times: PropTypes.object,
    callback: PropTypes.func,
    parent: PropTypes.object,
    hide: PropTypes.func,
    device: PropTypes.string,
    inner: PropTypes.object,
    withPast: PropTypes.bool,
    classNameInner: PropTypes.string,
    btn: PropTypes.object,
};
