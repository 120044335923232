import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFile from '../../../classes/File';

import Icon from '../../Icon.jsx';
import Checkbox from '../../Checkbox.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

import getMailings from '../../../requests/getMailings';
import handlerPopup from '../../../functions/app/handlerPopup';
import getDeepValue from '../../../functions/getDeepValue';
import handlerLoading from '../../../functions/handlerLoading';
import getUpdateFormData from '../../../functions/getUpdateFormData';

import Switch from '../../Switch.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Avatar from '../../Avatar.jsx';
import getUserInfo from '../../../functions/getUserInfo';
import Field from '../../Field.jsx';
import Animate from '../../Animate.jsx';
import EmodziList from '../../EmodziList.jsx';
import Button from '../../Button.jsx';
import WindowPrompt from '../../WindowPrompt.jsx';

import FileItem from '../../chat/File.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import setNotification from '../../../functions/setNotification';
import Loader from '../../Loader.jsx';
import changePage from '../../../functions/changePage';
import getNotPopupPage from '../../../functions/getNotPopupPage';
import getHeaders from '../../../functions/getHeaders';
import checkChangeArray from '../../../functions/checkChangeArray';
import { dispatcher } from '../../../redux/redux';
import downloadFile from '../../../functions/downloadFile';
import setPermissions from '../../../functions/crm/setPermissions';
import removeTransition from '../../../functions/removeTransition.ts';

class MailingPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            areaHeight: 170,
        };

        this.changeMailing = this.changeMailing.bind(this);
        this.renderTag = this.renderTag.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.save = this.save.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.delete = this.delete.bind(this);
        this.downloadList = this.downloadList.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    renderTag({ item }) {
        return (
            <div className="crmPopup__tag">
                <div className="tag _full" style={{ background: item.color }}>
                    {item.name}
                </div>
            </div>
        );
    }

    setAreaHeight() {
        const field = this.parent.current.querySelector('.crmPopup__field._content');
        const params = getRealParams({
            parent: field,
            elem: '.field__input',
            width: field.offsetWidth,
            isClearStyles: true,
        });

        const resultHeight = params.scrollHeight > 170 ? params.scrollHeight : 170;

        this.setState({ areaHeight: resultHeight });
    }

    changeMailing({ values }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const mailing = { ...newState.mailing };

                    values.forEach(({ value, key, checkArray, arrayProp }) => {
                        const { item, lastKey } = getDeepValue({ item: mailing, name: key });

                        if (checkArray) {
                            const itemIndex = arrayProp
                                ? item[lastKey].findIndex(
                                      (innerItem) => innerItem[arrayProp] === value[arrayProp],
                                  )
                                : item[lastKey].indexOf(value);

                            if (itemIndex === -1) {
                                item[lastKey].push(value);
                            } else {
                                item[lastKey].splice(itemIndex, 1);
                            }
                        } else {
                            item[lastKey] = value;
                        }
                    });

                    newState.mailing = mailing;

                    return newState;
                },
                () => {
                    if (
                        [
                            'query.tags',
                            'query.executorStatuses',
                            'query.executorTypes',
                            'query.executorHasPays',
                        ].find((prop) => values.find((value) => value.key === prop))
                    ) {
                        this.getExecutorCounter();
                    }

                    resolve();
                },
            );
        });
    }

    formData = new FormData();

    handlerFile = new HandlerFile({ typeOfFiles: 'media' });

    deletedFiles = [];

    deleteFile({ id }) {
        const { mailing } = this.state;
        const file = mailing.files.find((item) => item._id === id);

        if (file.isLocal) {
            this.formData.delete(file._id);
        } else {
            this.deletedFiles.push(file._id);
        }

        this.changeMailing({
            values: [{ key: 'files', value: file, checkArray: true, arrayProp: '_id' }],
        });
    }

    renderFile({ item }) {
        const { fromTemplate } = item;

        return (
            <div className="crmPopup__file">
                <FileItem
                    withOpen={true}
                    file={item}
                    deleteFile={!fromTemplate && !this.checkDisabled() ? this.deleteFile : null}
                />
            </div>
        );
    }

    uploadFiles({ target }) {
        const { mailing } = this.state;

        if (mailing.files.length + target.files.length > 6) {
            target.value = null;

            setNotification({ notification: 'max-limit-counter-files-6' });
        } else {
            this.handlerFile
                .uploadFiles({
                    target,
                    getName: (key) => `${key}-${new Date().getTime()}`,
                    formData: this.formData,
                })
                .then(({ resultFiles }) => {
                    target.value = null;

                    const files = JSON.parse(JSON.stringify(mailing.files));

                    files.push(
                        ...resultFiles.map((file) => ({
                            _id: file.key,
                            size: file.size,
                            name: file.name,
                            type: file.type,
                            isLocal: file.isLocal,
                        })),
                    );

                    this.changeMailing({ values: [{ key: 'files', value: files }] });
                });
        }
    }

    renderEmodzi() {
        const { mailing, showEmodziList } = this.state;
        const area = this.parent.current?.querySelector('textarea');

        return (
            <div className="crmPopup__inputEmodzi">
                <div
                    className="crmPopup__inputEmodziBtn _click"
                    onClick={(e) => {
                        e.stopPropagation();

                        if (!this.checkDisabled()) {
                            this.setState({
                                showEmodziList: !this.state.showEmodziList,
                            });
                        }
                    }}
                >
                    <Icon name="emodzi" />
                </div>
                <Animate className="crmPopup__inputEmodziList" isShow={showEmodziList}>
                    <EmodziList
                        className="_minHeight"
                        addEmodzi={(emodzi) => {
                            const currentPosition =
                                area?.selectionEnd || mailing?.content?.length || 0;
                            let resultValue = emodzi;

                            if (currentPosition > 0) {
                                resultValue = mailing.content.slice(0, currentPosition);

                                resultValue += emodzi;

                                resultValue += mailing.content.slice(currentPosition);
                            }

                            this.changeMailing({
                                values: [
                                    {
                                        key: 'content',
                                        value: resultValue,
                                    },
                                ],
                            }).then(() => {
                                area.focus();
                                area.selectionEnd = currentPosition + emodzi.length;
                            });
                        }}
                        input={area}
                        startPosition={area?.selectionEnd || mailing?.content?.length || 0}
                        dirClassName="_topRight"
                        hide={() => {
                            this.setState({
                                showEmodziList: false,
                            });
                        }}
                    />
                </Animate>
            </div>
        );
    }

    getExecutorCounter(isStart) {
        const { mailing } = this.state;

        if (mailing.inProcess || isStart) {
            this.setState({ executorCounter: mailing.allCounter });

            return;
        }

        const query = {
            params: [{ key: 'executorCounter', value: true }],
        };

        if (mailing.query.tags.length) {
            query.params.push(...mailing.query.tags.map((id) => ({ key: 'tags', value: id })));
        }

        if (mailing.query.executorStatuses.length) {
            query.params.push(
                ...mailing.query.executorStatuses.map((status) => ({
                    key: 'executorStatuses',
                    value: status,
                })),
            );
        }

        if (mailing.query.executorTypes.length) {
            query.params.push(
                ...mailing.query.executorTypes.map((type) => ({
                    key: 'executorTypes',
                    value: type,
                })),
            );
        }

        if (mailing.query.executorHasPays) {
            query.params.push({ key: 'executorHasPays', value: true });
        }

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        handlerLoading.call(this, 'executorCounter').then(() => {
            getMailings({ ...query, signal: this.controller.signal }).then(
                ({ counter }) => {
                    this.setState({ executorCounter: counter, loadingKey: null });
                },
                () => null,
            );
        });
    }

    setMailing({ mailing }) {
        return new Promise((resolve) => {
            this.setState(
                {
                    mailing,
                    savedMailing: JSON.parse(JSON.stringify(mailing)),
                    isReady: true,
                },
                resolve,
            );
        });
    }

    getMailing() {
        const { repeatMailingId } = this.props;
        const params = [];
        const id = this.getId();

        if (repeatMailingId) {
            params.push({ key: 'repeatMailingId', value: repeatMailingId });
        }

        getMailings({ id, params }).then(({ mailing }) => {
            if (mailing) {
                mailing.files.forEach((file) => {
                    file.src = `${process.env.REACT_APP_STATIC}/mailing/${file.path}`;
                });
            }

            this.setMailing({ mailing }).then(() => {
                this.setAreaHeight();

                removeTransition({ item: '.crmPopup__fieldButton' });

                if (repeatMailingId) {
                    dispatcher({ type: 'repeatMailingId', data: null });
                }

                this.getExecutorCounter(true);
            });
        });
    }

    setTemplate({ template }) {
        const { mailing } = this.state;

        const files = [
            ...mailing.files.filter((file) => !file.fromTemplate),
            ...template.files.map((file) => ({ ...file, fromTemplate: true })),
        ];

        this.setState({ template }, () => {
            this.changeMailing({
                values: [
                    { key: 'content', value: template.content },
                    { key: 'files', value: files },
                ],
            }).then(() => {
                this.setAreaHeight();
            });
        });
    }

    getMailingData() {
        const { mailing } = this.state;

        return {
            _id: mailing._id,
            type: mailing.type,
            query: mailing.query,
            responsibleUserId: mailing.responsibleUserId,
            setTags: mailing.setTags,
            closeChats: mailing.closeChats,
            content: mailing.content,
            tags: mailing.tags,
        };
    }

    getActions() {
        const { id } = this.state;

        const actions = [{ key: 'files' }];

        if (id === 'new') {
            actions.unshift({ key: 'template' });
        }

        return actions;
    }

    renderAction({ prop: key }) {
        if (key === 'files') {
            return (
                <div className="crmPopup__action _file">
                    <label className="crmPopup__actionInner _row _click">
                        <input
                            type="file"
                            onChange={this.uploadFiles}
                            multiple
                            disabled={this.checkDisabled()}
                        />
                        <i className="crmPopup__actionIcon">
                            <Icon name="chat-attach" />
                        </i>
                        Прикрепить файл
                    </label>
                </div>
            );
        }

        return (
            <div className="crmPopup__action _template">
                <div
                    className="crmPopup__actionInner _row _click"
                    onClick={() => {
                        handlerPopup({
                            name: 'chatTemplatesPopup',
                            isShow: true,
                            callback: ({ template }) => {
                                this.setTemplate({ template });
                            },
                        });
                    }}
                >
                    <i className="crmPopup__actionIcon">
                        <Icon name="chat-templates" />
                    </i>
                    Выбрать шаблон
                </div>
            </div>
        );
    }

    getId() {
        const { levels } = this.props;

        return levels[2];
    }

    checkDisabled() {
        const { mailing } = this.state;

        return mailing?.inProcess;
    }

    checkChange() {
        const { mailing, savedMailing } = this.state;
        const id = this.getId();
        let isChange = id === 'new';

        if (savedMailing) {
            [
                'type',
                'query.executorHasPays',
                'responsibleUserId',
                'setTags.set',
                'closeChats',
                'content',
            ].forEach((name) => {
                if (
                    getDeepValue({ item: mailing, name }).value !==
                    getDeepValue({ item: savedMailing, name }).value
                ) {
                    isChange = true;
                }
            });

            [
                'query.tags',
                'query.executorStatuses',
                'query.executorTypes',
                'setTags.tags',
                'tags',
            ].forEach((name) => {
                if (
                    checkChangeArray({
                        array: getDeepValue({ item: mailing, name }).value,
                        targetArray: getDeepValue({ item: savedMailing, name }).value,
                    })
                ) {
                    isChange = true;
                }
            });

            if (this.deletedFiles.length || mailing.files.find((file) => file.isLocal)) {
                isChange = true;
            }

            if (!mailing.type || !mailing.content) {
                isChange = false;
            }
        }

        return isChange;
    }

    save() {
        const { template } = this.state;
        const id = this.getId();
        const mailing = this.getMailingData();

        this.formData.set('id', id);
        this.formData.set('mailing', JSON.stringify(mailing));
        this.formData.set('deletedFiles', JSON.stringify(this.deletedFiles));

        if (id === 'new' && template) {
            this.formData.set('templateId', template._id);
        }

        handlerLoading.call(this, 'save').then(() => {
            axios[id === 'new' ? 'post' : 'patch'](
                `${process.env.REACT_APP_API}/mailing`,
                getUpdateFormData(this.formData),
                {
                    headers: getHeaders(),
                },
            ).then(
                (res) => {
                    handlerLoading.call(this, null);

                    const { success, data } = res.data;

                    if (success) {
                        const { id: newId } = data;

                        this.deletedFiles = [];
                        this.formData = new FormData();

                        this.hide();

                        if (newId) {
                            setNotification({ notification: 'success-save-info' });
                        } else {
                            setNotification({ notification: 'success-change-info' });
                        }

                        document.dispatchEvent(new CustomEvent('updateMailing'));
                    }
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    downloadList() {
        const { mailing } = this.state;
        const id = this.getId();

        handlerLoading.call(this, 'download').then(() => {
            axios
                .get(`${process.env.REACT_APP_API}/mailing?id=${id}&exportList=true`, {
                    responseType: 'blob',
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        handlerLoading.call(this, null);

                        res.data.text().then(
                            (result) => {
                                try {
                                    const { success } = JSON.parse(result);

                                    return success;
                                } catch (error) {
                                    const filename = `Рассылка-№${mailing.number}.xlsx`;

                                    downloadFile({ filename, data: res.data });
                                }

                                return true;
                            },
                            () => null,
                        );
                    },
                    () => {
                        handlerLoading.call(this, null);
                    },
                );
        });
    }

    delete({ hide, handlerLoader }) {
        const id = this.getId();

        axios
            .delete(`${process.env.REACT_APP_API}/mailing?id=${id}`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-change-info' });

                        document.dispatchEvent(new CustomEvent('updateMailing'));

                        hide();

                        this.hide();
                    } else {
                        handlerLoader(false);
                    }
                },
                () => {
                    handlerLoader(false);
                },
            );
    }

    hide() {
        changePage(getNotPopupPage());
    }

    componentDidMount() {
        this.getMailing();

        this.setState({ id: this.getId() });
    }

    render() {
        const {
            mailing,
            updatedSetTagsKey,
            areaHeight,
            executorCounter = 0,
            loadingKey,
            isReady,
            showDeleteWindow,
        } = this.state;
        const id = this.getId();

        return (
            <div ref={this.parent} className={`crmPopup _col _mailing ${isReady ? '_ready' : ''}`}>
                <div className="crmPopup__inner">
                    <div className="crmPopup__head">
                        <AnimateChangeUp
                            className="crmPopup__title _dynamic"
                            renderKey={`${id}${id === 'new' ? '' : !!mailing}`}
                            isNotNullParentSize={true}
                        >
                            {id === 'new' ? (
                                'Создание новой рассылки'
                            ) : (
                                <>Редактирование рассылки {mailing ? `№${mailing.number}` : ''}</>
                            )}
                        </AnimateChangeUp>
                        <i className="crmPopup__close _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className={`crmPopup__content ${isReady ? '_ready' : ''}`}>
                        <Animate className="crmPopup__loader _loader" isShow={!isReady}>
                            <div className="crmPopup__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="crmPopup__contentScroll">
                            <div className="crmPopup__field _col">
                                <p className="crmPopup__fieldSupport">Способ рассылки:</p>
                                <div className="crmPopup__fieldBox">
                                    <div className="crmPopup__checkboxes _row">
                                        {mailing?.types.map((type) => (
                                            <div className="crmPopup__checkbox" key={type.key}>
                                                <Checkbox
                                                    className="_square _blue"
                                                    value={mailing?.type === type.key}
                                                    handler={() => {
                                                        this.changeMailing({
                                                            values: [
                                                                {
                                                                    key: 'type',
                                                                    value:
                                                                        mailing?.type === type.key
                                                                            ? null
                                                                            : type.key,
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                    isDisabled={this.checkDisabled()}
                                                >
                                                    {type.content}
                                                </Checkbox>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _row">
                                <p className="crmPopup__fieldSupport">Теги:</p>
                                <div className="crmPopup__fieldBox _withAbsoluteBtn _col">
                                    <ListAbsoluteMain
                                        className="crmPopup__tags"
                                        items={mailing?.query.tagsInfo}
                                        renderItem={this.renderTag}
                                        classNameItem="crmPopup__tag"
                                        prop="id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                    <div
                                        className={`crmPopup__fieldButton _row _absolute _click ${
                                            mailing?.query.tags.length ? '_active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!this.checkDisabled()) {
                                                handlerPopup({
                                                    name: 'tagsPopup',
                                                    isShow: true,
                                                    ids: mailing?.query.tags,
                                                    callback: (newTags) => {
                                                        this.changeMailing({
                                                            values: [
                                                                {
                                                                    key: 'query.tags',
                                                                    value: newTags.map(
                                                                        (tag) => tag.id,
                                                                    ),
                                                                },
                                                                {
                                                                    key: 'query.tagsInfo',
                                                                    value: newTags,
                                                                },
                                                            ],
                                                        });
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <AnimateChangeUp
                                            className="crmPopup__fieldButtonInner _center"
                                            renderKey={mailing?.query.tags.length ? 1 : 0}
                                            isNotNullParentSize={true}
                                        >
                                            {mailing?.query.tags.length > 0
                                                ? 'Изменить теги'
                                                : 'Выбрать теги'}
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _col">
                                <p className="crmPopup__fieldSupport">Статус исполнителя:</p>
                                <div className="crmPopup__fieldBox">
                                    <div className="crmPopup__checkboxes _row">
                                        {mailing?.executorStatuses.map((status) => {
                                            const { id: name, content } = status;

                                            return (
                                                <div className="crmPopup__checkbox" key={name}>
                                                    <Checkbox
                                                        className="_square _blue"
                                                        value={mailing?.query.executorStatuses.includes(
                                                            name,
                                                        )}
                                                        handler={() => {
                                                            this.changeMailing({
                                                                values: [
                                                                    {
                                                                        key: 'query.executorStatuses',
                                                                        value: name,
                                                                        checkArray: true,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        isDisabled={this.checkDisabled()}
                                                    >
                                                        {content}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _col">
                                <p className="crmPopup__fieldSupport">Категория исполнителя:</p>
                                <div className="crmPopup__fieldBox">
                                    <div className="crmPopup__checkboxes _row">
                                        {mailing?.executorTypes.map((executorType) => {
                                            const { key: name, content } = executorType;

                                            return (
                                                <div className="crmPopup__checkbox" key={name}>
                                                    <Checkbox
                                                        className="_square _blue"
                                                        value={mailing?.query.executorTypes.includes(
                                                            name,
                                                        )}
                                                        handler={() => {
                                                            this.changeMailing({
                                                                values: [
                                                                    {
                                                                        key: 'query.executorTypes',
                                                                        value: name,
                                                                        checkArray: true,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        isDisabled={this.checkDisabled()}
                                                    >
                                                        {content}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _row">
                                <div className="crmPopup__fieldSupport">Проводились платежи:</div>
                                <div className="crmPopup__fieldBox _padding">
                                    <div className="crmPopup__switch">
                                        <Switch
                                            className="_size3"
                                            value={!!mailing?.query.executorHasPays}
                                            handler={() => {
                                                this.changeMailing({
                                                    values: [
                                                        {
                                                            key: 'query.executorHasPays',
                                                            value: !mailing?.query.executorHasPays,
                                                        },
                                                    ],
                                                });
                                            }}
                                            isDisabled={this.checkDisabled()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _row">
                                <div className="crmPopup__fieldSupport">
                                    Ответственный за рассылку:
                                </div>
                                <div className="crmPopup__fieldBox _row _withAbsoluteBtn">
                                    <AnimateChangeUp
                                        className="crmPopup__user"
                                        renderKey={mailing?.responsibleUserId}
                                    >
                                        <div className="crmPopup__userInner _row">
                                            <Avatar
                                                className="crmPopup__userAvatar"
                                                holder={{ ...mailing?.responsibleUser }}
                                            />
                                            {getUserInfo({
                                                type: 'nameShort',
                                                user: mailing?.responsibleUser,
                                            })}
                                        </div>
                                    </AnimateChangeUp>
                                    <div
                                        className={`crmPopup__fieldButton _row _absolute _click ${
                                            mailing?.responsibleUserId ? '_active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!this.checkDisabled()) {
                                                handlerPopup({
                                                    name: 'listPopup',
                                                    isShow: true,
                                                    currentName: 'users',
                                                    currentId: mailing?.responsibleUserId,
                                                    callback: (
                                                        responsibleUserId,
                                                        responsibleUser,
                                                    ) => {
                                                        this.changeMailing({
                                                            values: [
                                                                {
                                                                    key: 'responsibleUserId',
                                                                    value: responsibleUserId,
                                                                },
                                                                {
                                                                    key: 'responsibleUser',
                                                                    value: responsibleUser,
                                                                },
                                                            ],
                                                        });
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <AnimateChangeUp
                                            className="crmPopup__fieldButtonInner _center"
                                            renderKey={mailing?.responsibleUserId ? 1 : 0}
                                            isNotNullParentSize={true}
                                        >
                                            {mailing?.responsibleUserId
                                                ? 'Изменить ответственного'
                                                : 'Назначить ответственного'}
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _col">
                                <p className="crmPopup__fieldSupport">
                                    Автоматические действия после отправки:
                                </p>
                                <div className="crmPopup__fieldBox">
                                    <div className="crmPopup__checkboxes _col">
                                        <div className="crmPopup__checkbox _full">
                                            <div className="crmPopup__checkboxInner">
                                                <Checkbox
                                                    className="_square _blue"
                                                    value={!!mailing?.setTags.set}
                                                    handler={() => {
                                                        this.changeMailing({
                                                            values: [
                                                                {
                                                                    key: 'setTags.set',
                                                                    value: !mailing?.setTags.set,
                                                                },
                                                                ...(mailing?.setTags.set
                                                                    ? [
                                                                          {
                                                                              key: 'setTags.tags',
                                                                              value: [],
                                                                          },

                                                                          {
                                                                              key: 'setTags.tagsInfo',
                                                                              value: [],
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ],
                                                        });
                                                    }}
                                                    isDisabled={this.checkDisabled()}
                                                >
                                                    Присвоить теги после рассылки
                                                </Checkbox>
                                            </div>

                                            <AnimateChangeUp
                                                className="crmPopup__checkboxOther"
                                                renderKey={mailing?.setTags.set ? 1 : undefined}
                                                parentStyles={['height']}
                                                renderListKey={updatedSetTagsKey}
                                            >
                                                <div className="crmPopup__checkboxOtherInner">
                                                    <div className="crmPopup__field _col">
                                                        {mailing?.setTags.set && (
                                                            <>
                                                                <div
                                                                    className="crmPopup__fieldBox _withAbsoluteBtn _col"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                >
                                                                    <ListAbsoluteMain
                                                                        className="crmPopup__tags"
                                                                        items={
                                                                            mailing?.setTags
                                                                                .tagsInfo
                                                                        }
                                                                        renderItem={this.renderTag}
                                                                        classNameItem="crmPopup__tag"
                                                                        prop="id"
                                                                        paramsParent={{
                                                                            width: true,
                                                                        }}
                                                                        styles={['height']}
                                                                        callback={({
                                                                            isChangeLen,
                                                                        }) => {
                                                                            if (isChangeLen) {
                                                                                this.setState({
                                                                                    updatedSetTagsKey:
                                                                                        new Date().getTime(),
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={`crmPopup__fieldButton _row _absolute _click ${
                                                                            mailing?.setTags.tags
                                                                                .length
                                                                                ? '_active'
                                                                                : ''
                                                                        }`}
                                                                        onClick={() => {
                                                                            if (
                                                                                !this.checkDisabled()
                                                                            ) {
                                                                                handlerPopup({
                                                                                    name: 'tagsPopup',
                                                                                    isShow: true,
                                                                                    ids: mailing
                                                                                        ?.setTags
                                                                                        .tags,
                                                                                    callback: (
                                                                                        newTags,
                                                                                    ) => {
                                                                                        this.changeMailing(
                                                                                            {
                                                                                                values: [
                                                                                                    {
                                                                                                        key: 'setTags.tags',
                                                                                                        value: newTags.map(
                                                                                                            (
                                                                                                                tag,
                                                                                                            ) =>
                                                                                                                tag.id,
                                                                                                        ),
                                                                                                    },
                                                                                                    {
                                                                                                        key: 'setTags.tagsInfo',
                                                                                                        value: newTags,
                                                                                                    },
                                                                                                ],
                                                                                            },
                                                                                        );
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <AnimateChangeUp
                                                                            className="crmPopup__fieldButtonInner _center"
                                                                            renderKey={
                                                                                mailing?.setTags
                                                                                    .tags.length
                                                                                    ? 1
                                                                                    : 0
                                                                            }
                                                                            isNotNullParentSize={
                                                                                true
                                                                            }
                                                                        >
                                                                            {mailing?.setTags.tags
                                                                                .length > 0
                                                                                ? 'Изменить теги'
                                                                                : 'Выбрать теги'}
                                                                        </AnimateChangeUp>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </AnimateChangeUp>
                                        </div>
                                        <div className="crmPopup__checkbox">
                                            <Checkbox
                                                className="_square _blue"
                                                value={!!mailing?.closeChats}
                                                handler={() => {
                                                    this.changeMailing({
                                                        values: [
                                                            {
                                                                key: 'closeChats',
                                                                value: !mailing?.closeChats,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                isDisabled={this.checkDisabled()}
                                            >
                                                Закрыть чаты после рассылки
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _col _content">
                                <div className="crmPopup__fieldSupport">Текст рассылки:</div>
                                <div className="crmPopup__fieldBox">
                                    <div
                                        className="crmPopup__input _area _content"
                                        style={{ height: `${areaHeight}px` }}
                                    >
                                        {this.renderEmodzi()}
                                        <Field
                                            type="mailing"
                                            keyName="message"
                                            name="content"
                                            value={mailing?.content}
                                            callback={({ action, value }) => {
                                                if (action === 'change') {
                                                    this.changeMailing({
                                                        values: [
                                                            {
                                                                key: 'content',
                                                                value,
                                                            },
                                                        ],
                                                    }).then(() => {
                                                        this.setAreaHeight();
                                                    });
                                                }
                                            }}
                                            className="_grey"
                                            stopPropagation={true}
                                            isDisabled={this.checkDisabled()}
                                        />
                                    </div>
                                    <ListAbsoluteMain
                                        className="crmPopup__files"
                                        items={mailing?.files || []}
                                        renderItem={this.renderFile}
                                        classNameItem="crmPopup__file"
                                        prop="_id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                    <ListAbsoluteMain
                                        className="crmPopup__actions _dynamic"
                                        items={this.getActions()}
                                        renderItem={this.renderAction}
                                        classNameItem="crmPopup__action"
                                        prop="key"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                </div>
                            </div>
                            <div className="crmPopup__field _row">
                                <p className="crmPopup__fieldSupport">Теги рассылки:</p>
                                <div className="crmPopup__fieldBox _withAbsoluteBtn _col">
                                    <ListAbsoluteMain
                                        className="crmPopup__tags"
                                        items={mailing?.tagsInfo}
                                        renderItem={this.renderTag}
                                        classNameItem="crmPopup__tag"
                                        prop="id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                    <div
                                        className={`crmPopup__fieldButton _row _absolute _click ${
                                            mailing?.tags.length ? '_active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!this.checkDisabled()) {
                                                handlerPopup({
                                                    name: 'tagsPopup',
                                                    isShow: true,
                                                    ids: mailing?.tags,
                                                    callback: (newTags) => {
                                                        this.changeMailing({
                                                            values: [
                                                                {
                                                                    key: 'tags',
                                                                    value: newTags.map(
                                                                        (tag) => tag.id,
                                                                    ),
                                                                },
                                                                { key: 'tagsInfo', value: newTags },
                                                            ],
                                                        });
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <AnimateChangeUp
                                            className="crmPopup__fieldButtonInner _center"
                                            renderKey={mailing?.tags.length ? 1 : 0}
                                            isNotNullParentSize={true}
                                        >
                                            {mailing?.tags.length > 0
                                                ? 'Изменить теги'
                                                : 'Выбрать теги'}
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="crmPopup__foot _row">
                        <div className="crmPopup__footDescription">
                            <b>Выбрано:</b>{' '}
                            <AnimateChangeUp
                                className="crmPopup__footDescriptionCount"
                                renderKey={executorCounter}
                            >
                                {executorCounter}
                            </AnimateChangeUp>{' '}
                            чел.
                            <Animate
                                className="crmPopup__footDescriptionLoader"
                                isShow={loadingKey === 'executorCounter'}
                            >
                                <Loader className="_main" />
                            </Animate>
                        </div>
                        <div className="crmPopup__footButtons _row">
                            {this.state.id !== 'new' && (
                                <div className="crmPopup__footButton">
                                    <Button
                                        className="_medium2Size _blue"
                                        onClick={this.downloadList}
                                        showLoader={loadingKey === 'download'}
                                        icon={{ type: 'end', name: 'download' }}
                                    >
                                        Выгрузить список
                                    </Button>
                                </div>
                            )}
                            {!this.checkDisabled() && (
                                <>
                                    <div className="crmPopup__footButton">
                                        <Button
                                            className="_medium2Size"
                                            onClick={this.save}
                                            showLoader={loadingKey === 'save'}
                                            isDisabled={!this.checkChange()}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                    {this.state.id !== 'new' &&
                                        this.getPermissions({
                                            key: 'chat',
                                            items: [{ key: 'mailing', rules: ['delete'] }],
                                        }) && (
                                            <div className="crmPopup__footButton">
                                                <Animate
                                                    className="crmPopup__footButtonWindow"
                                                    isShow={showDeleteWindow}
                                                >
                                                    <WindowPrompt
                                                        className="_bottomRight"
                                                        name="delete"
                                                        callback={this.delete}
                                                        hide={() => {
                                                            this.setState({
                                                                showDeleteWindow: false,
                                                            });
                                                        }}
                                                    />
                                                </Animate>

                                                <Button
                                                    className="_medium2Size _alertLight"
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeleteWindow:
                                                                !this.state.showDeleteWindow,
                                                        });
                                                    }}
                                                    showLoader={loadingKey === 'delete'}
                                                >
                                                    Удалить
                                                </Button>
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        repeatMailingId: state.repeatMailingId,
        user: state.user,
    };
}

export default connect(mapStateToProps)(MailingPopup);

MailingPopup.propTypes = {
    levels: PropTypes.array,
    repeatMailingId: PropTypes.string,
    user: PropTypes.object,
};
