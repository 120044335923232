import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Link from '../../../../components/Link.jsx';
import getPage from '../../../../functions/getPage';
import setPermissions from '../../../../functions/crm/setPermissions';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.delete = this.delete.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);

        setPermissions.call(this);
    }

    orderLinks = [{ key: 'main' }, { key: 'logs' }];

    links = {
        main: {
            key: 'joins-scripts-inner-main',
        },
        logs: {
            key: 'joins-scripts-inner-logs',
        },
    };

    filterLink() {
        const { isNew } = this.props;

        return !isNew;
    }

    renderLink({ prop: key }) {
        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headLink">
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;
        const items = this.orderLinks.filter(this.filterLink);

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                {this.getPermissions({
                    key: 'joins',
                    items: [
                        {
                            key: 'scripts',
                            rules: ['delete'],
                        },
                    ],
                }) && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    typeOrganization: PropTypes.string,
    pagesStore: PropTypes.object,
    checkRights: PropTypes.func,
    levels: PropTypes.array,
    delete: PropTypes.func,
};
