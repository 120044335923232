import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';

import Icon from '../../Icon.jsx';
import BorderDashed from '../../BorderDashed.jsx';
import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';

class UploadOrdersPopupStepMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    instructions = {
        crm: 'Заказ-Livecargo.xlsx',
        service: 'Заказ-Сторонний.xlsx',
    };

    render() {
        const { uploadFile, loadingKey, type } = this.props;

        return (
            <>
                <p
                    className="uploadOrdersPopupUpload__contentDescription"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(
                            'Скачайте наш шаблон, заполните в нём<br/>все данные и загрузите заполненный файл сюда. Если у вас есть вопросы — ознакомьтесь с нашей инструкцией по загрузке заказов. ',
                        ),
                    }}
                ></p>
                <div className="uploadOrdersPopupUpload__contentButtons _row">
                    <div className="uploadOrdersPopupUpload__contentButton _download">
                        <a
                            className="uploadOrdersPopup__button _row"
                            href={require(`../../../img/crm/${this.instructions[type]}`)}
                            download={this.instructions[type]}
                        >
                            Скачать шаблон
                            <i className="uploadOrdersPopup__buttonIcon">
                                <Icon name="file-type-doc" />
                            </i>
                        </a>
                    </div>
                    <div className="uploadOrdersPopupUpload__contentButton _info">
                        <div className="uploadOrdersPopup__button _row _click">
                            Инструкция по загрузке заказов
                        </div>
                    </div>
                </div>
                <label className="uploadOrdersPopupUpload__contentUpload _col">
                    <div className="uploadOrdersPopupUpload__contentUploadBack">
                        <BorderDashed className="_upload" />
                    </div>
                    <input
                        type="file"
                        className="uploadOrdersPopupUpload__contentUploadInput"
                        onChange={({ target }) => {
                            uploadFile({ target });
                        }}
                    />
                    <div className="uploadOrdersPopupUpload__contentUploadIcon">
                        <Animate
                            className="uploadOrdersPopupUpload__contentUploadIconItem"
                            isShow={!loadingKey}
                        >
                            <Icon name="file-upload" />
                        </Animate>
                        <Animate
                            className="uploadOrdersPopupUpload__contentUploadIconItem"
                            isShow={loadingKey === 'check'}
                        >
                            <Loader className="_main" />
                        </Animate>
                    </div>

                    <p className="uploadOrdersPopupUpload__contentUploadTitle">
                        Загрузите или перетащите
                        <br />
                        сюда заполненный файл
                    </p>
                    <p className="uploadOrdersPopupUpload__contentUploadDescription">
                        Не более 50 заказов в файле
                    </p>
                </label>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(UploadOrdersPopupStepMain);

UploadOrdersPopupStepMain.propTypes = {
    setType: PropTypes.func,
    type: PropTypes.string,
    setStep: PropTypes.func,
    uploadFile: PropTypes.func,
    loadingKey: PropTypes.string,
};
