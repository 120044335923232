import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../manual/Table.jsx';

import Search from '../../Search.jsx';
import Button from '../../Button.jsx';
import getChatTemplates from '../../../requests/getChatTemplates';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';
import TableInfo from '../manual/TableInfo.jsx';

class ChatTemplatesPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 25;

    getQueryForRequest() {
        const { resultSearch } = this.state;
        const query = super.getQueryForRequest();

        if (resultSearch) {
            query.params.push({ key: 'searchForNameOrTags', value: resultSearch });
        }

        return query;
    }

    getItems(isForce = false) {
        const query = this.getQueryForRequest();

        if (isForce === true) {
            query.params.find((item) => item.key === 'limit').value =
                this.state.items?.length || this.stepCounter;
            query.params.find((item) => item.key === 'skip').value = 0;
        }

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        return new Promise((resolve) => {
            getChatTemplates({ ...query, signal: this.controller.signal }).then(
                ({ chatTemplates, isLimit }) => {
                    this.setItems(chatTemplates, isForce, isLimit).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setSearch() {
        this.setState({ resultSearch: this.state.search, currentId: null }, () => {
            this.updateItems().then(() => {
                this.setState({ resultSearch: null });
            });
        });
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ search: value }, () => {
                    this.setSearch();

                    resolve();
                });
            }
        });
    }

    getResult() {
        const { items = [], currentId } = this.state;

        if (!currentId) {
            return 'Не выбран';
        }

        const item = items.find((innerItem) => innerItem._id === currentId);

        return item.name;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { currentId, items = [] } = this.state;
        const currentItem = items.find((item) => item._id === currentId);
        const { chatTemplatesPopup } = this.props;
        const { callback } = chatTemplatesPopup;

        this.handlerLoading('save').then(() => {
            try {
                callback({ template: currentItem }).then(
                    () => {
                        this.handlerLoading(null);

                        this.hide();
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            } catch (error) {
                this.handlerLoading(null);

                this.hide();
            }
        });
    }

    hide() {
        handlerPopup({ name: 'chatTemplatesPopup', isShow: false });
    }

    handlerMissClick(e) {
        const inner = this.parent.current.querySelector('.chatTemplatesPopup__inner');

        if (inner !== e.target && !inner.contains(e.target)) {
            this.hide();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        setTimeout(() => {
            document.addEventListener('click', this.handlerMissClick);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const {
            items = [],
            isLimit,
            isReady,
            search,
            isLoadingFilter = false,
            currentId,
            loadingKey,
            isDisabledScroll,
        } = this.state;
        const result = this.getResult();

        return (
            <div ref={this.parent} className={`chatTemplatesPopup _col ${isReady ? '_ready' : ''}`}>
                <div className="chatTemplatesPopup__inner _col">
                    <div className="chatTemplatesPopup__head _row">
                        <div className="chatTemplatesPopup__headTitle">
                            Выбрать шаблон для отправки
                        </div>
                        <div className="chatTemplatesPopup__headSearch">
                            <Search
                                className="_grey _filter"
                                support="Введите название шаблона или тега"
                                callback={this.handlerSearch}
                                value={search || ''}
                            />
                        </div>
                        <i className="chatTemplatesPopup__headClose _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="chatTemplatesPopup__content">
                        <div className="chatTemplatesPopup__table">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="chatTemplatesPopup"
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={!isReady || isLoadingFilter}
                                isNotLink={true}
                                otherData={{
                                    currentId,
                                    setCurrent: (id) => {
                                        this.setState({
                                            currentId: currentId === id ? null : id,
                                        });
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="chatTemplatesPopup__foot _row">
                        <div className="chatTemplatesPopup__footButton">
                            <Button
                                className="_medium2Size _mainNotBorder"
                                isDisabled={!currentId}
                                onClick={this.save}
                                showLoader={loadingKey === 'save'}
                            >
                                Принять
                            </Button>
                        </div>
                        <div className="chatTemplatesPopup__footInfo">
                            <p className="chatTemplatesPopup__footInfoSupport">Шаблон:</p>
                            <AnimateChangeUp
                                className={`chatTemplatesPopup__footInfoResult ${
                                    currentId ? '_active' : ''
                                }`}
                                renderKey={result}
                                parentStyles={['height']}
                            >
                                {result}
                            </AnimateChangeUp>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        chatTemplatesPopup: state.chatTemplatesPopup,
    };
}

export default connect(mapStateToProps)(ChatTemplatesPopup);

ChatTemplatesPopup.propTypes = {
    chatTemplatesPopup: PropTypes.object,
};
