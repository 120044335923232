import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getModel from '../../requests/getModel';

import getWidthText from '../../functions/getWidthText';
import getFormatedDate from '../../functions/getFormatedDate';

import StatusPoint from '../order/StatusPoint.jsx';
import Button from '../Button.jsx';

import DocOfPoint from './DocOfPoint.jsx';

import AnimateChange from '../AnimateChange.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';

class DocsOfPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderDoc = this.renderDoc.bind(this);

        this.parent = React.createRef();
    }

    handlerLoadAddDocs(isLoadAddDocs) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.isLoadAddDocs = isLoadAddDocs;

                return newState;
            }, resolve);
        });
    }

    addDoc() {
        const { handlerDocs, point, editName } = this.props;
        const { _id: idOfPoint } = point;

        if (editName === idOfPoint) {
            this.handlerLoadAddDocs(true).then(() => {
                getModel({ name: 'docPoint', type: 'schema' }).then(
                    ({ model: doc }) => {
                        handlerDocs({ action: 'add', idOfPoint, doc }).then(() => {
                            this.handlerLoadAddDocs(false);
                        });
                    },
                    () => null,
                );
            });
        }
    }

    getDocItems() {
        const { point, number } = this.props;
        const { docs = [] } = point;

        const resultDocs = JSON.parse(JSON.stringify(docs));

        if (number !== 0) {
            resultDocs.unshift({ _id: 'etn' });
        }

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            resultDocs.push({ _id: 'add' });
        }

        return resultDocs;
    }

    renderDoc({ item: doc, prop: id }) {
        const { handlerDocs, order, point, editName, handlerFilesOfDoc, docsFormData } = this.props;

        if (id === 'etn') {
            return (
                <div className="orderDetailsDocsOfPoint__doc _etn">
                    <DocOfPoint
                        orderId={order._id}
                        pointId={point._id}
                        isReadOnly={true}
                        docKey="orderEtn"
                        name="ЭТН"
                        transportDoc={point.transportDoc}
                        isDisabled={
                            !['in-proccess', 'wait-close', 'complete'].includes(order.status)
                                ? true
                                : undefined
                        }
                    />
                </div>
            );
        }

        if (id === 'add') {
            const { isLoadAddDocs } = this.state;

            return (
                <div className="orderDetailsDocsOfPoint__doc _add">
                    <div
                        className={`orderDetailsDocsOfPoint__add _col ${
                            editName !== point._id ? '_disabled' : ''
                        }`}
                    >
                        <p className="orderDetailsDocsOfPoint__addSupport">+ Другой документ</p>
                        <div
                            className="orderDetailsDocsOfPoint__addButton"
                            onClick={() => this.addDoc()}
                        >
                            <Button
                                className="_minSize _disabledDark _mainNotBorder"
                                showLoader={isLoadAddDocs}
                                isDisabled={editName !== point._id}
                            >
                                Добавить
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="orderDetailsDocsOfPoint__doc">
                <div className="orderDetailsDocsOfPoint__groupDocInner">
                    <DocOfPoint
                        point={point}
                        isDisabled={editName !== point._id}
                        handlerFilesOfDoc={handlerFilesOfDoc}
                        docsFormData={docsFormData}
                        handlerDocs={handlerDocs}
                        {...doc}
                    />
                </div>
            </div>
        );
    }

    checkChangeDocs() {
        const { savedDocs } = this.state;
        const { point } = this.props;
        const { docs } = point;
        let isChange = false;

        if (savedDocs.length !== docs.length) {
            isChange = true;
        } else {
            docs.forEach((doc) => {
                const savedDoc = savedDocs.find((innerDoc) => innerDoc._id === doc._id);

                if (!savedDoc) {
                    isChange = true;
                } else {
                    if (savedDoc.name !== doc.name) {
                        isChange = true;
                    }

                    if (savedDoc.files.length !== doc.files.length) {
                        isChange = true;
                    }
                }
            });
        }

        if (isChange) {
            this.setState({
                savedDocs: JSON.parse(JSON.stringify(docs)),
                updatedKey: new Date().getTime(),
            });
        }
    }

    componentDidMount() {
        const { point } = this.props;
        const { docs } = point;

        this.setState({ savedDocs: JSON.parse(JSON.stringify(docs)) });
    }

    componentDidUpdate() {
        this.checkChangeDocs();
    }

    render() {
        const { updatedKey } = this.state;
        const { point } = this.props;
        const { status, statusDate } = point;
        const dateOfUpdate = point.dateOfUpdateDocs
            ? getFormatedDate({ date: new Date(point.dateOfUpdateDocs), type: 'full' })
            : '–';

        return (
            <div ref={this.parent} className={`orderDetailsDocsOfPoint`}>
                <div className="orderDetailsDocsOfPoint__head _row">
                    <input
                        type="text"
                        className="orderDetailsDocsOfPoint__address"
                        style={{
                            width: `${getWidthText({
                                text: point.address,
                                size: 16.5,
                                weight: 600,
                            })}px`,
                        }}
                        defaultValue={point.address}
                        readOnly
                    />
                    <div className="orderDetailsDocsOfPoint__status">
                        <StatusPoint status={status} statusDate={statusDate} />
                    </div>
                    <div className="orderDetailsDocsOfPoint__date _row">
                        Последняя загрузка:
                        <AnimateChange
                            className="orderDetailsDocsOfPoint__dateContent"
                            classNameParent="_parent"
                            prop={dateOfUpdate}
                            type="_translateMedium"
                        >
                            {dateOfUpdate}
                        </AnimateChange>
                    </div>
                </div>
                <div className="orderDetailsDocsOfPoint__content">
                    <div className="orderDetailsDocsOfPoint__contentInner">
                        <ListAbsoluteMain
                            className="orderDetailsDocsOfPoint__docs"
                            classNameInner="orderDetailsDocsOfPoint__docsInner"
                            items={this.getDocItems()}
                            renderItem={this.renderDoc}
                            classNameItem="orderDetailsDocsOfPoint__doc"
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['width']}
                            itemParams={['offsetLeft', 'offsetTop', 'width']}
                            renderChildren={(inner) => (
                                <div className="orderDetailsDocsOfPoint__docsInner">{inner}</div>
                            )}
                            keyUpdateItem={updatedKey}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DocsOfPoint);

DocsOfPoint.propTypes = {
    order: PropTypes.object,
    point: PropTypes.object,
    editName: PropTypes.string,
    handlerFilesOfDoc: PropTypes.func,
    docsFormData: PropTypes.object,
    handlerDocs: PropTypes.func,
    number: PropTypes.number,
    fakeCallback: PropTypes.func,
};
