import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Button from '../../Button.jsx';
import getFormatPrice from '../../../functions/getFormatPrice';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

import handlerPopup from '../../../functions/app/handlerPopup';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Animate from '../../Animate.jsx';
import CodeBox from '../../CodeBox.jsx';
import Loader from '../../Loader.jsx';
import ListScroll from '../../ListScroll.jsx';
import getEndText from '../../../functions/getEndText';
import Switch from '../../Switch.jsx';
import ExecutorOrg from '../ExecutorOrg.jsx';
import Table from '../manual/Table.jsx';
import getPays from '../../../requests/getPays';

class PaysPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'main',
            counterScroll: this.stepCounter,
        };

        this.renderStep = this.renderStep.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handlerScroll = this.handlerScroll.bind(this);
        this.close = this.close.bind(this);
        this.pay = this.pay.bind(this);
        this.sendCode = this.sendCode.bind(this);
        this.saveCode = this.saveCode.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 50;

    stepsOrder = ['main', 'final'];

    getSteps() {
        const { currentStep } = this.state;

        return [{ key: currentStep }];
    }

    handlerCurrent(id) {
        this.setState((state) => {
            const newState = { ...state };
            const items = JSON.parse(JSON.stringify(newState.items));

            const itemIndex = items.findIndex((item) => item._id === id);

            if (itemIndex !== -1) {
                items[itemIndex].isActive = !items[itemIndex].isActive;
            }

            newState.items = items;

            return newState;
        });
    }

    renderItem({ item }) {
        const { _id, userName, cardNumber, name, amount, beforeCommission, executorOrganization } =
            item;

        return (
            <div className="paysPopup__item _row" key={_id}>
                <div className="paysPopup__itemCol _col _number">
                    <div className="paysPopup__itemText">{item.number}</div>
                    <div className="paysPopup__itemOrg">
                        <ExecutorOrg name={executorOrganization} />
                    </div>
                </div>
                <div className="paysPopup__itemCol _col _name">
                    <div className="paysPopup__itemText">{name || '–'}</div>
                </div>
                <div className="paysPopup__itemCol _col _info">
                    <div className="paysPopup__itemText">{userName}</div>
                    <div className="paysPopup__itemText _number">{cardNumber}</div>
                </div>

                <div className="paysPopup__itemCol _col _amount">
                    <div className="paysPopup__itemText">
                        {getFormatPrice(+amount.toFixed(2))} ₽
                    </div>
                    {beforeCommission && (
                        <div className="paysPopup__itemText _grey">
                            Ком. {getFormatPrice(+beforeCommission?.toFixed(2))} ₽
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handlerScroll({ target }) {
        const { scrollTop } = target;
        const maxScroll = target.scrollHeight - target.offsetHeight;

        if (maxScroll - scrollTop < 5 && !this.state.isScrollFull) {
            this.setState({ isScrollFull: true });
        }

        if (maxScroll - scrollTop >= 5 && this.state.isScrollFull) {
            this.setState({ isScrollFull: false });
        }
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    steps = {
        main: {
            render() {
                const {
                    isScrollFull,
                    items = [],
                    isInit,
                    isDisabledScroll,
                    isLimit,
                    isShowLoaderList,
                } = this.state;
                const { paysPopup } = this.props;
                const { isSign } = paysPopup;

                return (
                    <>
                        <div className="paysPopup__description">
                            {isSign ? (
                                <>
                                    Вы хотите подписать выбранные акты —<br />
                                    подтвердите операцию смс-кодом
                                </>
                            ) : (
                                <>
                                    Вы хотите оплатить выбранные акты —<br />
                                    подтвердите операцию смс-кодом
                                </>
                            )}
                        </div>
                        <div className={`paysPopup__itemsBox ${isScrollFull ? '_full' : ''}`}>
                            <div className="paysPopup__itemsWrapper" onScroll={this.handlerScroll}>
                                <div className="paysPopup__items">
                                    {isInit && (
                                        <ListScroll
                                            getParent={() =>
                                                this.parent.current?.querySelector(
                                                    '.paysPopup__itemsWrapper',
                                                )
                                            }
                                            callback={this.getMoreItems}
                                            startCounter={this.stepCounter}
                                            stepCounter={this.stepCounter}
                                            maxCounter={Infinity}
                                            lengthCurrent={items.length}
                                            isDisabledScroll={!isInit || isDisabledScroll}
                                            isLimit={isLimit}
                                            handlerLoaderList={this.handlerLoaderList}
                                        >
                                            {items.map((item, key) =>
                                                this.renderItem({ item, key }),
                                            )}
                                        </ListScroll>
                                    )}
                                </div>
                            </div>
                            <Animate
                                className="paysPopup__listLoader _loaderScroll _white"
                                isShow={!!isShowLoaderList}
                            >
                                <div className="paysPopup__listLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                        </div>
                    </>
                );
            },
            renderButton() {
                const { loadingKey, items = [] } = this.state;

                return (
                    <>
                        <Button
                            icon={{ name: 'arrow-next', type: 'end' }}
                            onClick={this.sendCode}
                            isDisabled={items.length === 0}
                            showLoader={loadingKey === 'code'}
                        >
                            Смс-код
                        </Button>
                    </>
                );
            },
        },
        final: {
            render() {
                const { loadingKey, updateCodeKey } = this.state;

                return (
                    <>
                        <div className="paysPopup__description">
                            Мы отправили смс-код на ваш номер
                            <br />
                            телефона — введите код в поле ниже
                        </div>
                        <div className="paysPopup__code _row">
                            <div className="paysPopup__codeBox">
                                <CodeBox
                                    className="_pays"
                                    inputs={[1, 2, 3, 4, 5, 6]}
                                    callback={this.saveCode}
                                    inMoment={true}
                                    updateKey={updateCodeKey}
                                />
                            </div>
                            <div className="paysPopup__codeInfo _col">
                                <div className="paysPopup__codeInfoTitle">Код из смс</div>
                                <div
                                    className="paysPopup__codeInfoBtn _click"
                                    onClick={() => {
                                        this.sendCode(true);
                                    }}
                                >
                                    Отправить повторно
                                    <Animate
                                        className="paysPopup__codeInfoBtnLoader _loader"
                                        isShow={loadingKey === 'codeAgain'}
                                    >
                                        <div className="paysPopup__codeInfoBtnLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
            renderButton() {
                const { loadingKey, authCode, items = [], amount = 0 } = this.state;
                const { paysPopup } = this.props;
                const { isSign } = paysPopup;

                return (
                    <>
                        <Button
                            icon={{ name: 'arrow-next', type: 'end' }}
                            onClick={this.pay}
                            isDisabled={items.length === 0 || !authCode || authCode.length !== 6}
                            showLoader={loadingKey === 'pay'}
                        >
                            {isSign ? (
                                <>
                                    Подписать {items.length}{' '}
                                    {getEndText(items.length, ['акт', 'акта', 'актов'])}
                                </>
                            ) : (
                                <>Оплатить {getFormatPrice(+amount.toFixed(2))} ₽</>
                            )}
                        </Button>
                    </>
                );
            },
        },
    };

    renderStep({ prop: key }) {
        const step = this.steps[key];

        return <div className="paysPopup__step">{step?.render?.call(this)}</div>;
    }

    renderButton({ prop: key }) {
        const step = this.steps[key];

        return (
            <div className={`paysPopup__footButton _${key}`}>{step?.renderButton?.call(this)}</div>
        );
    }

    sendCode(isAgain) {
        const { loadingKey } = this.state;
        const { paysPopup } = this.props;
        const { groupId, isSign, items = [] } = paysPopup;
        const query = this.getQueryForRequest().params;

        if (!loadingKey) {
            this.handlerLoading(isAgain === true ? 'codeAgain' : 'code').then(() => {
                axios
                    .put(
                        `${process.env.REACT_APP_HARD_API}/pays?${query
                            .map((item) => `${item.key}=${item.value}`)
                            .join('&')}`,
                        {
                            groupId,
                            itemsIds: items,
                            isForce: true,
                            isSign,
                            notCompleted: items[0] === 'all',
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { hash } = data;

                                this.setState({ hash, currentStep: 'final' });

                                if (isAgain === true) {
                                    setNotification({ notification: 'success-send-code' });
                                }
                            } else {
                                const { message } = data;

                                if (message === 'Phone is incorrect') {
                                    setNotification({ notification: 'error-corporation-phone' });
                                } else if (message === 'Not enough amount') {
                                    setNotification({ notification: 'error-corporation-balance' });
                                } else if (message === 'Error balance limit') {
                                    setNotification({
                                        notification: 'error-corporation-balance-limit',
                                    });
                                }
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        }
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    saveCode(authCode) {
        return new Promise((resolve) => {
            this.setState({ authCode }, () => {
                resolve(true);
            });
        });
    }

    pay() {
        const { authCode, hash } = this.state;
        let { isAutoPay } = this.state;
        const { paysPopup } = this.props;
        const { groupId, successCallback, isSign, items = [] } = paysPopup;
        const query = this.getQueryForRequest().params;

        if (items[0] === 'all') {
            isAutoPay = true;
        }

        this.handlerLoading('pay').then(() => {
            axios
                .put(
                    `${process.env.REACT_APP_HARD_API}/pays?${query
                        .map((item) => `${item.key}=${item.value}`)
                        .join('&')}`,
                    {
                        groupId,
                        itemsIds: items,
                        code: authCode,
                        isForce: true,
                        isSign,
                        isAutoPay,
                        notCompleted: items[0] === 'all',
                        hash,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            this.close();

                            if (successCallback) {
                                successCallback();
                            }
                        } else {
                            const { message } = data;

                            if (message === 'Code is incorrect') {
                                setNotification({ notification: 'error-send-code' });

                                this.setState({
                                    updateCodeKey: new Date().getTime(),
                                    authCode: null,
                                });
                            }
                        }

                        this.handlerLoading(null);
                    },
                    () => null,
                );
        });
    }

    close() {
        handlerPopup({ name: 'paysPopup', isShow: false });
    }

    getQueryForRequest() {
        const { counterScroll } = this.state;
        const stepKey = counterScroll / this.stepCounter;
        const query = super.getQueryForRequest();
        const { paysPopup } = this.props;
        const { groupId, items = [], isSign, filterQuery } = paysPopup;

        query.params.push({ key: 'notCompleted', value: true });
        query.params.push({ key: 'withGroup', value: true });
        query.params.push({ key: 'groupId', value: groupId || 'main' });

        if (items[0] !== 'all') {
            query.params.push({ key: 'isSign', value: !!isSign });

            const listIds = items.filter(
                (item, key) =>
                    key >= (stepKey - 1) * this.stepCounter && key < stepKey * this.stepCounter,
            );

            listIds.forEach((id) => {
                query.params.push({ key: 'listIds', value: id });
            });
        }

        query.params.push(
            ...filterQuery.filter((item) =>
                ['searchForNumber', 'searchForName', 'searchForPhone', 'searchForAmount'].includes(
                    item.key,
                ),
            ),
        );

        query.params.push({ key: 'beforePay', value: true });

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getPays(query).then(
                ({ pays, isLimit, counter, payGroup }) => {
                    this.setItems(pays, false, isLimit, counter).then(() => {
                        setTimeout(() => {
                            this.setState(
                                { isInit: true, isShowLoaderList: false, amount: payGroup?.amount },
                                resolve,
                            );
                        }, 300);
                    });
                },
                () => null,
            );
        });
    }

    init() {
        this.getItems(true).then(() => {
            const wrapper = this.parent.current.querySelector('.paysPopup__itemsWrapper');

            if (wrapper) {
                wrapper.dispatchEvent(new CustomEvent('scroll'));
            }
        });
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const {
            updateKey,
            currentStep,
            isAutoPay,
            counter,
            amount = 0,
            items = [],
            isInit,
        } = this.state;
        const { paysPopup } = this.props;
        const { isSign } = paysPopup;

        return (
            <div ref={this.parent} className={`paysPopup _col ${isInit ? '_init' : ''}`}>
                <div className="paysPopup__inner _col">
                    <Animate className="paysPopup__loader _loader" isShow={!isInit}>
                        <div className="paysPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="paysPopup__close _click" onClick={this.close}>
                        <Icon name="close-circle" />
                    </div>
                    <div className="paysPopup__content">
                        <div className="paysPopup__head">
                            <div className="paysPopup__title _row">
                                {isSign ? (
                                    <>
                                        Подписание актов:
                                        <AnimateChangeUp
                                            className="paysPopup__titleItem"
                                            renderKey={amount === 0 ? false : amount}
                                        >
                                            <>
                                                {counter ? (
                                                    <>
                                                        {counter}{' '}
                                                        {getEndText(counter, [
                                                            'акт',
                                                            'акта',
                                                            'актов',
                                                        ])}{' '}
                                                        ({getFormatPrice(+amount.toFixed(2))} ₽)
                                                    </>
                                                ) : (
                                                    <>—</>
                                                )}
                                            </>
                                        </AnimateChangeUp>
                                    </>
                                ) : (
                                    <>
                                        Оплата на сумму:
                                        <AnimateChangeUp
                                            className="paysPopup__titleItem"
                                            renderKey={amount === 0 ? false : amount}
                                        >
                                            <>
                                                {getFormatPrice(+amount.toFixed(2))} ₽ ({counter})
                                            </>
                                        </AnimateChangeUp>
                                    </>
                                )}
                            </div>
                        </div>
                        <ListAbsoluteMain
                            className="paysPopup__steps"
                            items={this.getSteps()}
                            renderItem={this.renderStep}
                            classNameItem="paysPopup__step"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                            keyRender={`${updateKey}${items.length}${isInit}`}
                            isNotNullParentSize={true}
                            currentItemKey={currentStep}
                            allItems={this.stepsOrder}
                        />
                        <div className="paysPopup__foot _row">
                            <ListAbsoluteMain
                                className="paysPopup__footButtons"
                                items={this.getSteps()}
                                renderItem={this.renderButton}
                                classNameItem="paysPopup__footButton"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['width', 'height']}
                                isNotParamsItem={true}
                                keyRender={updateKey}
                                isNotNullParentSize={true}
                                currentItemKey={currentStep}
                                allItems={this.stepsOrder}
                            />
                            {isSign && (
                                <>
                                    <div className="paysPopup__footAuto">
                                        <div className="paysPopup__footAutoSwitch">
                                            <Switch
                                                value={!!isAutoPay}
                                                handler={() => {
                                                    this.setState({ isAutoPay: !isAutoPay });
                                                }}
                                            />
                                        </div>
                                        <div className="paysPopup__footAutoTitle">
                                            Автооплата выбранных актов
                                        </div>
                                        <div className="paysPopup__footAutoDescription">
                                            Акты будут оплачены после подписания
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="paysPopup__footPaginations _row">
                                {this.stepsOrder.map((key) => (
                                    <div
                                        className={`paysPopup__footPaginationsItem ${
                                            currentStep === key ? '_current' : ''
                                        }`}
                                        key={key}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        paysPopup: state.paysPopup,
    };
}

export default connect(mapStateToProps)(PaysPopup);

PaysPopup.propTypes = {
    paysPopup: PropTypes.object,
};
