import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.delete = this.delete.bind(this);

        setPermissions.call(this);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <div className="widget__headActionsGroups _row">
                {this.getPermissions({
                    key: 'joins',
                    items: [
                        {
                            key: 'docs',
                            rules: ['delete'],
                        },
                    ],
                }) && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    typeOrganization: PropTypes.string,
    pagesStore: PropTypes.object,
    levels: PropTypes.array,
    delete: PropTypes.func,
};
