import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './roles/Main.jsx';
import Pages from '../../../components/Pages.jsx';

import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import getRealParams from '../../../functions/getRealParams.ts';
import Inner from './roles/Inner.jsx';

class SettingsRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'settings-roles-main': {
            render() {
                const { corporation, getParent, setDeleteRoleRequest, updateCorporation } =
                    this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    corporation={corporation}
                                    getParent={getParent}
                                    setDeleteRoleRequest={setDeleteRoleRequest}
                                    updateCorporation={updateCorporation}
                                    setHeightPage={this.setHeightPage}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-roles-inner': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Inner setHeightPage={this.setHeightPage} />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
    }

    render() {
        return (
            <div ref={this.parent} className="widget _full _ready">
                <div ref={this.parent} className="widget__content _full _inner">
                    <div className="widget__contentBox _full">
                        <Pages
                            classNamePage="widget__page _full _deep2"
                            filter={(page) =>
                                page.parentName === 'settings-roles' && page.level === 3
                            }
                            pages={this.pages}
                            context={this}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsRoles);

SettingsRoles.propTypes = {
    corporation: PropTypes.object,
    getTariffString: PropTypes.func,
    getParent: PropTypes.func,
    setDeleteRoleRequest: PropTypes.func,
    updateCorporation: PropTypes.func,
    setHeightPage: PropTypes.func,
};
