import React from 'react';
import PropTypes from 'prop-types';

import Sign from './Sign.jsx';
import Pagenation from './Pagenation.jsx';
import getUserInfo from '../../functions/getUserInfo';
import getUserIncline from '../../functions/getUserIncline';
import { namesMonths } from '../../functions/getFormatedDate';

class UserProxy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderPagination = this.renderPagination.bind(this);

        this.parent = React.createRef();
    }

    renderPagination({ key }) {
        const { renderPagination, pdf } = this.props;
        const { otherData } = pdf;
        const { contractId, docDate, isTest } = otherData;

        const render = ({ pageCounter }) => (
            <Pagenation
                pageCurrent={key}
                pageCounter={pageCounter}
                contractId={contractId}
                docDate={isTest ? new Date().toUTCString() : docDate}
            />
        );

        return renderPagination({ key, render });
    }

    renderFoot() {
        const { renderFoot, pdf } = this.props;
        const { otherData, data } = pdf;
        const { contractId, docDate } = otherData;
        const { signUserInfo, signExecutorInfo } = data;

        const render = () => (
            <>
                <Sign
                    contractId={contractId}
                    docDate={docDate}
                    signUserInfo={signUserInfo}
                    signExecutorInfo={signExecutorInfo}
                />
            </>
        );

        return renderFoot({ render });
    }

    componentDidMount() {
        const { init } = this.props;

        init();
    }

    render() {
        const { pdf, getPages } = this.props;
        const { data, otherData, corporationInfo } = pdf;
        const { signUserInfo, signExecutorInfo, contractNumber, executorInfo, signs } = data;
        const { isTest } = otherData;
        const withSign = !!signUserInfo || !!signExecutorInfo;
        const { passport } = executorInfo;
        const { birthday = '' } = passport;
        const [day, month, year] = birthday.split('.');

        return (
            <div className="pdf__inner _col">
                {getPages().map((page) => this.renderPagination({ key: page }))}
                <div ref={this.parent} className="pdf__innerBox">
                    <div className="pdfUserProxy">
                        <div className="pdfUserProxy__title">Доверенность №{contractNumber}</div>
                        <div className="pdfUserProxy__block">
                            <div className="pdfUserProxy__text">
                                Настоящей доверенностью{' '}
                                <i>
                                    {corporationInfo?.name} ИНН {corporationInfo.inn} ОГРН{' '}
                                    {corporationInfo.ogrn}
                                </i>{' '}
                                в лице директора{' '}
                                <i>
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({ user: corporationInfo.holder }),
                                    })}
                                </i>
                                , уполномочивает{' '}
                                <i>
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({
                                            user: executorInfo,
                                            dec: 'accusative',
                                        }),
                                    })}
                                </i>{' '}
                                <i>
                                    {day} {namesMonths[+month - 1][1].toLowerCase()} {year}
                                </i>{' '}
                                года рождения,{' '}
                                <i>
                                    {passport.viewName} {passport.series} {passport.number}
                                </i>{' '}
                                выданный{' '}
                                <i>
                                    {passport.nameGet} {passport.dateGet}
                                </i>
                                , зарегистрированного по адресу: <i>{passport.addressReg}</i>,
                                состоящего в штате доверителя на должности{' '}
                                <i>{executorInfo.roleName}</i>, совершать от имени{' '}
                                <i>{corporationInfo?.name}</i> следующие действия:
                            </div>
                        </div>

                        {signs.find(
                            (sign) => sign.sectionKey === 'pays' && sign.itemKey === 'acts',
                        ) && (
                            <div className="pdfUserProxy__block">
                                <div className="pdfUserProxy__text _bold">Акты</div>
                                <div className="pdfUserProxy__text">
                                    Подписывать в целях исполнения договоров, заключенных с любыми
                                    пользователями CRM Livecargo в рамках пользовательского
                                    соглашения ООО «Лайвкарго»
                                    https://livecargo.ru/legal/user-agreement, все необходимые
                                    документы первичного бухгалтерского учета и налоговой
                                    отчетности, включая, но не ограничиваясь актами сдачи-приемки
                                    оказанных услуг.
                                </div>
                            </div>
                        )}
                        {signs.find(
                            (sign) => sign.sectionKey === 'joins' && sign.itemKey === 'contracts',
                        ) && (
                            <div className="pdfUserProxy__block">
                                <div className="pdfUserProxy__text _bold">Договоры</div>
                                <div className="pdfUserProxy__text">
                                    Заключать любые договоры с любыми пользователями CRM Livecargo в
                                    рамках пользовательского соглашения
                                    https://livecargo.ru/legal/user-agreement, согласовывать,
                                    подписывать дополнительные соглашения и приложения к указанным
                                    договорам, без ограничений по сумме.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {withSign && !isTest && this.renderFoot()}
            </div>
        );
    }
}

export default UserProxy;

UserProxy.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    renderFoot: PropTypes.func,
    renderPagination: PropTypes.func,
};
