import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../classes/File';

import Icon from '../Icon.jsx';
import BorderDashed from '../BorderDashed.jsx';
import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Field from '../Field.jsx';

import handlerPopup from '../../functions/handlerPopup';
import ImageLazy from '../ImageLazy.jsx';
import AnimateChangeUp from '../AnimateChangeUp.jsx';

import downloadFile from '../../functions/downloadFile';
import getHeaders from '../../functions/getHeaders';
import Loader from '../Loader.jsx';

class DocOfPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerDoc = this.handlerDoc.bind(this);
        this.download = this.download.bind(this);

        this.parent = React.createRef();
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    uploadFiles({ target }) {
        const { point, _id: idOfDoc, handlerFilesOfDoc, docsFormData } = this.props;
        const { files } = target;

        const names = Object.keys(files).map((key) => `${idOfDoc}-${key}-${new Date().getTime()}`);

        this.handlerFile.uploadFiles({ target, names, formData: docsFormData }).then(
            ({ resultFiles }) => {
                handlerFilesOfDoc({
                    action: 'add',
                    idOfPoint: point._id,
                    idOfDoc,
                    files: resultFiles,
                });
            },
            () => null,
        );
    }

    handlerDoc({ action, value }) {
        return new Promise((resolve) => {
            const { handlerDocs, point, _id: idOfDoc } = this.props;
            const { _id: idOfPoint } = point;

            if (action === 'change') {
                handlerDocs({ action, idOfPoint, idOfDoc, props: { name: value } }).then(() => {
                    resolve();
                });
            } else if (action === 'delete') {
                handlerDocs({ action, idOfPoint, idOfDoc });
            } else {
                this.setState({ nameIsFocus: action === 'focus' }, resolve);
            }
        });
    }

    renderName({ type = '' }) {
        const { _id, isEdit, name, isDisabled } = this.props;

        return (
            <Field
                keyName="name-of-file"
                name="name"
                value={name}
                callback={this.handlerDoc}
                className={`_fileOfDoc ${!isEdit || isDisabled ? '_notBack' : ''} _notFocus ${
                    type === 'info' ? '_white' : '_dark'
                }`}
                group="file-of-doc"
                isDisabled={isDisabled || !isEdit}
                isEditmode={true}
                id={`doc-${_id}-${type}`}
            />
        );
    }

    getSrc(file) {
        return file?.isNew
            ? file?.path
            : `${process.env.REACT_APP_STATIC}/docs-of-point/${file?.path}`;
    }

    isInitDrag = false;

    initDrag() {
        const { isReadOnly } = this.props;

        if (!isReadOnly && !this.isInitDrag) {
            const area = this.parent.current;

            const handlerOver = () => {
                if (!this.props.isDisabled) {
                    this.setState({ dragIsActive: true });
                }
            };
            const handlerLeave = () => {
                if (!this.props.isDisabled) {
                    this.setState({ dragIsActive: false });
                }
            };
            const handlerDrop = (files) => {
                if (!this.props.isDisabled) {
                    this.uploadFiles({ target: { files } });
                    this.setState({ dragIsActive: false });
                }
            };

            this.handlerFile.setDrag({ area, handlerOver, handlerLeave, handlerDrop });

            this.isInitDrag = true;
        }
    }

    download() {
        const { docKey, orderId, pointId, isDisabled } = this.props;

        if (!isDisabled) {
            if (docKey === 'orderEtn') {
                this.handlerLoading('download').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/order-etn`,
                            {
                                orderId,
                                pointId,
                            },
                            {
                                responseType: 'blob',
                                headers: getHeaders(),
                            },
                        )
                        .then(
                            (res) => {
                                res.data.text().then(
                                    (result) => {
                                        try {
                                            const { success } = JSON.parse(result);

                                            if (success === false) {
                                                console.log(success);
                                            }
                                        } catch (error) {
                                            const filename = `ЭТН.zip`;

                                            downloadFile({ filename, data: res.data });
                                        }
                                    },
                                    () => null,
                                );

                                this.handlerLoading(null);
                            },
                            () => null,
                        );
                });
            }
        }
    }

    componentDidMount() {
        this.handlerFile = new File({ typeOfFiles: 'docs' });

        this.initDrag();
    }

    render() {
        const { nameIsFocus, dragIsActive, loadingKey } = this.state;
        const { isReadOnly, isEdit, name, isDisabled, files, transportDoc } = this.props;

        const status = transportDoc?.isConfirm ? 'Подписана' : 'Не подписана';

        return (
            <div
                ref={this.parent}
                className={`orderDetailsDocOfPoint ${isReadOnly ? '_isReadOnly' : ''} ${
                    files?.length === 0 ? '_empty' : ''
                } ${
                    isDisabled !== undefined
                        ? !isDisabled
                            ? '_active'
                            : isReadOnly
                            ? '_disabled'
                            : ''
                        : ''
                } ${nameIsFocus ? '_focus' : ''} ${dragIsActive ? '_dragIsActive' : ''} ${
                    isEdit ? '_isEdit' : ''
                } ${loadingKey ? '_isLoading' : ''} ${transportDoc?.isConfirm ? '_confirm' : ''}`}
            >
                <Animate className="orderDetailsDocOfPoint__loader _loader" isShow={!!loadingKey}>
                    <div className="orderDetailsDocOfPoint__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                {isReadOnly && (
                    <div className="orderDetailsDocOfPoint__inner _col _click" onClick={this.download}>
                        <div className="orderDetailsDocOfPoint__innerContent _col">
                            <i className="orderDetailsDocOfPoint__icon">
                                <Icon name="download" />
                            </i>
                            <div className="orderDetailsDocOfPoint__name">{name}</div>
                            <AnimateChangeUp
                                className="orderDetailsDocOfPoint__description"
                                renderKey={status}
                            >
                                {status}
                            </AnimateChangeUp>
                        </div>
                    </div>
                )}
                {!isReadOnly && (
                    <label className="orderDetailsDocOfPoint__inner _col _click">
                        {isEdit && (
                            <Animate
                                className="orderDetailsDocOfPoint__delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handlerDoc({ action: 'delete' });
                                }}
                                isShow={!isDisabled}
                            >
                                <div
                                    className={`action _col _delete ${
                                        files?.length > 0 ? '_notShadow _dark' : ''
                                    }`}
                                >
                                    <i className="action__icon">
                                        <Icon name="delete" />
                                    </i>
                                </div>
                            </Animate>
                        )}
                        <input
                            type="file"
                            className="orderDetailsDocOfPoint__file"
                            multiple
                            disabled={isDisabled}
                            onChange={({ target }) => this.uploadFiles({ target })}
                        />
                        <div className="orderDetailsDocOfPoint__back">
                            <BorderDashed className="_upload _grey" />
                        </div>
                        <Animate
                            className="orderDetailsDocOfPoint__image"
                            isShow={files.length}
                            onClick={() => {
                                if (isDisabled) {
                                    handlerPopup({
                                        action: 'show',
                                        name: 'popupGalery',
                                        images: files.map((file) => ({
                                            path: this.getSrc(file),
                                            type: file.type,
                                        })),
                                        title: name,
                                    });
                                }
                            }}
                        >
                            <div className="orderDetailsDocOfPoint__imageCounter _row">
                                <AnimateChange
                                    className="orderDetailsDocOfPoint__imageCounterItem"
                                    prop={files.length}
                                    type="_translateMedium"
                                >
                                    {`${files.length}`}
                                </AnimateChange>
                                /20
                            </div>
                            <div className="orderDetailsDocOfPoint__imageInfo _col">
                                <i className="orderDetailsDocOfPoint__imageInfoIcon">
                                    <Icon name="search" />
                                </i>
                                <div className="orderDetailsDocOfPoint__imageInfoName">
                                    {this.renderName({ type: 'info' })}
                                </div>
                            </div>
                            <ImageLazy
                                src={this.getSrc(files[0])}
                                className="orderDetailsDocOfPoint__imagePreview"
                            />
                        </Animate>
                        <Animate className="orderDetailsDocOfPoint__drag _col" isShow={dragIsActive}>
                            Отпустите курсор для загрузки файла
                        </Animate>
                        <Animate className="orderDetailsDocOfPoint__content _col" isShow={!files.length}>
                            <i className="orderDetailsDocOfPoint__icon">
                                <Icon name="camera-2" />
                            </i>
                            <div className="orderDetailsDocOfPoint__name">
                                {this.renderName({})}
                            </div>
                            <p className="orderDetailsDocOfPoint__description">До 20 файлов</p>
                        </Animate>
                    </label>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DocOfPoint);

DocOfPoint.propTypes = {
    _id: PropTypes.string,
    isReadOnly: PropTypes.bool,
    name: PropTypes.string,
    doc: PropTypes.object,
    isDisabled: PropTypes.bool,
    isEdit: PropTypes.bool,
    point: PropTypes.object,
    handlerFilesOfDoc: PropTypes.func,
    files: PropTypes.array,
    docsFormData: PropTypes.object,
    handlerDocs: PropTypes.func,
    orderId: PropTypes.string,
    pointId: PropTypes.string,
    docKey: PropTypes.string,
    transportDoc: PropTypes.object,
};
