import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Animate from '../../../Animate.jsx';
import Comments from '../card/Comments.jsx';

import getEndText from '../../../../functions/getEndText';

class ManualOrganizationsAddressMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getDescriptionInfo() {
        const { organization } = this.props;
        const { comments } = organization;

        const description = comments.length
            ? `${comments.length} ${getEndText(comments.length, [
                  'комментарий',
                  'комментария',
                  'комментариев',
              ])}`
            : '–';

        return {
            description,
        };
    }

    render() {
        const {
            organization,
            checkEditmode = () => false,
            addComment,
            changeComment,
            deleteComment,
        } = this.props;
        const { description } = this.getDescriptionInfo();
        const { comments } = organization;

        return (
            <div className="manualCard _organizationComments">
                <div className="manualCard__head _row">
                    <Head title="Комментарии" description={description} />
                </div>
                <div className="manualCard__content _fix">
                    <Animate
                        className="manualCard__contentEmpty"
                        isShow={!checkEditmode() && comments.length === 0}
                    >
                        <div className="empty _col _block _notBack">
                            <div className="empty__inner">
                                <div className="empty__title">Комментариев нет</div>
                                <p className="empty__content">
                                    Вы можете добавить их перейдя <br />в режим редактирования
                                </p>
                            </div>
                        </div>
                    </Animate>
                    <div className="manualCard__comments">
                        <Comments
                            comments={comments}
                            add={addComment}
                            change={changeComment}
                            deleteComment={deleteComment}
                            isDisabled={!checkEditmode()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualOrganizationsAddressMail);

ManualOrganizationsAddressMail.propTypes = {
    organization: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    addComment: PropTypes.func,
    changeComment: PropTypes.func,
    deleteComment: PropTypes.func,
};
