import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../../Icon.jsx';
import Field from '../../../../Field.jsx';

const tariffConfigFields = require('../../../../../infos/crm/tariffConfigFields.json');

class ParamsPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    cards = {
        pallets: {
            icon: 'pallets',
            support: 'Паллеты',
            keyName: 'numberCounter',
        },
        maxWeight: {
            icon: 'tonnage',
            support: 'Макс. груз',
            keyName: 'numberWeight',
        },
        volume: {
            icon: 'volume',
            support: 'Объём',
            keyName: 'numberVolume',
        },
    };

    render() {
        const { type, parameters, isDisabled, handler, errors, id } = this.props;

        return (
            <div className={`manualCardParamsPreview _row _${type} ${!isDisabled ? '_edit' : ''}`}>
                <div className="manualCardParamsPreview__cards _row">
                    {tariffConfigFields[type].main.map(({ name }) => {
                        const card = this.cards[name];
                        const { icon, support, keyName } = card;
                        let value = parameters[name];
                        const isError = errors?.[name];

                        if (name === 'volume') {
                            value = +(
                                (parameters.long * parameters.width * parameters.height) /
                                1_000_000
                            ).toFixed(1);
                        }

                        return (
                            <div
                                className={`manualCardParamsPreview__card _col ${
                                    isError ? '_error' : ''
                                }`}
                                key={name}
                            >
                                <i className="manualCardParamsPreview__cardIcon">
                                    <Icon name={icon} />
                                </i>
                                <div className="manualCardParamsPreview__cardValue">
                                    <Field
                                        id={`${id}-${name}`}
                                        keyName={keyName}
                                        name={name}
                                        value={value}
                                        callback={handler}
                                        className={`_manualMain _bigSize _fontMedium _whiteBack ${
                                            isDisabled ? '_notBack' : ''
                                        } _center`}
                                        isDisabled={isDisabled}
                                        isEditmode={true}
                                        error={isError}
                                    />
                                </div>
                                <p className="manualCardParamsPreview__cardSupport">{support}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ParamsPreview);

ParamsPreview.propTypes = {
    id: PropTypes.string,
    parameters: PropTypes.object,
    type: PropTypes.string,
    isDisabled: PropTypes.bool,
    handler: PropTypes.func,
    errors: PropTypes.object,
};
