import axios from 'axios';

import getHeaders from './getHeaders';
import handlerErrorRequest from './handlerErrorRequest';
import handlerWindow from './handlerWindow';
import setNotification from './setNotification';

export default function cancelOrder({ parent, target, status, id }) {
    const getCondForCancel = () => ['new', 'start-work', 'choice-crew'].indexOf(status) !== -1;

    const reqCancelOrder = () =>
        new Promise((resolve) => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/order`, {
                        id,
                        type: 'change-status-order',
                        status: 'cancel',
                    }, {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (success) {
                            resolve();
                        } else {
                            handlerErrorRequest(res);

                            setNotification({ notification: 'error-cancel-order' });
                        }
                    },
                    () => null,
                );
        });

    if (getCondForCancel()) {
        handlerWindow({
            parent,
            parentResize: parent,
            target,
            action: 'show',
            name: 'promptCancelOrder',
            className: '_prompt',
            uniqKey: `prompt-${id}`,
            cancelOrder: reqCancelOrder,
            centers: {
                left: 0.5,
                top: 1,
            },
        });
    }
}