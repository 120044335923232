export default function getFilterModel({ stateFilter, localName }) {
    if (stateFilter) {
        return stateFilter;
    }

    let localFilter = localStorage.getItem(localName);

    localFilter = localFilter ? JSON.parse(localFilter) : [];

    return localFilter;
}
