import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import handlerPopup from '../../../functions/app/handlerPopup';

import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';

class ServerNotAvailablePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="bonusPopup _col _serverNotAvailable">
                <div className="bonusPopup__inner">
                    <div className="bonusPopup__innerBox _col">
                        <i className="bonusPopup__icon">
                            <Icon name="alert-2" />
                        </i>
                        <div className="bonusPopup__title">Идут технические работы</div>
                        <p
                            className="bonusPopup__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Ввиду избежания ошибок, функции <b>проведения платежей</b>, <b>подписания договоров</b> и <b>отправления рассылок</b> не работают. Приносим извинения за неудобства',
                                ),
                            }}
                        />
                        <div className="bonusPopup__button">
                            <Button
                                onClick={() => {
                                    handlerPopup({
                                        name: 'serverNotAvailablePopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                Понятно
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ServerNotAvailablePopup);

ServerNotAvailablePopup.propTypes = {
    isBonusPopupShow: PropTypes.bool,
};
