import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getUserInfo from '../../functions/getUserInfo';

import getOrganizations from '../../requests/getOrganizations';

import Icon from '../Icon.jsx';
import Field from '../Field.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Animate from '../Animate.jsx';
import WindowList from '../WindowList.jsx';
import AnimateChange from '../AnimateChange.jsx';
import stopPropagationClick from '../../functions/stopPropagationClick';

class DetailsHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowWindow: false,
        };

        this.handlerHolder = this.handlerHolder.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
        this.handlerComments = this.handlerComments.bind(this);
        this.addnewHolder = this.addnewHolder.bind(this);
        this.handlerUser = this.handlerUser.bind(this);
        this.handlerMissClickWindow = this.handlerMissClickWindow.bind(this);

        this.parent = React.createRef();
    }

    infosOrder = {
        juristic: ['bic', 'inn'],
        physical: ['fullname', 'phone'],
    };

    infos = {
        fullname: {
            support: 'ФИО',
        },
        phone: {
            support: 'Тел.',
        },
        inn: {
            support: 'ИНН',
        },
        bic: {
            support: 'БИК',
        },
    };

    getHolderData() {
        const { clients = [] } = this.state;
        const { order, isNew } = this.props;
        const client = clients.find((clientLoop) => clientLoop._id === order?.holder);

        return isNew
            ? {
                  ...client,
                  id: client?._id,
              }
            : order?.holderData;
    }

    getInfosOrder() {
        const { order } = this.props;
        const type = order?.type;
        const newHolder = order?.newHolder;
        const holderData = this.getHolderData();

        if (!order) {
            return [];
        }

        return this.infosOrder[type].map((key) => ({
            _id: `${key}-${type}-${!!newHolder}`,
            key,
            value: newHolder
                ? newHolder[key]
                : key === 'fullname'
                ? getUserInfo({
                      type: 'name',
                      user: holderData,
                  })
                : holderData?.[key],
        }));
    }

    renderInfo({ item }) {
        const { order, isNew, errors } = this.props;
        const newHolder = order?.newHolder;
        const { key, value } = item;
        const info = this.infos[key];
        const { support } = info;

        return (
            <div className="orderDetailsCard__info _row">
                <p className="orderDetailsCard__infoSupport">{support}</p>
                <div className={`orderDetailsCard__infoField ${isNew ? '_edit' : ''}`}>
                    <Field
                        type="orderHolder"
                        name={key}
                        keyName={key}
                        value={newHolder ? value : value || '–'}
                        callback={this.handlerUser}
                        className={`_orderService _center`}
                        group="holder"
                        isDisabled={!newHolder}
                        isEditmode={isNew}
                        error={errors?.indexOf(key) !== -1}
                    />
                </div>
            </div>
        );
    }

    handlerUser({ action, name, value }) {
        const { order, changeOrder } = this.props;
        const newHolder = order?.newHolder;

        return new Promise((resolve) => {
            if (action === 'change') {
                changeOrder({
                    props: {
                        newHolder: { ...newHolder, [name]: value },
                        holder: undefined,
                    },
                }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    handlerHolder({ value: holder }) {
        const { clients = [] } = this.state;
        const { changeOrder, isNew, order } = this.props;
        const client = clients.find((clientLoop) => clientLoop._id === holder);

        return new Promise((resolve) => {
            changeOrder({
                props: {
                    holder,
                    ...(isNew
                        ? {
                              creater:
                                  client.typeOrganization === 'juristic'
                                      ? client.userInfo?._id || holder
                                      : holder,
                              type: client.typeOrganization,
                              ...(order?.systemType === 'crm' ? { tariff: {} } : {}),
                              tariffInfo: undefined,
                              newHolder: undefined,
                          }
                        : {}),
                },
            }).then(resolve);
        });
    }

    handlerComments({ action, value: comments }) {
        const { changeOrder, isNew } = this.props;

        return new Promise((resolve) => {
            if (isNew && action === 'change') {
                changeOrder({
                    props: {
                        comments,
                    },
                }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    addnewHolder(type) {
        const { order, changeOrder } = this.props;
        const newHolder = order?.newHolder;

        return new Promise((resolve) => {
            changeOrder({
                props: {
                    type,
                    newHolder: newHolder || {},
                    holder: undefined,
                    tariffInfo: undefined,
                    creater: undefined,
                },
            }).then(resolve);
        });
    }

    getClients() {
        getOrganizations({
            params: [{ key: 'forOrder', value: 'true' }],
        }).then(
            ({ organizations }) => {
                this.setState({ clients: organizations });
            },
            () => null,
        );
    }

    getClientsList() {
        const { clients = [] } = this.state;
        const { isNew } = this.props;

        if (!isNew) {
            return [];
        }

        let resultClients = clients.map((client) => {
            if (client.typeOrganization === 'juristic') {
                return {
                    ...client,
                    type: 'company',
                    content: client.name,
                };
            }

            return {
                ...client,
                type: 'client',
                content: getUserInfo({ type: 'fullnameShort', user: client }),
            };
        });

        resultClients = [
            ...[
                { _id: 'physical', content: 'Новое физическое лицо', text: 'Ф' },
                { _id: 'juristic', content: 'Новое юридическое лицо', text: 'Ю' },
            ],
            ...resultClients,
        ];

        return resultClients;
    }

    handlerWindow(isShowWindow = !this.state.isShowWindow) {
        return new Promise((resolve) => {
            this.setState({ isShowWindow }, resolve);
        });
    }

    getResultHolder() {
        const { order, isNew } = this.props;
        const holder = order?.holder;
        const clients = this.getClientsList();

        if (holder) {
            if (isNew) {
                const client = clients.find((innerClient) => innerClient._id === holder);

                if (client) {
                    return client.content;
                }
            }

            return (
                order?.holderData?.fullName ||
                getUserInfo({ type: 'fullnameShort', user: order?.holderData })
            );
        }

        if (isNew && order?.newHolder) {
            return order?.type === 'physical' ? 'Новое физ. лицо' : 'Новое юр. лицо';
        }

        return 'Не выбран';
    }

    handlerMissClickWindow({ target }) {
        const { isShowWindow } = this.state;
        const list = this.parent.current.querySelector('.orderDetailsCard__clientsWindow');

        if (isShowWindow && target !== list && !list.contains(target)) {
            this.handlerWindow(false);
        }
    }

    componentDidMount() {
        const { isNew } = this.props;

        if (isNew) {
            this.getClients();
        }

        document.addEventListener('click', this.handlerMissClickWindow);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClickWindow);
    }

    render() {
        const { isShowWindow } = this.state;
        const { editName, order, isNew, errors } = this.props;
        const resultHolder = this.getResultHolder();

        return (
            <div
                ref={this.parent}
                className={`orderDetailsCard _holder ${isNew ? '_new' : ''} ${
                    editName === 'holder' && isNew ? '_edit' : ''
                }`}
            >
                <div className="orderDetailsCard__inner">
                    <div className="orderDetailsCard__head _col">
                        <h5 className="orderDetailsCard__title">Клиент</h5>
                        <div
                            className={`orderDetailsCard__description _row ${
                                errors?.indexOf('holder') !== -1 ? '_error' : ''
                            }`}
                            onClick={(e) => {
                                if (editName === 'holder' && isNew) {
                                    this.handlerWindow();

                                    stopPropagationClick(e);
                                }
                            }}
                        >
                            <AnimateChange
                                className="orderDetailsCard__descriptionItem"
                                classNameChild="_child"
                                prop={resultHolder}
                                type="_translateMedium"
                            >
                                {`${resultHolder}`}
                            </AnimateChange>
                        </div>
                        {this.getClientsList()?.length > 0 && (
                            <Animate
                                className="orderDetailsCard__clientsWindow"
                                isShow={isShowWindow}
                            >
                                <WindowList
                                    name="orderClients"
                                    className="_centerRight"
                                    items={this.getClientsList()}
                                    idOfCurrent={order?.newHolder ? order?.type : order?.holder}
                                    callback={({ id }) =>
                                        new Promise((resolve) => {
                                            if (id === 'physical' || id === 'juristic') {
                                                this.addnewHolder(id).then(() => {
                                                    this.handlerWindow(false);

                                                    resolve();
                                                });
                                            } else {
                                                this.handlerHolder({ value: id }).then(() => {
                                                    this.handlerWindow(false);

                                                    resolve();
                                                });
                                            }
                                        })
                                    }
                                />
                            </Animate>
                        )}
                    </div>
                    <div className="orderDetailsCard__content">
                        <div className="orderDetailsCard__contentInner">
                            <ListAbsoluteMain
                                className="orderDetailsCard__infos _col"
                                items={this.getInfosOrder()}
                                renderItem={this.renderInfo}
                                classNameItem="orderDetailsCard__info"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                itemParams={['offsetTop', 'height']}
                                defaultTop={0}
                                propsForUpdate={['value']}
                            />
                            {(order?.comments || isNew) && (
                                <div
                                    className={`orderDetailsCard__comment ${isNew ? '_edit' : ''}`}
                                >
                                    <i className="orderDetailsCard__commentSpeach">
                                        <Icon name="comment-speach" />
                                    </i>
                                    <div className="orderDetailsCard__commentBox">
                                        <Field
                                            name="comments"
                                            tag="area"
                                            support="Комментарий"
                                            className="_notBack"
                                            value={order?.comments || ''}
                                            isDisabled={!isNew}
                                            callback={this.handlerComments}
                                            isEditmode={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsHolder);

DetailsHolder.propTypes = {
    order: PropTypes.object,
    editName: PropTypes.string,
    isDisabled: PropTypes.bool,
    changeOrder: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
};
