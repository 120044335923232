import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../functions/setServerData';
import getFormatedDate from '../../functions/getFormatedDate';

import AnimateChange from '../AnimateChange.jsx';
// import removeTransition from '../../functions/removeTransition.ts';

class OrderStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderName = this.renderName.bind(this);
    }

    renderName(nameRes) {
        let name = nameRes;

        if (name.indexOf('!name!') !== -1) {
            let content;
            const { order, serverData } = this.props;
            const { crew, tariffInfo } = order;

            if (order.systemType === 'crm') {
                if (
                    crew.length > 1 ||
                    (crew.find((item) => item.role === 'driver') && crew.length > 1)
                ) {
                    content = 'экипаж';
                } else {
                    const type = tariffInfo.typeConfig === 'car' ? 'driver' : tariffInfo.typeConfig;

                    content =
                        serverData.executorNames &&
                        serverData.executorNames[type].content.toLowerCase();
                }
            } else {
                content = 'экипаж';
            }

            name = name.replace('!name!', content);
        }

        const letters = name.split('');

        return `${letters.shift().toUpperCase()}${letters.join('')}`;
    }

    renderContent({ name, content }) {
        const { order, date, serverData, className } = this.props;
        const { orderStatuses } = serverData;

        return (
            <div className="orderStatus__innerItem">
                {orderStatuses && order.status
                    ? (content && this.renderName(content)) ||
                      this.renderName(orderStatuses[name].content)
                    : 'Статус'}
                {className === '_gps' ? ':' : ''}
                {` `}
                {date && `(${getFormatedDate({ date: new Date(date), type: 'full' })})`}
            </div>
        );
    }

    getDatas = ['pointStatuses', 'orderStatuses', 'executorNames'];

    componentDidMount() {
        this.getDatas.forEach((name) => {
            setServerData(name);
        });
    }

    render() {
        const { order, status, serverData, type, className = '' } = this.props;
        const { pointStatuses, orderStatuses } = serverData;
        let content;
        let name = 'complete';

        if (status) {
            if (
                order.systemType !== 'service' &&
                (status.name === 'in-proccess' || status.type === 'point')
            ) {
                const { route } = order;
                let { idOfCurrentPoint } = order;
                let statusKey;

                if (!idOfCurrentPoint || status.type === 'point') {
                    idOfCurrentPoint = status.idPoint;
                    statusKey = status.statusKey;
                }

                const point = route.find((pointLoop) => pointLoop._id === idOfCurrentPoint);
                const statusPoint = point.statuses[statusKey ?? point.statuses.length - 1]?.name;

                name = 'in-proccess';

                if (pointStatuses && pointStatuses[statusPoint]?.short) {
                    content = `${pointStatuses[statusPoint].short} №${route.indexOf(point) + 1}`;
                }
            } else {
                name = status.name;
            }
        }

        return (
            <div className={`orderStatus _${name} ${(type && `_${type}`) || ``} ${className}`}>
                {type !== 'full' && (
                    <>
                        <div className="orderStatus__point"></div>
                        <AnimateChange
                            className="orderStatus__content"
                            prop={`${name}-${content}-${orderStatuses?.[name].content}`}
                            type="_translateMedium"
                        >
                            {this.renderContent({ name, content })}
                        </AnimateChange>
                    </>
                )}
                {type === 'full' && (
                    <AnimateChange
                        className="orderStatus__inner"
                        prop={`${name}-${content}-${orderStatuses?.[name].content}`}
                        type="_translateMedium"
                    >
                        {this.renderContent({ name, content })}
                    </AnimateChange>
                )}

                {this.props.children}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(OrderStatus);

OrderStatus.propTypes = {
    serverData: PropTypes.object,
    order: PropTypes.object,
    status: PropTypes.object,
    type: PropTypes.string,
    date: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};
