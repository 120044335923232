export default class Stack {
    constructor({ name }) {
        this.items = [];
        this.timer = null;
        this.name = name;
    }

    setInStack({ context, id, value }) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.items.push({ id, value });

        return new Promise((resolve) => {
            this.timer = setTimeout(() => {
                context.setState(
                    (state) => {
                        const newState = { ...state };
                        const target = newState[this.name]
                            ? JSON.parse(JSON.stringify(newState[this.name]))
                            : {};
                        let counterStack = newState[`${this.name}Counter`] || 0;

                        counterStack += 1;

                        this.items.forEach((item) => {
                            target[item.id] = item.value;
                        });

                        this.items = [];

                        newState[this.name] = target;

                        newState[`${this.name}Counter`] = counterStack;

                        return newState;
                    },
                    () => {
                        // console.log(context.state[this.name], this.name);
                        resolve();
                    },
                );
            }, 1);
        });
    }
}
