import setServerData from '../../functions/setServerData';
import { store, dispatcher } from '../../redux/redux';
import getFormatPrice from '../../functions/getFormatPrice';
import getTariffs from '../../requests/getTariffs';
import removeTransition from '../../functions/removeTransition.ts';
import getCurrentPage from '../../functions/getCurrentPage';

const typesMap = [];

export default class OrderTariffs {
    constructor({ context }) {
        this.context = context;

        this.setTariffs = this.setTariffs.bind(this);
        this.init = this.init.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.handlerCategory = this.handlerCategory.bind(this);
        this.getTariffs = this.getTariffs.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
        this.getPercentOfCarIsField = this.getPercentOfCarIsField.bind(this);
        this.getTariffList = this.getTariffList.bind(this);
        this.getTariffInfo = this.getTariffInfo.bind(this);
        this.getFormatedValue = this.getFormatedValue.bind(this);
        this.getMinTime = this.getMinTime.bind(this);
        this.setMinTime = this.setMinTime.bind(this);
        this.setBodywork = this.setBodywork.bind(this);
        this.getBestTariff = this.getBestTariff.bind(this);
        this.setCounterWorker = this.setCounterWorker.bind(this);
        this.setMinCounterWorker = this.setMinCounterWorker.bind(this);
        this.getCurrentConfig = this.getCurrentConfig.bind(this);
        this.checkParamsOfItems = this.checkParamsOfItems.bind(this);
        this.getBodyworks = this.getBodyworks.bind(this);
        this.handlerNds = this.handlerNds.bind(this);
        this.getCurrentItem = this.getCurrentItem.bind(this);
        this.getCargo = this.getCargo.bind(this);
        this.filterCard = this.filterCard.bind(this);
    }

    namesForPhysicalCar = [0.75, 1, 2, 5];

    setTariffs() {
        return new Promise((resolve) => {
            const { user } = this.context.props;

            getTariffs({
                params: [{
                    key: 'holder',
                    value: user && user.type === 'juristic' ?
                        user.idOfCurrentCompany : 'admin-client',
                }, ],
            }).then(
                ({ tariff }) => {
                    let isNds = false;

                    isNds = !!tariff.nds;

                    const configurations = tariff.configurations
                        .filter(
                            (config) =>
                            config.isPersonal ||
                            !tariff.configurations.find(
                                (configInner) =>
                                configInner.name === config.name && configInner.isPersonal,
                            ),
                        )
                        .sort((a, b) => {
                            const condA = a.type === 'car' ? +a.name : 0;
                            const condB = b.type === 'car' ? +b.name : 0;

                            return condA - condB;
                        });
                    // .sort((a, b) => {
                    //     const condA = a.isPersonal === true ? 1 : 0;
                    //     const condB = b.isPersonal === true ? 1 : 0;

                    //     return condB - condA;
                    // });

                    let currentFirst;

                    removeTransition({ item: '.orderTariffs__contentCard' });

                    this.context.setState(
                        (state) => {
                            const newState = {...state };
                            const { currentTariffInOrder } = this.context.props;
                            const type = this.getCurrentType();

                            if (user) {
                                newState.isNds = isNds;

                                if (isNds) {
                                    newState.isNdsFix = true;
                                }
                            }

                            newState.tariffsItems = {
                                physical: configurations,
                                juristic: configurations,
                            };

                            ['physical', 'juristic'].forEach((name) => {
                                newState.tariffsItems[name].forEach((item, key) => {
                                    if (item.type === 'car') {
                                        const itemFirst = item.items[0];
                                        newState.tariffsItems[name][key].currentBodywork =
                                            itemFirst._id;
                                    }
                                });
                            });

                            currentFirst = configurations.find((item) => +item.name === 0.75)._id;

                            if (!currentFirst) {
                                currentFirst = configurations[0]._id;
                            }

                            const currentCategories = {
                                physical: 'car',
                                juristic: 'cars-less3',
                            };

                            if (currentTariffInOrder) {
                                const { id } = currentTariffInOrder;

                                currentFirst = id;
                                const currentConfigCome = configurations.find(
                                    (item) => item._id === id,
                                );

                                currentCategories[type] =
                                    type === 'physical' ?
                                    currentConfigCome.type :
                                    currentConfigCome.category;
                            }

                            newState.currentCategories = currentCategories;

                            newState.currentTariff = configurations[0].idOfParentTariff;
                            newState.isReadyTariffs = true;

                            return newState;
                        },
                        () => {
                            this.setCurrent(currentFirst);
                            resolve();
                        },
                    );
                },
                () => null,
            );
        });
    }

    filterCard({ type, card, isAllTariffs, currentCategory: currentCategoryRes }) {
        const { currentCategories } = this.context.state;

        if (currentCategories) {
            const currentCategory = currentCategoryRes || currentCategories[type];

            if (type === 'physical') {
                return (
                    (((this.namesForPhysicalCar.indexOf(+card.name) !== -1 &&
                                this.checkParamsOfItems(
                                    card,
                                    this.getCurrentItem(card._id, type).item,
                                    isAllTariffs,
                                )) ||
                            (card.type === 'worker' &&
                                this.checkParamsOfItems(
                                    card,
                                    this.getCurrentItem(card._id, type).item,
                                    isAllTariffs,
                                ))) &&
                        card.type === currentCategory) ||
                    isAllTariffs === true
                );
            }

            if (type === 'juristic') {
                return (
                    ((card.type === 'car' &&
                            this.checkParamsOfItems(
                                card,
                                this.getCurrentItem(card._id, type).item,
                                true,
                            )) ||
                        (card.type === 'worker' &&
                            this.checkParamsOfItems(
                                card,
                                this.getCurrentItem(card._id, type).item,
                                true,
                            ))) &&
                    (card.category === currentCategory || isAllTariffs === true)
                );
            }
        }

        return false;
    }

    saveType = null;

    getCurrentType() {
        const type = typesMap[getCurrentPage({ filter: (page) => page.group === 'order' })];

        if (type) {
            this.saveType = type;
        }

        return type || this.saveType;
    }

    getTariffs(typeRes = null, isAllTariffs = false) {
        const { tariffsItems } = this.context.state;
        let tariffs = [];
        let type = typeRes;
        if (typeRes === null) {
            type = this.getCurrentType();
        }

        if (tariffsItems) {
            tariffs = tariffsItems[type].filter((card) =>
                this.filterCard({ type, card, isAllTariffs }),
            );
        }

        return tariffs;
    }

    getCategories(type, isAll = false) {
        let tabs = [];
        const settings = store.getState().serverData.tariffs;

        const { tariffsItems, currentCategories } = this.context.state;
        const currentCategory = currentCategories && currentCategories[type];

        // console.log(tariffsItems);
        const filterCategories = (key) => {
            switch (type) {
                case 'physical':
                    return (
                        tariffsItems.physical
                        .filter((item) => item.type === key)
                        .filter(
                            (item) =>
                            (item.type === 'car' &&
                                this.namesForPhysicalCar.indexOf(+item.name) !== -1) ||
                            item.type !== 'car',
                        )
                        .filter(
                            (item) =>
                            isAll ||
                            this.checkParamsOfItems(
                                item,
                                this.getCurrentItem(item._id).item,
                                true,
                            ),
                        ).length !== 0
                    );
                case 'juristic':
                    return (
                        tariffsItems.juristic
                        .filter((item) => item.category === key)
                        .filter(
                            (item) =>
                            isAll ||
                            this.checkParamsOfItems(
                                item,
                                this.getCurrentItem(item._id).item,
                                true,
                            ),
                        ).length !== 0
                    );
                default:
                    return true;
            }
        };

        if (settings && tariffsItems) {
            switch (type) {
                case 'physical':
                    settings.categories.forEach((item) => {
                        if (!tabs.find((tab) => tab.key === item.type)) {
                            tabs.push({
                                key: item.type,
                                content: item.parentContent,
                            });
                        }
                    });

                    tabs = tabs.filter((tab) => filterCategories(tab.key));
                    break;
                case 'juristic':
                    tabs = settings.categories.map((item) => ({
                        key: item.key,
                        content: item.content,
                    }));

                    tabs = tabs.filter((tab) => filterCategories(tab.key));
                    break;
                default:
                    break;
            }

            if (
                currentCategory &&
                filterCategories(currentCategory) === false &&
                tabs.length !== 0
            ) {
                this.handlerCategory({ type, value: tabs[0].key });
            }
        }

        // console.log(tabs);

        return tabs;
    }

    handlerCategory({ type, value }) {
        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = {...state };
                    const currentCategories = {...newState.currentCategories };
                    currentCategories[type] = value;

                    newState.currentCategories = currentCategories;

                    return newState;
                },
                () => {
                    if (this.getTariffs(type).length) {
                        this.setCurrent(this.getTariffs(type)[0]._id);
                    } else {
                        this.setCurrent(null);
                    }
                    resolve();
                },
            );
        });
    }

    setMinTime(value = null, afterChangeRoute = false) {
        const { currentConfig, counterTime } = this.context.state;

        let minTime = this.getMinTime(currentConfig);
        if (value !== null && value >= minTime) {
            minTime = value;
        }
        if (afterChangeRoute === true && counterTime > minTime) {
            minTime = counterTime;
            // minTime = minTime;
        }

        this.context.setState((state) => {
            const newState = {...state };

            newState.counterTime = minTime;

            return newState;
        });
    }

    getMinTime(id = this.context.state.currentConfig) {
        let minTime = 0;
        const { isNds } = this.context.state;

        if (id) {
            const { item: currentConfigItem } = this.getCurrentConfig(id);
            if (Object.keys(currentConfigItem).length) {
                minTime = this.getFormatedValue({
                        name: 'minTime',
                        value: this.getCurrentItem(currentConfigItem._id).item.parameters.minTime,
                    },
                    isNds,
                );
                const infoRoute = {...this.context.state.infoRoute };
                const { duration } = infoRoute;

                if (this.getFormatedValue({ name: 'minTime', value: duration, isNds }) > minTime) {
                    minTime = this.getFormatedValue({ name: 'minTime', value: duration, isNds });
                }
            }
        }

        // console.log(duration);

        return minTime;
    }

    setCurrent(id, items = null, isChangeType) {
        const tariffsItems = JSON.parse(JSON.stringify(this.context.state.tariffsItems));
        const idWas = this.context.state.currentConfig;
        const { currentTariffInOrder } = this.context.props;
        const type = this.getCurrentType();
        const { item: currentConfig, indexConfig } = this.getCurrentConfig(id);
        const { item: currentItem } = this.getCurrentItem(id);

        if (idWas !== id || isChangeType) {
            this.context.setState(
                (state) => {
                    const newState = {...state };

                    if (
                        (items &&
                            (items.find((item) => item._id !== newState.currentConfig) ||
                                items.length === 0)) ||
                        items === null
                    ) {
                        newState.currentConfig = id;
                        newState.currentBodywork = (currentItem && currentItem._id) || null;
                        if (!newState.currentBodyworks) {
                            newState.currentBodyworks = {};
                        }

                        newState.currentBodyworks[type] = (currentItem && currentItem._id) || null;

                        if (isChangeType) {
                            if (!this.checkParamsOfItems(currentConfig, currentItem)) {
                                newState.currentBodywork = currentConfig.items[0]._id;
                                newState.currentBodyworks[type] = currentConfig.items[0]._id;
                                tariffsItems[type][indexConfig].currentBodywork =
                                    currentConfig.items[0]._id;
                            }
                        }

                        newState.currentTariff = currentConfig.idOfParentTariff;

                        if (currentTariffInOrder) {
                            const { currentBodywork } = currentTariffInOrder;

                            const comingItemIndex = tariffsItems[type].findIndex(
                                (item) =>
                                item._id === id &&
                                item.items.find((el) => el.key === currentBodywork),
                            );

                            if (comingItemIndex !== -1) {
                                const idOfBodywork = tariffsItems[type][comingItemIndex].items.find(
                                    (el) => el.key === currentBodywork,
                                )._id;

                                if (
                                    currentBodywork &&
                                    type === 'juristic' &&
                                    this.getCurrentConfig(id).item.type === 'car'
                                ) {
                                    newState.currentBodywork = idOfBodywork;
                                    newState.currentBodyworks[type] = idOfBodywork;

                                    tariffsItems[type][comingItemIndex].currentBodywork =
                                        idOfBodywork;
                                }
                            }
                        }

                        newState.tariffsItems = tariffsItems;
                    }

                    return newState;
                },
                () => {
                    if (id !== null || isChangeType) {
                        this.setMinTime(id);
                        this.setMinCounterWorker();
                        this.checkParamsOfItems();
                    }
                    if (this.context.handlerServices) {
                        this.context.handlerServices.updateServices(['passengers']);
                    }
                    if (currentTariffInOrder) {
                        dispatcher({ type: 'currentTariffInOrder', data: null });
                    }
                },
            );
        }
    }

    setCounterWorker(counter = null) {
        this.context.setState((state) => {
            const newState = {...state };

            newState.counterWorker = counter;

            return newState;
        });
    }

    setMinCounterWorker(counter = null) {
        const { item: currentConfigItem } = this.getCurrentConfig();

        this.context.setState(
            (state) => {
                const newState = {...state };

                if (counter !== null) {
                    newState.minCounterWorker = counter;
                } else if (currentConfigItem.type === 'worker') {
                    newState.minCounterWorker = 1;
                } else {
                    newState.minCounterWorker = 0;
                }

                return newState;
            },
            () => {
                if (
                    this.context.state.minCounterWorker > this.context.state.counterWorker ||
                    counter === null
                ) {
                    this.setCounterWorker(this.context.state.minCounterWorker);
                }
            },
        );
    }

    setBodywork(id, name) {
        const { item: currentConfigItem, index } = this.getCurrentConfig(id);
        const type = this.getCurrentType();

        if (currentConfigItem.currentBodywork !== name) {
            this.context.setState(
                (state) => {
                    const newState = {...state };

                    const tariffsItems = JSON.parse(JSON.stringify(newState.tariffsItems));

                    tariffsItems[type][index].currentBodywork = name;
                    newState.currentBodywork = name;
                    newState.currentBodyworks[type] = name;

                    newState.tariffsItems = tariffsItems;

                    return newState;
                },
                () => {
                    this.setMinTime();
                },
            );
        } else {
            this.setMinTime();
        }
    }

    filterBodywork({ config, item }) {
        return this.checkParamsOfItems(config, item) === true;
    }

    getBodyworks(config, isAll = false) {
        const bodyworks = [];

        config.items.forEach((item) => {
            if (this.filterBodywork({ config, item }) || isAll) {
                const { _id, key } = item;
                bodyworks.push({ _id, key });
            }
        });

        if (!isAll &&
            bodyworks.length &&
            !bodyworks.find((item) => item._id === config.currentBodywork)
        ) {
            this.setBodywork(config._id, bodyworks[0]._id);
        }

        return bodyworks;
    }

    getCurrentConfig(id = this.context.state.currentConfig, type = this.getCurrentType()) {
        const tariffsItems =
            (this.context.state.tariffsItems && [...this.context.state.tariffsItems[type]]) || [];

        const currentConfigIndex = tariffsItems.findIndex((item) => item._id === id);

        return {
            item: currentConfigIndex !== -1 ? tariffsItems[currentConfigIndex] : {},
            index: currentConfigIndex,
        };
    }

    getCurrentItem(id, type) {
        const { item: currentConfigItem } = this.getCurrentConfig(id, type);
        const { currentBodywork } = currentConfigItem;

        let index =
            currentConfigItem.items &&
            currentConfigItem.items.findIndex((item) => item._id === currentBodywork);

        if (index === -1) {
            index = 0;
        }

        return { item: (currentConfigItem.items && currentConfigItem.items[index]) || null, index };
    }

    getBestTariff(cargo, itemsPallets, withPallets) {
        const type = this.getCurrentType();
        let tariffsItems = [...this.context.state.tariffsItems[type]];
        tariffsItems = tariffsItems
            .filter((item) => item.type === 'car')
            .filter(
                (item) =>
                (type === 'physical' && this.namesForPhysicalCar.indexOf(+item.name) !== -1) ||
                type === 'juristic',
            )
            .filter(
                (item) =>
                this.getPercentOfCarIsField({
                    ...this.getCurrentItem(item._id).item.parameters,
                    ... { cargo, itemsPallets, withPallets },
                }) <= 100 &&
                this.checkMaxValueOfItem({
                    parameters: this.getCurrentItem(item._id).item.parameters,
                    ... { cargo, itemsPallets, withPallets },
                }),
            );

        const resultItems = tariffsItems
            .map((item) => ({
                id: item._id,
                percent: this.getPercentOfCarIsField({
                    ...this.getCurrentItem(item._id).item.parameters,
                    ... { cargo, itemsPallets, withPallets },
                }),
            }))
            .sort((a, b) => a - b);

        return resultItems[0] && resultItems[0].id;
    }

    getCargo(points = this.context.state.points) {
        return points?.reduce(
            (prevPoint, curPoint) =>
            prevPoint.concat(
                ...curPoint.cargo.reduce(
                    (prevCargo, curCargo) => prevCargo.concat(...curCargo.items), [],
                ),
            ), [],
        );
    }

    checkParamsOfItems(config = null, item = null, isCheckAll = false) {
        const { currentConfig } = this.context.state;
        const cargo = this.getCargo();
        const maxWeightOfItem =
            cargo.length === 0 ? 0 : Math.max(...cargo.map((itemLoop) => itemLoop.weight));
        const currentConfigItem = config || this.getCurrentConfig().item;
        const currentItem = item || this.getCurrentItem(currentConfig).item;
        let cond = true;

        const checkAllItems = () => {
            let condCheck = false;

            currentConfigItem.items.forEach((itemLoop) => {
                condCheck = condCheck || this.checkParamsOfItems(currentConfigItem, itemLoop);
            });

            // console.log(condCheck);

            return condCheck;
        };

        switch (currentConfigItem.type) {
            case 'worker':
                {
                    const { maxWeight } = currentItem.parameters;
                    const counterWorker = Math.ceil(maxWeightOfItem / maxWeight) || 1;
                    if (isCheckAll === false) {
                        if (counterWorker <= 4) {
                            // console.log(counterWorker);
                            if (counterWorker !== this.context.state.minCounterWorker) {
                                this.setMinCounterWorker(counterWorker);
                            }
                        } else {
                            cond = false;
                        }
                    } else {
                        cond = checkAllItems();
                    }
                }
                break;
            case 'car':
                if (isCheckAll === false) {
                    if (
                        this.getPercentOfCarIsField({
                            ...currentItem.parameters,
                            ... { cargo },
                        }) > 100
                    ) {
                        cond = false;
                    }

                    if (
                        this.checkMaxValueOfItem({
                            parameters: currentItem.parameters,
                            ... { cargo },
                        }) === false
                    ) {
                        cond = false;
                    }
                } else {
                    cond = checkAllItems();
                }
                break;
            default:
                break;
        }

        return cond;
    }

    getPercentOfCarIsField(info) {
        const { long, width, height, itemsPallets, withPallets } = info;
        const cargo = this.getCargo();
        // console.log(info);
        const items =
            this.context.props.type === 'juristic' && withPallets === true ? itemsPallets : cargo;
        let value;
        let valueOfItems;

        if (this.context.props.type === 'juristic' && withPallets === true) {
            value = long * width;
            valueOfItems = items.reduce(
                (sum, item) => sum + item.long * item.width * item.counter,
                0,
            );
        } else {
            value = long * width * height;
            valueOfItems =
                (items &&
                    items.reduce(
                        (sum, item) => sum + item.long * item.width * item.height * item.counter,
                        0,
                    )) ||
                0;
        }

        return parseInt((valueOfItems / value) * 100, 10);
    }

    checkMaxValueOfItem(info) {
        const { parameters } = info;
        const cargo = this.getCargo();
        const items = cargo;
        const params = ['long', 'width', 'height'];
        let cond = true;

        const maxInCur = Object.entries(parameters)
            .filter((item) => params.indexOf(item[0]) !== -1)
            .map((item) => item[1])
            .sort((a, b) => b - a);

        items.forEach((item) => {
            const maxInItem = Object.entries(item)
                .filter((itemInner) => params.indexOf(itemInner[0]) !== -1)
                .map((itemInner) => itemInner[1])
                .sort((a, b) => b - a);

            // eslint-disable-next-line
            for (let i = 0; i < params.length; i++) {
                cond = cond && maxInCur[i] >= maxInItem[i];
            }
        });

        return cond;
    }

    getTariffList(data) {
        const { type, typeOfTariff, info } = data;
        const list = [];
        let props = [];
        const formatValue = (val, name) => {
            if (['long', 'width', 'height'].indexOf(name) !== -1) {
                return +(val / 100).toFixed(1);
            }
            if (name === 'volume') {
                return +((info.long * info.width * info.height) / 1000000).toFixed(1);
            }
            if (name === 'size') {
                return `${+(info.long / 100).toFixed(1)}x${+(info.width / 100).toFixed(1)}x${+(
                    info.height / 100
                ).toFixed(1)}`;
            }
            return val;
        };

        switch (typeOfTariff) {
            case 'car':
                switch (type) {
                    case 'physical':
                        props = ['long', 'width', 'height'];
                        break;
                    case 'juristic':
                        props = ['volume', 'size', 'pallets', 'minTime', 'minPrice'];
                        break;
                    default:
                        break;
                }
                break;
            case 'worker':
                props = ['maxWeight', 'minTime'];
                break;
            default:
                break;
        }
        props.forEach((name) => {
            list.push({ name, value: formatValue(info[name], name) });
        });

        return list;
    }

    getTariffInfo({ type, typeOfTariff, parameters }) {
        const info = [];

        switch (typeOfTariff) {
            case 'car':
                switch (type) {
                    case 'physical':
                        ['counter', 'minTime', 'minPrice', 'priceOfMinute', 'priceOfMkad'].forEach(
                            (name) => {
                                info.push({
                                    name,
                                    value: parameters[name],
                                });
                            },
                        );
                        break;
                    case 'juristic':
                        ['priceOfMinute', 'priceOfMkad', 'passengers', 'counter'].forEach(
                            (name) => {
                                info.push({
                                    name,
                                    value: parameters[name],
                                });
                            },
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'worker':
                ['counter', 'minPrice', 'priceOfMinute'].forEach((name) => {
                    info.push({
                        name,
                        value: parameters[name],
                    });
                });
                break;
            default:
                break;
        }

        return info;
    }

    handlerNds(value) {
        if (!this.context.state.isNdsFix) {
            this.context.setState((state) => {
                const newState = {...state };

                newState.isNds = value;

                return newState;
            });
        }
    }

    getFormatedValue(data) {
        const { name, isNds, type } = data;
        let { value } = data;
        const valueNds = isNds === true && type === 'juristic' ? 1.2 : 1;

        if (['minTime'].indexOf(name) !== -1) {
            return Math.ceil(value / 60);
        }

        if (['priceOfMinute'].indexOf(name) !== -1) {
            value *= 60;
        }

        if (['minPrice', 'priceOfMinute', 'priceOfMkad'].indexOf(name) !== -1) {
            return getFormatPrice(value * valueNds);
        }

        return value;
    }

    init() {
        return new Promise((resolve) => {
            setServerData('tariffs').then(
                () => {
                    this.setTariffs().then(
                        () => {
                            this.context.setState((state) => {
                                const newState = {...state };

                                newState.counterTime = 1;
                                newState.counterWorker = 0;
                                newState.minCounterWorker = 0;
                                newState.isNds = false;
                                newState.currentBodyworks = {
                                    physical: null,
                                    juristic: null,
                                };

                                return newState;
                            }, resolve);
                        },
                        () => null,
                    );
                },
                () => null,
            );
        });
    }
}