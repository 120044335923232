export default function checkCrewComplete({ crew, crewTemplate }) {
    if (crew && crewTemplate) {
        const counterExecutors = {};

        crewTemplate.forEach((type) => {
            if (!counterExecutors[type]) {
                counterExecutors[type] = 0;
            }

            counterExecutors[type] += 1;
        });

        return Object.keys(counterExecutors).every(
            (type) =>
                crew.filter((executor) => executor.role === type).length === counterExecutors[type],
        );
    }

    return false;
}
