import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../functions/setServerData';

import StatusPoint from '../../order/StatusPoint.jsx';
import Button from '../../Button.jsx';
import Animate from '../../Animate.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class StatusesOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);
    }

    handlerStatus(statusCurrent) {
        this.setState({
            statusCurrent: this.state.statusCurrent === statusCurrent ? null : statusCurrent,
        });
    }

    handlerLoading(isLoading) {
        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    save() {
        const { statusCurrent } = this.state;
        const { callback } = this.props;

        this.handlerLoading(true).then(() => {
            callback({ status: statusCurrent }).then(
                () => {
                    this.handlerLoading(false).then(() => {
                        handlerWindow({
                            action: 'hide',
                            name: 'statusesOrder',
                        });
                    });
                },
                () => {
                    this.handlerLoading(false);
                },
            );
        });
    }

    componentDidMount() {
        setServerData('pointStatuses');
    }

    render() {
        const { statusCurrent, isLoading } = this.state;
        const { serverData, statusInit } = this.props;
        const { pointStatuses } = serverData;

        return (
            <div className={`statusesOrder _windowWithCursor _centerLeft ${statusCurrent ? '_active' : ''}`}>
                <div className="statusesOrder__inner">
                    <div className="statusesOrder__items">
                        {pointStatuses &&
                            Object.keys(pointStatuses)
                                .filter((status) => status !== statusInit)
                                .map((status) => (
                                    <div
                                        className="statusesOrder__item"
                                        key={status}
                                        onClick={() => this.handlerStatus(status)}
                                    >
                                        <StatusPoint
                                            status={status}
                                            isActive={statusCurrent === status}
                                        />
                                    </div>
                                ))}
                    </div>
                    <Animate className="statusesOrder__button" isShow={statusCurrent}>
                        <div className="statusesOrder__buttonInner">
                            <Button
                                className="_mediumSize _mainNotBorder"
                                onClick={this.save}
                                showLoader={isLoading}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(StatusesOrder);

StatusesOrder.propTypes = {
    serverData: PropTypes.object,
    status: PropTypes.string,
    statusInit: PropTypes.string,
    callback: PropTypes.func,
};
