import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import pages from '../../../redux/pages';
import Link from '../../Link.jsx';

import getCurrentPage from '../../../functions/getCurrentPage';

class PublicMobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getNav() {
        return pages.filter((page) => page.parentName === 'public');
    }

    render() {
        const { heightWindow, storePages, handlerMenu } = this.props;
        const nav = this.getNav();
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.parentName === 'public',
        });

        return (
            <menu
                ref={this.parent}
                className="publicMobileMenu"
                style={{ height: `${heightWindow}px` }}
            >
                <div
                    className="publicMobileMenu__inner _col"
                    style={{ minHeight: `${heightWindow}px` }}
                >
                    <div className="publicMobileMenu__head _row">
                        <img
                            src={require('../../../img/crm/logo.svg').default}
                            alt=""
                            className="publicMobileMenu__logo"
                        />
                        <i
                            className="publicMobileMenu__close"
                            onClick={() => {
                                handlerMenu(false);
                            }}
                        >
                            <Icon name="public-close" />
                        </i>
                    </div>
                    <div className="publicMobileMenu__nav">
                        {nav.map((item) => (
                            <div
                                className={`publicMobileMenu__navItem _col ${
                                    currentPage === item.name ? '_current' : ''
                                }`}
                                key={item.name}
                            >
                                <Link
                                    className="publicMobileMenu__navLink"
                                    pageName={item.name}
                                    changeIsHard={true}
                                    callback={() => {
                                        handlerMenu(false);
                                    }}
                                >
                                    {item.contentOfLink}
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="publicMobileMenu__copy">
                        livecargo CRM {new Date().getUTCFullYear()} ©
                    </div>
                </div>
            </menu>
        );
    }
}

function mapStateToProps(state) {
    return {
        storePages: state.pages,
        device: state.device,
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(PublicMobileMenu);

PublicMobileMenu.propTypes = {
    storePages: PropTypes.object,
    device: PropTypes.string,
    heightWindow: PropTypes.number,
    handlerMenu: PropTypes.func,
};
