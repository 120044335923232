import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Orders from '../../components/crm/Orders.jsx';

class OrdersParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <Orders type="main" />;
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrdersParent);

OrdersParent.propTypes = {
    theme: PropTypes.string,
};
