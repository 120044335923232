import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFile from '../../../classes/File';

import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Field from '../manual/card/Field.jsx';
import getFormatedDate from '../../../functions/getFormatedDate';

import getHeaders from '../../../functions/getHeaders';
import handlerPopup from '../../../functions/app/handlerPopup';
import setNotification from '../../../functions/setNotification';
import Calendar from '../../Calendar.jsx';
import getExecutorMvds from '../../../requests/getExecutorMvds';
import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import FileComponent from '../../chat/File.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import checkValueOfEmpty from '../../../functions/checkValueOfEmpty';

class MvdPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            deletesFiles: [],
        };

        this.handlerField = this.handlerField.bind(this);
        this.renderDatesItem = this.renderDatesItem.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerDate = this.handlerDate.bind(this);
        this.saveDate = this.saveDate.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['number', 'status', 'date', 'comment'];

    fields = {
        number: {
            keyName: 'docNumber',
        },
        status: {
            keyName: 'status',
            items: [
                {
                    key: 'notsent',
                    content: 'Не подано',
                },
                {
                    key: 'sent',
                    content: 'Подано',
                },
                {
                    key: 'complete',
                    content: 'Получено',
                },
                {
                    key: 'inactive',
                    content: 'Не актуально',
                },
                {
                    key: 'reject',
                    content: 'Отказ',
                },
            ],
        },
        date: {
            keyName: 'date',
        },
        comment: {
            keyName: 'anyText',
            support: 'Введите текст здесь',
        },
    };

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const executor = JSON.parse(JSON.stringify(newState.executor));

                        executor[name] = value;

                        newState.executor = executor;

                        return newState;
                    },
                    () => {
                        this.handlerErrors({ action: 'remove', errors: [name] });

                        resolve();
                    },
                );
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getDatesItems() {
        const { dateKey } = this.state;

        return dateKey ? [{ key: dateKey }] : [];
    }

    dates = {};

    handlerDate(type, { currentDate, currentDateDouble }) {
        if (!this.dates[type]) {
            this.dates[type] = {};
        }

        if (currentDate) {
            this.dates[type].currentDate = currentDate;
        }

        if (currentDateDouble) {
            this.dates[type].currentDateDouble = currentDateDouble;
        }
    }

    saveDate(type) {
        if (this.dates[type]?.currentDate) {
            this.handlerField({
                action: 'change',
                name: 'date',
                value: getFormatedDate({ date: new Date(this.dates[type].currentDate) }),
            }).then(() => {
                this.handlerDateState({ dateKey: null });
            });
        }
    }

    renderDatesItem({ prop: key }) {
        return (
            <div className="payPopup__calendar _col">
                <div className="payPopup__calendarInner">
                    <div className="payPopup__calendarBox _col">
                        <Calendar
                            className="_pay"
                            withPast={true}
                            withFuture={false}
                            isDouble={key === 'period'}
                            isAllDates={true}
                            handlerDate={this.handlerDate.bind(this, key)}
                            startDate={this.dates[key]?.currentDate}
                        />
                        <div className="payPopup__calendarButton">
                            <Button
                                className="_medium2Size"
                                onClick={this.saveDate.bind(this, key)}
                            >
                                Сохранить дату
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handlerErrors({ errors, action }) {
        this.setState((state) => {
            const newState = { ...state };
            const stateErrors = [...(newState.errors || [])];

            errors.forEach((error) => {
                const errorIndex = stateErrors.indexOf(error);

                if (action && errorIndex !== -1) {
                    stateErrors.splice(errorIndex, 1);
                }

                if (!action && errorIndex === -1) {
                    stateErrors.push(error);
                }
            });

            newState.errors = stateErrors;

            return newState;
        });
    }

    save() {
        const { executor, deletesFiles } = this.state;
        const { mvdPopup } = this.props;
        const errors = [];
        const fields = {};

        this.fieldsOrder.forEach((name) => {
            if (checkValueOfEmpty(executor[name], true)) {
                if (name === 'date') {
                    if (executor.date) {
                        const [day, month, year] = executor.date.split('.');
                        const dateObject = new Date();

                        dateObject.setUTCFullYear(+year);
                        dateObject.setUTCMonth(+month - 1);
                        dateObject.setUTCDate(+day);
                        dateObject.setUTCHours(0, 0, 0, 0);

                        fields.date = dateObject.toGMTString();
                    }
                } else {
                    fields[name] = executor[name];
                }
            } else if (name !== 'comment') {
                errors.push(name);
            }
        });

        if (errors.length) {
            this.handlerErrors({ errors });

            setNotification({ notification: 'required-fields-not-complete' });
        } else {
            this.formData.set('id', executor._id);
            this.formData.set('fields', JSON.stringify(fields));
            this.formData.set('deletesFiles', JSON.stringify(deletesFiles));

            this.handlerLoading('save').then(() => {
                axios
                    .patch(`${process.env.REACT_APP_API}/executor-mvd`, this.formData, {
                        headers: getHeaders(),
                    })
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                handlerPopup({
                                    name: 'mvdPopup',
                                    isShow: false,
                                });

                                if (typeof mvdPopup.callback === 'function') {
                                    mvdPopup.callback();
                                }

                                setNotification({ notification: 'success-change-info' });
                            } else {
                                const { message } = data;

                                if (message === 'Date is incorrect') {
                                    this.handlerErrors({ errors: ['date'] });
                                }
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        }
    }

    handlerDateState({ dateKey }) {
        this.setState({ dateKey }, () => {
            if (!dateKey) {
                // this.currentDate = null;
                // this.currentDateDouble = null;
            }
        });
    }

    getValue({ name }) {
        const { executor } = this.state;

        if (!executor) {
            return null;
        }

        // if (name === 'date' && executor.date) {
        //     return getFormatedDate({ date: new Date(executor.date) });
        // }

        return executor[name];
    }

    handlerMissClick(e) {
        const { dateKey } = this.state;

        if (dateKey) {
            const calendar = this.parent.current.querySelector('.payPopup__calendarInner');

            if (calendar && e.target !== calendar && !calendar.contains(e.target)) {
                this.handlerDateState({ dateKey: null });
            }
        }
    }

    getFiles() {
        const { files } = this.state;

        return files;
    }

    formData = new FormData();

    deleteFile({ key }) {
        this.setState((state) => {
            const newState = { ...state };
            const files = [...newState.files];
            const deletesFiles = [...newState.deletesFiles];

            const fileIndex = files.findIndex((item) => item.key === key);

            if (fileIndex !== -1) {
                if (!files[fileIndex].isLocal) {
                    deletesFiles.push(key);
                }

                files.splice(fileIndex, 1);

                this.formData.delete(key);
            }

            newState.files = files;
            newState.deletesFiles = deletesFiles;

            return newState;
        });
    }

    renderFile({ item: file }) {
        return (
            <div className="payPopup__filesItem">
                <FileComponent
                    file={file}
                    className="_default"
                    deleteFile={this.deleteFile}
                    withOpen={true}
                />
            </div>
        );
    }

    uploadFile({ target }) {
        this.handlerFile
            .uploadFiles({
                target,
                formData: this.formData,
                getName: (key) => `${key}-${new Date().getTime()}`,
            })
            .then(({ resultFiles }) => {
                if (resultFiles) {
                    this.setState((state) => {
                        const newState = { ...state };
                        const files = [...newState.files];

                        files.push(
                            ...resultFiles.map((file) => ({
                                ...file,
                                keyFile: file.key,
                                type: this.handlerFile.checkImage({ type: file.type })
                                    ? 'image'
                                    : null,
                            })),
                        );

                        if (files.length > 3) {
                            setNotification({ notification: 'max-limit-counter-files-3' });
                        }

                        newState.files = files.filter((file, key) => key < 3);

                        return newState;
                    });
                }
            });
    }

    getExecutor() {
        const { mvdPopup } = this.props;
        const { executorId } = mvdPopup;

        getExecutorMvds({
            params: [{ key: 'id', value: executorId }],
        }).then(({ executorMvd }) => {
            const files =
                executorMvd.files?.map((file) => ({
                    ...file,
                    key: file._id,
                    keyFile: file._id,
                    type: this.handlerFile.checkImage({ type: file.type }) ? 'image' : null,
                    src: `${process.env.REACT_APP_STATIC}/executors/${file.path}`,
                })) || [];

            const executorDate = executorMvd.date;

            if (executorDate) {
                executorMvd.date = getFormatedDate({ date: new Date(executorDate) });
            }

            this.setState({ executor: executorMvd, files }, () => {
                if (executorDate) {
                    this.dates.date = {
                        currentDate: new Date(executorDate),
                    };
                }
            });
        });
    }

    componentDidMount() {
        this.getExecutor();

        this.handlerFile = new HandlerFile({ typeOfFiles: 'docs' });

        document.addEventListener('click', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { loadingKey, executor, files, errors = [] } = this.state;
        const isReady = !!executor;

        return (
            <div ref={this.parent} className="payPopup _col">
                <div className="payPopup__inner _col">
                    <Animate className="payPopup__loader _loader" isShow={!isReady}>
                        <div className="payPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <ListAbsoluteMain
                        className="payPopup__calendars"
                        items={this.getDatesItems()}
                        renderItem={this.renderDatesItem}
                        classNameItem="payPopup__calendar _col"
                        prop="key"
                        styles={[]}
                        isNotParamsItem={true}
                    />
                    <div className={`payPopup__innerBox _col ${isReady ? '_ready' : ''}`}>
                        <div className="payPopup__head">
                            <div className="payPopup__headTitle">Редактирование карточки: </div>
                            <div className="payPopup__headDescription">
                                {executor?.fullName || '–'}
                            </div>
                            <i
                                className="payPopup__headClose _click"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'mvdPopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                <Icon name="close-circle" />
                            </i>
                        </div>
                        <div className="payPopup__content">
                            <div className="payPopup__form">
                                {this.fieldsOrder
                                    .filter(
                                        (name) =>
                                            name !== 'status' ||
                                            !executor?.contractInactive ||
                                            executor?.type === 'reject',
                                    )
                                    .map((name) => {
                                        const value = this.getValue({ name });
                                        const isDisabled = false;
                                        const { support, items } = this.fields[name];

                                        const isError = errors.indexOf(name) !== -1;

                                        return (
                                            <div className="payPopup__formFields" key={name}>
                                                <div
                                                    className={`payPopup__formField _col ${
                                                        isError ? '_error' : ''
                                                    }`}
                                                    key={name}
                                                >
                                                    <Field
                                                        className="_heightSize"
                                                        type="mvdPopup"
                                                        value={value}
                                                        name={name}
                                                        keyName={this.fields[name].keyName}
                                                        isEditmode={false}
                                                        isError={isError}
                                                        group="mvdPopup"
                                                        isDisabled={isDisabled}
                                                        handler={this.handlerField}
                                                        tag={name === 'comment' ? 'area' : ''}
                                                        support={support}
                                                        items={items}
                                                        notItems={[
                                                            { key: 'notsent' },
                                                            { key: 'inactive' },
                                                        ]}
                                                    />
                                                    {name === 'date' && (
                                                        <div className="payPopup__formFieldDates">
                                                            <div
                                                                className="payPopup__formFieldDatesBtn _col _click"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();

                                                                    this.handlerDateState({
                                                                        dateKey: name,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="payPopup__formFieldDatesIcon">
                                                                    <Icon name="calendar-new" />
                                                                </i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <div className="payPopup__files _col">
                                    <p className="payPopup__filesSupport">
                                        Прикреплённые файлы{' '}
                                        <AnimateChangeUp
                                            className="payPopup__filesSupportItem"
                                            renderKey={files.length}
                                        >
                                            <>({files.length})</>
                                        </AnimateChangeUp>
                                    </p>
                                    <ListAbsoluteMain
                                        className="payPopup__filesItems"
                                        items={this.getFiles()}
                                        renderItem={this.renderFile}
                                        classNameItem="payPopup__filesItem"
                                        paramsParent={{ width: true }}
                                        prop="key"
                                        styles={['height']}
                                        // isNotParamsItem={true}
                                    />
                                    <label className="payPopup__filesButton _row">
                                        <input type="file" multiple onChange={this.uploadFile} />
                                        <i className="payPopup__filesButtonIcon">
                                            <Icon name="chat-attach" />
                                        </i>
                                        Прикрепить файл
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="payPopup__foot _row">
                            <div className="payPopup__footButton">
                                <Button
                                    className="_medium2Size"
                                    onClick={this.save}
                                    showLoader={loadingKey === 'save'}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mvdPopup: state.mvdPopup,
    };
}

export default connect(mapStateToProps)(MvdPopup);

MvdPopup.propTypes = {
    mvdPopup: PropTypes.object,
};
