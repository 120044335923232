import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getTariffs from '../../requests/getTariffs';

import HandlerFilterTariffs from '../../classes/Filter';

import TariffsCard from './TariffsCard.jsx';
import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Icon from '../Icon.jsx';
import Filter from '../Filter.jsx';
import scrollToPosition from '../../functions/scrollToPosition';
import removeTransition from '../../functions/removeTransition.ts';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import TariffsCustomCard from './TariffsCustomCard.jsx';

class DetailsTariffsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isInit: !!this.props.tariff,
        };

        this.filterItem = this.filterItem.bind(this);
        this.handlerStateFilter = this.handlerStateFilter.bind(this);
        this.sortItems = this.sortItems.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.getSortItems = this.getSortItems.bind(this);
        this.checkCurrent = this.checkCurrent.bind(this);
        this.initHandlerFilter = this.initHandlerFilter.bind(this);
        this.scrollTopList = this.scrollTopList.bind(this);

        this.parent = React.createRef();
    }

    minHeight = 280;

    setTariff(tariff) {
        const { tariffInfo } = this.props;

        return new Promise((resolve) => {
            this.setState(
                {
                    tariff: {
                        ...tariff,
                        configurations: tariff.configurations
                            .filter(
                                (config) =>
                                    !tariffInfo ||
                                    config._id === 'customConfig' ||
                                    config.type === tariffInfo.typeConfig,
                            )
                            .map((config) => ({
                                ...config,
                            })),
                    },
                },
                resolve,
            );
        });
    }

    getTariffs() {
        const { tariff } = this.props;

        if (tariff) {
            removeTransition({ item: '.orderDetailsTariffsList__inner' });
        }

        const getTariff = () =>
            new Promise((resolve) => {
                if (tariff) {
                    resolve(tariff);
                } else {
                    const { newHolder, type, holder } = this.props;
                    const getTariffsPromises =
                        type === 'juristic' && !newHolder
                            ? [
                                  getTariffs({
                                      params: [
                                          { key: 'holder', value: holder },
                                          { key: 'orderType', value: type },
                                      ],
                                  }),
                              ]
                            : [
                                  getTariffs({
                                      params: [
                                          { key: 'holder', value: 'admin-client' },
                                          { key: 'orderType', value: type },
                                      ],
                                  }),
                              ];

                    // if (type === 'juristic') {
                    //     getTariffsPromises.push(
                    //         getTariffs({ params: [{ key: 'holder', value: holder }] }),
                    //     );
                    // }

                    Promise.all(getTariffsPromises).then((tariffs) => {
                        const [{ tariff: tariffRes }] = tariffs;

                        resolve(tariffRes);
                    });
                }
            });

        return new Promise((resolve) => {
            getTariff().then(
                (tariffRes) => {
                    this.setTariff(tariffRes).then(() => {
                        this.getNameOfTariff();
                        this.initFilter();

                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    getNameOfTariff() {
        const { tariffData, tariff: tariffProps } = this.props;
        const { tariff: tariffState } = this.state;

        if (tariffProps) {
            const items = this.getItemsOrder();

            const currentItem = items.find(
                (itemLoop) => itemLoop._id === (tariffProps.idOfTariff || tariffData?.idOfItem),
            );

            if (currentItem) {
                const nameOfTariff = currentItem.shortName || currentItem.name || '–';

                if (nameOfTariff !== this.state.nameOfTariff) {
                    this.setState({
                        nameOfTariff,
                    });
                }
            }
        } else if (tariffState) {
            const items = this.getItemsOrder(true);
            const currentItem = items.find((itemLoop) => itemLoop._id === tariffData?.idOfItem);

            if (currentItem) {
                const nameOfTariff = currentItem.shortName || currentItem.name || '–';

                if (nameOfTariff !== this.state.nameOfTariff) {
                    this.setState({
                        nameOfTariff,
                    });
                }
            }
        }
    }

    checkCurrent(item) {
        const { tariffData, tariff: tariffProps } = this.props;

        return tariffProps
            ? (tariffProps.idOfTariff || tariffData?.idOfItem) === item._id
            : tariffData?.idOfItem === item._id;
    }

    filterItem(item) {
        const { filter } = this.state;

        let cond = true;

        if (filter) {
            filter.forEach((block) => {
                if (block.choices.length) {
                    if (block.key === 'tariffsCategories') {
                        cond =
                            cond && block.choices.find((choice) => choice === item.config.category);
                    }
                    if (block.key === 'typeBodyworks') {
                        cond = cond && block.choices.find((choice) => choice === item.key);
                    }
                }
            });

            if (this.checkCurrent(item)) {
                cond = true;
            }
        }

        return cond;
    }

    handlerStateFilter(isShowFilter = !this.state.isShowFilter) {
        this.setState({ isShowFilter });
    }

    initFilter() {
        const { tariff } = this.state;

        if (tariff) {
            const blocks = [
                {
                    type: 'checkbox',
                    key: 'tariffsCategories',
                    isShowInit: true,
                    items: [],
                },
                {
                    type: 'checkbox',
                    key: 'typeBodyworks',
                    isShowInit: true,
                    items: [],
                },
            ];
            const blockCategories = blocks.find((block) => block.key === 'tariffsCategories');
            const blockType = blocks.find((block) => block.key === 'typeBodyworks');
            const tariffItems = this.getItemsOrder();

            tariff.configurations.forEach((config) => {
                if (
                    !blockCategories.items.find((elem) => elem.id === config.category) &&
                    config.category
                ) {
                    blockCategories.items.push({
                        id: config.category,
                    });
                }
            });

            tariffItems.forEach((item) => {
                if (item.key && !blockType.items.find((elem) => elem.id === item.key)) {
                    blockType.items.push({
                        id: item.key,
                    });
                }
            });

            this.handlerFilter.init({ blocks });
        }
    }

    getItems() {
        const { tariff } = this.state;

        return (
            tariff?.configurations.reduce(
                (prevConfigs, curConfig) =>
                    prevConfigs.concat(
                        ...curConfig.items.map((item) => ({
                            ...item,
                            id: item._id,
                            config: {
                                category: curConfig.category,
                                name: curConfig.name,
                                type: curConfig.type,
                                _id: curConfig._id,
                            },
                            configurationWorker: tariff.configurationWorker,
                        })),
                    ),
                [],
            ) || []
        );
    }

    sortItems(tariffA, tariffB) {
        const weightA = this.checkCurrent(tariffA) ? 1 : 0;
        const weightB = this.checkCurrent(tariffB) ? 1 : 0;

        return weightB - weightA;
    }

    getSortItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    scrollTopList() {
        const parentScroll = this.parent.current.querySelector('.orderDetailsTariffsList__inner');

        scrollToPosition({ position: 'top', parent: parentScroll });
    }

    renderItem({ item: itemCome, prop: id }) {
        const { type, changeTariff, isNds, tariffData, tariff } = this.props;
        const items = this.getItemsOrder();
        const item = items.find((itemLoop) => itemLoop._id === id) || itemCome;

        const isCurrent = this.checkCurrent(item);
        const resultTariff = tariffData || tariff;
        const customPrice = (tariffData || tariff)?.customPrice;

        return (
            <div className="orderDetailsTariffsList__item">
                {id === 'custom' ? (
                    <TariffsCustomCard
                        key={id}
                        isCustom={id === 'custom'}
                        tariff={item}
                        type={type}
                        isCurrent={isCurrent}
                        changeTariff={changeTariff}
                        isNds={isNds}
                        scrollTopList={this.scrollTopList}
                        price={customPrice}
                        resultTariff={resultTariff}
                    />
                ) : (
                    <TariffsCard
                        key={id}
                        tariff={item}
                        type={type}
                        isCurrent={isCurrent}
                        changeTariff={changeTariff}
                        isNds={isNds}
                        scrollTopList={this.scrollTopList}
                    />
                )}
            </div>
        );
    }

    getItemsOrder(noFilter = false) {
        const items = this.getItems();

        const itemsFilter = items.filter((item) => noFilter || this.filterItem(item));

        return this.getSortItems(itemsFilter);
    }

    initHandlerFilter({ callback }) {
        this.handlerFilter.callback = (data) => {
            if (data && data.isChangeItems) {
                this.scrollTopList();
            }

            callback();
        };
    }

    handlerFilter = new HandlerFilterTariffs({ context: this });

    saveKeys = {};

    componentDidMount() {
        this.getTariffs();
    }

    componentDidUpdate() {
        this.getNameOfTariff();
    }

    render() {
        const { nameOfTariff, filter, isShowFilter, heightItems = 0, isInit } = this.state;
        const { className = '' } = this.props;
        const items = this.getItems();
        const itemsSort = this.getItemsOrder();
        const currentId = items.find(this.checkCurrent)?._id;
        const counterFilter = filter?.filter(this.handlerFilter.checkEmpty).length || 0;
        const heightInner = heightItems > this.minHeight || !isInit ? this.minHeight : heightItems;

        if (counterFilter) {
            this.filterCounterSave = counterFilter;
        }

        return (
            <div
                ref={this.parent}
                className={`orderDetailsTariffsList ${isInit ? '_init' : ''} _windowWithCursor ${className}`}
            >
                <div className="orderDetailsTariffsList__head _row">
                    <div className="orderDetailsTariffsList__name _row">
                        Тариф:{' '}
                        <AnimateChange
                            className="orderDetailsTariffsList__nameItem"
                            classNameParent="_parent"
                            prop={nameOfTariff}
                            type="_translateMedium"
                        >
                            {nameOfTariff || '–'}
                        </AnimateChange>
                    </div>
                    <Animate
                        className={`orderDetailsTariffsList__filter ${
                            isShowFilter ? '_active' : ''
                        }`}
                        isShow={filter && filter.length}
                    >
                        <div
                            className="orderDetailsTariffsList__filterButton _row _click"
                            onClick={() => this.handlerStateFilter()}
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            <Animate
                                className="orderDetailsTariffsList__filterCounter"
                                isShow={counterFilter > 0}
                            >
                                <AnimateChange
                                    className="orderDetailsTariffsList__filterCounterInner _col"
                                    prop={counterFilter || this.filterCounterSave}
                                    type="_translateMedium"
                                >
                                    {`${counterFilter || this.filterCounterSave}`}
                                </AnimateChange>
                            </Animate>
                            Фильтры
                            <i className="orderDetailsTariffsList__filterButtonIcon">
                                <Icon name="filter" />
                            </i>
                        </div>
                        <Animate
                            className="orderDetailsTariffsList__filterWindow"
                            isShow={isShowFilter}
                        >
                            <Filter
                                filter={filter}
                                handlerFilter={this.handlerFilter}
                                hideFilter={() => this.handlerStateFilter(false)}
                                inMoment={true}
                                initHandlerFilter={this.initHandlerFilter}
                            />
                        </Animate>
                    </Animate>
                </div>
                <div
                    className="orderDetailsTariffsList__inner"
                    style={{ height: `${heightInner}px` }}
                >
                    <Animate className="orderDetailsTariffsList__loader _loader" isShow={!isInit}>
                        <div className="orderDetailsTariffsList__loaderIcon _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>

                    <ListAbsoluteMain
                        className="orderDetailsTariffsList__items"
                        items={itemsSort}
                        renderItem={this.renderItem}
                        classNameItem="orderDetailsTariffsList__item"
                        prop="id"
                        paramsParent={{ width: true }}
                        keyRender={currentId}
                        style={['height']}
                        callback={({ type, params }) => {
                            if (type === 'parent') {
                                this.setState({ heightItems: params.height });
                            }
                        }}
                        sort={this.getSortItems}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsTariffsList);

DetailsTariffsList.propTypes = {
    type: PropTypes.string,
    holder: PropTypes.string,
    tariff: PropTypes.object,
    tariffData: PropTypes.object,
    tariffInfo: PropTypes.object,
    changeTariff: PropTypes.func,
    isNds: PropTypes.bool,
    className: PropTypes.string,
    newHolder: PropTypes.object,
};
