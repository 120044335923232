import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../../functions/setServerData';
import setAnimate from '../../../../functions/setAnimate';

import Head from '../card/Head.jsx';
import File from '../card/File.jsx';

import Select from '../../../Select.jsx';
import ArrowBtn from '../../../ArrowBtn.jsx';

const blocksManual = require('../../../../infos/crm/blocksManual.json');

class ManualCarsFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerScroll = this.handlerScroll.bind(this);

        this.parent = React.createRef();
    }

    templateFiles = [
        {
            name: 'forward',
            description: (
                <>
                    Машина <span>спереди</span>
                </>
            ),
        },
        {
            name: 'back',
            description: (
                <>
                    Машина <span>сзади</span>
                </>
            ),
        },
        {
            name: 'left',
            description: (
                <>
                    Машина <span>слева</span>
                </>
            ),
        },
        {
            name: 'right',
            description: (
                <>
                    Машина <span>справа</span>
                </>
            ),
        },
    ];

    getDescriptionInfo() {
        const { car = {} } = this.props;
        const { images = {} } = car;
        const { files = [] } = images;
        const filesComplete = files.filter(({ file }) => file.path);

        if (filesComplete.length) {
            return {
                description: `${filesComplete.length}/4`,
            };
        }

        return {
            description: '–',
        };
    }

    handlerScroll(dir) {
        const parentScroll = this.parent.current.querySelector('.manualCard__filesBox');
        const { scrollLeft, scrollWidth, offsetWidth } = parentScroll;

        setAnimate({
            draw: (progress) => {
                const left =
                    dir === 'next'
                        ? scrollLeft + progress * (scrollWidth - offsetWidth - scrollLeft)
                        : scrollLeft - progress * scrollLeft;

                parentScroll.scrollTo({
                    left,
                });
            },
            duration: 300,
        });
    }

    componentDidMount() {
        setServerData('carImagesPeriods');
    }

    render() {
        const {
            title,
            car = {},
            checkEditmode,
            uploadFile,
            deleteFile,
            serverData,
            changeCar,
            errors = [],
        } = this.props;
        const { carImagesPeriods = [] } = serverData;
        const { images = {} } = car;
        const { files = [], period } = images;
        const { description } = this.getDescriptionInfo();

        return (
            <div
                ref={this.parent}
                className={`manualCard _carFiles ${checkEditmode() ? '_isEdit' : ''}`}
            >
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                    <div className="manualCard__headInfo">
                        <div className="manualCard__headArrows _row">
                            <div className="manualCard__headArrow">
                                <ArrowBtn type="short" name="prev" callback={this.handlerScroll} />
                            </div>
                            <div className="manualCard__headArrow">
                                <ArrowBtn type="short" name="next" callback={this.handlerScroll} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__files">
                        <div className="manualCard__filesBox">
                            <div className="manualCard__filesInner">
                                {this.templateFiles.map((templateFile) => {
                                    const { name, description: descriptionFile } = templateFile;
                                    const fileParent = files.find((item) => item.name === name);
                                    const file = fileParent?.file;
                                    const isLocal = fileParent?.isLocal;

                                    return (
                                        <div className="manualCard__file" key={name}>
                                            <File
                                                _id={fileParent._id}
                                                files={files
                                                    .filter((fileLoop) => fileLoop.file.path)
                                                    .map((fileLoop) => ({
                                                        _id: fileLoop._id,
                                                        path: fileLoop.file.path,
                                                    }))}
                                                file={file}
                                                title={blocksManual.car.images.title}
                                                folder="cars"
                                                name={name}
                                                uploadFile={uploadFile}
                                                deleteFile={deleteFile}
                                                isDisabled={!checkEditmode()}
                                                isLocal={isLocal}
                                            >
                                                {descriptionFile}
                                            </File>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="manualCard__filesSelect _row">
                            <p className="manualCard__filesSelectSupport">График фотоконтроля</p>
                            <div className="manualCard__filesSelectField">
                                <Select
                                    support="–"
                                    name="period"
                                    value={period}
                                    callback={async ({ value }) =>
                                        changeCar({ type: 'images', name: 'period', value })
                                    }
                                    className="_manualField _reverse"
                                    isDisabled={!checkEditmode()}
                                    isEditmode={true}
                                    items={carImagesPeriods}
                                    error={errors.indexOf('period') !== -1}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualCarsFiles);

ManualCarsFiles.propTypes = {
    car: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    serverData: PropTypes.object,
    changeCar: PropTypes.func,
    errors: PropTypes.array,
};
