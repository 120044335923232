import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../../../components/crm/manual/Table.jsx';
import getExecutorLimits from '../../../../requests/getExecutorLimits';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import handlerWindow from '../../../../functions/handlerWindow';
import TableInfo from '../../../../components/crm/manual/TableInfo.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class PaysTaxMain extends Table {
    constructor(props) {
        super(props);

        const nowDate = new Date();

        nowDate.setUTCHours(nowDate.getUTCHours() + 3);

        let month = nowDate.getUTCMonth() - 1;

        if (month === -1) {
            month = 11;
        }

        this.state = {
            month,
        };

        this.handlerMore = this.handlerMore.bind(this);
        this.getActionsItems = this.getActionsItems.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    getActionsItems({ corporationIndex }) {
        const items = [];

        if (corporationIndex === 0) {
            items.push('logs');
        }

        if (
            this.getPermissions({
                adminCorporation: true,
                key: 'pays',
                items: [{ key: 'limits', rules: ['update'] }],
            })
        ) {
            items.unshift('edit');
        }

        return items;
    }

    handlerMore(executor, corporation, corporationIndex, e) {
        const { getParent, windows } = this.props;
        const parent = getParent();

        const actionsItems = this.getActionsItems({ corporationIndex });
        const { target } = e;

        e.stopPropagation();

        const key = `limits-${executor._id}-${corporationIndex}`;

        if (windows[key]) {
            handlerWindow({
                action: 'hide',
            });
        } else {
            handlerWindow({
                parent,
                parentResize: parent,
                parentScroll: this.parent.current?.querySelector('.tableInfo__scroll'),
                target: target.closest('.tableInfo__more'),
                action: 'show',
                name: 'limitsActions',
                className: '_actions _right',
                uniqKey: key,
                executor: {
                    _id: executor._id,
                    fullName: executor.fullName,
                    limits: {
                        month: corporation.monthLimit,
                        year: corporation.yearLimit,
                    },
                },
                corporation,
                items: actionsItems,
                centers: {
                    left: 1,
                    top: 0,
                },
                isHideFromScroll: true,
                isCheckScrollPosition: false,
                updateLimits: this.updateItems,
            });
        }
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getExecutorLimits(query).then(({ executors, isLimit, counter }) => {
                this.setItems(executors, false, isLimit, counter).then(() => {
                    resolve();
                });
            });
        });
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });

        super.componentDidMount();
    }

    render() {
        const {
            isReady,
            items = [],
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
            user,
        } = this.state;

        return (
            <div ref={this.parent} className={`widget _notPadding ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="paysLimits"
                                setHeightPage={this.setHeightPage}
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={isLoadingFilter}
                                isWrap={true}
                                isBlock={true}
                                isNotLink={true}
                                getActionsItems={this.getActionsItems}
                                {...(user?.idOfCurrentCorporation === 'admin'
                                    ? { handlerMore: this.handlerMore }
                                    : {})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        user: state.user,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(PaysTaxMain);

PaysTaxMain.propTypes = {
    user: PropTypes.object,
    setHeightPage: PropTypes.func,
    heightWindow: PropTypes.number,
    windows: PropTypes.object,
};
