import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import handlerPopup from '../../../functions/app/handlerPopup';

import Icon from '../../Icon.jsx';
import Switch from '../../Switch.jsx';
import Checkbox from '../../Checkbox.jsx';
import Button from '../../Button.jsx';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import Windows from '../../Windows.jsx';
import WindowPrompt from '../../WindowPrompt.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class RolePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerActive = this.handlerActive.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.save = this.save.bind(this);
        this.renderRolePrompt = this.renderRolePrompt.bind(this);

        this.parent = React.createRef();
    }

    colsOrder = ['name', 'read', 'update', 'create', 'delete', 'sign'];

    cols = {
        name: 'Подраздел',
        read: 'Просмотр',
        update: 'Редактирование',
        create: 'Создание',
        delete: 'Удаление',
        sign: 'Подписание',
    };

    getActive({ key }) {
        const { section } = this.state;
        const item = section?.items.find((innerItem) => innerItem.key === key);

        return item?.rules.length || item?.actions.length;
    }

    handlerActive({ key, isActive }) {
        const { rolePopup } = this.props;
        const { role } = rolePopup;
        const sectionInfo = role?.sectionsTemplate.find(
            (item) => item.key === this.state.section?.key,
        );

        this.setState((state) => {
            const newState = { ...state };
            const section = { ...newState.section };

            const index = section.items.findIndex((item) => item.key === key);

            if (index !== -1) {
                const sectionInfoItem = sectionInfo.items.find((item) => item.key === key);

                section.items[index].rules = isActive ? [...sectionInfoItem.rules] : [];
                section.items[index].actions = isActive
                    ? [...sectionInfoItem.actions.map((action) => action.key)]
                    : [];
            }

            newState.section = section;

            return newState;
        });
    }

    handlerItem({ key, name, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const section = { ...newState.section };

            const index = section.items.findIndex((item) => item.key === key);

            if (index !== -1) {
                const valueIndex = section.items[index][name].indexOf(value);

                if (valueIndex === -1) {
                    section.items[index].isActive = true;

                    section.items[index][name].push(value);
                } else {
                    section.items[index][name].splice(valueIndex, 1);
                }
            }

            newState.section = section;

            return newState;
        });
    }

    checkAll() {
        const { section } = this.state;

        if (!section) {
            return false;
        }

        const { rolePopup } = this.props;
        const { role } = rolePopup;
        const sectionInfo = role?.sectionsTemplate.find(
            (item) => item.key === this.state.section?.key,
        );

        let isAll = true;

        sectionInfo.items.forEach((item) => {
            const sectionItem = section.items.find((innerItem) => innerItem.key === item.key);

            item.rules.forEach((rule) => {
                if (!sectionItem.rules.includes(rule)) {
                    isAll = false;
                }
            });

            item.actions.forEach((action) => {
                if (!sectionItem.actions.includes(action.key)) {
                    isAll = false;
                }
            });
        });

        return isAll;
    }

    handlerAll() {
        const { rolePopup } = this.props;
        const { role } = rolePopup;
        const sectionInfo = role?.sectionsTemplate.find(
            (item) => item.key === this.state.section?.key,
        );
        const isAll = this.checkAll();

        this.setState((state) => {
            const newState = { ...state };
            const section = { ...newState.section };

            section.items.forEach((item) => {
                const sectionInfoItem = sectionInfo.items.find(
                    (innerItem) => innerItem.key === item.key,
                );

                item.rules = !isAll ? [...sectionInfoItem.rules] : [];
                item.actions = !isAll
                    ? [...sectionInfoItem.actions.map((action) => action.key)]
                    : [];
            });

            newState.section = section;

            return newState;
        });
    }

    checkChange() {
        const { section, savedSection } = this.state;
        let isChange = false;

        section?.items.forEach((item) => {
            const savedItem = savedSection.items.find((innerItem) => innerItem.key === item.key);

            item.rules.forEach((rule) => {
                if (!savedItem.rules.includes(rule)) {
                    isChange = true;
                }
            });

            savedItem.rules.forEach((rule) => {
                if (!item.rules.includes(rule)) {
                    isChange = true;
                }
            });

            item.actions.forEach((action) => {
                if (!savedItem.actions.includes(action)) {
                    isChange = true;
                }
            });

            savedItem.actions.forEach((action) => {
                if (!item.actions.includes(action)) {
                    isChange = true;
                }
            });
        });

        return isChange;
    }

    save(isForce) {
        const { section } = this.state;
        const { rolePopup } = this.props;
        const { role, updateRole } = rolePopup;

        return new Promise((resolve) => {
            if (!this.checkChange()) {
                resolve();
            } else {
                this.handlerLoading(true).then(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/role`,
                            {
                                id: role._id,
                                fields: { sections: [section] },
                                ...(isForce === true ? { isForce } : {}),
                            },
                            { headers: getHeaders() },
                        )
                        .then(
                            (res) => {
                                this.handlerLoading(false);

                                const { success, data } = res.data;

                                if (success) {
                                    setNotification({
                                        notification: 'success-change-info',
                                    });

                                    this.setState({
                                        section: JSON.parse(JSON.stringify(section)),
                                        savedSection: JSON.parse(JSON.stringify(section)),
                                    });

                                    if (typeof updateRole === 'function') {
                                        updateRole();
                                    }
                                } else {
                                    const { message } = data;

                                    if (message === 'Users lose proxies') {
                                        const parent =
                                            this.parent.current.querySelector('.rolePopup__inner');
                                        const target =
                                            this.parent.current.querySelector(
                                                '.rolePopup__footButton',
                                            );

                                        handlerWindow({
                                            parent,
                                            parentResize: parent,
                                            target,
                                            action: 'show',
                                            name: 'roleUsersLoseProxies',
                                            className: '_prompt _roleUsersLoseProxies',
                                            uniqKey: `roleUsersLoseProxies`,
                                            centers: {
                                                left: 1,
                                                top: 0,
                                            },
                                        });
                                    }
                                }

                                resolve();
                            },
                            () => {
                                this.handlerLoading(false);

                                resolve();
                            },
                        );
                });
            }
        });
    }

    renderRolePrompt() {
        const callback = ({ hide, handlerLoader }) => {
            this.save(true).then(
                () => {
                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return (
            <WindowPrompt
                className="_bottomRight"
                callback={callback}
                name="roleUsersLoseProxies"
            />
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    componentDidMount() {
        const { rolePopup } = this.props;
        const { section } = rolePopup;

        this.setState({
            section: JSON.parse(JSON.stringify(section)),
            savedSection: JSON.parse(JSON.stringify(section)),
        });
    }

    render() {
        const { section, loadingKey } = this.state;
        const { rolePopup } = this.props;
        const { role } = rolePopup;
        const sectionInfo = role?.sectionsTemplate.find((item) => item.key === section?.key);

        return (
            <div ref={this.parent} className="rolePopup _col">
                <div className="rolePopup__inner">
                    <Windows name="roleUsersLoseProxies" renderContent={this.renderRolePrompt} />

                    <div className="rolePopup__innerBox _col">
                        <div className="rolePopup__head _col">
                            <div className="rolePopup__title">Раздел — {section?.name}</div>
                            <p className="rolePopup__description">
                                Настройка раздела для роли {role?.name}
                            </p>
                            <label className="rolePopup__choiceAll _click">
                                <div className="rolePopup__choiceAllBox">
                                    <Checkbox
                                        value={this.checkAll()}
                                        handler={() => {
                                            this.handlerAll();
                                        }}
                                        className="_square _green _bigSize"
                                    />
                                </div>
                                Полные права
                            </label>
                            <i
                                className="rolePopup__headClose _click"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'rolePopup',
                                        isShow: false,
                                    });
                                }}
                            >
                                <Icon name="close-circle" />
                            </i>
                        </div>
                        <div className="rolePopup__content">
                            <div className="rolePopup__foot _col">
                                <div className="rolePopup__footButton">
                                    <Button
                                        className="_main _medium2Size"
                                        onClick={this.save}
                                        showLoader={!!loadingKey}
                                        isDisabled={!this.checkChange()}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </div>
                            </div>
                            <div className="rolePopup__table">
                                <div className="rolePopup__tableRow _row _head">
                                    {this.colsOrder.map((name) => (
                                        <div className={`rolePopup__tableCol _col _${name}`} key={name}>
                                            {this.cols[name]}
                                        </div>
                                    ))}
                                </div>
                                <div className="rolePopup__tableInner">
                                    {sectionInfo?.items.map((item) => {
                                        const sectionItem = section.items.find(
                                            (innerItem) => innerItem.key === item.key,
                                        );
                                        const isActive = this.getActive({
                                            key: item.key,
                                        });

                                        return (
                                            <div className="rolePopup__tableRow _row" key={item.key}>
                                                {this.colsOrder.map((name) => (
                                                    <div
                                                        className={`rolePopup__tableCol _col _${name}`}
                                                        key={name}
                                                    >
                                                        {name === 'name' ? (
                                                            <>
                                                                <label className="rolePopup__tableCheckbox _click">
                                                                    <div className="rolePopup__tableCheckboxBox">
                                                                        <Checkbox
                                                                            value={isActive}
                                                                            handler={() => {
                                                                                this.handlerActive({
                                                                                    key: item.key,
                                                                                    isActive:
                                                                                        !isActive,
                                                                                });
                                                                            }}
                                                                            className="_square _green _bigSize"
                                                                        />
                                                                    </div>
                                                                    {item.name}
                                                                </label>
                                                                {item.actions?.length > 0 && (
                                                                    <div className="rolePopup__tableActions _col">
                                                                        {item.actions.map(
                                                                            (action) => (
                                                                                <label
                                                                                    className="rolePopup__tableCheckbox"
                                                                                    key={action.key}
                                                                                >
                                                                                    <div className="rolePopup__tableCheckboxBox">
                                                                                        <Checkbox
                                                                                            value={sectionItem.actions.includes(
                                                                                                action.key,
                                                                                            )}
                                                                                            handler={() => {
                                                                                                this.handlerItem(
                                                                                                    {
                                                                                                        key: item.key,
                                                                                                        name: 'actions',
                                                                                                        value: action.key,
                                                                                                    },
                                                                                                );
                                                                                            }}
                                                                                            className="_square _green"
                                                                                        />
                                                                                    </div>
                                                                                    {action.name}
                                                                                </label>
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="rolePopup__tableSwitch">
                                                                    {item.rules.includes(name) ? (
                                                                        <Switch
                                                                            value={sectionItem.rules.includes(
                                                                                name,
                                                                            )}
                                                                            handler={() => {
                                                                                this.handlerItem({
                                                                                    key: item.key,
                                                                                    name: 'rules',
                                                                                    value: name,
                                                                                });
                                                                            }}
                                                                            className="_green _size3"
                                                                        />
                                                                    ) : (
                                                                        <>—</>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolePopup: state.rolePopup,
    };
}

export default connect(mapStateToProps)(RolePopup);

RolePopup.propTypes = {
    rolePopup: PropTypes.object,
};
