import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Head from '../../../components/crm/manual/card/Head.jsx';
import Icon from '../../../components/Icon.jsx';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import Switch from '../../../components/Switch.jsx';
import ImageLazy from '../../../components/ImageLazy.jsx';

import getHeaders from '../../../functions/getHeaders';
import handlerSupportCursor from '../../../functions/handlerSupportCursor';

import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';

const orderServiceCompanies = require('../../../infos/crm/orderServiceCompanies.json');

class SettingsServiceOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerService = this.handlerService.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    renderServiceCard({ key, link, logo, tariffs }) {
        const { loadingKey } = this.state;
        const { serverData, corporation } = this.props;
        const { orderServiceTariffs } = serverData;
        const { serviceOrder } = corporation;
        const isChoice = !!serviceOrder.items.find((item) => item.key === key);

        return (
            <div className={`settingsServiceOrder__service ${isChoice ? '_choice' : ''}`}>
                <div className="settingsServiceOrder__serviceContent">
                    <img
                        src={require(`../../../img/crm/order-services-companies/${logo}`)}
                        alt=""
                        className="settingsServiceOrder__serviceLogo"
                    />
                    <div className="settingsServiceOrder__serviceTariffs _row">
                        {orderServiceTariffs &&
                            tariffs.map((tariff) => {
                                const tariffsInfo = orderServiceTariffs?.tariffs.find(
                                    (item) => item.key === tariff,
                                );

                                return (
                                    <div
                                        className="settingsServiceOrder__serviceTariff"
                                        key={tariff}
                                        onMouseEnter={(e) =>
                                            handlerSupportCursor({
                                                action: 'enter',
                                                content: tariffsInfo.name,
                                                e,
                                                data: { className: '_normalCase _center' },
                                            })
                                        }
                                        onMouseLeave={(e) =>
                                            handlerSupportCursor({
                                                action: 'leave',
                                                e,
                                            })
                                        }
                                    >
                                        <ImageLazy
                                            src={require(`../../../img/crm/order-services-companies/${tariffsInfo.icon}`)}
                                            alt=""
                                            className="settingsServiceOrder__serviceTariffIcon"
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="settingsServiceOrder__serviceFoot _row">
                    <div className="settingsServiceOrder__serviceCheckbox _row">
                        <AnimateChangeUp
                            className="settingsServiceOrder__serviceCheckboxInner"
                            renderKey={isChoice}
                        >
                            {isChoice ? 'Выбрана' : 'Не выбрана'}
                        </AnimateChangeUp>
                        <div className="settingsServiceOrder__serviceCheckboxButton">
                            <Switch
                                className="_size3"
                                value={isChoice}
                                handler={() => {
                                    this.handlerService({ key });
                                }}
                            />
                        </div>
                        <Animate
                            className="settingsServiceOrder__serviceCheckboxLoader"
                            isShow={loadingKey === key}
                        >
                            <Loader className="_main" />
                        </Animate>
                    </div>
                    {link && (
                        <div className="settingsServiceOrder__serviceInfo">
                            <div className="settingsServiceOrder__serviceInfoAlert">
                                <div className="settingsServiceOrder__serviceInfoAlertInner">
                                    Условия тарифа
                                    <br />
                                    читайте на{' '}
                                    <a href={link} target="_blank" rel="noreferrer">
                                        сайте
                                        <br />
                                        службы доставки
                                    </a>
                                </div>
                            </div>
                            <i className="settingsServiceOrder__serviceInfoIcon _click">
                                <Icon name="info" />
                            </i>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerService({ key }) {
        const { loadingKey } = this.state;
        const { corporation } = this.props;

        if (!loadingKey) {
            this.handlerLoading(key).then(() => {
                axios.patch(
                    `${process.env.REACT_APP_API}/corporation`,
                    { id: corporation._id, action: 'service-order', serviceKey: key },
                    { headers: getHeaders() },
                );
            });
        }
    }

    handlerSocket({ detail }) {
        const { corporation, user } = this.props;
        const { name, data } = detail;

        if (corporation && name === 'corporation') {
            const { type, fields, idOfCorporation, initiatorUserId } = data;

            if (
                type === 'changeInfo' &&
                corporation._id === idOfCorporation &&
                fields.serviceOrder &&
                initiatorUserId === user._id
            ) {
                this.handlerLoading(null);
            }
        }
    }

    componentDidMount() {
        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { corporation } = this.props;
        const { serviceOrder } = corporation;

        return (
            <div ref={this.parent} className="widget _ready">
                <div ref={this.parent} className="widget__content _inner">
                    <div className="widget__contentBox">
                        <div className="settingsServiceOrder">
                            <div className="settingsServiceOrder__card">
                                <div className="manualCard">
                                    <div className="manualCard__head _row">
                                        <Head
                                            title="Ваши службы доставки"
                                            description={`Выбраны ${serviceOrder.items.length} из ${orderServiceCompanies.length}`}
                                        />
                                    </div>
                                    <div className="manualCard__content">
                                        <div className="settingsServiceOrder__main">
                                            <div className="settingsServiceOrder__info">
                                                <i className="settingsServiceOrder__infoIcon">
                                                    <Icon name="info" />
                                                </i>
                                                При создании заказа, поиск исполнителя будет
                                                происходить среди выбранных вами служб доставки
                                            </div>
                                            <div className="settingsServiceOrder__servicesCards _row">
                                                {orderServiceCompanies.map((service) => (
                                                    <div
                                                        className="settingsServiceOrder__servicesCard"
                                                        key={service.key}
                                                    >
                                                        {this.renderServiceCard(service)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsServiceOrder);

SettingsServiceOrder.propTypes = {
    corporation: PropTypes.object,
    serverData: PropTypes.object,
    user: PropTypes.object,
};
