import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

import getDateFromString from '../../../../functions/getDateFromString';
import getStatusDate from '../../../../functions/crm/getStatusDate';

class ManualExecutorsDriverPassport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    fields = [
        { name: 'country' },
        {
            name: 'dateGet',
            max: new Date(),
        },
        { name: 'view', nameSelect: 'executorCarViews' },
        { name: 'dateAction' },
        { name: 'series' },
        {
            name: 'dateExp',
            max: new Date(),
        },
        { name: 'number' },
        { name: 'categories', classNameField: '_reverse' },
    ];

    getDescriptionInfo() {
        const { executor = {} } = this.props;
        const dateAction = executor.driverPassport?.dateAction;

        if (dateAction) {
            const date = getDateFromString({ date: dateAction });
            const { status, className } = getStatusDate({ date });

            if (status === 'success') {
                return {
                    className,
                    description: 'Действительно',
                };
            }

            if (status === 'medium') {
                return {
                    className,
                    description: 'Истекает',
                };
            }

            if (status === 'error') {
                return {
                    className,
                    description: 'Просрочено',
                };
            }
        }

        return {
            description: '–',
        };
    }

    render() {
        const { title, executor = {}, changeExecutor, checkEditmode, errors = [] } = this.props;
        const { description, className: classNameDescription } = this.getDescriptionInfo();

        return (
            <div className="manualCard _executorDriverPassport">
                <div className="manualCard__head _row">
                    <Head
                        title={title}
                        description={description}
                        classNameDescription={classNameDescription}
                    />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, ...propsField } = field;
                            const { [name]: value } = executor.driverPassport || {};

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="executor"
                                        name={name}
                                        handler={async (props) =>
                                            changeExecutor({ ...props, type: 'driverPassport' })
                                        }
                                        group="executorDriverPassport"
                                        isDisabled={!checkEditmode()}
                                        isError={errors.indexOf(name) !== -1}
                                        {...propsField}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsDriverPassport);

ManualExecutorsDriverPassport.propTypes = {
    executor: PropTypes.object,
    title: PropTypes.string,
    changeExecutor: PropTypes.func,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
};
