import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../classes/Editmode';

import CarInCrew from '../../../../components/crm/order-details/CarInCrew.jsx';
import Executor from '../../../../components/crm/order-details/Executor.jsx';

import removeTransition from '../../../../functions/removeTransition.ts';
import getHeaders from '../../../../functions/getHeaders';
import setNotification from '../../../../functions/setNotification';
import changePage from '../../../../functions/changePage';
import getCrewInfo from '../../../../functions/order-details/getCrewInfo';
import setServerData from '../../../../functions/setServerData';

import Animate from '../../../../components/Animate.jsx';
import Button from '../../../../components/Button.jsx';
import Edit from '../../../../components/Edit.jsx';
import Icon from '../../../../components/Icon.jsx';
import Link from '../../../../components/Link.jsx';
import AnimateChange from '../../../../components/AnimateChange.jsx';
import ListAbsolute from '../../../../components/ListAbsolute.jsx';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import getPageLink from '../../../../functions/getPageLink';

class OrderDetailsCrewFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changedPrices: {},
        };

        this.deleteExecutor = this.deleteExecutor.bind(this);
        this.setCrew = this.setCrew.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.getCondForInfo = this.getCondForInfo.bind(this);
        this.renderExecutor = this.renderExecutor.bind(this);
        this.setParamsExecutors = this.setParamsExecutors.bind(this);
        this.getExecutorsList = this.getExecutorsList.bind(this);
        this.setCurrentCar = this.setCurrentCar.bind(this);
        this.handlerChangedPrice = this.handlerChangedPrice.bind(this);
        this.getTariffInfo = this.getTariffInfo.bind(this);

        this.parent = React.createRef();
    }

    getHeightInfo() {
        if (this.parent.current) {
            const { heightExecutors = 0 } = this.state;

            const car = this.parent.current.querySelector(
                '.orderDetailsCrewFinal__contentItem._car',
            );
            const { offsetHeight: heightCar } = car;

            return heightCar - heightExecutors - 12;
        }

        return 0;
    }

    updateExecutor({ executor }) {
        const { order, executors, setExecutors } = this.props;

        return new Promise((resolve) => {
            if (!order.crew.length) {
                resolve();
            } else {
                this.editmode.handlerLoading(this.state.editName).then(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/order`,
                            {
                                id: order._id,
                                type: 'update-crew',
                                executor,
                            },
                            { headers: getHeaders() },
                        )
                        .then((res) => {
                            const { success } = res.data;

                            this.editmode.handlerLoading(null);

                            if (success) {
                                setExecutors({ executors });

                                setNotification({ notification: 'change-order' });
                            } else {
                                handlerErrorRequest(res);
                            }

                            resolve();
                        });
                });
            }
        });
    }

    handlerChangedPrice({ action, id, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const changedPrices = { ...newState.changedPrices };

                        changedPrices[id] = value;

                        newState.changedPrices = changedPrices;

                        return newState;
                    },
                    () => {
                        removeTransition({ item: '.orderDetailsCrewExecutor__contentPrice' });

                        resolve();
                    },
                );
            }
        });
    }

    getTariffInfo({ tariff, type, isDriver }) {
        if (tariff) {
            if (type === 'worker' && isDriver) {
                const { configurationWorker } = tariff;

                return {
                    amount: configurationWorker.amount,
                    name: configurationWorker.name,
                };
            }

            const { configurations, idOfTariff } = tariff;
            const items = configurations.reduce((prev, cur) => prev.concat(...cur.items), []);

            if (idOfTariff) {
                const item = items.find((itemLoop) => itemLoop._id === idOfTariff);

                if (item) {
                    return {
                        amount: item.amount,
                        name: item.name,
                    };
                }
            }
        }

        return {};
    }

    handlerEditmode({ editName }) {
        const { changedPrices } = this.state;
        const { executors, executorsSave, order } = this.props;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else if (this.state.editName) {
                    if (this.state.editName === 'car') {
                        const executor = executors.find(
                            (executorLoop) => executorLoop.type === 'driver',
                        );
                        const executorSave = executorsSave.find(
                            (executorLoop) => executorLoop.type === 'driver',
                        );
                        const { car } = executor;

                        if (car === executorSave.car) {
                            resolve();
                        } else {
                            this.updateExecutor({ executor: { _id: executor._id, car } }).then(
                                resolve,
                            );
                        }
                    } else {
                        const executor = executors.find(
                            (executorLoop) =>
                                this.getExecutorsList().find(
                                    (executorList) =>
                                        executorList._id === executorLoop._id &&
                                        (executorLoop.isDriver ? executorList.isDriver : true),
                                ).id === this.state.editName,
                        );
                        const executorSave = executorsSave.find(
                            (executorLoop) =>
                                this.getExecutorsList().find(
                                    (executorList) =>
                                        executorList._id === executorLoop._id &&
                                        (executorLoop.isDriver ? executorList.isDriver : true),
                                ).id === this.state.editName,
                        );

                        const { idOfTariff, customPrice } = executor.tariff;

                        const changedPrice = changedPrices[this.state.editName];

                        if (
                            order.crew.find((item) => item.id === executor._id) &&
                            (idOfTariff !== executorSave.tariff.idOfTariff ||
                                customPrice !== executorSave.tariff.customPrice ||
                                +changedPrice !== +this.getTariffInfo(executor).amount)
                        ) {
                            this.updateExecutor({
                                executor: {
                                    _id: executor._id,
                                    idOfTariff,
                                    customPrice,
                                    changedPrice,
                                },
                            }).then(resolve);
                        } else {
                            resolve();
                        }
                    }
                }
            });

        return new Promise((resolve) => {
            checkEdit().then(() => {
                this.editmode.handlerEdit({ editName }).then(resolve);
            });
        });
    }

    handlerLoading(isLoading = !this.state.isLoading) {
        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    setCrew() {
        const { order, executors } = this.props;
        const crew = executors.map((executor) => ({
            id: executor._id,
            role: executor.type,
            car: executor.car,
            idOfTariff: executor.tariff.idOfTariff,
            customPrice: +executor.tariff.customPrice,
        }));

        this.handlerLoading(true).then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/order`,
                    { id: order._id, type: 'crew', crew },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    this.handlerLoading(false);

                    const { success, data } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-set-crew' });

                        changePage({
                            href: getPageLink({
                                name: 'order-details-main',
                                ids: { 2: order._id },
                            }),
                        });
                    } else {
                        const { message } = data;

                        if (message === 'Executor not active smz status') {
                            setNotification({ notification: 'error-set-crew-smz' });
                        }

                        handlerErrorRequest(res);
                    }
                });
        });
    }

    checkChangeCrew() {
        const { order, executors } = this.props;

        if (order) {
            let isChange =
                !!order.crew.find(
                    (executorSave) =>
                        !executors.find(
                            (executor) =>
                                executor._id === executorSave.id &&
                                executor.type === executorSave.role,
                        ),
                ) ||
                !!executors.find(
                    (executor) =>
                        !order.crew.find(
                            (executorSave) =>
                                executorSave.id === executor._id &&
                                executorSave.role === executor.type,
                        ),
                );
            const driver = executors.find((executor) => executor.type === 'driver');
            const driverSave = order.crew.find((executor) => executor.role === 'driver');

            if (
                !order.crew.length &&
                driver &&
                ((driverSave && driver.car.toString() !== driverSave.car.toString()) || !driverSave)
            ) {
                isChange = true;
            }

            return isChange;
        }

        return false;
    }

    checkCompleteCrew() {
        const { order, executors = [] } = this.props;
        const crewTemplate = order?.crewTemplate || [];
        const crewTemplateCounters = {};

        crewTemplate.forEach((role) => {
            if (!crewTemplateCounters[role]) {
                crewTemplateCounters[role] = 0;
            }

            crewTemplateCounters[role] += 1;
        });

        return (
            crewTemplate.length &&
            Object.keys(crewTemplateCounters).every(
                (role) =>
                    executors.filter((executor) => executor.type === role).length ===
                    crewTemplateCounters[role],
            )
        );
    }

    deleteExecutor({ id, type }) {
        const { deleteExecutor, executors } = this.props;

        this.setState({ executors }, () => {
            deleteExecutor({ id, type });
        });
    }

    checkGetExecutors() {
        if (this.props.executors && this.props.executors.length && !this.isGetExecutors) {
            this.isGetExecutors = true;

            this.setState({ listUpdatedKey: new Date().getTime() });
        }
    }

    getCondForInfo() {
        return this.getHeightInfo() > 80;
    }

    getIdExecutor({ key, type }) {
        return `${key}-${type}`;
    }

    setParamsExecutors(params = {}) {
        const { height: heightExecutors } = params;

        if (heightExecutors !== this.state.heightExecutors) {
            this.setState({ heightExecutors }, () => {
                if (!this.isInit) {
                    this.isInit = true;

                    removeTransition({ item: '.orderDetailsCrewFinal__executors' });
                }
            });
        }
    }

    getExecutorsList() {
        const { executors = [], order } = this.props;
        const crewTemplate = order?.crewTemplate || [];
        const executorsForCrew = [];

        crewTemplate.forEach((type, key) => {
            const executor = executors.find(
                (item) =>
                    item.type === type &&
                    !executorsForCrew.find(
                        (executorCrew) =>
                            executorCrew._id === item._id && executorCrew.type === type,
                    ),
            );

            const id = this.getIdExecutor({
                key,
                type,
            });

            if (executor) {
                const executorCrew = order?.crew.find(
                    (executorLoop) => executorLoop.id === executor._id,
                );
                const isAccept = executorCrew?.isAccept;

                executorsForCrew.push({ ...executor, id, isAccept });
            } else {
                executorsForCrew.push({
                    isEmpty: true,
                    type,
                    id,
                });
            }
        });

        return executorsForCrew;
    }

    renderExecutor({ item, prop: id, isShow, isLast, params }) {
        const { editName, keyLoading, changedPrices } = this.state;
        const { getCondForChange, isGetExecutors, parent, changeTariff, order } = this.props;
        const executors = this.getExecutorsList();
        let executor = executors.find((executorLoop) => executorLoop.id === id) || item;
        const executorInCrew = order?.crew.find((innerItem) => innerItem.id === executor._id);

        // console.log(executorInCrew?.changedPrice);

        if (!executor?.firstName) {
            executor = { ...executor, ...item };
        }

        return (
            <div
                className={`orderDetailsCrewFinal__executor _parentForEdit ${isShow ? '_show' : ''} ${
                    executor && editName === id ? '_current' : editName ? '_disabled' : ''
                } ${isLast ? '_last' : ''}`}
                key={id}
                data-id={id}
                style={{
                    transform: `translate(${params?.offsetLeft}px,${params?.offsetTop}px)`,
                    height: `${params?.height}px`,
                }}
            >
                {!executor.isEmpty && !executor.isDriver && getCondForChange('executor') && (
                    <Edit
                        className="orderDetailsCrewFinal__executorEdit"
                        handlerEditmode={this.handlerEditmode}
                        editName={editName}
                        name={id}
                        isLoader={keyLoading === id}
                    />
                )}
                <Executor
                    {...executor}
                    executorInCrew={executorInCrew}
                    type={executor.type}
                    isEmpty={executor.isEmpty}
                    handlerEditmode={this.handlerEditmode}
                    editName={editName}
                    deleteExecutor={this.deleteExecutor}
                    getCondForChange={getCondForChange}
                    editNameExecutor={id}
                    isShowLoading={!isGetExecutors}
                    parent={parent}
                    changeTariff={changeTariff}
                    handlerChangedPrice={this.handlerChangedPrice}
                    changedPrice={changedPrices[id]}
                    order={order}
                    getTariffInfo={this.getTariffInfo}
                />
            </div>
        );
    }

    setCurrentCar(id) {
        return new Promise((resolve) => {
            const { setCurrentCar } = this.props;

            setCurrentCar(id).then(() => {
                this.handlerEditmode({ editName: null }).then(resolve);
            });
        });
    }

    checkUpdateList() {
        const { executors } = this.props;

        if (executors.length && this.lenExecutorProps !== executors.length) {
            this.lenExecutorProps = executors.length;

            this.setState({ updateKey: new Date().getTime() });
        }
    }

    editmode = new Editmode({
        context: this,
    });

    componentDidMount() {
        removeTransition({ item: '.orderDetailsCrewFinal__info' });

        setServerData('executorNames');

        this.checkGetExecutors();
        this.checkUpdateList();

        window.addEventListener('resize', this.getParams);
    }

    componentDidUpdate() {
        this.checkGetExecutors();
        this.checkUpdateList();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getParams);
    }

    render() {
        const { editName, isLoading, heightExecutors = 0, updateKey, listUpdatedKey } = this.state;
        const { serverData, order, getCondForChange, isGetExecutors } = this.props;
        let { executors } = this.props;
        const { executorNames } = serverData;

        if (this.state.executors) {
            executors = this.state.executors;
        }

        const driver = executors.find((executor) => executor.type === 'driver');
        const currentCar = driver?.infoCars.find((car) => car._id === driver.car);
        const executorsForCrew = this.getExecutorsList();

        // console.log(executorsForCrew);

        const infoCrew = getCrewInfo({
            order: {
                crew: executorsForCrew
                    .filter((executor) => !executor.isEmpty)
                    .map((executor) => ({ role: executor.type })),
                crewTemplate: order?.crewTemplate || [],
            },
            executorNames,
        });

        return (
            <div ref={this.parent} className={`orderDetailsCrewFinal _parentForEdits ${editName ? '_edit' : ''}`}>
                <div className="orderDetailsCrewFinal__content _row">
                    <div className="orderDetailsCrewFinal__contentItem _car">
                        <CarInCrew
                            isEmpty={!driver}
                            car={currentCar}
                            cars={driver?.infoCars}
                            idOfCurrentCar={driver?.car}
                            setCurrentCar={this.setCurrentCar}
                            handlerEditmode={this.handlerEditmode}
                            editName={editName}
                            getCondForChange={getCondForChange}
                            isShowLoading={!isGetExecutors}
                        />
                    </div>
                    <div className="orderDetailsCrewFinal__contentItem _executors">
                        <div className="orderDetailsCrewFinal__contentItemInner">
                            <div
                                className="orderDetailsCrewFinal__executors"
                                style={{ height: `${heightExecutors}px` }}
                            >
                                <ListAbsolute
                                    parent={(() =>
                                        this.parent.current?.querySelector(
                                            '.orderDetailsCrewFinal__executors',
                                        ))()}
                                    items={executorsForCrew}
                                    renderItem={this.renderExecutor}
                                    classNameItem="orderDetailsCrewFinal__executor"
                                    prop="id"
                                    paramsParent={{ width: true }}
                                    clearStyleElems={['.orderDetailsCrewFinal__executor']}
                                    setParamsParent={this.setParamsExecutors}
                                    propsForUpdate={['isEmpty', 'isAccept']}
                                    keyUpdateItem={updateKey}
                                    keyRender={listUpdatedKey}
                                    // name="test"
                                />
                            </div>
                            <Animate
                                className="orderDetailsCrewFinal__info _editBack"
                                isShow={this.getCondForInfo()}
                                style={{ height: `${this.getHeightInfo()}px` }}
                            >
                                <div className="empty _col _block _notBack">
                                    <AnimateChange
                                        className="empty__inner"
                                        type="_translate _transFast"
                                        prop={infoCrew.description}
                                    >
                                        <div className="empty__innerItem">
                                            <div className="empty__title">{infoCrew.title}</div>
                                            <p
                                                className="empty__content"
                                                dangerouslySetInnerHTML={{
                                                    __html: infoCrew.description,
                                                }}
                                            ></p>
                                        </div>
                                    </AnimateChange>
                                </div>
                            </Animate>
                        </div>
                    </div>
                </div>
                <div className="orderDetailsCrewFinal__foot _editBack _row">
                    <div className="orderDetailsCrewFinal__footItem _row">
                        <div className="orderDetailsCrewFinal__footButton _click _row _action">
                            Доверенность
                            <i className="orderDetailsCrewFinal__footButtonIcon">
                                <Icon name="crew-download" />
                            </i>
                            <i className="orderDetailsCrewFinal__footButtonIcon">
                                <Icon name="crew-eye" />
                            </i>
                        </div>
                        <div className="orderDetailsCrewFinal__footButton _click _row _action">
                            Для мессенджера
                            <i className="orderDetailsCrewFinal__footButtonIcon">
                                <Icon name="crew-download" />
                            </i>
                            <i className="orderDetailsCrewFinal__footButtonIcon">
                                <Icon name="crew-eye" />
                            </i>
                        </div>
                        <div className="orderDetailsCrewFinal__footButton _click _row _action">
                            Отправить водителю
                        </div>
                    </div>
                    <div className="orderDetailsCrewFinal__footItem _row _nav">
                        <Link
                            pageName="order-details-crew-map"
                            className="orderDetailsCrewFinal__footButton _back"
                            ids={{ 1: order?._id }}
                        >
                            <Button
                                className="_mainNotBorder _white _mediumSize _bigPaddings"
                                icon={{ name: 'arrow-prev', type: 'start' }}
                            >
                                Назад
                            </Button>
                        </Link>
                        <div className="orderDetailsCrewFinal__footButton _click _save">
                            <Button
                                className="_mainNotBorder _mediumSize _bigPaddings _disabledWhite"
                                isDisabled={
                                    !getCondForChange() ||
                                    !this.checkChangeCrew() ||
                                    !this.checkCompleteCrew() ||
                                    !isGetExecutors
                                }
                                onClick={this.setCrew}
                                showLoader={isLoading}
                            >
                                Назначить экипаж
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(OrderDetailsCrewFinal);

OrderDetailsCrewFinal.propTypes = {
    serverData: PropTypes.object,
    executors: PropTypes.array,
    executorsSave: PropTypes.array,
    setCurrentCar: PropTypes.func,
    order: PropTypes.object,
    deleteExecutor: PropTypes.func,
    getCondForChange: PropTypes.func,
    isGetExecutors: PropTypes.bool,
    parent: PropTypes.object,
    changeTariff: PropTypes.func,
    setExecutors: PropTypes.func,
};
