import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFile from '../../../classes/File';

import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getDeepValue from '../../../functions/getDeepValue';
import handlerLoading from '../../../functions/handlerLoading';
import getUpdateFormData from '../../../functions/getUpdateFormData';
import checkChangeArray from '../../../functions/checkChangeArray';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Field from '../../Field.jsx';
import Animate from '../../Animate.jsx';
import EmodziList from '../../EmodziList.jsx';
import Button from '../../Button.jsx';
import WindowPrompt from '../../WindowPrompt.jsx';

import FileItem from '../../chat/File.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import setNotification from '../../../functions/setNotification';
import Loader from '../../Loader.jsx';
import changePage from '../../../functions/changePage';
import getNotPopupPage from '../../../functions/getNotPopupPage';
import getHeaders from '../../../functions/getHeaders';
import setPermissions from '../../../functions/crm/setPermissions';
import removeTransition from '../../../functions/removeTransition.ts';
import getChatTemplates from '../../../requests/getChatTemplates';

class ChatTemplatePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            areaHeight: 170,
        };

        this.changeTemplate = this.changeTemplate.bind(this);
        this.renderTag = this.renderTag.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    renderTag({ item }) {
        return (
            <div className="crmPopup__tag">
                <div className="tag _full" style={{ background: item.color }}>
                    {item.name}
                </div>
            </div>
        );
    }

    setAreaHeight() {
        const field = this.parent.current.querySelector('.crmPopup__field._content');
        const params = getRealParams({
            parent: field,
            elem: '.field__input',
            width: field.offsetWidth,
            isClearStyles: true,
        });

        const resultHeight = params.scrollHeight > 170 ? params.scrollHeight : 170;

        this.setState({ areaHeight: resultHeight });
    }

    changeTemplate({ values }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const template = { ...newState.template };

                    values.forEach(({ value, key, checkArray, arrayProp }) => {
                        const { item, lastKey } = getDeepValue({ item: template, name: key });

                        if (checkArray) {
                            const itemIndex = arrayProp
                                ? item[lastKey].findIndex(
                                      (innerItem) => innerItem[arrayProp] === value[arrayProp],
                                  )
                                : item[lastKey].indexOf(value);

                            if (itemIndex === -1) {
                                item[lastKey].push(value);
                            } else {
                                item[lastKey].splice(itemIndex, 1);
                            }
                        } else {
                            item[lastKey] = value;
                        }
                    });

                    newState.template = template;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    formData = new FormData();

    handlerFile = new HandlerFile({ typeOfFiles: 'media' });

    deletedFiles = [];

    deleteFile({ id }) {
        const { template } = this.state;
        const file = template.files.find((item) => item._id === id);

        if (file.isLocal) {
            this.formData.delete(file._id);
        } else {
            this.deletedFiles.push(file._id);
        }

        this.changeTemplate({
            values: [{ key: 'files', value: file, checkArray: true, arrayProp: '_id' }],
        });
    }

    renderFile({ item }) {
        const { fromTemplate } = item;

        return (
            <div className="crmPopup__file">
                <FileItem
                    withOpen={true}
                    file={item}
                    deleteFile={!fromTemplate ? this.deleteFile : null}
                />
            </div>
        );
    }

    uploadFiles({ target }) {
        const { template } = this.state;

        if (template.files.length + target.files.length > 6) {
            target.value = null;

            setNotification({ notification: 'max-limit-counter-files-6' });
        } else {
            this.handlerFile
                .uploadFiles({
                    target,
                    getName: (key) => `${key}-${new Date().getTime()}`,
                    formData: this.formData,
                })
                .then(({ resultFiles }) => {
                    target.value = null;

                    const files = JSON.parse(JSON.stringify(template.files));

                    files.push(
                        ...resultFiles.map((file) => ({
                            _id: file.key,
                            size: file.size,
                            name: file.name,
                            type: file.type,
                            isLocal: file.isLocal,
                        })),
                    );

                    this.changeTemplate({ values: [{ key: 'files', value: files }] });
                });
        }
    }

    renderEmodzi() {
        const { template, showEmodziList } = this.state;
        const area = this.parent.current?.querySelector('textarea');

        return (
            <div className="crmPopup__inputEmodzi">
                <div
                    className="crmPopup__inputEmodziBtn _click"
                    onClick={(e) => {
                        e.stopPropagation();

                        if (!this.checkDisabled()) {
                            this.setState({
                                showEmodziList: !this.state.showEmodziList,
                            });
                        }
                    }}
                >
                    <Icon name="emodzi" />
                </div>
                <Animate className="crmPopup__inputEmodziList" isShow={showEmodziList}>
                    <EmodziList
                        className="_minHeight"
                        addEmodzi={(emodzi) => {
                            const currentPosition =
                                area?.selectionEnd || template?.content?.length || 0;
                            let resultValue = emodzi;

                            if (currentPosition > 0) {
                                resultValue = template.content.slice(0, currentPosition);

                                resultValue += emodzi;

                                resultValue += template.content.slice(currentPosition);
                            }

                            this.changeTemplate({
                                values: [
                                    {
                                        key: 'content',
                                        value: resultValue,
                                    },
                                ],
                            }).then(() => {
                                area.focus();
                                area.selectionEnd = currentPosition + emodzi.length;
                            });
                        }}
                        input={area}
                        startPosition={area?.selectionEnd || template?.content?.length || 0}
                        dirClassName="_topRight"
                        hide={() => {
                            this.setState({
                                showEmodziList: false,
                            });
                        }}
                    />
                </Animate>
            </div>
        );
    }

    setTemplate({ template }) {
        return new Promise((resolve) => {
            this.setState(
                {
                    template,
                    savedTemplate: JSON.parse(JSON.stringify(template)),
                    isReady: true,
                },
                resolve,
            );
        });
    }

    getTemplate() {
        const id = this.getId();

        getChatTemplates({ id }).then(({ chatTemplate }) => {
            if (chatTemplate) {
                chatTemplate.files.forEach((file) => {
                    file.src = `${process.env.REACT_APP_STATIC}/chat-template/${file.path}`;
                });
            }

            this.setTemplate({ template: chatTemplate }).then(() => {
                this.setAreaHeight();

                removeTransition({ item: '.crmPopup__fieldButton' });
            });
        });
    }

    getTemplateData() {
        const { template } = this.state;

        return {
            _id: template._id,
            name: template.name,
            content: template.content,
            tags: template.tags,
        };
    }

    getActions() {
        const id = this.getId();

        const actions = [{ key: 'files' }];

        if (id === 'new') {
            actions.unshift({ key: 'template' });
        }

        return actions;
    }

    getId() {
        const { levels } = this.props;

        return levels[2];
    }

    checkDisabled() {
        const { template } = this.state;

        return template?.inProcess;
    }

    checkChange() {
        const { template, savedTemplate } = this.state;
        const id = this.getId();
        let isChange = id === 'new';

        if (savedTemplate) {
            ['name', 'content'].forEach((name) => {
                if (
                    getDeepValue({ item: template, name }).value !==
                    getDeepValue({ item: savedTemplate, name }).value
                ) {
                    isChange = true;
                }
            });

            ['tags'].forEach((name) => {
                if (
                    checkChangeArray({
                        array: getDeepValue({ item: template, name }).value,
                        targetArray: getDeepValue({ item: savedTemplate, name }).value,
                    })
                ) {
                    isChange = true;
                }
            });

            if (this.deletedFiles.length || template.files.find((file) => file.isLocal)) {
                isChange = true;
            }

            if (!template.name || !template.content) {
                isChange = false;
            }
        }

        return isChange;
    }

    save() {
        const id = this.getId();
        const template = this.getTemplateData();

        this.formData.set('id', id);
        this.formData.set('deletedFiles', JSON.stringify(this.deletedFiles));
        this.formData.set('fields', JSON.stringify(template));

        handlerLoading.call(this, 'save').then(() => {
            axios[id === 'new' ? 'post' : 'patch'](
                `${process.env.REACT_APP_API}/chat-template`,
                getUpdateFormData(this.formData),
                {
                    headers: getHeaders(),
                },
            ).then(
                (res) => {
                    handlerLoading.call(this, null);

                    const { success, data } = res.data;

                    if (success) {
                        const { id: newId } = data;

                        this.deletedFiles = [];
                        this.formData = new FormData();

                        this.hide();

                        if (newId) {
                            setNotification({ notification: 'success-save-info' });
                        } else {
                            setNotification({ notification: 'success-change-info' });
                        }

                        document.dispatchEvent(new CustomEvent('updateChatTemplate'));
                    }
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    delete({ hide, handlerLoader }) {
        const id = this.getId();

        axios
            .delete(`${process.env.REACT_APP_API}/chat-template?id=${id}`, {
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-change-info' });

                        document.dispatchEvent(new CustomEvent('updateChatTemplate'));

                        hide();

                        this.hide();
                    } else {
                        handlerLoader(false);
                    }
                },
                () => {
                    handlerLoader(false);
                },
            );
    }

    hide() {
        changePage(getNotPopupPage());
    }

    componentDidMount() {
        this.getTemplate();

        this.setState({ id: this.getId() });
    }

    render() {
        const { template, areaHeight, loadingKey, isReady, showDeleteWindow } = this.state;
        const id = this.getId();

        return (
            <div
                ref={this.parent}
                className={`crmPopup _col _chatTemplate ${isReady ? '_ready' : ''}`}
            >
                <div className="crmPopup__inner">
                    <div className="crmPopup__head">
                        <AnimateChangeUp
                            className="crmPopup__title _dynamic"
                            renderKey={`${id}${id === 'new' ? '' : !!template}`}
                            isNotNullParentSize={true}
                        >
                            {id === 'new' ? 'Создание нового шаблона' : <>Редактирование шаблона</>}
                        </AnimateChangeUp>
                        <i className="crmPopup__close _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className={`crmPopup__content ${isReady ? '_ready' : ''}`}>
                        <Animate className="crmPopup__loader _loader" isShow={!isReady}>
                            <div className="crmPopup__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="crmPopup__contentScroll">
                            <div className="crmPopup__field _col">
                                <div className="crmPopup__fieldSupport">Название шаблона:</div>
                                <div className="crmPopup__fieldBox">
                                    <div className="crmPopup__input">
                                        <Field
                                            type="template"
                                            keyName="anyText"
                                            name="name"
                                            value={template?.name}
                                            callback={({ action, value }) => {
                                                if (action === 'change') {
                                                    this.changeTemplate({
                                                        values: [
                                                            {
                                                                key: 'name',
                                                                value,
                                                            },
                                                        ],
                                                    });
                                                }
                                            }}
                                            className="_grey"
                                            isDisabled={this.checkDisabled()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _col _content">
                                <div className="crmPopup__fieldSupport">Текст шаблона:</div>
                                <div className="crmPopup__fieldBox">
                                    <div
                                        className="crmPopup__input _area _content"
                                        style={{ height: `${areaHeight}px` }}
                                    >
                                        {this.renderEmodzi()}
                                        <Field
                                            type="template"
                                            keyName="message"
                                            name="content"
                                            value={template?.content}
                                            callback={({ action, value }) => {
                                                if (action === 'change') {
                                                    this.changeTemplate({
                                                        values: [
                                                            {
                                                                key: 'content',
                                                                value,
                                                            },
                                                        ],
                                                    }).then(() => {
                                                        this.setAreaHeight();
                                                    });
                                                }
                                            }}
                                            className="_grey"
                                            stopPropagation={true}
                                            isDisabled={this.checkDisabled()}
                                        />
                                    </div>
                                    <ListAbsoluteMain
                                        className="crmPopup__files"
                                        items={template?.files || []}
                                        renderItem={this.renderFile}
                                        classNameItem="crmPopup__file"
                                        prop="_id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                    <div className="crmPopup__actions _row">
                                        <div className="crmPopup__action _file">
                                            <label className="crmPopup__actionInner _row _click">
                                                <input
                                                    type="file"
                                                    onChange={this.uploadFiles}
                                                    multiple
                                                    disabled={this.checkDisabled()}
                                                />
                                                <i className="crmPopup__actionIcon">
                                                    <Icon name="chat-attach" />
                                                </i>
                                                Прикрепить файл
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="crmPopup__field _row">
                                <p className="crmPopup__fieldSupport">Теги шаблона:</p>
                                <div className="crmPopup__fieldBox _withAbsoluteBtn _col">
                                    <ListAbsoluteMain
                                        className="crmPopup__tags"
                                        items={template?.tagsInfo}
                                        renderItem={this.renderTag}
                                        classNameItem="crmPopup__tag"
                                        prop="id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                    />
                                    <div
                                        className={`crmPopup__fieldButton _row _absolute _click ${
                                            template?.tags.length ? '_active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!this.checkDisabled()) {
                                                handlerPopup({
                                                    name: 'tagsPopup',
                                                    isShow: true,
                                                    ids: template?.tags,
                                                    callback: (newTags) => {
                                                        this.changeTemplate({
                                                            values: [
                                                                {
                                                                    key: 'tags',
                                                                    value: newTags.map(
                                                                        (tag) => tag.id,
                                                                    ),
                                                                },
                                                                { key: 'tagsInfo', value: newTags },
                                                            ],
                                                        });
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <AnimateChangeUp
                                            className="crmPopup__fieldButtonInner _center"
                                            renderKey={template?.tags.length ? 1 : 0}
                                            isNotNullParentSize={true}
                                        >
                                            {template?.tags.length > 0
                                                ? 'Изменить теги'
                                                : 'Выбрать теги'}
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="crmPopup__foot _row">
                        <div className="crmPopup__footButtons _row">
                            {!this.checkDisabled() && (
                                <>
                                    <div className="crmPopup__footButton">
                                        <Button
                                            className="_medium2Size"
                                            onClick={this.save}
                                            showLoader={loadingKey === 'save'}
                                            isDisabled={!this.checkChange()}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                    {this.state.id !== 'new' &&
                                        this.getPermissions({
                                            key: 'chat',
                                            items: [{ key: 'templates', rules: ['delete'] }],
                                        }) && (
                                            <div className="crmPopup__footButton">
                                                <Animate
                                                    className="crmPopup__footButtonWindow"
                                                    isShow={showDeleteWindow}
                                                >
                                                    <WindowPrompt
                                                        className="_bottomRight"
                                                        name="delete"
                                                        callback={this.delete}
                                                        hide={() => {
                                                            this.setState({
                                                                showDeleteWindow: false,
                                                            });
                                                        }}
                                                    />
                                                </Animate>

                                                <Button
                                                    className="_medium2Size _alertLight"
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeleteWindow:
                                                                !this.state.showDeleteWindow,
                                                        });
                                                    }}
                                                    showLoader={loadingKey === 'delete'}
                                                >
                                                    Удалить
                                                </Button>
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ChatTemplatePopup);

ChatTemplatePopup.propTypes = {
    levels: PropTypes.array,
    user: PropTypes.object,
};
