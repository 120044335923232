import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import AnimateChange from '../AnimateChange.jsx';
import Animate from '../Animate.jsx';
import Loader from '../Loader.jsx';

import getHeaders from '../../functions/getHeaders';
import getFormatPrice from '../../functions/getFormatPrice';
import getCorporationCommission from '../../functions/crm/getCorporationCommission';

const orderServiceCompanies = require('../../infos/crm/orderServiceCompanies.json');

class DetailsServicesCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getInfo = this.getInfo.bind(this);
    }

    getCompany() {
        const { name } = this.props;

        return orderServiceCompanies.find((item) => item.key === name);
    }

    getInfo() {
        const { name, setInfo, isInner, order, points, city } = this.props;

        return new Promise((resolve) => {
            if (isInner) {
                resolve();
            } else {
                this.setState({ isSearch: true }, () => {
                    if (this.controller) {
                        this.controller.abort();
                    }

                    this.controller = new AbortController();

                    axios
                        .post(
                            `${process.env.REACT_APP_API}/order-service-companies`,
                            {
                                name,
                                order: {
                                    dateOfOrder: order.dateOfOrder,
                                    tariffId: order.tariff.id,
                                    city,
                                    route: points.map((point) => ({
                                        address: point.address,
                                        house: point.house,
                                        coords: point.coords,
                                        addressatInfo: {
                                            name: point.addressatInfo.name,
                                            phone: point.addressatInfo.phone,
                                            entrance: point.addressatInfo.entrance,
                                            floor: point.addressatInfo.floor,
                                            room: point.addressatInfo.room,
                                        },
                                        cargo: point.cargo.map((cargo) => ({
                                            comment: cargo.comment,
                                            items: cargo.items
                                                .filter((item) => item.counter > 0)
                                                .map((item) => ({
                                                    name: item.name,
                                                    long: item.long,
                                                    width: item.width,
                                                    height: item.height,
                                                    weight: item.weight,
                                                    counter: item.counter,
                                                })),
                                        })),
                                    })),
                                    services: order.services,
                                },
                            },
                            {
                                signal: this.controller.signal,
                                headers: getHeaders(),
                            },
                        )
                        .then((res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { price, duration, serviceOtherInfo } = data;

                                setInfo({
                                    name,
                                    price,
                                    duration,
                                    isError: false,
                                    serviceOtherInfo,
                                }).then(() => {
                                    document.dispatchEvent(
                                        new CustomEvent('serviceCompanySetInfo'),
                                    );

                                    this.setState({ isSearch: false });
                                });
                            } else {
                                setInfo({ name, isError: true }).then(() => {
                                    document.dispatchEvent(
                                        new CustomEvent('serviceCompanySetInfo'),
                                    );

                                    this.setState({ isSearch: false });
                                });
                            }

                            resolve();
                        }, resolve);
                });
            }
        });
    }

    getDuration() {
        const { duration = 0 } = this.props;

        if (!duration) {
            return '–';
        }

        if (duration > 60) {
            const hours = Math.floor(duration / 60);
            const minutes = duration - hours * 60;

            if (minutes === 0) {
                return `≈${hours}ч`;
            }

            return `≈${hours}ч${minutes}м`;
        }

        return `≈${duration}мин`;
    }

    componentDidMount() {
        this.getInfo();

        document.addEventListener('serviceCompanyUpdateInfo', this.getInfo);
    }

    componentWillUnmount() {
        document.removeEventListener('serviceCompanyUpdateInfo', this.getInfo);
    }

    render() {
        const { isSearch } = this.state;
        const { isBest, isCurrent, setCurrent, name, order, price = 0, isError } = this.props;
        const { corporationTariff } = order;
        const company = this.getCompany();

        const resultPrice = isError
            ? 0
            : +price +
              getCorporationCommission({
                  tariff: corporationTariff,
                  name: 'serviceOrder',
                  amount: +price,
              });

        return (
            <div
                className={`orderDetailsServiceCompany _click ${isSearch ? '_search' : ''} ${
                    isCurrent ? '_current' : ''
                } ${isBest ? '_best' : ''} ${isError ? '_error' : ''}`}
                onClick={() => {
                    setCurrent(name);
                }}
            >
                <Animate className="orderDetailsServiceCompany__status" isShow={isBest}>
                    Самый выгодный
                </Animate>
                <div className="orderDetailsServiceCompany__head">
                    <img
                        src={require(`../../img/crm/order-services-companies/${company?.logo}`)}
                        alt=""
                        className="orderDetailsServiceCompany__logo"
                    />
                </div>
                <div className="orderDetailsServiceCompany__content">
                    <Animate
                        className="orderDetailsServiceCompany__loader _loader"
                        isShow={isSearch}
                    >
                        <i className="orderDetailsServiceCompany__loaderIcon _loaderItem">
                            <Loader />
                        </i>
                        Поиск...
                    </Animate>
                    <div className="orderDetailsServiceCompany__result _row">
                        <div className="orderDetailsServiceCompany__resultPrice">
                            <AnimateChange
                                className="orderDetailsServiceCompany__resultPriceInner"
                                prop={resultPrice}
                                type="_translateMedium"
                            >
                                <>{getFormatPrice(resultPrice || 0)}₽</>
                            </AnimateChange>
                        </div>
                        <div className="orderDetailsServiceCompany__resultDuration">
                            <AnimateChange
                                className="orderDetailsServiceCompany__resultDurationInner"
                                prop={this.getDuration()}
                                type="_translateMedium"
                            >
                                <>{this.getDuration()}</>
                            </AnimateChange>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsServicesCompany);

DetailsServicesCompany.propTypes = {
    name: PropTypes.string,
    isBest: PropTypes.bool,
    isCurrent: PropTypes.bool,
    setCurrent: PropTypes.func,
    price: PropTypes.number,
    duration: PropTypes.number,
    setInfo: PropTypes.func,
    isInner: PropTypes.bool,
    order: PropTypes.object,
    points: PropTypes.array,
    city: PropTypes.string,
    isError: PropTypes.bool,
};
