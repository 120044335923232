import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Filter from '../../../components/Filter.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import Windows from '../../../components/Windows.jsx';
import WindowPrompt from '../../../components/WindowPrompt.jsx';

import handlerWindow from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import Main from './googleVision/Main.jsx';
import List from './googleVision/List.jsx';
import Inner from './googleVision/Inner.jsx';

import EditPanel from '../../../components/crm/content/EditPanel.jsx';
import getFormatedDate from '../../../functions/getFormatedDate';
import setPermissions from '../../../functions/crm/setPermissions';

class ContentGoogleVision extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.renderGoogleVisionWordsFilter = this.renderGoogleVisionWordsFilter.bind(this);

        this.renderEditPanel = this.renderEditPanel.bind(this);
        this.renderWindowsPromptGroup = this.renderWindowsPromptGroup.bind(this);
        this.setDeleteWordRequest = this.setDeleteWordRequest.bind(this);
        this.initCallbackGoogleVisionWordsFilter =
            this.initCallbackGoogleVisionWordsFilter.bind(this);

        this.setFilter = this.setFilter.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.checkRights = this.checkRights.bind(this);

        this.parent = React.createRef();

        setPermissions.call(this);
    }

    wordProps = ['key', 'translation', 'long', 'width', 'height', 'weight'];

    headPages = {
        'content-googleVision-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Google Vision{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'content-googleVision-list': {
            render() {
                return (
                    <div className="widget__headNameItemContent">
                        Google Vision – Добавленные слова
                    </div>
                );
            },
        },
        'content-googleVision-inner': {
            render() {
                const { infoHead } = this.state;
                const { group } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Google Vision – фото от{' '}
                        {group && getFormatedDate({ date: new Date(group.dateOfUpload) })}
                    </div>
                );
            },
        },
    };

    headActions = {
        'content-googleVision-main': {
            render() {
                const { filter } = this.state;
                const { checkRights } = this.props;

                return (
                    <TableHead
                        name="content-googleVision"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        checkRights={checkRights}
                    />
                );
            },
        },
        'content-googleVision-list': {
            render() {
                return null;
            },
        },
        'content-googleVision-inner': {
            render() {
                return null;
            },
        },
    };

    pages = {
        'content-googleVision-main': {
            render() {
                const { isShowLoaderList, filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setInfoHead={this.setInfoHead}
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._content-googleVision-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    handlerLoaderList={this.handlerLoaderList}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    checkRights={this.checkRights}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'content-googleVision-list': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <List
                                    setInfoHead={this.setInfoHead}
                                    checkNew={this.checkNew}
                                    getParent={() => this.parent.current}
                                    checkRights={this.checkRights}
                                    wordProps={this.wordProps}
                                    setDeleteWordRequest={this.setDeleteWordRequest}
                                    initCallbackGoogleVisionWordsFilter={
                                        this.initCallbackGoogleVisionWordsFilter
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'content-googleVision-inner': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Inner
                                    setInfoHead={this.setInfoHead}
                                    checkNew={this.checkNew}
                                    getParent={() => this.parent.current}
                                    checkRights={this.checkRights}
                                    wordProps={this.wordProps}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    checkRights() {
        return this.getPermissions({
            key: 'content',
            items: [{ key: 'google', rules: ['update'] }],
        });
    }

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfArticle = levels[3];

        if (
            levels[1] === 'content' &&
            levels[2] === 'info-system' &&
            idOfArticle !== 'main' &&
            idOfArticle
        ) {
            this.isNew = idOfArticle === 'new';
        }

        return this.isNew;
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'googleVision' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    initCallbackGoogleVisionWordsFilter(callbackGoogleVisionWordsFilter) {
        this.callbackGoogleVisionWordsFilter = callbackGoogleVisionWordsFilter;
    }

    renderGoogleVisionWordsFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackGoogleVisionWordsFilter}
            />
        );
    }

    setDeleteWordRequest(deleteWordRequest) {
        this.deleteWordRequest = deleteWordRequest;
    }

    renderWindowsPromptGroup({ userId }) {
        const deleteWord = ({ hide }) => {
            this.deleteWordRequest({ id: userId, hide });
        };

        return <WindowPrompt className="_topRight" callback={deleteWord} name="delete" />;
    }

    renderEditPanel({ selection, handlerEditText }) {
        return <EditPanel selection={selection} callback={handlerEditText} />;
    }

    componentDidMount() {
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => null,
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows
                    name="googleVisionWordsFilter"
                    renderContent={this.renderGoogleVisionWordsFilter}
                />
                <Windows name="editPanel" renderContent={this.renderEditPanel} />
                <Windows name="promptDeleteGroup" renderContent={this.renderWindowsPromptGroup} />
                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Контент"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'content-googleVision' &&
                                        page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'content-googleVision' &&
                                        page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === 'content-googleVision' && page.level === 3
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ContentGoogleVision);

ContentGoogleVision.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    user: PropTypes.object,
};
