import BusinessAdvantagesI from '../types.ts';

const inputHandler: BusinessAdvantagesI['inputHandler'] = function ({ name, value }) {
    this.setState((state) => {
        const newState = { ...state };
        const fields = { ...newState.fields };

        fields[name as keyof typeof fields] = value as string;

        newState.fields = fields;

        return newState;
    });
};

export default inputHandler;
