import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

class ManualExecutorsPassport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    fields = [
        { name: 'country' },
        {
            name: 'dateGet',
            max: new Date(),
        },
        { name: 'nameGet' },
        { name: 'view' },
        { name: 'dateAction', isReadOnly: true },
        { name: 'addressReg' },
        { name: 'series' },
        { name: 'code' },
        { name: 'addressFact', withAddressList: true },
        { name: 'number' },
        {
            name: 'birthday',
            max: (() => {
                const date = new Date();

                date.setFullYear(date.getFullYear() - 16);
                date.setMonth(11);
                date.setDate(31);

                return date;
            })(),
        },
    ];

    getDescriptionInfo() {
        const { executor = {} } = this.props;
        const data = [];

        if (executor.passport?.series) {
            data.push(executor.passport.series);
        }

        if (executor.passport?.number) {
            data.push(executor.passport.number);
        }

        if (data.length) {
            return {
                description: data.join(' '),
            };
        }

        return {
            description: '–',
        };
    }

    render() {
        const {
            isNew,
            title,
            executor = {},
            changeExecutor,
            checkEditmode,
            errors = [],
        } = this.props;
        const { description, className: classNameDescription } = this.getDescriptionInfo();

        return (
            <div className="manualCard _executorPassport">
                <div className="manualCard__head _row">
                    <Head
                        title={title}
                        description={description}
                        classNameDescription={classNameDescription}
                    />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, ...propsField } = field;
                            let { isReadOnly } = propsField;
                            const { [name]: value } = executor.passport || {};

                            if (
                                name === 'dateAction' &&
                                executor?.passport.country &&
                                executor?.passport.country !== 'ru' &&
                                checkEditmode()
                            ) {
                                isReadOnly = false;
                            }

                            if (
                                !isNew &&
                                name === 'country' &&
                                ['IP', 'SMZ'].includes(executor.organization)
                            ) {
                                isReadOnly = true;
                            }

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="executor"
                                        name={name}
                                        handler={async (props) =>
                                            changeExecutor({ ...props, type: 'passport' })
                                        }
                                        group="executorPassport"
                                        isDisabled={!checkEditmode()}
                                        isError={errors.indexOf(name) !== -1}
                                        {...propsField}
                                        isReadOnly={isReadOnly}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsPassport);

ManualExecutorsPassport.propTypes = {
    executor: PropTypes.object,
    title: PropTypes.string,
    changeExecutor: PropTypes.func,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    isNew: PropTypes.bool,
};
