import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import SignContractPopup from './SignContractPopup.jsx';

class SignContractPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { signContractPopup } = this.props;

        return (
            <Animate className="body__uploadOrdersPopup" isShow={signContractPopup?.isShow}>
                <SignContractPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        signContractPopup: state.signContractPopup,
    };
}

export default connect(mapStateToProps)(SignContractPopupWrapper);

SignContractPopupWrapper.propTypes = {
    signContractPopup: PropTypes.object,
};
