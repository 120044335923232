import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Pages from '../../components/Pages.jsx';

import Registry from './reports/Registry.jsx';

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = {
        'reports-registry': {
            render() {
                return (
                    <Registry
                        name="registry"
                        parentKey="reports-registry"
                        keys={{
                            'reports-registry-index': 'all',
                            'reports-registry-executors': 'executors',
                            'reports-registry-clients': 'clients',
                        }}
                    />
                );
            },
        },
        'reports-servicesRegistry': {
            render() {
                return (
                    <Registry
                        name="servicesRegistry"
                        parentKey="reports-servicesRegistry"
                        keys={{
                            'reports-servicesRegistry-index': 'services',
                        }}
                    />
                );
            },
        },
    };

    render() {
        return (
            <div ref={this.parent} className="reports">
                <div className="reports__inner">
                    <Pages
                        classNamePage="reports__page"
                        filter={(page) => page.parentName === 'reports'}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Reports);

Reports.propTypes = {
    theme: PropTypes.string,
};
