import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import Main from './contracts/Main.jsx';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import setPermissions from '../../../functions/crm/setPermissions';
import setContractsParent from '../../../functions/crm/setContractsParent';

class JoinsContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.delete = this.delete.bind(this);

        setContractsParent.call(this);
        setPermissions.call(this);

        this.parent = React.createRef();
    }

    headPages = {
        'joins-contracts-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Договоры{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    headActions = {
        'joins-contracts-main': {
            render() {
                return <TableHead name="joinsContracts" getParent={() => this.parent.current} />;
            },
        },
    };

    pages = {
        'joins-contracts-main': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    getParent={() => this.parent.current}
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { joinDoc } = infoHead;
        const id = joinDoc?._id;
        const windowId = `delete-joinDoc-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/join-doc?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            const windowName = !success ? 'deleteExecutorInfo' : 'promptDelete';

                            if (!isForce) {
                                handlerWindow({
                                    parent: this.parent.current,
                                    parentResize: this.parent.current,
                                    target: this.parent.current.querySelector(
                                        '.widget__headLink._delete',
                                    ),
                                    action: 'show',
                                    name: windowName,
                                    className: '_prompt _center',
                                    uniqKey: windowId,
                                    watchesProps: { left: true },
                                    centers: {
                                        left: 0.5,
                                        top: 1,
                                    },
                                });
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });
                            }

                            if (success) {
                                if (isForce) {
                                    setNotification({ notification: 'success-change-info' });
                                }

                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        return !this.props.disabled && !nextProps.disabled;
    }

    componentDidMount() {
        this.setHeightPage();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: 'filter-tags', filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="contractActions" renderContent={this.renderContractActions} />
                <Windows name="contractPrompt" renderContent={this.renderContractPrompt} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Подключение"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) => page.parentName === 'joins-contracts'}
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) => page.parentName === 'joins-contracts'}
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) => page.parentName === 'joins-contracts'}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinsContracts);

JoinsContracts.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    windows: PropTypes.object,
    user: PropTypes.object,
    disabled: PropTypes.bool,
};
