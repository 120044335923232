import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../../../../components/Icon.jsx';

import handlerPopup from '../../../../../functions/app/handlerPopup';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import handlerWindow from '../../../../../functions/handlerWindow';

import getHeaders from '../../../../../functions/getHeaders';
import handlerLoading from '../../../../../functions/handlerLoading';
import setNotification from '../../../../../functions/setNotification';
import getEndText from '../../../../../functions/getEndText';

class ManualExecutorsInnerWallets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.showPopup = this.showPopup.bind(this);
    }

    getExecutor() {
        const { getExecutor } = this.props;

        return new Promise((resolve) => {
            getExecutor().then(() => {
                this.setState({ walletsUpdateKey: new Date().getTime() }, resolve);
            });
        });
    }

    showPopup({ wallet }) {
        const { executor } = this.props;
        const { organization, currentWallet } = executor;
        const walletIsCurrent = currentWallet === wallet?._id;

        handlerPopup({
            name: 'walletsPopup',
            isShow: true,
            executorId: executor._id,
            executorOrganization: organization,
            wallet,
            callback: this.getExecutor.bind(this),
            walletIsCurrent,
        });
    }

    deleteWallet(_id) {
        const { executor, getExecutor } = this.props;

        return new Promise((resolve, reject) => {
            handlerLoading.call(this, _id).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/executor`,
                        {
                            action: 'handler-wallet',
                            id: executor._id,
                            walletId: _id,
                            isDelete: true,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                getExecutor().then(() => {
                                    handlerLoading.call(this, null);

                                    setNotification({ notification: 'success-change-info' });

                                    resolve();
                                });
                            } else {
                                reject();

                                handlerLoading.call(this, null);
                            }
                        },
                        () => {
                            reject();

                            handlerLoading.call(this, null);
                        },
                    );
            });
        });
    }

    handlerDelete(_id, e) {
        const target = e.target.closest('.manualWallets__cardAction');
        const { getParent } = this.props;
        const parent = getParent();

        handlerWindow({
            parent,
            parentResize: parent,
            target,
            action: 'show',
            name: 'deleteWallet',
            className: '_prompt _right _bottom',
            uniqKey: `prompt-${_id}`,
            centers: {
                left: 0.5,
                top: 1,
            },
            deleteWallet: this.deleteWallet.bind(this, _id),
        });
    }

    renderCard({ item: wallet }) {
        if (wallet._id === 'empty') {
            return (
                <div className="manualWallets__card">
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">Способов оплаты нет</div>
                            <p className="empty__content">
                                Чтобы добавить способ
                                <br />
                                нажмите на кнопку {'"'}+ Способ оплаты{'"'}
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        const { executor, checkRights } = this.props;
        const isCurrent = wallet._id === executor.currentWallet;

        return (
            <div className={`manualWallets__card _${wallet.type} ${isCurrent ? '_current' : ''}`}>
                <div className="manualWallets__cardInner">
                    <div className="manualWallets__cardItems _row">
                        {wallet.type === 'card' && (
                            <>
                                <div
                                    className={`manualWallets__cardItem _col ${
                                        wallet.number ? '_short' : ''
                                    }`}
                                >
                                    <div className="manualWallets__cardValue">
                                        {wallet.number ? (
                                            <>*{wallet.number?.replace(/[^\d]/gi, '')?.slice(-4)}</>
                                        ) : (
                                            <>–</>
                                        )}
                                    </div>
                                </div>
                                <div className="manualWallets__cardItem _col">
                                    <div className="manualWallets__cardValue">Банковская карта</div>
                                </div>
                                <div className="manualWallets__cardItem _col">
                                    <div className="manualWallets__cardValue">
                                        {wallet.number || '–'}
                                    </div>
                                </div>

                                <div className="manualWallets__cardItem _current _col">
                                    <div className="manualWallets__cardValue">Основной счёт</div>
                                </div>
                            </>
                        )}
                        {wallet.type === 'account' && (
                            <>
                                <div className="manualWallets__cardItem _col">
                                    <div className="manualWallets__cardSupport">БИК</div>
                                    <div className="manualWallets__cardValue">{wallet.bic}</div>
                                </div>
                                <div className="manualWallets__cardItem _col">
                                    <div className="manualWallets__cardSupport">Получатель</div>
                                    <div className="manualWallets__cardValue">
                                        {wallet.addressatName}
                                    </div>
                                </div>
                                <div className="manualWallets__cardItem _col">
                                    <div className="manualWallets__cardSupport">Банк. счёт</div>
                                    <div className="manualWallets__cardValue">{wallet.number}</div>
                                </div>

                                <div className="manualWallets__cardItem _current _col">
                                    <div className="manualWallets__cardSupport">&nbsp;</div>
                                    <div className="manualWallets__cardValue">Основной счёт</div>
                                </div>
                                <div className="manualWallets__cardItem _col _bankName">
                                    <div className="manualWallets__cardSupport">
                                        Наименование банка
                                    </div>
                                    <div className="manualWallets__cardValue">
                                        {wallet.bankName}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {checkRights?.(['changeWallet']) && (
                        <div className="manualWallets__cardActions _row">
                            <div
                                className="manualWallets__cardAction _click"
                                onClick={() => {
                                    this.showPopup({ wallet });
                                }}
                            >
                                <Icon name="actions-edit" />
                            </div>
                            <div
                                className="manualWallets__cardAction _click"
                                onClick={this.handlerDelete.bind(this, wallet._id)}
                            >
                                <Icon name="actions-delete" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    getWallets() {
        const { executor } = this.props;
        const { wallets } = executor;

        if (wallets.length) {
            return wallets;
        }

        return [{ _id: 'empty' }];
    }

    render() {
        const { walletsUpdateKey } = this.state;
        const { executor, checkRights } = this.props;
        const { wallets } = executor;

        return (
            <>
                <div className="manualWallets">
                    <div className="manualCard _wallets">
                        <div className="manualCard__head _row">
                            <div className="manualCard__headTitle">Добавленные способы оплаты:</div>
                            <div className="manualCard__headDescription _parent">
                                {wallets.length}{' '}
                                {getEndText(wallets.length, ['способ', 'способа', 'способов'])}
                            </div>
                            {checkRights?.(['changeWallet']) && (
                                <div className="manualCard__headInfo">
                                    <div
                                        className="manualCard__headLink _main _click"
                                        onClick={() => {
                                            this.showPopup({});
                                        }}
                                    >
                                        + Способ оплаты
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="manualCard__content">
                            <div className="manualWallets">
                                <ListAbsoluteMain
                                    className="manualWallets__cards"
                                    items={this.getWallets()}
                                    renderItem={this.renderCard.bind(this)}
                                    classNameItem="manualWallets__card"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    keyUpdateItem={walletsUpdateKey}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsInnerWallets);

ManualExecutorsInnerWallets.propTypes = {
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    parentScroll: PropTypes.object,
    executor: PropTypes.object,
    getExecutor: PropTypes.func,
    checkRights: PropTypes.func,
};
