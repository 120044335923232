import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FeedbackCard from '../../order/FeedbackCard.jsx';
import FeedbackStars from '../../order/FeedbackStars.jsx';

import setSpacesInText from '../../../functions/setSpacesInText';
import ImageLazy from '../../ImageLazy.jsx';
import Icon from '../../Icon.jsx';
import handlerPopup from '../../../functions/handlerPopup';

class FeedbackOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { rate = 0, keyName, comment, file } = this.props;

        return (
            <div className="feedbackOrder _windowWithCursor _centerRight">
                <div className="feedbackOrder__inner _col">
                    <div className="feedbackOrder__head">
                        <p className="feedbackOrder__title">Оценка клиента</p>
                        <div className="feedbackOrder__stars">
                            <FeedbackStars rate={rate} />
                        </div>
                    </div>
                    {keyName === 'comment' ? (
                        <>
                            <div className="feedbackOrder__comment">
                                <div
                                    className="feedbackOrder__commentInner"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(comment).replace(/\n/g, '<br/>'),
                                    }}
                                ></div>
                            </div>
                            {file?.path && (
                                <div
                                    className="feedbackOrder__image _click"
                                    onClick={() => {
                                        handlerPopup({
                                            action: 'show',
                                            name: 'popupGalery',
                                            images: [
                                                {
                                                    path: `${process.env.REACT_APP_STATIC}/order-feedbacks/${file.path}`,
                                                    type: file.type,
                                                },
                                            ],
                                            title: 'Фото к отзыву на точке',
                                        });
                                    }}
                                >
                                    <i className="feedbackOrder__imageIcon">
                                        <Icon name="camera-full" />
                                    </i>
                                    <ImageLazy
                                        className="feedbackOrder__imageItem"
                                        src={`${process.env.REACT_APP_STATIC}/order-feedbacks/${file.path}`}
                                    ></ImageLazy>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="feedbackOrder__result">
                                <FeedbackCard name={keyName} className="_grey" />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(FeedbackOrder);

FeedbackOrder.propTypes = {
    rate: PropTypes.number,
    keyName: PropTypes.string,
    comment: PropTypes.string,
    file: PropTypes.object,
};
