import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import FileClass from '../../../../../classes/File';

import Field from '../../../../../components/Field.jsx';
import Icon from '../../../../../components/Icon.jsx';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import Search from '../../../../../components/Search.jsx';
import Animate from '../../../../../components/Animate.jsx';
import scrollToPosition from '../../../../../functions/scrollToPosition';
import copyInBuffer from '../../../../../functions/copyInBuffer';

import getHeaders from '../../../../../functions/getHeaders';
import setNotification from '../../../../../functions/setNotification';
import changePage from '../../../../../functions/changePage';
import setPdf from '../../../../../requests/setPdf';
import download from '../../../../../functions/download';
import getPageLink from '../../../../../functions/getPageLink';
import Editor from '../../../../../components/Editor.jsx';

const patterns = require('../../../../../infos/crm/joinTemplatePatterns.json');

class JoinsTemplatesInnerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: null,
        };

        this.handlerName = this.handlerName.bind(this);
        this.renderLogo = this.renderLogo.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.deleteLogo = this.deleteLogo.bind(this);
        this.renderPattern = this.renderPattern.bind(this);
        this.handlerPatternsList = this.handlerPatternsList.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerScroll = this.handlerScroll.bind(this);
        this.createPdf = this.createPdf.bind(this);
        this.changeJoinContent = this.changeJoinContent.bind(this);

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    handlerName({ action, value }) {
        const { changeJoinTemplate } = this.props;

        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                changeJoinTemplate({ action, name: 'name', value }).then(
                    () => {
                        this.handlerErrors({ action: 'delete', errors: ['name'] });

                        resolve();
                    },
                    () => null,
                );
            }
        });
    }

    getLogo() {
        const { logo, isDeleteLogo } = this.state;
        const { joinTemplate } = this.props;

        if (isDeleteLogo) {
            return null;
        }

        return (
            logo ||
            (joinTemplate?.logo?.path
                ? {
                      ...joinTemplate?.logo,
                      path: `${process.env.REACT_APP_STATIC}/join-templates/${joinTemplate?.logo?.path}`,
                  }
                : null)
        );
    }

    getLogoItems() {
        const logo = this.getLogo();

        return logo
            ? [{ ...logo, key: new Date(logo.dateOfUpload).getTime() }]
            : [{ key: 'empty' }];
    }

    formData = new FormData();

    HandlerFile = new FileClass({});

    uploadLogo({ target }) {
        this.HandlerFile.uploadFiles({ target, formData: this.formData }).then(
            ({ resultFiles: files }) => {
                const [file] = files;

                this.setState({ logo: file, isDeleteLogo: undefined });
            },
            () => null,
        );
    }

    deleteLogo() {
        this.setState({ logo: null, isDeleteLogo: true });
    }

    renderLogo({ item: logo, prop: key }) {
        const { checkRights, joinTemplate } = this.props;

        if (key === 'empty') {
            return (
                <div className="joinsTemplates__logoItem _row">
                    <div className="joinsTemplates__logoPreview">
                        <i className="joinsTemplates__logoPreviewIcon">
                            <Icon name="blog-image" />
                        </i>
                    </div>
                    <div className="joinsTemplates__logoContent">
                        <div className="joinsTemplates__logoTitle">Логотип компании</div>
                        <p className="joinsTemplates__logoDescription">
                            JPG, PNG (240x60px, до 1 Мб)
                        </p>
                    </div>
                    {(joinTemplate.isNew || checkRights()) && (
                        <div className="joinsTemplates__logoActions _row">
                            <label className="joinsTemplates__logoButton _click">
                                <input type="file" onChange={this.uploadLogo} />
                                Загрузить логотип
                            </label>
                        </div>
                    )}
                </div>
            );
        }

        const fileSrc = logo.path;

        return (
            <div className="joinsTemplates__logoItem _row _image">
                <div
                    className={`joinsTemplates__logoPreview ${
                        logo.type === 'image/svg+xml' ? '_svg' : ''
                    }`}
                >
                    <img src={fileSrc} alt="" className="joinsTemplates__logoPreviewImage" />
                    <div className="joinsTemplates__logoPreviewDone _col">
                        <i className="joinsTemplates__logoPreviewDoneIcon">
                            <Icon name="done" />
                        </i>
                    </div>
                </div>
                <div className="joinsTemplates__logoContent">
                    <div className="joinsTemplates__logoTitle">{logo.name}</div>
                    <p className="joinsTemplates__logoDescription">
                        {this.HandlerFile.getSize(logo.size)}
                    </p>
                </div>
                {(joinTemplate.isNew || checkRights()) && (
                    <div className="joinsTemplates__logoActions _row">
                        <label className="joinsTemplates__logoButton _click">
                            <input type="file" onChange={this.uploadLogo} />
                            Загрузить другой
                        </label>
                        <div
                            className="joinsTemplates__logoDelete _click"
                            onClick={this.deleteLogo}
                        >
                            <Icon name="actions-delete" />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    getPattersItems() {
        const { search } = this.state;
        const filteredItems = JSON.parse(JSON.stringify(patterns)).filter(
            (item) =>
                !search ||
                search.length < 3 ||
                item.name.toLowerCase().includes(search.toLowerCase()),
        );

        if (filteredItems.length === 0) {
            return [{ key: 'empty' }];
        }

        return filteredItems;
    }

    renderPattern({ item: pattern, prop: key }) {
        if (key === 'empty') {
            return (
                <div className="joinsTemplates__patternsListItem _row _empty">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">Паттерн не найден</div>
                            <p className="empty__content">Попробуйте изменить поиск</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="joinsTemplates__patternsListItem _row">
                <div
                    className="joinsTemplates__patternsListItemSupport"
                    dangerouslySetInnerHTML={{ __html: `${pattern.name}:` }}
                ></div>
                <div
                    className="joinsTemplates__patternsListItemValue _row _click"
                    onClick={() => {
                        copyInBuffer(pattern.reg);

                        this.handlerPatternsList(false);
                    }}
                >
                    {pattern.reg}
                    <i className="joinsTemplates__patternsListItemCopy">
                        <Icon name="copy" />
                    </i>
                </div>
            </div>
        );
    }

    handlerPatternsList(isPatternsListShow = !this.state.isPatternsListShow) {
        this.setState({ isPatternsListShow });
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ search: value });
            }
        });
    }

    handlerMissClick(e) {
        const head = this.parent.current.querySelector('.joinsTemplates__patternsHead');
        const list = this.parent.current.querySelector('.joinsTemplates__patternsList');

        if (
            list &&
            e.target !== list &&
            !list.contains(e.target) &&
            e.target !== head &&
            !head.contains(e.target)
        ) {
            this.handlerPatternsList(false);
        }
    }

    cursor = '_|cursor|_';

    changeJoinContent({ value }) {
        const { changeJoinTemplate } = this.props;

        return new Promise((resolve) => {
            changeJoinTemplate({ action: 'change', name: 'content', value }).then(() => {
                this.handlerErrors({ action: 'delete', errors: ['content'] });

                resolve();
            }, resolve);
        });
    }

    handlerErrors({ action, errors }) {
        this.setState((state) => {
            const newState = { ...state };
            const resultErrors = JSON.parse(JSON.stringify(newState.errors || []));

            errors.forEach((error) => {
                const index = resultErrors.indexOf(error);

                if (action === 'delete') {
                    if (index !== -1) {
                        resultErrors.splice(index, 1);
                    }
                } else if (index === -1) {
                    resultErrors.push(error);
                }
            });

            newState.errors = resultErrors;

            return newState;
        });
    }

    checkChange() {
        const { logo, isDeleteLogo } = this.state;
        const { joinTemplate, joinTemplateSave } = this.props;
        const fields = {};

        ['name', 'content'].forEach((key) => {
            if (joinTemplate.isNew || joinTemplate[key] !== joinTemplateSave[key]) {
                fields[key] = joinTemplate[key];
            }
        });

        const isUploadLogo = !!logo;

        return { fields, isChange: Object.keys(fields).length > 0 || isUploadLogo || isDeleteLogo };
    }

    validate() {
        const { joinTemplate } = this.props;
        const errors = [];

        ['name', 'content'].forEach((key) => {
            if (!joinTemplate[key]) {
                errors.push(key);
            }
        });

        return { errors };
    }

    save({ detail: { callback } }) {
        const { isDeleteLogo } = this.state;
        const { joinTemplate, setJoinTemplate, getJoinTemplate } = this.props;
        const { fields, isChange } = this.checkChange();

        if (!isChange) {
            callback();
        } else {
            const { errors } = this.validate();

            if (errors.length) {
                setNotification({ notification: 'required-fields-not-complete' });

                this.handlerErrors({ errors });

                callback();
            } else {
                this.formData.set('id', joinTemplate._id);
                this.formData.set('fields', JSON.stringify(fields));

                if (isDeleteLogo) {
                    this.formData.set('isDeleteLogo', true);
                }

                axios[joinTemplate.isNew ? 'post' : 'patch'](
                    `${process.env.REACT_APP_API}/join-template`,
                    this.formData,
                    { headers: getHeaders() },
                ).then(
                    (res) => {
                        const { success, data } = res.data;
                        const { id } = data;

                        if (success) {
                            setNotification({ notification: 'success-change-info' });

                            if (id) {
                                changePage({
                                    href: getPageLink({
                                        name: 'joins-templates-inner',
                                        ids: { 3: id },
                                    }),
                                });

                                getJoinTemplate();
                            }

                            setJoinTemplate({
                                joinTemplate: {
                                    ...joinTemplate,
                                    isNew: undefined,
                                },
                            });
                        }

                        callback();
                    },
                    () => null,
                );
            }
        }
    }

    handlerScroll() {
        const contentCard = this.parent.current.querySelector('.joinsTemplates__card._content');

        const offsetTop =
            contentCard.getBoundingClientRect().y - this.getScrollBox().getBoundingClientRect().y;

        if (offsetTop <= 16 && !this.state.isContentSticky) {
            this.setState({ isContentSticky: true });
        }

        if (offsetTop > 16 && this.state.isContentSticky) {
            this.setState({ isContentSticky: false });
        }
    }

    getScrollBox() {
        return this.parent.current.closest('.widget__pageBox._scroll');
    }

    createPdf({ detail: { callback } }) {
        const { joinTemplate } = this.props;

        setPdf({
            id: `joinsTemplate-${new Date().getTime()}`,
            key: 'joinsTemplate',
            name: `Договор.pdf`,
            otherData: {
                templateId: joinTemplate._id,
                isTest: true,
            },
            inMoment: true,
        }).then(({ fileName }) => {
            if (fileName) {
                download({
                    files: [
                        {
                            src: fileName,
                            folder: 'corporations-docs',
                            name: 'Договор.pdf',
                            isDelete: true,
                        },
                    ],
                });
            }

            callback();
        }, callback);
    }

    componentDidMount() {
        document.addEventListener('click', this.handlerMissClick);
        document.addEventListener('joinTemplatesSave', this.save);
        document.addEventListener('joinTemplatesCreatePdf', this.createPdf);

        this.getScrollBox().addEventListener('scroll', this.handlerScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
        document.removeEventListener('joinTemplatesSave', this.save);
        document.removeEventListener('joinTemplatesCreatePdf', this.createPdf);

        this.getScrollBox().removeEventListener('scroll', this.handlerScroll);
    }

    render() {
        const { isPatternsListShow = false, search, errors, isContentSticky } = this.state;
        const { joinTemplate, checkRights } = this.props;


        return (
            <div ref={this.parent} className="joinsTemplates _col">
                <div className="joinsTemplates__card">
                    <div className="joinsTemplates__cardHead _row">
                        <div className="joinsTemplates__cardTitle">Название шаблона</div>
                    </div>
                    <div className="joinsTemplates__cardContent _col">
                        <div className="joinsTemplates__name">
                            <Field
                                value={joinTemplate?.name}
                                callback={this.handlerName}
                                support="Введите (или вставьте) название шаблона"
                                className="_joinTemplates _notBack"
                                error={errors?.includes('name')}
                                isDisabled={!checkRights()}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`joinsTemplates__card _content ${isContentSticky ? '_sticky' : ''}`}
                >
                    <Editor
                        name="template"
                        title="Контент шаблона"
                        content={joinTemplate?.content}
                        change={this.changeJoinContent}
                        disabled={!(joinTemplate?.isNew || checkRights())}
                        patterns={patterns}
                        support="Введите (или вставьте) текст шаблона договора"
                    >
                        <div className="joinsTemplates__logo _row">
                            <ListAbsoluteMain
                                className="joinsTemplates__logoInner"
                                items={this.getLogoItems()}
                                renderItem={this.renderLogo}
                                classNameItem="joinsTemplates__logoItem"
                                prop="key"
                                paramsParent={{}}
                                styles={[]}
                                isNotParamsItem={true}
                                keyUpdateItem={this.getLogo()?.path}
                            />
                        </div>

                        <div
                            className={`joinsTemplates__patterns ${
                                isPatternsListShow ? '_active' : ''
                            }`}
                        >
                            <div
                                className="joinsTemplates__patternsHead _col _click"
                                onClick={() => {
                                    this.handlerPatternsList();
                                }}
                            >
                                <div className="joinsTemplates__patternsHeadTitle">
                                    Список паттернов для вставки
                                </div>
                                <p className="joinsTemplates__patternsHeadDescription">
                                    Скопируйте нужный паттерн для вставки в договор
                                </p>
                                <i className="joinsTemplates__patternsHeadIcon _col" />
                            </div>
                            <Animate
                                className="joinsTemplates__patternsList"
                                isShow={isPatternsListShow}
                            >
                                <div className="joinsTemplates__patternsListInner">
                                    <div className="joinsTemplates__patternsListSearch">
                                        <Search
                                            support="Поиск по паттернам: введите название"
                                            className="_mediumSize _grey"
                                            callback={this.handlerSearch}
                                            value={search || ''}
                                        />
                                    </div>
                                    <div className="joinsTemplates__patternsListItemsWrapper">
                                        <ListAbsoluteMain
                                            className="joinsTemplates__patternsListItems _col"
                                            items={this.getPattersItems()}
                                            renderItem={this.renderPattern}
                                            classNameItem="joinsTemplates__patternsListItem"
                                            prop="key"
                                            paramsParent={{ width: true }}
                                            styles={['height']}
                                            maxHeight={320}
                                            callback={({ isChangeLen }) => {
                                                if (isChangeLen) {
                                                    const wrapper =
                                                        this.parent.current.querySelector(
                                                            '.joinsTemplates__patternsListItemsWrapper',
                                                        );

                                                    scrollToPosition({
                                                        position: 'top',
                                                        parent: wrapper,
                                                    });
                                                }
                                            }}
                                            // name="test"
                                        />
                                    </div>
                                </div>
                            </Animate>
                        </div>
                    </Editor>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(JoinsTemplatesInnerMain);

JoinsTemplatesInnerMain.propTypes = {
    levels: PropTypes.array,
    joinTemplate: PropTypes.object,
    joinTemplateSave: PropTypes.object,
    changeJoinTemplate: PropTypes.func,
    setJoinTemplate: PropTypes.func,
    getJoinTemplate: PropTypes.func,
    checkRights: PropTypes.func,
};
