import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getExecutors from '../../../requests/getExecutors';

import Pages from '../../../components/Pages.jsx';

import Map from './crew/Map.jsx';
import Final from './crew/Final.jsx';
import changePage from '../../../functions/changePage';
import checkResponsible from '../../../functions/order/checkResponsible';
import removeTransition from '../../../functions/removeTransition.ts';
import getIconMap from '../../../functions/getIconMap';
import { addRoute } from '../../../functions/handlerYmap';
import Filter from '../../../classes/Filter';
import getFilter from '../../../requests/getFilter';
import getQueryFilter from '../../../functions/getQueryFilter';
import checkValueOfEmpty from '../../../functions/checkValueOfEmpty';
import setPermissions from '../../../functions/crm/setPermissions';

class OrderDetailsCrew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            executorsInfo: {},
            mapInfo: {
                center: [55.755819, 37.617644],
                zoom: 10,
                controls: [],
            },
        };

        this.handlerChoice = this.handlerChoice.bind(this);
        this.setCurrentCar = this.setCurrentCar.bind(this);
        this.deleteExecutor = this.deleteExecutor.bind(this);
        this.checkCompleteCrew = this.checkCompleteCrew.bind(this);
        this.getCondForChange = this.getCondForChange.bind(this);
        this.addWorkerRoleForDriver = this.addWorkerRoleForDriver.bind(this);
        this.getCondForWorkerRoleForExecutor = this.getCondForWorkerRoleForExecutor.bind(this);
        this.setExecutorMark = this.setExecutorMark.bind(this);
        this.clearMarks = this.clearMarks.bind(this);
        this.changeTariff = this.changeTariff.bind(this);
        this.setExecutors = this.setExecutors.bind(this);
        this.getMoreExecutors = this.getMoreExecutors.bind(this);
        this.setMap = this.setMap.bind(this);
        this.filterCallback = this.filterCallback.bind(this);
        this.routeCallback = this.routeCallback.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;

    pages = {
        'order-details-crew-map': {
            render() {
                const {
                    ymaps,
                    map,
                    mapInfo,
                    executors,
                    isLimit,
                    isDisabledScroll,
                    isInit,
                    executorsInfo,
                    filter,
                    isFilterLoading,
                    updateKey,
                } = this.state;
                const { order, getParent } = this.props;

                return (
                    <Map
                        ymaps={ymaps}
                        map={map}
                        mapInfo={mapInfo}
                        handlerRoute={this.handlerRoute}
                        order={order}
                        executors={executors}
                        handlerChoice={this.handlerChoice}
                        checkCompleteCrew={this.checkCompleteCrew}
                        getCondForChange={this.getCondForChange}
                        addWorkerRoleForDriver={this.addWorkerRoleForDriver}
                        getCondForWorkerRoleForExecutor={this.getCondForWorkerRoleForExecutor}
                        clearMarks={this.clearMarks}
                        stepCounter={this.stepCounter}
                        isLimit={isLimit}
                        isDisabledScroll={isDisabledScroll}
                        getMoreExecutors={this.getMoreExecutors}
                        isInit={isInit}
                        setMap={this.setMap}
                        executorsInfo={executorsInfo}
                        handlerFilter={this.handlerFilter}
                        getParent={getParent}
                        filter={filter}
                        isFilterLoading={isFilterLoading}
                        updateKey={updateKey}
                        routeCallback={this.routeCallback}
                    />
                );
            },
        },
        'order-details-crew-final': {
            render() {
                const { executors = [], executorsSave = [], isGetExecutors } = this.state;
                const { order, getParent } = this.props;
                const executorsActive = executors.filter((executor) => executor.isActive);
                const executorsSaveActive = executorsSave.filter(
                    (executor) =>
                        executors.find(
                            (executorLoop) =>
                                executorLoop._id === executor._id &&
                                executorLoop.type === executor.type,
                        ).isActive,
                );

                return (
                    <Final
                        order={order}
                        executors={executorsActive}
                        executorsSave={executorsSaveActive}
                        setCurrentCar={this.setCurrentCar}
                        deleteExecutor={this.deleteExecutor}
                        getCondForChange={this.getCondForChange}
                        isGetExecutors={isGetExecutors}
                        parent={getParent()}
                        changeTariff={this.changeTariff}
                        setExecutors={this.setExecutors}
                    />
                );
            },
        },
    };

    routeCallback() {
        this.setState({ isSetRoute: true }, () => {
            this.setExecutorsMapPoints(true);
        });
    }

    checkCompleteCrew() {
        const { executors = [] } = this.state;
        const { order } = this.props;

        return (
            order?.crewTemplate.length === executors.filter((executor) => executor.isActive).length
        );
    }

    deleteWorkerRoleForDriver(id) {
        this.setState(
            (state) => {
                const newState = { ...state };
                const executors = JSON.parse(JSON.stringify(newState.executors));

                const indexExecutor = executors.findIndex(
                    (executor) => executor._id === id && executor.type === 'worker',
                );

                executors.splice(indexExecutor, 1);

                newState.executors = executors;

                return newState;
            },
            () => {
                this.checkMarks();
            },
        );
    }

    checkCompleteForType(type) {
        const { order } = this.props;
        const { executors } = this.state;

        return (
            executors.filter((executor) => executor.isActive && executor.type === type).length ===
            order?.crewTemplate.filter((item) => item === type).length
        );
    }

    getCondForWorkerRoleForExecutor() {
        const { order } = this.props;

        return order?.crewTemplate.find((item) => item === 'worker');
    }

    setExecutorMark({ _id, type, placemark }) {
        this.setState((state) => {
            const newState = { ...state };
            const marks = newState.marks ? [...newState.marks] : [];
            const executors = JSON.parse(JSON.stringify(newState.executors));

            const indexExecutor = executors.findIndex(
                (executor) => executor._id === _id && executor.type === type,
            );
            const currentExecutor = executors[indexExecutor];

            if (currentExecutor) {
                currentExecutor.isInitMark = true;
            }

            marks.push({
                _id,
                type,
                placemark,
            });

            newState.marks = marks;
            newState.executors = executors;

            return newState;
        });
    }

    clearMarks() {
        this.setState({ marks: null });
    }

    checkMarks() {
        const { marks, executors } = this.state;

        if (marks) {
            executors.forEach((executor) => {
                const mark = marks.find(
                    (markLoop) => markLoop._id === executor._id && markLoop.type === executor.type,
                );
                const { isActive, type } = executor;

                if (mark) {
                    const { placemark } = mark;

                    placemark.options.set('iconImageHref', getIconMap({ isActive, type }));
                }
            });
        }
    }

    setMap({ ymaps, map }) {
        this.setState({ ymaps, map }, () => {
            this.setExecutorsMapPoints(true);
        });
    }

    handlerMark({ _id, type, isActive }) {
        this.handlerChoice({ _id, type, action: isActive ? 'delete' : 'add' });
    }

    setEventsMap({ _id, type, coords, secondName, isActive }) {
        const { ymaps, map } = this.state;
        const { order } = this.props;
        const { route } = order;

        const myPlacemark = new ymaps.Placemark(
            coords,
            { iconContent: secondName[0].toUpperCase() },
            {
                iconLayout: 'default#image',
                iconImageHref: getIconMap({ isActive, type }),
                iconImageSize: [32, 32],
            },
        );

        if (this.mapPoints[_id].mark) {
            map.geoObjects.remove(this.mapPoints[_id].mark);
        }

        myPlacemark.events.add('click', () => {
            this.handlerMark({ _id, type, isActive });
        });

        map.geoObjects.add(myPlacemark);

        this.mapPoints[_id].mark = myPlacemark;

        this.setExecutorMark({ _id, type, placemark: myPlacemark });

        addRoute({
            ymaps,
            map,
            points: [coords, route[0].coords],
            isForCalc: true,
            isClear: true,
        }).then((infoRoute) => {
            this.setState((state) => {
                const newState = { ...state };
                const executorsInfo = JSON.parse(JSON.stringify(newState.executorsInfo));

                executorsInfo[_id] = {
                    minutes: infoRoute.minutes,
                    hours: infoRoute.hours,
                    distance: infoRoute.distance,
                };

                newState.executorsInfo = executorsInfo;

                return newState;
            });
        });
    }

    mapPoints = {};

    setExecutorsMapPoints(isForce) {
        const { executors = [], ymaps, map, isSetRoute } = this.state;

        if (executors.length && ymaps && map && isSetRoute) {
            executors
                .filter(
                    (executor) =>
                        !this.mapPoints[executor._id] ||
                        isForce === true ||
                        isForce === executor._id,
                )
                .filter((executor) => executor.coords && executor.coords[0] && executor.coords[1])
                .forEach((executor) => {
                    if (!this.mapPoints[executor._id]) {
                        this.mapPoints[executor._id] = {
                            isInit: true,
                        };
                    }

                    this.setEventsMap(executor);
                });
        }
    }

    handlerChoice({ _id, type, action }) {
        if (this.getCondForChange()) {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const executors = JSON.parse(JSON.stringify(newState.executors));

                    const indexExecutor = executors.findIndex(
                        (executor) => executor._id === _id && executor.type === type,
                    );
                    const currentExecutor = executors[indexExecutor];

                    const checkCompleteForType = this.checkCompleteForType(currentExecutor.type);

                    if (action === 'delete') {
                        currentExecutor.isActive = false;

                        if (this.getCondForWorkerRoleForExecutor()) {
                            if (currentExecutor.isDriver) {
                                this.deleteWorkerRoleForDriver(currentExecutor._id);
                            } else {
                                const driverWithWorkerRole = executors.find(
                                    (executor) =>
                                        executor._id === currentExecutor._id && executor.isDriver,
                                );

                                if (driverWithWorkerRole) {
                                    this.deleteWorkerRoleForDriver(driverWithWorkerRole._id);
                                }
                            }
                        }
                    } else {
                        if (checkCompleteForType) {
                            const executorCompleteForType = executors.find(
                                (executor) =>
                                    executor.isActive && executor.type === currentExecutor.type,
                            );

                            if (executorCompleteForType) {
                                executorCompleteForType.isActive = false;

                                if (this.getCondForWorkerRoleForExecutor()) {
                                    const driverWithWorkerRole = executors.find(
                                        (executor) =>
                                            executor._id === executorCompleteForType._id &&
                                            executor.isDriver,
                                    );

                                    if (driverWithWorkerRole) {
                                        this.deleteWorkerRoleForDriver(driverWithWorkerRole._id);
                                    }
                                }
                            }
                        }

                        currentExecutor.isActive = true;
                    }

                    newState.executors = executors;

                    return newState;
                },
                () => {
                    this.checkMarks();
                },
            );
        }
    }

    addWorkerRoleForDriver(id) {
        if (this.getCondForChange() && this.getCondForWorkerRoleForExecutor()) {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const executors = JSON.parse(JSON.stringify(newState.executors));

                    const driver = executors.find((executor) => executor._id === id);

                    driver.isActive = true;

                    const otherDriverAlreadyChoice = executors.find(
                        (executor) =>
                            executor.type === 'driver' && executor.isActive && executor._id !== id,
                    );
                    let checkCompleteForWorker = this.checkCompleteForType('worker');

                    if (otherDriverAlreadyChoice) {
                        otherDriverAlreadyChoice.isActive = false;

                        const otherDriverWithWorkerRole = executors.find(
                            (executor) =>
                                executor._id === otherDriverAlreadyChoice._id && executor.isDriver,
                        );

                        if (otherDriverWithWorkerRole) {
                            checkCompleteForWorker = false;
                            this.deleteWorkerRoleForDriver(otherDriverWithWorkerRole._id);
                        }
                    }

                    const driverWithWorkerRole = executors.find(
                        (executor) =>
                            executor._id === driver._id && executor.isDriver && !executor.isDelete,
                    );

                    if (driverWithWorkerRole) {
                        driverWithWorkerRole.isDelete = true;
                        driverWithWorkerRole.isActive = false;
                        this.deleteWorkerRoleForDriver(driverWithWorkerRole._id);
                    } else {
                        if (checkCompleteForWorker) {
                            const worker = executors.find((executor) => executor.type === 'worker');

                            if (worker) {
                                worker.isActive = false;
                            }
                        }

                        executors.push(
                            JSON.parse(
                                JSON.stringify({
                                    ...driver,
                                    type: 'worker',
                                    isDriver: true,
                                    isActive: true,
                                }),
                            ),
                        );
                    }

                    newState.executors = executors;

                    return newState;
                },
                () => {
                    this.checkMarks();
                },
            );
        }
    }

    setCurrentCar(id) {
        return new Promise((resolve) => {
            if (this.getCondForChange()) {
                this.setState((state) => {
                    const newState = { ...state };
                    const executors = JSON.parse(JSON.stringify(newState.executors));

                    const indexExecutor = executors.findIndex(
                        (executor) => executor.isActive && executor.type === 'driver',
                    );

                    executors[indexExecutor].car = id;

                    newState.executors = executors;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    deleteExecutor({ id, type }) {
        const { order } = this.props;

        return new Promise((resolve) => {
            if (this.getCondForChange()) {
                changePage({
                    href: 'order-details-crew-map',
                    id: order._id,
                });

                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const executors = JSON.parse(JSON.stringify(newState.executors));

                        const executor = executors.find(
                            (item) => item._id === id && item.type === type,
                        );

                        executor.isActive = false;

                        if (executor.isDriver) {
                            executor.isDelete = true;

                            this.deleteWorkerRoleForDriver(executor._id);
                        } else if (executor.type === 'driver') {
                            const worker = executors.find(
                                (item) => item._id === executor._id && item.type === 'worker',
                            );

                            if (worker) {
                                this.deleteWorkerRoleForDriver(worker._id);
                            }
                        }

                        newState.executors = executors;

                        return newState;
                    },
                    () => {
                        this.checkMarks();
                        resolve();
                    },
                );
            } else {
                resolve();
            }
        });
    }

    getCondForChange(type) {
        const { user, order, checkRights } = this.props;
        const condForChange = checkRights() && order && checkResponsible({ user, order });

        if (type === 'executor') {
            if (condForChange && order.status === 'complete') {
                return this.getPermissions({
                    key: 'orders',
                    items: [{ key: 'main', rules: [], actions: ['changeAmount'] }],
                });
            }

            return (
                condForChange &&
                ['new', 'start-work', 'choice-crew', 'complete'].indexOf(order.status) !== -1
            );
        }

        return condForChange && ['new', 'start-work', 'choice-crew'].indexOf(order.status) !== -1;
    }

    isProccessCheckCrew = false;

    checkCrew() {
        const { executors = [] } = this.state;
        const { order } = this.props;

        if (order && !this.isProccessCheckCrew && executors.length) {
            const executorsCancel = executors.filter(
                (executor) =>
                    executor.fromCrew &&
                    !order.crew.find((executorCrew) => executorCrew.id === executor._id),
            );

            if (executorsCancel.length) {
                this.isProccessCheckCrew = true;

                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const executorsState = JSON.parse(JSON.stringify(newState.executors));

                        executorsCancel.forEach((executorCancel) => {
                            const indexExecutor = executorsState.findIndex(
                                (executorLoop) => executorLoop._id === executorCancel._id,
                            );

                            if (indexExecutor !== -1) {
                                executorsState[indexExecutor].isActive = false;

                                delete executorsState[indexExecutor].fromCrew;
                            }
                        });

                        newState.executors = executorsState;

                        return newState;
                    },
                    () => {
                        this.isProccessCheckCrew = false;
                    },
                );
            }
        }
    }

    changeTariff({ idOfItem, _id, customPrice }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const executors = JSON.parse(JSON.stringify(newState.executors));
                const executor = executors.find((executorLoop) => executorLoop._id === _id);

                if (idOfItem) {
                    executor.tariff.idOfTariff = idOfItem;
                }

                if (checkValueOfEmpty(customPrice, true)) {
                    executor.tariff.customPrice = customPrice;
                    executor.tariff.configurations.find(
                        (config) => config._id === 'customConfig',
                    ).items[0].amount = +customPrice;

                    // console.log(executor.tariff);
                }

                newState.executors = executors;

                return newState;
            }, resolve);
        });
    }

    setExecutors({ executors, isLimit, isFilter }) {
        return new Promise((resolve) => {
            this.setState(
                {
                    executors,
                    executorsSave: JSON.parse(JSON.stringify(executors)),
                    isGetExecutors: true,
                    isLimit,
                    ...(isFilter
                        ? { updateKey: new Date().getTime(), counterScroll: this.stepCounter }
                        : {}),
                },
                () => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    resolve();
                },
            );
        });
    }

    getMoreExecutors(counter, isStart) {
        const actionPrev = () =>
            new Promise((resolve) => {
                this.setState({ isDisabledScroll: true }, () => {
                    if (isStart) {
                        resolve();
                    } else {
                        this.getExecutors(true).then(resolve);
                    }
                });
            });

        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                actionPrev().then(() => {
                    removeTransition({
                        item: '.orderDetailsCrewMap__listItem',
                        isCurrent: true,
                    });

                    setTimeout(() => {
                        this.setState({ isDisabledScroll: false }, resolve);
                    }, 100);
                });
            });
        });
    }

    getQueryForRequest() {
        const { counterScroll } = this.state;
        const params = [];

        params.push({ key: 'limit', value: this.stepCounter });
        params.push({ key: 'skip', value: counterScroll ? counterScroll - this.stepCounter : 0 });

        return params;
    }

    getFilterExecutors() {
        const setLoading = (isFilterLoading) =>
            new Promise((resolve) => {
                this.setState({ isFilterLoading, counterScroll: 0 }, resolve);
            });

        setLoading(true).then(() => {
            setTimeout(() => {
                this.getExecutors(true, true).then(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 100);
                });
            }, 300);
        });
    }

    updateExecutor(data) {
        const { _id, role, amount } = data;

        this.setState((state) => {
            const newState = { ...state };
            const executors = JSON.parse(JSON.stringify(newState.executors));
            const executor = executors.find((item) => item._id === _id && item.type === role);

            if (executor) {
                const { tariff } = executor;
                const { configurations, idOfTariff } = tariff;
                const items = configurations.reduce((prev, cur) => prev.concat(...cur.items), []);
                const item = items.find((itemLoop) => itemLoop._id === idOfTariff);

                if (item) {
                    item.amount = amount;
                }
            }

            newState.executors = executors;

            return newState;
        });
    }

    getExecutors(isForce = false, isFilter = false) {
        const { filter } = this.state;
        const { order } = this.props;

        return new Promise((resolve) => {
            if (order && (!this.isGetExecutors || isForce)) {
                this.isGetExecutors = true;

                getExecutors({
                    params: [
                        ...this.getQueryForRequest(),
                        ...getQueryFilter({ filter }),
                        { key: 'type', value: 'crew' },
                        { key: 'idOfOrder', value: order._id },
                    ],
                }).then(({ executors, isLimit }) => {
                    const executorsUpdate = executors;

                    order.crew.forEach((executorInCrew) => {
                        const executorInfo = order.crewInfo.find(
                            (item) => item.id === executorInCrew.id,
                        );
                        const executor = executorsUpdate.find(
                            (item) => item._id === executorInCrew.id,
                        );

                        if (executor) {
                            executor.isActive = true;
                            executor.fromCrew = true;

                            if (executorInCrew.car) {
                                executor.car = executorInCrew.car;
                            }

                            if (
                                executorInfo.role === 'driver' &&
                                executorInCrew.role === 'worker'
                            ) {
                                executorsUpdate.push(
                                    JSON.parse(
                                        JSON.stringify({
                                            ...executor,
                                            type: 'worker',
                                            isDriver: true,
                                            isActive: true,
                                        }),
                                    ),
                                );
                            }
                        }
                    });

                    this.setExecutors({
                        executors:
                            isForce && !isFilter
                                ? this.state.executors.concat(...executorsUpdate)
                                : executorsUpdate,
                        isLimit,
                        isFilter,
                    }).then(() => {
                        this.setExecutorsMapPoints();

                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    getFilter() {
        getFilter({ name: 'orderExecutors' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    filterCallback({ filter, isChange }) {
        return new Promise((resolve) => {
            if (!isChange) {
                resolve();
            } else {
                this.setState({ filter }, () => {
                    this.getFilterExecutors();

                    resolve();
                });
            }
        });
    }

    handlerSocket({ detail }) {
        const { executors } = this.state;
        const { order } = this.props;
        const { name, data } = detail;

        if (executors && name === 'executor' && data) {
            const { idOfExecutor, fields } = data;
            const index = executors.findIndex((executor) => executor._id === idOfExecutor);

            if (fields.coords && idOfExecutor && index !== -1) {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const newExecutors = JSON.parse(JSON.stringify(newState.executors));

                        newExecutors[index].coords = fields.coords;

                        newState.executors = newExecutors;

                        return newState;
                    },
                    () => {
                        this.setExecutorsMapPoints(executors[index]._id);
                    },
                );
            }
        }

        if (name === 'order' && order?._id === data.idOfOrder) {
            const updatedExecutor = data?.fields?.updatedExecutor;

            if (updatedExecutor) {
                this.updateExecutor(updatedExecutor);
            }
        }
    }

    componentDidMount() {
        const { setFilterCallback } = this.props;

        if (setFilterCallback) {
            setFilterCallback(this.filterCallback);
        }

        this.getExecutors();
        this.checkCrew();

        this.getFilter();

        this.handlerFilter = new Filter({
            context: this,
            callback: () => {
                // const { filter } = this.state;
            },
        });

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentDidUpdate() {
        this.getExecutors();
        this.checkCrew();
    }

    render() {
        const { pages } = this.state;

        return (
            <div ref={this.parent} className="orderDetailsCrew">
                <div className="orderDetailsCrew__inner">
                    <Pages
                        classNamePage="orderDetailsCrew__page"
                        filter={(page) =>
                            page.parentName === 'order-details-crew' && page.level === 4
                        }
                        pages={this.pages}
                        context={this}
                        pagesStates={pages}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderDetailsCrew);

OrderDetailsCrew.propTypes = {
    order: PropTypes.object,
    pagesStore: PropTypes.object,
    user: PropTypes.object,
    getParent: PropTypes.func,
    checkRights: PropTypes.func,
    setFilterCallback: PropTypes.func,
};
