import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Tariff from './Tariff.jsx';
import Edit from '../Edit.jsx';
import Chat from './Chat.jsx';
import Animate from '../Animate.jsx';
import Date from './Date.jsx';

class DetailsOther extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightChatItem = this.setHeightChatItem.bind(this);
    }

    orderItems = ['tariff', 'chat'];

    items = {
        tariff: {
            render() {
                const {
                    order,
                    handlerEditmode,
                    editmode,
                    editName,
                    changeOrder,
                    isNew,
                    points,
                    infoRoute,
                    errors,
                    handlerChangedPrice,
                    changedPrice,
                } = this.props;

                return (
                    <Tariff
                        order={order}
                        handlerEditmode={handlerEditmode}
                        editmode={editmode}
                        editName={isNew ? 'tariff' : editName}
                        changeOrder={changeOrder}
                        isNew={isNew}
                        points={points}
                        infoRoute={infoRoute}
                        errors={errors}
                        handlerChangedPrice={handlerChangedPrice}
                        changedPrice={changedPrice}
                    />
                );
            },
        },
        chat: {
            render() {
                const {
                    order,
                    handlerEditmode,
                    editmode,
                    fakeCallback,
                    isNew,
                    handlerDate,
                    date,
                    times,
                } = this.props;

                return (
                    <>
                        <Animate
                            className="orderDetailsMain__itemBlockInner"
                            isShow={!isNew}
                            actionInit={this.setHeightChatItem}
                            actionPrevRemove={this.setHeightChatItem}
                        >
                            <Chat
                                order={order}
                                handlerEditmode={handlerEditmode}
                                editmode={editmode}
                                fakeCallback={fakeCallback}
                            />
                        </Animate>
                        <Animate
                            className="orderDetailsMain__itemBlockInner"
                            isShow={isNew}
                            actionInit={this.setHeightChatItem}
                            actionPrevRemove={this.setHeightChatItem}
                        >
                            <Date
                                order={order}
                                handlerDate={handlerDate}
                                date={date}
                                times={times}
                            />
                        </Animate>
                    </>
                );
            },
        },
    };

    setHeightChatItem() {
        const currentItem = document.querySelector('.orderDetailsMain__itemBlockInner._show');

        if (currentItem) {
            const { offsetHeight: chatItemHeight } = currentItem;

            if (chatItemHeight !== this.state.chatItemHeight) {
                this.setState({ chatItemHeight });
            }
        }
    }

    render() {
        const { chatItemHeight = 0 } = this.state;
        const {
            editName,
            editCurrent,
            isLoad,
            handlerEditmode,
            getCondForChange,
            isNew,
            checkRights,
        } = this.props;

        return (
            <>
                {this.orderItems.map((name) => (
                    <div
                        className={`orderDetailsMain__itemWrap orderDetailsMain__itemBlock _parentForEdit _editBack _${name} ${
                            editName === name ||
                            editCurrent === name ||
                            (isNew && getCondForChange({ name }))
                                ? '_current'
                                : ''
                        }`}
                        key={name}
                        data-id={name}
                        style={name === 'chat' ? { height: chatItemHeight } : {}}
                    >
                        {!isNew &&
                            checkRights() &&
                            name === 'tariff' &&
                            getCondForChange({ name }) && (
                                <Edit
                                    className="orderDetailsMain__itemEdit"
                                    handlerEditmode={handlerEditmode}
                                    editName={editName}
                                    name={name}
                                    isLoader={isLoad}
                                />
                            )}
                        {this.items[name].render.call(this)}
                    </div>
                ))}
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsOther);

DetailsOther.propTypes = {
    order: PropTypes.object,
    editName: PropTypes.string,
    isNotShowBtn: PropTypes.bool,
    isDisabled: PropTypes.bool,
    handlerEditmode: PropTypes.func,
    isLoad: PropTypes.bool,
    editmode: PropTypes.object,
    editCurrent: PropTypes.string,
    changeOrder: PropTypes.func,
    getCondForChange: PropTypes.func,
    fakeCallback: PropTypes.func,
    isNew: PropTypes.bool,
    points: PropTypes.array,
    infoRoute: PropTypes.object,
    handlerDate: PropTypes.func,
    date: PropTypes.string,
    times: PropTypes.object,
    errors: PropTypes.array,
    checkRights: PropTypes.func,
    handlerChangedPrice: PropTypes.func,
    changedPrice: PropTypes.string,
};
