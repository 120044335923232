import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CorporationLimitsPopup from './CorporationLimitsPopup.jsx';
import Animate from '../../Animate.jsx';

class CorporationLimitsPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { corporationLimitsPopup } = this.props;

        return (
            <Animate
                className="body__corporationTariffPopup"
                isShow={corporationLimitsPopup?.isShow}
            >
                <CorporationLimitsPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        corporationLimitsPopup: state.corporationLimitsPopup,
    };
}

export default connect(mapStateToProps)(CorporationLimitsPopupWrapper);

CorporationLimitsPopupWrapper.propTypes = {
    corporationLimitsPopup: PropTypes.object,
};
