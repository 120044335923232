import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilterOrder from '../../classes/Filter';
import Stack from '../../classes/Stack';

import getOrders from '../../requests/getOrders';
import setOrderResponsible from '../../requests/setOrderResponsible';
import getFilter from '../../requests/getFilter';

import getRealParams from '../../functions/getRealParams.ts';
import removeTransition from '../../functions/removeTransition.ts';
import setAnimate from '../../functions/setAnimate';
import getPositionFromParent from '../../functions/getPositionFromParent';
import getMaxHeightContentWidget from '../../functions/crm/getMaxHeightContentWidget';
import sortOrders from '../../functions/sortOrders';
import getQueryFilter from '../../functions/getQueryFilter';
import handlerWindow, { updateWindow } from '../../functions/handlerWindow';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';

import Animate from '../Animate.jsx';
import Loader from '../Loader.jsx';
import AnimateChange from '../AnimateChange.jsx';
import OrderPreview from './OrderPreview.jsx';
import WidgetFilter from './widget/Filter.jsx';
import Windows from '../Windows.jsx';
import WindowList from '../WindowList.jsx';
import ListDynamic from '../ListDynamic.jsx';
import ListScroll from '../ListScroll.jsx';
import Filter from '../Filter.jsx';

import InfoHead from './widget/InfoHead.jsx';
import Back from './widget/Back.jsx';
import LinkActions from './widget/LinkActions.jsx';
import checkRights from '../../functions/crm/checkRights';
import Icon from '../Icon.jsx';
import UploadTemplateInfo from '../order/UploadTemplateInfo.jsx';
import handlerPopup from '../../functions/crm/handlerPopup';
import setPermissions from '../../functions/crm/setPermissions';

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counterScroll: this.stepCounter,
            isDisabledScroll: true,
        };

        this.changeCorporation = this.changeCorporation.bind(this);
        this.filterOrder = this.filterOrder.bind(this);

        this.getParams = this.getParams.bind(this);
        this.getParamsContent = this.getParamsContent.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.addOrder = this.addOrder.bind(this);
        this.renderOrder = this.renderOrder.bind(this);
        this.setScrollPosition = this.setScrollPosition.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getParentForScroll = this.getParentForScroll.bind(this);
        this.checkChangePage = this.checkChangePage.bind(this);
        this.renderWindowsList = this.renderWindowsList.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.getMoreOrders = this.getMoreOrders.bind(this);
        this.handlerUpdateFilter = this.handlerUpdateFilter.bind(this);
        this.setParamsCrew = this.setParamsCrew.bind(this);
        this.setStateLoaderOrder = this.setStateLoaderOrder.bind(this);
        this.checkRights = this.checkRights.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getOrdersEvent = this.getOrdersEvent.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    stepCounter = this.props.type === 'main' ? 15 : 10;

    getInfoEmpty() {
        const { type } = this.props;
        const ordersSort = this.getOrdersSort();

        if (ordersSort.length === 0) {
            return {
                title:
                    type === 'main'
                        ? 'В компании пока нет заказов'
                        : 'В компании нет новых заказов',
                description: 'Вы можете создать заказ',
            };
        }

        if (ordersSort.filter(this.filterOrder).length === 0) {
            return {
                title: 'По вашему фильтру ничего не найдено',
                description: 'Попробуйте изменить критерии',
            };
        }

        return {};
    }

    getParamsContent() {
        const { type } = this.props;
        const page = this.parent.current;

        if (page) {
            const { offsetWidth: widthPage } = page;
            const elems = [
                { id: 'content', className: '.widget__content' },
                { id: 'cards', className: '.ordersWidget__cardsInner' },
            ];

            const params = getRealParams({
                parent: page,
                elems,
                width: widthPage,
                classNames: ['_static', `_${type}`],
                isClearStyles: true,
                // isNotRemove: true,
            });

            let { height: heightContent } = params.content;
            const { height: heightCards } = params.cards;

            if (heightContent > getMaxHeightContentWidget()) {
                heightContent = getMaxHeightContentWidget();
            }

            if (heightCards !== this.state.heightCards) {
                this.setState({ heightContent, heightCards });
            }
        }
    }

    getParams() {
        const { type } = this.props;
        const orders = this.getOrdersSort();
        const items = orders;

        return new Promise((resolve) => {
            const page = this.parent.current?.querySelector('.ordersWidget');
            const resultParams = this.state.resultParams || {};

            if (page) {
                const { offsetWidth: widthPage } = page;

                const elems = items
                    .map((item) => ({
                        className: `.ordersWidget__card[data-id="${item._id}"]`,
                        id: item._id,
                    }))
                    .concat(...[{ className: '.ordersWidget__cardsInner', id: 'cardsInner' }]);

                const params = getRealParams({
                    parent: page,
                    elems,
                    width: widthPage,
                    classNames: ['_static', `_${type}`],
                    clearStyleElems: ['.ordersWidget__cardsInner'],
                });

                items.filter(this.filterOrder).forEach((item) => {
                    if (params[item._id]) {
                        const { offsetTop, width, offsetLeft } = params[item._id];

                        resultParams[item._id] = {
                            width,
                            offsetTop,
                            offsetLeft,
                        };
                    }
                });

                this.setState({ resultParams, widthInner: params.cardsInner.width }, () => {
                    this.getParamsContent();

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    statusesForPage = {
        new: ['wait-pay', 'new'],
        'in-proccess': ['start-work', 'choice-crew', 'in-proccess', 'wait-close'],
        complete: ['complete', 'cancel'],
    };

    filterOrder(order, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    changeCorporation({ detail: { id: idOfCurrentCorporation } }) {
        if (idOfCurrentCorporation) {
            this.setState({ isReady: false }, () => {
                setTimeout(() => {
                    this.getOrders(true);
                }, 300);
            });
        }
    }

    initFilter({ blocks }) {
        this.handlerFilter.init({ blocks });
    }

    getOrdersSort() {
        const { orders = [] } = this.state;

        return sortOrders(orders);
    }

    getParentForList() {
        return this.parent.current;
    }

    getParentForScroll() {
        const { type } = this.props;

        return type === 'main'
            ? this.parent.current?.querySelector('.widget__contentInner')
            : this.parent.current?.querySelector('.ordersWidget__cards');
    }

    updateOrder({ id, fields }) {
        const { type } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const orders = JSON.parse(JSON.stringify(newState.orders));
                    const indexOrder = orders.findIndex((order) => order._id === id);

                    if (indexOrder !== -1) {
                        const order = orders[indexOrder];

                        [
                            'idOfResponsible',
                            'infoResponsible',
                            'crewTemplate',
                            'status',
                            'crew',
                            'crewInfo',
                            'serviceCompany',
                        ].forEach((prop) => {
                            if (fields[prop]) {
                                orders[indexOrder][prop] = fields[prop];
                            }
                        });

                        if (type === 'preview') {
                            if (['wait-pay', 'new', 'start-work'].indexOf(order.status) === -1) {
                                orders.splice(indexOrder, 1);
                            }
                        } else {
                            const { levels } = this.props;
                            const groupOrder = levels[1];
                            const groupStatuses = this.statusesForPage[groupOrder];

                            if (
                                groupOrder &&
                                groupStatuses &&
                                groupStatuses.indexOf(order.status) === -1
                            ) {
                                orders.splice(indexOrder, 1);
                            }
                        }
                    }

                    newState.orders = orders;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    addOrder({ id }) {
        const query = this.getQueryForRequest();

        getOrders({ id, ...query }).then(
            ({ order }) => {
                this.setState((state) => {
                    const newState = { ...state };
                    const { orders } = newState;

                    if (order) {
                        orders.push(order);
                    }

                    newState.isLimit = false;
                    newState.orders = orders;

                    return newState;
                });
            },
            () => null,
        );
    }

    setScrollPosition({ _id }) {
        const { type } = this.props;
        const card = this.parent.current.querySelector(`.ordersWidget__card[data-id="${_id}"]`);
        const cards = this.getParentForScroll();
        const {
            [type === 'main' ? 'offsetHeight' : 'offsetWidth']: sizeCards,
            [type === 'main' ? 'scrollTop' : 'scrollLeft']: scrollCards,
        } = cards;
        const { [type === 'main' ? 'offsetHeight' : 'offsetWidth']: sizeCard } = card;
        const offsetCard = getPositionFromParent({ target: card, parent: cards })[
            type === 'main' ? 1 : 0
        ];
        const scrollCard = sizeCards / 2 - sizeCard / 2 - offsetCard;

        setAnimate({
            draw: (progress) => {
                cards.scrollTo({
                    [type === 'main' ? 'top' : 'left']: scrollCards - progress * scrollCard,
                });
            },
            duration: 300,
            callback: () => {
                setTimeout(() => {
                    updateWindow({ key: _id, isHideFromScroll: true });
                }, 100);
            },
        });
    }

    renderOrder({ item, prop: _id, key, isShow, isLast }) {
        const { orders = [], resultParams, crewStack, orderLoaderStack } = this.state;
        const { type } = this.props;
        const order = orders.find((orderLoop) => orderLoop._id === _id) || item;
        const resultParam = resultParams?.[_id];

        return (
            <div
                className={`ordersWidget__card ${
                    this.filterOrder(order, key) && isShow ? '_show' : ''
                } ${isLast ? '_last' : ''}`}
                key={order._id}
                data-id={order._id}
                style={{
                    transform: `translate(${resultParam?.offsetLeft}px,${resultParam?.offsetTop}px)`,
                    order: key,
                }}
            >
                <div className="ordersWidget__cardInner">
                    <OrderPreview
                        {...order}
                        parent={this.getParentForList()}
                        parentScroll={this.getParentForScroll()}
                        setScrollPosition={this.setScrollPosition}
                        typePage={type}
                        setParamsCrew={this.setParamsCrew}
                        paramCrew={crewStack?.[_id]}
                        setStateLoaderOrder={this.setStateLoaderOrder}
                        orderLoaderStack={orderLoaderStack}
                        checkRights={this.checkRights}
                    />
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getFilter() {
        const { type } = this.props;

        getFilter({ name: 'orders', type }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    getQueryForRequest() {
        const { filter, counterScroll } = this.state;
        const { levels, type } = this.props;
        const query = {};
        const params = getQueryFilter({ filter });

        if (type === 'preview') {
            params.push({ key: 'statusGroup', value: 'important' });
        } else if (levels[2]) {
            params.push({ key: 'statusGroup', value: levels[2] });
        }

        params.push({ key: 'limit', value: this.stepCounter });
        params.push({ key: 'skip', value: counterScroll ? counterScroll - this.stepCounter : 0 });

        query.params = params;

        return query;
    }

    checkActiveFilter() {
        const { filter } = this.state;
        const params = getQueryFilter({ filter });

        return params.length > 0;
    }

    getOrders(isForce) {
        return new Promise((resolve) => {
            const query = this.getQueryForRequest();

            getOrders(query).then(
                ({ orders: ordersNew, counter }) => {
                    const orders = this.state.orders || [];

                    this.setState(
                        {
                            orders: isForce ? ordersNew : [...orders, ...ordersNew],
                            isLimit: ordersNew.length < this.stepCounter,
                            isDisabledScroll: false,
                            counter,
                        },
                        () => {
                            resolve();

                            if (!this.state.isReady) {
                                setTimeout(() => {
                                    this.setState({ isReady: true });
                                }, 300);
                            }
                        },
                    );
                },
                () => null,
            );
        });
    }

    getMoreOrders(counter, isStart) {
        const actionPrev = () =>
            new Promise((resolve) => {
                this.setState({ isDisabledScroll: true }, () => {
                    if (isStart) {
                        resolve();
                    } else {
                        this.getOrders().then(resolve, () => null);
                    }
                });
            });

        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                actionPrev().then(() => {
                    removeTransition({
                        item: '.ordersWidget__card',
                        isCurrent: true,
                    });

                    setTimeout(() => {
                        this.setState({ isDisabledScroll: false }, resolve);
                    }, 100);
                });
            });
        });
    }

    renderWindowsList({ idOfResponsible, uniqKey: idOfOrder }) {
        const { type } = this.props;

        return (
            <WindowList
                name="responsible"
                className={type === 'main' ? '_centerRight' : '_topCenter'}
                keyParent="orders"
                callback={({ id }) => setOrderResponsible({ id, idOfOrder })}
                idOfCurrent={idOfResponsible}
            />
        );
    }

    handlerUpdateFilter({ filter: filterUpdate, isNotUpdate }) {
        return new Promise((resolve) => {
            if (!isNotUpdate) {
                this.updateOrders();
            }

            this.setState({ filter: filterUpdate }, resolve);
        });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.handlerUpdateFilter}
            />
        );
    }

    updateOrders() {
        return new Promise((resolve) => {
            this.setState({ isReady: false }, () => {
                setTimeout(() => {
                    this.setState(
                        {
                            orders: [],
                            counterScroll: this.stepCounter,
                            isLimit: false,
                        },
                        () => {
                            this.getOrders().then(resolve, () => null);
                        },
                    );
                }, 300);
            });
        });
    }

    checkChangePage(isStart) {
        const { type, levels } = this.props;

        if (type === 'main' && levels[1] === 'orders' && levels[2] !== this.ordersType) {
            this.ordersType = levels[2];

            if (!isStart) {
                this.updateOrders();
            }
        }
    }

    stackCrew = new Stack({ name: 'crewStack' });

    setParamsCrew({ id, params }) {
        this.stackCrew.setInStack({ context: this, id, value: params });
    }

    orderLoaderStack = new Stack({ name: 'orderLoaderStack' });

    setStateLoaderOrder({ id, value }) {
        this.orderLoaderStack.setInStack({ context: this, id, value });
    }

    checkRights() {
        const { user } = this.props;

        return checkRights({ user, key: 'orders', isEdit: true });
    }

    handlerSocket({ detail }) {
        const { orders } = this.state;
        const { name, data } = detail;

        if (orders && name === 'order') {
            const { fields, type, idOfOrder } = data;

            if (type === 'changeInfo') {
                if (orders.find((order) => order._id === idOfOrder)) {
                    this.updateOrder({ id: idOfOrder, fields });
                } else {
                    this.addOrder({ id: idOfOrder });
                }
            }
            if (type === 'create') {
                this.addOrder({ id: idOfOrder });
            }
        }
    }

    getOrdersEvent() {
        this.setState({ isReady: false }, () => {
            setTimeout(() => {
                this.getOrders(true);
            }, 300);
        });
    }

    componentDidMount() {
        this.getOrders();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: (isNotUpdate) => {
                const { filter } = this.state;

                updateWindow({ key: `filter-orders`, filter });

                if (!isNotUpdate) {
                    this.setState({
                        keyFilterUpdate: checkValueOfEmpty(this.state.keyFilterUpdate)
                            ? this.state.keyFilterUpdate + 1
                            : 0,
                    });
                }
                this.getParams(true);
            },
        });

        this.checkChangePage(true);

        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('getOrders', this.getOrdersEvent);
    }

    componentDidUpdate() {
        this.checkChangePage();
    }

    componentWillUnmount() {
        document.removeEventListener('changeCorporation', this.changeCorporation);
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('getOrders', this.getOrdersEvent);
    }

    render() {
        const {
            isShowLoaderList,
            resultParams,
            isReady,
            filter,
            widthInner = 0,
            isLimit,
            keyFilterUpdate,
            isDisabledScroll,
            counter = 0,
        } = this.state;
        let { heightCards } = this.state;
        const { counterChangePage, type } = this.props;
        const ordersSort = this.getOrdersSort();
        const ordersFilterForList = ordersSort.filter(this.filterOrder);
        const ordersFilter = ordersSort.filter(this.filterOrder);

        const infoEmpty = this.getInfoEmpty();
        const isEmpty = isReady && ordersFilter.length === 0;

        if (isEmpty || !isReady) {
            heightCards = 300;
        }

        return (
            <div ref={this.parent} className={`widget _ready _parent`}>
                <Windows
                    name={`filter-${type}`}
                    renderContent={this.renderFilter}
                    keyFilterUpdate={keyFilterUpdate}
                />
                <Windows name="listResponsibleOrders" renderContent={this.renderWindowsList} />
                <div className="widget__head _row">
                    {type === 'main' && <Back />}
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Заказы"
                                className={`${
                                    type === 'main' && counterChangePage > 0 ? '_withBack' : ''
                                }`}
                            >
                                <AnimateChange
                                    className="widget__headNameItemInner"
                                    prop={counter}
                                    type="_translateMedium"
                                >
                                    <>({counter})</>
                                </AnimateChange>
                            </InfoHead>
                            <div className="widget__headActions">
                                <div className="widget__headActionsGroups _row">
                                    {this.getPermissions({
                                        key: 'orders',
                                        items: [{ key: 'main', rules: ['create'] }],
                                    }) && (
                                        <>
                                            <div className="widget__headActionsGroup">
                                                <LinkActions name="new-order" />
                                            </div>
                                            <div className="widget__headActionsGroup">
                                                <div
                                                    className="widget__headLink _main"
                                                    onClick={() => {
                                                        handlerPopup({
                                                            name: 'isUploadOrdersPopupShow',
                                                            isShow: true,
                                                        });
                                                    }}
                                                >
                                                    <div className="widget__headLinkInner _click">
                                                        Импорт из Excel
                                                        <i className="widget__headLinkInfo">
                                                            <Icon name="info" />
                                                        </i>
                                                    </div>
                                                    <div className="widget__headLinkAlert">
                                                        <UploadTemplateInfo />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="widget__headActionsGroup">
                                        <WidgetFilter
                                            name="orders"
                                            filter={filter}
                                            getParent={() => this.parent.current}
                                            handlerFilter={this.handlerFilter}
                                            windowName={`filter-${type}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`widget__content ${type === 'main' ? '_scroll _full' : ''}`}>
                    <Animate className="widget__loader _loader" isShow={!isReady}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentInner">
                        <div className={`ordersWidget ${isReady ? '_ready' : ''} _${type}`}>
                            <Animate className="ordersWidget__empty" isShow={isEmpty && isLimit}>
                                <div className="empty _col _block _notBack">
                                    <AnimateChange
                                        className="empty__inner"
                                        type="_translate _transFast"
                                        prop={infoEmpty.title}
                                        isDisabled={!isEmpty}
                                    >
                                        <div className="empty__innerItem">
                                            <div className="empty__title">{infoEmpty.title}</div>
                                            <p
                                                className="empty__content"
                                                dangerouslySetInnerHTML={{
                                                    __html: infoEmpty.description,
                                                }}
                                            ></p>
                                        </div>
                                    </AnimateChange>
                                </div>
                            </Animate>
                            <div className="ordersWidget__cards">
                                <Animate
                                    className="ordersWidget__cardsInner"
                                    style={
                                        type === 'main'
                                            ? { height: `${heightCards}px` }
                                            : { width: `${widthInner}px` }
                                    }
                                    isShow={isReady}
                                >
                                    <ListScroll
                                        getParent={this.getParentForScroll}
                                        callback={this.getMoreOrders}
                                        startCounter={this.stepCounter}
                                        stepCounter={this.stepCounter}
                                        maxCounter={Infinity}
                                        lengthCurrent={ordersFilterForList.length}
                                        direction={type !== 'main' && 'horizontal'}
                                        handlerLoaderList={this.handlerLoaderList}
                                        classNamesForParams={[`_${type}`]}
                                        isLimit={isLimit}
                                        keyUpdate={keyFilterUpdate}
                                        isDisabledScroll={isDisabledScroll || !isReady}
                                    >
                                        <ListDynamic
                                            items={ordersFilter}
                                            renderItem={this.renderOrder}
                                            callback={() => {
                                                this.getParams();
                                            }}
                                            resultParams={resultParams}
                                            prop="_id"
                                            propsForUpdate={['idOfResponsible', 'status']}
                                            sort={sortOrders}
                                            classNamesForParams={[`_${type}`]}
                                        />
                                    </ListScroll>
                                </Animate>
                            </div>
                        </div>
                    </div>
                    <Animate
                        className={`widget__pageLoader ${type === 'main' ? '' : '_right'} ${
                            isEmpty && !isLimit ? '_loader' : '_loaderScroll'
                        }`}
                        isShow={isShowLoaderList}
                    >
                        <div className="widget__pageLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Orders);

Orders.propTypes = {
    type: PropTypes.string,
    user: PropTypes.object,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
};
