import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from '../../../../AnimateChange.jsx';
import ImageLazy from '../../../../ImageLazy.jsx';
import Field from '../../../../Field.jsx';
import Select from '../../../../Select.jsx';
import Animate from '../../../../Animate.jsx';

import setServerData from '../../../../../functions/setServerData';
import handlerWindow from '../../../../../functions/handlerWindow';
import Loader from '../../../../Loader.jsx';
import handlerSupportCursor from '../../../../../functions/handlerSupportCursor';

class MainPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerList = this.handlerList.bind(this);
    }

    orderFields = ['markCar', 'modelCar', 'numberCar'];

    fields = {
        markCar: {
            keyName: 'mark',
        },
        modelCar: {
            keyName: 'model',
        },
        numberCar: {
            keyName: 'number',
        },
    };

    handlerList({ target }) {
        const { car, getParent, handler } = this.props;
        const parent = getParent();

        handlerWindow({
            parent,
            target,
            action: 'show',
            name: 'listBodyworks',
            className: '_listBodyworks',
            uniqKey: car._id,
            bodyworkDefault: car.bodywork,
            handler: (value) =>
                new Promise((resolve) => {
                    handler({ action: 'change', name: 'bodywork', value, block: 'params' }).then(
                        resolve,
                    );
                }),
            centers: {
                left: 0.5,
                top: 1,
            },
        });
    }

    componentDidMount() {
        setServerData('carBodyworks');
    }

    render() {
        const {
            serverData,
            car = {},
            handler,
            isDisabled,
            marksOfCar = [],
            modelsOfCar = [],
            errors,
            setStateLoader,
            stateLoader,
        } = this.props;
        const { carBodyworks } = serverData;
        const bodyworkCurrent = car.bodywork || 'tent';
        const bodyworkInfo = carBodyworks?.[bodyworkCurrent];
        const bodyworkImage = bodyworkInfo?.image;
        const isLoading = !this.props.marksOfCar || !this.props.modelsOfCar;
        const isError = errors.find((error) => ['mark', 'model', 'number'].indexOf(error) !== -1);

        let src = `${process.env.REACT_APP_STATIC}/car-icons/${bodyworkImage}`;

        if (car?.type === 'trailer') {
            src = require('../../../../../img/crm/trailer-icon.png');
        }

        return (
            <div
                className={`manualCardMainPreview _row ${!car.bodywork ? '_empty' : ''} _car ${
                    !isDisabled ? '_edit' : ''
                } ${isError ? '_error' : ''}`}
            >
                <div
                    className="manualCardMainPreview__item _col _image"
                    onMouseEnter={(e) => {
                        if (car.bodywork) {
                            handlerSupportCursor({
                                action: 'enter',
                                content: bodyworkInfo?.short,
                                e,
                                data: { className: '_normalCase _center' },
                            });
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (car.bodywork) {
                            handlerSupportCursor({
                                action: 'leave',
                                content: bodyworkInfo?.short,
                                e,
                            });
                        }
                    }}
                >
                    <AnimateChange
                        className="manualCardMainPreview__image _col"
                        prop={src}
                        type="_translateMedium"
                    >
                        <ImageLazy
                            src={src}
                            className="manualCardMainPreview__imageItem"
                            setStateLoader={setStateLoader}
                            stateLoader={stateLoader}
                        />
                    </AnimateChange>
                    <Animate className="manualCardMainPreview__imageChange" isShow={!isDisabled}>
                        <div
                            className="manualCardMainPreview__imageChangeLink _click"
                            onClick={this.handlerList}
                        >
                            Выбрать
                        </div>
                    </Animate>
                </div>
                <div className="manualCardMainPreview__item _col _content">
                    <Animate className="manualCardMainPreview__itemLoader _loader" isShow={isLoading}>
                        <div className="manualCardMainPreview__itemLoaderItem _loaderItem">
                            <Loader className="_main _mediumSize" />
                        </div>
                    </Animate>
                    <Animate className="manualCardMainPreview__itemInner" isShow={!isLoading}>
                        {this.orderFields.map((name) => {
                            const infoField = this.fields[name];
                            const { keyName } = infoField;
                            const { [keyName]: value } = car;

                            if (name === 'numberCar') {
                                return (
                                    <div className="manualCardMainPreview__field" key={name}>
                                        <Field
                                            keyName={name}
                                            name={keyName}
                                            value={value}
                                            callback={handler}
                                            className={`_manualMain _whiteBack ${
                                                isDisabled ? '_notBack' : ''
                                            }`}
                                            isDisabled={isDisabled}
                                            isEditmode={true}
                                            error={errors.indexOf(keyName) !== -1}
                                        />
                                    </div>
                                );
                            }

                            let items = [];

                            if (name === 'markCar') {
                                items = marksOfCar.map((mark) => ({
                                    key: mark._id,
                                    content: mark.name,
                                }));
                            }

                            if (name === 'modelCar') {
                                if (!car.mark) {
                                    items = modelsOfCar.map((model) => ({
                                        key: model._id,
                                        content: model.name,
                                    }));
                                } else {
                                    items = modelsOfCar
                                        .filter((model) => model.idOfMark === car.mark)
                                        .map((model) => ({
                                            key: model._id,
                                            content: model.name,
                                        }));
                                }
                            }

                            return (
                                <div className="manualCardMainPreview__field _select" key={name}>
                                    <Select
                                        type={name}
                                        name={keyName}
                                        value={value}
                                        callback={handler}
                                        className={`_manualMain _${name} _whiteBack ${
                                            isDisabled ? '_notBack' : ''
                                        } _fullWidthList`}
                                        isDisabled={isDisabled}
                                        isEditmode={true}
                                        items={items}
                                        propsForResponse={{ modelsOfCar }}
                                        error={errors.indexOf(keyName) !== -1}
                                    />
                                </div>
                            );
                        })}
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(MainPreview);

MainPreview.propTypes = {
    car: PropTypes.object,
    handler: PropTypes.func,
    isDisabled: PropTypes.bool,
    serverData: PropTypes.object,
    getParent: PropTypes.func,
    modelsOfCar: PropTypes.array,
    marksOfCar: PropTypes.array,
    errors: PropTypes.array,
    setStateLoader: PropTypes.func,
    stateLoader: PropTypes.any,
};
