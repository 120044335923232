import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerRoute from '../../classes/order/Route';

import Status from '../../components/order/Status.jsx';
import getFormatedDate from '../../functions/getFormatedDate';
import Ymaps from '../../components/Ymaps.jsx';
import getFormatPrice from '../../functions/getFormatPrice';
import Table from '../../components/crm/manual/Table.jsx';
import getOrders from '../../requests/getOrders';
import ListScroll from '../../components/ListScroll.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

class OrderGps extends Table {
    constructor(props) {
        super(props);
        this.state = {
            mapInfo: {
                center: [55.755819, 37.617644],
                zoom: 10,
                controls: [],
            },
        };

        this.renderGroup = this.renderGroup.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.showCurrentPoint = this.showCurrentPoint.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 50;

    colsOrder = ['date', 'speed', 'lat', 'long', 'distance', 'time', 'price'];

    cols = {
        date: 'Дата и время',
        // status: 'Статус исполнителя',
        speed: 'Скорость',
        lat: 'Широта',
        long: 'Долгота',
        distance: 'Пробег',
        time: 'Время',
        price: 'Цена',
    };

    getGroups() {
        const { items = [] } = this.state;
        const { order = {} } = this.props;
        const { route } = order;
        const groups = [];

        const getKey = (item) => (item ? `${item.currentPointId}-${item.currentPointStatus}` : '');

        items.forEach((item, itemKey) => {
            const key = getKey(item);
            const prevKey = getKey(items[itemKey - 1]);
            let groupIndex = groups.findIndex((group) => group.key === key);

            if (!prevKey || key !== prevKey) {
                const currentPoint = route.find(
                    (point) => point._id.toString() === item.currentPointId,
                );

                if (currentPoint) {
                    groups.push({
                        key,
                        id: item.currentPointId,
                        status: item.currentPointStatus,
                        address: currentPoint?.address,
                        items: [],
                    });

                    groupIndex = groups.length - 1;
                }
            }

            const date = new Date(item.dateOfCreate);
            const dateString = getFormatedDate({ date, isShortYear: true });
            const timeString = getFormatedDate({ date, type: 'time', withSeconds: true });

            if (groupIndex !== -1) {
                groups[groupIndex].items.push({ ...item, dateString, timeString });
            }
        });

        if (groups.length === 0) {
            groups.push({
                key: 'info',
            });
        }

        return groups;
    }

    renderRow({ item }) {
        return (
            <div
                className={`orderDetailsGps__tableRow _row _click _inner _${item.currentPointStatus}`}
                onClick={() => {
                    this.showCurrentPoint(item._id);
                }}
            >
                {this.colsOrder.map((name) => {
                    let content = '–';

                    if (name === 'date') {
                        content = getFormatedDate({
                            date: new Date(item.createdDate),
                            type: 'full',
                            withSeconds: true,
                            withoutYear: true,
                        });
                    }

                    if (name === 'speed') {
                        content = `≈${+item.speed.toFixed(1)}км/ч`;
                    }

                    if (name === 'lat') {
                        content = item.coords[0];
                    }

                    if (name === 'long') {
                        content = item.coords[1];
                    }

                    if (name === 'distance') {
                        if (item.distance < 1000) {
                            content = `${+item.distance.toFixed(3)}м`;
                        } else {
                            content = `${+(item.distance / 1000).toFixed(2)}км`;
                        }
                    }

                    if (name === 'time') {
                        const toFormatTime = () => {
                            const time = +item.time;
                            const seconds = Math.floor(time % 60);
                            const minutes = Math.floor((time / 60) % 60);
                            const hours = Math.floor((time / 3_600) % 3_600);

                            let resultTime = '';

                            if (hours) {
                                resultTime += `${hours}ч`;
                            }

                            if (minutes) {
                                resultTime += ` ${minutes}м`;
                            }

                            if (!hours) {
                                resultTime += ` ${seconds}с`;
                            }

                            return resultTime;
                        };

                        content = toFormatTime();
                    }

                    if (name === 'price') {
                        content = `${getFormatPrice(item.price)}₽`;
                    }

                    return (
                        <div className={`orderDetailsGps__tableCol _${name}`} key={name}>
                            {content}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderGroup({ item: group }) {
        if (group.key === 'info') {
            return (
                <div className="orderDetailsGps__tableGroup">
                    <div className="orderDetailsGps__tableGroupInfo">
                        <div className="empty _col _notBack _block">
                            <div className="empty__inner">
                                <div className="empty__title">Список GPS пуст</div>
                                <p className="empty__content">
                                    GPS будет передаваться, когда
                                    <br />
                                    экипаж начнет выполнение заказа
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const { order } = this.props;
        const resultGroup = group;
        const currentPoint = order.route.find((point) => point._id === resultGroup.id);

        return (
            <div className="orderDetailsGps__tableGroup">
                <div className="orderDetailsGps__tableGroupHead _row">
                    <div className="orderDetailsGps__tableGroupStatus">
                        <Status
                            status={{
                                name: 'in-proccess',
                                idPoint: resultGroup.id,
                                statusKey: currentPoint.statuses.findIndex(
                                    (status) => status.name === resultGroup.status,
                                ),
                            }}
                            order={{
                                systemType: order.systemType,
                                crew: order.crew,
                                tariffInfo: order.tariffInfo,
                                status: 'in-proccess',
                                route: order.route,
                                type: 'point',
                            }}
                            className="_gps"
                        />
                    </div>
                    <div className="orderDetailsGps__tableGroupAddress">{resultGroup.address}</div>
                </div>
                <div className="orderDetailsGps__tableGroupContent">
                    {resultGroup.items.map((row) => this.renderRow({ item: row }))}
                </div>
            </div>
        );
    }

    handlerRoute = new HandlerRoute({
        context: this,
        mapDisabled: true,
    });

    showCurrentPoint(id) {
        const { ymaps, map, allGps = [] } = this.state;
        const point = allGps.find((item) => item._id === id);

        if (this.currentPoint) {
            map.geoObjects.remove(this.currentPoint);
        }

        this.currentPoint = new ymaps.Placemark(
            point.coords,
            {},
            {
                iconLayout: 'default#image',
                iconImageHref: require(`../../img/map-car.png`),
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -16],
            },
        );

        map.geoObjects.add(this.currentPoint);

        const isShow = ymaps.util.bounds.containsPoint(map.getBounds(), point.coords);

        if (!isShow) {
            map.setCenter(point.coords, 16, {
                checkZoomRange: true,
            });
        }
    }

    setPolyline(isInit) {
        const { ymaps, map, allGps = [] } = this.state;
        const { order } = this.props;
        const { route = [] } = order;

        if (allGps.length) {
            const geometry = allGps.map(({ coords }) => coords);
            const options = {
                strokeColor: '#3e6aed',
                strokeWidth: 1,
            };

            if (this.polyline) {
                map.geoObjects.remove(this.polyline);
            }

            this.polyline = new ymaps.Polyline(geometry, {}, options);

            map.geoObjects.add(this.polyline);

            if (isInit) {
                route.forEach((point) => {
                    const pointPlacemark = new ymaps.Placemark(
                        point.coords,
                        {},
                        { preset: 'islands#dotIcon' },
                    );

                    map.geoObjects.add(pointPlacemark);
                });

                map.setCenter(allGps[allGps.length - 1].coords, 17, {
                    checkZoomRange: true,
                });
            }

            this.showCurrentPoint(allGps[allGps.length - 1]._id);
        }
    }

    initMap() {
        const { ymaps, map } = this.state;

        if (ymaps && map && !this.isInitMap) {
            this.isInitMap = true;

            this.handlerRoute
                .init({
                    mapInfo: this.state.mapInfo,
                    isUpdate: true,
                })
                .then(() => {
                    this.getAllGps().then(() => {
                        this.setPolyline(true);
                    });
                });
        }
    }

    checkChangeGps() {
        const { order = {}, setHeightPage } = this.props;
        const { gps = [] } = order;

        if (gps.length !== this.gpsLen) {
            this.gpsLen = gps.length;

            if (this.polyline) {
                this.setPolyline();
            }

            setHeightPage();

            this.setScroll();
        }
    }

    setScroll() {
        const table = this.parent.current.querySelector('.orderDetailsGps__table');

        if (table) {
            const resultScroll = table.scrollHeight - table.offsetHeight;

            table.scrollTop = resultScroll;
        }
    }

    getAllGps() {
        const { order } = this.props;

        return new Promise((resolve) => {
            getOrders({
                id: order._id,
                params: [
                    { key: 'type', value: 'gps' },
                    { key: 'gpsFull', value: true },
                ],
            }).then(({ gps }) => {
                this.setState({ allGps: gps }, () => {
                    resolve();
                });
            });
        });
    }

    getItems() {
        const { order } = this.props;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'gps' });

        return new Promise((resolve) => {
            getOrders({ id: order._id, ...query }).then(({ gps, isLimit, counter }) => {
                this.setItems(gps, false, isLimit, counter).then(() => {
                    this.setState({ isShowLoaderList: false });

                    // removeTransition({
                    //     item: '.chatUserSettings__contentListItem',
                    //     isCurrent: true,
                    // });

                    resolve();
                });
            });
        });
    }

    componentDidMount() {
        super.componentDidMount();

        const { setHeightPage } = this.props;

        setHeightPage();

        this.initMap();
        this.checkChangeGps();

        this.setScroll();
    }

    componentDidUpdate() {
        this.initMap();
        this.checkChangeGps();
    }

    render() {
        const { items = [], isLimit, isDisabledScroll, isShowLoaderList = false } = this.state;
        const groups = this.getGroups();

        return (
            <div ref={this.parent} className="orderDetailsGps">
                <div className="orderDetailsGps__inner">
                    <div className="orderDetailsGps__map">
                        <Ymaps {...this.state.mapInfo} callback={this.handlerRoute.bootstrap} />
                    </div>
                    <div className="orderDetailsGps__content">
                        <div className="orderDetailsGps__table">
                            <ListScroll
                                getParent={() =>
                                    this.parent.current?.querySelector('.orderDetailsGps__table')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={items.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll}
                            >
                                <div className="orderDetailsGps__tableInner">
                                    <div className="orderDetailsGps__tableRow _row _head">
                                        {this.colsOrder.map((name) => {
                                            const col = this.cols[name];

                                            return (
                                                <div
                                                    className={`orderDetailsGps__tableCol _${name}`}
                                                    key={name}
                                                >
                                                    {col}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="orderDetailsGps__tableGroups">
                                        {groups.map((group) => this.renderGroup({ item: group }))}
                                    </div>
                                </div>
                            </ListScroll>
                        </div>
                        <Animate
                            className="orderDetailsGps__contentScrollLoader _loaderScroll _white"
                            isShow={isShowLoaderList}
                        >
                            <div className="orderDetailsGps__contentScrollLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderGps);

OrderGps.propTypes = {
    order: PropTypes.object,
    setHeightPage: PropTypes.func,
};
