import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../../Button.jsx';

import getUserInfo from '../../../../functions/getUserInfo';
import getHeaders from '../../../../functions/getHeaders';
import handlerWindow from '../../../../functions/handlerWindow';
import setNotification from '../../../../functions/setNotification';

class LikeExecutorWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setExecutor = this.setExecutor.bind(this);

        this.parent = React.createRef();
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    setExecutor() {
        const { itemId, executor } = this.props;

        this.handlerLoading('save').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/pays`,
                    { id: itemId, action: 'set-executor', executorId: executor._id },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (success) {
                            handlerWindow({
                                action: 'hide',
                            });

                            setNotification({ notification: 'success-change-info' });
                        }

                        this.handlerLoading(null);
                    },
                    () => null,
                );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { executor } = this.props;
        const firstLetter = (executor.secondName ||
            executor.firstName ||
            executor.thirdName)?.[0]?.toUpperCase();

        return (
            <div ref={this.parent} className="likeExecutorWindow">
                <div className="likeExecutorWindow__inner">
                    <div className="likeExecutorWindow__title">
                        Такой исполнитель не найден. <br />
                        Возможно, вы имели ввиду:
                    </div>
                    <div className="likeExecutorWindow__info _row">
                        <div className="likeExecutorWindow__infoAvatar _col">{firstLetter}</div>
                        <div className="likeExecutorWindow__infoContent">
                            <div className="likeExecutorWindow__infoName">
                                {getUserInfo({ type: 'name', user: executor })}
                            </div>
                            <div className="likeExecutorWindow__infoType">{executor.typeName}</div>
                        </div>
                    </div>
                    <div className="likeExecutorWindow__button">
                        <Button
                            className="_mediumSize"
                            onClick={this.setExecutor}
                            showLoader={loadingKey}
                        >
                            Выбрать предложенное ФИО
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(LikeExecutorWindow);

LikeExecutorWindow.propTypes = {
    executor: PropTypes.object,
    payId: PropTypes.string,
    itemId: PropTypes.string,
};
