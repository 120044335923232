import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Point from './Point.jsx';
import DragBox from '../DragBox.jsx';

import setHeightItems from '../../functions/setHeightItems';
import removeTransition from '../../functions/removeTransition.ts';
import setAnimate from '../../functions/setAnimate';

class Points extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightItems = this.setHeightItems.bind(this);

        this.parent = React.createRef();
    }

    offset = 6;

    filterPoint(point) {
        return point.state === 1 && !point.isDelete;
    }

    classItem = '.orderPoints__item';

    classItemStatic = '.orderPoint__innerBox';

    setHeightItems() {
        const { points, setHeightPage, getParent } = this.props;

        setHeightItems
            .call(this, {
                parent: this.parent.current,
                items: points,
                classItem: this.classItem,
                classItemStatic: this.classItemStatic,
            })
            .then(() => {
                if (setHeightPage) {
                    setHeightPage();
                }

                if (process.env.REACT_APP_SYSTEM === 'crm' && getParent) {
                    const pageCurrent = getParent().querySelector('.widget__page._show');

                    if (pageCurrent) {
                        const pageBoxCurrent = pageCurrent.querySelector(
                            '.widget__pageBox._scroll',
                        );

                        if (pageBoxCurrent) {
                            setAnimate({
                                draw: () => {
                                    pageBoxCurrent.dispatchEvent(new CustomEvent('scroll'));
                                },
                                duration: 300,
                                callback: () => null,
                            });
                        }
                    }
                }
            });
    }

    render() {
        const { heightItems } = this.state;
        const {
            order,
            points,
            counterSort,
            handlerRoute,
            isProccessSetRoute,
            isProccessOptimization,
            newOrder,
            isDisabled,
            type,
            fakeCallback,
            cargoList,
            formDataImages,
            getParent,
            isNew,
            errors,
            handlerErrors,
        } = this.props;

        return (
            <div ref={this.parent} className="orderPoints">
                <DragBox
                    className={`orderPoints__inner`}
                    classItem={this.classItem}
                    classItemStatic={this.classItemStatic}
                    key={counterSort}
                    items={points}
                    setHeightItems={this.setHeightItems}
                    heightItems={heightItems}
                    offset={this.offset}
                    sort={isProccessOptimization ? newOrder : null}
                    callbackInit={() => {
                        if (process.env.REACT_APP_SYSTEM === 'crm') {
                            removeTransition({ item: '.orderPoints__inner' });
                        }
                    }}
                >
                    {points &&
                        points.map((point, key) => {
                            const { state, uniqKey } = point;
                            let isDisabledPoint = isDisabled;
                            let indexCurrentItem;

                            let keyPoint = points
                                .filter(this.filterPoint)
                                .findIndex((item) => item.uniqKey === point.uniqKey);

                            if (keyPoint === -1) {
                                keyPoint = key;
                            }

                            if (isProccessOptimization && newOrder) {
                                keyPoint = newOrder[keyPoint];
                            }

                            if (
                                type === 'details' &&
                                order &&
                                [
                                    'new',
                                    'start-work',
                                    'choice-crew',
                                    'wait-close',
                                    'complete',
                                    'cancel',
                                ].indexOf(order.status) === -1
                            ) {
                                const { idOfCurrentPoint } = order;
                                indexCurrentItem = points.findIndex(
                                    (item) => item._id === idOfCurrentPoint,
                                );

                                isDisabledPoint = isDisabled || key < indexCurrentItem;
                            }

                            return (
                                <div
                                    className={`orderPoints__item ${
                                        state === 1 ? '_show' : ''
                                    } _${uniqKey}`}
                                    key={uniqKey}
                                    data-id={uniqKey}
                                    data-key={key}
                                >
                                    <div className="orderPoints__itemInner">
                                        <div className="orderPoints__itemBox">
                                            <Point
                                                order={order}
                                                number={keyPoint}
                                                point={point}
                                                points={points}
                                                handlerRoute={handlerRoute}
                                                isProccessSetRoute={isProccessSetRoute}
                                                setHeightItems={this.setHeightItems}
                                                offset={this.offset}
                                                isDisabled={isDisabledPoint}
                                                isCurrent={indexCurrentItem === key}
                                                type={type}
                                                indexCurrentItem={indexCurrentItem}
                                                fakeCallback={fakeCallback}
                                                cargoList={cargoList}
                                                formDataImages={formDataImages}
                                                getParent={getParent}
                                                idOfCurrentPoint={order?.idOfCurrentPoint}
                                                statusOrder={order?.status}
                                                isNew={isNew}
                                                errors={errors}
                                                handlerErrors={handlerErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </DragBox>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Points);

Points.propTypes = {
    order: PropTypes.object,
    points: PropTypes.array,
    counterSort: PropTypes.number,
    handlerRoute: PropTypes.object,
    isProccessSetRoute: PropTypes.bool,
    isProccessOptimization: PropTypes.bool,
    newOrder: PropTypes.array,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    fakeCallback: PropTypes.func,
    cargoList: PropTypes.array,
    formDataImages: PropTypes.object,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    handlerErrors: PropTypes.func,
};
