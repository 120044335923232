import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import BankCard from '../../../../../components/crm/manual/organization/BankCard.jsx';
import Editmode from '../../../../../classes/Editmode';
import Button from '../../../../../components/Button.jsx';
import createId from '../../../../../requests/createId';
import getHeaders from '../../../../../functions/getHeaders';
import setNotification from '../../../../../functions/setNotification';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';

class ManualOrganizationsInnerBankCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.renderCard = this.renderCard.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.addCard = this.addCard.bind(this);
        this.changeCard = this.changeCard.bind(this);
        this.deleteSaveCard = this.deleteSaveCard.bind(this);
        this.saveCard = this.saveCard.bind(this);
        this.sortCards = this.sortCards.bind(this);
        this.setErrors = this.setErrors.bind(this);

        this.parent = React.createRef();
    }

    getCards() {
        const { organization, checkRights } = this.props;
        const cards = [];

        if (organization) {
            const bankCards = this.sortCards(organization.bankCards).reverse();

            cards.push(...bankCards);
        }

        if (checkRights?.('organizationInfo')) {
            cards.push({ _id: 'info' });
        }

        return cards;
    }

    renderCard({ item, prop: _id }) {
        const { loadingKey } = this.state;

        if (_id === 'info') {
            return (
                <div className="manualOrganizations__card _parentForEdit _editBack _info">
                    <div className="empty _col _block">
                        <div className="empty__inner">
                            <div className="empty__title">Пока это все Банковские карты</div>
                            <p className="empty__content">
                                Чтобы добавить карту, нажмите
                                <br />
                                на кнопку «+Новая карта» ниже
                            </p>
                            <div className="empty__button _show">
                                <Button
                                    className="_mediumSize _mainNotBorder"
                                    onClick={this.addCard}
                                    showLoader={loadingKey === 'add'}
                                >
                                    + Новая карта
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const { editName, errors } = this.state;
        const { organization, organizationSave, getParent, checkRights } = this.props;

        return (
            <div className={`manualOrganizations__card _parentForEdit _editBack ${editName === _id ? '_current' : ''}`}>
                <BankCard
                    {...item}
                    isEdit={editName === _id}
                    handlerEditmode={this.handlerEditmode}
                    idOfClient={organization._id}
                    parent={getParent()}
                    changeCard={({ ...props }) =>
                        new Promise((resolve) =>
                            this.changeCard({ id: _id, ...props }).then(resolve),
                        )
                    }
                    isCurrent={organization.idOfCurrentBankCard === _id}
                    isSaveCurrent={organizationSave.idOfCurrentBankCard === _id}
                    deleteSaveCard={this.deleteSaveCard}
                    saveCard={this.saveCard}
                    loadingKey={loadingKey}
                    errors={errors[_id]}
                    isDisabled={!checkRights?.('organizationInfo')}
                />
            </div>
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    changeCard({ id, action, name, value }) {
        const { organization, organizationSave, changeOrganization } = this.props;
        let resultValue;
        let resultName;

        if (name === 'idOfCurrentBankCard') {
            resultName = name;
            resultValue = value ? id : organizationSave.idOfCurrentBankCard;
        } else {
            const cards = JSON.parse(JSON.stringify(organization.bankCards));
            const card = cards.find((innerCard) => innerCard._id === id);

            resultName = 'bankCards';

            card[name] = value;

            resultValue = cards;

            this.setErrors({ id, error: name });
        }

        return new Promise((resolve) => {
            changeOrganization({ action, name: resultName, value: resultValue }).then(() => {
                resolve();
            });
        });
    }

    sortCards(cards) {
        return JSON.parse(JSON.stringify(cards)).sort((a, b) => {
            const aWeight = a.isNew ? 1 : 0;
            const bWeight = b.isNew ? 1 : 0;

            return bWeight - aWeight;
        });
    }

    clearNewCard() {
        const { organization, changeOrganization, setOrganization } = this.props;

        return new Promise((resolve) => {
            const cards = JSON.parse(JSON.stringify(organization.bankCards));
            const card = cards.find((innerCard) => innerCard.isNew);

            card.isNew = undefined;

            changeOrganization({ action: 'change', name: 'bankCards', value: cards }).then(() => {
                setOrganization({ organization: this.props.organization });

                this.handlerEditmode({ editName: null }).then(resolve);
            });
        });
    }

    deleteSaveCard(id) {
        const { organization, changeOrganization } = this.props;

        return new Promise((resolve) => {
            const cards = JSON.parse(JSON.stringify(organization.bankCards));
            const cardIndex = cards.findIndex((card) => (id ? card._id === id : card.isNew));

            if (cardIndex !== -1) {
                cards.splice(cardIndex, 1);
            }

            changeOrganization({ action: 'change', name: 'bankCards', value: cards }).then(() => {
                this.handlerEditmode({ editName: null }).then(resolve);
            });
        });
    }

    addCard() {
        const { organization, changeOrganization, scrollToTop } = this.props;

        return new Promise((resolve) => {
            this.handlerLoadingKey('add').then(() => {
                createId().then(
                    ({ id }) => {
                        const cards = JSON.parse(JSON.stringify(organization.bankCards)).concat(
                            ...[{ _id: id, number: '', isNew: true }],
                        );

                        changeOrganization({
                            action: 'change',
                            name: 'bankCards',
                            value: cards,
                        }).then(() => {
                            this.handlerEditmode({ editName: id }).then(() => {
                                scrollToTop();

                                this.handlerLoadingKey(null).then(resolve);
                            });
                        });
                    },
                    () => null,
                );
            });
        });
    }

    setErrors({ id, errors, error }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const stateErrors = newState.errors;

                if (!stateErrors[id]) {
                    stateErrors[id] = {};
                }

                if (errors) {
                    errors.forEach((key) => {
                        stateErrors[id][key] = true;
                    });
                } else if (error) {
                    stateErrors[id][error] = undefined;
                } else {
                    stateErrors[id] = {};
                }

                newState.errors = stateErrors;

                return newState;
            }, resolve);
        });
    }

    validateCard(card) {
        const errors = [];

        ['type', 'number'].forEach((prop) => {
            if (!card[prop]) {
                errors.push(prop);
            }
        });

        return { errors };
    }

    checkChange({ id }) {
        const { organization, organizationSave } = this.props;
        const card = organization.bankCards.find((innerCard) => innerCard._id === id);
        const saveCard = organizationSave.bankCards.find((innerCard) => innerCard._id === id);
        let isChange = false;

        if (!saveCard) {
            isChange = true;
        } else {
            ['type', 'number'].forEach((prop) => {
                if (card[prop] !== saveCard[prop]) {
                    isChange = true;
                }
            });
        }

        if (organization.idOfCurrentBankCard !== organizationSave.idOfCurrentBankCard) {
            isChange = true;
        }

        return { isChange };
    }

    saveCard({ id }) {
        const { organization, setOrganization } = this.props;
        const card = organization.bankCards.find((innerCard) => innerCard._id === id);
        const fields = card.isNew
            ? {
                  newBankCard: {
                      _id: card._id,
                      number: card.number,
                      type: card.type,
                      isCurrent: organization.idOfCurrentBankCard === card._id,
                  },
              }
            : {
                  bankCard: { ...card, isCurrent: organization.idOfCurrentBankCard === card._id },
              };

        const { errors } = this.validateCard(card);
        const { isChange } = this.checkChange({ id });

        return new Promise((resolve) => {
            if (!isChange) {
                this.handlerEditmode({ editName: null });

                resolve();
            } else if (errors.length) {
                this.setErrors({ id, errors });

                setNotification({ notification: 'required-fields-not-complete' });
            } else {
                this.handlerLoadingKey(id).then(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/organization`,
                            {
                                fields,
                                id: organization._id,
                                action: 'changeInfo',
                            },
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then((res) => {
                            const { success, data } = res.data;

                            if (success) {
                                if (card.isNew) {
                                    this.clearNewCard();

                                    setNotification({
                                        notification: 'success-create-bank-card',
                                    });
                                } else {
                                    setNotification({
                                        notification: 'success-update-bank-card',
                                    });

                                    setOrganization({ organization });
                                }

                                this.handlerEditmode({ editName: null });

                                resolve();
                            } else {
                                const { errors: resErrors = [] } = data;

                                resErrors.forEach((error) => {
                                    const { message } = error;

                                    if (message === 'Card with number already create') {
                                        setNotification({
                                            notification: 'bank-card-already-create',
                                        });

                                        this.setErrors({ id, errors: ['number'] });
                                    }
                                });

                                handlerErrorRequest(res);
                            }

                            this.handlerLoadingKey(null);
                        });
                });
            }
        });
    }

    handlerEditmode({ editName }) {
        return new Promise((resolve) => {
            this.editmode.handlerEdit({ editName }).then(resolve);
        });
    }

    editmode = new Editmode({
        context: this,
    });

    componentWillUnmount() {
        const { backToSave } = this.props;

        backToSave();
    }

    render() {
        const { editName } = this.state;

        return (
            <div ref={this.parent} className={`manualOrganizations _parentForEdits ${editName ? '_edit' : ''}`}>
                <div className="manualOrganizations__inner">
                    <ListAbsoluteMain
                        className="manualOrganizations__cards _col"
                        items={this.getCards()}
                        renderItem={this.renderCard}
                        classNameItem="manualOrganizations__card"
                        prop="_id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        propsForUpdate={['number', 'type', 'isNew']}
                        sort={this.sortCards}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualOrganizationsInnerBankCards);

ManualOrganizationsInnerBankCards.propTypes = {
    organization: PropTypes.object,
    organizationSave: PropTypes.object,
    isInit: PropTypes.bool,
    getParent: PropTypes.func,
    changeOrganization: PropTypes.func,
    backToSave: PropTypes.func,
    scrollToTop: PropTypes.func,
    setOrganization: PropTypes.func,
    checkRights: PropTypes.func,
};
