import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ImageLazy from '../../../ImageLazy.jsx';
import Animate from '../../../Animate.jsx';
import Icon from '../../../Icon.jsx';

import getCarInfo from '../../../../functions/getCarInfo';
import getRealParams from '../../../../functions/getRealParams.ts';
import Button from '../../../Button.jsx';

class CarsInCrewList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getParams = this.getParams.bind(this);
        this.sort = this.sort.bind(this);

        this.parent = React.createRef();
    }

    orderActions = ['add', 'delete'];

    getParams() {
        const { cars = [] } = this.props;
        const items = cars;

        return new Promise((resolve) => {
            const page = this.parent.current.querySelector('.orderDetailsCrewCarList__items');
            const resultParams = this.state.resultParams || {};

            if (page) {
                const { offsetWidth: widthPage } = page;

                const elems = items.map((item) => ({
                    className: `.orderDetailsCrewCarList__item[data-id="${item._id}"]`,
                    id: item._id,
                }));

                const params = getRealParams({
                    parent: page,
                    elems,
                    width: widthPage,
                    classNames: ['_static'],
                    isClearStyleParent: true,
                    clearStyleElems: ['.orderDetailsCrewCarList__itemsInner'],
                });

                items.forEach((item) => {
                    if (params[item._id]) {
                        const { offsetTop, width, offsetLeft } = params[item._id];

                        resultParams[item._id] = {
                            width,
                            offsetTop,
                            offsetLeft,
                        };
                    }
                });

                this.setState({ resultParams, heightItems: params.parent.height }, resolve);
            } else {
                resolve();
            }
        });
    }

    sort(a, b) {
        const { idOfCurrentCar } = this.state;
        const weightA = a._id === idOfCurrentCar ? 1 : 0;
        const weightB = b._id === idOfCurrentCar ? 1 : 0;

        return weightB - weightA;
    }

    setCurrentCar(idOfCurrentCar) {
        return new Promise((resolve) => {
            this.setState({ idOfCurrentCar }, () => {
                const scrollArea = this.parent.current.querySelector(
                    '.orderDetailsCrewCarList__items',
                );

                scrollArea.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                resolve();
            });
        });
    }

    componentDidMount() {
        const { idOfCurrentCar } = this.props;

        this.setState({ saveIdOfCar: idOfCurrentCar, idOfCurrentCar });
    }

    render() {
        const { heightItems = 0, resultParams, saveIdOfCar, idOfCurrentCar } = this.state;
        const { serverData, cars = [], handlerShowList, setCurrentCar } = this.props;
        const { carBodyworks } = serverData;
        const carsSort = JSON.parse(JSON.stringify(cars)).sort(this.sort);

        return (
            <div ref={this.parent} className="orderDetailsCrewCarList">
                <div className="orderDetailsCrewCarList__items">
                    <div
                        className="orderDetailsCrewCarList__itemsInner"
                        style={{ height: `${heightItems}px` }}
                    >
                        {cars.map((car) => {
                            const { _id, bodywork, nameMark, nameModel, number } = car;
                            const isCurrent = idOfCurrentCar === _id;
                            const resultParam = resultParams?.[_id];
                            const indexSort = carsSort.findIndex((item) => item._id === _id);

                            const icon = getCarInfo({
                                type: 'icon',
                                icons: carBodyworks,
                                icon: bodywork,
                            });

                            return (
                                <Animate
                                    className={`orderDetailsCrewCarList__item ${
                                        isCurrent ? '_current' : ''
                                    }`}
                                    key={_id}
                                    data-id={_id}
                                    isShow={true}
                                    actionInit={this.getParams}
                                    style={{
                                        transform: `translate(${resultParam?.offsetLeft}px,${resultParam?.offsetTop}px)`,
                                        order: indexSort,
                                    }}
                                >
                                    <div className="orderDetailsCrewCarList__itemInner _row">
                                        <div className="orderDetailsCrewCarList__itemPreview _col">
                                            <ImageLazy
                                                src={icon}
                                                className="orderDetailsCrewCarList__itemPreviewIcon"
                                            />
                                            <i className="orderDetailsCrewCarList__itemPreviewDone">
                                                <Icon name="accept" />
                                            </i>
                                        </div>
                                        <div className="orderDetailsCrewCarList__itemContent">
                                            <b>
                                                {nameMark} {nameModel}
                                            </b>{' '}
                                            {number}
                                        </div>
                                        <div className="orderDetailsCrewCarList__itemAction">
                                            <div
                                                className={`action _col _animate ${
                                                    !isCurrent ? '_add' : '_delete'
                                                }`}
                                                onClick={() => {
                                                    this.setCurrentCar(_id).then(this.getParams);
                                                }}
                                            >
                                                {this.orderActions.map((action) => (
                                                    <Animate
                                                        key={action}
                                                        className={`action__icon _${action}`}
                                                        isShow={
                                                            action === 'add'
                                                                ? !isCurrent
                                                                : !!isCurrent
                                                        }
                                                    >
                                                        <Icon name={action} />
                                                    </Animate>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Animate>
                            );
                        })}
                    </div>
                </div>
                <div className="orderDetailsCrewCarList__actions">
                    <div className="orderDetailsCrewCarList__actionsInner">
                        <Animate
                            className="orderDetailsCrewCarList__button _save"
                            isShow={saveIdOfCar !== idOfCurrentCar}
                        >
                            <Button
                                className="_mainNotBorder _normalSize"
                                icon={{ name: 'arrow-next-2', type: 'end' }}
                                onClick={() => setCurrentCar(idOfCurrentCar)}
                            >
                                Сохранить
                            </Button>
                        </Animate>
                        <Animate
                            className="orderDetailsCrewCarList__button _cancel"
                            isShow={saveIdOfCar === idOfCurrentCar}
                        >
                            <Button
                                className="_cancel _normalSize"
                                icon={{ name: 'arrow-prev-2', type: 'start' }}
                                onClick={() => handlerShowList(false)}
                            >
                                Отменить
                            </Button>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(CarsInCrewList);

CarsInCrewList.propTypes = {
    serverData: PropTypes.object,
    cars: PropTypes.array,
    idOfCurrentCar: PropTypes.string,
    setCurrentCar: PropTypes.func,
    handlerShowList: PropTypes.func,
};
