export default function getIcons({ type }) {
    const icons = [];
    let len = 35;

    if (type === 'company') {
        len = 19;
    }

    if (type === 'client') {
        len = 19;
    }

    for (let i = 0; i < len; i++) {
        icons.push(i);
    }

    return icons;
}
