import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerWindow from '../functions/handlerWindow';
import setNotification from '../functions/setNotification';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function setOrderResponsible({ id: idOfResponsible, idOfOrder }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/order`,
                {
                    id: idOfOrder,
                    type: 'set-responsible',
                    idOfResponsible,
                },
                {
                    headers: getHeaders(),
                },
            )
            .then((res) => {
                if (res?.data && typeof res?.data === 'object') {
                    const { success } = res.data;

                    if (success) {
                        setNotification({ notification: 'success-set-responsible' });
                        handlerWindow({
                            action: 'hide',
                            name: 'listResponsibleOrders',
                        });
                    } else {
                        const { data } = res.data;
                        const { message } = data;

                        if (message === 'User already set') {
                            setNotification({ notification: 'user-already-set-responsible' });
                        }

                        handlerErrorRequest(res);
                    }

                    resolve();
                } else {
                    reject();
                }
            }, reject);
    });
}
