import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import Main from '../../../../../components/crm/manual/tariff/Main.jsx';
import Configurations from '../../../../../components/crm/manual/tariff/Configurations.jsx';

import getVariables from '../../../../../functions/getVariables';
import scrollToPosition from '../../../../../functions/scrollToPosition';
import getHeaders from '../../../../../functions/getHeaders';
import setNotification from '../../../../../functions/setNotification';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import Edit from '../../../../../components/Edit.jsx';
import checkValueOfEmpty from '../../../../../functions/checkValueOfEmpty';

const tariffConfigFields = require('../../../../../infos/crm/tariffConfigFields.json');

class ManualTariffsInnerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.scrollToConfig = this.scrollToConfig.bind(this);
        this.addConfiguration = this.addConfiguration.bind(this);
        this.deleteConfiguration = this.deleteConfiguration.bind(this);
        this.handlerConfiguration = this.handlerConfiguration.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.handlerConfigurationItem = this.handlerConfigurationItem.bind(this);
    }

    editmode = new Editmode({
        context: this,
    });

    scrollToConfig(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualTariffsConfigurations__card[data-_id="${id}"]`,
            });
        }
    }

    checkChange() {
        const fields = {};
        const { tariff, tariffSave } = this.props;

        if (this.state.editName === 'tariff') {
            ['name', 'nds'].forEach((key) => {
                if (tariff[key] !== tariffSave[key]) {
                    fields[key] = tariff[key];
                }
            });

            return { isChange: Object.keys(fields).length > 0, fields };
        }

        let isChange = false;
        const parametersFields = [
            'pallets',
            'maxWeight',
            'long',
            'minPrice',
            'overThanMin',
            'distance1OfMkad',
            'width',
            'minTime',
            'priceOfMinute',
            'distance2OfMkad',
            'height',
            'freeWaiting',
            'passengers',
            'priceOfMkad',
            'priceTo20',
            'priceTo50',
            'priceTo100',
        ];
        const { editName: idOfConfig } = this.state;
        const config = tariff.configurations.find((configLoop) => configLoop._id === idOfConfig);
        const configSave = tariffSave.configurations.find(
            (configLoop) => configLoop._id === idOfConfig,
        );

        if (!configSave) {
            isChange = true;
        } else {
            if (configSave.name !== config.name) {
                isChange = true;
            }

            config.items.forEach((item) => {
                const itemTarget = configSave.items.find(
                    (itemLoop) => itemLoop._id.toString() === item._id,
                );

                if (!itemTarget) {
                    isChange = true;
                } else {
                    parametersFields.forEach((key) => {
                        if (item.parameters[key] !== itemTarget.parameters[key]) {
                            isChange = true;
                        }
                    });

                    item.services.forEach((service) => {
                        const serviceTarget = itemTarget.services.find(
                            (serviceLoop) => serviceLoop.key === service.key,
                        );

                        if (!serviceTarget) {
                            isChange = true;
                        } else {
                            ['key', 'value'].forEach((key) => {
                                if (service[key] !== serviceTarget[key]) {
                                    isChange = true;
                                }
                            });

                            [].forEach((key) => {
                                if (service.otherInfo[key] !== serviceTarget.otherInfo[key]) {
                                    isChange = true;
                                }
                            });
                        }
                    });

                    itemTarget.services.forEach((serviceTarget) => {
                        const service = item.services.find(
                            (serviceLoop) => serviceLoop.key === serviceTarget.key,
                        );

                        if (!service) {
                            isChange = true;
                        }
                    });
                }
            });

            configSave.items.forEach((itemTarget) => {
                const item = config.items.find(
                    (itemLoop) => itemLoop._id === itemTarget._id.toString(),
                );

                if (!item) {
                    isChange = true;
                }
            });
        }

        return { isChange };
    }

    handlerLoading(isLoadingKey) {
        return new Promise((resolve) => {
            this.setState({ isLoadingKey }, resolve);
        });
    }

    deleteConfiguration({ hide }) {
        const { editName: idOfConfig } = this.state;
        const { tariff } = this.props;

        return new Promise((resolve) => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/tariff`,
                    {
                        id: tariff._id,
                        idOfConfig,
                        action: 'change-configuration',
                        config: {
                            isDelete: true,
                        },
                    },
                    {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (success) {
                            hide();

                            this.handlerEditmode({ editName: null, isNotScroll: true });

                            setNotification({
                                notification: 'success-update-tariff',
                            });

                            resolve();
                        } else {
                            handlerErrorRequest(res);
                        }
                    },
                    () => null,
                );
        });
    }

    handlerErrors({ action, errors, id }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                let resultErrors = JSON.parse(JSON.stringify(newState.errors || {}));

                if (action === 'set') {
                    resultErrors = errors;

                    if (Object.keys(errors).length > 0) {
                        const itemsError = Object.keys(errors).find((key) =>
                            Object.keys(errors[key]).find((innerKey) => innerKey === 'items'),
                        );

                        if (itemsError) {
                            setNotification({ notification: 'tariff-config-items-empty' });
                        } else {
                            setNotification({ notification: 'required-fields-not-complete' });
                        }
                    }
                }

                if (id) {
                    if (action === 'delete' && resultErrors[id]) {
                        Object.keys(errors).forEach((key) => {
                            resultErrors[id][key] = undefined;
                            delete resultErrors[id][key];
                        });
                    } else if (action === 'add') {
                        resultErrors[id] = {
                            ...resultErrors[id],
                            ...errors,
                        };
                    }
                }

                newState.errors = resultErrors;

                return newState;
            }, resolve);
        });
    }

    filterConfig({ config }) {
        const { serverData } = this.props;
        const { tariffs } = serverData;

        if (tariffs) {
            const { services } = tariffs;

            // console.log(tariffs, config.items);

            const updateConfig = {
                ...config,
                items: config.items.map((item) => ({
                    ...item,
                    services: item.services.filter(
                        ({ key, value }) =>
                            services[item.typeConfig][key] &&
                            (services[item.typeConfig][key].type !== 'text' || value > 0),
                    ),
                })),
            };

            return updateConfig;
        }

        return config;
    }

    validateConfig({ config }) {
        const { _id } = config;
        const errors = {};

        if (!config.category || !config.name || !config.type || config.items.length === 0) {
            if (!errors[_id]) {
                errors[_id] = {};
            }

            ['category', 'name', 'type'].forEach((key) => {
                if (!config[key]) {
                    errors[_id][key] = true;
                }
            });

            if (config.type === 'car' && +config.name === 0) {
                errors[_id].name = true;
            }

            if (Object.keys(errors[_id]).length === 0 && config.items.length === 0) {
                errors[_id].items = true;
            }
        } else {
            config.items.forEach((item) => {
                const { _id: itemId, parameters, typeConfig } = item;

                []
                    .concat(
                        ...tariffConfigFields[typeConfig].main.filter(
                            (innerItem) => innerItem.name !== 'volume',
                        ),
                    )
                    .concat(...tariffConfigFields[typeConfig].items)
                    .forEach((field) => {
                        if (!checkValueOfEmpty(parameters[field.name])) {
                            if (!errors[itemId]) {
                                errors[itemId] = {};
                            }

                            errors[itemId][field.name] = true;
                        }
                    });
            });
        }

        return { isSuccess: Object.keys(errors).length === 0, errors };
    }

    changeTariff() {
        const { tariff, setTariff } = this.props;

        return new Promise((resolve) => {
            const { editName: idOfConfig } = this.state;
            const config = tariff.configurations.find(
                (configLoop) => configLoop._id === idOfConfig,
            );
            const { isChange, fields } = this.checkChange();
            const body = {};
            let isSuccess = true;

            if (this.state.editName === 'tariff') {
                body.fields = fields;
            } else {
                body.idOfConfig = idOfConfig;
                body.action = 'change-configuration';
                body.config = this.filterConfig({ config });

                const validate = this.validateConfig({ config });
                const { errors } = validate;

                isSuccess = validate.isSuccess;

                this.handlerErrors({ action: 'set', errors });
            }

            if (!isChange) {
                resolve();
            } else if (!isSuccess) {
                console.log('er');
            } else {
                this.handlerLoading(idOfConfig).then(() => {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/tariff`,
                            {
                                id: tariff._id,
                                ...body,
                            },
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then((res) => {
                            this.handlerLoading(null);
                            
                            const { success } = res.data;

                            if (success) {
                                setNotification({
                                    notification: 'success-update-tariff',
                                });

                                setTariff({ tariff });

                           

                                resolve();
                            } else {
                                handlerErrorRequest(res);
                            }
                        });
                });
            }
        });
    }

    handlerEditmode({ editName, isNotScroll = false }) {
        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    if (!isNotScroll) {
                        this.scrollToConfig(editName);
                    }

                    resolve();
                } else {
                    this.changeTariff().then(resolve);
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    addConfiguration() {
        const { editName } = this.state;

        return new Promise((resolve) => {
            const { addConfiguration } = this.props;

            if (!editName) {
                addConfiguration().then((id) => {
                    resolve();

                    this.handlerEditmode({ editName: id, isNotScroll: true });
                });
            }
        });
    }

    handlerConfiguration({ ...props }) {
        return new Promise((resolve) => {
            const { action } = props;
            const { handlerConfiguration } = this.props;

            if (props.action === 'change') {
                this.handlerErrors({
                    action: 'delete',
                    errors: { [props.name]: true },
                    id: props.idOfConfig,
                });
            }

            handlerConfiguration({ ...props }).then(() => {
                if (action === 'delete') {
                    this.editmode.handlerEdit({ editName: null });
                }

                resolve();
            });
        });
    }

    initScroll() {
        const { parentScroll } = this.props;

        if (parentScroll && !this.isInitScroll) {
            this.isInitScroll = true;

            parentScroll.addEventListener('scroll', () => {
                const { isFixHead } = this.state;
                const head = parentScroll.querySelector('.manualTariffs__head');
                const { offsetHeight } = head;
                const top = offsetHeight + getVariables().paddingWidget.value;

                if (!isFixHead && parentScroll.scrollTop >= top) {
                    this.setState({ isFixHead: true });
                }
                if (isFixHead && parentScroll.scrollTop < top) {
                    this.setState({ isFixHead: false });
                }
            });
        }
    }

    handlerConfigurationItem({ idOfItem, name, ...props }) {
        const { handlerConfigurationItem } = this.props;

        return new Promise((resolve) => {
            handlerConfigurationItem({ idOfItem, name, ...props }).then(resolve);

            if (props.action === 'change') {
                this.handlerErrors({
                    action: 'delete',
                    errors: { [name]: true },
                    id: idOfItem,
                });
            }
        });
    }

    checkTariff() {
        const { tariff, isInit, serverData } = this.props;

        if (tariff && isInit && serverData.tariffs && !this.isInit) {
            this.isInit = true;

            setTimeout(() => {
                this.setState({ isShowFull: true });
            }, 1000);
        }
    }

    componentDidMount() {
        this.initScroll();
        this.checkTariff();
    }

    componentDidUpdate() {
        this.initScroll();
        this.checkTariff();
    }

    render() {
        const { editName, isFixHead, isLoadingKey, isShowFull, errors } = this.state;
        const {
            tariff,
            setHeightPage,
            parent,
            parentScroll,
            initTariff,
            isInit,
            handlerConfigurationItems,
            setCurrentItem,
            infoConfigs,
            handlerLoaderList,
            changeTariff,
            checkRights,
        } = this.props;

        return (
            <div className={`manualTariffs _parentForEdits ${editName ? '_edit' : ''}`}>
                <div className="manualTariffs__inner">
                    <div
                        className={`manualTariffs__head _parentForEdit _editBack ${editName === 'tariff' ? '_current' : ''}`}
                    >
                        {checkRights() && (
                            <Edit
                                name="tariff"
                                className="manualTariffs__headEdit"
                                editName={editName}
                                handlerEditmode={this.handlerEditmode}
                                isLoader={isLoadingKey === 'tariff'}
                            />
                        )}

                        <Main
                            tariff={tariff}
                            changeTariff={changeTariff}
                            checkEditmode={() => editName === 'tariff'}
                        />
                    </div>
                    <div className="manualTariffs__content">
                        <Configurations
                            editName={editName}
                            handlerEditmode={this.handlerEditmode}
                            tariff={tariff}
                            setHeightPage={setHeightPage}
                            parent={parent}
                            parentScroll={parentScroll}
                            initTariff={initTariff}
                            isInit={isInit}
                            scrollToConfig={this.scrollToConfig}
                            isFixHead={isFixHead}
                            handlerConfigurationItem={this.handlerConfigurationItem}
                            handlerConfigurationItems={handlerConfigurationItems}
                            addConfiguration={this.addConfiguration}
                            handlerConfiguration={this.handlerConfiguration}
                            setCurrentItem={setCurrentItem}
                            infoConfigs={infoConfigs}
                            isLoadingKey={isLoadingKey}
                            deleteConfiguration={this.deleteConfiguration}
                            isShowFull={isShowFull}
                            handlerLoaderList={handlerLoaderList}
                            checkRights={checkRights}
                            errors={errors}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualTariffsInnerMain);

ManualTariffsInnerMain.propTypes = {
    serverData: PropTypes.object,
    tariff: PropTypes.object,
    tariffSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    parent: PropTypes.object,
    parentScroll: PropTypes.object,
    initTariff: PropTypes.func,
    isInit: PropTypes.bool,
    handlerConfigurationItem: PropTypes.func,
    handlerConfigurationItems: PropTypes.func,
    addConfiguration: PropTypes.func,
    handlerConfiguration: PropTypes.func,
    setCurrentItem: PropTypes.func,
    infoConfigs: PropTypes.object,
    setTariff: PropTypes.func,
    isShowFull: PropTypes.bool,
    handlerLoaderList: PropTypes.func,
    changeTariff: PropTypes.func,
    checkRights: PropTypes.func,
};
