import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CorporationTariffPopup from './CorporationTariffPopup.jsx';
import Animate from '../../Animate.jsx';

class CorporationTariffPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { corporationTariffPopup } = this.props;

        return (
            <Animate className="body__corporationTariffPopup" isShow={corporationTariffPopup?.isShow}>
                <CorporationTariffPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        corporationTariffPopup: state.corporationTariffPopup,
    };
}

export default connect(mapStateToProps)(CorporationTariffPopupWrapper);

CorporationTariffPopupWrapper.propTypes = {
    corporationTariffPopup: PropTypes.object,
};
