import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import createId from '../../../../requests/createId';
import getRoles from '../../../../requests/getRoles';

import Editmode from '../../../../classes/Editmode';

import Table from '../../../../components/crm/manual/Table.jsx';
import SettingsRole from '../../../../components/crm/settings/Role.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import AnimateChange from '../../../../components/AnimateChange.jsx';
import Button from '../../../../components/Button.jsx';
import Edit from '../../../../components/Edit.jsx';
import getHeaders from '../../../../functions/getHeaders';
import setNotification from '../../../../functions/setNotification';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';

class SettingsRolesMain extends Table {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.handlerRole = this.handlerRole.bind(this);
        this.renderRole = this.renderRole.bind(this);
        this.deleteRoleRequest = this.deleteRoleRequest.bind(this);
        this.hideDeleteRole = this.hideDeleteRole.bind(this);
        this.addRole = this.addRole.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 12;

    getEmptyInfo() {
        const { items = [] } = this.state;

        if (items.length === 0) {
            return {
                title: 'В компании пока нет ролей',
                description: 'Вы можете добавить новые роли:',
            };
        }

        return {
            title: 'Пока это все роли',
            description: 'Вы можете добавить больше ролей:',
        };
    }

    handlerErrors({ action, errors, error, block }) {
        this.setState((state) => {
            const newState = { ...state };
            const errorsState = { ...newState.errors };

            if (action === 'set') {
                newState.errors = errors;
            } else if (action === 'add') {
                if (!errorsState[block]) {
                    errorsState[block] = [];
                }

                const indexError = errorsState[block].indexOf(error);

                if (indexError === -1) {
                    if (error) {
                        errorsState[block].push(error);
                    } else {
                        errorsState[block].push(...errors);
                    }
                }

                newState.errors = errorsState;
            } else if (action === 'delete' && errorsState[block]) {
                const indexError = errorsState[block].indexOf(error);

                if (indexError !== -1) {
                    errorsState[block].splice(indexError, 1);
                }

                newState.errors = errorsState;
            }

            return newState;
        });
    }

    handlerRole({ action, name, value, id }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const items = JSON.parse(JSON.stringify(newState.items));
                        const index = items.findIndex((role) => role._id === id);

                        if (index !== -1) {
                            items[index][name] = value;
                        }

                        newState.items = items;

                        return newState;
                    },
                    () => {
                        if (action === 'change') {
                            this.handlerErrors({
                                action: 'delete',
                                error: name,
                                block: id,
                            });
                        }

                        resolve();
                    },
                );
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    addRole() {
        return new Promise((resolve) => {
            this.handlerLoading('new').then(() => {
                createId().then(({ id }) => {
                    this.setState(
                        (state) => {
                            const newState = { ...state };
                            const items = JSON.parse(JSON.stringify(newState.items));

                            items.push({
                                _id: id,
                                name: '',
                                usersCounter: 0,
                                isNew: true,
                            });

                            newState.items = items;
                            newState.editName = id;

                            return newState;
                        },
                        () => {
                            this.handlerLoading(null);

                            resolve();
                        },
                    );
                });
            });
        });
    }

    renderRole({ item: role, prop }) {
        const { editName, loadingKey, errors, items = [] } = this.state;
        const { getParent } = this.props;

        if (prop === 'info') {
            const info = this.getEmptyInfo();

            return (
                <div className="settingsRoles__card _info">
                    <div className="settingsRoles__cardInner _parentForEdit _editBack">
                        <div className="empty _col _block _withChange">
                            <AnimateChange
                                className="empty__inner"
                                type="_translate _transFast"
                                prop={items.length === 0 ? 0 : 1}
                                isNotParams={true}
                            >
                                <div className="empty__innerItem">
                                    <div className="empty__title">{info.title}</div>
                                    <p
                                        className="empty__content"
                                        dangerouslySetInnerHTML={{
                                            __html: info.description,
                                        }}
                                    ></p>
                                    <div className="empty__button _show">
                                        <Button
                                            className="_mediumSize _bigPaddings _mainNotBorder"
                                            onClick={this.addRole}
                                            showLoader={loadingKey === 'new'}
                                        >
                                            + Добавить роль
                                        </Button>
                                    </div>
                                </div>
                            </AnimateChange>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="settingsRoles__card">
                <div className={`settingsRoles__cardInner _parentForEdit _editBack ${editName === prop ? '_current' : ''}`}>
                    {!role.isAdmin && (
                        <Edit
                            name={prop}
                            className="settingsRoles__cardEdit"
                            editName={editName}
                            handlerEditmode={this.handlerEditmode}
                            isLoader={loadingKey === prop}
                        />
                    )}
                    <SettingsRole
                        {...role}
                        isEdit={editName === prop}
                        handlerRole={({ ...props }) =>
                            new Promise((resolve) => {
                                this.handlerRole({ ...props, id: prop }).then(resolve);
                            })
                        }
                        errors={errors?.[prop]}
                        hideDeleteRole={this.hideDeleteRole}
                        getParent={getParent}
                        getParentForScroll={() =>
                            this.parent.current?.querySelector('.settingsRoles__inner')
                        }
                    />
                </div>
            </div>
        );
    }

    clearNewRole({ id }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items));
                const index = items.findIndex((role) => role._id === id);

                if (index !== -1) {
                    items[index].isNew = false;
                }

                newState.items = items;

                return newState;
            }, resolve);
        });
    }

    getChangedFields() {
        const id = this.state.editName;
        const { items = [], saveItems = [] } = this.state;
        const saveRole = saveItems.find((role) => role._id === id);
        const role = items.find((innerRole) => innerRole._id === id);
        const fields = {};

        ['name'].forEach((key) => {
            if (!saveRole || role[key] !== saveRole[key]) {
                fields[key] = role[key];
            }
        });

        return { isSuccess: Object.keys(fields).length > 0, fields };
    }

    handlerEditmode({ editName }) {
        const { items } = this.state;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else {
                    const id = this.state.editName;
                    const role = items.find((innerRole) => innerRole._id === id);
                    const { isSuccess, fields } = this.getChangedFields();
                    const body = role.isNew ? { _id: id, name: fields.name } : { id, fields };

                    if (!isSuccess) {
                        resolve();
                    } else if (!fields.name) {
                        this.handlerErrors({
                            action: 'add',
                            error: 'name',
                            block: id,
                        });
                    } else {
                        this.handlerLoading(this.state.editName).then(() => {
                            axios[role.isNew ? 'post' : 'patch'](
                                `${process.env.REACT_APP_API}/role`,
                                body,
                                { headers: getHeaders() },
                            ).then((res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    if (role.isNew) {
                                        setNotification({
                                            notification: 'create-new-role',
                                            title: `Роль «${role.name}» успешно <b>добавлена</b>`,
                                        });

                                        this.clearNewRole({ id });
                                    } else {
                                        setNotification({
                                            notification: 'update-role',
                                            title: `Роль «${role.name}» успешно <b>обновлена</b>`,
                                        });
                                    }

                                    this.setState({
                                        saveItems: JSON.parse(JSON.stringify(this.state.items)),
                                    });

                                    resolve();
                                } else {
                                    const { message } = data;

                                    if (message === 'Role with this name already create') {
                                        this.handlerErrors({
                                            action: 'add',
                                            error: 'name',
                                            block: id,
                                        });

                                        setNotification({
                                            notification: 'role-already-create',
                                        });
                                    }

                                    handlerErrorRequest(res);
                                }

                                this.handlerLoading(null);
                            });
                        });
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    hideDeleteRole({ id }) {
        this.setState((state) => {
            const newState = { ...state };
            const items = JSON.parse(JSON.stringify(newState.items));
            const index = items.findIndex((role) => role._id === id);

            if (index !== -1) {
                items.splice(index, 1);
            }

            newState.items = items;
            newState.editName = null;

            return newState;
        });
    }

    deleteRoleRequest({ id, hide }) {
        const { items = [] } = this.state;

        const role = items.find((innerRole) => innerRole._id === id);

        axios
            .delete(`${process.env.REACT_APP_API}/role?id=${id}`, { headers: getHeaders() })
            .then((res) => {
                const { success } = res.data;

                if (success) {
                    setNotification({
                        notification: 'delete-role',
                        title: `Роль «${role.name}» успешно <b>удалена</b>`,
                    });

                    this.hideDeleteRole({ id });

                    if (hide && typeof hide === 'function') {
                        hide();
                    }
                } else {
                    handlerErrorRequest(res);
                }
            });
    }

    getRoles() {
        const { mainRoles = [], items = [] } = this.state;

        return [...mainRoles, ...JSON.parse(JSON.stringify(items)), { _id: 'info' }];
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getRoles(query).then(
                ({ mainRoles, roles, isLimit, counter }) => {
                    this.setItems(roles, false, isLimit, counter).then(() => {
                        this.setState({ mainRoles });

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    componentDidMount() {
        super.componentDidMount();

        const { setDeleteRoleRequest } = this.props;

        setDeleteRoleRequest(this.deleteRoleRequest);

        this.editmode = new Editmode({
            context: this,
        });
    }

    render() {
        const { editName, isReady, isDisabledScroll, isLimit } = this.state;
        const { setHeightPage } = this.props;
        const roles = this.getRoles();

        return (
            <div
                ref={this.parent}
                className={`settingsRoles _parentForEdits ${editName ? '_edit' : ''} ${isReady ? '_ready' : ''}`}
            >
                <Animate className="settingsRoles__loader _loader" isShow={!isReady}>
                    <div className="settingsRoles__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="settingsRoles__inner">
                    <div className="settingsRoles__content _col">
                        {isReady && (
                            <ListScroll
                                getParent={() =>
                                    this.parent.current?.querySelector('.settingsRoles__inner')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={roles.length}
                                keyUpdate={roles.length}
                                isDisabledScroll={isDisabledScroll || !isReady}
                                isLimit={isLimit}
                                handlerLoaderList={this.handlerLoaderList}
                                otherItemsCounter={
                                    roles.filter((item) => item._id === 'info' || item.isAdmin)
                                        .length
                                }
                            >
                                <ListAbsoluteMain
                                    className="settingsRoles__cards"
                                    items={roles}
                                    renderItem={this.renderRole}
                                    classNameItem="settingsRoles__card"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    itemParams={['offsetRight', 'offsetTop', 'width', 'height']}
                                    propsForUpdate={['name', 'usersCounter', 'isNew']}
                                    callback={setHeightPage}
                                    resizeParent={this.parent.current}
                                />
                            </ListScroll>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsRolesMain);

SettingsRolesMain.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    setDeleteRoleRequest: PropTypes.func,
    updateCorporation: PropTypes.func,
    setHeightPage: PropTypes.func,
};
