import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getCars({ id, params = [] }) {
    let query = '';

    if (id) {
        query += `id=${id}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve) => {
        axios.get(`${process.env.REACT_APP_API}/car?${query}`, { headers: getHeaders() }).then(
            (res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success) {
                        const { cars = [], car, isLimit, counter } = data;

                        resolve({ cars, car, isLimit, counter });
                    } else {
                        handlerErrorRequest(res);
                    }
                }
            },
            () => null,
        );
    });
}