import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import ChatTemplatesPopup from './ChatTemplatesPopup.jsx';

class ChatTemplatesPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { chatTemplatesPopup } = this.props;

        return (
            <Animate className="body__chatTemplatesPopup" isShow={chatTemplatesPopup.isShow}>
                <ChatTemplatesPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        chatTemplatesPopup: state.chatTemplatesPopup,
    };
}

export default connect(mapStateToProps)(ChatTemplatesPopupWrapper);

ChatTemplatesPopupWrapper.propTypes = {
    chatTemplatesPopup: PropTypes.object,
};
