import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { dispatcher } from '../redux/redux';

import createChat from '../requests/createChat';
import setChatResponsible from '../requests/setChatResponsible';

import Window from './chat/Window.jsx';
import List from './chat/List.jsx';
import CounterNotRead from './chat/CounterNotRead.jsx';

import Icon from './Icon.jsx';
import Animate from './Animate.jsx';
import Loader from './Loader.jsx';
import WindowList from './WindowList.jsx';
import Windows from './Windows.jsx';
import WindowPrompt from './WindowPrompt.jsx';
import closeChat from '../requests/closeChat';
import checkRights from '../functions/crm/checkRights';

class ChatFix extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentChat: null,
            isLoader: false,
        };

        this.setChat = this.setChat.bind(this);
        this.handlerLoader = this.handlerLoader.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.renderWindowsList = this.renderWindowsList.bind(this);
        this.renderWindowsPromptDone = this.renderWindowsPromptDone.bind(this);
        this.checkRights = this.checkRights.bind(this);
        this.close = this.close.bind(this);

        this.parent = React.createRef();
    }

    handlerWindow(isShowWindow = !this.props.isShowChatWindow) {
        return new Promise((resolve) => {
            dispatcher({ type: 'isShowChatWindow', data: isShowWindow });

            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.isShowWindow = isShowWindow;

                    return newState;
                },
                () => {
                    if (isShowWindow === false) {
                        setTimeout(() => {
                            this.setChat(null).then(resolve, () => null);
                        }, 300);
                    } else {
                        resolve();
                    }
                },
            );
        });
    }

    handlerLoader(isLoader = !this.state.isLoader) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.isLoader = isLoader;

                return newState;
            }, resolve);
        });
    }

    setChat(currentChat) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.currentChat = currentChat;

                return newState;
            }, resolve);
        });
    }

    getChat() {
        const { idOfOrder, idOfChat, name, setChatId } = this.props;

        this.handlerLoader(true).then(() => {
            setTimeout(() => {
                if (idOfChat) {
                    this.setChat(idOfChat);
                } else {
                    createChat({
                        idOfOrder,
                        changeToChatPage: false,
                        isNotNotificate: name === 'orderInfo',
                    }).then(
                        ({ id }) => {
                            if (setChatId) {
                                setChatId(id);
                            }

                            this.setChat(id);
                        },
                        () => null,
                    );
                }
            }, 500);
        });
    }

    close({ hide, handlerLoader }) {
        const { currentChat } = this.state;

        return new Promise((resolve) => {
            closeChat({ id: currentChat }).then(
                () => {
                    handlerLoader(false);
                    hide();

                    resolve();
                },
                () => null,
            );
        });
    }

    renderWindowsList({ idOfResponisble }) {
        const { currentChat } = this.state;

        return (
            <WindowList
                name="responsible"
                className="_topCenter"
                keyParent="chat"
                keySection="important"
                callback={({ id }) => setChatResponsible({ idOfResponsible: id, id: currentChat })}
                idOfCurrent={idOfResponisble}
            />
        );
    }

    renderWindowsPromptDone() {
        return <WindowPrompt className="_topRight" name="closeChat" callback={this.close} />;
    }

    handlerMissClick({ target }) {
        const { isShowChatWindow } = this.props;
        const window = this.parent.current.querySelector('.chatFix__box');

        if (isShowChatWindow && target !== window && !window.contains(target)) {
            this.handlerWindow(false);
        }
    }

    checkRights() {
        const { user } = this.props;

        if (process.env.REACT_APP_SYSTEM !== 'crm') {
            return true;
        }

        return checkRights({ user, key: 'chat', isEdit: true });
    }

    componentDidMount() {
        const { type } = this.props;

        if (type === 'separate') {
            this.getChat();
        }

        document.addEventListener('mouseup', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handlerMissClick);
    }

    render() {
        const { currentChat, isLoader } = this.state;
        const { type, isShowChatWindow, callbackClose, getParent, className = '' } = this.props;

        return (
            <div
                ref={this.parent}
                className={`body__chatFix ${isShowChatWindow ? '_isShow' : ''} ${
                    type ? `_${type}` : ''
                }`}
            >
                <Windows name="listResponsibleChat" renderContent={this.renderWindowsList} />
                <Windows name="promptCloseChat" renderContent={this.renderWindowsPromptDone} />
                <div className={`chatFix _col ${type ? `_${type}` : ''} ${className}`}>
                    {type === 'separate' && (
                        <Animate className="chatFix__loader _loader" isShow={isLoader}>
                            <div className="chatFix__loaderIcon _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    )}
                    <Animate
                        className="chatFix__box"
                        isShow={isShowChatWindow || type === 'separate'}
                    >
                        <div className="chatFix__head _row">
                            <div className="chatFix__avatar _col">
                                <img
                                    src={require('../img/logo-short.svg').default}
                                    alt=""
                                    className="chatFix__avatarLogo"
                                />
                            </div>
                            <div className="chatFix__info">
                                <h3 className="chatFix__name">Служба поддержки LIVECARGO</h3>
                                <p className="chatFix__status">Круглосуточно</p>
                            </div>

                            {callbackClose && typeof callbackClose === 'function' && (
                                <div className="chatFix__close" onClick={callbackClose}>
                                    <Icon name="delete-circle" />
                                </div>
                            )}
                        </div>
                        <Animate className="chatFix__window" isShow={!!currentChat}>
                            <Window
                                type="fix"
                                currentChat={currentChat}
                                setChat={this.setChat}
                                isLoader={isLoader}
                                typeParent={type}
                                handlerLoader={this.handlerLoader}
                                getParent={getParent}
                                checkRights={this.checkRights}
                            />
                        </Animate>
                        {type !== 'separate' && (
                            <Animate className="chatFix__list" isShow={!currentChat}>
                                <List type="fix" setChat={this.setChat} />
                            </Animate>
                        )}
                    </Animate>
                    {type !== 'separate' && (
                        <div
                            className="chatFix__btn _col _click"
                            onMouseUp={(e) => e.stopPropagation()}
                            onClick={() => this.handlerWindow()}
                        >
                            <div className="chatFix__btnCounter">
                                <CounterNotRead type="all" />
                            </div>
                            <i className="chatFix__btnIcon">
                                <Icon name="chat" />
                            </i>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isShowChatWindow: state.isShowChatWindow,
    };
}

export default connect(mapStateToProps)(ChatFix);

ChatFix.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    idOfOrder: PropTypes.string,
    idOfChat: PropTypes.string,
    user: PropTypes.object,
    isShowChatWindow: PropTypes.bool,
    callbackClose: PropTypes.func,
    getParent: PropTypes.func,
    className: PropTypes.string,
    setChatId: PropTypes.func,
};
