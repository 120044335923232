import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getRoles({ id, withoutAdmin = false, isAvailable = false, params = [] }) {
    let query = ``;

    if (id) {
        query = `id=${id}&`;
    }

    if (withoutAdmin) {
        query += 'withoutAdmin=true&';
    }

    if (isAvailable) {
        query += 'isAvailable=true&';
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve) => {
        axios.get(`${process.env.REACT_APP_API}/role?${query}`, { headers: getHeaders() }).then(
            (res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success === true) {
                        const { mainRoles, roles = [], role, isLimit, counter } = data;

                        resolve({ mainRoles, roles, role, isLimit, counter });
                    } else {
                        handlerErrorRequest(res);
                    }
                }
            },
            () => null,
        );
    });
}
