import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Select from '../Select.jsx';
import Field from '../Field.jsx';
import Switch from '../Switch.jsx';
import Animate from '../Animate.jsx';

class PointContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    order = ['name', 'phone', 'time'];

    fields = {
        name: {
            className: '_start',
            type: 'input',
        },
        phone: {
            type: 'input',
        },
        time: {
            type: 'select',
            className: '_end',
        },
    };

    renderField(name) {
        const { point, numberOfPoint, handler, isDisabled, isEditmode, errors = [] } = this.props;
        const { className, type } = this.fields[name];
        const field = point?.addressatInfo?.[name];
        let result;

        switch (type) {
            case 'select':
                {
                    const timeStart = point?.addressatInfo?.timeStart;
                    const timeEnd = point?.addressatInfo?.timeEnd;

                    result = (
                        <Select
                            type="times"
                            name={name}
                            value={field?.value}
                            className={className}
                            callback={handler}
                            otherField={{
                                timeStart: timeStart?.value,
                                timeEnd: timeEnd?.value,
                            }}
                            isDisabled={isDisabled}
                            setStateComplete={true}
                            isEditmode={isEditmode}
                            id={`point-contacts-${numberOfPoint}`}
                        />
                    );
                }
                break;

            default:
                {
                    let typeField = 'point';

                    if (name === 'name') {
                        typeField = numberOfPoint === 0 ? 'pointFirst' : 'point';
                    }

                    result = (
                        <Field
                            type={typeField}
                            keyName={name}
                            name={name}
                            value={field?.value}
                            callback={handler}
                            group={`point-contacts-${numberOfPoint}`}
                            className={`_orderPoint ${className || ``}`}
                            isDisabled={isDisabled}
                            setStateComplete={true}
                            isEditmode={isEditmode}
                            error={errors.includes(`route-${point._id}-addressatInfo-${name}`)}
                        />
                    );
                }
                break;
        }

        return (
            <div className={`orderPointContacts__field _${name}`} key={name}>
                {result}
            </div>
        );
    }

    render() {
        const { order, point, handler, isDisabled, isOtherDisabled } = this.props;

        return (
            <div className="orderPointContacts _row">
                <div className="orderPointContacts__fields _row">
                    {this.order.map((name) => this.renderField(name))}
                </div>

                <Animate
                    className="orderPointContacts__sms _row"
                    isShow={
                        process.env.REACT_APP_SYSTEM === 'crm' &&
                        order?.systemType === 'crm' &&
                        order?.deliveryType === 'delivery'
                    }
                >
                    <p className="orderPointContacts__smsSupport">
                        Не отправлять ссылку на отслеживание
                    </p>
                    <div className="orderPointContacts__smsSwitch">
                        <Switch
                            value={!point?.withSms}
                            name="withSms"
                            className="_size3"
                            handler={handler}
                            isDisabled={isDisabled || isOtherDisabled}
                        />
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(PointContacts);

PointContacts.propTypes = {
    point: PropTypes.object,
    handler: PropTypes.func,
    numberOfPoint: PropTypes.number,
    isDisabled: PropTypes.bool,
    isOtherDisabled: PropTypes.bool,
    isEditmode: PropTypes.bool,
    errors: PropTypes.array,
    order: PropTypes.object,
};
