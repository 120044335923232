import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './inner/Main.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import getModel from '../../../../requests/getModel';
import getJoinDocs from '../../../../requests/getJoinDocs';
import setPermissions from '../../../../functions/crm/setPermissions';

class JoinsDocsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getJoinDoc = this.getJoinDoc.bind(this);
        this.setJoinDoc = this.setJoinDoc.bind(this);
        this.changeJoinDoc = this.changeJoinDoc.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'joinDoc';

    targetNameSave = 'joinDocSave';

    pages = {
        'joins-docs-inner-main': {
            render() {
                const { joinDoc, joinDocSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector('.widget__page._joins-docs-inner-main')
                                            ?.querySelector('.widget__pageBox'))()}
                                    joinDoc={joinDoc}
                                    joinDocSave={joinDocSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeJoinDoc={this.changeJoinDoc}
                                    isNew={checkNew()}
                                    getJoinDoc={this.getJoinDoc}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                    setJoinDoc={this.setJoinDoc}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeJoinDoc({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const joinDoc = JSON.parse(JSON.stringify(newState.joinDoc));
                    const target = joinDoc;

                    target[name] = value;

                    newState.joinDoc = joinDoc;

                    return newState;
                }, resolve);
            }
        });
    }

    setJoinDoc({ joinDoc }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.joinDoc = joinDoc;
                    newState.joinDocSave = JSON.parse(JSON.stringify(joinDoc));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'joinDoc', value: joinDoc });

                    resolve();
                },
            );
        });
    }

    updateJoinDoc({ fields }) {
        return new Promise((resolve) => {
            this.setJoinDoc(
                {
                    joinDoc: {
                        ...this.state.joinDoc,
                        ...fields,
                    },
                },
                resolve,
            );
        });
    }

    getJoinDoc() {
        const { levels, checkNew } = this.props;
        const id = levels[3];

        return new Promise((resolve) => {
            if (checkNew()) {
                getModel({ name: 'joinDoc' }).then(
                    ({ model }) => {
                        this.setJoinDoc({
                            joinDoc: {
                                ...model,
                                isNew: true,
                            },
                        }).then(() => {
                            resolve();
                        });
                    },
                    () => null,
                );
            } else {
                getJoinDocs({ id }).then(
                    ({ joinDoc }) => {
                        this.setJoinDoc({ joinDoc }).then(() => {
                            resolve();
                        });
                    },
                    () => null,
                );
            }
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'joins',
            items: [
                {
                    key: 'docs',
                    rules: ['update'],
                },
            ],
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getJoinDoc();

        // document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        // document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'joinDoc', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget  ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                filter={(page) => page.parentName === 'joins-docs-inner'}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinsDocsInner);

JoinsDocsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
};
