import setServerData from '../../functions/setServerData';
import { store } from '../../redux/redux';

export default class Services {
    constructor({ context }) {
        this.context = context;

        this.setServices = this.setServices.bind(this);
        this.getServices = this.getServices.bind(this);
        this.filterService = this.filterService.bind(this);
        this.handlerService = this.handlerService.bind(this);
        this.updateServices = this.updateServices.bind(this);
    }

    setServices() {
        return new Promise((resolve) => {
            setServerData(
                this.context.props.order?.systemType === 'service' ?
                'orderServiceTariffs' :
                'orderServices',
            ).then(
                (res) => {
                    resolve(
                        this.context.props.order?.systemType === 'service' ? res?.services : res,
                    );
                },
                () => null,
            );
        });
    }

    getServices(data) {
        let services =
            (store.getState().serverData.orderServices && [
                ...store.getState().serverData.orderServices,
            ]) || [];

        if (data.systemType === 'service') {
            services =
                (store.getState().serverData.orderServiceTariffs?.services && [
                    ...store.getState().serverData.orderServiceTariffs?.services,
                ]) || [];
        }

        services = services.filter((service) => this.filterService({ service, ...data }));

        return services;
    }

    filterService(data) {
        const {
            systemType,
            service,
            currentConfig,
            typeOfTariff,
            type,
            amountInItems,
            points,
            services,
            onlyIsActive,
            isAll = false,
        } = data;

        let cond = true;

        const currentItem =
            data.currentItem || this.context.handlerTariffs.getCurrentItem(currentConfig).item;

        if (systemType !== 'service' && currentItem?._id !== 'custom') {
            cond = cond && service.forTypes.indexOf(type) !== -1;
            cond = cond && service.forTariffs.indexOf(typeOfTariff) !== -1;
        }

        switch (service.key) {
            case 'save':
                cond = cond && amountInItems && +amountInItems >= 1000;
                break;
            case 'pointToPoint':
                cond = cond && points && points.length > 2;
                break;
            case 'temperature':
                cond = cond && currentItem?.key === 'refrigerator';
                break;
            default:
                break;
        }

        if (onlyIsActive === true && services) {
            const currentService = services.find((item) => item.key === service.key);

            if (services?.find((serviceItem) => serviceItem.key === service.key)) {
                cond = cond && this.getCondForActive({...currentService, ...service });
            } else {
                cond = false;
            }
        }

        if (service.isAlways !== true) {
            if (currentItem) {
                cond =
                    cond &&
                    (!!currentItem.services.find((item) => item.key === service.key) ||
                        currentItem._id === 'custom');
            }
        }

        if (isAll) {
            cond = true;
        }

        return cond;
    }

    getCondForActive(info) {
        switch (info.type) {
            case 'counter':
                return info.value > 0;
            case 'checkbox':
                return info.value || info.value === undefined;
            case 'temperature':
                return info.start !== '' || info.end !== '';
            case 'number':
                return info.value > 0;
            case 'switch':
                return info.value || info.value === undefined;
            default:
                return false;
        }
    }

    handlerService({ key, name, value }) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = {...state };
                const services = [...newState.services];
                const currentService = services.find((service) => service.key === key);

                currentService[name] = value === null ? this.getDefaultVal(currentService) : value;
                if (value === null && ['descent', 'workers'].indexOf(key) !== -1) {
                    currentService.elevator = null;
                }

                if (value === null && key === 'temperature') {
                    currentService.start = '';
                    currentService.end = '';
                }

                if (
                    key === 'temperature' &&
                    currentService.start.length === 3 &&
                    currentService.end.length === 3
                ) {
                    if (name === 'end' && +currentService.start > +currentService.end) {
                        currentService.start = currentService.end;
                    }
                    if (name === 'start' && +currentService.end < +currentService.start) {
                        currentService.end = currentService.start;
                    }
                }

                newState.services = services;

                return newState;
            }, resolve);
        });
    }

    updateServices(names = null, isAll = false) {
        const { currentConfig } = this.context.state;
        const { item: currentItem } = this.context.handlerTariffs.getCurrentItem(currentConfig);

        const handler = (currentServiceRes, name) => {
            const item = currentServiceRes;

            switch (name) {
                case 'passengers':
                    if (currentItem && item.max !== currentItem.parameters.passengers) {
                        item.max = currentItem.parameters.passengers;
                    }
                    if (item.value > item.max) {
                        item.value = item.max;
                    }
                    break;
                case 'save':
                    item.value = false;
                    break;
                case 'pointToPoint':
                    item.value = false;
                    break;
                case 'workers':
                    item.elevator = null;
                    break;
                case 'temperature':
                    item.start = ``;
                    item.end = ``;
                    break;
                default:
                    item.value = false;
                    break;
            }
        };

        if (isAll === true || (names !== null && names.length)) {
            this.context.setState((state) => {
                const newState = {...state };
                const services = (newState.services && [...newState.services]) || [];

                if (isAll === true) {
                    services.forEach((service) => {
                        handler(service, service.key);
                    });
                } else {
                    names.forEach((name) => {
                        const currentService = services.find((service) => service.key === name);
                        if (currentService) {
                            handler(currentService, name);
                        }
                    });
                }

                newState.services = services;

                return newState;
            });
        }
    }

    getDefaultVal(item) {
        const { key } = item;
        let { type } = item;

        if (!type) {
            type = store
                .getState()
                .serverData.orderServices.find((service) => service.key === key).type;
        }

        switch (type) {
            case 'counter':
                return 0;
            case 'checkbox':
                return false;
            default:
                return null;
        }
    }

    init() {
        this.setServices().then(
            (servicesCome) => {
                this.context.setState(
                    (state) => {
                        const newState = {...state };
                        const services = [];

                        servicesCome.forEach((item) => {
                            const { key, price } = item;
                            const service = { key, price };

                            service.value = this.getDefaultVal(item);

                            services.push(service);
                        });

                        newState.services = services;

                        return newState;
                    },
                    () => {
                        this.updateServices(null, true);
                    },
                );
            },
            () => null,
        );
    }
}