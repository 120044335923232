import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../Field.jsx';

class PointDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    order = ['entrance', 'floor', 'room'];

    fields = {
        entrance: {
            className: '_start',
            type: 'input',
        },
        floor: {
            type: 'input',
        },
        room: {
            type: 'input',
            className: '_end',
        },
    };

    renderField(name) {
        const { point, numberOfPoint, handler, isDisabled, isEditmode } = this.props;
        const { className, type } = this.fields[name];
        const field = point?.addressatInfo?.[name];
        let result;

        switch (type) {
            default:
                {
                    let typeField = 'point';

                    if (name === 'name') {
                        typeField = numberOfPoint === 0 ? 'pointFirst' : 'point';
                    }

                    result = (
                        <Field
                            type={typeField}
                            keyName={name}
                            name={name}
                            value={field?.value}
                            error={field?.error}
                            callback={handler}
                            group={`point-details-${numberOfPoint}`}
                            className={`_orderPoint ${className || ``}`}
                            isDisabled={isDisabled}
                            setStateComplete={true}
                            isEditmode={isEditmode}
                        />
                    );
                }
                break;
        }

        return (
            <div className={`orderPointDetails__field _${name}`} key={name}>
                {result}
            </div>
        );
    }

    render() {
        return (
            <div className="orderPointDetails _row">
                <div className="orderPointDetails__fields _row">
                    {this.order.map((name) => this.renderField(name))}
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(PointDetails);

PointDetails.propTypes = {
    point: PropTypes.object,
    handler: PropTypes.func,
    numberOfPoint: PropTypes.number,
    isDisabled: PropTypes.bool,
    isEditmode: PropTypes.bool,
};
