import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Animate from '../Animate.jsx';
import DateWindow from '../Date.jsx';

import getFormatedDate from '../../functions/getFormatedDate';
import stopPropagationClick from '../../functions/stopPropagationClick';

class DetailsDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowDate: false,
        };

        this.handlerDate = this.handlerDate.bind(this);
        this.setStateOrderDate = this.setStateOrderDate.bind(this);

        this.dateWindow = React.createRef();
        this.parent = React.createRef();
    }

    handlerDate(isShowDate = !this.state.isShowDate) {
        this.setState({ isShowDate });
    }

    setStateOrderDate({ detail: { isShowDate } }) {
        this.handlerDate(isShowDate);
    }

    componentDidMount() {
        document.addEventListener('setStateOrderDate', this.setStateOrderDate);
    }

    componentWillUnmount() {
        document.removeEventListener('setStateOrderDate', this.setStateOrderDate);
    }

    render() {
        const { isShowDate } = this.state;
        const { order, date, times, handlerDate } = this.props;
        const resultDate = order?.dateOfOrder
            ? getFormatedDate({ date: new Date(order.dateOfOrder), type: 'fullText' })
            : 'Когда и во сколько везём?';

        return (
            <div ref={this.parent} className={`orderDetailsCard _date`}>
                <div className="orderDetailsCard__inner">
                    <div className="orderDetailsCard__head _col">
                        <h5 className="orderDetailsCard__title">Дата заказа</h5>
                    </div>
                    <div className="orderDetailsCard__content">
                        <div
                            className={`orderDetailsCard__date _row ${
                                order?.dateOfOrder ? '_complete' : ''
                            }`}
                        >
                            <div ref={this.dateWindow} className="orderDetailsCard__dateWindow">
                                <Animate
                                    className="orderDetailsCard__dateWindowInner"
                                    isShow={isShowDate}
                                >
                                    <DateWindow
                                        type="oneTime"
                                        className="_bottomRight"
                                        btn={this.parent.current?.querySelector(
                                            '.orderDetailsCard__dateInner',
                                        )}
                                        parent={this.dateWindow?.current}
                                        callback={handlerDate}
                                        hide={() => this.handlerDate(false)}
                                        date={date}
                                        times={times}
                                    />
                                </Animate>
                            </div>
                            <div
                                className="orderDetailsCard__dateInner _click"
                                onClick={(e) => {
                                    this.handlerDate();

                                    stopPropagationClick(e);
                                }}
                            >
                                <i className="orderDetailsCard__dateIcon">
                                    <Icon name="calendar-new" />
                                </i>
                                <div className="orderDetailsCard__dateResult">
                                    <AnimateChange
                                        className="orderDetailsCard__dateResultInner"
                                        prop={resultDate}
                                        type="_translateMedium"
                                    >
                                        {resultDate}
                                    </AnimateChange>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsDate);

DetailsDate.propTypes = {
    order: PropTypes.object,
    editName: PropTypes.string,
    isDisabled: PropTypes.bool,
    handlerEditmode: PropTypes.func,
    editmode: PropTypes.object,
    fakeCallback: PropTypes.func,
    date: PropTypes.string,
    times: PropTypes.object,
    handlerDate: PropTypes.func,
};
