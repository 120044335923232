import axios from 'axios';
import { dispatcher } from '../redux/redux';

import getHeaders from './getHeaders';
import getUser from './getUser';
import setNotification from './setNotification';
import handlerErrorRequest from './handlerErrorRequest';

export default function login({ login: loginValue, password }) {
    return new Promise((resolve) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/login`, { login: loginValue, password }, {
                    headers: getHeaders(),
                },
            )
            .then(
                (res) => {
                    const { success, data } = res.data;
                    const { hash, id } = data;

                    if (success === true) {
                        dispatcher({ type: 'isSuccessLogout', data: false });
                        dispatcher({ type: 'isProccessLogin', data: true }).then(() => {
                            getUser(hash, false, false, id).then(
                                () => {
                                    resolve(res);
                                },
                                () => null,
                            );
                        });
                    } else {
                        handlerErrorRequest(res);

                        const { message } = data;

                        if (message === 'You have unpaid order') {
                            setNotification({ notification: 'order-wait-pay' });
                        }

                        resolve(res);
                    }
                },
                () => null,
            );
    });
}