import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getGoogleVisionGroups from '../../../../requests/getGoogleVisionGroups';
import createId from '../../../../requests/createId';

import HandlerFilter from '../../../../classes/Filter';

import Animate from '../../../../components/Animate.jsx';
import MainDefault from '../../../../components/crm/manual/Main.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Button from '../../../../components/Button.jsx';
import getEndText from '../../../../functions/getEndText';

import WidgetFilter from '../../../../components/crm/widget/Filter.jsx';
import Edit from '../../../../components/Edit.jsx';
import Icon from '../../../../components/Icon.jsx';
import Field from '../../../../components/Field.jsx';

import Editmode from '../../../../classes/Editmode';
import setNotification from '../../../../functions/setNotification';
import ListScroll from '../../../../components/ListScroll.jsx';
import removeTransition from '../../../../functions/removeTransition.ts';
import scrollToPosition from '../../../../functions/scrollToPosition';
import getHeaders from '../../../../functions/getHeaders';
import handlerWindow, { updateWindow } from '../../../../functions/handlerWindow';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';

class ContentGoogleVisionList extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            counterScroll: this.stepCounter,
        };

        this.getWordsOrder = this.getWordsOrder.bind(this);
        this.renderWord = this.renderWord.bind(this);
        this.filterWord = this.filterWord.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.getMoreWords = this.getMoreWords.bind(this);
        this.addWord = this.addWord.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.deleteWordRequest = this.deleteWordRequest.bind(this);
        this.callbackGoogleVisionWordsFilter = this.callbackGoogleVisionWordsFilter.bind(this);
        this.filterByFilter = this.filterByFilter.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 12;

    cols = {
        key: {
            content: 'Слово (английский)',
        },
        translation: {
            content: 'Перевод (русский)',
        },
        long: {
            content: 'Длина',
            keyName: 'numberSize',
        },
        width: {
            content: 'Ширина',
            keyName: 'numberSize',
        },
        height: {
            content: 'Высота',
            keyName: 'numberSize',
        },
        weight: {
            content: 'Вес',
            keyName: 'numberWeight',
        },
        temperature: {
            content: 'Режим t°',
        },
    };

    filterByFilter(word) {
        const { filter = [] } = this.state;
        let cond = true;

        filter.forEach((block) => {
            if (block.type === 'search' && block.search) {
                cond = cond && word.key.toLowerCase().indexOf(block.search.toLowerCase()) !== -1;
            }
        });

        return cond;
    }

    getWordsOrder(withFilter = true) {
        const { group } = this.state;

        let resultWords = [];

        if (group) {
            resultWords = group.words.filter(this.filterByFilter);
        }

        resultWords = resultWords.sort(
            (a, b) => new Date(b.dateOfCreate).getTime() - new Date(a.dateOfCreate).getTime(),
        );

        if (withFilter) {
            resultWords = resultWords.filter(this.filterWord);
        }

        if (resultWords.length === 0) {
            resultWords = [{ _id: 'filterInfo' }];
        }

        return resultWords;
    }

    renderWord({ item, prop: id }) {
        if (id === 'filterInfo') {
            return (
                <div className="settingsUsersTable__row _info">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">Слово не найдено</div>
                            <p className="empty__content">Попробуйте изменить параметры фильтра</p>
                        </div>
                    </div>
                </div>
            );
        }

        const { group, editName, loadingKey, errors } = this.state;
        const { wordProps, checkRights } = this.props;
        const word = group.words.find((innerWord) => innerWord._id === id) || item;
        const { isNew } = word;

        return (
            <div className={`settingsUsersTable__row ${editName === id ? '_current' : ''}`}>
                <div
                    className={`settingsUsersTable__rowInner _parentForEdit _editBack _row ${isNew ? '_new' : ''} ${
                        editName === id ? '_current' : ''
                    }`}
                >
                    {checkRights() && (
                        <>
                            <Edit
                                name={id}
                                className="settingsUsersTable__rowEdit"
                                editName={editName}
                                handlerEditmode={this.handlerEditmode}
                                isLoader={loadingKey === id}
                            />
                            <div
                                className="settingsUsersTable__rowDelete _click"
                                onClick={({ target }) => this.deleteWord({ target, id })}
                            >
                                <Icon name="actions-delete" />
                            </div>
                        </>
                    )}

                    {wordProps.map((key) => {
                        let isDisabled = !isNew && editName !== id;

                        if (key === 'key' && !isNew) {
                            isDisabled = true;
                        }

                        return (
                            <div className={`settingsUsersTable__col _col _${key}`} key={key}>
                                <div className="settingsUsersTable__colInner">
                                    <div className="settingsUsersTable__colField">
                                        <Field
                                            support="–"
                                            type="contentGoogleVision"
                                            keyName={this.cols[key].keyName || key}
                                            name={key}
                                            value={word[key]}
                                            className="_notPadding"
                                            callback={({ ...props }) =>
                                                new Promise((resolve) => {
                                                    this.changeWord({ id, ...props }).then(resolve);
                                                })
                                            }
                                            isDisabled={isDisabled}
                                            isEditmode={true}
                                            id={`${id}-${key}`}
                                            error={errors?.[id]?.includes(key)}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    hideDeleteWord({ id }) {
        this.setState((state) => {
            const newState = { ...state };
            const group = JSON.parse(JSON.stringify(newState.group));
            const groupIndex = group.words.findIndex((innerWord) => innerWord._id === id);

            if (groupIndex !== -1) {
                group.words.splice(groupIndex, 1);
            }

            newState.group = group;
            newState.editName = null;

            return newState;
        });
    }

    deleteWord({ id, target }) {
        const { group } = this.state;
        const word = group.words.find((innerWord) => innerWord._id === id);
        const { getParent } = this.props;
        const parent = getParent();

        if (word.isNew) {
            this.hideDeleteWord({ id });
        } else {
            handlerWindow({
                parent,
                parentResize: parent,
                parentScroll: this.parent.current.querySelector('.settingsUsersTable__content'),
                isHideFromScroll: true,
                target: target.closest('.settingsUsersTable__rowDelete'),
                action: 'show',
                name: 'promptDeleteGroup',
                className: '_prompt _right',
                uniqKey: `prompt-${id}`,
                userId: id,
                centers: {
                    left: 0.5,
                    top: 1,
                },
            });
        }
    }

    deleteWordRequest({ id, hide }) {
        const { group } = this.state;

        axios
            .patch(
                `${process.env.REACT_APP_API}/google-items`,
                { id: group._id, deleteWordId: id },
                { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        setNotification({
                            notification: 'delete-googleVision-word',
                        });

                        this.hideDeleteWord({ id });

                        if (hide && typeof hide === 'function') {
                            hide();
                        }
                    } else {
                        handlerErrorRequest(res);
                    }
                },
                () => null,
            );
    }

    filterWord(word, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    getMoreWords(counter) {
        return new Promise((resolve) => {
            this.setState({ counterScroll: counter, isDisabledScroll: true }, () => {
                resolve();

                setTimeout(() => {
                    this.setState({ isDisabledScroll: false });
                }, 100);
            });
        });
    }

    changeWord({ id, action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const group = JSON.parse(JSON.stringify(newState.group));
                        const word = group.words.find((innerWord) => innerWord._id === id);

                        word[name] = value;

                        newState.group = group;

                        return newState;
                    },
                    () => {
                        this.handlerErrors({
                            action: 'delete',
                            error: name,
                            block: id,
                        });

                        resolve();
                    },
                );
            }
        });
    }

    addWord() {
        return new Promise((resolve) => {
            if (!this.state.editName) {
                this.handlerLoadingBlock('add').then(() => {
                    createId().then(
                        ({ id }) => {
                            this.setState(
                                (state) => {
                                    const newState = { ...state };
                                    const group = JSON.parse(JSON.stringify(newState.group));

                                    const word = {
                                        _id: id,
                                        key: '',
                                        translation: '',
                                        long: '',
                                        width: '',
                                        height: '',
                                        weight: '',
                                        isNew: true,
                                        dateOfCreate: new Date(),
                                    };

                                    group.words.push(word);

                                    newState.group = group;
                                    newState.editName = id;

                                    return newState;
                                },
                                () => {
                                    this.handlerLoadingBlock(null).then(resolve);

                                    const scrollDom = this.parent.current.querySelector(
                                        '.settingsUsersTable__content',
                                    );

                                    scrollToPosition({ position: 'top', parent: scrollDom });
                                },
                            );
                        },
                        () => null,
                    );
                });
            }
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getChangedFields() {
        const { group, saveGroup } = this.state;
        const { wordProps } = this.props;
        const id = this.state.editName;
        const word = group.words.find((innerWord) => innerWord._id === id);
        const saveWord = saveGroup.words.find((innerWord) => innerWord._id === id);
        const fields = {};

        if (word && saveWord) {
            wordProps.forEach((key) => {
                if (word[key] !== saveWord[key]) {
                    fields[key] = word[key];
                }
            });
        }

        return { isSuccess: Object.keys(fields).length > 0, fields };
    }

    checkValidation({ fields }) {
        const errors = [];
        const { wordProps } = this.props;

        wordProps.forEach((prop) => {
            if (!fields[prop]) {
                errors.push(prop);
            }
        });

        return { errors };
    }

    handlerEditmode({ editName }) {
        const { group } = this.state;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else {
                    const id = this.state.editName;
                    const word = group.words.find((innerWord) => innerWord._id === id);
                    const { isNew } = word;
                    const { isSuccess } = this.getChangedFields();
                    const body = { id: group._id, words: [word] };
                    const { errors } = this.checkValidation({ fields: word });

                    if (errors.length) {
                        this.handlerErrors({
                            action: 'add',
                            errors,
                            block: id,
                        });

                        setNotification({ notification: 'required-fields-not-complete' });
                    } else if (isNew || isSuccess) {
                        this.handlerLoadingBlock(id).then(() => {
                            axios
                                .patch(`${process.env.REACT_APP_API}/google-items`, body, {
                                    headers: getHeaders(),
                                })
                                .then(
                                    (res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            this.setGroup({ group: this.state.group });

                                            setNotification({ notification: 'success-change-info' });

                                            resolve();
                                        } else {
                                            const { message } = data;

                                            if (message === 'Word with this key already create') {
                                                setNotification({
                                                    notification:
                                                        'error-googleVision-word-already-create',
                                                });

                                                this.handlerErrors({
                                                    action: 'add',
                                                    error: 'key',
                                                    block: id,
                                                });
                                            }

                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoadingBlock(null);
                                    },
                                    () => null,
                                );
                        });
                    } else if (!isSuccess) {
                        resolve();
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    setGroup({ group: resGroup }) {
        const group = resGroup;

        group.words = group.words.map((word) => ({
            ...word,
            isNew: undefined,
        }));

        this.setState(
            {
                group,
                saveGroup: JSON.parse(JSON.stringify(group)),
            },
            () => {
                setTimeout(() => {
                    this.setState({ isReady: true });
                }, 300);
            },
        );
    }

    getGroup() {
        getGoogleVisionGroups({ params: [{ key: 'isAdmin', value: true }] }).then(
            ({ group }) => {
                this.setGroup({ group });
            },
            () => null,
        );
    }

    initFilter({ blocks }) {
        this.handlerFilter.init({ blocks });
    }

    filterCallback({ filter, isChange }) {
        return new Promise((resolve) => {
            resolve();

            if (isChange) {
                this.setState({ filter });
            }
        });
    }

    callbackGoogleVisionWordsFilter({ filter, isChange }) {
        return new Promise((resolve) => {
            if (isChange) {
                resolve();

                this.setState({ isFilterLoading: true }, () => {
                    setTimeout(() => {
                        this.setState(
                            {
                                filter,
                                filterKey: new Date().getTime(),
                                counterScroll: this.stepCounter,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ isFilterLoading: false });
                                }, 300);
                            },
                        );
                    }, 300);
                });
            } else {
                resolve();
            }
        });
    }

    getFilter() {
        const blocks = [
            {
                type: 'search',
                key: 'searchForWord',
                isShowInit: true,
            },
        ];

        this.initFilter({ blocks });
    }

    componentDidMount() {
        const { setDeleteWordRequest, initCallbackGoogleVisionWordsFilter } = this.props;

        this.editmode = new Editmode({
            context: this,
            callback: () => null,
        });

        this.getGroup();

        setDeleteWordRequest(this.deleteWordRequest);

        initCallbackGoogleVisionWordsFilter(this.callbackGoogleVisionWordsFilter);

        this.handlerFilter = new HandlerFilter({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: 'filter-googleVisionWords', filter });
            },
        });

        this.getFilter();
    }

    render() {
        const {
            loadingKey,
            editName,
            group,
            filter,
            isReady,
            isShowLoaderList = false,
            isDisabledScroll,
            isFilterLoading = false,
        } = this.state;
        const { wordProps, checkRights, getParent } = this.props;
        const filteredWords = this.getWordsOrder();
        const words = this.getWordsOrder(false);

        return (
            <div ref={this.parent} className={`widget ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _inner _full _scroll">
                    <div className="widget__contentBox _padding">
                        <div
                            className={`settingsUsersTable _googleVision _init ${
                                editName ? '_edit' : ''
                            } ${isFilterLoading ? '_loading' : ''}`}
                        >
                            <div className="settingsUsersTable__inner">
                                <div className="settingsUsersTable__wrapper">
                                    <div className="settingsUsersTable__head _static">
                                        <div className="settingsUsersTable__headInner _row">
                                            <div className="settingsUsersTable__headTitle _row">
                                                {group?.words.length}{' '}
                                                {getEndText(group?.words.length, [
                                                    'объект',
                                                    'объекта',
                                                    'объектов',
                                                ])}
                                            </div>
                                            <div className="settingsUsersTable__headActions _row">
                                                <div className="settingsUsersTable__headFilter">
                                                    <WidgetFilter
                                                        className="_grey"
                                                        name="googleVisionWords"
                                                        filter={filter}
                                                        getParent={getParent}
                                                        handlerFilter={this.handlerFilter}
                                                        windowName="googleVisionWordsFilter"
                                                    />
                                                </div>
                                                {checkRights() && (
                                                    <div className="settingsUsersTable__headButton">
                                                        <Button
                                                            className="_mediumSize _mainNotBorder"
                                                            onClick={this.addWord}
                                                            showLoader={loadingKey === 'add'}
                                                        >
                                                            + Новое слово
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Animate
                                        className="settingsUsersTable__loader _loader"
                                        isShow={isFilterLoading}
                                    >
                                        <i className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </i>
                                    </Animate>
                                    <div
                                        className={`JStransVertical settingsUsersTable__content ${
                                            editName ? '_disabled' : ''
                                        }`}
                                    >
                                        <div className="settingsUsersTable__row _head">
                                            {wordProps.map((name) => (
                                                <div
                                                    className={`settingsUsersTable__col _col _${name}`}
                                                    key={name}
                                                >
                                                    {this.cols[name].content}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="settingsUsersTable__contentInner">
                                            <ListScroll
                                                isInit={isReady}
                                                getParent={() =>
                                                    this.parent.current?.querySelector(
                                                        '.settingsUsersTable__content',
                                                    )
                                                }
                                                callback={this.getMoreWords}
                                                startCounter={this.stepCounter}
                                                stepCounter={this.stepCounter}
                                                maxCounter={words.length}
                                                lengthCurrent={filteredWords.length}
                                                handlerLoaderList={this.handlerLoaderList}
                                                isLimit={filteredWords.length === words.length}
                                                isDisabledScroll={
                                                    !isReady || isDisabledScroll || isFilterLoading
                                                }
                                            >
                                                <ListAbsoluteMain
                                                    className="settingsUsersTable__rows _col _dynamic"
                                                    items={filteredWords}
                                                    renderItem={this.renderWord}
                                                    classNameItem="settingsUsersTable__row"
                                                    prop="_id"
                                                    paramsParent={{ width: true }}
                                                    styles={['height']}
                                                    propsForUpdate={wordProps.concat(...['isNew'])}
                                                    callback={({ type }) => {
                                                        if (type === 'parent') {
                                                            removeTransition({
                                                                item: '.settingsUsersTable__row',
                                                                isCurrent: true,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </ListScroll>
                                        </div>
                                    </div>
                                    <Animate
                                        className="settingsUsersTable__listLoader _loaderScroll _white"
                                        isShow={isShowLoaderList}
                                    >
                                        <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ContentGoogleVisionList);

ContentGoogleVisionList.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    wordProps: PropTypes.array,
    setDeleteWordRequest: PropTypes.func,
    checkRights: PropTypes.func,
    initCallbackGoogleVisionWordsFilter: PropTypes.func,
};
