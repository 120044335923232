import React from 'react';
import { connect } from 'react-redux';

import setSpacesInText from '@functions/setSpacesInText.js';

import CasesI from './types.ts';

import cards from './static/cards.tsx';

class Cases extends React.Component<CasesI['props'], CasesI['state']> implements CasesI {
    constructor(props: CasesI['props']) {
        super(props);
        this.state = {};
    }

    cards = cards;

    render() {
        return (
            <div className="publicBusinessCases _SECTION">
                <div className="publicBusinessCases__inner _INNER _PUBLIC">
                    <div className="publicBusinessCases__head _COL">
                        <h3 className="publicBusinessCases__title _TITLE _PUBLIC _WHITE">
                            Что умеет LIVECARGO&nbsp;CRM
                        </h3>
                        <div className="publicBusinessCases__subTitle _TEXT _PUBLIC _WHITE">
                            От подключения исполнителей – <br />
                            до налогов самозанятых.
                        </div>
                    </div>
                    <div className="publicBusinessCases__content">
                        {this.cards.map((card, cardKey) => {
                            const { category, title, content, decor } = card;

                            return (
                                <div className={`publicBusinessCases__block _ROW _${cardKey + 1}`} key={cardKey}>
                                    <div className="publicBusinessCases__blockDecor">{decor()}</div>
                                    <div className="publicBusinessCases__blockContent">
                                        <div className="publicBusinessCases__blockCategory">
                                            {category}
                                        </div>
                                        <div
                                            className="publicBusinessCases__blockTitle"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(title),
                                            }}
                                        ></div>
                                        <div
                                            className="publicBusinessCases__blockText"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(content),
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Cases);
