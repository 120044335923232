import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import Icon from '../../Icon.jsx';
import AnimateChange from '../../AnimateChange.jsx';

import handlerSupportCursor from '../../../functions/handlerSupportCursor';
import getFormatPrice from '../../../functions/getFormatPrice';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';
import getWidthInput from '../../../functions/getWidthInput';
import getExecutorPassportInfo from '../../../functions/getExecutorPassportInfo';

import Field from '../../Field.jsx';

class OrderDetailsCrewExecutor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getDescriptionAccept() {
        const { isAccept } = this.props;

        let description = 'Исполнитель';

        description += ' ';
        description += isAccept ? 'принял' : 'не принял';
        description += ' заказ';

        return description;
    }

    checkPrice() {
        const {
            handlerChangedPrice,
            editNameExecutor,
            getTariffInfo,
            tariff,
            type,
            isDriver,
            executorInCrew,
        } = this.props;
        const { amount } = getTariffInfo({ tariff, type, isDriver });

        if (+amount !== this.savePrice) {
            this.savePrice = amount;
            this.saveChangedPrice = executorInCrew?.changedPrice;

            handlerChangedPrice({ action: 'change', id: editNameExecutor, value: amount });
        }
    }

    componentDidMount() {
        this.checkPrice();
    }

    componentDidUpdate() {
        this.checkPrice();
    }

    render() {
        const {
            isEmpty,
            _id,
            firstName,
            secondName,
            thirdName,
            type,
            phone,
            passport,
            editName,
            deleteExecutor,
            getCondForChange,
            editNameExecutor,
            isShowLoading,
            isAccept,
            parent,
            tariff,
            changeTariff,
            handlerChangedPrice,
            changedPrice,
            order,
            getTariffInfo,
            isDriver,
        } = this.props;

        const typeName = type === 'driver' ? 'Водитель' : 'Грузчик';
        const changeContent = type === 'driver' ? 'водителя' : 'грузчика';
        const descriptionAccept = this.getDescriptionAccept();
        const { amount, name } = getTariffInfo({ tariff, type, isDriver });
        const amountString = `${getFormatPrice(amount)}₽`;
        const idWindow = `executor-tariff-${_id}`;

        const condForPrice = editName === editNameExecutor && order?.status === 'complete';

        return (
            <div
                className={`orderDetailsCrewExecutor _editBack ${isEmpty ? '_empty' : ''} ${
                    !isEmpty && editName === editNameExecutor
                        ? '_current'
                        : editName
                        ? '_disabled'
                        : ''
                }`}
            >
                <Animate className="orderDetailsCrewCar__loader _loader" isShow={isShowLoading}>
                    <div className="orderDetailsCrewCar__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate
                    className="orderDetailsCrewExecutor__empty"
                    isShow={!isShowLoading && isEmpty}
                >
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">{typeName} не выбран</div>
                            <p className="empty__content">Вернитесь назад для выбора</p>
                        </div>
                    </div>
                </Animate>
                <Animate className="orderDetailsCrewExecutor__inner _row" isShow={!isEmpty}>
                    <div className="orderDetailsCrewExecutor__items _row">
                        <div className="orderDetailsCrewExecutor__item _col _name">
                            <div className="orderDetailsCrewExecutor__support">{typeName}:</div>
                            <div className="orderDetailsCrewExecutor__content _row">
                                {secondName} {firstName} {thirdName}
                            </div>
                        </div>
                        <div className="orderDetailsCrewExecutor__item _col _passport">
                            <div className="orderDetailsCrewExecutor__support">Паспорт:</div>
                            <div className="orderDetailsCrewExecutor__content _row">
                                {getExecutorPassportInfo({ passport })}
                            </div>
                        </div>
                        <div className="orderDetailsCrewExecutor__item _col _phone">
                            <div className="orderDetailsCrewExecutor__support">Телефон:</div>
                            <div className="orderDetailsCrewExecutor__content _row">{phone}</div>
                            <div className="orderDetailsCrewExecutor__itemFoot">
                                <Animate
                                    className="orderDetailsCrewExecutor__button"
                                    isShow={getCondForChange() && editName === editNameExecutor}
                                    onClick={() => {
                                        deleteExecutor({ id: _id, type });
                                    }}
                                >
                                    <div className="orderDetailsCrewExecutor__buttonInner _row _click">
                                        Сменить {changeContent}
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                    <div className="orderDetailsCrewExecutor__item _col _tariff">
                        {type === 'driver' && (
                            <div
                                className={`orderDetailsCrewExecutor__accept _col ${
                                    isAccept ? '_active' : ''
                                }`}
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: descriptionAccept,
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <i className="orderDetailsCrewExecutor__acceptIcon">
                                    <Icon name="done" />
                                </i>
                            </div>
                        )}
                        <div className="orderDetailsCrewExecutor__support">Тариф:</div>
                        <div className="orderDetailsCrewExecutor__content _row">{name}</div>
                        <div
                            className={`orderDetailsCrewExecutor__content _row _amount ${
                                order?.status === 'complete' ? '_active' : ''
                            }`}
                        >
                            Общая сумма:
                            <AnimateChange
                                className="orderDetailsCrewExecutor__contentItem"
                                prop={amountString}
                                type="_translateMedium"
                            >
                                <div
                                    className="orderDetailsCrewExecutor__contentPrice"
                                    style={{
                                        width: `${
                                            getWidthInput({
                                                classNames: [
                                                    'orderDetailsCrewExecutor__contentPrice',
                                                ],
                                                value: `${changedPrice}₽`,
                                            }) + (condForPrice ? 8 : 0)
                                        }px`,
                                    }}
                                >
                                    <Field
                                        className="_crewExecutor"
                                        keyName="price"
                                        name="price"
                                        callback={({ ...props }) =>
                                            new Promise((resolve) => {
                                                handlerChangedPrice({
                                                    ...props,
                                                    id: editNameExecutor,
                                                }).then(resolve);
                                            })
                                        }
                                        value={changedPrice}
                                        isDisabled={!condForPrice}
                                        isEditmode={true}
                                    />
                                </div>
                            </AnimateChange>
                        </div>
                        <div className="orderDetailsCrewExecutor__itemFoot">
                            <Animate
                                className="orderDetailsCrewExecutor__button"
                                isShow={getCondForChange() && editName === editNameExecutor}
                                onClick={({ target }) => {
                                    handlerWindow({
                                        parent,
                                        parentResize: parent,
                                        target: target.closest('.orderDetailsCrewExecutor__button'),
                                        action: 'show',
                                        name: 'tariffExecutor',
                                        className: '_executorTariff',
                                        uniqKey: idWindow,
                                        watchesProps: { left: true },
                                        tariff: {
                                            ...tariff,
                                            customPrice:
                                                tariff.configurations.find(
                                                    (config) => config._id === 'customConfig',
                                                )?.items[0]?.amount || '',
                                        },
                                        changeTariff: (data) =>
                                            new Promise((resolve) => {
                                                changeTariff({ ...data, _id }).then(() => {
                                                    updateWindow({
                                                        key: idWindow,
                                                        tariff: this.props.tariff,
                                                    });

                                                    resolve();
                                                });
                                            }),
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }}
                            >
                                <div className="orderDetailsCrewExecutor__buttonInner _row _click">
                                    Сменить тариф
                                </div>
                            </Animate>
                        </div>
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderDetailsCrewExecutor);

OrderDetailsCrewExecutor.propTypes = {
    isEmpty: PropTypes.bool,
    _id: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    thirdName: PropTypes.string,
    phone: PropTypes.string,
    type: PropTypes.string,
    tariff: PropTypes.object,
    passport: PropTypes.object,
    editName: PropTypes.string,
    deleteExecutor: PropTypes.func,
    getCondForChange: PropTypes.func,
    editNameExecutor: PropTypes.string,
    isShowLoading: PropTypes.bool,
    isAccept: PropTypes.bool,
    parent: PropTypes.object,
    changeTariff: PropTypes.func,
    isDriver: PropTypes.bool,
    changedPrice: PropTypes.string,
    handlerChangedPrice: PropTypes.func,
    order: PropTypes.object,
    getTariffInfo: PropTypes.func,
    executorInCrew: PropTypes.object,
};
