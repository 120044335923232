import getExecutorIcon from './getExecutorIcon';

export default function getExecutorInfo({ type, executor }) {
    switch (type) {
        case 'icon':
            return getExecutorIcon({
                type: executor.role,
            });
        case 'car':
            return executor.infoCars
                ? executor.infoCars.find((car) => car._id === executor.car)
                : null;
        default:
            return null;
    }
}
