import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getFormatedDate from '../../functions/getFormatedDate';
import handlerWindow from '../../functions/handlerWindow';
import getUserInfo from '../../functions/getUserInfo';
import getExecutorInfo from '../../functions/getExecutorInfo';
import handlerSupportCursor from '../../functions/handlerSupportCursor';
import getFormatPrice from '../../functions/getFormatPrice';

import Icon from '../Icon.jsx';
import Link from '../Link.jsx';
import ImageLazy from '../ImageLazy.jsx';
import Responsible from './Responsible.jsx';
import Status from '../order/Status.jsx';
import Animate from '../Animate.jsx';
import InputRunning from '../InputRunning.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Loader from '../Loader.jsx';
import changePage from '../../functions/changePage';
import { dispatcher } from '../../redux/redux';
import setPermissions from '../../functions/crm/setPermissions';
import getPageLink from '../../functions/getPageLink';

const selects = require('../../infos/selects.json');
const orderSystemTypes = require('../../infos/crm/orderSystemTypes.json');
const orderServiceCompanies = require('../../infos/crm/orderServiceCompanies.json');

class OrderPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCrewItem = this.renderCrewItem.bind(this);
        this.renderCrewStep = this.renderCrewStep.bind(this);
        this.orderCopy = this.orderCopy.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    orderItems = ['number', 'systemType', 'city', 'date', 'client'];

    items = {
        number: {
            support: 'Номер заказа',
            render() {
                const { number } = this.props;

                return `№${number}`;
            },
        },
        systemType: {
            support: 'Тип заказа',
            render() {
                const { systemType } = this.props;
                const info = orderSystemTypes[systemType];
                const { icon: iconName, name } = info;

                return (
                    <>
                        <div className="orderPreview__typ _row">
                            <i className="orderPreview__typeIcon">
                                <Icon name={iconName} />
                            </i>
                            {name}
                        </div>
                    </>
                );
            },
        },
        city: {
            support: 'Город',
            render() {
                const { city } = this.props;

                return city || '–';
            },
        },
        date: {
            support: 'Дата',
            render() {
                const { dateOfOrder } = this.props;

                return getFormatedDate({ date: new Date(dateOfOrder), type: 'full' });
            },
        },
        client: {
            support: 'Клиент',
            render() {
                const { holderData } = this.props;

                return holderData.fullName || getUserInfo({ type: 'doubleName', user: holderData });
            },
        },
    };

    renderDetailsBtn() {
        const { _id } = this.props;

        return (
            <Link className="orderPreview__link _col" pageName="order-details-main" ids={{ 2: _id }}>
                <i className="orderPreview__linkIcon">
                    <Icon name="more-horizontal" />
                </i>
                Детали заказа
            </Link>
        );
    }

    renderResponsible() {
        const {
            _id,
            parent,
            parentScroll,
            idOfResponsible,
            infoResponsible = {},
            setScrollPosition,
            typePage,
            checkRights,
        } = this.props;

        const props = {};

        if (typePage === 'main') {
            props.className = '_list _right';
            props.watchesProps = { top: true };
            props.centers = {
                left: 0,
                top: 0.5,
            };
        } else {
            props.className = '_list';
            props.watchesProps = { left: true };
            props.centers = {
                left: 0.5,
                top: 1,
            };
        }

        return (
            <div
                className="orderPreview__responsible"
                onClick={(e) => {
                    if (checkRights?.()) {
                        handlerWindow({
                            parent,
                            parentScroll,
                            target: e.target.closest('.orderPreview__responsible'),
                            action: 'show',
                            name: 'listResponsibleOrders',
                            uniqKey: _id,
                            idOfResponsible,
                            isNotReverse: true,
                            ...props,
                        });
                        setScrollPosition({ _id });
                    }
                }}
            >
                <Responsible {...infoResponsible} type={typePage === 'main' ? 'short' : ''} />
            </div>
        );
    }

    getCrewExecutors() {
        const { crewTemplate, crewInfo: crewInfoProps = [], tariffInfo } = this.props;
        const crew = JSON.parse(JSON.stringify(crewInfoProps));

        const crewItems = [
            ...(!['worker', 'courier'].includes(tariffInfo.typeConfig)
                ? [
                      {
                          id: 'car',
                      },
                  ]
                : []),
            ...crewTemplate.map((executorTemplate, key) => {
                const indexExecutor = crew.findIndex(
                    (executor) => executor.role === executorTemplate,
                );
                const executor = crew[indexExecutor];

                crew.splice(indexExecutor, 1);

                return {
                    ...executor,
                    id: `${executorTemplate}-${key}`,
                    role: executorTemplate,
                    _id: executor?.id,
                };
            }),
        ];

        return crewItems;
    }

    renderCrewItem({ item, prop: id, isLast }) {
        const { _id, setStateLoaderOrder, orderLoaderStack } = this.props;
        const { carInfo } = this.props;
        const idLoader = `${_id}-${id}`;
        let icon;
        let idLink;
        let href;
        let contentSupport;

        if (id === 'car') {
            href = 'manual-cars-inner';
            idLink = carInfo?._id;

            icon = 'car-icons';
            icon += '/';
            icon += carInfo ? carInfo.icon : 'kabluk.png';

            if (carInfo) {
                contentSupport = carInfo.nameMark;
                contentSupport += ', ';
                contentSupport += carInfo.nameModel;
            }
        } else {
            href = 'manual-executors-inner';
            idLink = item._id;

            icon = 'images';
            icon += '/';
            icon += getExecutorInfo({ type: 'icon', executor: { role: item.role } });

            contentSupport = selects.executorTypes.items.find(
                (innerType) => innerType.key === item.role,
            )?.content;
            contentSupport += '<br/>';
            contentSupport += getUserInfo({ type: 'name', user: item });
        }

        return (
            <Link
                pageName={href}
                ids={{ 2: idLink }}
                className={`orderPreview__detailsCrewItem _col _${id} ${isLast ? '_last' : ''}`}
                onMouseEnter={(e) => {
                    handlerSupportCursor({
                        action: 'enter',
                        content: contentSupport,
                        e,
                        data: { className: '_normalCase _center' },
                    });
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                <ImageLazy
                    className="orderPreview__detailsCrewItemIcon"
                    src={`${process.env.REACT_APP_STATIC}/${icon}`}
                    setStateLoader={({ value }) => setStateLoaderOrder({ id: idLoader, value })}
                    stateLoader={orderLoaderStack?.[idLoader]}
                />
            </Link>
        );
    }

    renderCrewBtn() {
        const { user, _id, crew, idOfResponsible } = this.props;

        return (
            <Animate
                className="orderPreview__contentCrewBtn"
                isShow={crew.length === 0 && idOfResponsible === user._id}
            >
                <Link
                    className="orderPreview__contentCrewBtnInner"
                    pageName="order-details-crew"
                    ids={{ 2: _id }}
                >
                    Назначить экипаж
                </Link>
            </Animate>
        );
    }

    renderCrewStep({ prop }) {
        const { _id, systemType, serviceCompany } = this.props;

        return (
            <div className={`orderPreview__detailsCrewBlock _${prop}`}>
                <>
                    {systemType === 'crm' ? (
                        <>
                            {prop === 'cancel' && (
                                <>
                                    <div className="orderPreview__detailsCrewSuccess _col _cancel">
                                        <i className="orderPreview__detailsCrewSuccessIcon">
                                            <Icon name="close-circle" />
                                        </i>
                                        <p className="orderPreview__detailsCrewSuccessSupport">
                                            Заказ отменен
                                        </p>
                                    </div>
                                </>
                            )}
                            {prop === 'wait-crew' && (
                                <>
                                    <Link
                                        className="orderPreview__detailsCrewLink _col"
                                        pageName="order-details-crew"
                                        ids={{ 2: _id }}
                                    >
                                        <i className="orderPreview__detailsCrewLinkIcon">
                                            <Icon name="order-crew-plus" />
                                        </i>
                                        <div className="orderPreview__detailsCrewLinkContent">
                                            Назначить исполнителя
                                        </div>
                                    </Link>
                                </>
                            )}
                            {prop === 'set-crew' && (
                                <>
                                    <div className="orderPreview__detailsCrewSuccess _col">
                                        <i className="orderPreview__detailsCrewSuccessIcon">
                                            <Icon name="success-crew" />
                                        </i>
                                        <p className="orderPreview__detailsCrewSuccessSupport">
                                            Экипаж назначен:
                                        </p>
                                        <div className="orderPreview__detailsCrewSuccessContent _col">
                                            <ListAbsoluteMain
                                                className="orderPreview__detailsCrewItems"
                                                items={this.getCrewExecutors()}
                                                renderItem={this.renderCrewItem}
                                                classNameItem="orderPreview__detailsCrewItem"
                                                prop="id"
                                                paramsParent={{ width: true, height: true }}
                                                // isNotParams={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {prop === 'cancel' && (
                                <>
                                    <div className="orderPreview__detailsCrewSuccess _col _cancel">
                                        <i className="orderPreview__detailsCrewSuccessIcon">
                                            <Icon name="close-circle" />
                                        </i>
                                        <p className="orderPreview__detailsCrewSuccessSupport">
                                            Заказ отменен
                                        </p>
                                        <div className="orderPreview__detailsCrewSuccessContent _col">
                                            <div className="orderPreview__detailsCrewSuccessLogo">
                                                <img
                                                    src={require(`../../img/crm/order-services-companies/${
                                                        orderServiceCompanies.find(
                                                            (item) =>
                                                                item.key === serviceCompany.id,
                                                        ).logo
                                                    }`)}
                                                    alt=""
                                                    className="orderPreview__detailsCrewSuccessLogoImage"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {prop === 'wait-crew' && (
                                <>
                                    <div className="orderPreview__detailsCrewSuccess _col">
                                        <i className="orderPreview__detailsCrewSuccessIcon">
                                            <Loader className="_main" />
                                        </i>
                                        <p className="orderPreview__detailsCrewSuccessSupport">
                                            Ищем исполнителя
                                        </p>
                                        <div className="orderPreview__detailsCrewSuccessContent _col">
                                            <div className="orderPreview__detailsCrewSuccessLogo">
                                                <img
                                                    src={require(`../../img/crm/order-services-companies/${
                                                        orderServiceCompanies.find(
                                                            (item) =>
                                                                item.key === serviceCompany.id,
                                                        ).logo
                                                    }`)}
                                                    alt=""
                                                    className="orderPreview__detailsCrewSuccessLogoImage"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {prop === 'set-crew' && (
                                <>
                                    <div className="orderPreview__detailsCrewSuccess _col">
                                        <i className="orderPreview__detailsCrewSuccessIcon">
                                            <Icon name="success-crew" />
                                        </i>
                                        <p className="orderPreview__detailsCrewSuccessSupport">
                                            Исполнитель найден:
                                        </p>
                                        <div className="orderPreview__detailsCrewSuccessContent _col">
                                            <div className="orderPreview__detailsCrewSuccessLogo">
                                                <img
                                                    src={require(`../../img/crm/order-services-companies/${
                                                        orderServiceCompanies.find(
                                                            (item) =>
                                                                item.key === serviceCompany.id,
                                                        ).logo
                                                    }`)}
                                                    alt=""
                                                    className="orderPreview__detailsCrewSuccessLogoImage"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            </div>
        );
    }

    renderCrew() {
        const { status, systemType, crew, serviceCompany } = this.props;
        const crewItems = [];

        if (status === 'cancel') {
            crewItems.push({ key: 'cancel' });
        } else {
            if (systemType === 'crm') {
                if (crew.length === 0) {
                    crewItems.push({ key: 'wait-crew' });
                } else {
                    crewItems.push({ key: 'set-crew' });
                }
            }

            if (systemType === 'service') {
                if (serviceCompany?.crew.length === 0) {
                    crewItems.push({ key: 'wait-crew' });
                } else {
                    crewItems.push({ key: 'set-crew' });
                }
            }
        }

        return (
            <div className="orderPreview__detailsCrew">
                <ListAbsoluteMain
                    className="orderPreview__detailsCrewInner"
                    items={crewItems}
                    renderItem={this.renderCrewStep}
                    classNameItem="orderPreview__detailsCrewBlock"
                    prop="key"
                    paramsParent={{ width: true, height: true }}
                    isNotParams={true}
                    isNotParamsItem={true}
                />
            </div>
        );
    }

    orderCopy() {
        const { systemType } = this.props;
        const order = {};

        [
            'isNds',
            'pay',
            'type',
            'services',
            'holder',
            'counterTime',
            'duration',
            'distance',
            'city',
        ].forEach((key) => {
            order[key] = this.props[key];
        });

        if (this.props.systemType === 'service') {
            ['tariff', 'tariffInfo'].forEach((key) => {
                order[key] = this.props[key];
            });
        }

        // order.tariff.id = '123';
        // order.tariff.idOfConfig = '123';
        // order.tariff.idOfItem = '123';

        order.route = this.props.route.map((point) => ({
            address: point.address,
            addressatInfo: point.addressatInfo,
            coords: point.coords,
            sk: point.sk,
            ttk: point.ttk,
            cargo: point.cargo,
        }));

        dispatcher({ type: 'copyOrder', data: order }).then(() => {
            changePage({
                href: getPageLink({ name: 'order-details-main', ids: { 2: systemType } }),
            });
        });
    }

    render() {
        const {
            number,
            dateOfOrder,
            route,
            typePage,
            status,
            amount,
            tariffInfo,
            city,
            systemType,
        } = this.props;
        const [firstPoint] = route;
        const lastPoint = route[route.length - 1];
        const isMore = route.length > 2;
        const tariffType = systemType === 'service' ? tariffInfo.key : tariffInfo.typeConfig;

        return (
            <div
                ref={this.parent}
                className={`orderPreview _${typePage} _${status} ${
                    !this.getPermissions({
                        key: 'orders',
                        items: [{ key: 'main', rules: ['create'] }],
                    })
                        ? '_notRights'
                        : ''
                }`}
            >
                {typePage === 'main' ? (
                    <>
                        <div className="orderPreview__status">
                            <Status
                                status={{ name: status }}
                                order={{ ...this.props }}
                                type="full"
                                className="_inPreview"
                            />
                        </div>
                        <div className="orderPreview__inner _col">
                            <div className="orderPreview__head _row">
                                <div className="orderPreview__headItems _row">
                                    {this.orderItems.map((name) => {
                                        const item = this.items[name];
                                        const { support } = item;

                                        return (
                                            <div
                                                className={`orderPreview__headItem _col _${name}`}
                                                key={name}
                                            >
                                                <p className="orderPreview__headItemSupport">
                                                    {support}
                                                </p>
                                                <div className="orderPreview__headItemValue">
                                                    {item.render.call(this)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="orderPreview__headDetails">
                                    {this.renderDetailsBtn()}
                                </div>
                            </div>
                            <div className="orderPreview__content _row">
                                <div className="orderPreview__details _row">
                                    <div className="orderPreview__detailsBlock _col _addresses">
                                        <div className="orderPreview__detailsAddresses">
                                            {(route.length === 1 ? [0] : [0, route.length - 1])
                                                .filter((key) => !!route[key])
                                                .map((key) => {
                                                    const point = route[key];

                                                    return (
                                                        <div
                                                            className="orderPreview__detailsAddress"
                                                            key={key}
                                                        >
                                                            <div className="orderPreview__detailsAddressInner">
                                                                <i className="orderPreview__detailsAddressIcon">
                                                                    <Icon name="order-preview-point" />
                                                                    {key !== 0 && (
                                                                        <>
                                                                            <div className="orderPreview__detailsAddressNumber">
                                                                                {key + 1}
                                                                            </div>
                                                                            <svg className="orderPreview__detailsAddressLine">
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="10"
                                                                                    x2="10"
                                                                                    y2="24"
                                                                                />
                                                                            </svg>
                                                                        </>
                                                                    )}
                                                                </i>
                                                                <div className="orderPreview__detailsAddressName">
                                                                    <InputRunning
                                                                        type="text"
                                                                        className="orderPreview__detailsAddressNameInput"
                                                                        defaultValue={point.address}
                                                                        readOnly={true}
                                                                        isRun={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            {route.length === 1 && (
                                                <div className="orderPreview__detailsAddressSupport">
                                                    Больше адресов нет. Маршрут будет построен
                                                    <br />
                                                    в&nbsp;процессе выполнения
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="orderPreview__detailsBlock _col _amount">
                                        <div className="orderPreview__detailsAmount _col">
                                            <p className="orderPreview__detailsAmountValue">
                                                {getFormatPrice(amount)}₽
                                            </p>
                                            <p className="orderPreview__detailsAmountSupport">
                                                Стоимость заказа
                                            </p>
                                        </div>
                                    </div>
                                    <div className="orderPreview__detailsBlock _col _tariff">
                                        <div
                                            className={`orderPreview__detailsTariff _${tariffType}`}
                                        >
                                            <ImageLazy
                                                className="orderPreview__detailsTariffImage"
                                                src={`${process.env.REACT_APP_STATIC}/${tariffInfo.icon}`}
                                            />
                                            <div className="orderPreview__detailsTariffInfo">
                                                {tariffInfo.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="orderPreview__detailsBlock _col _crew">
                                        {this.renderCrew()}
                                    </div>
                                    <div className="orderPreview__detailsBlock _col _responsible">
                                        {this.getPermissions({
                                            key: 'orders',
                                            items: [{ key: 'main', rules: ['create'] }],
                                        }) && (
                                            <div
                                                className="orderPreview__copy _col _click"
                                                onClick={this.orderCopy}
                                            >
                                                <i className="orderPreview__copyIcon">
                                                    <Icon name="copy" />
                                                </i>
                                            </div>
                                        )}

                                        {this.renderResponsible()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="orderPreview__inner _col">
                        <div className="orderPreview__head _row">
                            <p className="orderPreview__city">{city || '–'}</p>
                            <div className="orderPreview__date">
                                {getFormatedDate({ date: new Date(dateOfOrder), type: 'full' })}
                            </div>
                        </div>
                        <div className="orderPreview__info _row">
                            <div className="orderPreview__number">
                                Заказ
                                <br />№{number}
                            </div>
                            {this.renderDetailsBtn()}
                        </div>
                        <div className="orderPreview__addresses _col">
                            <div className="orderPreview__address">
                                <div className="orderPreview__addressInner">
                                    <span>От:</span> {firstPoint.address}
                                </div>
                            </div>
                            <div className={`orderPreview__address ${isMore ? '_more' : ''}`}>
                                <div className="orderPreview__addressInner">
                                    <span>До:</span> {lastPoint.address}
                                </div>
                                {isMore && (
                                    <div className="orderPreview__addressMore">
                                        Ещё {route.length - 2}
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.renderResponsible()}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(OrderPreview);

OrderPreview.propTypes = {
    user: PropTypes.object,
    _id: PropTypes.string,
    number: PropTypes.number,
    dateOfOrder: PropTypes.string,
    route: PropTypes.array,
    parent: PropTypes.object,
    idOfResponsible: PropTypes.string,
    infoResponsible: PropTypes.object,
    setScrollPosition: PropTypes.func,
    typePage: PropTypes.string,
    status: PropTypes.string,
    holderData: PropTypes.object,
    crewTemplate: PropTypes.array,
    crew: PropTypes.array,
    crewInfo: PropTypes.array,
    carInfo: PropTypes.object,
    parentScroll: PropTypes.object,
    setParamsCrew: PropTypes.func,
    paramCrew: PropTypes.object,
    setStateLoaderOrder: PropTypes.func,
    orderLoaderStack: PropTypes.object,
    systemType: PropTypes.string,
    amount: PropTypes.number,
    type: PropTypes.string,
    tariffInfo: PropTypes.object,
    serviceCompany: PropTypes.object,
    checkRights: PropTypes.func,
    city: PropTypes.string,
};
