import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './profile/Main.jsx';

import Pages from '../../components/Pages.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

import getRealParams from '../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../functions/removeTransition.ts';

import Inner from '../../components/crm/manual/Inner.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import Back from '../../components/crm/widget/Back.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import HeadInner from './profile/HeadInner.jsx';

import getUsers from '../../requests/getUsers';
import getUserInfo from '../../functions/getUserInfo';

import Proxy from './joins/contracts/Main.jsx';
import setContractsParent from '../../functions/crm/setContractsParent';
import Windows from '../../components/Windows.jsx';

class Profile extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getUser = this.getUser.bind(this);
        this.setUser = this.setUser.bind(this);
        this.changeUser = this.changeUser.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);

        setContractsParent.call(this);

        this.parent = React.createRef();
    }

    targetName = 'user';

    targetNameSave = 'userSave';

    headPages = {
        'profile-main': {
            render() {
                const { user } = this.state;
                const name = user ? getUserInfo({ type: 'doubleName', user }) : '';

                return (
                    <div className="widget__headNameItemContent">
                        <AnimateChange
                            prop={name}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {name}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'profile-proxy': {
            render() {
                return <>Доверенности</>;
            },
        },
    };

    pages = {
        'profile-main': {
            render() {
                const { user, userSave, isInit } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector('.widget__page._deep1._profile-main')
                                            ?.querySelector('.widget__pageBox'))()}
                                    setHeightPage={this.setHeightPage}
                                    user={user}
                                    userSave={userSave}
                                    isInit={isInit}
                                    changeUser={this.changeUser}
                                    getUser={this.getUser}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'profile-proxy': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Proxy
                                    getParent={() => this.parent.current}
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    type="userProxy"
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._show .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    changeUser({ block, action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change' || !action) {
                this.setState((state) => {
                    const newState = { ...state };
                    const user = JSON.parse(JSON.stringify(newState.user));
                    const target = block ? user[block] : user;

                    target[name] = value;

                    newState.user = user;

                    return newState;
                }, resolve);
            }
        });
    }

    setUser({ user }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.user = user;
                    newState.userSave = JSON.parse(JSON.stringify(user));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    resolve();
                },
            );
        });
    }

    updateUser({ fields }) {
        return new Promise((resolve) => {
            this.setUser({ user: { ...this.state.user, ...fields } }, resolve);
        });
    }

    getUser(isForce = false) {
        return new Promise((resolve) => {
            getUsers({ params: [{ key: 'my', value: true }] }).then(
                ({ user }) => {
                    setTimeout(
                        () => {
                            this.setUser({ user }, resolve);
                        },
                        isForce ? 0 : 300,
                    );
                },
                () => null,
            );
        });
    }

    handlerSocket({ detail }) {
        const { organization } = this.state;
        const { name, data } = detail;

        if (organization) {
            const { type, fields } = data;

            if (type === 'changeInfo') {
                if (name === 'company' && organization._id === data.idOfCompany) {
                    this.updateUser({ fields });
                }
                if (name === 'client' && organization._id === data.idOfUser) {
                    this.updateUser({ fields });
                }
            }
        }
    }

    componentDidMount() {
        this.getUser();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);
    }

    render() {
        const { isInit } = this.state;
        let { heightPage = 0 } = this.state;
        const { counterChangePage } = this.props;

        if (!heightPage || !isInit) {
            heightPage = 500;
        }

        return (
            <div ref={this.parent} className={`widget _fix _parent ${isInit ? '_ready' : ''}`}>
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="contractActions" renderContent={this.renderContractActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Профиль"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'profile' && page.level === 1
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <HeadInner />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep1"
                                filter={(page) => page.parentName === 'profile' && page.level === 1}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        serverData: state.serverData,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    user: PropTypes.object,
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    serverData: PropTypes.object,
    counterChangePage: PropTypes.number,
};
