import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';

class UploadOrdersPopupStepCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getFiles() {
        const { file } = this.props;

        return [{ name: file?.name }];
    }

    renderFile({ item }) {
        const { name } = item;

        return (
            <div className="uploadOrdersPopupUpload__contentFile _row">
                <i className="uploadOrdersPopupUpload__contentFileIcon">
                    <Icon name="file-type-doc" />
                </i>
                <div className="uploadOrdersPopupUpload__contentFileName">{name}</div>
            </div>
        );
    }

    getResultError(errors) {
        let resultErrors = [];

        errors.forEach((error) => {
            if (error === 'tariff') {
                resultErrors.push('некорректный id тарифа');
            }

            if (error === 'service') {
                resultErrors.push('некорректный сервис');
            }

            if (error === 'client') {
                resultErrors.push('некорректный id клиента');
            }

            if (error === 'date') {
                resultErrors.push('некорректная дата');
            }

            if (error === 'date-date') {
                resultErrors.push('дата должна быть заполнена в формате dd.mm.yyyy');
            }

            if (error === 'date-time') {
                resultErrors.push('время должно быть заполнено в формате hh:mm');
            }

            if (error === 'date-date-day') {
                resultErrors.push('некорректное значение дня в дате');
            }

            if (error === 'date-date-month') {
                resultErrors.push('некорректное значение месяца в дате');
            }

            if (error === 'date-date-year') {
                resultErrors.push('некорректное значение года в дате');
            }

            if (error === 'date-time-hours') {
                resultErrors.push('некорректное значение часа во времени');
            }

            if (error === 'date-time-minutes') {
                resultErrors.push('некорректное значение минут во времени');
            }

            if (error === 'workers') {
                resultErrors.push('некорректное значение услуги грузчиков');
            }

            if (error === 'workers-max') {
                resultErrors.push('кол-во грузчиков не может превышать 2-х');
            }

            if (error.indexOf('address') !== -1) {
                const errorSplit = error.split('-');
                const number = +errorSplit[1];
                const type = errorSplit[2];

                if (type === 'limit') {
                    resultErrors.push(`лишний ${number + 1} адрес`);
                } else {
                    resultErrors.push(`некорректный ${number + 1} адрес`);
                }
            }

            if (error.indexOf('contact') !== -1) {
                const errorSplit = error.split('-');
                const number = +errorSplit[1];
                const type = errorSplit[2];
                const type2 = errorSplit[3];

                if (!type) {
                    resultErrors.push(`некорректный контакт ${number + 1} адреса`);
                } else if (type === 'limit') {
                    resultErrors.push(`лишний ${number + 1} контакт`);
                } else if (type === 'name') {
                    resultErrors.push(`некорректное имя контакта ${number + 1} адреса`);
                } else if (type === 'phone') {
                    if (type2 === 'validate') {
                        resultErrors.push(
                            `телефон контакта ${
                                number + 1
                            } адреса должен быть заполнен в формате 8-999-123-45-67`,
                        );
                    } else {
                        resultErrors.push(`некорректный телефон контакта ${number + 1} адреса`);
                    }
                }
            }

            if (error === 'maxlen') {
                resultErrors.push(
                    'Лишние строки. <br/>В документе не может быть больше 50 заказов',
                );
            }
        });

        resultErrors = resultErrors.map((error, key) =>
            key === 0 ? `${error[0].toUpperCase()}${error.slice(1)}` : error,
        );

        return resultErrors.join(', ');
    }

    render() {
        const { uploadFile, setStep, loadingKey, errors = [], uploadCorrectDoc } = this.props;
        const isProccess = loadingKey === 'check';

        return (
            <>
                <div className="uploadOrdersPopupUpload__contentFiles">
                    <ListAbsoluteMain
                        className="uploadOrdersPopupUpload__contentFilesInner"
                        items={this.getFiles()}
                        renderItem={this.renderFile}
                        classNameItem="uploadOrdersPopupUpload__contentFile"
                        prop="name"
                        isNotParams={true}
                        isNotParamsItem={true}
                    />
                    <Animate
                        className="uploadOrdersPopupUpload__contentFilesDelete"
                        isShow={!isProccess}
                        onClick={() => {
                            setStep('main');
                        }}
                    >
                        <div className="action _col _click _delete">
                            <i className="action__icon">
                                <Icon name="delete" />
                            </i>
                        </div>
                    </Animate>
                </div>
                <div className="uploadOrdersPopupUpload__contentInfo">
                    <Animate
                        className="uploadOrdersPopupUpload__contentInfoLoader _loader"
                        isShow={isProccess}
                    >
                        <i className="uploadOrdersPopupUpload__contentInfoLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="uploadOrdersPopupUpload__contentInfoWrapper">
                        <div className="uploadOrdersPopupUpload__contentInfoHead _row">
                            <div className="uploadOrdersPopupUpload__contentInfoHeadIcon">
                                <Icon name="upload-order-info" />
                            </div>
                            Исправьте ошибки и загрузите документ снова
                        </div>
                        <div className="uploadOrdersPopupUpload__contentInfoInner">
                            <div className="uploadOrdersPopupUpload__contentInfoRow _row _head">
                                <div className="uploadOrdersPopupUpload__contentInfoCol">
                                    <b>Ошибки в документе:</b> {errors.length}
                                </div>
                            </div>
                            {errors.map((error) => {
                                const { number, errors: errorItems, start, end } = error;

                                return (
                                    <div
                                        className="uploadOrdersPopupUpload__contentInfoRow _row"
                                        key={number}
                                    >
                                        <div className="uploadOrdersPopupUpload__contentInfoCol _counter">
                                            Строка{' '}
                                            {number === 'max' ? (
                                                <>
                                                    {end > start ? (
                                                        <>
                                                            <br />
                                                            {start} — {end}
                                                        </>
                                                    ) : (
                                                        <>{start}</>
                                                    )}
                                                </>
                                            ) : (
                                                <>{number + 1}</>
                                            )}
                                        </div>
                                        <div
                                            className="uploadOrdersPopupUpload__contentInfoCol _content"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    this.getResultError(errorItems),
                                                ),
                                            }}
                                        ></div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="uploadOrdersPopupUpload__contentButtons _row">
                    <div className="uploadOrdersPopupUpload__contentButton _downloadCheck">
                        <div className="uploadOrdersPopup__button _row _click" onClick={uploadCorrectDoc}>
                            <Animate
                                className="uploadOrdersPopup__buttonLoader _loader"
                                isShow={loadingKey === 'download-correct'}
                            >
                                <div className="uploadOrdersPopup__buttonLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            Скачать документ с отметками
                            <i className="uploadOrdersPopup__buttonIcon _info">
                                <Icon name="info" />
                            </i>
                        </div>
                    </div>
                    <div className="uploadOrdersPopupUpload__contentButton _upload">
                        <label className="uploadOrdersPopup__button _row _main">
                            Загрузить новый
                            <input
                                type="file"
                                className="uploadOrdersPopup__buttonInput"
                                onChange={({ target }) => {
                                    uploadFile({ target });
                                }}
                                disabled={!!loadingKey}
                            />
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(UploadOrdersPopupStepCheck);

UploadOrdersPopupStepCheck.propTypes = {
    setType: PropTypes.func,
    setStep: PropTypes.func,
    type: PropTypes.string,
    file: PropTypes.object,
    uploadFile: PropTypes.func,
    loadingKey: PropTypes.string,
    errors: PropTypes.array,
    uploadCorrectDoc: PropTypes.func,
};
