import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import getPays from '../../../../requests/getPays';
import Pages from '../../../../components/Pages.jsx';
import Logs from './inner/Logs.jsx';
import Main from './inner/Main.jsx';
import removeTransition from '../../../../functions/removeTransition.ts';
import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';

import checkPaysErrors from '../../../../functions/crm/checkPaysErrors';

class PaysGroupsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.getPay = this.getPay.bind(this);
        this.setPayGroup = this.setPayGroup.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    targetName = 'pay';

    targetNameSave = 'paySave';

    pages = {
        'pays-groups-inner-main': {
            render() {
                const { pay, paySave, isInit } = this.state;
                const { getParent, initCallbackFilter, checkRights } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    pay={pay}
                                    paySave={paySave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    backToSave={this.backToSave}
                                    getParent={getParent}
                                    initCallbackFilter={initCallbackFilter}
                                    getPay={this.getPay}
                                    checkRights={checkRights}
                                    setPayGroup={this.setPayGroup}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'pays-groups-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, pay } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    pay={pay}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    setPayGroup({ payGroup }) {
        this.setState({ pay: payGroup });
    }

    setPay({ pay }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState({ pay, paySave: JSON.parse(JSON.stringify(pay)) }, () => {
                setInfoHead({ key: 'pay', value: pay });

                setTimeout(() => {
                    this.setState({ isInit: true });
                }, 100);

                resolve();
            });
        });
    }

    getPay() {
        const { levels } = this.props;
        const id = levels[3];

        this.setState({ isInit: false }, () => {
            getPays({ id }).then(({ pay }) => {
                setTimeout(() => {
                    this.setPay({ pay });
                }, 300);
            });
        });
    }

    updatePay({ fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const pay = JSON.parse(JSON.stringify(newState.pay));
            const deletedPays =
                fields.deletedPays && fields.deletedPays.filter((item) => item.groupId === pay._id);

            if (deletedPays) {
                pay.counter -= deletedPays.length;
                pay.errorCounter -= deletedPays.filter((item) =>
                    checkPaysErrors({ pay: item }),
                ).length;
                pay.amount -= deletedPays.reduce((prev, cur) => prev + cur.amount, 0);
            } else if (fields.item?.groupId === pay._id) {
                const { item } = fields;

                if (item.isDelete) {
                    pay.counter -= 1;

                    if (checkPaysErrors({ pay: item })) {
                        pay.errorCounter -= 1;
                    }
                } else {
                    if (item.status === 'completed') {
                        pay.completedCounter += 1;

                        if (item.commission) {
                            pay.commission += item.commission;
                        }
                    }

                    if (item.isNew) {
                        pay.counter += 1;
                        pay.amount += item.amount;
                    }

                    if (checkPaysErrors({ pay: item }) && !item.isNotAddError) {
                        pay.errorCounter += 1;
                    }

                    if (item.isRemoveError) {
                        pay.errorCounter -= 1;
                    }
                }
            }

            newState.pay = pay;

            return newState;
        });
    }

    handlerSocket({ detail }) {
        const { pay } = this.state;
        const { name, data } = detail;

        if (name === 'pays' && (pay?._id === data?.id || data.fields?.deletedPays)) {
            const { fields } = data;

            this.updatePay({ fields });
        }
    }

    componentDidMount() {
        this.getPay();

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        <Pages
                            classNamePage="widget__page _deep2"
                            getClassName={(pageName) =>
                                ['pays-groups-inner-logs'].includes(pageName) ? '_full' : ''
                            }
                            filter={(page) => page.parentName === 'pays-groups-inner'}
                            pages={this.pages}
                            context={this}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(PaysGroupsInner);

PaysGroupsInner.propTypes = {
    getParent: PropTypes.func,
    initCallbackFilter: PropTypes.func,
    levels: PropTypes.array,
    checkRights: PropTypes.func,
};
