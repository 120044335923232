import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import Main from './modelsOfCar/Main.jsx';
import HeadInner from './modelsOfCar/HeadInner.jsx';
import Inner from './modelsOfCar/Inner.jsx';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';
import getHeaders from '../../../functions/getHeaders';
import setPermissions from '../../../functions/crm/setPermissions';
import getPageLink from '../../../functions/getPageLink';

class ManualModelsOfCar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.delete = this.delete.bind(this);
        this.renderWindowsPromptDelete = this.renderWindowsPromptDelete.bind(this);
        this.renderWindowsDeleteInfo = this.renderWindowsDeleteInfo.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    headPages = {
        'manual-modelsOfCar-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Модели машин{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'manual-modelsOfCar-inner': {
            render() {
                const { infoHead } = this.state;
                const { modelOfCar = {} } = infoHead;
                const { id, isNew } = modelOfCar;

                return (
                    <div className="widget__headNameItemContent">
                        Модель
                        <AnimateChange
                            prop={id}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {id ? `ID${id}` : isNew ? ' новый' : '–'}
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfOrganization = levels[3];

        if (levels[1] === 'manual' && levels[2] === 'modelsOfCar' && idOfOrganization !== 'main') {
            this.isNew = idOfOrganization === 'new';
        }

        return this.isNew;
    }

    headActions = {
        'manual-modelsOfCar-main': {
            render() {
                const { filter } = this.state;

                return (
                    <TableHead
                        name="modelsOfCar"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        checkRights={() =>
                            this.getPermissions({
                                key: 'manual',
                                items: [
                                    {
                                        key: 'modelsOfCar',
                                        rules: ['create'],
                                    },
                                ],
                            })
                        }
                    />
                );
            },
        },
        'manual-modelsOfCar-inner': {
            render() {
                const { infoHead } = this.state;
                const { organization } = infoHead;

                return (
                    <HeadInner
                        typeOrganization={organization?.typeOrganization}
                        isNew={this.checkNew()}
                        delete={this.delete}
                    />
                );
            },
        },
    };

    pages = {
        'manual-modelsOfCar-main': {
            render() {
                const { filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-modelsOfCar-inner': {
            render() {
                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner _notPadding">
                            <Inner
                                setHeightPage={this.setHeightPage}
                                setInfoHead={this.setInfoHead}
                                getParent={() => this.parent.current}
                                checkNew={this.checkNew}
                            />
                        </div>
                    </div>
                );
            },
        },
    };

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'modelsOfCar' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { modelOfCar } = infoHead;
        const id = modelOfCar?._id;
        const windowId = `delete-modelOfCar-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/modelOfCar?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;
                            const { message } = data;

                            const windowName =
                                message === 'You dont delete this model'
                                    ? 'deleteModelOfCarInfo'
                                    : 'promptDelete';

                            if (!isForce) {
                                if (message === 'You dont delete this model' || success) {
                                    handlerWindow({
                                        parent: this.parent.current,
                                        parentResize: this.parent.current,
                                        target: this.parent.current.querySelector(
                                            '.widget__headLink._delete',
                                        ),
                                        action: 'show',
                                        name: windowName,
                                        className: '_prompt _center',
                                        uniqKey: windowId,
                                        watchesProps: { left: true },
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });
                            }

                            if (success) {
                                if (isForce) {
                                    setNotification({ notification: 'success-change-info' });
                                }

                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            changePage({
                                href: getPageLink({
                                    name: 'manual-modelsOfCar-main',
                                }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    renderWindowsDeleteInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteModelOfCarInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: 'filter-modelsOfCar', filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="promptDelete" renderContent={this.renderWindowsPromptDelete} />
                <Windows name="deleteModelOfCarInfo" renderContent={this.renderWindowsDeleteInfo} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Справочник"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-modelsOfCar' && page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-modelsOfCar' && page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === 'manual-modelsOfCar' && page.level === 3
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualModelsOfCar);

ManualModelsOfCar.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    windows: PropTypes.object,
};
