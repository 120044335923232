import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import getHeaders from '../../../../../functions/getHeaders';

import Edit from '../../../../../components/Edit.jsx';
import Button from '../../../../../components/Button.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Link from '../../../../../components/Link.jsx';

import Main from '../../../../../components/crm/manual/tag/Main.jsx';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import setNotification from '../../../../../functions/setNotification';
import changePage from '../../../../../functions/changePage';
import setAnimate from '../../../../../functions/setAnimate';

import scrollToPosition from '../../../../../functions/scrollToPosition';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import getPageLink from '../../../../../functions/getPageLink';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualTagInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.checkEditmode = this.checkEditmode.bind(this);

        this.changeTag = this.changeTag.bind(this);
        this.createTag = this.createTag.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);

        this.parent = React.createRef();
    }

    name = 'tag';

    orderCards = ['main'];

    cards = {
        main: {
            changedProps: [{ name: 'name', validate: false }, 'idOfGroup', 'color'],
            requiredProps: [{ name: 'name', validate: false }, 'idOfGroup', 'color'],
            render() {
                const { modelsOfCar, marksOfCar, errors } = this.state;
                const { tag, isNew, getParent } = this.props;

                return (
                    <Main
                        title={blocksManual.tag.main.title}
                        tag={tag}
                        checkEditmode={() => this.checkEditmode('main')}
                        isNew={isNew}
                        getParent={getParent}
                        changeTag={({ ...props }) => this.changeTag({ block: 'main', ...props })}
                        modelsOfCar={modelsOfCar}
                        marksOfCar={marksOfCar}
                        errors={errors.main}
                    />
                );
            },
        },
    };

    changeTag({ block, ...props }) {
        const { changeTag } = this.props;

        return new Promise((resolve) => {
            changeTag({ ...props }).then(() => {
                if (props.action === 'change' || !props.action) {
                    this.handlerErrors({ action: 'delete', error: props.name, block });
                }

                resolve();
            });
        });
    }

    checkChangeProp({ value, valueWas }) {
        return value !== valueWas;
    }

    deepCards = [];

    checkChange(name) {
        const { tag, tagSave } = this.props;
        const { changedProps } = this.cards[name];
        let isChange = false;
        const fields = {};

        changedProps.forEach((propRes) => {
            const prop = typeof propRes === 'object' ? propRes.name : propRes;
            const target = this.deepCards.indexOf(name) === -1 ? tag : tag[name];
            const targetWas = this.deepCards.indexOf(name) === -1 ? tagSave : tagSave[name];

            if (this.checkChangeProp({ prop, value: target[prop], valueWas: targetWas[prop] })) {
                isChange = true;
                fields[prop] = target[prop];
            }
        });

        if (tag.newGroup) {
            fields.newGroup = tag.newGroup;
            fields.color = tag.color;

            isChange = true;
        }

        return { isChange, fields };
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualContent__card[data-id="${id}"]`,
            });
        }
    }

    handlerReqErrors(errors = []) {
        errors.forEach((error) => {
            if (error.message === 'Group already create') {
                setNotification({
                    notification: 'tagGroup-already-reg',
                });

                this.handlerErrors({
                    action: 'add',
                    error: 'newGroup',
                    block: 'main',
                });
            }

            if (error.message === 'Tag already create') {
                setNotification({
                    notification: 'tag-already-reg',
                });

                this.handlerErrors({
                    action: 'add',
                    error: 'name',
                    block: 'main',
                });
            }
        });
    }

    checkNewGroup({ errors }) {
        const { tag } = this.props;
        let isSuccess = false;

        if (tag.newGroup && errors.main.indexOf('idOfGroup') !== -1) {
            const index = errors.main.indexOf('idOfGroup');

            errors.main.splice(index, 1);
        }

        if (tag.idOfGroup && errors.main.indexOf('color') !== -1) {
            const index = errors.main.indexOf('color');

            errors.main.splice(index, 1);
        }

        if (errors.main.length === 0) {
            isSuccess = true;
        }

        return isSuccess;
    }

    handlerEditmode({ editName }) {
        const { levels } = this.props;
        const id = levels[3];

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const { requiredProps } = this.cards[this.state.editName];

                    const validateResult = this.validate({
                        props: requiredProps,
                        nameProp: this.state.editName,
                        isShowNotification: false,
                    });

                    const { errors } = validateResult;
                    let { isSuccess } = validateResult;

                    if (this.checkNewGroup({ errors })) {
                        isSuccess = true;
                    }

                    if (!isChange) {
                        resolve();
                    } else if (!isSuccess) {
                        this.handlerErrors({ action: 'set', errors });
                    } else {
                        this.handlerLoadingBlock(this.state.editName).then(() => {
                            axios
                                .patch(
                                    `${process.env.REACT_APP_API}/tag`,
                                    {
                                        id,
                                        fields,
                                    },
                                    {
                                        headers: getHeaders(),
                                    },
                                )
                                .then(
                                    (res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'success-update-tag',
                                            });

                                            resolve();
                                        } else {
                                            const { errors: resErrors } = data;

                                            this.handlerReqErrors(resErrors);

                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoadingBlock(null);
                                    },
                                    () => null,
                                );
                        });
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    createTag() {
        const { tag, getTag } = this.props;
        const validateResult = this.validate({ isShowNotification: false });
        const { errors } = validateResult;
        let { isSuccess } = validateResult;

        if (this.checkNewGroup({ errors })) {
            isSuccess = true;
        }

        if (!isSuccess) {
            setNotification({ notification: 'required-fields-not-complete' });

            this.handlerErrors({ action: 'set', errors });
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API}/tag`,
                    { fields: tag },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { id } = data;

                            changePage({
                                href: getPageLink({ name: 'manual-tags-inner', ids: { 3: id } }),
                            });

                            setNotification({ notification: 'success-create-tag' });

                            const parentScroll = document.querySelector(
                                '.widget__page._manual-tags-inner-main .widget__pageBox',
                            );
                            const { scrollTop: scrollParent } = parentScroll;

                            setAnimate({
                                draw: (progress) => {
                                    parentScroll.scrollTo({
                                        top: scrollParent - progress * scrollParent,
                                    });
                                },
                                duration: 300,
                            });

                            getTag();
                        } else {
                            const { errors: resErrors } = data;

                            this.handlerReqErrors(resErrors);

                            handlerErrorRequest(res);
                        }
                    },
                    () => null,
                );
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.editmode = new Editmode({
            context: this,
        });
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        if (backToSave) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave, loadingKey } = this.state;
        const { isNew, setHeightPage, checkRights } = this.props;

        return (
            <div ref={this.parent} className={`manualContent _parentForEdits _tag ${editName ? '_edit' : ''}`}>
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div className="manualContent__cards _row">
                            {this.props.tag &&
                                this.orderCards.map((name, key) => {
                                    const card = this.cards[name];
                                    const zIndex = this.orderCards.length - key + 10;

                                    return (
                                        <div
                                            className={`manualContent__card _parentForEdit _editBack _${name} ${
                                                name === editName || isNew ? '_current' : ''
                                            } _show`}
                                            key={name}
                                            data-id={name}
                                            style={{ zIndex }}
                                        >
                                            {!isNew && checkRights() && (
                                                <Edit
                                                    name={name}
                                                    className="manualContent__cardEdit"
                                                    editName={editName}
                                                    handlerEditmode={this.handlerEditmode}
                                                    isLoader={loadingKey === name}
                                                />
                                            )}

                                            {card.render.call(this)}
                                        </div>
                                    );
                                })}
                        </div>
                        <Animate
                            className="manualContent__actions"
                            isShow={isNew}
                            actionInit={() => {
                                setHeightPage();
                            }}
                            actionPrevRemove={() => {
                                setHeightPage();
                            }}
                        >
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="manual-tags-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <div className="manualContent__actionsButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.createTag}
                                        showLoader={isLoadingSave}
                                    >
                                        Сохранить данные
                                    </Button>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ManualTagInnerMain);

ManualTagInnerMain.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    tag: PropTypes.object,
    tagSave: PropTypes.object,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataFiles: PropTypes.object,
    filesDelete: PropTypes.array,
    clearFiles: PropTypes.func,
    isInit: PropTypes.bool,
    backToSave: PropTypes.func,
    isNew: PropTypes.bool,
    gettag: PropTypes.func,
    getParent: PropTypes.func,
    changeTag: PropTypes.func,
    handlerServices: PropTypes.func,
    checkRights: PropTypes.func,
};
