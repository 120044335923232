import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../../Icon.jsx';
import Field from '../../../../Field.jsx';

class ParamsPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    orderCards = ['pallets', 'tonnage', 'volume'];

    cards = {
        pallets: {
            icon: 'pallets',
            support: 'Паллеты',
            keyName: 'numberCounter',
        },
        tonnage: {
            icon: 'tonnage',
            support: 'Тоннаж',
            keyName: 'numberWeight',
        },
        volume: {
            icon: 'volume',
            support: 'Объём',
            keyName: 'numberVolume',
        },
    };

    render() {
        const { car, isDisabled, handler, errors } = this.props;
        const isReadOnly = car.type === 'car' && car.bodywork === 'tyagch_pricep';

        return (
            <div className={`manualCardParamsPreview _row ${!isDisabled && !isReadOnly ? '_edit' : ''}`}>
                <div className="manualCardParamsPreview__cards _row">
                    {this.orderCards.map((name) => {
                        const card = this.cards[name];
                        const { icon, support, keyName } = card;
                        let value = car[name];
                        const isError = errors.indexOf(name) !== -1;

                        if (name === 'volume') {
                            value = +((car.long * car.width * car.height) / 1_000_000).toFixed(1);
                        }

                        return (
                            <div
                                className={`manualCardParamsPreview__card _col ${
                                    isError ? '_error' : ''
                                }`}
                                key={name}
                            >
                                <i className="manualCardParamsPreview__cardIcon">
                                    <Icon name={icon} />
                                </i>
                                <div className="manualCardParamsPreview__cardValue">
                                    <Field
                                        keyName={keyName}
                                        name={name}
                                        value={value}
                                        callback={handler}
                                        className={`_manualMain _bigSize _fontMedium _whiteBack ${
                                            isDisabled ? '_notBack' : ''
                                        } _center`}
                                        isDisabled={isDisabled || isReadOnly}
                                        isEditmode={true}
                                        error={isError}
                                        isReadOnly={isReadOnly}
                                    />
                                </div>
                                <p className="manualCardParamsPreview__cardSupport">{support}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ParamsPreview);

ParamsPreview.propTypes = {
    car: PropTypes.object,
    isDisabled: PropTypes.bool,
    handler: PropTypes.func,
    errors: PropTypes.array,
};
