import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Docs from '../../../../../components/crm/manual/Docs.jsx';

class ManualCarsInnerDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    checkInit() {
        const { isInit, setHeightPage } = this.props;

        if (!this.isInit && isInit) {
            this.isInit = true;

            setHeightPage();
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    render() {
        const {
            isInit,
            car = {},
            carSave = {},
            formDataDocs,
            changeDoc,
            uploadFile,
            deleteFile,
            filesDocsDelete,
            setHeightPage,
            addDoc,
            deleteDoc,
            getParent,
            handlerError,
            backToSaveDocs,
            clearFormData,
            parentScroll,
            checkRights,
        } = this.props;
        const { docs = [] } = car;
        const { docs: docsSave = [] } = carSave;

        return (
            <div className="manualContent _executor _isInit">
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        {this.props.car && (
                            <Docs
                                type="car"
                                isInit={isInit}
                                docs={docs}
                                docsSave={docsSave}
                                formDataDocs={formDataDocs}
                                changeDoc={changeDoc}
                                uploadFile={uploadFile}
                                deleteFile={deleteFile}
                                filesDocsDelete={filesDocsDelete}
                                setHeightPage={setHeightPage}
                                addDoc={addDoc}
                                deleteDoc={deleteDoc}
                                getParent={getParent}
                                handlerError={handlerError}
                                backToSaveDocs={backToSaveDocs}
                                clearFormData={clearFormData}
                                parentScroll={parentScroll}
                                checkRights={checkRights}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualCarsInnerDocs);

ManualCarsInnerDocs.propTypes = {
    isInit: PropTypes.bool,
    car: PropTypes.object,
    carSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    changeDoc: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataDocs: PropTypes.object,
    filesDocsDelete: PropTypes.array,
    addDoc: PropTypes.func,
    deleteDoc: PropTypes.func,
    getParent: PropTypes.func,
    handlerError: PropTypes.func,
    backToSaveDocs: PropTypes.func,
    clearFormData: PropTypes.func,
    parentScroll: PropTypes.object,
    checkRights: PropTypes.func,
};
