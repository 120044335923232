import axios from 'axios';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getModel({ name, type, len }) {
    let query = `name=${name}`;

    if (type) {
        query += `&type=${type}`;
    }

    if (len) {
        query += `&len=${len}`;
    }

    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API}/model?${query}`).then((res) => {
            if (res?.data?.data && typeof res?.data?.data === 'object') {
                const { success, data } = res.data;
                const { model, models } = data;

                if (success) {
                    resolve({ model, models });
                } else {
                    handlerErrorRequest(res);

                    reject();
                }
            } else {
                reject();
            }
        }, reject);
    });
}
