import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getCorporation({ id, params = [] }) {
    let query = ``;

    if (id) {
        query += `id=${id}`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/corporation?${query}`, { headers: getHeaders() })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;
                    const { corporation, corporations, isLimit, counter } = data;

                    if (success) {
                        resolve({ corporation, corporations, isLimit, counter });
                    } else {
                        handlerErrorRequest(res);

                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
