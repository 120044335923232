import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';
import Select from '../Select.jsx';
import Field from '../Field.jsx';
import PointCargoItem from './PointCargoItem.jsx';
import Animate from '../Animate.jsx';

import handlerPopup from '../../functions/handlerPopup';

class PointCargo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
        };

        this.handlerSearch = this.handlerSearch.bind(this);
        this.setHeight = this.setHeight.bind(this);
        this.handlerPhoto = this.handlerPhoto.bind(this);
        this.addItems = this.addItems.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.filterCargoItem = this.filterCargoItem.bind(this);
        this.showComments = this.showComments.bind(this);
        this.setComments = this.setComments.bind(this);
        this.handlerPopupCargo = this.handlerPopupCargo.bind(this);

        this.parent = React.createRef();
    }

    offsetItem = 6;

    order = ['type', 'number', 'counterPlace'];

    fields = {
        type: {
            key: 'type',
            type: 'select',
        },
        number: {
            key: 'numberOfCargo',
            type: 'input',
        },
        counterPlace: {
            key: 'counterPlaceInCargo',
            type: 'input',
        },
    };

    renderHeadFields(name) {
        const { cargo, handler, numberOfPoint, number, isDisabled, isEditmode } = this.props;
        const { type, key } = this.fields[name];
        const value = cargo?.[name];
        let result;

        switch (type) {
            case 'select':
                result = (
                    <Select
                        type="orderPointTypes"
                        name={name}
                        value={value}
                        callback={handler}
                        isDisabled={isDisabled}
                        isEditmode={isEditmode}
                    />
                );

                break;

            default:
                result = (
                    <Field
                        keyName={key}
                        name={name}
                        value={value}
                        callback={handler}
                        group={`point-cargo-${numberOfPoint}-${number}`}
                        className={`_orderPoint _full`}
                        isDisabled={isDisabled}
                        isEditmode={isEditmode}
                        setStateComplete={true}
                    />
                );
                break;
        }

        return (
            <div className={`orderPointCargo__headField _${name}`} key={name}>
                {result}
            </div>
        );
    }

    renderComments() {
        const { cargo, handler, numberOfPoint, number, isDisabled, isEditmode } = this.props;
        const { comment } = cargo;

        return (
            <div className="orderPointCargo__commentsInput">
                <Field
                    keyName="commentOfPoint"
                    name="comment"
                    value={comment}
                    callback={handler}
                    group={`point-comments-${numberOfPoint}-${number}`}
                    className={`_orderPoint _full`}
                    isDisabled={isDisabled}
                    isEditmode={isEditmode}
                />
            </div>
        );
    }

    searchSave = '';

    handlerSearch({ action, value: search }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };

                    if (search) {
                        this.searchSave = search;
                    }

                    newState.search = search;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    addItems({ items = null, images = [], itemCome = {} }) {
        const { search } = this.state;
        const { point, handlerRoute, numberOfPoint, number, handlerDrop, formDataImages } =
            this.props;

        images.forEach((image) => {
            formDataImages.set(`${point.uniqKey}-${image.name}`, image.object);
        });

        handlerRoute
            .handlerCargoItem({
                action: 'add',
                keyOfPoint: numberOfPoint,
                keyOfCargo: number,
                handlerDrop,
                nameOfItem: search,
                items,
                images,
                itemCome,
            })
            .then(() => {
                this.handlerSearch({
                    action: 'change',
                    search: '',
                });
                this.setHeight().then(() => {
                    handlerDrop(true);
                });
            });
    }

    deleteImage({ name }) {
        const { point, handlerRoute, numberOfPoint, number, formDataImages } = this.props;

        formDataImages.delete(`${point.uniqKey}-${name}`);

        handlerRoute.handlerCargoItem({
            action: 'deleteImage',
            keyOfPoint: numberOfPoint,
            keyOfCargo: number,
            nameOfImage: name,
        });
    }

    handlerPhoto() {
        const { cargo, handlerDisabledEvent } = this.props;

        if (handlerDisabledEvent) {
            handlerDisabledEvent(true);
        }

        handlerPopup({
            action: 'show',
            name: 'popupOrderCargoImages',
            addItems: this.addItems,
            deleteImage: this.deleteImage,
            images: cargo.images,
            callbackHide: () => {
                if (handlerDisabledEvent) {
                    handlerDisabledEvent(false);
                }
            },
        });
    }

    setHeight() {
        return new Promise((resolve) => {
            const { setHeightCargo } = this.props;
            const head = this.parent.current?.querySelector('.orderPointCargo__head');
            const inner = this.parent.current?.querySelector('.orderPointCargo__itemsInner');
            const comments = this.parent.current?.querySelector('.orderPointCargo__comments');

            if (inner) {
                const { clientHeight: heightHead } = head;
                const { clientHeight: heightInner } = inner;
                const heightComments = (comments && comments.clientHeight) || 0;
                const heigthAll = 12 + heightHead + heightInner + heightComments;

                if (heightInner && heightInner !== this.state.heightInner) {
                    this.setState(
                        (state) => {
                            const newState = { ...state };

                            newState.heightInner = heightInner;
                            newState.heigthAll = heigthAll;

                            return newState;
                        },
                        () => {
                            if (setHeightCargo) {
                                setHeightCargo();
                            }

                            resolve();
                        },
                    );
                }
            } else {
                resolve();
            }
        });
    }

    renderSupport() {
        const { device, number, cargo } = this.props;

        if (device === 'desktop') {
            return `Груз ${number + 1}:`;
        }

        if (cargo.items.length) {
            const volume = +cargo.items
                .reduce((prev, cur) => prev + (cur.long * cur.width * cur.height) / 1000000, 0)
                .toFixed(1);
            const weight = cargo.items.reduce((prev, cur) => prev + +cur.weight, 0);

            return `${cargo.items.length}шт. — ${weight}кг (${volume}м³)`;
        }

        return 'Указать груз';
    }

    renderSupportComments() {
        const { device, cargo } = this.props;

        if (device === 'mobile') {
            if (cargo.comment) {
                return cargo.comment;
            }

            return `Указать комментарий`;
        }

        return `Комментарий:`;
    }

    filterCargoItem({ translation }) {
        // const { search } = this.state;

        return (
            !this.searchSave ||
            translation.toLowerCase().indexOf(this.searchSave.toLowerCase()) !== -1
        );
    }

    setComments({ comments }) {
        const { handlerRoute, number, numberOfPoint, point } = this.props;
        const { cargo } = point;

        if (comments) {
            cargo[number].comment = comments.value;

            handlerRoute.changePoint({ key: numberOfPoint, values: { cargo } });
        }
    }

    showComments() {
        const { number, cargo } = this.props;
        const { comment } = cargo;

        handlerPopup({
            action: 'show',
            name: 'popupCommentsPoint',
            numberOfCargo: number + 1,
            startFields: {
                comments: {
                    value: comment,
                },
            },
            callback: this.setComments,
        });
    }

    handlerPopupCargo({ cargo, formDataImages: formDataImagesResponse }) {
        const { handlerRoute, numberOfPoint, number, formDataImages } = this.props;

        for (const [key, file] of formDataImagesResponse.entries()) {
            formDataImages.set(key, file);
        }

        handlerRoute.handlerCargo({
            action: 'change',
            keyOfPoint: numberOfPoint,
            keyOfCargo: number,
            props: cargo,
        });
    }

    componentDidMount() {
        this.setHeight();

        const observer = new MutationObserver(() => {
            this.setHeight();
        });

        observer.observe(this.parent.current, {
            childList: true,
            subtree: true,
            characterDataOldValue: true,
        });
    }

    componentDidUpdate() {
        this.setHeight();
    }

    render() {
        const { search, heightInner } = this.state;
        let { heigthAll } = this.state;
        const {
            points,
            point,
            number,
            numberOfPoint,
            cargo,
            handlerRoute,
            handlerDrop,
            isDisabled,
            cargoList,
            device,
            type,
            isEditmode,
            isNew,
        } = this.props;
        const searchShow = cargo?.type;
        const heightItem =
            this.parent.current?.querySelector('.orderPointCargo__item')?.clientHeight || 26;
        const heightItemsBox =
            (cargo.items?.filter((item) => !item.isDelete).length &&
                heightItem * cargo.items.filter((item) => !item.isDelete).length +
                    (this.offsetItem * cargo.items.filter((item) => !item.isDelete).length - 1)) ||
            0;
        const condForDelete = point.cargo.filter((item) => !item.isDelete).length > 1;
        const condForList =
            !isDisabled &&
            search &&
            search.length > 2 &&
            cargoList &&
            cargoList.filter(this.filterCargoItem).length > 0;

        if (device === 'mobile' && type === 'popup' && !heigthAll) {
            heigthAll = 36;
        }

        return (
            <div
                ref={this.parent}
                className={`orderPointCargo ${condForDelete ? '_forDelete' : ''} ${
                    (type && `_${type}`) || ''
                } ${cargo?.items.filter((item) => !item.isDelete).length === 0 ? '_empty' : ''}`}
            >
                <div className="orderPointCargo__box" style={{ height: `${heigthAll}px` }}>
                    <div className="orderPointCargo__inner _row">
                        {type !== 'popup' && (
                            <div
                                className={`orderPointCargo__support _row ${
                                    cargo?.items.length > 0 ? '_active' : ''
                                }`}
                                onClick={() =>
                                    handlerPopup({
                                        action: 'show',
                                        name: 'popupCargosPoint',
                                        number,
                                        numberOfPoint,
                                        points,
                                        point,
                                        cargo,
                                        cargoList,
                                        callback: this.handlerPopupCargo,
                                    })
                                }
                            >
                                <i className="orderPointCargo__supportIcon">
                                    <Icon name="order-point-cargo" />
                                </i>
                                {this.renderSupport()}
                                {device === 'mobile' && (
                                    <Animate
                                        className="orderPointCargo__supportEdit"
                                        isShow={cargo?.items.length > 0}
                                    >
                                        <Icon name="edit" />
                                    </Animate>
                                )}
                            </div>
                        )}
                        {(device === 'desktop' || type === 'popup') && (
                            <div className="orderPointCargo__content">
                                <div className="orderPointCargo__head _row">
                                    <div className="orderPointCargo__headFields _row">
                                        {this.order.map((name) => this.renderHeadFields(name))}
                                    </div>
                                    <div className="orderPointCargo__headActions _row">
                                        <Animate
                                            className="orderPointCargo__headPhoto _click"
                                            onClick={this.handlerPhoto}
                                            isShow={searchShow && (type !== 'details' || isNew)}
                                        >
                                            <Animate
                                                className="orderPointCargo__headPhotoElem orderPointCargo__headPhotoBtn _row"
                                                isShow={!cargo.images || cargo.images.length === 0}
                                            >
                                                {type !== 'popup' && <>Определить груз по фото</>}
                                                <i className="orderPointCargo__headPhotoBtnIcon">
                                                    <Icon name="camera-2" />
                                                </i>
                                            </Animate>
                                            <Animate
                                                className="orderPointCargo__headPhotoElem orderPointCargo__headPhotoInfo _row _click"
                                                isShow={cargo.images && cargo.images.length}
                                            >
                                                {type !== 'popup' && <>Фото груза</>}
                                                <div className="orderPointCargo__headPhotoInfoCounter _col">
                                                    {cargo.images?.length}
                                                </div>
                                            </Animate>
                                        </Animate>

                                        <Animate
                                            className="orderPointCargo__headDelete _click"
                                            onClick={() => {
                                                handlerRoute.handlerCargo({
                                                    action: 'delete',
                                                    keyOfPoint: numberOfPoint,
                                                    keyOfCargo: number,
                                                    handlerDrop,
                                                });
                                            }}
                                            isShow={condForDelete}
                                        >
                                            <div className="orderPointCargo__headDeleteInner">
                                                <Icon name="delete-circle" />
                                            </div>
                                        </Animate>
                                    </div>
                                </div>
                                <div
                                    className="orderPointCargo__items"
                                    style={{ height: `${heightInner}px` }}
                                >
                                    <div className="orderPointCargo__itemsInner">
                                        <Animate
                                            className={`orderPointCargo__search ${
                                                isDisabled !== undefined && !isDisabled
                                                    ? '_isEdit'
                                                    : ''
                                            } ${condForList ? '_showList' : ''}`}
                                            isShow={searchShow}
                                        >
                                            <div className="orderPointCargo__searchField">
                                                <Field
                                                    type={
                                                        device === 'mobile'
                                                            ? 'pointCargoMob'
                                                            : 'pointCargo'
                                                    }
                                                    keyName="search"
                                                    name="search"
                                                    value={search}
                                                    callback={this.handlerSearch}
                                                    group={`point-search-${numberOfPoint}-${number}`}
                                                    className={`_orderPoint _full`}
                                                    isDisabled={isDisabled}
                                                    isEditmode={isEditmode}
                                                />
                                                <Animate
                                                    isShow={!isDisabled}
                                                    className={`orderPointCargo__searchAdd _click ${
                                                        isDisabled !== undefined && !isDisabled
                                                            ? '_isEdit'
                                                            : ''
                                                    }`}
                                                    onClick={() => this.addItems({})}
                                                >
                                                    <Icon name="add-circle" />
                                                </Animate>
                                            </div>
                                            <Animate
                                                className="orderPointCargo__searchList"
                                                isShow={condForList}
                                            >
                                                {cargoList &&
                                                    cargoList
                                                        .filter(this.filterCargoItem)
                                                        .map((item) => (
                                                            <div
                                                                className="orderPointCargo__searchListItem _click"
                                                                onClick={() =>
                                                                    this.addItems({
                                                                        itemCome: {
                                                                            ...item,
                                                                            name: item.translation,
                                                                        },
                                                                    })
                                                                }
                                                                key={item._id}
                                                            >
                                                                {item.translation} ({item.long}х
                                                                {item.width}х{item.height}м³)
                                                            </div>
                                                        ))}
                                            </Animate>
                                        </Animate>
                                        <div
                                            className="orderPointCargo__itemsBox"
                                            style={{ height: `${heightItemsBox}px` }}
                                        >
                                            {cargo?.items.map((item, keyItem) => {
                                                const findIndexOfDelete = cargo.items.findIndex(
                                                    (elem) => elem.isDelete,
                                                );
                                                const keyItemUpdate =
                                                    findIndexOfDelete !== -1 &&
                                                    findIndexOfDelete < keyItem
                                                        ? 1
                                                        : 0;

                                                let top =
                                                    (heightItem + this.offsetItem) *
                                                    (keyItem - keyItemUpdate);

                                                if (device === 'mobile') {
                                                    top += 10;
                                                }

                                                return (
                                                    <div
                                                        className={`orderPointCargo__item ${
                                                            item.stateOfShow === 1 ? '_show' : ''
                                                        } ${item.isDelete ? '_delete' : ''}`}
                                                        style={{
                                                            transform: `translate(0px,${top}px)`,
                                                        }}
                                                        key={item.id || item._id}
                                                    >
                                                        <PointCargoItem
                                                            item={item}
                                                            numberOfPoint={numberOfPoint}
                                                            numberOfCargo={number}
                                                            number={keyItem}
                                                            handlerRoute={handlerRoute}
                                                            handlerDrop={handlerDrop}
                                                            isDisabled={isDisabled}
                                                            isEditmode={isEditmode}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {type !== 'popup' && (
                        <div
                            className={`orderPointCargo__comments ${
                                cargo.comment ? '_active' : ''
                            }`}
                        >
                            <div className="orderPointCargo__commentsInner _row">
                                <div
                                    className="orderPointCargo__support _row"
                                    onClick={this.showComments}
                                >
                                    <i className="orderPointCargo__supportIcon">
                                        <Icon name="order-point-comments" />
                                    </i>
                                    {this.renderSupportComments()}
                                    {device === 'mobile' && (
                                        <Animate
                                            className="orderPointCargo__supportEdit"
                                            isShow={!!cargo.comment}
                                        >
                                            <Icon name="edit" />
                                        </Animate>
                                    )}
                                </div>
                                {device === 'desktop' && this.renderComments()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PointCargo);

PointCargo.propTypes = {
    points: PropTypes.array,
    point: PropTypes.object,
    cargo: PropTypes.object,
    handler: PropTypes.func,
    handlerContacts: PropTypes.func,
    numberOfPoint: PropTypes.number,
    number: PropTypes.number,
    setHeightCargo: PropTypes.func,
    handlerRoute: PropTypes.object,
    handlerDrop: PropTypes.func,
    isDisabled: PropTypes.bool,
    cargoList: PropTypes.array,
    device: PropTypes.string,
    formDataImages: PropTypes.object,
    type: PropTypes.string,
    handlerDisabledEvent: PropTypes.func,
    isEditmode: PropTypes.bool,
    isNew: PropTypes.bool,
};
