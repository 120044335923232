import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './inner/Main.jsx';
import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import getJoinTemplates from '../../../../requests/getJoinTemplates';
import setPermissions from '../../../../functions/crm/setPermissions';

class JoinsTemplatesInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getJoinTemplate = this.getJoinTemplate.bind(this);
        this.setJoinTemplate = this.setJoinTemplate.bind(this);
        this.changeJoinTemplate = this.changeJoinTemplate.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'joinTemplate';

    targetNameSave = 'joinTemplateSave';

    pages = {
        'joins-templates-inner-main': {
            render() {
                const { joinTemplate, joinTemplateSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._joins-templates-inner-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    joinTemplate={joinTemplate}
                                    joinTemplateSave={joinTemplateSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeJoinTemplate={this.changeJoinTemplate}
                                    setJoinTemplate={this.setJoinTemplate}
                                    isNew={checkNew()}
                                    getJoinTemplate={this.getJoinTemplate}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeJoinTemplate({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const joinTemplate = JSON.parse(JSON.stringify(newState.joinTemplate));
                    const target = joinTemplate;

                    target[name] = value;

                    newState.joinTemplate = joinTemplate;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    setJoinTemplate({ joinTemplate }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.joinTemplate = joinTemplate;
                    newState.joinTemplateSave = JSON.parse(JSON.stringify(joinTemplate));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'joinTemplate', value: joinTemplate });

                    resolve();
                },
            );
        });
    }

    updateJoinTemplate({ fields }) {
        return new Promise((resolve) => {
            this.setJoinTemplate(
                {
                    joinTemplate: {
                        ...this.state.joinTemplate,
                        ...fields,
                    },
                },
                resolve,
            );
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'joins',
            items: [
                {
                    key: 'templates',
                    rules: ['update'],
                },
            ],
        });
    }

    getJoinTemplate() {
        const { levels } = this.props;
        const id = levels[3];

        return new Promise((resolve) => {
            getJoinTemplates({ id }).then(
                ({ joinTemplate }) => {
                    this.setJoinTemplate({ joinTemplate }).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getJoinTemplate();

        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'joinTemplate', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _full _deep2"
                                filter={(page) => page.parentName === 'joins-templates-inner'}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeTag: state.typeTemplates,
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinsTemplatesInner);

JoinsTemplatesInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    typeTag: PropTypes.string,
};
