import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from '../../../../../components/crm/manual/executor/Main.jsx';
import List from '../../../../../components/ListAbsolute.jsx';

import removeTransition from '../../../../../functions/removeTransition.ts';
import ListScroll from '../../../../../components/ListScroll.jsx';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualCarsInnerExecutors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderExecutor = this.renderExecutor.bind(this);
        this.filterItem = this.filterItem.bind(this);
        this.filterExecutor = this.filterExecutor.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.getParentScroll = this.getParentScroll.bind(this);
        this.getMoreExecutors = this.getMoreExecutors.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 4;

    filterItem(item) {
        const { car = {} } = this.props;
        const { infoExecutors = [] } = car;

        return infoExecutors.length === 0 || item._id !== 'info';
    }

    getExecutors() {
        const { car = {} } = this.props;
        const { infoExecutors = [] } = car;

        return infoExecutors.concat(...[{ _id: 'info' }]).filter(this.filterItem);
    }

    filterExecutor(executor, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    getParentScroll() {
        const { getParent } = this.props;

        return getParent().querySelector(
            '.widget__page._manual-cars-inner-executors .widget__pageBox',
        );
    }

    getMoreExecutors(counter) {
        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                resolve();
                removeTransition({ item: '.manualContent__item', isCurrent: true });
            });
        });
    }

    renderExecutor({ item, prop: _id, isShow, params }) {
        const executors = this.getExecutors();
        const executor = executors.find((executorLoop) => executorLoop._id === _id) || item;

        return (
            <div
                className={`manualContent__item _parentForEdit _editBack ${_id === 'info' ? '_info' : ''} ${
                    isShow ? '_show' : ''
                }`}
                style={{ transform: `translate(0,${params?.offsetTop}px)` }}
                data-_id={_id}
                key={_id}
            >
                <div className="manualContent__itemInner">
                    {_id === 'info' ? (
                        <>
                            {' '}
                            <>
                                <div className="empty _col _block _whiteOpacity">
                                    <div className="empty__inner">
                                        <div className="empty__title">Водителей пока нет</div>
                                        <p className="empty__content">
                                            Вы можете добавить эту машину <br />
                                            на странице водителя
                                        </p>
                                    </div>
                                </div>
                            </>
                        </>
                    ) : (
                        <>
                            <Main
                                type="preview"
                                title={blocksManual.executor.main.title}
                                executor={executor}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }

    setParamsParent(params = {}) {
        const { setHeightPage } = this.props;
        const { height: heightItems } = params;

        if (heightItems !== this.state.heightItems) {
            this.setState({ heightItems }, () => {
                if (!this.isInit) {
                    this.isInit = true;
                    removeTransition({ item: '.manualContent__items' });
                    this.setState({ isInit: true });
                }
                setHeightPage();
            });
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        this.setState({ isUpdate: true }, () => {
            setHeightPage();
        });
    }

    render() {
        const { isInit } = this.state;
        let { heightItems = 0 } = this.state;
        const { handlerLoaderList } = this.props;
        const executors = this.getExecutors();
        const executorsFilter = executors.filter(this.filterExecutor);
        const parentList = this.parent.current?.querySelector('.manualContent__items');
        const isEmpty = executors.length === 0;

        if (isEmpty) {
            heightItems = 258;
        }

        return (
            <div ref={this.parent} className="manualContent _isInit">
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div
                            className={`manualContent__items ${!isEmpty ? '_show' : ''}`}
                            style={{ height: `${heightItems}px` }}
                        >
                            <div className="manualContent__itemsInner">
                                <ListScroll
                                    isInit={isInit}
                                    getParent={this.getParentScroll}
                                    callback={this.getMoreExecutors}
                                    startCounter={this.stepCounter}
                                    stepCounter={this.stepCounter}
                                    maxCounter={executors.length}
                                    lengthCurrent={executorsFilter.length}
                                    handlerLoaderList={handlerLoaderList}
                                    isLimit={executorsFilter.length === executors.length}
                                >
                                    <List
                                        parent={parentList}
                                        items={executorsFilter}
                                        renderItem={this.renderExecutor}
                                        classNameItem="manualContent__item"
                                        prop="_id"
                                        setParamsParent={this.setParamsParent}
                                    />
                                </ListScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualCarsInnerExecutors);

ManualCarsInnerExecutors.propTypes = {
    isInit: PropTypes.bool,
    car: PropTypes.object,
    setHeightPage: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    getParent: PropTypes.func,
};
