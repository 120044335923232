import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HeadInfo from './configuration/HeadInfo.jsx';
import Items from './configuration/Items.jsx';
import sortForAlphabet from '../../../../functions/sortForAlphabet';
import HeadTitle from './configuration/HeadTitle.jsx';
import Loader from '../../../Loader.jsx';
import Animate from '../../../Animate.jsx';

class Configuration extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};

        this.getItems = this.getItems.bind(this);

        this.parent = React.createRef();
    }

    getCurrentItem() {
        const { type, items = [], idOfCurrentItem } = this.props;

        return type === 'car' ? items.find((item) => item.key === idOfCurrentItem) : items[0];
    }

    renderHeadTitle() {
        const {
            _id,
            categories,
            category,
            isNew,
            name,
            type,
            isEdit,
            isDisabled,
            handlerConfiguration,
            errors,
        } = this.props;
        const item = this.getCurrentItem();

        return (
            <>
                <div className="manualCard__headTariff">
                    <HeadTitle
                        idOfConfig={_id}
                        categories={categories}
                        category={category}
                        isNew={isNew}
                        type={type}
                        name={name}
                        isEdit={isEdit}
                        isDisabled={isDisabled}
                        item={item}
                        handlerConfiguration={handlerConfiguration}
                        errors={errors}
                    />
                </div>
            </>
        );
    }

    getItems() {
        const { isInit } = this.state;
        const { serverData, items = [] } = this.props;
        const { tariffs } = serverData;
        const itemsUpdate = isInit
            ? JSON.parse(JSON.stringify(items)).map((item) => ({
                  ...item,
                  content:
                      tariffs?.['types-of-configurations'].find(
                          (itemInfo) => itemInfo.key === item.key,
                      )?.short || '',
              }))
            : [];

        return sortForAlphabet({ arr: itemsUpdate, prop: 'content' });
    }

    renderHeadInfo() {
        const {
            _id,
            type,
            idOfCurrentItem,
            setCurrentItem,
            tabs = [],
            infoCars = [],
            isEdit,
            isNew,
            name,
            handlerConfigurationItems,
            handlerConfiguration,
            deleteConfiguration,
            parent,
            parentScroll,
        } = this.props;

        return (
            <div className="manualCard__headInfo">
                <HeadInfo
                    _id={_id}
                    type={type}
                    tabs={tabs}
                    infoCars={infoCars}
                    items={this.getItems()}
                    setCurrentItem={(key) => setCurrentItem({ id: _id, key })}
                    idOfCurrentItem={idOfCurrentItem}
                    isNew={isNew}
                    isEdit={isEdit}
                    name={name}
                    handlerConfigurationItems={handlerConfigurationItems}
                    handlerConfiguration={handlerConfiguration}
                    deleteConfiguration={deleteConfiguration}
                    parent={parent}
                    parentScroll={parentScroll}
                />
            </div>
        );
    }

    componentDidMount() {
        const { type, _id, items, setCurrentItem, idOfCurrentItem, isNew } = this.props;

        if (type === 'car' && !idOfCurrentItem && items.length) {
            setCurrentItem({ id: _id, key: items[0].key });
        }

        setTimeout(
            () => {
                this.setState({ isInit: true });
            },
            isNew ? 0 : 300,
        );
    }

    render() {
        const { isInit } = this.state;
        const {
            _id,
            type,
            name,
            setCurrentItem,
            setHeightPage,
            setStateConfiguration,
            setStateConfigurationsHeight,
            configurationStackCounter,
            handlerStateServices,
            servicesState,
            isEdit,
            handlerConfigurationItem,
            category,
            parentScroll,
            errors,
        } = this.props;
        const currentItem = this.getCurrentItem();

        let { heightContent } = this.props;

        if (!heightContent) {
            heightContent = 148;
        }

        return (
            <div ref={this.parent} className="manualCard _tariffConfig">
                <div className="manualCard__head _row">
                    {this.renderHeadTitle()}
                    {this.renderHeadInfo()}
                </div>

                <div className="manualCard__content" style={{ height: `${heightContent}px` }}>
                    <Animate className="manualCard__contentLoader _loader" isShow={!isInit}>
                        <div className="manualCard__contentLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    {isInit && (
                        <Items
                            idOfConfig={_id}
                            idOfCurrentItem={currentItem?._id}
                            items={this.getItems()}
                            setHeightPage={setHeightPage}
                            setCurrentItem={(key) => setCurrentItem({ id: _id, key })}
                            type={type}
                            name={name}
                            setStateConfiguration={() => setStateConfiguration({ id: _id })}
                            setStateConfigurationsHeight={(height) =>
                                new Promise((resolve) => {
                                    setStateConfigurationsHeight({ id: _id, value: height }).then(
                                        resolve,
                                    );
                                })
                            }
                            configurationStackCounter={configurationStackCounter}
                            heightContent={heightContent}
                            handlerStateServices={handlerStateServices}
                            servicesState={servicesState}
                            isInit={isInit}
                            isEdit={isEdit}
                            handlerConfigurationItem={handlerConfigurationItem}
                            category={category}
                            parentScroll={parentScroll}
                            errors={errors}
                        />
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(Configuration);

Configuration.propTypes = {
    serverData: PropTypes.object,
    categories: PropTypes.array,
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    tabs: PropTypes.array,
    infoCars: PropTypes.array,
    items: PropTypes.array,
    isNew: PropTypes.bool,
    isDisabled: PropTypes.bool,
    setHeightPage: PropTypes.func,
    setCurrentItem: PropTypes.func,
    idOfCurrentItem: PropTypes.string,
    setStateConfiguration: PropTypes.func,
    setStateConfigurationsHeight: PropTypes.func,
    configurationStackCounter: PropTypes.number,
    heightContent: PropTypes.number,
    handlerStateServices: PropTypes.func,
    servicesState: PropTypes.bool,
    isEdit: PropTypes.bool,
    handlerConfiguration: PropTypes.func,
    handlerConfigurationItem: PropTypes.func,
    handlerConfigurationItems: PropTypes.func,
    deleteConfiguration: PropTypes.func,
    category: PropTypes.string,
    parent: PropTypes.object,
    parentScroll: PropTypes.object,
    isShowFull: PropTypes.bool,
    errors: PropTypes.object,
};
