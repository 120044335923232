import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../../../../functions/getPage';

import Link from '../../../../components/Link.jsx';

import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import handlerPopup from '../../../../functions/app/handlerPopup';
import setPermissions from '../../../../functions/crm/setPermissions';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.delete = this.delete.bind(this);

        setPermissions.call(this);

        this.group = React.createRef();
    }

    orderLinks = [{ key: 'main' }];

    links = {
        main: {
            key: 'pays-groups-inner-main',
        },
        logs: {
            key: 'pays-groups-inner-logs',
        },
    };

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    renderLink({ prop: key }) {
        const { levels } = this.props;
        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headLink" ids={{ 2: levels[2] }}>
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    render() {
        const { loadingKey } = this.state;
        const { groupId } = this.props;
        const items = this.orderLinks;

        return (
            <div className="widget__headActionsGroups _row">
                {false && (
                    <ListAbsoluteMain
                        className="widget__headActionsGroup _dynamic"
                        items={items}
                        renderItem={this.renderLink}
                        classNameItem="widget__headLink"
                        prop="key"
                        itemParams={['offsetRight']}
                    />
                )}
                {this.getPermissions({
                    notAdminCorporation: true,
                    key: 'pays',
                    items: [{ key: 'acts', rules: ['create'] }],
                }) && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headActionsElem widget__headLink _main">
                            <div
                                className="widget__headLinkInner _click"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'payPopup',
                                        isShow: true,
                                        groupId,
                                    });
                                }}
                            >
                                + Новый акт
                            </div>
                        </div>
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    groupId: PropTypes.string,
    pagesStore: PropTypes.object,
    checkRights: PropTypes.func,
    levels: PropTypes.array,
    user: PropTypes.object,
    delete: PropTypes.func,
};
