import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import changePage from '../../../functions/changePage';
import getPageLink from '../../../functions/getPageLink';
import checkVerification from '../../../functions/crm/checkVerification';

class VerificationPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { callback } = this.props;

        return (
            <div className="bonusPopup _col _verification">
                <div className="bonusPopup__inner">
                    <div className="bonusPopup__innerBox _col">
                        <img
                            src={require('../../../img/crm/verification-icon.svg').default}
                            alt=""
                            className="bonusPopup__icon"
                        />
                        <div className="bonusPopup__topTitle">ВАЖНО!</div>
                        <div className="bonusPopup__title">Пройдите верификацию корпорации</div>
                        <p className="bonusPopup__description">
                            Ваша корпорация должна быть верифицирована системой. Фунционал
                            CRM-системы будет ограничен: нельзя совершать выплаты, управлять
                            договорами и&nbsp;массовыми подключениями.
                        </p>
                        <div className="bonusPopup__button">
                            <Button
                                onClick={() => {
                                    changePage({
                                        href: getPageLink({ name: 'settings-docs' }),
                                    }).then(() => {
                                        checkVerification({ set: true });

                                        if (typeof callback === 'function') {
                                            callback();
                                        }
                                    });
                                }}
                                className="_mainNotBorder _medium2Size"
                            >
                                Пройти верификацию сейчас
                            </Button>
                        </div>
                        <div
                            className="bonusPopup__button _empty _click"
                            onClick={() => {
                                checkVerification({ set: true });
                            }}
                        >
                            Пройти позже
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(VerificationPopup);

VerificationPopup.propTypes = {
    user: PropTypes.object,
    callback: PropTypes.func,
};
