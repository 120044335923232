import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import getHeaders from '../../../../../functions/getHeaders';
import getUpdateFormData from '../../../../../functions/getUpdateFormData';

import Edit from '../../../../../components/Edit.jsx';
import Button from '../../../../../components/Button.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Link from '../../../../../components/Link.jsx';

import DriverPassport from '../../../../../components/crm/manual/executor/DriverPassport.jsx';

import Main from '../../../../../components/crm/manual/executor/Main.jsx';
import Passport from '../../../../../components/crm/manual/executor/Passport.jsx';
import Files from '../../../../../components/crm/manual/executor/Files.jsx';
import Services from '../../../../../components/crm/manual/executor/Services.jsx';
import Tags from '../../../../../components/crm/manual/executor/Tags.jsx';
import Comments from '../../../../../components/crm/manual/executor/Comments.jsx';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import setNotification from '../../../../../functions/setNotification';
import changePage from '../../../../../functions/changePage';
import setAnimate from '../../../../../functions/setAnimate';
import scrollToPosition from '../../../../../functions/scrollToPosition';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import getUserInfo from '../../../../../functions/getUserInfo';
import handlerPopup from '../../../../../functions/handlerPopup';
import getPageLink from '../../../../../functions/getPageLink';
import Verification from '../../../../../components/crm/manual/executor/Verification.jsx';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualExecutorsInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.createExecutor = this.createExecutor.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.renderCard = this.renderCard.bind(this);
        this.getCardsOrder = this.getCardsOrder.bind(this);
        this.filterCard = this.filterCard.bind(this);
        this.invite = this.invite.bind(this);

        this.parent = React.createRef();
    }

    name = 'executor';

    imagesCards = ['imagesPassport', 'imagesDriverPassport'];

    deepCards = ['passport', 'driverPassport'];

    orderCards = ['main', 'tags', 'comments', 'verification'];

    cards = {
        main: {
            changedProps: [
                'firstName',
                'secondName',
                'thirdName',
                'type',
                'inactive',
                'town',
                'phone',
                'telegram',
                'organization',
                'rate',
                'inn',
            ],
            requiredProps: [
                'firstName',
                'secondName',
                // 'thirdName',
                'type',
                'phone',
                'organization',
            ],
            render() {
                const { errors } = this.state;
                const { executor, isNew, setExecutor } = this.props;

                return (
                    <Main
                        title={blocksManual.executor.main.title}
                        executor={executor}
                        changeExecutor={({ ...props }) =>
                            this.changeExecutor({ ...props, block: 'main' })
                        }
                        checkEditmode={() => this.checkEditmode('main')}
                        isNew={isNew}
                        errors={errors.main}
                        setExecutor={setExecutor}
                    />
                );
            },
        },
        passport: {
            keyTarget: 'passport',
            changedProps: [
                'country',
                'series',
                'number',
                'view',
                'dateAction',
                'dateGet',
                'code',
                'birthday',
                'nameGet',
                'addressReg',
                'addressFact',
            ],
            getRequiredProps() {
                return [];

                // const { executor } = this.props;

                // return [
                //     'country',
                //     'series',
                //     'number',
                //     'view',
                //     'dateGet',
                //     'birthday',
                //     'nameGet',
                //     'addressReg',
                //     'addressFact',
                // ].concat(
                //     ...(executor?.passport.country && executor?.passport.country !== 'ru'
                //         ? ['dateAction']
                //         : ['code']),
                // );
            },
            render() {
                const { errors } = this.state;
                const { executor, isNew } = this.props;

                return (
                    <Passport
                        title={blocksManual.executor.passport.title}
                        executor={executor}
                        changeExecutor={({ ...props }) =>
                            this.changeExecutor({ ...props, block: 'passport' })
                        }
                        checkEditmode={() => this.checkEditmode('passport')}
                        errors={errors.passport}
                        isNew={isNew}
                    />
                );
            },
        },
        driverPassport: {
            keyTarget: 'driverPassport',
            changedProps: [
                'country',
                'series',
                'number',
                'dateGet',
                'view',
                'dateAction',
                'dateExp',
                'categories',
            ],
            requiredProps: [
                'country',
                'series',
                'number',
                'view',
                'dateGet',
                'dateAction',
                'dateExp',
                'categories',
            ],
            render() {
                const { errors } = this.state;
                const { executor } = this.props;

                return (
                    <DriverPassport
                        title={blocksManual.executor.driverPassport.title}
                        executor={executor}
                        changeExecutor={({ ...props }) =>
                            this.changeExecutor({ ...props, block: 'driverPassport' })
                        }
                        checkEditmode={() => this.checkEditmode('driverPassport')}
                        errors={errors.driverPassport}
                    />
                );
            },
        },
        imagesPassport: {
            parent: 'passport',
            changedProps: [],
            render() {
                const { executor, uploadFile, deleteFile } = this.props;

                return (
                    <Files
                        title={blocksManual.executor.imagesPassport.title}
                        type="passport"
                        typeFiles="imagesPassport"
                        images={executor?.passport?.images}
                        executor={executor}
                        checkEditmode={() => this.checkEditmode('imagesPassport')}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                    />
                );
            },
        },
        imagesDriverPassport: {
            parent: 'driverPassport',
            changedProps: [],
            render() {
                const { executor, uploadFile, deleteFile } = this.props;

                return (
                    <Files
                        title={blocksManual.executor.imagesDriverPassport.title}
                        type="driverPassport"
                        typeFiles="imagesDriverPassport"
                        images={executor?.driverPassport?.images}
                        executor={executor}
                        checkEditmode={() => this.checkEditmode('imagesDriverPassport')}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                    />
                );
            },
        },
        services: {
            changedProps: ['services'],
            render() {
                const { executor, handlerServices } = this.props;

                return (
                    <Services
                        title={blocksManual.executor.services.title}
                        executor={executor}
                        checkEditmode={() => this.checkEditmode('services')}
                        handlerServices={handlerServices}
                    />
                );
            },
        },
        tags: {
            changedProps: ['tags'],
            render() {
                const { isHoverUpdateTags } = this.state;
                const { executor, handlerTags, isNew } = this.props;

                return (
                    <Tags
                        title={blocksManual.executor.tags.title}
                        executor={executor}
                        checkEditmode={() => this.checkEditmode('tags')}
                        handlerTags={handlerTags}
                        isNew={isNew}
                        isHover={isHoverUpdateTags}
                    />
                );
            },
        },
        comments: {
            changedProps: ['comments'],
            render() {
                const { executor, addComment, changeComment, deleteComment } = this.props;

                return (
                    <Comments
                        title={blocksManual.executor.comments.title}
                        executor={executor}
                        checkEditmode={() => this.checkEditmode('comments')}
                        addComment={addComment}
                        changeComment={changeComment}
                        deleteComment={deleteComment}
                    />
                );
            },
        },
        verification: {
            changedProps: [],
            render() {
                const { executor } = this.props;

                return <Verification executor={executor} />;
            },
        },
    };

    changeExecutor({ block, ...props }) {
        const { changeExecutor } = this.props;

        return new Promise((resolve) => {
            changeExecutor({ ...props }).then(() => {
                if (props.action === 'change') {
                    this.handlerErrors({ action: 'delete', error: props.name, block });
                }

                resolve();
            });
        });
    }

    checkChangeProp({ prop, value, valueWas }) {
        switch (prop) {
            case 'services': {
                let isChange = false;

                value.forEach((item) => {
                    if (!valueWas.find((itemWas) => itemWas.name === item.name)) {
                        isChange = true;
                    }
                });

                valueWas.forEach((itemWas) => {
                    if (!value.find((item) => item.name === itemWas.name)) {
                        isChange = true;
                    }
                });

                return isChange;
            }
            case 'categories': {
                let isChange = false;

                value.forEach((item) => {
                    if (!valueWas.find((itemWas) => itemWas.name === item.name)) {
                        isChange = true;
                    }
                });

                valueWas.forEach((itemWas) => {
                    if (!value.find((item) => item.name === itemWas.name)) {
                        isChange = true;
                    }
                });

                return isChange;
            }
            case 'tags': {
                let isChange = false;

                value.forEach((item) => {
                    if (!valueWas.find((itemWas) => itemWas.id === item.id)) {
                        isChange = true;
                    }
                });

                valueWas.forEach((itemWas) => {
                    if (!value.find((item) => item.id === itemWas.id)) {
                        isChange = true;
                    }
                });

                return isChange;
            }
            case 'comments': {
                let isChange = false;

                value.forEach((item) => {
                    const valueWasItem = valueWas.find((itemWas) => itemWas._id === item._id);

                    if (!valueWasItem) {
                        isChange = true;
                    } else if (valueWasItem.content !== item.content) {
                        isChange = true;
                    }
                });

                valueWas.forEach((itemWas) => {
                    const valueItem = value.find((item) => item._id === itemWas._id);

                    if (!valueItem) {
                        isChange = true;
                    } else if (valueItem.content !== itemWas.content) {
                        isChange = true;
                    }
                });

                return isChange;
            }
            default:
                return value !== valueWas;
        }
    }

    checkChange(name) {
        const { executor, executorSave, filesDelete } = this.props;
        const { changedProps, parent } = this.cards[name];
        let isChange = false;
        const fields = {};

        if (['imagesPassport', 'imagesDriverPassport'].indexOf(name) !== -1) {
            executor[parent].images.files.forEach((file) => {
                const fileSave = executorSave[parent].images.files.find(
                    (item) => item.name === file.name,
                );

                if (fileSave.file.path !== file.file.path) {
                    isChange = true;
                }
                if (
                    filesDelete.find((imageDelete) => {
                        const [type, nameFile] = imageDelete.split('-');

                        return type === parent && nameFile === file.file.name;
                    })
                ) {
                    isChange = true;
                }
            });
        } else {
            changedProps.forEach((prop) => {
                const target = this.deepCards.indexOf(name) === -1 ? executor : executor[name];
                const targetWas =
                    this.deepCards.indexOf(name) === -1 ? executorSave : executorSave[name];

                if (
                    this.checkChangeProp({ prop, value: target[prop], valueWas: targetWas[prop] })
                ) {
                    isChange = true;
                    fields[prop] = target[prop];

                    if (name === 'passport' && prop === 'addressFact') {
                        fields.coords = executor.coords;
                    }
                }
            });
        }

        return { isChange, fields };
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualContent__card[data-id="${id}"]`,
            });
        }
    }

    handlerEditmode({ editName }) {
        const { levels, formDataFiles, filesDelete, clearFiles } = this.props;
        const id = levels[3];

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const requiredProps =
                        this.cards[this.state.editName].requiredProps ||
                        this.cards[this.state.editName].getRequiredProps?.call(this) ||
                        [];

                    formDataFiles.set('id', id);
                    formDataFiles.set('prop', this.state.editName);
                    formDataFiles.set('filesDelete', JSON.stringify(filesDelete));

                    const body = this.imagesCards.includes(this.state.editName)
                        ? getUpdateFormData(formDataFiles)
                        : {
                              id,
                              prop: this.state.editName,
                              fields,
                          };

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors } = this.validate({
                            props: requiredProps,
                            nameProp: this.state.editName,
                        });

                        if (!isSuccess) {
                            this.handlerErrors({ action: 'set', errors });
                        } else {
                            this.handlerLoadingBlock(this.state.editName).then(() => {
                                axios
                                    .patch(`${process.env.REACT_APP_API}/executor`, body, {
                                        headers: getHeaders(),
                                    })
                                    .then(
                                        (res) => {
                                            const { success, data } = res.data;

                                            if (success) {
                                                setNotification({
                                                    notification: 'success-update-executor',
                                                });
                                                if (
                                                    this.imagesCards.indexOf(
                                                        this.state.editName,
                                                    ) !== -1
                                                ) {
                                                    clearFiles();
                                                }

                                                resolve();
                                            } else {
                                                const { message } = data;

                                                if (message === 'Executor already register') {
                                                    setNotification({
                                                        notification: 'executor-already-reg',
                                                    });

                                                    this.handlerErrors({
                                                        action: 'add',
                                                        error: 'phone',
                                                        block: 'main',
                                                    });
                                                } else if (message === 'Inn already use') {
                                                    this.handlerErrors({
                                                        action: 'add',
                                                        error: 'inn',
                                                        block: 'main',
                                                    });

                                                    setNotification({
                                                        notification: 'inn-already-use',
                                                    });
                                                }

                                                handlerErrorRequest(res);
                                            }

                                            this.handlerLoadingBlock(null);
                                        },
                                        () => null,
                                    );
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    invite(executor) {
        const { getExecutor } = this.props;

        return new Promise((resolve) => {
            handlerPopup({
                action: 'show',
                name: 'popupExecutorInvite',
                executor,
                callback: () => {
                    changePage({
                        href: getPageLink({
                            name: 'manual-executors-inner',
                            ids: { 3: executor._id },
                        }),
                    });

                    getExecutor();
                },
            });

            resolve();
        });
    }

    createExecutor() {
        const { formDataFiles, executor, getExecutor, parentScroll } = this.props;
        const executorNew = JSON.parse(JSON.stringify(executor));
        let formData = new FormData();

        formData.set('executor', JSON.stringify(executorNew));

        formData = getUpdateFormData(formDataFiles, formData);

        const { isSuccess, errors } = this.validate({});

        if (!isSuccess) {
            this.handlerErrors({ action: 'set', errors });
        } else {
            this.handlerLoadingSave(true).then(() => {
                axios
                    .post(`${process.env.REACT_APP_API}/executor`, formData, {
                        headers: getHeaders(),
                    })
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { id } = data;

                                changePage({
                                    href: getPageLink({
                                        name: 'manual-executors-inner',
                                        ids: { 3: id },
                                    }),
                                });

                                setNotification({ notification: 'success-create-executor' });

                                const { scrollTop: scrollParent } = parentScroll;

                                setAnimate({
                                    draw: (progress) => {
                                        parentScroll.scrollTo({
                                            top: scrollParent - progress * scrollParent,
                                        });
                                    },
                                    duration: 300,
                                });

                                getExecutor();
                            } else {
                                const { message, otherExecutor } = data;

                                if (message === 'Executor already register') {
                                    if (otherExecutor) {
                                        setNotification({
                                            notification: 'executor-already-create',
                                            description: `${getUserInfo({
                                                user: otherExecutor,
                                                type: 'name',
                                            })}, <span class="_noWrap">${
                                                otherExecutor.phone
                                            }</span>`,
                                            callback: this.invite.bind(this, otherExecutor),
                                        });
                                    } else {
                                        setNotification({
                                            notification: 'executor-already-reg',
                                        });

                                        this.handlerErrors({
                                            action: 'add',
                                            error: 'phone',
                                            block: 'main',
                                        });
                                    }
                                } else if (message === 'Inn already use') {
                                    setNotification({
                                        notification: 'inn-already-use',
                                    });

                                    this.handlerErrors({
                                        action: 'add',
                                        error: 'inn',
                                        block: 'main',
                                    });
                                }

                                handlerErrorRequest(res);
                            }

                            this.handlerLoadingSave(false);
                        },
                        () => null,
                    );
            });
        }
    }

    getCardsOrder() {
        const { executor } = this.props;

        return executor ? this.orderCards.filter(this.filterCard).map((name) => ({ name })) : [];
    }

    renderCard({ prop: name, key: order, items = [] }) {
        const { editName, isHoverTags, loadingKey } = this.state;
        const { isNew, checkRights, executor } = this.props;
        let zIndex = items.length - order;
        const card = this.cards[name];
        const isDisabled = executor?.status === 'app-invite';

        if (!editName && name === 'tags' && (!isNew || isHoverTags)) {
            zIndex = 10;
        }

        if (name === editName && !isNew) {
            zIndex = 11;
        }

        return (
            <div
                className={`manualContent__card _parentForEdit _editBack _${name} ${
                    name === editName || isNew ? '_current' : ''
                }`}
                data-id={name}
                style={{
                    zIndex,
                }}
                onMouseOver={() => {
                    if (isNew && name === 'tags') {
                        if (this.timerHoverTags) {
                            clearTimeout(this.timerHoverTags);
                        }

                        this.setState({ isHoverTags: true }, () => {
                            setTimeout(() => {
                                this.setState({
                                    isHoverUpdateTags: true,
                                });
                            }, 10);
                        });
                    }
                }}
                onMouseOut={() => {
                    if (isNew && name === 'tags') {
                        this.timerHoverTags = setTimeout(() => {
                            this.setState({
                                isHoverTags: false,
                                isHoverUpdateTags: false,
                            });
                        }, 300);
                    }
                }}
            >
                {!isNew && checkRights() && !isDisabled && (
                    <Edit
                        name={name}
                        className="manualContent__cardEdit"
                        editName={editName}
                        handlerEditmode={this.handlerEditmode}
                        isLoader={loadingKey === name}
                    />
                )}

                {card.render.call(this)}
            </div>
        );
    }

    filterCard(name) {
        return ['driverPassport', 'imagesDriverPassport'].indexOf(name) === -1;
    }

    componentDidMount() {
        this.editmode = new Editmode({
            context: this,
        });
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        if (backToSave) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave, isInit } = this.state;
        const { executor = {}, isNew } = this.props;

        return (
            <div
                ref={this.parent}
                className={`manualContent _parentForEdits _executor ${editName ? '_edit' : ''}`}
            >
                <div className="manualContent__inner">
                    <div className={`manualContent__content ${!isInit ? '_hide' : ''}`}>
                        <ListAbsoluteMain
                            className="manualContent__cards _row"
                            items={this.getCardsOrder()}
                            renderItem={this.renderCard}
                            classNameItem="manualContent__card"
                            prop="name"
                            paramsParent={{ width: true }}
                            keyRender={executor?.type}
                            styles={['height']}
                            itemParams={['offsetLeft', 'offsetTop', 'height']}
                            defaultLeft={0}
                            defaultTop={0}
                            classNames={[
                                '_executor',
                                ...(executor.type ? [`_${executor.type}`] : []),
                            ]}
                            callback={({ type }) => {
                                if (!this.isInit && type === 'parent') {
                                    this.isInit = true;

                                    this.setState({ isInit: true });
                                }
                            }}
                            resizeParent={document.querySelector('.body__content')}
                        />
                        <Animate className="manualContent__actions" isShow={isNew}>
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="manual-executors-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <div className="manualContent__actionsButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.createExecutor}
                                        showLoader={isLoadingSave}
                                    >
                                        Сохранить данные
                                    </Button>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ManualExecutorsInnerMain);

ManualExecutorsInnerMain.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    executor: PropTypes.object,
    executorSave: PropTypes.object,
    changeExecutor: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataFiles: PropTypes.object,
    filesDelete: PropTypes.array,
    clearFiles: PropTypes.func,
    handlerServices: PropTypes.func,
    handlerTags: PropTypes.func,
    addComment: PropTypes.func,
    changeComment: PropTypes.func,
    deleteComment: PropTypes.func,
    isInit: PropTypes.bool,
    backToSave: PropTypes.func,
    isNew: PropTypes.bool,
    getExecutor: PropTypes.func,
    parentScroll: PropTypes.object,
    checkRights: PropTypes.func,
    setExecutor: PropTypes.func,
};
