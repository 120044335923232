import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import DocsOfPoint from '../../components/order-details/DocsOfPoint.jsx';
import Edit from '../../components/Edit.jsx';

import Editmode from '../../classes/Editmode';

import getHeaders from '../../functions/getHeaders';
import setNotification from '../../functions/setNotification';
import getUpdateFormData from '../../functions/getUpdateFormData';
import checkResponsible from '../../functions/order/checkResponsible';
import scrollToPosition from '../../functions/scrollToPosition';
import handlerErrorRequest from '../../functions/handlerErrorRequest';

class Docs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerEditmode = this.handlerEditmode.bind(this);
    }

    handlerLoad({ isLoad }) {
        return new Promise((resolve) => {
            this.setState({ isLoad }, resolve);
        });
    }

    checkPrevEdit() {
        const cond = true;

        return cond;
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.orderDetailsDocs__item[data-id="${id}"]`,
            });
        }
    }

    handlerEditmode({ editName }) {
        const { orderSave, handlerDocs, clearDocsForm } = this.props;
        const { docsFormData } = this.props;

        const promiseActions = () =>
            new Promise((resolveActions) => {
                const { order } = this.props;
                const { editName: idOfPoint } = this.state;
                const indexOfPoint = order.route.findIndex((point) => point._id === idOfPoint);

                if (order.route[indexOfPoint].docs.find((doc) => !doc.name)) {
                    const idsOfDocs = order.route[indexOfPoint].docs
                        .filter((doc) => !doc.name && doc.isNew)
                        .map((doc) => doc._id);

                    handlerDocs({ action: 'delete', idOfPoint, idsOfDocs }).then(() => {
                        resolveActions();
                    });
                } else {
                    resolveActions();
                }
            });

        return new Promise((resolve) => {
            this.handlerLoad({ isLoad: true }).then(() => {
                if (editName) {
                    this.editmode.handlerEdit({ editName }).then(() => {
                        this.scrollToCard(editName);

                        this.handlerLoad({ isLoad: false }).then(resolve);
                    });
                } else if (!this.checkPrevEdit()) {
                    this.handlerLoad({ isLoad: false });
                } else {
                    promiseActions().then(() => {
                        const { order } = this.props;
                        const { editName: idOfPoint } = this.state;
                        const indexOfPoint = order.route.findIndex(
                            (point) => point._id === idOfPoint,
                        );
                        let isChanged = false;

                        if (
                            order.route[indexOfPoint].docs.filter((doc) => !doc.isDelete).length !==
                            orderSave.route[indexOfPoint].docs.length
                        ) {
                            isChanged = true;
                        }

                        order.route[indexOfPoint].docs
                            .filter((doc) => !doc.isDelete)
                            .forEach((doc, keyDoc) => {
                                if (doc.name !== orderSave.route[indexOfPoint].docs[keyDoc]?.name) {
                                    isChanged = true;
                                }
                                if (
                                    doc.files.length !==
                                    orderSave.route[indexOfPoint].docs[keyDoc]?.files.length
                                ) {
                                    isChanged = true;
                                }
                            });

                        if (!isChanged) {
                            this.editmode.handlerEdit({ editName }).then(() => {
                                clearDocsForm();

                                this.handlerLoad({ isLoad: false }).then(resolve);
                            });
                        } else {
                            docsFormData.set('id', order._id);
                            docsFormData.set('idOfPoint', idOfPoint);
                            docsFormData.set('type', 'docs-of-points');

                            const docsOfPoints = order.route.map((point) => ({
                                _id: point._id,
                                docs: point.docs.map((doc) => ({
                                    _id: doc._id,
                                    name: doc.name,
                                    isNew: doc.isNew,
                                })),
                            }));

                            docsFormData.set('docs', JSON.stringify(docsOfPoints));

                            axios
                                .patch(
                                    `${process.env.REACT_APP_API}/order`,
                                    getUpdateFormData(docsFormData),
                                    {
                                        headers: getHeaders(),
                                    },
                                )
                                .then((res) => {
                                    const { success, data } = res.data;

                                    if (success) {
                                        clearDocsForm();
                                        setNotification({ notification: 'change-order' });

                                        this.editmode.handlerEdit({ editName }).then(() => {
                                            this.handlerLoad({ isLoad: false }).then(resolve);
                                        });
                                    } else {
                                        const { errors } = data;

                                        if (errors) {
                                            errors.forEach((error) => {
                                                const { message } = error;

                                                if (message === 'Required fields not complete') {
                                                    setNotification({
                                                        notification:
                                                            'required-fields-not-complete',
                                                    });
                                                }
                                            });
                                        }

                                        this.handlerLoad({ isLoad: false }).then(resolve);

                                        handlerErrorRequest(res);
                                    }
                                });
                        }
                    });
                }
            });
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        this.editmode = new Editmode({
            context: this,
        });

        if (setHeightPage) {
            setHeightPage();
        }
    }

    render() {
        const { editName, isLoad } = this.state;
        const { user, order, handlerFilesOfDoc, docsFormData, handlerDocs, fakeCallback } =
            this.props;
        const route = order?.route;

        return (
            <div className={`orderDetailsDocs _parentForEdits ${editName ? '_edit' : ''}`}>
                <div className="orderDetailsDocs__items">
                    {route?.map((point, key) => (
                        <div
                            className={`orderDetailsDocs__item _parentForEdit _editBack ${
                                point._id === editName ? '_current' : ''
                            }`}
                            data-id={point._id}
                            key={point._id}
                        >
                            <div className="orderDetailsDocs__itemInner">
                                {process.env.REACT_APP_SYSTEM === 'crm' &&
                                    checkResponsible({ user, order }) && (
                                        <Edit
                                            className="orderDetailsDocs__itemEdit"
                                            handlerEditmode={this.handlerEditmode}
                                            editName={editName}
                                            name={point._id}
                                            isLoader={isLoad}
                                        />
                                    )}

                                <DocsOfPoint
                                    order={order}
                                    number={key}
                                    point={point}
                                    editName={editName}
                                    handlerFilesOfDoc={handlerFilesOfDoc}
                                    docsFormData={docsFormData}
                                    handlerDocs={handlerDocs}
                                    fakeCallback={fakeCallback}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Docs);

Docs.propTypes = {
    user: PropTypes.object,
    order: PropTypes.object,
    orderSave: PropTypes.object,
    handlerFilesOfDoc: PropTypes.func,
    docsFormData: PropTypes.object,
    handlerDocs: PropTypes.func,
    fakeCallback: PropTypes.func,
    clearDocsForm: PropTypes.object,
    setHeightPage: PropTypes.func,
    parentScroll: PropTypes.object,
};
