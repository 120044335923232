import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Search from './Search.jsx';
import Icon from './Icon.jsx';
import Animate from './Animate.jsx';
import Button from './Button.jsx';
import Loader from './Loader.jsx';

import getHeaders from '../functions/getHeaders';
import getUserInfo from '../functions/getUserInfo';
import setAnimate from '../functions/setAnimate';
import AnimateChange from './AnimateChange.jsx';
import setSpacesInText from '../functions/setSpacesInText';
import handlerErrorRequest from '../functions/handlerErrorRequest';
import Avatar from './Avatar.jsx';
import Link from './Link.jsx';
import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import removeTransition from '../functions/removeTransition.ts';
import getRealParams from '../functions/getRealParams.ts';
import { dispatcher } from '../redux/redux';
import handlerWindow from '../functions/handlerWindow';

class WindowList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idOfCurrent: null,
        };

        this.handlerSearch = this.handlerSearch.bind(this);
        this.filterItem = this.filterItem.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.sortItems = this.sortItems.bind(this);
        this.getSortItems = this.getSortItems.bind(this);
        this.linkCallback = this.linkCallback.bind(this);

        this.set = this.set.bind(this);

        this.parent = React.createRef();
    }

    orderActions = ['add', 'delete'];

    getInfoEmpty() {
        const { items = [] } = this.state;

        if (items.length === 0) {
            return {
                title: 'Список пуст',
                description: 'По вашему запросу ничего не найдено',
            };
        }

        return {
            title: 'Измените поиск',
            description: 'По вашему фильтру ничего не найдено',
        };
    }

    getInfo() {
        const { name } = this.props;

        if (name === 'responsible') {
            return {
                search: 'Введите ФИО исполнителя',
                button: 'Назначить сотрудника',
                filter(item) {
                    const { search = '' } = this.state;

                    return getUserInfo({ type: 'name', user: item })
                        .toLowerCase()
                        .includes(search.toLowerCase());
                },
                getItemInfo(item) {
                    const { firstName, secondName, thirdName, icon } = item;

                    const content = (
                        <>
                            <span>{secondName}</span> {firstName} {thirdName}
                        </>
                    );

                    return {
                        icon,
                        content,
                    };
                },
                getQueryReq() {
                    const { keyParent } = this.props;

                    return `key=${keyParent}`;
                },
            };
        }

        if (name === 'cars') {
            return {
                search: 'Введите номер автомобиля',
                button: 'Добавить автомобиль',
                link: {
                    content: 'Создать новый автомобиль',
                    href: 'manual-cars-inner',
                    ids: { 3: 'new' },
                },
                filter(item) {
                    const { search = '' } = this.state;

                    return item.number.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                },
                getItemInfo(item) {
                    const { number, nameMark, nameModel, icon } = item;
                    const iconPath = `${process.env.REACT_APP_STATIC}/car-icons/${icon}`;
                    const content = (
                        <>
                            <span>{nameModel}</span> {nameMark} {number}
                        </>
                    );

                    return {
                        iconPath,
                        content,
                    };
                },
                getQueryReq() {
                    return '';
                },
            };
        }

        if (name === 'trailers') {
            return {
                search: 'Введите номер прицепа',
                button: 'Добавить прицеп',
                link: {
                    content: 'Создать новый прицеп',
                    href: 'manual-cars-inner',
                    ids: { 3: 'new' },
                },
                filter(item) {
                    const { search = '' } = this.state;

                    return item.number.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                },
                getItemInfo(item) {
                    const { number, nameMark, nameModel, icon } = item;
                    const iconPath = `${process.env.REACT_APP_STATIC}/car-icons/${icon}`;
                    const content = (
                        <>
                            <span>{nameModel}</span> {nameMark} {number}
                        </>
                    );

                    return {
                        iconPath,
                        content,
                    };
                },
                getQueryReq() {
                    return '';
                },
            };
        }

        if (name === 'companies') {
            return {
                search: 'Введите название компании',
                button: 'Добавить компанию',
                link: {
                    content: 'Создать новую компанию',
                    href: 'manual-organizations-inner',
                    ids: { 3: 'new' },
                },
                filter(item) {
                    const { search = '' } = this.state;

                    return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                },
                getItemInfo(item) {
                    const { name: nameCompany, inn, icon } = item;
                    const iconPath = `${process.env.REACT_APP_STATIC}/companies-icons/${icon}`;
                    const content = (
                        <>
                            <span>{nameCompany}</span>, {inn}
                        </>
                    );

                    return {
                        iconPath,
                        content,
                    };
                },
                getQueryReq() {
                    return '';
                },
            };
        }

        if (name === 'orderClients') {
            return {
                search: 'Введите название клиента',
                button: 'Выбрать клиента',
                filter(item) {
                    const { search = '' } = this.state;
                    const { typeOrganization } = item;
                    let content;

                    if (item._id === 'physical' || item._id === 'juristic') {
                        content = item.content;
                    } else if (typeOrganization === 'juristic') {
                        const { name: clientName, inn } = item;

                        content = `${clientName}, ${inn}`;
                    } else {
                        const { secondName, firstName, thirdName } = item;

                        content = `${secondName} ${firstName} ${thirdName}`;
                    }

                    return content.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                },
                getItemInfo(item) {
                    const { typeOrganization } = item;
                    let content;

                    if (item._id === 'physical') {
                        content = (
                            <>
                                Новое <span>физическое</span> лицо
                            </>
                        );
                    } else if (item._id === 'juristic') {
                        content = (
                            <>
                                Новое <span>юридическое</span> лицо
                            </>
                        );
                    } else if (typeOrganization === 'juristic') {
                        const { name: clientName, inn } = item;

                        content = (
                            <>
                                <span>{clientName}</span>, {inn}
                            </>
                        );
                    } else {
                        const { secondName, firstName, thirdName } = item;

                        content = (
                            <>
                                <span>{secondName}</span> {firstName} {thirdName}
                            </>
                        );
                    }

                    return {
                        content,
                    };
                },
                getQueryReq() {
                    return '';
                },
            };
        }

        return {};
    }

    linkCallback() {
        const { name } = this.props;

        if (name === 'companies') {
            const { idOfExecutor } = this.props;

            dispatcher({ type: 'forCompanyExecutorId', data: idOfExecutor });
        }

        if (name === 'cars') {
            const { idOfExecutor } = this.props;

            dispatcher({ type: 'forCarExecutorId', data: idOfExecutor });
        }

        if (name === 'trailers') {
            const { idOfCar } = this.props;

            dispatcher({ type: 'forTrailerCarId', data: idOfCar });
        }

        handlerWindow({
            action: 'hide',
        });
    }

    getItemsOrder() {
        const { items = [] } = this.state;
        let itemsFilter = items.filter(this.filterItem);

        if (itemsFilter.length === 0) {
            const infoEmpty = this.getInfoEmpty();

            itemsFilter = [{ _id: 'info', ...infoEmpty }];
        }

        return itemsFilter;
    }

    setCurrent(idOfCurrent, idOfCurrentStart = this.state.idOfCurrentStart) {
        this.setState({ idOfCurrent, idOfCurrentStart }, () => {
            const content = this.parent.current.querySelector('.windowList__content');
            const { scrollTop } = content;

            this.setContentHeight();

            setAnimate({
                draw: (progress) => {
                    content.scrollTo({
                        top: scrollTop - progress * scrollTop,
                    });
                },
                duration: 300,
            });
        });
    }

    setItems({ items }) {
        const { idOfCurrent } = this.props;

        return new Promise((resolve) => {
            this.setState({ items, isReady: true }, () => {
                if (idOfCurrent && items.find((item) => item._id === idOfCurrent)) {
                    this.setCurrent(idOfCurrent, idOfCurrent);
                }

                removeTransition({
                    item: '.windowList__item',
                    isCurrent: true,
                });

                resolve(items);
            });
        });
    }

    getList() {
        const { name, query = [] } = this.props;
        const info = this.getInfo();
        let queryString = '';

        query.forEach((itemQuery) => {
            queryString += `${itemQuery.key}=${itemQuery.value}&`;
        });

        return new Promise((resolve) => {
            axios
                .get(
                    `${process.env.REACT_APP_API}/list?name=${name}&${info.getQueryReq.call(
                        this,
                    )}&${queryString}`,
                    {
                        headers: getHeaders(),
                    },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { items } = data;

                            this.setItems({ items }, () => {
                                resolve(items);
                            });
                        } else {
                            handlerErrorRequest(res);
                        }
                    },
                    () => null,
                );
        });
    }

    filterItem(item) {
        const { search = '', idOfCurrent } = this.state;
        const info = this.getInfo();

        return !search || idOfCurrent === item._id || info.filter.call(this, item);
    }

    async handlerSearch({ action, value: search }) {
        if (action === 'change') {
            return this.setState({ search });
        }

        return true;
    }

    handlerLoading(isLoading) {
        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    set() {
        const { idOfCurrent } = this.state;
        const { callback } = this.props;

        if (callback && typeof callback === 'function') {
            this.handlerLoading(true).then(() => {
                callback({ id: idOfCurrent }).then(
                    () => {
                        this.handlerLoading(false);
                    },
                    () => null,
                );
            });
        }
    }

    sortItems(a, b) {
        const { idOfCurrent } = this.state;

        const weightA = a._id === idOfCurrent ? 1 : 0;
        const weightB = b._id === idOfCurrent ? 1 : 0;

        return weightB - weightA;
    }

    getSortItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    renderItem({ item: itemCome, prop: _id, key: orderKey }) {
        if (_id === 'info') {
            return (
                <div className="windowList__item _row _info">
                    <div className="empty _col _notBack _block">
                        <AnimateChange
                            className="empty__inner"
                            type="_translate _transFast"
                            prop={itemCome.title}
                        >
                            <div className="empty__innerItem">
                                <div className="empty__title">{itemCome.title}</div>
                                <p
                                    className="empty__content"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(itemCome.description),
                                    }}
                                ></p>
                            </div>
                        </AnimateChange>
                    </div>
                </div>
            );
        }

        const { idOfCurrent, items = [] } = this.state;
        const { name } = this.props;
        const info = this.getInfo();
        const item = items.find((itemLoop) => itemLoop._id === _id) || itemCome;
        const infoItem = info.getItemInfo.call(this, item);
        const isCurrent = idOfCurrent === _id;
        const stateAction = isCurrent ? 'delete' : 'add';
        let src;

        if (name === 'cars') {
            src = `${process.env.REACT_APP_STATIC}/car-icons/${item.icon}`;
        }

        if (name === 'trailers') {
            src = require('../img/crm/trailer-icon.png');
        }

        return (
            <div
                className={`windowList__item _row ${isCurrent ? '_current' : ''}`}
                style={{
                    zIndex: items.length - orderKey,
                }}
            >
                <div className="windowList__itemPreview _col">
                    <div className="windowList__itemPreviewIcon">
                        <Avatar
                            src={src}
                            holder={{
                                ...item,
                            }}
                            type={item.type}
                        />
                    </div>
                </div>
                <div className="windowList__itemContent">{infoItem.content}</div>
                <div
                    className="windowList__itemAction"
                    onClick={() => this.setCurrent(isCurrent ? null : _id)}
                >
                    <div className={`action _col _animate _${stateAction}`}>
                        {this.orderActions.map((action) => (
                            <Animate
                                key={action}
                                className={`action__icon _${action}`}
                                isShow={action === stateAction}
                            >
                                <Icon name={action} />
                            </Animate>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    checkActiveChoice() {
        const { idOfCurrent, idOfCurrentStart } = this.state;

        return idOfCurrent && (!idOfCurrentStart || idOfCurrentStart !== idOfCurrent);
    }

    setContentHeight() {
        const info = this.getInfo();
        const max = info.link ? 230 : 200;

        const { height: heightInner } = getRealParams({
            parent: this.parent.current,
            elem: '.windowList__contentInner',
            width: this.parent.current.offsetWidth,
        });

        const contentHeight = heightInner > max ? max : heightInner;

        this.setState({
            contentHeight,
        });
    }

    componentDidMount() {
        const { items } = this.props;

        if (items) {
            this.setItems({ items });
        } else {
            this.getList();
        }
    }

    render() {
        const { isReady, search, isLoading, idOfCurrent, contentHeight = 140 } = this.state;
        const { name, className = '' } = this.props;
        const info = this.getInfo();

        const isChoice = this.checkActiveChoice();

        return (
            <div
                ref={this.parent}
                className={`windowList _windowWithCursor _${name} ${
                    isReady ? '_ready' : ''
                } ${className} ${isChoice ? '_choice' : ''} ${info.link ? '_withLink' : ''}`}
            >
                <div className="windowList__inner">
                    <div className="windowList__head">
                        <div className="windowList__search">
                            <Search
                                support={info.search}
                                className="_darkBlue"
                                value={search}
                                callback={this.handlerSearch}
                            />
                        </div>
                    </div>
                    <div className="windowList__content" style={{ height: `${contentHeight}px` }}>
                        <div className="windowList__contentInner">
                            <Animate className="windowList__loader _loader" isShow={!isReady}>
                                <div className="windowList__loaderIcon _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <ListAbsoluteMain
                                className="windowList__items"
                                items={this.getItemsOrder()}
                                renderItem={this.renderItem}
                                classNameItem="windowList__item"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                itemParams={['offsetTop', 'height']}
                                sort={this.getSortItems}
                                keyRender={`${search}${idOfCurrent}`}
                                isNotNullParentSize={true}
                                callback={({ isInit }) => {
                                    if (isInit) {
                                        removeTransition({
                                            item: '.windowList__content',
                                            isCurrent: true,
                                        });
                                    }

                                    this.setContentHeight();
                                }}
                            />
                        </div>
                    </div>
                    <Animate className="windowList__foot _col" isShow={isChoice}>
                        <div className="windowList__button">
                            <Button
                                className="_mainNotBorder _normalSize"
                                onClick={this.set}
                                showLoader={isLoading}
                            >
                                {info.button}
                            </Button>
                        </div>
                    </Animate>
                    {info.link && (
                        <div className="windowList__link _col">
                            <Link
                                className="windowList__linkInner _click"
                                pageName={info.link.href}
                                ids={info.link.ids}
                                callback={this.linkCallback}
                            >
                                {info.link.content}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(WindowList);

WindowList.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    keyParent: PropTypes.string,
    serverData: PropTypes.object,
    callback: PropTypes.func,
    idOfCurrent: PropTypes.string,
    query: PropTypes.array,
    items: PropTypes.array,
    idOfExecutor: PropTypes.string,
    idOfCar: PropTypes.string,
};
