import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Field from '../../Field.jsx';
import Button from '../../Button.jsx';
import handlerLoading from '../../../functions/handlerLoading';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

class CorporationTariffPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'pay',
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    blocksOrder = ['pay', 'systemOrder', 'serviceOrder'];

    blocks = {
        serviceOrder: {
            icon: 'corporation-tariff-service-order',
            name: 'Заказы служб доставки',
        },
        systemOrder: {
            icon: 'corporation-tariff-system-order',
            name: 'Заказы сервиса',
        },
        pay: {
            icon: 'corporation-tariff-pay',
            name: 'Выплаты',
        },
    };

    handlerTariff({ name, values = [] }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const tariff = { ...newState.tariff };

                values.forEach(({ value, key }) => {
                    tariff[name][key] = value;
                });

                newState.tariff = tariff;

                return newState;
            }, resolve);
        });
    }

    handlerField(name, { action, name: key, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.handlerTariff({ name, values: [{ key, value }] }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    renderBlock({ prop: key }) {
        const { tariff } = this.state;
        const currentTariff = tariff?.[key];

        return (
            <div className="corporationTariffPopup__contentBlock _row">
                <div
                    className={`corporationTariffPopup__block _percent _click ${
                        currentTariff?.current === 'percent' ? '_current' : ''
                    }`}
                    onClick={() => {
                        this.handlerTariff({
                            name: key,
                            values: [{ key: 'current', value: 'percent' }],
                        });
                    }}
                >
                    <div className="corporationTariffPopup__blockHead _row">
                        <div className="corporationTariffPopup__blockHeadPoint _col" />
                        Процент и минимальная стоимость
                    </div>
                    <div className="corporationTariffPopup__blockContent _row">
                        <div className="corporationTariffPopup__blockField">
                            <p className="corporationTariffPopup__blockFieldSupport">
                                ПРОЦЕНТ ЗА ВЫПЛАТЫ
                            </p>
                            <div
                                className="corporationTariffPopup__blockFieldBox"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Field
                                    type="corporationTariff"
                                    keyName="percent"
                                    name="percent"
                                    value={currentTariff?.percent}
                                    callback={this.handlerField.bind(this, key)}
                                    className="_white"
                                    isDisabled={currentTariff?.current !== 'percent'}
                                />
                            </div>
                        </div>
                        <div className="corporationTariffPopup__blockField">
                            <p className="corporationTariffPopup__blockFieldSupport">
                                МИН. СТОИМОСТЬ
                            </p>
                            <div
                                className="corporationTariffPopup__blockFieldBox"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Field
                                    type="corporationTariff"
                                    keyName="price"
                                    name="min"
                                    value={currentTariff?.min}
                                    callback={this.handlerField.bind(this, key)}
                                    className="_white"
                                    isDisabled={currentTariff?.current !== 'percent'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`corporationTariffPopup__block _amount _click ${
                        currentTariff?.current === 'amount' ? '_current' : ''
                    }`}
                    onClick={() => {
                        this.handlerTariff({
                            name: key,
                            values: [{ key: 'current', value: 'amount' }],
                        });
                    }}
                >
                    <div className="corporationTariffPopup__blockHead _row">
                        <div className="corporationTariffPopup__blockHeadPoint _col" />
                        Фикс. стоимость
                    </div>
                    <div className="corporationTariffPopup__blockContent _row">
                        <div className="corporationTariffPopup__blockField">
                            <p className="corporationTariffPopup__blockFieldSupport">
                                Фикс. стоимость
                            </p>
                            <div
                                className="corporationTariffPopup__blockFieldBox"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Field
                                    type="corporationTariff"
                                    keyName="price"
                                    name="amount"
                                    value={currentTariff?.amount}
                                    callback={this.handlerField.bind(this, key)}
                                    className="_white"
                                    isDisabled={currentTariff?.current !== 'amount'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    checkChange() {
        const { tariff, savedTariff } = this.state;
        let isChange = false;

        if (savedTariff) {
            ['pay', 'serviceOrder', 'systemOrder'].forEach((name) => {
                const tariffItem = tariff[name];
                const savedTariffItem = savedTariff[name];

                ['current', 'percent', 'min', 'amount'].forEach((key) => {
                    if (tariffItem[key] !== savedTariffItem[key]) {
                        isChange = true;
                    }
                });
            });
        }

        return isChange;
    }

    save() {
        const { tariff, corporationId } = this.state;

        if (this.checkChange()) {
            handlerLoading.call(this, 'save').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/corporation`,
                        { id: corporationId, action: 'handler-tariff', tariff },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            const { success } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-change-info' });

                                this.setState({ savedTariff: JSON.parse(JSON.stringify(tariff)) });

                                this.state.callback();

                                this.hide();
                            }
                        },
                        () => {
                            handlerLoading.call(this, null);
                        },
                    );
            });
        }
    }

    hide() {
        handlerPopup({ name: 'corporationTariffPopup', isShow: false });
    }

    componentDidMount() {
        const { corporationTariffPopup } = this.props;
        const tariff = JSON.parse(JSON.stringify(corporationTariffPopup.tariff));

        ['pay', 'serviceOrder', 'systemOrder'].forEach((name) => {
            const tariffItem = tariff[name];

            ['min', 'amount'].forEach((key) => {
                if (tariffItem[key]) {
                    tariffItem[key] = tariffItem[key]?.toString().replace(/\./gi, ',');
                }
            });
        });

        this.setState({
            ...corporationTariffPopup,
            tariff,
            savedTariff: JSON.parse(JSON.stringify(tariff)),
        });
    }

    render() {
        const { currentBlock, corporationName, loadingKey } = this.state;

        return (
            <div ref={this.parent} className="corporationTariffPopup _col">
                <div className="corporationTariffPopup__inner">
                    <i
                        className="corporationTariffPopup__close _click"
                        onClick={() => {
                            this.hide();
                        }}
                    >
                        <Icon name="close-circle" />
                    </i>
                    <div className="corporationTariffPopup__head">
                        <div className="corporationTariffPopup__title">Редактировать тариф</div>
                        <p className="corporationTariffPopup__description">
                            Корпорация {corporationName}
                        </p>
                        <div className="corporationTariffPopup__tabs _row">
                            {this.blocksOrder.map((blockName) => {
                                const block = this.blocks[blockName];

                                return (
                                    <label className="corporationTariffPopup__tab" key={blockName}>
                                        <input
                                            type="radio"
                                            className="corporationTariffPopup__tabInput"
                                            checked={currentBlock === blockName}
                                            onChange={() => {
                                                this.setState({ currentBlock: blockName });
                                            }}
                                        />
                                        <div className="corporationTariffPopup__tabView">
                                            <i className="corporationTariffPopup__tabIcon">
                                                <Icon name={block.icon} />
                                            </i>
                                            {block.name}
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                    <div className="corporationTariffPopup__content">
                        <ListAbsoluteMain
                            className="corporationTariffPopup__contentBlocks"
                            items={[{ key: currentBlock }]}
                            renderItem={this.renderBlock}
                            classNameItem="corporationTariffPopup__contentBlock"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                            isNotParamsItem={true}
                            allItems={this.blocksOrder}
                            currentItemKey={currentBlock}
                        />
                    </div>
                    <div className="corporationTariffPopup__foot _col">
                        <div className="corporationTariffPopup__button">
                            <Button
                                className="_main _mainNotBorder"
                                onClick={this.save}
                                showLoader={!!loadingKey}
                                isDisabled={!this.checkChange()}
                            >
                                Сохранить изменения в тарифе
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        corporationTariffPopup: state.corporationTariffPopup,
    };
}

export default connect(mapStateToProps)(CorporationTariffPopup);

CorporationTariffPopup.propTypes = {
    corporationTariffPopup: PropTypes.object,
};
