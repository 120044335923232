import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getJoinContracts({ params = [] }) {
    let query = '';

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/join-contract?${query}`, {
                headers: getHeaders(),
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;
                    const {
                        joinContracts = [],
                        allJoinContracts = [],
                        isLimit,
                        counter,
                        completedCounter,
                        waitedCounter,
                    } = data;

                    if (success) {
                        resolve({
                            joinContracts,
                            allJoinContracts,
                            isLimit,
                            counter,
                            completedCounter,
                            waitedCounter,
                        });
                    } else {
                        handlerErrorRequest(res);

                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
