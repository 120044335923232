export default function setLocalFilter({ localFilter, filter }) {
    filter.forEach((block) => {
        const localBlock = localFilter.find((item) => item.key === block.key);

        if (localBlock) {
            block.choices = localBlock.choices;
            block.search = localBlock.search;
            block.firstDate = localBlock.firstDate;
            block.secondDate = localBlock.secondDate;
        }
    });
}
