import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ServiceCompanies from '../../components/order-details/ServiceCompanies.jsx';

import AnimateChange from '../../components/AnimateChange.jsx';
import Icon from '../../components/Icon.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // this.handlerEditmode = this.handlerEditmode.bind(this);
    }

    // handlerEditmode({ editName }) {
    //     const { order, orderSave } = this.props;

    //     const prevActions = () =>
    //         new Promise((resolve, reject) => {
    //             if (!editName) {
    //                 const orderData = {};

    //                 if (
    //                     order.serviceCompany.isAutoSearch !== orderSave.serviceCompany.isAutoSearch
    //                 ) {
    //                     orderData.serviceCompany = order.serviceCompany;
    //                 }

    //                 if (Object.keys(orderData).length === 0) {
    //                     this.editmode.handlerEdit({ editName: null });

    //                     reject();
    //                 } else {
    //                     resolve(orderData);
    //                 }
    //             } else {
    //                 resolve();
    //             }
    //         });

    //     const sendRequest = (orderData) =>
    //         new Promise((resolve, reject) => {
    //             if (orderData) {
    //                 const formData = new FormData();

    //                 formData.set('id', order._id);
    //                 formData.set('orderData', JSON.stringify(orderData));

    //                 setTimeout(() => {
    //                     axios
    //                         .patch(`${process.env.REACT_APP_API}/order`, formData, {
    //                             headers: getHeaders(),
    //                         })
    //                         .then((res) => {
    //                             const { success } = res.data;

    //                             if (success) {
    //                                 setNotification({ notification: 'change-order' });

    //                                 resolve();
    //                             } else {
    //                                 handlerErrorRequest(res);

    //                                 reject();
    //                             }
    //                         });
    //                 }, 300);
    //             } else {
    //                 resolve();
    //             }
    //         });

    //     return new Promise((resolve) => {
    //         prevActions().then((orderData) => {
    //             this.editmode.handlerLoading(this.state.editName).then(() => {
    //                 sendRequest(orderData).then(() => {
    //                     this.editmode.handlerEdit({ editName }).then(() => {
    //                         this.editmode.handlerLoading(null).then(resolve);
    //                     });
    //                 });
    //             });
    //         });
    //     });
    // }

    // componentDidMount() {
    //     this.editmode = new Editmode({
    //         context: this,
    //     });
    // }

    render() {
        const { editName } = this.state;
        const { order, setHeightPage, changeOrder } = this.props;
        const executor = order?.serviceCompany?.crew[0];

        return (
            <div className={`orderDetailsServices _parentForEdits ${editName ? '_edit' : ''}`}>
                <div className="orderDetailsServices__inner">
                    <div
                        className={`orderDetailsServices__info _parentForEdit _editBack ${
                            editName === 'info' ? '_current' : ''
                        }`}
                    >
                        {/* <Edit
                            className="orderDetailsServices__infoEdit"
                            handlerEditmode={this.handlerEditmode}
                            editName={editName}
                            name="info"
                            isLoader={keyLoading === 'info'}
                        /> */}
                        <ServiceCompanies
                            order={order}
                            points={order?.route}
                            isInner={true}
                            changeOrder={changeOrder}
                            setHeightPage={setHeightPage}
                            isDisabled={editName !== 'info'}
                        />
                    </div>
                    <div className="orderDetailsServices__crew _editBack">
                        <div className="orderDetailsServices__crewHead">
                            <div className="orderDetailsServices__crewTitle _row">
                                <div className="orderDetailsServices__crewTitleSupport">
                                    Исполнитель:
                                </div>
                                <AnimateChange
                                    className="orderDetailsServices__crewTitleItem"
                                    prop={!!executor}
                                    type="_translateMedium"
                                >
                                    <>{executor ? 'Назначен' : 'Идёт поиск'}</>
                                </AnimateChange>
                            </div>
                        </div>
                        <div className="orderDetailsServices__crewContent _row">
                            <div className="orderDetailsServices__crewBlock _col _info">
                                <div className="orderDetailsServices__crewExecutor _row">
                                    <div className="orderDetailsServices__crewExecutorPreview _col">
                                        <Animate
                                            className="orderDetailsServices__crewExecutorPreviewLoader _loader"
                                            isShow={!executor}
                                        >
                                            <i className="orderDetailsServices__crewExecutorPreviewLoaderIcon _loaderItem">
                                                <Loader className="_main" />
                                            </i>
                                        </Animate>
                                        <i className="orderDetailsServices__crewExecutorPreviewIcon">
                                            <Icon name="crew-executor" />
                                        </i>
                                    </div>
                                    <div className="orderDetailsServices__crewExecutorContent">
                                        <p className="orderDetailsServices__crewSupport">
                                            Водитель
                                        </p>
                                        <AnimateChange
                                            className="orderDetailsServices__crewValue"
                                            prop={!!executor}
                                            type="_translateMedium"
                                        >
                                            <>{executor?.name || '–'}</>
                                        </AnimateChange>
                                    </div>
                                </div>
                            </div>
                            <div className="orderDetailsServices__crewBlock _col _phone">
                                <p className="orderDetailsServices__crewSupport">Телефон</p>
                                <AnimateChange
                                    className="orderDetailsServices__crewValue"
                                    prop={!!executor}
                                    type="_translateMedium"
                                >
                                    <>{executor?.phone || '–'}</>
                                </AnimateChange>
                            </div>
                            <div className="orderDetailsServices__crewBlock _col _carNumber">
                                <p className="orderDetailsServices__crewSupport">Гос. номер</p>
                                <AnimateChange
                                    className="orderDetailsServices__crewValue"
                                    prop={!!executor}
                                    type="_translateMedium"
                                >
                                    <>{order?.serviceCompany?.car?.number || '–'}</>
                                </AnimateChange>
                            </div>
                            <div className="orderDetailsServices__crewBlock _col _carMark">
                                <p className="orderDetailsServices__crewSupport">Марка ТС</p>
                                <AnimateChange
                                    className="orderDetailsServices__crewValue"
                                    prop={!!executor}
                                    type="_translateMedium"
                                >
                                    <>{order?.serviceCompany?.car?.mark || '–'}</>
                                </AnimateChange>
                            </div>
                            <div className="orderDetailsServices__crewBlock _col _carModel">
                                <p className="orderDetailsServices__crewSupport">Модель ТС</p>
                                <AnimateChange
                                    className="orderDetailsServices__crewValue"
                                    prop={!!executor}
                                    type="_translateMedium"
                                >
                                    <>{order?.serviceCompany?.car?.model || '–'}</>
                                </AnimateChange>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Services);

Services.propTypes = {
    order: PropTypes.object,
    orderSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    changeOrder: PropTypes.func,
    setOrder: PropTypes.func,
};
