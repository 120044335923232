import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChangeUp from '../AnimateChangeUp.jsx';

import getFormatedDate from '../../functions/getFormatedDate';

class StatusModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { name = '', statuses = [], status = '', date = '' } = this.props;
        const info = statuses.find((item) => item.key === status);
        const prop = `${status}${date}`;

        return (
            <div className={`statusModel _row _${name} _${status}`}>
                <AnimateChangeUp className="statusModel__inner" renderKey={prop} offsetWidth={2}>
                    <>
                        {info?.content}
                        {date && <span>{getFormatedDate({ date: new Date(date) })}</span>}
                    </>
                </AnimateChangeUp>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(StatusModel);

StatusModel.propTypes = {
    name: PropTypes.string,
    statuses: PropTypes.array,
    status: PropTypes.string,
    date: PropTypes.string,
};
