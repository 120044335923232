import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MainDefault from '../../../components/crm/manual/Main.jsx';
import ReportsTable from '../../../components/crm/settings/ReportsTable.jsx';

class SettingsReports extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();
    }

    render() {
        const { corporation, getParent } = this.props;

        return (
            <div ref={this.parent} className="settingsTariffs">
                <div className="settingsTariffs__inner">
                    <div className="settingsTariffs__content">
                        {corporation && <ReportsTable getParent={getParent} />}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsReports);

SettingsReports.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    setHeightPage: PropTypes.func,
};
