import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import getHeaders from '../../../../../functions/getHeaders';

import Edit from '../../../../../components/Edit.jsx';
import Button from '../../../../../components/Button.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Link from '../../../../../components/Link.jsx';

import Main from '../../../../../components/crm/joins/docs/Main.jsx';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import setNotification from '../../../../../functions/setNotification';
import changePage from '../../../../../functions/changePage';
import setAnimate from '../../../../../functions/setAnimate';

import scrollToPosition from '../../../../../functions/scrollToPosition';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import getPageLink from '../../../../../functions/getPageLink';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class JoinsDocsInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.changeJoinDoc = this.changeJoinDoc.bind(this);
        this.createJoinDoc = this.createJoinDoc.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);

        this.parent = React.createRef();
    }

    name = 'joinDoc';

    orderCards = ['main'];

    cards = {
        main: {
            changedProps: ['name', 'filesCounter'],
            requiredProps: [{ name: 'name', keyName: 'anyText' }, 'filesCounter'],
            render() {
                const { modelsOfCar, marksOfCar, errors } = this.state;
                const { joinDoc, isNew, getParent } = this.props;

                return (
                    <Main
                        title={blocksManual.joinDoc.main.title}
                        joinDoc={joinDoc}
                        checkEditmode={() => this.checkEditmode('main')}
                        isNew={isNew}
                        getParent={getParent}
                        changeJoinDoc={({ ...props }) =>
                            this.changeJoinDoc({ block: 'main', ...props })
                        }
                        modelsOfCar={modelsOfCar}
                        marksOfCar={marksOfCar}
                        errors={errors.main}
                    />
                );
            },
        },
    };

    changeJoinDoc({ block, ...props }) {
        const { changeJoinDoc } = this.props;

        return new Promise((resolve) => {
            changeJoinDoc({ ...props }).then(
                () => {
                    if (props.action === 'change' || !props.action) {
                        this.handlerErrors({ action: 'delete', error: props.name, block });
                    }

                    resolve();
                },
                () => null,
            );
        });
    }

    checkChangeProp({ value, valueWas }) {
        return value !== valueWas;
    }

    deepCards = [];

    checkChange(name) {
        const { joinDoc, joinDocSave } = this.props;
        const { changedProps } = this.cards[name];
        let isChange = false;
        const fields = {};

        changedProps.forEach((propRes) => {
            const prop = typeof propRes === 'object' ? propRes.name : propRes;
            const target = joinDoc;
            const targetWas = joinDocSave;

            if (this.checkChangeProp({ prop, value: target[prop], valueWas: targetWas[prop] })) {
                isChange = true;
                fields[prop] = target[prop];
            }
        });

        if (fields.filesCounter) {
            fields.filesCounter = +fields.filesCounter;
        }

        return { isChange, fields };
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualContent__card[data-id="${id}"]`,
            });
        }
    }

    handlerReqErrors({ message }) {
        if (message === 'Join doc already create') {
            setNotification({
                notification: 'joinDoc-already-reg',
            });

            this.handlerErrors({
                action: 'add',
                error: 'name',
                block: 'main',
            });
        }
    }

    handlerEditmode({ editName }) {
        const { levels, setJoinDoc, joinDoc } = this.props;
        const id = levels[2];

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const { requiredProps } = this.cards[this.state.editName];

                    const validateResult = this.validate({
                        props: requiredProps,
                        nameProp: this.state.editName,
                        isShowNotification: false,
                    });

                    const { isSuccess, errors } = validateResult;

                    if (!isChange) {
                        resolve();
                    } else if (!isSuccess) {
                        this.handlerErrors({ action: 'set', errors });
                    } else {
                        this.handlerLoadingSave(true).then(() => {
                            axios
                                .patch(
                                    `${process.env.REACT_APP_API}/join-doc`,
                                    {
                                        id,
                                        fields,
                                    },
                                    {
                                        headers: getHeaders(),
                                    },
                                )
                                .then(
                                    (res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'success-update-joinDoc',
                                            });

                                            setJoinDoc({ joinDoc });

                                            resolve();
                                        } else {
                                            this.handlerReqErrors(data);

                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoadingSave(null);
                                    },
                                    () => null,
                                );
                        });
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    createJoinDoc() {
        const { joinDoc, getJoinDoc } = this.props;
        const validateResult = this.validate({ isShowNotification: false });
        const { isSuccess, errors } = validateResult;
        const fields = {};

        ['name', 'filesCounter'].forEach((key) => {
            if (key === 'filesCounter') {
                fields[key] = +joinDoc[key];
            } else {
                fields[key] = joinDoc[key];
            }
        });

        if (!isSuccess) {
            setNotification({ notification: 'required-fields-not-complete' });

            this.handlerErrors({ action: 'set', errors });
        } else {
            this.handlerLoadingSave(true).then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/join-doc`,
                        { fields },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { id } = data;

                                changePage({
                                    href: getPageLink({ name: 'joins-docs-inner', ids: { 3: id } }),
                                });

                                setNotification({ notification: 'success-create-joinDoc' });

                                const parentScroll = document.querySelector(
                                    '.widget__page._joins-docs-inner-main .widget__pageBox',
                                );
                                const { scrollTop: scrollParent } = parentScroll;

                                setAnimate({
                                    draw: (progress) => {
                                        parentScroll.scrollTo({
                                            top: scrollParent - progress * scrollParent,
                                        });
                                    },
                                    duration: 300,
                                });

                                getJoinDoc();
                            } else {
                                this.handlerReqErrors(data);

                                handlerErrorRequest(res);
                            }

                            this.handlerLoadingSave(null);
                        },
                        () => null,
                    );
            });
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.editmode = new Editmode({
            context: this,
        });
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        if (backToSave) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave, loadingKey } = this.state;
        const { isNew, setHeightPage, checkRights } = this.props;

        return (
            <div ref={this.parent} className={`manualContent _parentForEdits _joinDoc ${editName ? '_edit' : ''}`}>
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div className="manualContent__cards _row">
                            {this.props.joinDoc &&
                                this.orderCards.map((name, key) => {
                                    const card = this.cards[name];
                                    const zIndex = this.orderCards.length - key + 10;

                                    return (
                                        <div
                                            className={`manualContent__card _parentForEdit _editBack _${name} ${
                                                name === editName || isNew ? '_current' : ''
                                            } _show`}
                                            key={name}
                                            data-id={name}
                                            style={{ zIndex }}
                                        >
                                            {!isNew && checkRights() && (
                                                <Edit
                                                    name={name}
                                                    className="manualContent__cardEdit"
                                                    editName={editName}
                                                    handlerEditmode={this.handlerEditmode}
                                                    isLoader={loadingKey === name}
                                                />
                                            )}

                                            {card.render.call(this)}
                                        </div>
                                    );
                                })}
                        </div>
                        <Animate
                            className="manualContent__actions"
                            isShow={isNew}
                            actionInit={() => {
                                setHeightPage();
                            }}
                            actionPrevRemove={() => {
                                setHeightPage();
                            }}
                        >
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="joins-docs-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <div className="manualContent__actionsButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.createJoinDoc}
                                        showLoader={isLoadingSave}
                                    >
                                        Сохранить данные
                                    </Button>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(JoinsDocsInnerMain);

JoinsDocsInnerMain.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    joinDoc: PropTypes.object,
    joinDocSave: PropTypes.object,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataFiles: PropTypes.object,
    filesDelete: PropTypes.array,
    clearFiles: PropTypes.func,
    isInit: PropTypes.bool,
    backToSave: PropTypes.func,
    isNew: PropTypes.bool,
    getParent: PropTypes.func,
    changeJoinDoc: PropTypes.func,
    handlerServices: PropTypes.func,
    checkRights: PropTypes.func,
    setJoinDoc: PropTypes.func,
};
