export default class MobileDeletePoint {
    constructor({
        key,
        point,
        dragArea,
        deleteArea,
        moreBtnClass,
        getCondForDelete,
        callbackMove,
    }) {
        this.key = key;
        this.point = point;
        this.dragArea = dragArea;
        this.deleteArea = deleteArea;
        this.moreBtnClass = moreBtnClass;
        this.getCondForDelete = getCondForDelete;
        this.callbackMove = callbackMove;

        this.startX = 0;
        this.startY = 0;
        this.moveX = 0;
        this.lastX = 0;
        this.condForMove = false;

        this.condForX = false;
        this.condForY = false;

        this.reset = this.reset.bind(this);
        this.handlerDeleteBtn = this.handlerDeleteBtn.bind(this);

        this.init();
    }

    setStateDeleteBtn(action) {
        this.deleteArea.classList[action]('_active');
    }

    isReset = false;

    reset() {
        if (!this.isReset && this.getCondForDelete()) {
            this.isReset = true;
            this.dragArea.style.transition = `.5s ease-in-out`;
            this.moveX = 0;
            this.dragArea.style.transform = `translate3d(${this.moveX}px,0,0)`;

            setTimeout(() => {
                this.dragArea.style.transform = `none`;
            }, 500);

            this.setStateDeleteBtn('remove');

            this.lastX = this.moveX;
            this.callbackMove(false);
        }
    }

    handlerDeleteBtn() {
        if (this.getCondForDelete()) {
            const widthDeleteArea = this.deleteArea.clientWidth;

            this.setStateDeleteBtn('add');

            this.dragArea.style.transition = `.5s ease-in-out`;
            this.moveX = widthDeleteArea;
            this.dragArea.style.transform = `translate3d(${-this.moveX}px,0,0)`;
            this.lastX = this.moveX;

            document.dispatchEvent(
                new CustomEvent('moveDeletePoint', { detail: { key: this.key } }),
            );
        }
    }

    init() {
        this.dragArea.oncontextmenu = () => false;

        this.dragArea.addEventListener('touchstart', (e) => {
            if (this.condForMove === false && this.getCondForDelete()) {
                this.callbackMove(true);
                this.isReset = false;
                this.condForMove = true;
                this.dragArea.style.transition = `none`;

                this.startX = e.changedTouches[0].pageX;
                this.startY = e.changedTouches[0].pageY;
            }
        });

        document.addEventListener(
            'touchmove',
            (e) => {
                if (this.condForMove === true && this.getCondForDelete()) {
                    const widthDragArea = this.dragArea.clientWidth;
                    const widthDeleteArea = this.deleteArea.clientWidth;
                    const { pageX, pageY } = e.changedTouches[0];

                    if (this.condForY === false && Math.abs(pageX - this.startX) > 4) {
                        this.condForX = true;
                    }

                    if (this.condForX === false && Math.abs(pageY - this.startY) > 4) {
                        this.condForY = true;
                    }

                    if (this.condForX === true) {
                        e.preventDefault();
                        document.dispatchEvent(
                            new CustomEvent('moveDeletePoint', { detail: { key: this.key } }),
                        );

                        if (
                            this.startX - pageX + this.lastX >= 0 &&
                            this.startX - pageX + this.lastX <= widthDragArea
                        ) {
                            this.moveX = this.startX - pageX + this.lastX;

                            if (this.moveX > widthDeleteArea) {
                                const diff = this.moveX - widthDeleteArea;
                                this.moveX =
                                    widthDeleteArea +
                                    diff /
                                        ((widthDragArea - widthDeleteArea + diff * 3) /
                                            (widthDragArea - widthDeleteArea));
                            }
                        } else if (this.startX - pageX + this.lastX < 0) {
                            this.moveX = 0;
                        } else if (this.moveX > widthDragArea) {
                            this.moveX = widthDragArea;
                        }
                        this.dragArea.style.transform = `translate3d(${-this.moveX}px,0,0)`;
                    }
                }
            },
            { passive: false },
        );

        document.addEventListener('touchend', () => {
            if (this.condForMove === true && this.getCondForDelete()) {
                const widthDeleteArea = this.deleteArea.clientWidth;

                this.dragArea.style.transition = `.3s ease-in-out`;

                if (this.moveX > widthDeleteArea / 2) {
                    this.moveX = widthDeleteArea;
                    this.setStateDeleteBtn('add');
                } else {
                    this.moveX = 0;
                    this.setStateDeleteBtn('remove');
                    this.callbackMove(false);
                }

                this.dragArea.style.transform = `translate3d(${-this.moveX}px,0,0)`;
                this.lastX = this.moveX;
                this.moveX = 0;
                this.condForMove = false;

                this.condForX = false;
                this.condForY = false;
            }
        });

        document.addEventListener('moveDeletePoint', (e) => {
            const { detail } = e;
            const { key } = detail;

            if (key !== this.key) {
                this.reset();
            }
        });

        document.addEventListener('touchstart', ({ target }) => {
            if (target !== this.dragArea && !this.dragArea.contains(target)) {
                this.reset();
            }
        });

        document.addEventListener('scroll', () => {
            this.reset();
        });
    }
}
