import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getTags from '../../../../requests/getTags';

import Main from './inner/Main.jsx';
import Logs from './inner/Logs.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import getModel from '../../../../requests/getModel';
import setPermissions from '../../../../functions/crm/setPermissions';

class ManualTagsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getTag = this.getTag.bind(this);
        this.setTag = this.setTag.bind(this);
        this.changeTag = this.changeTag.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'tag';

    targetNameSave = 'tagSave';

    pages = {
        'manual-tags-inner-main': {
            render() {
                const { tag, tagSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector('.widget__page._manual-tags-inner-main')
                                            ?.querySelector('.widget__pageBox'))()}
                                    tag={tag}
                                    tagSave={tagSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeTag={this.changeTag}
                                    isNew={checkNew()}
                                    getTag={this.getTag}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-tags-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, tag } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    tag={tag}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeTag({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const tag = JSON.parse(JSON.stringify(newState.tag));
                    const target = tag;

                    target[name] = value;

                    if (name === 'idOfGroup') {
                        target.newGroup = '';
                    }

                    if (name === 'newGroup') {
                        target.idOfGroup = null;
                    }

                    newState.tag = tag;

                    return newState;
                }, resolve);
            }
        });
    }

    setTag({ tag }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.tag = tag;
                    newState.tagSave = JSON.parse(JSON.stringify(tag));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'tag', value: tag });

                    resolve();
                },
            );
        });
    }

    updateTag({ fields }) {
        return new Promise((resolve) => {
            this.setTag(
                {
                    tag: {
                        ...this.state.tag,
                        ...fields,
                        ...(fields.idOfGroup ? { newGroup: '' } : {}),
                    },
                },
                resolve,
            );
        });
    }

    getTag() {
        const { levels, checkNew } = this.props;
        const idOfTag = levels[3];

        return new Promise((resolve) => {
            if (checkNew()) {
                getModel({ name: 'tag' }).then(({ model }) => {
                    setTimeout(() => {
                        this.setTag({
                            tag: {
                                ...model,
                                typeTag: idOfTag,
                                isNew: true,
                            },
                        }).then(() => {
                            resolve();
                        });
                    }, 300);
                });
            } else {
                getTags({ id: idOfTag }).then(({ tag }) => {
                    setTimeout(() => {
                        this.setTag({ tag }).then(() => {
                            resolve();
                        });
                    }, 300);
                });
            }
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'tags',
                    rules: ['update'],
                },
            ],
        });
    }

    handlerSocket({ detail }) {
        const { tag } = this.state;
        const { name, data } = detail;

        if (tag) {
            const { type, fields } = data;

            if (type === 'changeInfo') {
                if (name === 'tag' && tag._id === data.idOfTag) {
                    this.updateTag({ fields });
                }
            }
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getTag();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'tag', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget  ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    ['manual-tags-inner-logs'].includes(pageName) ? '_full' : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-tags-inner' && page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeTag: state.typeTag,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualTagsInner);

ManualTagsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    typeTag: PropTypes.string,
};
