import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import ImageLazy from '../ImageLazy.jsx';
import Icon from '../Icon.jsx';
import Field from '../Field.jsx';

class DetailsTariffsCustomCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerPrice = this.handlerPrice.bind(this);
    }

    handlerPrice({ action, value }) {
        const { changeTariff } = this.props;

        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                changeTariff({ customPrice: value }).then(resolve);
            }
        });
    }

    render() {
        const { tariff, isCurrent, changeTariff, scrollTopList, price, resultTariff } = this.props;

        return (
            <div
                className={`orderDetailsTariffsCard _col _click _custom ${isCurrent ? '_current' : ''}`}
                onClick={() => {
                    changeTariff({
                        tariff,
                        id: tariff.idOfTariff || resultTariff?.id,
                        idOfConfig: tariff.idOfConfig || resultTariff?.idOfConfig,
                        idOfItem: tariff.id,
                    }).then(() => {
                        scrollTopList();
                    });
                }}
            >
                <div className="orderDetailsTariffsCard__inner">
                    <div className="orderDetailsTariffsCard__action _col">
                        <i className="orderDetailsTariffsCard__actionIcon">
                            <Icon name="done" />
                        </i>
                    </div>
                    <div className="orderDetailsTariffsCard__head _row">
                        <div className="orderDetailsTariffsCard__preview _col">
                            <img
                                src={`${process.env.REACT_APP_STATIC}/images/tariff-fix.png`}
                                alt=""
                                className="orderDetailsTariffsCard__previewImage"
                            />
                        </div>
                        <div className="orderDetailsTariffsCard__headContent _col">
                            <div
                                className={`orderDetailsTariffsCard__name ${
                                    tariff.isPersonal ? '_isPersonal' : ''
                                }`}
                            >
                                Фиксированная цена
                            </div>
                            <div
                                className="orderDetailsTariffsCard__price"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Field
                                    support="0₽"
                                    name="price"
                                    keyName="price"
                                    value={price}
                                    callback={this.handlerPrice}
                                    className={`_manualField _notBack`}
                                    // isEditmode={true}
                                    // error={isError}
                                    // id={id}
                                    isDisabled={!isCurrent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsTariffsCustomCard);

DetailsTariffsCustomCard.propTypes = {
    type: PropTypes.string,
    tariff: PropTypes.object,
    isCurrent: PropTypes.bool,
    changeTariff: PropTypes.func,
    isNds: PropTypes.bool,
    scrollTopList: PropTypes.func,
    price: PropTypes.string,
    resultTariff: PropTypes.object,
};
