import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getModelLogs from '../../requests/getModelLogs';

import Table from './manual/Table.jsx';
import TableInfo from './manual/TableInfo.jsx';

class LogsTable extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    stepCounter = 20;

    getItems() {
        const { setCounter } = this.props;
        const query = this.getQueryForRequest();
        const { modelName, modelId, models } = this.props;

        if (models) {
            query.params.push(
                ...models.map((model) => ({ key: 'models', value: `${model.name}=${model.id}` })),
            );
        } else {
            query.params.push({ key: 'modelName', value: modelName });
            query.params.push({ key: 'modelId', value: modelId });
        }

        return new Promise((resolve) => {
            getModelLogs(query).then(({ logs, isLimit, counter }) => {
                this.setItems(logs, false, isLimit).then(() => {
                    if (setCounter) {
                        setCounter(counter);
                    }

                    resolve();
                });
            });
        });
    }

    render() {
        const {
            isReady,
            isLimit,
            items = [],
            isDisabledScroll,
            isLoadingFilter = false,
        } = this.state;
        const { inPage } = this.props;

        return (
            <TableInfo
                isReady={isReady}
                items={items}
                name="modelLogs"
                getMoreItems={this.getMoreItems}
                isLimit={isLimit}
                stepCounter={this.stepCounter}
                isDisabledScroll={isDisabledScroll}
                isLoading={!isReady || isLoadingFilter}
                isWrap={!!inPage}
                // isRowHeight={true}
                isNotLink={true}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(LogsTable);

LogsTable.propTypes = {
    modelName: PropTypes.string,
    modelId: PropTypes.string,
    setCounter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    user: PropTypes.object,
    inPage: PropTypes.bool,
    models: PropTypes.array,
};
