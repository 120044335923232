import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Editmode from '../../../../../classes/Editmode';

import getModelsOfCar from '../../../../../requests/getModelsOfCar';
import handlerCarForExecutor from '../../../../../requests/handlerCarForExecutor';

import Button from '../../../../../components/Button.jsx';
import AnimateChange from '../../../../../components/AnimateChange.jsx';
import Edit from '../../../../../components/Edit.jsx';
import ListScroll from '../../../../../components/ListScroll.jsx';

import Main from '../../../../../components/crm/manual/car/Main.jsx';
import ListAbsolute from '../../../../../components/ListAbsolute.jsx';

import handlerWindow from '../../../../../functions/handlerWindow';
import removeTransition from '../../../../../functions/removeTransition.ts';
import Stack from '../../../../../classes/Stack';
import scrollToPosition from '../../../../../functions/scrollToPosition';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualExecutorsInnerCars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCar = this.renderCar.bind(this);
        this.handlerList = this.handlerList.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.getSortCars = this.getSortCars.bind(this);
        this.checkEditmode = this.checkEditmode.bind(this);
        this.deleteCar = this.deleteCar.bind(this);
        this.setCarCurrent = this.setCarCurrent.bind(this);
        this.getMoreCars = this.getMoreCars.bind(this);
        this.filterCar = this.filterCar.bind(this);
        this.getParentScroll = this.getParentScroll.bind(this);
        this.setStateLoaderCars = this.setStateLoaderCars.bind(this);

        this.handlerEditmode = this.handlerEditmode.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 4;

    getInfoEmpty() {
        const { executor = {} } = this.props;
        const { infoCars = [] } = executor;

        if (infoCars.length === 0) {
            return {
                title: 'Автомобилей пока нет',
                description: 'Вы можете добавить новый:',
                button: 'Добавить автомобиль',
            };
        }

        return {
            title: 'Больше автомобилей нет',
            description: 'Но вы всегда можете добавить ещё:',
            button: 'Добавить автомобиль',
        };
    }

    getParentScroll() {
        const { getParent } = this.props;

        return getParent().querySelector(
            '.widget__page._manual-executors-inner-cars .widget__pageBox',
        );
    }

    handlerList({ target }) {
        const { getParent, executor = {} } = this.props;
        const { infoCars = [] } = executor;
        const parent = getParent();

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: this.getParentScroll(),
            isHideFromScroll: true,
            target: target.closest('.empty__button'),
            action: 'show',
            name: 'listCars',
            className: '_list',
            uniqKey: executor._id,
            watchesProps: { left: true, top: true },
            idOfExecutor: executor._id,
            cars: infoCars.map((car) => car._id),
            centers: {
                left: 0.5,
                top: 1,
            },
        });
    }

    filterCar(car, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    getCars() {
        const { executor = {} } = this.props;
        const { infoCars = [] } = executor;

        return infoCars.concat(...[{ _id: 'info' }]);
    }

    deleteCar({ idOfCar }) {
        const { executor = {}, scrollToTop } = this.props;
        const { _id: idOfExecutor } = executor;

        return new Promise((resolve) => {
            handlerCarForExecutor({ idOfExecutor, idOfCar, isDelete: true }).then(
                () => {
                    scrollToTop();

                    handlerWindow({
                        action: 'hide',
                        name: 'listCars',
                    });

                    this.handlerEditmode({ editName: null });

                    resolve();
                },
                () => null,
            );
        });
    }

    setCarCurrent({ idOfCar }) {
        const { executor = {} } = this.props;
        const { _id: idOfExecutor } = executor;

        return new Promise((resolve) => {
            handlerCarForExecutor({ idOfExecutor, idOfCar, isCurrent: true }).then(
                () => {
                    this.handlerEditmode({ editName: null });

                    resolve();
                },
                () => null,
            );
        });
    }

    renderCar({ item, prop: _id, key, isShow, params }) {
        const { modelsOfCar, marksOfCar, editName, isLoading, carsLoaderStack } = this.state;
        const { executor, checkRights } = this.props;
        const cars = this.getCars();
        const car = cars.find((carLoop) => carLoop._id === _id) || item;
        const infoEmpty = this.getInfoEmpty();

        return (
            <div
                className={`manualContent__item _parentForEdit _editBack ${_id === 'info' ? '_info' : ''} ${
                    isShow ? '_show' : ''
                } ${_id === editName ? '_current' : ''}`}
                style={{
                    transform: `translate(0,${params?.offsetTop}px)`,
                    order: key,
                }}
                data-_id={_id}
                key={_id}
            >
                <div className="manualContent__itemInner">
                    {_id === 'info' ? (
                        <>
                            <div className="empty _col _block _whiteOpacity">
                                <AnimateChange
                                    className="empty__inner"
                                    type="_translate _transFast"
                                    prop={infoEmpty.title}
                                >
                                    <div className="empty__innerItem">
                                        <div className="empty__title">{infoEmpty.title}</div>
                                        <p className="empty__content">{infoEmpty.description}</p>
                                        <div className="empty__button _show">
                                            <Button
                                                className="_mediumSize _mainNotBorder _fontLight"
                                                onClick={this.handlerList}
                                            >
                                                {infoEmpty.button}
                                            </Button>
                                        </div>
                                    </div>
                                </AnimateChange>
                            </div>
                        </>
                    ) : (
                        <>
                            {checkRights() && (
                                <Edit
                                    name={_id}
                                    className="manualContent__itemEdit"
                                    editName={editName}
                                    handlerEditmode={this.handlerEditmode}
                                    isLoader={isLoading}
                                />
                            )}

                            <Main
                                type="preview"
                                title={blocksManual.car.main.title}
                                executor={executor}
                                car={car}
                                modelsOfCar={modelsOfCar}
                                marksOfCar={marksOfCar}
                                checkEditmode={() => this.checkEditmode(_id)}
                                deleteCar={this.deleteCar}
                                setCarCurrent={this.setCarCurrent}
                                setStateLoader={({ value }) =>
                                    this.setStateLoaderCars({ id: _id, value })
                                }
                                stateLoader={carsLoaderStack?.[_id]}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }

    sortCars(a, b) {
        const weightA = a._id === 'info' ? 1 : 0;
        const weightB = b._id === 'info' ? 1 : 0;

        return weightA - weightB;
    }

    getSortCars(cars) {
        return JSON.parse(JSON.stringify(cars)).sort(this.sortCars);
    }

    setParamsParent(params = {}) {
        const { setHeightPage } = this.props;
        const { height: heightItems } = params;

        if (heightItems !== this.state.heightItems) {
            this.setState({ heightItems }, () => {
                if (!this.isInit) {
                    this.isInit = true;
                    removeTransition({ item: '.manualContent__items' });
                    this.setState({ isInit: true });
                }
                setHeightPage();
            });
        }
    }

    setLoading(isLoading) {
        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    checkEditmode(name) {
        const { editName } = this.state;

        return editName === name;
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualContent__item[data-_id="${id}"]`,
            });
        }
    }

    handlerEditmode({ editName }) {
        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    resolve();
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    getInfo() {
        getModelsOfCar({}).then(
            ({ models }) => {
                getModelsOfCar({ params: [{ key: 'type', value: 'marks' }] }).then(
                    ({ marks }) => {
                        this.setState({ modelsOfCar: models, marksOfCar: marks });
                    },
                    () => null,
                );
            },
            () => null,
        );
    }

    getMoreCars(counter) {
        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                resolve();
                removeTransition({ item: '.manualContent__item', isCurrent: true });
            });
        });
    }

    setStateLoaderCars({ id, value }) {
        this.stackLoaderOrder.setInStack({ context: this, id, value });
    }

    stackLoaderOrder = new Stack({ name: 'carsLoaderStack' });

    componentDidMount() {
        this.editmode = new Editmode({
            context: this,
        });

        this.getInfo();

        const { setHeightPage } = this.props;

        this.setState({ isUpdate: true }, () => {
            setHeightPage();
        });
    }

    render() {
        const { modelsOfCar, marksOfCar, editName, isInit } = this.state;
        let { heightItems = 0 } = this.state;
        const { handlerLoaderList } = this.props;
        const cars = this.getCars();
        const carsFilter = cars.filter(this.filterCar);
        const parentList = this.parent.current?.querySelector('.manualContent__items');
        const isEmpty = cars.length === 0;

        if (isEmpty) {
            heightItems = 258;
        }

        return (
            <div ref={this.parent} className={`manualContent _parentForEdits _isInit ${editName ? '_edit' : ''}`}>
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div
                            className={`manualContent__items ${!isEmpty ? '_show' : ''}`}
                            style={{ height: `${heightItems}px` }}
                        >
                            <div className="manualContent__itemsInner">
                                <ListScroll
                                    isInit={isInit}
                                    getParent={this.getParentScroll}
                                    callback={this.getMoreCars}
                                    startCounter={this.stepCounter}
                                    stepCounter={this.stepCounter}
                                    maxCounter={cars.length}
                                    lengthCurrent={carsFilter.length}
                                    handlerLoaderList={handlerLoaderList}
                                    isLimit={carsFilter.length === cars.length}
                                >
                                    <ListAbsolute
                                        parent={parentList}
                                        items={carsFilter}
                                        renderItem={this.renderCar}
                                        classNameItem="manualContent__item"
                                        prop="_id"
                                        setParamsParent={this.setParamsParent}
                                        modelsOfCar={modelsOfCar}
                                        marksOfCar={marksOfCar}
                                        sort={this.getSortCars}
                                    />
                                </ListScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsInnerCars);

ManualExecutorsInnerCars.propTypes = {
    isInit: PropTypes.bool,
    executor: PropTypes.object,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    scrollToTop: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    checkRights: PropTypes.func,
};
