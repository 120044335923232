export default function getIconMap({ isActive, type }) {
    if (type === 'worker') {
        return !isActive ?
            require(`../img/worker-point.png`) :
            require(`../img/worker-point-complete.png`);
    }

    return !isActive ?
        require(`../img/driver-point.png`) :
        require(`../img/driver-point-complete.png`);
}