import React from 'react';
import PropTypes from 'prop-types';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';

import handlerWindow from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import TableHead from '../../../components/crm/manual/TableHead.jsx';
import PaysLimitsMain from './limits/Main.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import WindowActions from '../../../components/WindowActions.jsx';

class PaysLimits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);

        this.parent = React.createRef();
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'executorLimits' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            if (key === 'pay') {
                newState.editName = value?.name || '';
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderWindowsEditActions({ items, corporation, executor, updateLimits }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'executorLimits',
                        modelId: executor._id,
                    });

                    resolve();
                } else if (key === 'edit') {
                    handlerPopup({
                        name: 'executorLimitsPopup',
                        isShow: true,
                        executorId: executor._id,
                        executorName: executor.fullName,
                        limits: executor.limits,
                        corporationId: corporation._id,
                        corporationName: corporation.name,
                        callback: updateLimits,
                    });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'taxActions',
                    });
                }}
            />
        );
    }

    componentDidMount() {
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
        });
    }

    render() {
        const { filter, infoHead } = this.state;
        const { counterChangePage } = this.props;
        const counter = infoHead?.counter || 0;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="limitsActions" renderContent={this.renderWindowsEditActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Выплаты"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <div className="widget__headNameItemContent">
                                    Лимиты
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                        classNameParent="_parent"
                                    >
                                        <>({counter})</>
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="paysTax"
                                    getParent={() => this.parent.current}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__page _wide _show _full">
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <PaysLimitsMain
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    setInfoHead={this.setInfoHead}
                                    getParent={() => this.parent.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaysLimits;

PaysLimits.propTypes = {
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    executorId: PropTypes.string,
};
