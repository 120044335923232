import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Pages from '../../components/Pages.jsx';

import getRealParams from '../../functions/getRealParams.ts';
import checkRights from '../../functions/crm/checkRights';
import JoinsInvites from './joins/JoinsInvites.jsx';
import JoinsDocs from './joins/JoinsDocs.jsx';
import JoinsScripts from './joins/JoinsScripts.jsx';
import JoinsTemplates from './joins/JoinsTemplates.jsx';
import JoinsContracts from './joins/JoinsContracts.jsx';
import JoinsMvd from './joins/JoinsMvd.jsx';

class Manual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.checkRights = this.checkRights.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'joins-invites': {
            render() {
                const { main } = this.props;

                return (
                    <JoinsInvites
                        setHeightPage={this.setHeightPage}
                        checkRights={this.checkRights}
                        main={main}
                    />
                );
            },
        },
        'joins-scripts': {
            render() {
                return (
                    <JoinsScripts
                        setHeightPage={this.setHeightPage}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        'joins-templates': {
            render() {
                return (
                    <JoinsTemplates
                        setHeightPage={this.setHeightPage}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        'joins-contracts': {
            render() {
                return (
                    <JoinsContracts
                        setHeightPage={this.setHeightPage}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        'joins-mvd': {
            render() {
                return (
                    <JoinsMvd setHeightPage={this.setHeightPage} checkRights={this.checkRights} />
                );
            },
        },
        'joins-docs': {
            render() {
                return (
                    <JoinsDocs setHeightPage={this.setHeightPage} checkRights={this.checkRights} />
                );
            },
        },
    };

    setHeightPage() {
        if (this.parent.current) {
            const page = this.parent.current;

            const { height: heightPage } = getRealParams({
                parent: page,
                elem: '.manual__page._current',
                width: page.offsetWidth,
            });

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    checkRights() {
        const { user } = this.props;

        return checkRights({ user, key: 'joins', isEdit: true });
    }

    componentDidMount() {
        this.setHeightPage();
    }

    componentDidUpdate() {
        this.setHeightPage();
    }

    render() {
        const { heightPage = 0 } = this.state;
        const { main } = this.props;

        return (
            <div ref={this.parent} className="manual">
                <div className="manual__inner" style={{ height: `${heightPage}px` }}>
                    {main ? (
                        <>
                            <div className="manual__page _show _current">
                                {this.pages['joins-invites'].render.call(this)}
                            </div>
                        </>
                    ) : (
                        <>
                            <Pages
                                classNamePage="manual__page"
                                filter={(page) => page.parentName === 'joins' && page.level === 2}
                                pages={this.pages}
                                context={this}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Manual);

Manual.propTypes = {
    user: PropTypes.object,
    main: PropTypes.bool,
};
