import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';

import getFormatedDate from '../../../../functions/getFormatedDate';
import AnimateChangeUp from '../../../../components/AnimateChangeUp.jsx';
import Icon from '../../../../components/Icon.jsx';
import handlerSupportCursor from '../../../../functions/handlerSupportCursor';
import Link from '../../../../components/Link.jsx';
import handlerWindow from '../../../../functions/handlerWindow';
import Avatar from '../../../../components/Avatar.jsx';
import getUserInfo from '../../../../functions/getUserInfo';
import setSpacesInText from '../../../../functions/setSpacesInText';
import CorporationInfo from '../../../../components/crm/manual/CorporationInfo.jsx';
import getDiffTime from '../../../../functions/getDiffTime';
import setPermissions from '../../../../functions/crm/setPermissions';

class JoinsInvitesMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderRow = this.renderRow.bind(this);
        this.handlerMore = this.handlerMore.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    renderStatus({ status }) {
        const { serverData } = this.props;
        const { joinStatuses } = serverData;
        const statusContent = joinStatuses?.[status.name]?.content;

        return (
            <AnimateChangeUp className="settingsUsersTable__joinsStatus" renderKey={status.name}>
                <div className={`settingsUsersTable__joinsStatusInner _row _${status.name}`}>
                    {statusContent}
                    <i
                        className="settingsUsersTable__joinsStatusInfo _click"
                        onMouseEnter={(e) => {
                            handlerSupportCursor({
                                action: 'enter',
                                content: getFormatedDate({
                                    date: new Date(status.date),
                                    type: 'full',
                                }),
                                e,
                                data: { className: '_normalCase _center' },
                            });
                        }}
                        onMouseLeave={(e) => {
                            handlerSupportCursor({ action: 'leave', e });
                        }}
                    >
                        <Icon name="info" />
                    </i>
                </div>
            </AnimateChangeUp>
        );
    }

    getActionsItems({ item }) {
        const items = ['logs'];

        if (item.status === 'wait') {
            items.unshift('smsAgain');
        }

        if (
            !['check', 'complete', 'inactive'].includes(item.status) &&
            this.getPermissions({
                key: 'joins',
                items: [
                    {
                        key: 'invites',
                        rules: ['delete'],
                    },
                ],
            })
        ) {
            items.unshift('deleteJoin');
        }

        return items;
    }

    handlerMore({ target, _id }) {
        const { items = [], getParent } = this.props;
        const parent = getParent();
        const item = items.find((innerItem) => innerItem._id === _id);
        const actionsItems = this.getActionsItems({ item });

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: parent.querySelector('.settingsUsersTable__content'),
            target: target.closest('.settingsUsersTable__more'),
            action: 'show',
            name: 'editActions',
            className: '_actions',
            uniqKey: `joins-${_id}`,
            items: actionsItems,
            joinId: _id,
            phone: item.executorPhone,
            link: item.scriptLink,
            centers: {
                left: 0.5,
                top: 0,
            },
            isHideFromScroll: true,
        });
    }

    renderRow({ item, prop: _id }) {
        const { user } = this.state;

        return (
            <div className={`settingsUsersTable__row`} key={_id} data-id={_id}>
                <div className="settingsUsersTable__rowInner _row">
                    <div className="settingsUsersTable__col _name">
                        <div
                            className={`settingsUsersTable__text _active ${
                                item.chatId ? '_chat' : ''
                            }`}
                        >
                            {item.chatId && (
                                <Link
                                    className="settingsUsersTable__textChat _col"
                                    pageName="chat-inner"
                                    ids={{ 3: item.chatId }}
                                >
                                    <i className="settingsUsersTable__textChatIcon">
                                        <Icon name="telegram" />
                                    </i>
                                </Link>
                            )}
                            <Link
                                className="settingsUsersTable__textInner _link"
                                pageName="manual-executors-inner-main"
                                ids={{ 3: item.executorId }}
                            >
                                {item.executorName || '–'}
                            </Link>
                        </div>
                        <div className="settingsUsersTable__text _active _sub">
                            <div className="settingsUsersTable__textInner">
                                {item.executorPhone}
                            </div>
                        </div>
                        {user.idOfCurrentCorporation === 'admin' && (
                            <div className="settingsUsersTable__text _active">
                                <Link
                                    pageName="joins-scripts-inner-main"
                                    ids={{ 3: item.scriptId }}
                                    className="settingsUsersTable__textInner _link _border"
                                >
                                    <span>{item.scriptName}</span>
                                </Link>
                            </div>
                        )}
                        {item.mvdAlarm && (
                            <div className="settingsUsersTable__text">
                                <div className="settingsUsersTable__textInner">
                                    <div
                                        className="settingsUsersTable__alert _alarm"
                                        onMouseEnter={(e) => {
                                            handlerSupportCursor({
                                                action: 'enter',
                                                content:
                                                    'Необходимо подать уведомление<br/>в МВД в течение 3-х дней после<br/>подписания договора',
                                                e,
                                                data: {
                                                    className: '_normalCase _center',
                                                },
                                            });
                                        }}
                                        onMouseLeave={(e) => {
                                            handlerSupportCursor({
                                                action: 'leave',
                                                e,
                                            });
                                        }}
                                    >
                                        <div className="settingsUsersTable__alertIcon">
                                            <Icon name="alert-2" />
                                        </div>
                                        Необходимо подать уведомление, осталось{' '}
                                        {getDiffTime(item.mvdAlarm)} (До{' '}
                                        {getFormatedDate({ date: new Date(item.mvdAlarm) })})
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {user.idOfCurrentCorporation === 'admin' && (
                        <div className="settingsUsersTable__col _corporationInfo">
                            <CorporationInfo {...(item.corporationInfo?.[0] || [])} />
                        </div>
                    )}
                    {user.idOfCurrentCorporation !== 'admin' && (
                        <div className="settingsUsersTable__col _script">
                            <div className="settingsUsersTable__text _active">
                                <Link
                                    pageName="joins-scripts-inner"
                                    className="settingsUsersTable__textInner _link _border"
                                    ids={{ 3: item.scriptId }}
                                >
                                    <span>{item.scriptName}</span>
                                </Link>
                            </div>
                        </div>
                    )}
                    <div className="settingsUsersTable__col _inviteDate">
                        <div className="settingsUsersTable__text _active">
                            <div className="settingsUsersTable__textInner">
                                {item.createdDate
                                    ? getFormatedDate({
                                          date: new Date(item.createdDate),
                                          type: 'full',
                                      })
                                    : '–'}
                            </div>
                            {item.isNonResident && (
                                <div className="settingsUsersTable__textInner">
                                    <div className="settingsUsersTable__alert">
                                        <div className="settingsUsersTable__alertIcon">
                                            <Icon name="alert-2" />
                                        </div>
                                        Нерезидент
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="settingsUsersTable__col _status">
                        {this.renderStatus({
                            status: item.statuses[item.statuses.length - 1],
                        })}
                    </div>
                    <div className="settingsUsersTable__col _comments">
                        <div className={`settingsUsersTable__comments _col _active`}>
                            {item.executorComments?.length ? (
                                <>
                                    {(item.executorComments || []).map((comment, key) => (
                                        <div className="settingsUsersTable__comment" key={key}>
                                            <div className="settingsUsersTable__commentHead _row">
                                                <div
                                                    className="settingsUsersTable__commentUser"
                                                    onMouseEnter={(e) => {
                                                        handlerSupportCursor({
                                                            action: 'enter',
                                                            content: getUserInfo({
                                                                type: 'name',
                                                                user: comment.creater,
                                                            }),
                                                            e,
                                                            data: {
                                                                className: '_normalCase _center',
                                                            },
                                                        });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        handlerSupportCursor({
                                                            action: 'leave',
                                                            e,
                                                        });
                                                    }}
                                                >
                                                    <Avatar
                                                        className="_circle _white"
                                                        holder={comment.creater}
                                                        src={
                                                            comment.creater?.logo &&
                                                            `${process.env.REACT_APP_STATIC}/corporations/${comment.creater?.logo?.path}`
                                                        }
                                                        iconType={comment.creater?.logo?.type}
                                                    />
                                                </div>
                                                <div className="settingsUsersTable__commentDate">
                                                    {getFormatedDate({
                                                        date: new Date(comment.date),
                                                    })}
                                                </div>
                                            </div>
                                            <div
                                                className="settingsUsersTable__commentContent"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(comment.content),
                                                }}
                                            ></div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                '–'
                            )}

                            <div
                                className="settingsUsersTable__more _click"
                                onClick={({ target }) =>
                                    this.handlerMore({
                                        target,
                                        _id,
                                    })
                                }
                            >
                                <Icon name="more-vertical" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ isInit: true, user: JSON.parse(JSON.stringify(user)) });

        document.dispatchEvent(
            new CustomEvent('changeNavCounter', { detail: { name: 'joins-invites' } }),
        );
    }

    render() {
        const { user, isInit } = this.state;
        const {
            items = [],
            stepCounter,
            getMoreItems,
            isLimit,
            isReady,
            isShowLoaderList = false,
            handlerLoaderList,
            tabs,
            currentTab,
            setTab,
            isLoadingFilter = false,
            counterUpdate,
            serverData,
        } = this.props;
        const joinStatuses = serverData.joinStatuses;

        return (
            <>
                <div ref={this.parent} className="manualContent _joins">
                    <div className="manualContent__inner">
                        <div className="manualContent__content">
                            <div
                                className={`settingsUsersTable _joins ${isReady ? '_init' : ''} ${
                                    isLoadingFilter ? '_processFilter' : ''
                                }`}
                            >
                                <div className="settingsUsersTable__inner">
                                    <Animate
                                        className="settingsUsersTable__loader _loader _full"
                                        isShow={!isReady}
                                    >
                                        <div className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <Animate
                                        className="settingsUsersTable__loader _loader"
                                        isShow={isLoadingFilter}
                                    >
                                        <div className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <div className="settingsUsersTable__wrapper">
                                        <div className="settingsUsersTable__head _static">
                                            <div className="settingsUsersTable__headInner _col">
                                                <div className="settingsUsersTable__tabs">
                                                    {[
                                                        'all',
                                                        ...Object.keys(joinStatuses || {}),
                                                    ].map((name) => {
                                                        const tab = joinStatuses?.[name];
                                                        let content = tab?.content;

                                                        if (name === 'all') {
                                                            content = 'Все';
                                                        }

                                                        return (
                                                            <div
                                                                className={`settingsUsersTable__tab _click ${
                                                                    currentTab === name
                                                                        ? '_current'
                                                                        : ''
                                                                }`}
                                                                key={name}
                                                                onClick={() => {
                                                                    setTab(name);
                                                                }}
                                                            >
                                                                {content}{' '}
                                                                <AnimateChangeUp
                                                                    className="settingsUsersTable__tabCounter"
                                                                    renderKey={tabs?.[name]}
                                                                    hasRenderKey={true}
                                                                    isNotNullParentSize={true}
                                                                >
                                                                    ({tabs?.[name]})
                                                                </AnimateChangeUp>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settingsUsersTable__contentWrapper">
                                            <Animate
                                                className="settingsUsersTable__empty"
                                                isShow={items.length === 0 && !isLoadingFilter}
                                            >
                                                <div className="empty _col _notBack _block">
                                                    <div className="empty__inner">
                                                        <div className="empty__title">
                                                            Подключений пока нет
                                                        </div>
                                                        <p className="empty__content">
                                                            Они будут отображаться здесь в виде
                                                            карточек
                                                        </p>
                                                    </div>
                                                </div>
                                            </Animate>
                                            <div className="settingsUsersTable__content JStransVertical">
                                                <div className="settingsUsersTable__row _head">
                                                    <div className="settingsUsersTable__col _name">
                                                        ФИО
                                                    </div>
                                                    {user?.idOfCurrentCorporation === 'admin' && (
                                                        <div className="settingsUsersTable__col _corporationInfo">
                                                            Корпорация
                                                        </div>
                                                    )}
                                                    {user?.idOfCurrentCorporation !== 'admin' && (
                                                        <div className="settingsUsersTable__col _script">
                                                            Сценарий
                                                        </div>
                                                    )}
                                                    <div className="settingsUsersTable__col _inviteDate">
                                                        Приглашён
                                                    </div>
                                                    <div className="settingsUsersTable__col _status">
                                                        Статус
                                                    </div>
                                                    <div className="settingsUsersTable__col _comments">
                                                        Комментарий
                                                    </div>
                                                </div>
                                                <div className="settingsUsersTable__contentInner">
                                                    <div
                                                        className="settingsUsersTable__rows _col"
                                                        key={counterUpdate}
                                                    >
                                                        {isInit && (
                                                            <ListScroll
                                                                getParent={() =>
                                                                    this.parent.current?.querySelector(
                                                                        '.settingsUsersTable__content',
                                                                    )
                                                                }
                                                                callback={getMoreItems}
                                                                startCounter={stepCounter}
                                                                stepCounter={stepCounter}
                                                                maxCounter={Infinity}
                                                                lengthCurrent={items.length}
                                                                callbackDuration={300}
                                                                isDisabledScroll={
                                                                    !isReady || isLoadingFilter
                                                                }
                                                                hideCallback={this.hideCallback}
                                                                isLimit={isLimit}
                                                                handlerLoaderList={
                                                                    handlerLoaderList
                                                                }
                                                            >
                                                                {items.map((item) =>
                                                                    this.renderRow({
                                                                        item,
                                                                        prop: item._id,
                                                                    }),
                                                                )}
                                                            </ListScroll>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <Animate
                                                className="settingsUsersTable__listLoader _loaderScroll _white"
                                                isShow={isShowLoaderList}
                                            >
                                                <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                                    <Loader className="_main" />
                                                </div>
                                            </Animate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(JoinsInvitesMain);

JoinsInvitesMain.propTypes = {
    items: PropTypes.array,
    stepCounter: PropTypes.number,
    getMoreItems: PropTypes.func,
    isLimit: PropTypes.bool,
    isReady: PropTypes.bool,
    isShowLoaderList: PropTypes.bool,
    handlerLoaderList: PropTypes.func,
    tabs: PropTypes.object,
    currentTab: PropTypes.string,
    setTab: PropTypes.func,
    isLoadingFilter: PropTypes.bool,
    counterUpdate: PropTypes.any,
    updateItemKey: PropTypes.any,
    getParent: PropTypes.func,
    user: PropTypes.object,
    serverData: PropTypes.object,
};
