import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Animate from '../../../../components/Animate.jsx';
import Switch from '../../../../components/Switch.jsx';
import getRoles from '../../../../requests/getRoles';
import Loader from '../../../../components/Loader.jsx';

import getHeaders from '../../../../functions/getHeaders';
import Icon from '../../../../components/Icon.jsx';
import AnimateChangeUp from '../../../../components/AnimateChangeUp.jsx';
import handlerPopup from '../../../../functions/app/handlerPopup';

class SettingsRolesInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setActive = this.setActive.bind(this);
        this.getRole = this.getRole.bind(this);

        this.parent = React.createRef();
    }

    sections = {
        chat: {
            icon: 'role-chat',
        },
        joins: {
            icon: 'role-joins',
        },
        pays: {
            icon: 'role-pays',
        },
        orders: {
            icon: 'role-orders',
        },
        reports: {
            icon: 'role-reports',
        },
        manual: {
            icon: 'role-manual',
        },
        content: {
            icon: 'role-content',
        },
        settings: {
            icon: 'role-settings',
        },
        corporations: {
            icon: 'role-corporations',
        },
    };

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    setActive({ key }) {
        const { role } = this.state;
        const section = role.sections.find((item) => item.key === key);
        const isActive = !section.isActive;

        return new Promise((resolve) => {
            this.handlerLoading(key).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/role`,
                        { id: role._id, fields: { sections: [{ key, isActive }] } },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            this.handlerLoading(null);

                            const { success } = res.data;

                            if (success) {
                                section.isActive = isActive;

                                this.setRole({ role });
                            }

                            resolve();
                        },
                        () => {
                            this.handlerLoading(null);
                        },
                    );
            });
        });
    }

    setRole({ role }) {
        return new Promise((resolve) => {
            this.setState(
                {
                    role,
                    saveRole: JSON.parse(JSON.stringify(role)),
                },
                resolve,
            );
        });
    }

    getRole() {
        const { levels } = this.props;
        const id = levels[3];

        if (id) {
            getRoles({ id }).then(({ role }) => {
                this.setRole({ role }).then(() => {
                    if (!this.state.isReady) {
                        setTimeout(() => {
                            this.setState({ isReady: true });
                        }, 300);
                    }
                });
            });
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getRole();
    }

    render() {
        const { role, isReady, loadingKey } = this.state;

        return (
            <div ref={this.parent} className={`settingsRoles ${isReady ? '_ready' : ''}`}>
                <Animate className="settingsRoles__loader _loader" isShow={!isReady}>
                    <div className="settingsRoles__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="settingsRoles__inner">
                    <div className="settingsRoles__content _col">
                        {role?.sections.map((section) => {
                            const { name } = section;
                            const sectionInfo = this.sections[section.key];
                            const { icon } = sectionInfo;

                            return (
                                <div className="settingsRoles__section _row" key={section.key}>
                                    <div
                                        className="settingsRoles__sectionBlock _col _click _info"
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'rolePopup',
                                                isShow: true,
                                                role,
                                                section,
                                                updateRole: this.getRole,
                                            });
                                        }}
                                    >
                                        <i className="settingsRoles__sectionIcon">
                                            <Icon name={icon} />
                                        </i>
                                        <div className="settingsRoles__sectionName">{name}</div>
                                        <p className="settingsRoles__sectionDescription">
                                            Кликните, чтобы настроить раздел
                                        </p>
                                    </div>
                                    <div className="settingsRoles__sectionBlock _col _actions">
                                        <div className="settingsRoles__sectionSwitch">
                                            <Animate
                                                className="settingsRoles__sectionLoader"
                                                isShow={loadingKey === section.key}
                                            >
                                                <div className="settingsRoles__sectionLoaderItem">
                                                    <Loader className="_main" />
                                                </div>
                                            </Animate>
                                            <Switch
                                                className="_size3 _green"
                                                value={section.isActive}
                                                handler={() => {
                                                    this.setActive({ key: section.key });
                                                }}
                                                isDisabled={!!loadingKey}
                                            />
                                        </div>
                                        <AnimateChangeUp
                                            className="settingsRoles__sectionDescription"
                                            renderKey={section.isActive}
                                        >
                                            {section.isActive
                                                ? 'Отключить раздел'
                                                : 'Включить раздел'}
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsRolesInner);

SettingsRolesInner.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    serverData: PropTypes.object,
    user: PropTypes.object,
};
