import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import getHeaders from '../../../../../functions/getHeaders';
import getUpdateFormData from '../../../../../functions/getUpdateFormData';

import Edit from '../../../../../components/Edit.jsx';
import Button from '../../../../../components/Button.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Link from '../../../../../components/Link.jsx';

import Main from '../../../../../components/crm/manual/car/Main.jsx';
import Params from '../../../../../components/crm/manual/car/Params.jsx';
import Services from '../../../../../components/crm/manual/car/Services.jsx';
import Files from '../../../../../components/crm/manual/car/Files.jsx';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import setNotification from '../../../../../functions/setNotification';
import changePage from '../../../../../functions/changePage';
import setAnimate from '../../../../../functions/setAnimate';
import getRealParams from '../../../../../functions/getRealParams.ts';
import removeTransition from '../../../../../functions/removeTransition.ts';
import getModelsOfCar from '../../../../../requests/getModelsOfCar';
import scrollToPosition from '../../../../../functions/scrollToPosition';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import { dispatcher } from '../../../../../redux/redux';
import getPageLink from '../../../../../functions/getPageLink';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualExecutorsInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.checkEditmode = this.checkEditmode.bind(this);

        this.changeCar = this.changeCar.bind(this);
        this.createCar = this.createCar.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);

        this.parent = React.createRef();
    }

    name = 'car';

    orderCards = ['main', 'params', 'services', 'images'];

    cards = {
        main: {
            changedProps: [
                'bodywork',
                'mark',
                'model',
                'number',
                'status',
                'sts',
                'year',
                'vin',
                'color',
                'perm-mass',
                'full-mass',
            ],
            requiredProps: [
                'mark',
                'model',
                'number',
                'type',
                'status',
                'sts',
                'vin',
                'perm-mass',
                'full-mass',
            ],
            render() {
                const { modelsOfCar, marksOfCar, errors } = this.state;
                const { car, isNew, getParent } = this.props;

                return (
                    <Main
                        title={blocksManual.car.main.title}
                        car={car}
                        checkEditmode={() => this.checkEditmode('main')}
                        isNew={isNew}
                        getParent={getParent}
                        changeCar={({ ...props }) => this.changeCar({ block: 'main', ...props })}
                        modelsOfCar={modelsOfCar}
                        marksOfCar={marksOfCar}
                        errors={errors.main}
                    />
                );
            },
        },
        params: {
            changedProps: [
                'pallets',
                'tonnage',
                'long',
                'width',
                'height',
                'heightWithFloor',
                'heightCargo',
                'loadings',
                'bodywork',
                'passengers',
            ],
            getRequiredProps() {
                const { isNew, car, carSave } = this.props;

                if (
                    car?.type === 'car' && isNew
                        ? car?.bodywork === 'tyagch_pricep'
                        : carSave?.bodywork === 'tyagch_pricep'
                ) {
                    return ['bodywork'];
                }

                return [
                    'pallets',
                    'tonnage',
                    'long',
                    'width',
                    'height',
                    'heightWithFloor',
                    'heightCargo',
                    'loadings',
                    'bodywork',
                ];
            },
            render() {
                const { errors } = this.state;
                const { car, isNew } = this.props;

                return (
                    <Params
                        title={blocksManual.car.params.title}
                        car={car}
                        checkEditmode={() => this.checkEditmode('params')}
                        isNew={isNew}
                        changeCar={({ ...props }) => this.changeCar({ ...props, block: 'params' })}
                        errors={errors.params}
                    />
                );
            },
        },
        services: {
            changedProps: ['services'],
            render() {
                const { car, isNew, handlerServices } = this.props;

                return (
                    <Services
                        title={blocksManual.car.services.title}
                        car={car}
                        checkEditmode={() => this.checkEditmode('services')}
                        isNew={isNew}
                        handlerServices={handlerServices}
                    />
                );
            },
        },
        images: {
            changedProps: ['images'],
            requiredProps: ['period'],
            keyTarget: 'images',
            render() {
                const { errors } = this.state;
                const { car, isNew, uploadFile, deleteFile } = this.props;

                return (
                    <Files
                        title={blocksManual.car.images.title}
                        car={car}
                        checkEditmode={() => this.checkEditmode('images')}
                        isNew={isNew}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                        changeCar={({ ...props }) => this.changeCar({ ...props, block: 'images' })}
                        errors={errors.images}
                    />
                );
            },
        },
    };

    changeCar({ block, ...props }) {
        const { changeCar } = this.props;

        return new Promise((resolve) => {
            changeCar({ ...props }).then(() => {
                if (props.action === 'change' || !props.action) {
                    this.handlerErrors({ action: 'delete', error: props.name, block });
                }

                resolve();
            });
        });
    }

    checkChangeProp({ prop, value, valueWas }) {
        switch (prop) {
            case 'loadings': {
                let isChange = false;

                if (value) {
                    value.forEach((item) => {
                        if (!valueWas.find((itemWas) => itemWas.name === item.name)) {
                            isChange = true;
                        }
                    });

                    valueWas.forEach((itemWas) => {
                        if (!value.find((item) => item.name === itemWas.name)) {
                            isChange = true;
                        }
                    });
                }

                return isChange;
            }
            case 'services': {
                let isChange = false;

                value.forEach((item) => {
                    const itemWas = valueWas.find((itemWasLoop) => itemWasLoop.key === item.key);

                    if (!itemWas) {
                        isChange = true;
                    } else {
                        ['value'].forEach((propLoop) => {
                            if (itemWas[propLoop] !== item[propLoop]) {
                                isChange = true;
                            }
                        });
                        if (itemWas.date && item.date && itemWas.date !== item.date) {
                            isChange = true;
                        }
                    }
                });

                valueWas.forEach((itemWas) => {
                    if (!value.find((item) => item.key === itemWas.key)) {
                        isChange = true;
                    }
                });

                return isChange;
            }
            default:
                return value !== valueWas;
        }
    }

    deepCards = [];

    checkChange(name) {
        const { car, carSave, filesDelete } = this.props;
        const { changedProps } = this.cards[name];
        let isChange = false;
        const fields = {};

        if (name === 'images') {
            car.images.files.forEach((file) => {
                const fileSave = carSave.images.files.find((item) => item.name === file.name);

                if (fileSave.file.path !== file.file.path) {
                    isChange = true;
                }
                if (filesDelete.find((imageDelete) => imageDelete === file.file.name)) {
                    isChange = true;
                }
            });

            if (car.images.period !== carSave.images.period) {
                isChange = true;
                fields.period = car.images.period;
            }
        } else {
            changedProps.forEach((prop) => {
                const target = this.deepCards.indexOf(name) === -1 ? car : car[name];
                const targetWas = this.deepCards.indexOf(name) === -1 ? carSave : carSave[name];

                if (
                    this.checkChangeProp({ prop, value: target[prop], valueWas: targetWas[prop] })
                ) {
                    isChange = true;
                    fields[prop] = target[prop];

                    if (prop === 'services') {
                        fields[prop] = fields[prop].filter(
                            (service) => service.key !== 'tail' || service.value,
                        );
                    }
                }
            });
        }

        return { isChange, fields };
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualContent__card[data-id="${id}"]`,
            });
        }
    }

    handlerEditmode({ editName }) {
        const { levels, formDataFiles, filesDelete, clearFiles } = this.props;
        const id = levels[3];

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const requiredProps =
                        this.cards[this.state.editName].requiredProps ||
                        this.cards[this.state.editName].getRequiredProps?.call(this) ||
                        [];

                    formDataFiles.set('id', id);
                    formDataFiles.set('prop', this.state.editName);
                    formDataFiles.set('filesDelete', JSON.stringify(filesDelete));
                    formDataFiles.set('fields', JSON.stringify(fields));

                    const body =
                        this.state.editName === 'images'
                            ? getUpdateFormData(formDataFiles)
                            : {
                                  id,
                                  prop: this.state.editName,
                                  fields,
                              };

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors } = this.validate({
                            props: requiredProps,
                            nameProp: this.state.editName,
                        });

                        if (!isSuccess) {
                            this.handlerErrors({ action: 'set', errors });
                        } else {
                            this.handlerLoadingBlock(this.state.editName).then(() => {
                                axios
                                    .patch(`${process.env.REACT_APP_API}/car`, body, {
                                        headers: getHeaders(),
                                    })
                                    .then(
                                        (res) => {
                                            const { success, data } = res.data;

                                            if (success) {
                                                setNotification({
                                                    notification: 'success-update-car',
                                                });
                                                if (this.state.editName === 'images') {
                                                    clearFiles();
                                                }

                                                resolve();
                                            } else {
                                                const { message } = data;

                                                if (message === 'Car already register') {
                                                    setNotification({
                                                        notification: 'car-already-reg',
                                                    });

                                                    this.handlerErrors({
                                                        action: 'add',
                                                        error: 'number',
                                                        block: 'main',
                                                    });
                                                }

                                                handlerErrorRequest(res);
                                            }

                                            this.handlerLoadingBlock(null);
                                        },
                                        () => null,
                                    );
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    createCar() {
        const { formDataFiles, car, getCar, forCarExecutorId, forTrailerCarId } = this.props;
        const carNew = JSON.parse(JSON.stringify(car));
        let formData = new FormData();

        carNew.images.files.forEach((file) => {
            file.file = {};
        });

        formData.set('car', JSON.stringify(carNew));

        if (forCarExecutorId) {
            formData.set('executorId', forCarExecutorId);
        }

        if (forTrailerCarId) {
            formData.set('carForTrailerId', forTrailerCarId);
        }

        formData = getUpdateFormData(formDataFiles, formData);

        const { isSuccess, errors } = this.validate({});

        if (!isSuccess) {
            this.handlerErrors({ action: 'set', errors });
        } else {
            this.handlerLoadingSave(true).then(() => {
                axios
                    .post(`${process.env.REACT_APP_API}/car`, formData, { headers: getHeaders() })
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { id } = data;

                                changePage({
                                    href: getPageLink({
                                        name: 'manual-cars-inner',
                                        ids: { 3: id },
                                    }),
                                });

                                setNotification({ notification: 'success-create-car' });

                                const parentScroll = document.querySelector(
                                    '.widget__page._manual-cars-inner-main .widget__pageBox',
                                );
                                const { scrollTop: scrollParent } = parentScroll;

                                setAnimate({
                                    draw: (progress) => {
                                        parentScroll.scrollTo({
                                            top: scrollParent - progress * scrollParent,
                                        });
                                    },
                                    duration: 300,
                                });

                                dispatcher({ type: 'forCarExecutorId', data: null });
                                dispatcher({ type: 'forTrailerCarId', data: null });

                                getCar();
                            } else {
                                const { message } = data;

                                if (message === 'Car already register') {
                                    setNotification({
                                        notification: 'car-already-reg',
                                    });

                                    this.handlerErrors({
                                        action: 'add',
                                        error: 'number',
                                        block: 'main',
                                    });
                                }

                                handlerErrorRequest(res);
                            }

                            this.handlerLoadingSave(false);
                        },
                        () => null,
                    );
            });
        }
    }

    getParams(isStart) {
        const { setHeightPage } = this.props;

        return new Promise((resolve) => {
            const page = this.parent.current;

            if (page) {
                const { offsetWidth: widthPage } = page;

                const params = getRealParams({
                    parent: page,
                    elem: '.manualContent__cards',
                    width: widthPage,
                    classNames: ['_static'],
                    isClearStyles: true,
                });

                if (!this.initCards || isStart) {
                    this.initCards = true;

                    removeTransition({ item: '.manualContent__cards' });
                }

                this.setState({ heightCards: params.height }, () => {
                    setHeightPage();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    getInfo() {
        getModelsOfCar({ params: [{ key: 'forCar', value: true }] }).then(({ models }) => {
            getModelsOfCar({
                params: [
                    { key: 'forCar', value: true },
                    { key: 'type', value: 'marks' },
                ],
            }).then(({ marks }) => {
                this.setState({ modelsOfCar: models, marksOfCar: marks });
            });
        });
    }

    componentDidMount() {
        this.getInfo();

        this.editmode = new Editmode({
            context: this,
        });

        this.getParams(true);
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        if (backToSave) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave, loadingKey } = this.state;
        let { heightCards } = this.state;
        const { isInit, car = {}, isNew, checkRights } = this.props;

        if (!heightCards || !isInit) {
            heightCards = 500;
        }

        return (
            <div
                ref={this.parent}
                className={`manualContent _parentForEdits _car ${editName ? '_edit' : ''} _${car.type}`}
            >
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div
                            className="manualContent__cards _row"
                            style={{ height: `${heightCards}px` }}
                        >
                            {this.props.car &&
                                this.orderCards.map((name, key) => {
                                    const card = this.cards[name];
                                    const zIndex = this.orderCards.length - key + 10;

                                    return (
                                        <div
                                            className={`manualContent__card _parentForEdit _editBack _${name} ${
                                                name === editName || isNew ? '_current' : ''
                                            } _show`}
                                            key={name}
                                            data-id={name}
                                            style={{ zIndex }}
                                        >
                                            {!isNew && checkRights() && (
                                                <Edit
                                                    name={name}
                                                    className="manualContent__cardEdit"
                                                    editName={editName}
                                                    handlerEditmode={this.handlerEditmode}
                                                    isLoader={loadingKey === name}
                                                />
                                            )}

                                            {card.render.call(this)}
                                        </div>
                                    );
                                })}
                        </div>
                        <Animate className="manualContent__actions" isShow={isNew}>
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="manual-executors-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <div className="manualContent__actionsButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.createCar}
                                        showLoader={isLoadingSave}
                                    >
                                        Сохранить данные
                                    </Button>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        forCarExecutorId: state.forCarExecutorId,
        forTrailerCarId: state.forTrailerCarId,
    };
}

export default connect(mapStateToProps)(ManualExecutorsInnerMain);

ManualExecutorsInnerMain.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    car: PropTypes.object,
    carSave: PropTypes.object,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataFiles: PropTypes.object,
    filesDelete: PropTypes.array,
    clearFiles: PropTypes.func,
    isInit: PropTypes.bool,
    backToSave: PropTypes.func,
    isNew: PropTypes.bool,
    getCar: PropTypes.func,
    getParent: PropTypes.func,
    changeCar: PropTypes.func,
    handlerServices: PropTypes.func,
    checkRights: PropTypes.func,
    forCarExecutorId: PropTypes.string,
    forTrailerCarId: PropTypes.string,
};
