import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../../functions/setServerData';
import getEndText from '../../../../functions/getEndText';

import Head from '../card/Head.jsx';
import List from '../card/List.jsx';
import Animate from '../../../Animate.jsx';
import Loader from '../../../Loader.jsx';

class ManualExecutorsServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerServices = this.handlerServices.bind(this);
        this.checkActive = this.checkActive.bind(this);
    }

    checkActive({ name }) {
        const { executor = {} } = this.props;
        const { services = [] } = executor;

        return !!services.find((service) => service.name === name);
    }

    handlerServices({ name }) {
        const { handlerServices } = this.props;

        handlerServices({ name });
    }

    componentDidMount() {
        setServerData('executorServices').then(
            () => {
                this.setState({ isGetServices: true });
            },
            () => null,
        );
    }

    render() {
        const { isGetServices } = this.state;
        const { title, serverData, executor = {}, checkEditmode } = this.props;
        const { services = [] } = executor;
        const { executorServices = [] } = serverData;
        const description = services.length
            ? `${services.length} ${getEndText(services.length, ['услуга', 'услуги', 'услуг'])}`
            : '–';

        return (
            <div className="manualCard _executorServices">
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                </div>
                <div className="manualCard__content _fix">
                    <Animate className="manualCard__contentLoader _loader" isShow={!isGetServices}>
                        <div className="manualCard__contentLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <Animate
                        className="manualCard__contentEmpty"
                        isShow={isGetServices && services.length === 0 && !checkEditmode()}
                    >
                        <div className="empty _col _block _notBack">
                            <div className="empty__inner">
                                <div className="empty__title">Услуги не выбраны</div>
                                <p className="empty__content">
                                    Вы можете добавить их перейдя <br />в режим редактирования
                                </p>
                            </div>
                        </div>
                    </Animate>
                    <Animate className="manualCard__contentList" isShow={isGetServices}>
                        <List
                            items={executorServices}
                            checkActive={this.checkActive}
                            handler={this.handlerServices}
                            prop={services.length}
                            isDisabled={!checkEditmode()}
                            keyProp="name"
                            keyContent="content"
                        />
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualExecutorsServices);

ManualExecutorsServices.propTypes = {
    executor: PropTypes.object,
    title: PropTypes.string,
    type: PropTypes.string,
    serverData: PropTypes.object,
    handlerServices: PropTypes.func,
    checkEditmode: PropTypes.func,
};
