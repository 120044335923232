import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import Editmode from '../../../classes/Editmode';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

import MainDefault from '../../../components/crm/manual/Main.jsx';
import Main from '../../../components/crm/profile/Main.jsx';
import Avatar from '../../../components/crm/profile/Avatar.jsx';
import Edit from '../../../components/Edit.jsx';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';
import Passport from '../../../components/crm/manual/executor/Passport.jsx';

class ProfileMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.changeUser = this.changeUser.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
    }

    name = 'user';

    orderCards = ['avatar', 'main', 'passport'];

    cards = {
        avatar: {
            getChangedProps() {
                return ['icon'];
            },
            getRequiredProps() {
                return [];
            },
            render() {
                const { errors } = this.state;
                const { user } = this.props;

                return (
                    <>
                        <Avatar
                            user={user}
                            checkEditmode={() => this.checkEditmode('avatar')}
                            changeUser={({ ...props }) =>
                                new Promise((resolve) => {
                                    this.changeUser({ ...props }).then(resolve);
                                })
                            }
                            errors={errors.avatar}
                        />
                    </>
                );
            },
        },
        main: {
            getChangedProps() {
                return [
                    'firstName',
                    'secondName',
                    'thirdName',
                    'phone',
                    { name: 'password', validate: 'password' },
                ];
            },
            getRequiredProps() {
                return ['firstName', 'secondName', 'thirdName', 'email'];
            },
            render() {
                const { errors } = this.state;
                const { user } = this.props;

                return (
                    <Main
                        user={user}
                        checkEditmode={() => this.checkEditmode('main')}
                        changeUser={({ ...props }) =>
                            new Promise((resolve) => {
                                this.changeUser({ ...props }).then(resolve);
                            })
                        }
                        errors={errors.main}
                    />
                );
            },
        },
        passport: {
            keyTarget: 'passport',
            getChangedProps() {
                return [
                    'country',
                    'series',
                    'number',
                    'view',
                    'dateAction',
                    'dateGet',
                    'code',
                    'birthday',
                    'nameGet',
                    'addressReg',
                    'addressFact',
                ];
            },
            getRequiredProps() {
                return ['series', 'number', 'view', 'dateGet', 'birthday', 'nameGet', 'addressReg'];
            },
            render() {
                const { errors } = this.state;
                const { user } = this.props;

                return (
                    <Passport
                        title="Паспорт"
                        executor={user}
                        changeExecutor={({ ...props }) =>
                            new Promise((resolve) => {
                                this.changeUser({ block: 'passport', ...props }).then(resolve);
                            })
                        }
                        checkEditmode={() => this.checkEditmode('passport')}
                        errors={errors.passport}
                    />
                );
            },
        },
    };

    changeUser({ block, ...props }) {
        const { changeUser } = this.props;

        return new Promise((resolve) => {
            changeUser({ block, ...props }).then(() => {
                this.handlerErrors({ action: 'delete', error: props.name, block });

                resolve();
            });
        });
    }

    checkChange(name) {
        const { user, userSave } = this.props;
        const card = this.cards[name];
        const { keyTarget } = card;
        const changedProps = card.getChangedProps.call(this);
        let isChange = false;
        const fields = {};

        const target = keyTarget ? user[keyTarget] : user;
        const targetSave = keyTarget ? userSave[keyTarget] : userSave;
        const targetFields = fields;

        const setFields = (prop) => {
            isChange = true;
            targetFields[prop] = target[prop];
        };

        changedProps.forEach((item) => {
            const prop = typeof item === 'object' ? item.name : item;

            if (target[prop] !== targetSave[prop]) {
                setFields(prop);
            }
        });

        return { isChange, fields };
    }

    editmode = new Editmode({
        context: this,
    });

    handlerEditmode({ editName }) {
        const { getUser, setEditName, user, inner, globalUser } = this.props;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else {
                    const card = this.cards[this.state.editName];
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const requiredProps = card?.getChangedProps();

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors } = this.validate({
                            props: requiredProps,
                            nameProp: this.state.editName,
                        });

                        if (!isSuccess) {
                            this.handlerErrors({ action: 'set', errors });
                        } else {
                            this.handlerLoadingBlock(this.state.editName).then(() => {
                                axios
                                    .patch(
                                        `${process.env.REACT_APP_API}/user`,
                                        {
                                            fields,
                                            blockName: card.keyTarget,
                                            ...(inner && globalUser?._id !== user._id
                                                ? { id: user._id }
                                                : {}),
                                        },
                                        {
                                            headers: getHeaders(),
                                        },
                                    )
                                    .then((res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'success-change-info',
                                            });

                                            if (typeof getUser === 'function') {
                                                getUser();
                                            }

                                            resolve();
                                        } else {
                                            const { message } = data;

                                            if (message === 'User already register') {
                                                setNotification({
                                                    notification: 'user-email-already-use',
                                                });

                                                this.handlerErrors({
                                                    action: 'add',
                                                    error: 'email',
                                                    block: 'main',
                                                });
                                            } else if (message === 'Phone already use') {
                                                setNotification({
                                                    notification: 'user-phone-already-use',
                                                });

                                                this.handlerErrors({
                                                    action: 'add',
                                                    error: 'phone',
                                                    block: 'main',
                                                });
                                            }

                                            handlerErrorRequest(res);
                                        }

                                        this.handlerLoadingBlock(null);
                                    });
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName }).then(() => {
                if (typeof setEditName === 'function') {
                    setEditName({ editName });
                }
            });
        });
    }

    componentDidMount() {
        const { setHandlerEditmode } = this.props;

        if (typeof setHandlerEditmode === 'function') {
            setHandlerEditmode(this.handlerEditmode);
        }
    }

    render() {
        const { editName, loadingKey } = this.state;
        const { user } = this.props;

        return (
            <div
                ref={this.parent}
                className={`manualContent _parentForEdits ${editName ? '_edit' : ''}`}
            >
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div className="manualContent__cards _row">
                            {this.orderCards.map((name) => {
                                const card = this.cards[name];

                                return (
                                    <div
                                        className={`manualContent__card _parentForEdit _editBack ${
                                            editName === name ? '_current' : ''
                                        } _${name}`}
                                        key={name}
                                    >
                                        {(!user?.hasVerificationCorporation ||
                                            name !== 'passport') && (
                                            <Edit
                                                name={name}
                                                className="manualContent__cardEdit"
                                                editName={editName}
                                                handlerEditmode={this.handlerEditmode}
                                                isLoader={loadingKey === name}
                                            />
                                        )}

                                        {card.render.call(this)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileMain;

ProfileMain.propTypes = {
    changeUser: PropTypes.func,
    user: PropTypes.object,
    userSave: PropTypes.object,
    getUser: PropTypes.func,
};
