import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PaysMain from './pays/Main.jsx';
import PaysGroups from './pays/Groups.jsx';

import Pages from '../../components/Pages.jsx';
import checkRights from '../../functions/crm/checkRights';
import PaysTax from './pays/Tax.jsx';
import PaysLimits from './pays/Limits.jsx';

class Pays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.checkRights = this.checkRights.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'pays-main': {
            render() {
                return <PaysMain checkRights={this.checkRights} />;
            },
        },
        'pays-groups': {
            render() {
                const { counterChangePage } = this.props;

                return (
                    <PaysGroups
                        counterChangePage={counterChangePage}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        'pays-tax': {
            render() {
                return <PaysTax checkRights={this.checkRights} />;
            },
        },
        'pays-limits': {
            render() {
                return <PaysLimits checkRights={this.checkRights} />;
            },
        },
    };

    checkRights() {
        const { user } = this.props;

        return checkRights({ user, key: 'pays', isEdit: true });
    }

    render() {
        return (
            <div ref={this.parent} className="manual">
                <div className="manual__inner">
                    <Pages
                        classNamePage="manual__page"
                        filter={(page) => page.parentName === 'pays'}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Pays);

Pays.propTypes = {
    user: PropTypes.object,
    counterChangePage: PropTypes.number,
};
