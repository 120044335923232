import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsolute from '../../../../ListAbsolute.jsx';

import Item from './Item.jsx';
import Animate from '../../../../Animate.jsx';
import AnimateChange from '../../../../AnimateChange.jsx';

// import removeTransition from '../../../../../functions/removeTransition.ts';

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.setHeightCards = this.setHeightCards.bind(this);

        this.parent = React.createRef();
    }

    getInfoEmpty() {
        const { category, type, name } = this.props;

        if (!category) {
            return {
                title: 'Выберите категорию',
                description: 'Необходимо выбрать категорию из списка слева',
            };
        }

        if (
            type === 'car' &&
            ((name !== (+name).toFixed(1) && name !== (+name).toFixed(2)) || +name === 0)
        ) {
            return {
                title: 'Укажите название',
                description: 'В левом верхнем углу карточки',
            };
        }

        return {
            title: 'Выберите тип кузова',
            description: 'Вы можете выбрать его по кнопке «+ Тип кузова»',
        };
    }

    setHeightCards({ height: heightCards }) {
        const { setStateConfigurationsHeight } = this.props;

        if (heightCards && heightCards !== this.state.heightCards) {
            this.setState({ heightCards }, () => {
                setStateConfigurationsHeight(heightCards);
            });
        }
    }

    renderCard({ item: itemCome, prop: _id, isShow }) {
        const { idOfCurrentItem } = this.state;
        const {
            items = [],
            setStateConfiguration,
            handlerStateServices,
            servicesState,
            isEdit,
            handlerConfigurationItem,
            parentScroll,
            errors,
        } = this.props;
        const indexCurrent = items.findIndex((itemLoop) => itemLoop._id === idOfCurrentItem);
        const index = items.findIndex((itemLoop) => itemLoop._id === _id);
        const item = items[index] || itemCome;

        let stateHide = '';

        if (items.length > 1) {
            if (index < indexCurrent) {
                stateHide = '_prev';
            }
            if (index > indexCurrent) {
                stateHide = '_next';
            }
        }

        return (
            <div
                className={`manualTariffsConfigurationItems__card ${
                    isShow ? '_show' : ''
                } ${stateHide}`}
                key={_id}
                data-_id={_id}
            >
                <Item
                    {...item}
                    type={item.typeConfig}
                    setStateConfiguration={setStateConfiguration}
                    handlerServices={handlerStateServices}
                    isShowServices={servicesState}
                    isEdit={isEdit}
                    handlerConfigurationItem={(props) =>
                        new Promise((resolve) => {
                            handlerConfigurationItem({ idOfItem: _id, ...props }).then(resolve);
                        })
                    }
                    parentScroll={parentScroll}
                    errors={errors?.[_id]}
                />
            </div>
        );
    }

    checkChangeCurrent() {
        const { idOfCurrentItem } = this.props;

        if (idOfCurrentItem !== this.state.idOfCurrentItem) {
            setTimeout(() => {
                this.setState({ idOfCurrentItem });
            }, 10);
        }
    }

    componentDidMount() {
        this.setState({ isInit: true });
        this.checkChangeCurrent();
    }

    componentDidUpdate() {
        this.checkChangeCurrent();
    }

    render() {
        let { heightContent } = this.props;
        const { isInitItems } = this.state;

        const {
            idOfCurrentItem,
            items = [],
            configurationStackCounter,
            idOfConfig,
            isInit,
        } = this.props;
        const itemsResult = idOfCurrentItem
            ? items.filter((item) => item._id === idOfCurrentItem)
            : [];

        if (itemsResult.length === 0 || !heightContent) {
            heightContent = 148;
        }

        const infoEmpty = this.getInfoEmpty();

        return (
            <div ref={this.parent} className="manualTariffsConfigurationItems">
                <Animate
                    className="manualTariffsConfigurationItems__empty"
                    isShow={itemsResult.length === 0 && isInit}
                    style={{ height: `${heightContent}px` }}
                >
                    <div className="empty _col _block _notBack">
                        <AnimateChange
                            className="empty__inner"
                            type="_translate _transFast"
                            prop={infoEmpty.title}
                        >
                            <div className="empty__innerItem">
                                <div className="empty__title">{infoEmpty.title}</div>
                                <p
                                    className="empty__content"
                                    dangerouslySetInnerHTML={{
                                        __html: infoEmpty.description,
                                    }}
                                ></p>
                            </div>
                        </AnimateChange>
                    </div>
                </Animate>
                <div
                    className={`manualTariffsConfigurationItems__cards ${
                        isInitItems ? '_show' : ''
                    }`}
                    style={{ height: `${heightContent}px` }}
                >
                    <ListAbsolute
                        parent={(() =>
                            this.parent.current?.querySelector(
                                '.manualTariffsConfigurationItems__cards',
                            ))()}
                        items={itemsResult}
                        renderItem={this.renderCard}
                        classNameItem="manualTariffsConfigurationItems__card"
                        prop="_id"
                        setParamsParent={this.setHeightCards}
                        keyRender={`${configurationStackCounter}${idOfCurrentItem}`}
                        keyUpdateItem={JSON.stringify(itemsResult)}
                        // name="test"
                        paramsParent={{ width: true }}
                        callback={() => {
                            if (!this.state.isInitItems) {
                                setTimeout(() => {
                                    this.setState({ isInitItems: true });
                                }, 10);
                            }
                        }}
                        // name="configurationItem"
                        id={`configuration-${idOfConfig}`}
                        withoutParams={true}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Items);

Items.propTypes = {
    idOfConfig: PropTypes.string,
    idOfCurrentItem: PropTypes.string,
    items: PropTypes.array,
    setCurrentItem: PropTypes.func,
    name: PropTypes.string,
    type: PropTypes.string,
    setStateConfiguration: PropTypes.func,
    configurationStackCounter: PropTypes.number,
    setStateConfigurationsHeight: PropTypes.func,
    heightContent: PropTypes.number,
    handlerStateServices: PropTypes.func,
    servicesState: PropTypes.bool,
    isInit: PropTypes.bool,
    isEdit: PropTypes.bool,
    handlerConfigurationItem: PropTypes.func,
    category: PropTypes.string,
    parentScroll: PropTypes.object,
    errors: PropTypes.object,
};
