import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UploadOrdersPopup from './UploadOrdersPopup.jsx';
import Animate from '../../Animate.jsx';

class UploadOrdersPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isUploadOrdersPopupShow } = this.props;

        return (
            <Animate className="body__uploadOrdersPopup" isShow={isUploadOrdersPopupShow}>
                <UploadOrdersPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        isUploadOrdersPopupShow: state.isUploadOrdersPopupShow,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopupWrapper);

UploadOrdersPopupWrapper.propTypes = {
    isUploadOrdersPopupShow: PropTypes.bool,
};
