import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../Icon.jsx';
import ListDynamic from '../../../ListDynamic.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import removeTransition from '../../../../functions/removeTransition.ts';
import handlerSupportCursor from '../../../../functions/handlerSupportCursor';
import getUserInfo from '../../../../functions/getUserInfo';
import getFormatedDate from '../../../../functions/getFormatedDate';

import Animate from '../../../Animate.jsx';
import Avatar from '../../../Avatar.jsx';

class ManualComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderComment = this.renderComment.bind(this);
        this.getParamsItems = this.getParamsItems.bind(this);
        this.getParams = this.getParams.bind(this);

        this.parent = React.createRef();
    }

    getParams() {
        return new Promise((resolve) => {
            const page = this.parent.current;

            if (page) {
                const { offsetWidth: widthPage } = page;

                const { height: heightContent } = getRealParams({
                    parent: page,
                    elem: '.manualComments__innerContent',
                    width: widthPage,
                    classNames: ['_static'],
                    isClearStyleParent: true,
                    clearStyleElems: ['.manualComments__innerContent'],
                    // isNotRemove: true,
                });

                this.setState({ heightContent }, () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    getParamsItems() {
        const { comments = [] } = this.props;
        const items = comments;

        return new Promise((resolve) => {
            const page = this.parent.current;
            const resultParams = this.state.resultParams || {};

            if (page) {
                const { offsetWidth: widthPage } = page;

                const elems = items
                    .map((item) => ({
                        className: `.manualComments__item[data-id="${item._id}"]`,
                        id: item._id,
                    }))
                    .concat(...[{ className: '.manualComments__items', id: 'items' }]);

                const params = getRealParams({
                    parent: page,
                    elems,
                    width: widthPage,
                    classNames: ['_static'],
                    isClearStyleParent: true,
                    clearStyleElems: ['.manualComments__items'],
                });

                items.forEach((item) => {
                    if (params[item._id]) {
                        const { offsetTop, width, offsetLeft } = params[item._id];

                        resultParams[item._id] = {
                            width,
                            offsetTop,
                            offsetLeft,
                        };
                    }
                });

                this.setState({ resultParams, heightItems: params.items.height }, () => {
                    this.getParams();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    setHeightInput({ _id }) {
        const { change } = this.props;
        const item = this.parent.current.querySelector(`.manualComments__item[data-id="${_id}"]`);

        if (item) {
            const { offsetWidth: widthItem } = item;

            const { scrollHeight } = getRealParams({
                parent: item,
                elem: '.manualComments__itemInput',
                width: widthItem,
                isClearStyles: true,
            });

            removeTransition({
                item: `.manualComments__item[data-id="${_id}"] .manualComments__itemInput`,
            });

            change({ _id, props: { scrollHeight } }).then(this.getParamsItems);
        }
    }

    renderComment({ item, prop: _id, isShow }) {
        const { resultParams } = this.state;
        const resultParam = resultParams?.[_id];
        const { comments, change, isDisabled, deleteComment } = this.props;
        const comment = comments.find((commentThis) => commentThis._id === _id) || item;
        const { content, scrollHeight, creater } = comment;
        let descriptionOfCreater = creater
            ? `${getUserInfo({ type: 'name', user: creater })}`
            : null;
        const avatar = {};

        if (comment.idOfCreater === 'system') {
            descriptionOfCreater = `Система ${creater.name}`;
            avatar.src = `${process.env.REACT_APP_STATIC}/corporations/${creater.logo.path}`;
            avatar.type = creater.logo.type;
        }

        return (
            <Animate
                className="manualComments__item"
                isShow={isShow}
                key={_id}
                data-id={_id}
                actionInit={() => {
                    this.getParamsItems();
                    this.setHeightInput({ _id });
                }}
                actionPrevRemove={this.getParamsItems}
                style={{
                    transform: `translate(${resultParam?.offsetLeft}px,${resultParam?.offsetTop}px)`,
                }}
            >
                <div
                    className="manualComments__itemInner _row"
                    style={scrollHeight ? { height: `${scrollHeight}px` } : {}}
                >
                    <textarea
                        className="manualComments__itemInput _col"
                        value={content}
                        onChange={({ target: { value } }) =>
                            change({
                                _id,
                                props: { content: value },
                            }).then(() => {
                                this.setHeightInput({ _id });
                            })
                        }
                        onFocus={({ target }) => {
                            if (isDisabled) {
                                target.blur();
                            }
                        }}
                        style={scrollHeight ? { height: `${scrollHeight}px` } : {}}
                        readOnly={isDisabled}
                        tabIndex={isDisabled && -1}
                    />
                    <div className="manualComments__itemInfo _row">
                        <Animate
                            className="manualComments__itemUser"
                            isShow={isDisabled}
                            onMouseEnter={(e) => {
                                handlerSupportCursor({
                                    action: 'enter',
                                    content: descriptionOfCreater,
                                    e,
                                    data: { className: '_normalCase _center' },
                                });
                            }}
                            onMouseLeave={(e) => {
                                handlerSupportCursor({ action: 'leave', e });
                            }}
                        >
                            <Avatar
                                className="_circle _white"
                                holder={{
                                    ...comment.creater,
                                }}
                                src={avatar.src}
                                iconType={avatar.type}
                            />
                        </Animate>
                        <Animate
                            className="manualComments__itemAction"
                            isShow={!isDisabled}
                            onClick={() => {
                                deleteComment({ id: _id });
                            }}
                        >
                            <div className="action _col _click _delete">
                                <i className="action__icon">
                                    <Icon name="delete" />
                                </i>
                            </div>
                        </Animate>
                    </div>
                </div>
                <Animate className="manualComments__itemDate" isShow={comment.date}>
                    {getFormatedDate({ date: new Date(comment.date), type: 'full' })}
                </Animate>
            </Animate>
        );
    }

    checkHeightInputs() {
        const { comments = [] } = this.props;

        comments.forEach((comment) => {
            if (!comment.scrollHeight) {
                this.setHeightInput({ _id: comment._id });
            }
        });
    }

    componentDidUpdate() {
        this.checkHeightInputs();
    }

    render() {
        const { resultParams, heightContent = 0, heightItems = 0 } = this.state;
        const { comments = [], add, isDisabled } = this.props;

        return (
            <div ref={this.parent} className={`manualComments ${isDisabled ? '_isDisabled' : ''}`}>
                <div className="manualComments__inner">
                    <div
                        className="manualComments__innerContent"
                        style={{ height: `${heightContent}px` }}
                    >
                        <div
                            className="manualComments__items"
                            style={{ height: `${heightItems}px` }}
                        >
                            <div className="manualComments__itemsInner">
                                <ListDynamic
                                    items={comments}
                                    renderItem={this.renderComment}
                                    callback={this.getParamsItems}
                                    prop="_id"
                                    propsForRender={{ resultParams }}
                                    propsForUpdate={['content']}
                                    keyUpdate={isDisabled}
                                />
                            </div>
                        </div>
                        <Animate
                            className="manualComments__btn"
                            isShow={!isDisabled}
                            actionInit={this.getParams}
                            actionPrevRemove={this.getParams}
                        >
                            <div className="manualComments__btnInner _row">
                                <div className="manualComments__btnContent">
                                    Добавить новый комментарий
                                </div>
                                <div className="manualComments__btnAction" onClick={() => add()}>
                                    <div className="action _col _click _add">
                                        <i className="action__icon">
                                            <Icon name="add" />
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualComments);

ManualComments.propTypes = {
    comments: PropTypes.array,
    add: PropTypes.func,
    change: PropTypes.func,
    isDisabled: PropTypes.bool,
    deleteComment: PropTypes.func,
};
