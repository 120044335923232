import React from 'react';

import { store } from '@redux/redux';

import change from './methods/change.ts';
import changeReg from './methods/changeReg.ts';
import changeRegExp from './methods/changeRegExp.ts';
import checkSpecChar from './methods/checkSpecChar.ts';
import clearValue from './methods/clearValue.ts';
import focus from './methods/focus.ts';
import init from './methods/init.ts';
import resize from './methods/resize.ts';
import savePos from './methods/savePos.ts';
import setAreaHeight from './methods/setAreaHeight.ts';
import setValue from './methods/setValue.ts';
import validateReg from './methods/validateReg.ts';

import InputI from './types.ts';

import regs from './static/reg.ts';

class Input extends React.Component<InputI['props'], InputI['state']> implements InputI {
    input: React.RefObject<HTMLInputElement & HTMLTextAreaElement>;
    parent: React.RefObject<HTMLDivElement>;
    isInit?: boolean;
    updatedKey?: any;

    constructor(props: InputI['props']) {
        super(props);
        this.state = {};

        this.resize = this.resize.bind(this);

        this.input = React.createRef();
        this.parent = React.createRef();
    }

    regs = regs;
    pressShift = false;

    savePos = savePos;
    checkSpecChar = checkSpecChar;
    validateReg = validateReg;
    changeReg = changeReg;
    setAreaHeight = setAreaHeight;
    changeRegExp = changeRegExp;
    change = change;
    focus = focus;
    clearValue = clearValue;
    setValue = setValue;
    init = init;
    resize = resize;

    componentDidMount() {
        const { name, updatedKey, calcHeight } = this.props;

        this.updatedKey = updatedKey;

        this.init();

        this.setState({ id: `${name}-${new Date().getTime().toString().slice(-3)}` });

        setTimeout(() => {
            this.setAreaHeight();
        }, 10);

        if (calcHeight) {
            window.addEventListener('resize', this.resize);
        }
    }

    componentDidUpdate() {
        const { updatedKey } = this.props;

        this.init();

        if (updatedKey !== this.updatedKey) {
            this.updatedKey = updatedKey;

            this.init(true);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const { isFocus, id } = this.state;
        const {
            textarea,
            support,
            disabled,
            onClick,
            className = '',
            error,
            withoutLabel,
            isShowSupportInFocus,
        } = this.props;
        const InputTag = textarea ? 'textarea' : 'input';
        const LabelTag = withoutLabel ? 'div' : 'label';
        const value = this.props.reg || this.props.concat ? this.state.value : this.props.value;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`v2Input ${!isShowSupportInFocus && isFocus ? '_focus' : ''} ${
                        value === null || value === undefined || value === '' ? '_empty' : ''
                    } ${textarea ? '_area' : ''} ${className} ${error ? '_error' : ''} ${disabled ? '_disabled' : ''}`}
                >
                    {support && (
                        <LabelTag className="v2Input__support" htmlFor={id}>
                            {support}
                        </LabelTag>
                    )}
                    <InputTag
                        type="text"
                        onFocus={this.focus.bind(this, true)}
                        onBlur={this.focus.bind(this, false)}
                        onChange={this.change.bind(this)}
                        value={value}
                        id={id}
                        className="v2Input__field _NOSCROLL"
                        disabled={disabled}
                        onClick={onClick}
                        ref={this.input}
                        onKeyDown={(e) => {
                            const device = store.getState().device;

                            if (device === 'desktop') {
                                if (e.which === 16) {
                                    this.pressShift = true;
                                }

                                if (!this.pressShift && e.which === 13) {
                                    e.preventDefault();
                                }
                            }

                            this.savePos();
                        }}
                        onKeyUp={(e) => {
                            const device = store.getState().device;

                            if (device === 'desktop') {
                                if (e.which === 16) {
                                    this.pressShift = false;
                                }
                            }
                        }}
                        rows={1}
                    />
                </div>
            </>
        );
    }
}

export default Input;
