import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getExecutorBalances from '../../../../../requests/getExecutorBalances';

import TableInfo from '../../../../../components/crm/manual/TableInfo.jsx';
import Table from '../../../../../components/crm/manual/Table.jsx';

class ManualExecutorsInnerLogsBalance extends Table {
    constructor(props) {
        super(props);
        this.state = {
            counterScroll: 0,
        };
    }

    stepCounter = 15;

    getQueryForRequest() {
        const { executor } = this.props;
        const query = super.getQueryForRequest();

        query.params.push({ key: 'executorId', value: executor?._id });

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getExecutorBalances(query).then(
                ({ balances, isLimit, counter }) => {
                    this.setItems(balances, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    render() {
        const { items = [], isLimit, isDisabledScroll, isReady, isLoadingFilter } = this.state;

        return (
            <div ref={this.parent} className="manualContent _full _executor _isInit _notPadding">
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <TableInfo
                            isReady={isReady}
                            items={items}
                            name="executorsBalance"
                            getMoreItems={this.getMoreItems}
                            isLimit={isLimit}
                            stepCounter={this.stepCounter}
                            isDisabledScroll={isDisabledScroll}
                            isLoading={!isReady || isLoadingFilter}
                            isWrap={true}
                            // isRowHeight={true}
                            isNotLink={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsInnerLogsBalance);

ManualExecutorsInnerLogsBalance.propTypes = {
    isInit: PropTypes.bool,
    executor: PropTypes.object,
    setHeightPage: PropTypes.func,
    handlerLoaderList: PropTypes.func,
};
