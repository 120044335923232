import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../../manual/card/Head.jsx';
import Main from '../../../../views/crm/settings/Main.jsx';

class SettingsDocsMain extends Main {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div className="manualCard">
                    <div className="manualCard__head _row">
                        <Head title="Юр. данные" description={this.getMainInfo()} />
                    </div>
                    <div className="manualCard__content">
                        <div className="settingsMain__fieldsWrapper">
                            {this.renderMain()}
                            {this.renderSub({
                                fieldsOrder: ['kpp', 'fullName'],
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsDocsMain);

SettingsDocsMain.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    setHeightPage: PropTypes.func,
    inner: PropTypes.bool,
    setEditName: PropTypes.func,
    setHandlerEditmode: PropTypes.func,
    setLoadingKey: PropTypes.func,
};
