import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getFormatPrice from '../../functions/getFormatPrice';
import setServerData from '../../functions/setServerData';

class InfoAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderPrice = this.renderPrice.bind(this);
    }

    renderName(key) {
        const { serverData, order, mkad, mkadToPoints, counterTime, nameOfTariff } = this.props;
        const { orderServicesInfo } = serverData;
        let name = ``;

        if (orderServicesInfo) {
            const currentInfo = orderServicesInfo.find((item) => item.key === key);

            name += currentInfo.name;

            if (currentInfo.description || currentInfo.descriptions) {
                name += `: `;

                switch (key) {
                    case 'tariff':
                        if (order.systemType === 'service') {
                            name += nameOfTariff;
                        } else if (order.tariffInfo._id === 'custom') {
                            name += 'Фиксированная цена';
                        } else if (order.tariffInfo) {
                            switch (order.tariffInfo.typeConfig) {
                                case 'car':
                                    name += currentInfo.descriptions.car
                                        .replace('|name|', `${order.tariffInfo.nameConfig}т`)
                                        .replace('|duration|', `${counterTime}`);
                                    break;
                                case 'worker':
                                    name += currentInfo.descriptions.worker
                                        .replace('|counter|', `${order.counterWorkers}`)
                                        .replace('|duration|', `${counterTime}`);
                                    break;
                                default:
                                    break;
                            }
                        }

                        break;
                    case 'mkad':
                        name += currentInfo.description.replace('|distance|', mkad);
                        break;
                    case 'mkadToPoints':
                        name += currentInfo.description.replace('|distance|', mkadToPoints);
                        break;
                    case 'workers':
                        {
                            const workerServices = order.services.find(
                                (service) => service.key === 'workers',
                            );

                            name += currentInfo.description
                                .replace('|duration|', counterTime)
                                .replace('|counter|', workerServices.value);
                        }
                        break;
                    case 'passengers':
                        {
                            const passengersService = order.services.find(
                                (service) => service.key === 'passengers',
                            );

                            name += currentInfo.description.replace(
                                '|counter|',
                                passengersService.value,
                            );
                        }
                        break;
                    case 'commission':
                        {
                            const resultSystemType =
                                order.systemType === 'service'
                                    ? 'сторонний заказ'
                                    : 'заказ LIVECARGO';

                            name += currentInfo.description.replace(
                                '|systemType|',
                                resultSystemType,
                            );
                        }
                        break;
                    default:
                        name += currentInfo.description.replace('|duration|', order.counterTime);
                        break;
                }
            }
        }

        return name;
    }

    renderPrice({ price, key }) {
        const { order } = this.props;
        const { corporationTariff } = order;

        if (!price || typeof price === 'string') {
            return 'Бесплатно';
        }

        let priceString = `${getFormatPrice(price)}₽`;

        if (
            key === 'commission' &&
            order.systemType === 'service' &&
            corporationTariff.serviceOrder.current === 'percent'
        ) {
            priceString += ` (${corporationTariff.serviceOrder.percent}%)`;
        }

        return priceString;
    }

    componentDidMount() {
        setServerData('orderServicesInfo');
    }

    render() {
        const { totalAmount = 0, infoAmount = [] } = this.props;

        return (
            <div className={`orderInfoAmount`}>
                <div className="orderInfoAmount__items _row _head">
                    <div className="orderInfoAmount__item _col _name">Общая сумма:</div>
                    <div className="orderInfoAmount__item _col _price">
                        {getFormatPrice(totalAmount)}₽
                    </div>
                </div>
                {infoAmount
                    ?.sort((a, b) => {
                        const aPrice = typeof a.price === 'number' ? a.price : 0;
                        const bPrice = typeof b.price === 'number' ? b.price : 0;

                        return bPrice - aPrice;
                    })
                    .map((item, key) => (
                        <div className="orderInfoAmount__items _row" key={key}>
                            <div className="orderInfoAmount__item _col _name">
                                {this.renderName(item.key)}
                            </div>
                            <div
                                className={`orderInfoAmount__item _col _price ${
                                    item.price >= 0 || typeof item.price === 'string'
                                        ? '_plus'
                                        : '_minus'
                                }`}
                            >
                                {this.renderPrice(item)}
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(InfoAmount);

InfoAmount.propTypes = {
    serverData: PropTypes.object,
    order: PropTypes.object,
    totalAmount: PropTypes.number,
    infoAmount: PropTypes.array,
    mkad: PropTypes.number,
    mkadToPoints: PropTypes.number,
    counterTime: PropTypes.number,
    nameOfTariff: PropTypes.string,
};
