import axios from 'axios';
import getHeaders from '../getHeaders';

export default function freshJoinContracts({ itemsIds, type, isDelete, isForce }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/join-contract`, { itemsIds, isRefresh: true, isDelete, type, isForce }, { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        resolve();
                    } else {
                        reject(res.data.data);
                    }
                },
                () => null,
            );
    });
}