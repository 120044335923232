import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import RolePopup from './RolePopup.jsx';

class RolePopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { rolePopup } = this.props;

        return (
            <Animate className="body__rolePopup" isShow={rolePopup?.isShow}>
                <RolePopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolePopup: state.rolePopup,
    };
}

export default connect(mapStateToProps)(RolePopupWrapper);

RolePopupWrapper.propTypes = {
    rolePopup: PropTypes.object,
};
