import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    fields = [
        { name: 'category', isReadOnly: true },
        { name: 'typeOfHolder', isReadOnly: true },
        { name: 'name' },
        { name: 'type', isReadOnly: true },
        { name: 'nameOfHolder', isReadOnly: true },
        { name: 'nds' },
    ];

    render() {
        const { tariff = {}, changeTariff, checkEditmode = () => false, errors = [] } = this.props;

        return (
            <div className="manualCard _tariff">
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description="Почасовой" />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            let { [name]: value } = tariff;

                            if (name === 'category') {
                                value =
                                    tariff.typeOfHolder === 'admin' ? 'Базовый' : 'Персональный';
                            }

                            if (name === 'typeOfHolder') {
                                if (
                                    tariff.holder === 'admin-client' ||
                                    tariff.typeOfHolder === 'client'
                                ) {
                                    value = 'Клиент';
                                }
                                if (
                                    tariff.holder === 'admin-executor' ||
                                    tariff.typeOfHolder === 'executor'
                                ) {
                                    value = 'Исполнитель';
                                }
                            }

                            if (name === 'type') {
                                value = 'Почасовой';
                            }

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="tariff"
                                        name={name}
                                        handler={changeTariff}
                                        group="tariff"
                                        isDisabled={!checkEditmode()}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Main);

Main.propTypes = {
    setHeightPage: PropTypes.func,
    tariff: PropTypes.object,
    changeTariff: PropTypes.func,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
};
