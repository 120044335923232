import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

import getModelsOfCar from '../../../../requests/getModelsOfCar';

class ManualModelOfCarMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getInfo = this.getInfo.bind(this);

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { marks } = this.state;
        const { modelOfCar } = this.props;

        if (marks && modelOfCar) {
            let description = '';
            const mark = marks.find((markLoop) => markLoop._id === modelOfCar.idOfMark);

            if (modelOfCar.name) {
                description = modelOfCar.name;
            }

            if (mark) {
                if (modelOfCar.name) {
                    description += ', ';
                }

                description += mark.name;
            }

            return {
                description,
            };
        }

        return {
            description: '–',
        };
    }

    fields = [{ name: 'name' }, { name: 'idOfMark' }, { name: 'newMark' }];

    getInfo() {
        getModelsOfCar({ params: [{ key: 'type', value: 'marks' }] }).then(
            ({ marks }) => {
                this.setState({ marks });
            },
            () => null,
        );
    }

    checkChangeMark() {
        const { modelOfCar } = this.props;

        if (modelOfCar && this.state.idOfMark !== modelOfCar.idOfMark) {
            this.setState({ idOfMark: modelOfCar.idOfMark }, this.getInfo);
        }
    }

    componentDidMount() {
        this.getInfo();
    }

    componentDidUpdate() {
        this.checkChangeMark();
    }

    render() {
        const { marks = [] } = this.state;
        const {
            type,
            modelOfCar,
            checkEditmode = () => false,
            errors = [],
            changeModelOfCar,
        } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`manualCard _modelOfCarMain`}>
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            const { [name]: value } = modelOfCar;
                            let items;

                            if (name === 'idOfMark') {
                                items = marks.map((mark) => ({
                                    key: mark._id,
                                    content: mark.name,
                                }));
                            }

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="modelOfCar"
                                        name={name}
                                        handler={changeModelOfCar}
                                        group="modelOfCarMain"
                                        isDisabled={!checkEditmode() || type === 'preview'}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                        items={items}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualModelOfCarMain);

ManualModelOfCarMain.propTypes = {
    modelOfCar: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeModelOfCar: PropTypes.func,
    type: PropTypes.string,
};
