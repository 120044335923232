import getCorporationCommission from '../../functions/crm/getCorporationCommission';

const reservedWords = require('../../infos/reservedWords.json');
const contentOrdersServices = require('../../infos/order/services.json');

export default class Final {
    constructor({ context }) {
        this.context = context;

        this.getItems = this.getItems.bind(this);
        this.getInfoTariff = this.getInfoTariff.bind(this);
        this.getInfoServices = this.getInfoServices.bind(this);
    }

    getInfoTariff(settings) {
        const { currentConfig, counterTime, counterWorker, isNds, type } = settings;

        const { item: currentConfigItem } =
            this.context.handlerTariffs.getCurrentConfig(currentConfig);
        const { type: typeConfigItem } = currentConfigItem;
        let { item: currentItem } = this.context.handlerTariffs.getCurrentItem(currentConfig);

        if (settings.currentItem) {
            currentItem = settings.currentItem;
        }

        let parameters;

        if (currentItem) {
            parameters = currentItem.parameters;
        }

        let name = ``;
        let price = 0;

        const getDescription = () => {
            if (typeConfigItem === 'car') {
                const { weight } = reservedWords.ci;

                return `<span class="_noWrap">(${+currentConfigItem.name}${weight})</span>`;
            }
            return ``;
        };

        name += reservedWords.tariff;
        name += `: `;
        name += reservedWords[typeConfigItem];
        name += ` `;
        name += getDescription();
        name += ` — `;
        name += counterTime;
        name += ` `;
        name += reservedWords.ci.hours;
        name += `.`;

        if (typeConfigItem === 'worker') {
            name += `, `;
            name += counterWorker;
            name += ` чел.`;
        }

        if (currentItem?._id === 'custom' && currentItem.customPrice) {
            price = +currentItem.customPrice;
        }

        if (parameters) {
            const minTime = this.context.handlerTariffs.getFormatedValue({
                name: 'minTime',
                value: parameters.minTime,
            });
            price += parameters.minPrice;
            if (counterTime > minTime) {
                price += (counterTime - minTime) * (parameters.priceOfMinute * 60);
            }

            switch (typeConfigItem) {
                case 'car':
                    break;
                case 'worker':
                    price *= counterWorker;

                    break;
                default:
                    break;
            }

            if (type === 'juristic' && isNds === true) {
                price *= 1.2;
            }
        }

        return { key: 'tariff', name, price };
    }

    getInfoServices(settings) {
        const {
            systemType,
            services,
            tariffsItems,
            currentConfig,
            counterTime,
            amountInItems,
            isNds,
        } = settings;
        const items = [];

        const getPriceOfElevator = (currentService, item) =>
            item.supportContent.find((el) => el.key === currentService.elevator)
                ? item.supportContent.find((el) => el.key === currentService.elevator).price
                : item.supportContent.find((el) => el.key === 'no').price;

        if (services) {
            this.context.handlerServices.getServices({ ...settings }).forEach((service) => {
                const currentService = services.find(
                    (serviceLoop) => serviceLoop.key === service.key,
                );
                const currentItem =
                    settings.currentItem ||
                    this.context.handlerTariffs.getCurrentItem(currentConfig)?.item;

                const otherData = {};

                let name = ``;
                let price = 0;

                if (systemType !== 'service' && currentItem && currentItem._id !== 'custom') {
                    const serviceOrder = currentItem.services.find(
                        (serviceLoop) => serviceLoop.key === service.key,
                    );

                    switch (service.key) {
                        case 'passengers':
                            name += contentOrdersServices[service.key].content;
                            name += ` `;
                            name += `<span class="_noWrap">(${currentService.value} чел.)</span>`;
                            break;
                        case 'workers':
                            {
                                name += reservedWords.workers;
                                name += ` `;
                                name += `<span class="_noWrap">(${currentService.value} чел.)</span>`;

                                const tariffWorker =
                                    settings.configurationWorker?.parameters ||
                                    tariffsItems?.find((itemInner) => itemInner.type === 'worker')
                                        .items[0].parameters;
                                const priceOfElevator = getPriceOfElevator(currentService, service);
                                const minTime = this.context.handlerTariffs.getFormatedValue({
                                    name: 'minTime',
                                    value: tariffWorker.minTime,
                                });
                                const timeOver = counterTime > minTime ? counterTime - minTime : 0;

                                otherData.elevator = currentService.elevator;

                                price +=
                                    (tariffWorker.minPrice +
                                        priceOfElevator +
                                        timeOver * tariffWorker.priceOfMinute * 60) *
                                    currentService.value;
                            }
                            break;
                        case 'descent':
                            {
                                name += contentOrdersServices[service.key].content;

                                const priceOfElevator = getPriceOfElevator(currentService, service);

                                otherData.elevator = currentService.elevator;

                                price +=
                                    ((serviceOrder ? serviceOrder.value : 0) + priceOfElevator) *
                                    counterTime;
                            }
                            break;
                        case 'save':
                            name += contentOrdersServices[service.key].content;

                            if (+amountInItems > 0 && +amountInItems <= 1000000) {
                                price = parseInt(+amountInItems / 100000, 10) * 50 + 50;
                            }
                            if (+amountInItems > 1000000 && +amountInItems <= 5000000) {
                                price = +amountInItems * 0.006;
                            }
                            if (+amountInItems > 5000000) {
                                price = +amountInItems * 0.007;
                            }

                            break;
                        case 'temperature':
                            name += contentOrdersServices[service.key].final.content;

                            otherData.start =
                                currentService.start || currentService.otherData?.start;
                            otherData.end = currentService.end || currentService.otherData?.end;
                            break;
                        default:
                            name += contentOrdersServices[service.key]?.content;
                            price += serviceOrder ? serviceOrder.value : 0;

                            break;
                    }
                }

                if (service.price) {
                    price = service.price;
                }
                if (isNds === true) {
                    price *= 1.2;
                }

                if (service.price === 0 || price === 0) {
                    price = reservedWords.free;
                }

                items.push({
                    key: service.key,
                    name,
                    price,
                    isDelete: true,
                    otherData,
                });
            });
        }

        return items.sort((a, b) => {
            const valA = a.price === reservedWords.free ? 0 : 1;
            const valB = b.price === reservedWords.free ? 0 : 1;

            return valB - valA;
        });
    }

    getPromo({ promo, isAll }) {
        if ((promo && promo.isUse === true) || isAll) {
            return {
                key: 'promo',
                name: contentOrdersServices.promo.content,
                price: promo.sale,
            };
        }

        return null;
    }

    getItems(settings) {
        let items = [];
        const {
            promo,
            infoRoute,
            currentConfig,
            points,
            isAll = false,
            systemType,
            corporationTariff,
            serviceCompany,
        } = settings;
        const { item: currentConfigItem } =
            this.context.handlerTariffs.getCurrentConfig(currentConfig);
        const configType = settings.typeOfTariff || currentConfigItem?.type;
        let { item: currentItem } = this.context.handlerTariffs.getCurrentItem(currentConfig);

        if (settings.currentItem) {
            currentItem = settings.currentItem;
        }

        if (systemType !== 'service') {
            // console.log(currentItem);
            if (currentItem?.parameters || currentItem?._id === 'custom') {
                items.push(this.getInfoTariff(settings));

                items = items.concat(...this.getInfoServices(settings));
            }

            if (this.getPromo({ promo, isAll })) {
                items.push(this.getPromo({ promo, isAll }));
            }

            if (
                points &&
                currentItem &&
                (points.find((point) => point.sk === true || point.ttk === true) || isAll)
            ) {
                let pricePass = 0;

                const inSk = points.find((point) => point.sk === true);
                const serviceSk = currentItem.services?.find((item) => item.key === 'sk');
                const inTtk = points.find((point) => point.ttk === true);
                const serviceTtk = currentItem.services?.find((item) => item.key === 'ttk');

                if (inTtk) {
                    pricePass = serviceTtk ? +serviceTtk.value : 0;
                }
                if (inSk) {
                    pricePass = serviceSk ? +serviceSk.value : 0;
                }

                items.push({
                    key: 'pass',
                    name: contentOrdersServices.pass.content,
                    price: pricePass || reservedWords.free,
                });
            }

            if (infoRoute || isAll) {
                const getPriceOfMkad = (mkad) => {
                    let price = 0;

                    switch (configType) {
                        case 'worker':
                            if (mkad <= 20) {
                                price = currentItem.parameters.priceTo20;
                            } else if (mkad <= 50) {
                                price = currentItem.parameters.priceTo50;
                            } else if (mkad <= 100) {
                                price = currentItem.parameters.priceTo100;
                            } else {
                                price = currentItem.parameters.priceTo100 * 2;
                            }
                            break;
                        case 'car':
                            price = (currentItem && currentItem.parameters.priceOfMkad * mkad) || 0;
                            break;
                        default:
                            break;
                    }

                    return price;
                };

                if (infoRoute) {
                    if (infoRoute.mkad || isAll) {
                        let nameOfMkad = contentOrdersServices.mkad.content;
                        nameOfMkad += ` (${infoRoute.mkad} км)`;

                        items.push({
                            key: 'mkad',
                            name: nameOfMkad,
                            price: getPriceOfMkad(infoRoute.mkad),
                        });
                    }
                    if ((infoRoute.mkadToPoints && configType === 'car') || isAll) {
                        let nameOfMkad = contentOrdersServices.mkadToPoints.content;
                        nameOfMkad += ` (${infoRoute.mkadToPoints} км)`;

                        items.push({
                            key: 'mkadToPoints',
                            name: nameOfMkad,
                            price:
                                (currentItem &&
                                    currentItem.parameters.priceOfMkad * infoRoute.mkadToPoints) ||
                                0,
                        });
                    }
                }
            }
        } else if (serviceCompany) {
            items.push({
                key: 'tariff',
                name: 'Тариф',
                price: Math.ceil(
                    serviceCompany.items.find((item) => item.key === serviceCompany.id)?.price || 0,
                ),
            });
        }

        if (corporationTariff) {
            const amount = items.reduce((prev, cur) => prev + cur.price, 0);
            const commission = getCorporationCommission({
                tariff: corporationTariff,
                name: systemType === 'service' ? 'serviceOrder' : 'systemOrder',
                amount,
            });

            items.push({
                key: 'commission',
                price: commission,
            });
        }

        return items;
    }
}
