import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../../../../functions/getPage';
import getCurrentPage from '../../../../functions/getCurrentPage';

import Link from '../../../../components/Link.jsx';
import More from '../../../../components/crm/widget/More.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

const pages = require('../../../../redux/pages').default.filter(
    (page) => page.parentName === 'manual-tariffs-inner' && page.level === 3,
);

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);

        setPermissions.call(this);

        this.group = React.createRef();
    }

    orderLinks = [{ key: 'main' }, { key: 'logs' }];

    links = {
        main: {
            key: 'manual-tariffs-inner-main',
        },
        logs: {
            key: 'manual-tariffs-inner-logs',
        },
    };

    getLinks(key) {
        if (key === 'last') {
            const { pagesStore } = this.props;

            const currentPage = getCurrentPage({
                pages: pagesStore,
                filter: (page) => page.parentName === 'manual-tariffs-inner' && page.level === 3,
            });

            return [].concat(
                ...(currentPage === 'manual-tariffs-inner-main' ? [{ key: 'add' }] : []),
            );
            // .concat(...[{ key: 'delete' }]);
        }

        return this.orderLinks.filter(this.filterLink).filter(
            (link) =>
                this.getPermissions({
                    key: 'manual',
                    items: [
                        {
                            key: 'executors',
                            rules: ['update'],
                        },
                    ],
                }) || link.key !== 'logs',
        );
    }

    filterLink() {
        const { isNew } = this.props;

        return !isNew;
    }

    renderLink({ prop: key }) {
        const { levels } = this.props;

        if (key === 'more') {
            const subPages = pages.filter(
                (page) =>
                    ['manual-cars-inner-logs', 'manual-cars-inner-organization'].indexOf(
                        page.name,
                    ) !== -1,
            );

            return <More pages={subPages} />;
        }

        if (key === 'add') {
            return (
                <div
                    className="widget__headLink _main"
                    onClick={() => {
                        document.dispatchEvent(new CustomEvent('addTariffConfig'));
                    }}
                >
                    <div className="widget__headLinkInner _click">+ Новая конфигурация</div>
                </div>
            );
        }

        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headLink" ids={{ 2: levels[2] }}>
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    render() {
        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={this.getLinks()}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                {this.getPermissions({
                    key: 'manual',
                    items: [
                        {
                            key: 'tariffs',
                            rules: ['update'],
                        },
                    ],
                }) && (
                    <ListAbsoluteMain
                        className="widget__headActionsGroup _dynamic"
                        items={this.getLinks('last')}
                        renderItem={this.renderLink}
                        classNameItem="widget__headLink"
                        prop="key"
                        itemParams={['offsetRight']}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    pagesStore: PropTypes.object,
    levels: PropTypes.array,
};
