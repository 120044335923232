import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../../../functions/setServerData';
// import setAnimate from '../../../../../functions/setAnimate';

import ImageLazy from '../../../../ImageLazy.jsx';
import Icon from '../../../../Icon.jsx';
import Animate from '../../../../Animate.jsx';
import handlerWindow from '../../../../../functions/handlerWindow';

class ListBodyworks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderItem = this.renderItem.bind(this);

        this.parent = React.createRef();
    }

    setCurrent({ key, isStart }) {
        const { handler } = this.props;
        const keyCurrent = this.state.keyCurrent === key ? null : key;

        this.setState({ keyCurrent }, () => {
            if (!isStart) {
                handler(keyCurrent);
                handlerWindow({
                    action: 'hide',
                    name: 'listBodyworks',
                });
            }
        });
    }

    renderItem(item) {
        const { keyCurrent } = this.state;
        const { serverData } = this.props;
        const { key } = item;
        const { carBodyworks = {} } = serverData;
        const bodywork = carBodyworks[key];
        const { image } = bodywork;
        const src = `${process.env.REACT_APP_STATIC}/car-icons/${image}`;

        return (
            <div className="manualCardListBodyworks__item" key={key}>
                <div
                    className="manualCardListBodyworks__itemInner _col _click"
                    onClick={() => this.setCurrent({ key })}
                >
                    <Animate
                        className="manualCardListBodyworks__itemDone _col"
                        isShow={keyCurrent === key}
                    >
                        <i className="manualCardListBodyworks__itemDoneIcon">
                            <Icon name="done" />
                        </i>
                    </Animate>
                    <ImageLazy src={src} className="manualCardListBodyworks__itemIcon" />
                </div>
            </div>
        );
    }

    getArrayBodyworks() {
        const { serverData } = this.props;
        const { carBodyworks = {} } = serverData;

        return Object.keys(carBodyworks).map((key) => ({ key }));
    }

    componentDidMount() {
        const { bodyworkDefault } = this.props;

        this.setCurrent({ key: bodyworkDefault, isStart: true });

        setServerData('carBodyworks');
    }

    render() {
        const carBodyworksArr = this.getArrayBodyworks();

        return (
            <div ref={this.parent} className="manualCardListBodyworks _windowWithCursor _topCenter">
                <div className="manualCardListBodyworks__inner _col">
                    <div className="manualCardListBodyworks__content">
                        <div className="manualCardListBodyworks__items _row">
                            {carBodyworksArr.map(this.renderItem)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ListBodyworks);

ListBodyworks.propTypes = {
    serverData: PropTypes.object,
    handler: PropTypes.func,
    bodyworkDefault: PropTypes.string,
};
