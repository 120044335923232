import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../manual/card/Head.jsx';
import Avatar from '../../Avatar.jsx';
import getIcons from '../../../functions/getIcons';

class ProfileAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { user } = this.props;

        if (user?.icon) {
            return {
                description: 'Выбран',
            };
        }

        return {
            description: 'По умолчанию',
        };
    }

    getIcons() {
        const icons = [{ key: 'text' }];

        icons.push(...getIcons({ type: 'user' }).map((icon) => ({ key: 'icon', value: icon + 1 })));

        return icons;
    }

    render() {
        const { user, checkEditmode = () => false, changeUser } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`manualCard _userMain`}>
                <div className="manualCard__head _row">
                    <Head title="Аватар в системе" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className={`manualCardAvatar _row ${checkEditmode() ? '_edit' : ''}`}>
                        <div className="manualCardAvatar__block _col _result">
                            <div className="manualCardAvatar__result">
                                <Avatar holder={user} />
                            </div>
                        </div>
                        <div className="manualCardAvatar__block _col _list">
                            <div className="manualCardAvatar__items _row">
                                {this.getIcons().map((item, key) => {
                                    const isCurrent = user?.icon
                                        ? item.value?.toString() === user.icon?.toString()
                                        : item.key === 'text';

                                    return (
                                        <div
                                            className={`manualCardAvatar__item _col ${
                                                isCurrent ? '_current' : ''
                                            }`}
                                            key={key}
                                            onClick={() => {
                                                if (checkEditmode()) {
                                                    changeUser({
                                                        name: 'icon',
                                                        value: item.value?.toString() || null,
                                                    });
                                                }
                                            }}
                                        >
                                            <div className="manualCardAvatar__itemInner _click">
                                                <Avatar
                                                    holder={
                                                        item.key === 'text'
                                                            ? {
                                                                  firstName: user.firstName,
                                                                  secondName: user.secondName,
                                                              }
                                                            : { icon: item.value }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ProfileAvatar);

ProfileAvatar.propTypes = {
    user: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    changeUser: PropTypes.func,
    type: PropTypes.string,
    deleteCompany: PropTypes.func,
};
