import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from '../AnimateChange.jsx';

import setServerData from '../../functions/setServerData';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';
import Switch from '../Switch.jsx';

class TarrifsServiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderTariff = this.renderTariff.bind(this);
        this.getSortTariffs = this.getSortTariffs.bind(this);
    }

    getTariffs() {
        const { serverData } = this.props;
        const { orderServiceTariffs } = serverData;

        return orderServiceTariffs?.tariffs || [];
    }

    getTariffName() {
        const { order } = this.props;

        if (order?.tariff?.id) {
            const tariffs = this.getTariffs();
            const tariff = tariffs.find((item) => item.key === order?.tariff?.id);
            let tariffName = tariff.name;

            if (tariff.shortName) {
                tariffName += ', ';
                tariffName += tariff.shortName;
            }

            return tariffName;
        }

        return '–';
    }

    checkCurrent(tariff) {
        const { order } = this.props;
        const { key } = tariff;

        return order?.tariff?.id === key;
    }

    getSortTariffs(tariffs) {
        return JSON.parse(JSON.stringify(tariffs)).sort((a, b) => {
            const weightA = this.checkCurrent(a) ? 1 : 0;
            const weightB = this.checkCurrent(b) ? 1 : 0;

            return weightB - weightA;
        });
    }

    renderTariff({ item: tariff }) {
        const { changeTariff } = this.props;
        const { key, name, weight, volume, image } = tariff;
        const isCurrent = this.checkCurrent(tariff);

        return (
            <div
                className={`orderDetailsTariffsServiceList__card _${key} ${
                    isCurrent ? '_current' : ''
                }`}
            >
                <div
                    className="orderDetailsTariffsServiceList__cardInner _col _click"
                    onClick={() => {
                        changeTariff({ id: key });
                    }}
                >
                    <div className="orderDetailsTariffsServiceList__cardHead">
                        <Animate
                            className="orderDetailsTariffsServiceList__cardCheck _col"
                            isShow={isCurrent}
                        >
                            <i className="orderDetailsTariffsServiceList__cardCheckIcon">
                                <Icon name="done" />
                            </i>
                        </Animate>
                        <img
                            src={`${process.env.REACT_APP_STATIC}/images/${image}`}
                            alt=""
                            className="orderDetailsTariffsServiceList__cardImage"
                        />
                    </div>
                    <div className="orderDetailsTariffsServiceList__cardContent">
                        <p className="orderDetailsTariffsServiceList__cardName">{name}</p>
                        <div className="orderDetailsTariffsServiceList__cardParams">
                            <div className="orderDetailsTariffsServiceList__cardParam _row">
                                <p className="orderDetailsTariffsServiceList__cardParamSupport">
                                    Вес
                                </p>
                                <div className="orderDetailsTariffsServiceList__cardParamContent">
                                    до {weight} кг
                                </div>
                            </div>
                            {volume && (
                                <div className="orderDetailsTariffsServiceList__cardParam _row">
                                    <p className="orderDetailsTariffsServiceList__cardParamSupport">
                                        Объём
                                    </p>
                                    <div className="orderDetailsTariffsServiceList__cardParamContent">
                                        до {volume}м³
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        setServerData('orderServiceTariffs');
    }

    render() {
        const { order, changeTariff } = this.props;
        const tariffs = this.getSortTariffs(this.getTariffs());

        return (
            <div className="orderDetailsTariffsServiceList _windowWithCursor _bottomRight">
                <div className="orderDetailsTariffsServiceList__head _row">
                    <div className="orderDetailsTariffsServiceList__headName _row">
                        <div className="orderDetailsTariffsServiceList__headNameTitle">Тариф:</div>
                        <AnimateChange
                            className="orderDetailsTariffsServiceList__headNameInner"
                            prop={this.getTariffName()}
                            type="_translateMedium"
                        >
                            <div className="orderDetailsTariffsServiceList__headNameItem">
                                {this.getTariffName()}
                            </div>
                        </AnimateChange>
                    </div>
                    <div className="orderDetailsTariffsServiceList__headNds _row">
                        <div className="orderDetailsTariffsServiceList__headNdsSwitch">
                            <Switch
                                className="_size3"
                                value={!!order?.tariff?.isNds}
                                handler={({ value }) =>
                                    changeTariff({ isNds: value, isHide: false })
                                }
                            />
                        </div>
                        НДС
                    </div>
                </div>
                <div className="orderDetailsTariffsServiceList__content">
                    <div className="orderDetailsTariffsServiceList__cards">
                        <ListAbsoluteMain
                            className="orderDetailsTariffsServiceList__cardsInner"
                            items={tariffs}
                            renderItem={this.renderTariff}
                            classNameItem="orderDetailsTariffsServiceList__card"
                            prop="key"
                            paramsParent={{ width: 'auto' }}
                            styles={['width']}
                            sort={this.getSortTariffs}
                            keyRender={order?.tariff?.id || null}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(TarrifsServiceList);

TarrifsServiceList.propTypes = {
    order: PropTypes.object,
    serverData: PropTypes.object,
    changeTariff: PropTypes.func,
};
