import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Balance from './settings/Balance.jsx';

import Pages from '../../components/Pages.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

import Inner from '../../components/crm/manual/Inner.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import Back from '../../components/crm/widget/Back.jsx';
import HeadInner from './settings/HeadInner.jsx';

import getCorporation from '../../requests/getCorporation';
import getCurrentCorporation from '../../functions/crm/getCurrentCorporation';
import handlerWindow from '../../functions/handlerWindow';

import Reports from './settings/Reports.jsx';
import Windows from '../../components/Windows.jsx';
import Filter from '../../components/Filter.jsx';
import Main from './settings/Main.jsx';
import Roles from './settings/Roles.jsx';
import Proxy from './joins/contracts/Main.jsx';

import WindowPrompt from '../../components/WindowPrompt.jsx';
import getRealParams from '../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../functions/crm/getMaxHeightContentWidget';
import Users from './settings/Users.jsx';
import Integrations from './settings/Integrations.jsx';
import ServiceOrder from './settings/ServiceOrder.jsx';
import WindowActions from '../../components/WindowActions.jsx';
import handlerPopup from '../../functions/app/handlerPopup';

import pages from '../../redux/pages';
import setContractsParent from '../../functions/crm/setContractsParent';
import downloadCorporationReportList from '../../requests/downloadCorporationReportList';
import Docs from './settings/Docs.jsx';
import LogsTable from '../../components/crm/LogsTable.jsx';

class Settings extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getCorporation = this.getCorporation.bind(this);
        this.setCorporation = this.setCorporation.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.setDeleteRoleRequest = this.setDeleteRoleRequest.bind(this);
        this.setDeleteUserRequest = this.setDeleteUserRequest.bind(this);
        this.updateCorporation = this.updateCorporation.bind(this);

        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.initCallbackUserFilter = this.initCallbackUserFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.renderUsersFilter = this.renderUsersFilter.bind(this);

        this.renderWindowsPromptRole = this.renderWindowsPromptRole.bind(this);
        this.renderWindowsPromptUser = this.renderWindowsPromptUser.bind(this);
        this.renderWindowsRoleActions = this.renderWindowsRoleActions.bind(this);
        this.renderWindowsUserActions = this.renderWindowsUserActions.bind(this);
        this.renderWindowsCorporationReportsActions =
            this.renderWindowsCorporationReportsActions.bind(this);

        this.setHeightPage = this.setHeightPage.bind(this);

        setContractsParent.call(this);

        this.headPages = {};

        pages
            .filter((page) => page.parentName === 'settings')
            .forEach((page) => {
                this.headPages[page.name] = {
                    render() {
                        return (
                            <div className="widget__headNameItemContent">{page.contentOfLink}</div>
                        );
                    },
                };
            });

        this.parent = React.createRef();
    }

    targetName = 'user';

    targetNameSave = 'userSave';

    pages = {
        'settings-main': {
            render() {
                const { corporation } = this.state;
                const { user } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Main
                                    user={user}
                                    corporation={corporation}
                                    setHeightPage={this.setHeightPage}
                                    getCorporation={this.getCorporation}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-operations': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Balance
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setHeightPage={this.setHeightPage}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-docs': {
            render() {
                const { corporation, updateKey } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Docs
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    updateCorporation={this.updateCorporation}
                                    updateKey={updateKey}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-users': {
            render() {
                const { corporation, updateKey } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Users
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    updateCorporation={this.updateCorporation}
                                    setHeightPage={this.setHeightPage}
                                    setDeleteUserRequest={this.setDeleteUserRequest}
                                    updateKey={updateKey}
                                    initCallbackFilter={this.initCallbackUserFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-reports': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Reports
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    setHeightPage={this.setHeightPage}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-roles': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Roles
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    setDeleteRoleRequest={this.setDeleteRoleRequest}
                                    updateCorporation={this.updateCorporation}
                                    setHeightPage={this.setHeightPage}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-proxy': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Proxy
                                    getParent={() => this.parent.current}
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    type="userProxy"
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-integrations': {
            render() {
                const { corporation, corporationSave } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Integrations
                                    corporationSave={corporationSave}
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    updateCorporation={this.updateCorporation}
                                    getCorporation={this.getCorporation}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-logs': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <LogsTable
                                    modelName="corporation"
                                    modelId={corporation._id}
                                    inPage={true}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'settings-serviceOrder': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <ServiceOrder
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    corporationsPages = {
        'corporations-inner-main': {
            render() {
                const { corporation, updatedCorporationKey } = this.state;
                const { user } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Main
                                    corporation={corporation}
                                    setHeightPage={this.setHeightPage}
                                    inner={true}
                                    user={user}
                                    getCorporation={this.getCorporation}
                                    updatedCorporationKey={updatedCorporationKey}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'corporations-inner-operations': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Balance
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setHeightPage={this.setHeightPage}
                                    inner={true}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'corporations-inner-docs': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Docs
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setHeightPage={this.setHeightPage}
                                    inner={true}
                                    updateCorporation={this.updateCorporation}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'corporations-inner-users': {
            render() {
                const { corporation, updateKey } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Users
                                    corporation={corporation}
                                    getParent={() => this.parent.current}
                                    updateCorporation={this.updateCorporation}
                                    setHeightPage={this.setHeightPage}
                                    setDeleteUserRequest={this.setDeleteUserRequest}
                                    updateKey={updateKey}
                                    initCallbackFilter={this.initCallbackUserFilter}
                                    inner={true}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'corporations-inner-logs': {
            render() {
                const { corporation } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <LogsTable
                                    models={[
                                        { name: 'corporationInfo', id: corporation._id },
                                        { name: 'corporationVerification', id: corporation._id },
                                        { name: 'userVerification', id: corporation.holderId },
                                    ]}
                                    inPage={true}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    updateCorporation({ fields, isSave }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const corporation = JSON.parse(JSON.stringify(newState.corporation));

                Object.keys(fields).forEach((key) => {
                    corporation[key] = fields[key];
                });

                newState.updateKey = new Date().getTime();

                newState.corporation = corporation;

                if (isSave) {
                    newState.corporationSave = JSON.parse(JSON.stringify(corporation));
                }

                return newState;
            }, resolve);
        });
    }

    setCorporation({ corporation }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.corporation = corporation;
                    newState.corporationSave = JSON.parse(JSON.stringify(corporation));
                    newState.updatedCorporationKey = new Date().getTime();

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    resolve();
                },
            );
        });
    }

    getCorporation() {
        const { user, inner, levels, setInfoHead } = this.props;
        const { idOfCurrentCorporation } = user;
        const id = inner ? levels[2] : idOfCurrentCorporation;

        return new Promise((resolve) => {
            getCorporation({ id }).then(
                ({ corporation }) => {
                    this.setCorporation({ corporation }).then(() => {
                        if (inner) {
                            setInfoHead({
                                key: 'corporation',
                                value: corporation,
                            });
                        }

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    saveCorporation() {
        const { user } = this.props;

        const currentCorporation = getCurrentCorporation({ user });

        if (currentCorporation) {
            this.setState({
                corporationName: currentCorporation?.shortName || currentCorporation?.fullName,
            });
        }
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    initCallbackUserFilter(callbackFilter) {
        this.callbackUsersFilter = callbackFilter;
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderUsersFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackUsersFilter}
            />
        );
    }

    setDeleteRoleRequest(deleteRoleRequest) {
        this.deleteRoleRequest = deleteRoleRequest;
    }

    setDeleteUserRequest(deleteUserRequest) {
        this.deleteUserRequest = deleteUserRequest;
    }

    renderWindowsPromptRole({ roleId }) {
        const deleteRole = ({ hide }) => {
            this.deleteRoleRequest({ id: roleId, hide });
        };

        return <WindowPrompt className="_topCenter" callback={deleteRole} name="delete" />;
    }

    renderWindowsPromptUser({ userId, updateItems }) {
        const deleteUser = ({ hide }) => {
            this.deleteUserRequest({
                id: userId,
                hide: () => {
                    hide();

                    if (updateItems) {
                        updateItems();
                    }
                },
            });
        };

        return <WindowPrompt className="_topRight" callback={deleteUser} name="delete" />;
    }

    renderWindowsRoleActions({ target, items, roleId, deleteRole }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'delete') {
                    deleteRole({ resultTarget: target });

                    resolve();
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'role',
                        modelId: roleId,
                    });

                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'roleActions',
                    });
                }}
            />
        );
    }

    renderWindowsUserActions({ parent, target, items, user, userId, updateItems }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'edit') {
                    handlerPopup({
                        name: 'userPopup',
                        isShow: true,
                        user,
                        callback: updateItems,
                    });

                    resolve();
                } else if (key === 'delete') {
                    handlerWindow({
                        parent,
                        parentResize: parent,
                        target,
                        action: 'show',
                        name: 'promptDeleteUser',
                        className: '_prompt _right',
                        uniqKey: `prompt-${userId}`,
                        userId,
                        centers: {
                            left: 0.5,
                            top: 1,
                        },
                        updateItems,
                    });
                    resolve();
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'user',
                        modelId: userId,
                    });

                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'roleActions',
                    });
                }}
            />
        );
    }

    renderWindowsCorporationReportsActions({ items, report }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'download') {
                    const fileName = `Отчёт_CRM_LIVECARGO_${report.month + 1}-${report.year}.xlsx`;

                    downloadCorporationReportList({ id: report._id, filename: fileName }).then(
                        () => {
                            resolve();
                        },
                    );
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'roleActions',
                    });
                }}
            />
        );
    }

    handlerSocket({ detail }) {
        const { corporation } = this.state;
        const { name, data } = detail;

        if (corporation && name === 'corporation') {
            const { type, fields, idOfCorporation } = data;

            if (type === 'changeInfo' && corporation._id === idOfCorporation) {
                this.updateCorporation({ fields, isSave: true });
            }
        }
    }

    setHeightPage() {
        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    componentDidMount() {
        this.getCorporation();
        this.saveCorporation();

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        const { inner, setInfoHead } = this.props;

        document.removeEventListener('getSocketData', this.handlerSocket);

        if (inner) {
            setTimeout(() => {
                setInfoHead({
                    key: 'name',
                    value: null,
                });
            }, 300);
        }
    }

    render() {
        const { isInit, corporationName, corporation } = this.state;
        const { counterChangePage, inner } = this.props;

        return (
            <div
                ref={this.parent}
                className={`widget _fix ${isInit ? '_ready' : ''} ${!inner ? '_parent' : ''}`}
            >
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="usersFilter" renderContent={this.renderUsersFilter} />
                <Windows name="roleActions" renderContent={this.renderWindowsRoleActions} />
                <Windows name="userActions" renderContent={this.renderWindowsUserActions} />
                <Windows
                    name="corporationReportsActions"
                    renderContent={this.renderWindowsCorporationReportsActions}
                />
                <Windows name="promptDeleteRole" renderContent={this.renderWindowsPromptRole} />
                <Windows name="promptDeleteUser" renderContent={this.renderWindowsPromptUser} />
                <Windows name="contractActions" renderContent={this.renderContractActions} />

                {!inner && (
                    <div className="widget__head _row">
                        <Back />
                        <div className="widget__headContent">
                            <div className="widget__headInner _row">
                                <InfoHead
                                    title={corporationName}
                                    className={counterChangePage > 0 ? '_withBack' : ''}
                                >
                                    <Pages
                                        classNamePage="widget__headNameItemInner _page"
                                        filter={(page) => page.parentName === 'settings'}
                                        pages={this.headPages}
                                        context={this}
                                    />
                                </InfoHead>
                                <div className="widget__headActions">
                                    <HeadInner corporation={corporation} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="widget__content _scroll _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            {isInit && (
                                <>
                                    {inner ? (
                                        <Pages
                                            classNamePage="widget__page _deep1"
                                            filter={(page) =>
                                                page.parentName === 'corporations-inner'
                                            }
                                            pages={this.corporationsPages}
                                            context={this}
                                            getClassName={(key) =>
                                                key === 'corporations-inner-logs'
                                                    ? '_full _wide'
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <Pages
                                            classNamePage="widget__page _deep1"
                                            filter={(page) => page.parentName === 'settings'}
                                            pages={this.pages}
                                            context={this}
                                            getClassName={(key) => {
                                                if (key === 'settings-logs') {
                                                    return '_full _wide';
                                                }

                                                return key === 'settings-roles' ? '_full' : '';
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        serverData: state.serverData,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Settings);

Settings.propTypes = {
    user: PropTypes.object,
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    serverData: PropTypes.object,
    counterChangePage: PropTypes.number,
    inner: PropTypes.bool,
    setInfoHead: PropTypes.func,
};
