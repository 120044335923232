import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import { dispatcher } from '../../../redux/redux';

class BonusPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="bonusPopup _col">
                <div className="bonusPopup__inner">
                    <div className="bonusPopup__innerBox _col">
                        <div className="bonusPopup__innerDecor _1"></div>
                        <div className="bonusPopup__innerDecor _2"></div>
                        <div className="bonusPopup__innerDecor _3"></div>
                        <img
                            src={require('../../../img/crm/bonus-icon.svg').default}
                            alt=""
                            className="bonusPopup__icon"
                        />
                        <div className="bonusPopup__title">Бонус за регистрацию!</div>
                        <p className="bonusPopup__description">
                            Дарим вам <b>3000₽</b> за регистрацию.
                            <br />
                            Бонус можно использовать только
                            <br />
                            за заказы через сервис.
                        </p>
                        <div className="bonusPopup__button">
                            <Button
                                onClick={() => {
                                    dispatcher({ type: 'isBonusPopupShow', data: false });
                                }}
                            >
                                Начать работу
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(BonusPopup);

BonusPopup.propTypes = {
    isBonusPopupShow: PropTypes.bool,
};
