import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Field from '../../../components/crm/manual/card/Field.jsx';
import Button from '../../../components/Button.jsx';
import Icon from '../../../components/Icon.jsx';
import Link from '../../../components/Link.jsx';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import setPermissions from '../../../functions/crm/setPermissions';

class SettingsIntegrations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.handlerField = this.handlerField.bind(this);
        this.save = this.save.bind(this);

        setPermissions.call(this);
    }

    cardsOrder = ['tg', '1C'];

    cards = {
        tg: {
            icon: 'tg-icon.svg',
            name: 'Telegram',
            fields: [{ name: 'tgToken', key: 'token' }],
            buttonContent: 'Применить',
            instruction: {
                href: 'content-infoPublic-inner',
                id: 'kak-integrirovat-telegram-v-crm',
                content: 'Инструкция для Telegram',
            },
        },
        '1C': {
            icon: '1c-icon.svg',
            name: '1C',
            fields: [{ name: '1CToken' }],
            buttonContent: 'Сгенерировать',
        },
    };

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    fields[name] = value;

                    newState.fields = fields;

                    return newState;
                });
            }
        });
    }

    checkChange({ key }) {
        const { fields } = this.state;
        const { corporationSave } = this.props;
        const card = this.cards[key];
        const { fields: cardFields } = card;
        let isChange = false;

        cardFields.forEach((field) => {
            if (corporationSave.integration[field.name] !== fields[field.name]) {
                isChange = true;
            }
        });

        if (key === '1C') {
            isChange = true;
        }

        return isChange;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getChangedFields({ key }) {
        const { corporation, corporationSave } = this.props;
        const card = this.cards[key];
        const { fields } = card;
        const resultFields = {};

        fields.forEach((field) => {
            if (corporationSave.integration[field.name] !== corporation.integration[field.name]) {
                resultFields[field.name] = corporation.integration[field.name];
            }
        });

        return resultFields;
    }

    save({ key }) {
        const { corporation, getCorporation } = this.props;

        if (this.checkChange({ key })) {
            this.handlerLoading(key).then(() => {
                const fields = this.getChangedFields({ key });

                let action = 'change-integrations';

                if (key === '1C') {
                    action = 'generate-1Ctoken';
                }

                axios
                    .patch(
                        `${process.env.REACT_APP_API}${
                            process.env.REACT_APP_ENV === 'prod' ? ':9090' : ''
                        }/corporation`,
                        {
                            id: corporation._id,
                            action,
                            fields,
                        },
                        { headers: getHeaders() },
                    )
                    .then((res) => {
                        const { success, data } = res.data;

                        if (success) {
                            getCorporation().then(() => {
                                this.init();
                            });

                            setNotification({ notification: 'success-change-info' });
                        } else {
                            const { errors } = data;

                            if (errors) {
                                errors.forEach((error) => {
                                    if (error === 'tg-many-request') {
                                        setNotification({ notification: 'tg-many-request' });
                                    }

                                    if (error === 'tg-token-already-use') {
                                        setNotification({ notification: 'tg-token-already-use' });
                                    }
                                });
                            }
                        }

                        this.handlerLoading(null);
                    });
            });
        }
    }

    init() {
        const { corporation } = this.props;

        this.setState({ fields: JSON.parse(JSON.stringify(corporation.integration)) });
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const { loadingKey, fields } = this.state;

        return (
            <div ref={this.parent} className="settingsMain">
                <div className="settingsMain__inner">
                    {this.cardsOrder.map((key) => {
                        const card = this.cards[key];
                        const { icon, name, fields: cardFields, instruction, buttonContent } = card;

                        return (
                            <div className="settingsMain__card _parentForEdit _editBack" key={key}>
                                <div className="manualCard">
                                    <div className="manualCard__head _row">
                                        <div className="manualCard__headTitle _row">
                                            <img
                                                src={require(`../../../img/crm/integrations/${icon}`)}
                                                alt=""
                                                className={`manualCard__headTitleIcon _${key}`}
                                            />
                                            {name}
                                        </div>
                                    </div>
                                    <div className="manualCard__content">
                                        <div className="settingsMain__integrations">
                                            <div className="settingsMain__integrationsFields _row">
                                                {cardFields.map((field) => {
                                                    const { [field.name]: value } = fields;
                                                    let isDisabled;

                                                    if (key === '1C') {
                                                        isDisabled = true;
                                                    }

                                                    return (
                                                        <div
                                                            className="settingsMain__integrationsField"
                                                            key={field.key}
                                                        >
                                                            <Field
                                                                value={value}
                                                                type="integrations"
                                                                name={field.name}
                                                                isEditmode={false}
                                                                handler={this.handlerField}
                                                                group="integrations"
                                                                isDisabled={isDisabled}
                                                                // isDisabled={!checkEditmode() || type === 'preview'}
                                                                // isReadOnly={isReadOnly}
                                                                // isError={errors.indexOf(name) !== -1}
                                                            />
                                                        </div>
                                                    );
                                                })}

                                                {this.getPermissions({
                                                    key: 'settings',
                                                    items: [
                                                        {
                                                            key: 'integrations',
                                                            rules: ['update'],
                                                        },
                                                    ],
                                                }) && (
                                                    <>
                                                        <div className="settingsMain__integrationsButton">
                                                            <Button
                                                                className="_minSize"
                                                                isDisabled={
                                                                    !this.checkChange({ key })
                                                                }
                                                                onClick={() => this.save({ key })}
                                                                showLoader={loadingKey === key}
                                                            >
                                                                {buttonContent}
                                                            </Button>
                                                        </div>
                                                        {instruction && (
                                                            <Link
                                                                className="settingsMain__integrationsLink"
                                                                pageName={instruction.href}
                                                                ids={{ 2: instruction.id }}
                                                            >
                                                                <i className="settingsMain__integrationsLinkIcon">
                                                                    <Icon name="instruction-file" />
                                                                </i>
                                                                {instruction.content}
                                                            </Link>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsIntegrations);

SettingsIntegrations.propTypes = {
    corporation: PropTypes.object,
    corporationSave: PropTypes.object,
    user: PropTypes.object,
    updateCorporation: PropTypes.func,
    getCorporation: PropTypes.func,
};
