import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getGoogleVisionGroups from '../../../../requests/getGoogleVisionGroups';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';

import TableDefault from '../../../../components/crm/manual/Table.jsx';

import ArticleCard from '../../../../components/crm/content/blog/ArticleCard.jsx';
import setSpacesInText from '../../../../functions/setSpacesInText';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';

class ContentGoogleVisionMain extends TableDefault {
    constructor(props) {
        super(props);
        this.state = {
            isDisabledScroll: false,
            isLoadingFilter: false,
        };

        this.renderCard = this.renderCard.bind(this);
        this.getSortItems = this.getSortItems.bind(this);

        this.parent = React.createRef();
    }

    name = 'googleVision';

    stepCounter = 12;

    getCards() {
        const { items = [], isLimit } = this.state;

        return isLimit ? items.concat(...[{ _id: 'info' }]) : items;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getGoogleVisionGroups(query).then(
                ({ groups, isLimit, counter }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(groups, false, isLimit, counter).then(resolve);
                },
                () => null,
            );
        });
    }

    renderCard({ item, prop: _id }) {
        const cards = this.getCards();
        const group = cards.find((innerGroup) => innerGroup._id === _id) || item;

        return (
            <div className={`contentBlog__card  ${_id === 'info' ? '_info' : ''}`}>
                <div className="contentBlog__cardInner">
                    {_id === 'info' ? (
                        <div className="empty _col _block">
                            <div className="empty__inner">
                                <div className="empty__title">Пока это все слова</div>
                                <p
                                    className="empty__content"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(
                                            'Чтобы добавить слово, нажмите «+Добавить слово»<br/>в правом верхнем углу страницы',
                                        ),
                                    }}
                                ></p>
                            </div>
                        </div>
                    ) : (
                        <ArticleCard cardName="googleVision" {...group} />
                    )}
                </div>
            </div>
        );
    }

    sortItems(a, b) {
        return new Date(b.dateOfCreate) - new Date(a.dateOfCreate);
    }

    getSortItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    render() {
        const { isReady, isDisabledScroll, isLimit, isInit, isLoadingFilter, counterUpdate } =
            this.state;
        let { heightCards = 0 } = this.state;
        const { parentScroll, handlerLoaderList } = this.props;
        const cards = this.getCards();

        if (!heightCards || isLoadingFilter) {
            heightCards = 300;
        }

        return (
            <div
                ref={this.parent}
                className={`widget _notPadding ${!isLoadingFilter && isReady ? '_ready' : ''}`}
            >
                <Animate className="widget__loader _loader" isShow={!isReady || isLoadingFilter}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _inner _full _scroll">
                    <div className="widget__contentBox">
                        <div className="contentBlog _googleVision">
                            <ListScroll
                                getParent={() => parentScroll}
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={cards.length}
                                handlerLoaderList={handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll || !isInit || isLoadingFilter}
                                key={counterUpdate}
                            >
                                <ListAbsoluteMain
                                    className="contentBlog__cards"
                                    items={cards}
                                    renderItem={this.renderCard}
                                    classNameItem="contentBlog__card"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    classNames={['_googleVision']}
                                    itemParams={['offsetRight', 'offsetTop', 'width', 'height']}
                                />
                            </ListScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ContentGoogleVisionMain);

ContentGoogleVisionMain.propTypes = {
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
};
