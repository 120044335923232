import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../Field.jsx';
import Counter from '../Counter.jsx';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';

class PointCargoItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handler = this.handler.bind(this);

        this.parent = React.createRef();
    }

    orderFields = ['name', 'long', 'width', 'height', 'weight', 'volume', 'counter'];

    fields = {
        name: {
            support: 'Груз',
            className: '_start',
        },
        long: {
            support: '0см',
            reg: 'number',
            valueSupport: {
                content: 'см',
                position: 'end',
            },
        },
        width: {
            support: '0см',
            reg: 'number',
            valueSupport: {
                content: 'см',
                position: 'end',
            },
        },
        height: {
            support: '0см',
            reg: 'number',
            valueSupport: {
                content: 'см',
                position: 'end',
            },
        },
        weight: {
            support: '0кг',
            reg: 'number',
            valueSupport: {
                content: 'кг',
                position: 'end',
            },
        },
        volume: {
            support: '0м³',
            reg: 'number',
            valueSupport: {
                content: 'м³',
                position: 'end',
            },
        },
        counter: {
            type: 'counter',
        },
    };

    handler({ ...props }) {
        return new Promise((resolve) => {
            const {
                handlerRoute,
                numberOfPoint: keyOfPoint,
                numberOfCargo: keyOfCargo,
                number: key,
                handlerDrop,
            } = this.props;

            if (props.action === 'change') {
                handlerRoute
                    .handlerCargoItem({ keyOfPoint, keyOfCargo, key, handlerDrop, ...props })
                    .then(resolve);
            } else {
                resolve();
            }
        });
    }

    renderField({ name }) {
        const {
            item,
            numberOfPoint,
            numberOfCargo,
            number,
            isDisabled,
            device,
            isEditmode,
        } = this.props;
        const fieldInfo = this.fields[name];
        const { type = 'input', className } = fieldInfo;

        const getValue = () => {
            if (name === 'volume') {
                const { long, width, height } = item;

                return checkValueOfEmpty(long) &&
                    checkValueOfEmpty(width) &&
                    checkValueOfEmpty(height)
                    ? +((+long * +width * +height) / 1000000).toFixed(1)
                    : 0;
            }

            return item?.[name];
        };

        switch (type) {
            case 'counter':
                return (
                    <Counter
                        value={getValue()}
                        handler={this.handler}
                        name={name}
                        className={device === 'desktop' ? '_end' : ''}
                        isDisabled={isDisabled}
                    />
                );
            default:
                return (
                    <Field
                        {...fieldInfo}
                        name={name}
                        value={getValue()}
                        callback={this.handler}
                        group={`point-cargo-item-${numberOfPoint}-${numberOfCargo}-${number}`}
                        className={`_orderPoint ${device === 'desktop' ? className || '' : ''}`}
                        isDisabled={isDisabled}
                        isEditmode={isEditmode}
                    />
                );
        }
    }

    render() {
        const { item, handlerRoute } = this.props;

        const condForState = handlerRoute.checkCompleteCargoItem(item);

        return (
            <div ref={this.parent} className="orderPointCargoItem">
                <Animate className="orderPointCargoItem__state _success" isShow={condForState}>
                    <Icon name="order-point-item-success" />
                </Animate>
                <Animate className="orderPointCargoItem__state _alert" isShow={!condForState}>
                    <Icon name="order-point-item-alert" />
                </Animate>
                <div className="orderPointCargoItem__fields _row">
                    {this.orderFields.map((nameOfField) => (
                        <div
                            className={`orderPointCargoItem__field _${nameOfField}`}
                            key={nameOfField}
                        >
                            {this.renderField({ name: nameOfField })}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PointCargoItem);

PointCargoItem.propTypes = {
    item: PropTypes.object,
    numberOfPoint: PropTypes.number,
    numberOfCargo: PropTypes.number,
    number: PropTypes.number,
    handlerRoute: PropTypes.object,
    handlerDrop: PropTypes.func,
    isDisabled: PropTypes.bool,
    device: PropTypes.string,
    isEditmode: PropTypes.bool,
};
