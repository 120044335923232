import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Stack from '../../../../classes/Stack';

import AnimateChange from '../../../AnimateChange.jsx';
import ListAbsolute from '../../../ListAbsolute.jsx';
import Configuration from './Configuration.jsx';
import Edit from '../../../Edit.jsx';
import ListScroll from '../../../ListScroll.jsx';

// import setServerData from '../../../../functions/setServerData';
import setAnimate from '../../../../functions/setAnimate';
import removeTransition from '../../../../functions/removeTransition.ts';
import copyInBuffer from '../../../../functions/copyInBuffer';

import Icon from '../../../Icon.jsx';

class Configurations extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'all',
            servicesState: {},
            counterScroll: this.stepCounter,
        };

        this.renderTab = this.renderTab.bind(this);
        this.renderConfig = this.renderConfig.bind(this);
        this.setHeightConfigs = this.setHeightConfigs.bind(this);
        this.filterConfig = this.filterConfig.bind(this);

        this.setStateConfiguration = this.setStateConfiguration.bind(this);
        this.setStateConfigurationsHeight = this.setStateConfigurationsHeight.bind(this);
        this.setStateConfigurationItems = this.setStateConfigurationItems.bind(this);

        this.handlerStateServices = this.handlerStateServices.bind(this);
        this.addConfiguration = this.addConfiguration.bind(this);

        this.sortConfigsNew = this.sortConfigsNew.bind(this);
        this.getSortConfigs = this.getSortConfigs.bind(this);
        this.getMoreConfigs = this.getMoreConfigs.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 4;

    setTab({ key: currentTab }) {
        return new Promise((resolve) => {
            const { editName, parentScroll } = this.props;

            if (editName) {
                resolve();
            } else {
                this.setState({ currentTab }, () => {
                    const { scrollTop } = parentScroll;

                    // console.log('f')

                    setAnimate({
                        draw: (progress) => {
                            parentScroll.scrollTo({
                                top: scrollTop - progress * scrollTop,
                            });
                        },
                        duration: 300,
                    });

                    resolve();
                });
            }
        });
    }

    renderTab({ item, prop: key, isShow, isLast, params }) {
        const { currentTab } = this.state;

        return (
            <div
                className={`manualTariffsConfigurations__headTab ${isShow ? '_show' : ''} ${
                    isLast ? '_last' : ''
                } ${currentTab === key ? '_current' : ''} _${key}`}
                key={key}
                data-key={key}
                style={{
                    transform: `translate(${-params?.offsetRight}px,0px)`,
                }}
                onClick={() => this.setTab({ key })}
            >
                <div className="manualTariffsConfigurations__headTabInner _row _click">
                    {item.content}
                </div>
            </div>
        );
    }

    renderTabs() {
        const { tariff, serverData } = this.props;
        const { tariffs } = serverData;
        let tabs = [{ key: 'all', content: 'Все' }];

        if (tariffs?.categories) {
            const configurations = tariff.configurations || [];

            tabs = tabs.concat(
                ...tariffs.categories.filter((category) =>
                    configurations.find((config) => config.category === category.key),
                ),
            );
        }

        if (tabs.length === 2) {
            tabs = [];
        }

        return (
            <div className={`manualTariffsConfigurations__headTabs ${tariffs ? '_show' : ''}`}>
                <ListAbsolute
                    parent={(() =>
                        this.parent.current?.querySelector(
                            '.manualTariffsConfigurations__headTabs',
                        ))()}
                    items={tabs}
                    renderItem={this.renderTab}
                    classNameItem="manualTariffsConfigurations__headTab"
                    prop="key"
                    callback={() => {
                        if (!this.isInitTabs && tariffs) {
                            this.isInitTabs = true;
                            removeTransition({
                                item: '.manualTariffsConfigurations__headTab',
                                isCurrent: true,
                            });
                        }
                    }}
                />
            </div>
        );
    }

    setHeightConfigs(params) {
        const { setHeightPage, isInit: isInitProps } = this.props;

        this.setState({ heightConfigs: params.height }, () => {
            if (isInitProps) {
                setHeightPage();
            }

            if (!this.isInit) {
                this.isInit = true;
                removeTransition({
                    item: '.manualTariffsConfigurations__cards',
                    isCurrent: true,
                });
            }
        });
    }

    filterConfig(config) {
        const { currentTab } = this.state;

        return currentTab === 'all' || config.category === currentTab || config.isNew;
    }

    getConfigs(withFilter = false) {
        const { tariff } = this.props;

        return this.getSortConfigs(
            tariff?.configurations.filter((config) => !withFilter || this.filterConfig(config)) ||
                [],
        );
    }

    sortConfigsNew(a, b) {
        const weightA = a.isNew ? 1 : 0;
        const weightB = b.isNew ? 1 : 0;

        return weightB - weightA;
    }

    sortConfigsType(a, b) {
        const weightA = a.type === 'car' ? 1 : 0;
        const weightB = b.type === 'car' ? 1 : 0;

        return weightB - weightA;
    }

    sortConfigsName(a, b) {
        return +a.name - +b.name;
    }

    sortConfigsDisabled(a, b) {
        const weightA = a.isDisabled ? 1 : 0;
        const weightB = b.isDisabled ? 1 : 0;

        return weightA - weightB;
    }

    getSortConfigs(items) {
        return JSON.parse(JSON.stringify(items))
            .sort(this.sortConfigsType)
            .sort(this.sortConfigsName)
            .sort(this.sortConfigsDisabled)
            .sort(this.sortConfigsNew);
    }

    renderConfig({ item, prop: _id, key: order, isShow, isLast, params }) {
        const { configurationStackCounter, configurationsHeightStack, servicesState } = this.state;
        const {
            tariff,
            isInit,
            infoConfigs,
            editName,
            handlerEditmode,
            handlerConfiguration,
            handlerConfigurationItem,
            handlerConfigurationItems,
            setCurrentItem,
            isLoadingKey,
            deleteConfiguration,
            parent,
            parentScroll,
            checkRights,
            errors,
        } = this.props;
        const configs = this.getConfigs();
        const config = configs.find((configLoop) => configLoop._id === _id) || item;
        const heightContent = configurationsHeightStack?.[_id] || 0;
        const isEdit = _id === editName;

        if (config.isNew) {
            // console.log(config);
        }

        const itemId =
            config?.items.find((innerItem) => innerItem.key === infoConfigs[_id])?._id ||
            config?.items[0]?._id;

        return (
            <div
                className={`manualTariffsConfigurations__card _parentForEdit _editBack ${isShow ? '_show' : ''} ${
                    isLast ? '_last' : ''
                } ${servicesState[_id] ? '_showServices' : ''} ${
                    _id === editName ? '_current' : ''
                }`}
                data-_id={_id}
                key={_id}
                style={{
                    transform: `translate(0px,${params?.offsetTop}px)`,
                    order,
                }}
            >
                <div className="manualTariffsConfigurations__cardInner">
                    {!config.isDisabled && checkRights() && (
                        <Edit
                            name={_id}
                            className="manualTariffsConfigurations__cardEdit"
                            editName={editName}
                            handlerEditmode={handlerEditmode}
                            isLoader={isLoadingKey === _id}
                        />
                    )}
                    {itemId && (
                        <div
                            className={`manualTariffsConfigurations__cardCopy _col _editBtn ${
                                !config.isDisabled && checkRights() ? '_withEdit' : ''
                            }`}
                        >
                            <div
                                className="edit"
                                onClick={() => {
                                    copyInBuffer(itemId);
                                }}
                            >
                                <i className="edit__icon _edit">
                                    <Icon name="copy-2" />
                                </i>
                            </div>
                        </div>
                    )}

                    <Configuration
                        {...config}
                        categories={tariff.categories}
                        idOfCurrentItem={infoConfigs[_id]}
                        setCurrentItem={setCurrentItem}
                        setStateConfiguration={this.setStateConfiguration}
                        configurationStackCounter={configurationStackCounter}
                        heightContent={heightContent}
                        setStateConfigurationsHeight={this.setStateConfigurationsHeight}
                        handlerStateServices={(isShowServices) =>
                            this.handlerStateServices({ id: _id, isShow: isShowServices })
                        }
                        servicesState={servicesState[_id]}
                        isEdit={config.isDisabled ? false : isEdit}
                        isInit={isInit}
                        handlerConfigurationItem={(props) =>
                            new Promise((resolve) => {
                                handlerConfigurationItem({ idOfConfig: _id, ...props }).then(
                                    resolve,
                                );
                            })
                        }
                        handlerConfigurationItems={(props) =>
                            new Promise((resolve) => {
                                handlerConfigurationItems({ idOfConfig: _id, ...props }).then(
                                    resolve,
                                );
                            })
                        }
                        handlerConfiguration={(props) =>
                            new Promise((resolve) => {
                                handlerConfiguration({ idOfConfig: _id, ...props }).then(resolve);
                            })
                        }
                        deleteConfiguration={deleteConfiguration}
                        parent={parent}
                        parentScroll={parentScroll}
                        infoCars={tariff.infoCars}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    getMoreConfigs(counter) {
        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                resolve();
                removeTransition({ item: '.manualTariffsConfigurations__card', isCurrent: true });
            });
        });
    }

    renderConfigs() {
        const { heightConfigs, counterScroll } = this.state;
        const { parentScroll, isShowFull = false, handlerLoaderList } = this.props;
        const configs = this.getConfigs(true);
        const configsFilter = configs.filter((config, key) => key < counterScroll);

        return (
            <div
                className="manualTariffsConfigurations__cards"
                style={{ height: `${heightConfigs}px` }}
            >
                <ListScroll
                    isDisabledScroll={!isShowFull}
                    getParent={() => parentScroll}
                    callback={this.getMoreConfigs}
                    startCounter={this.stepCounter}
                    stepCounter={this.stepCounter}
                    maxCounter={configs.length}
                    lengthCurrent={configsFilter.length}
                    handlerLoaderList={handlerLoaderList}
                    isLimit={configsFilter.length === configs.length}
                >
                    <ListAbsolute
                        parent={(() =>
                            this.parent.current?.querySelector(
                                '.manualTariffsConfigurations__cards',
                            ))()}
                        items={configsFilter}
                        renderItem={this.renderConfig}
                        classNameItem="manualTariffsConfigurations__card"
                        prop="_id"
                        // name="test"
                        setParamsParent={this.setHeightConfigs}
                        // keyRender={currentTab}
                        id="configurations"
                        paramsParent={{ width: true }}
                        sort={this.getSortConfigs}
                        propsForUpdate={['isNew', 'category', 'type', 'name']}
                    />
                </ListScroll>
            </div>
        );
    }

    configurationStack = new Stack({ name: 'configurationStack' });

    setStateConfiguration({ id, value }) {
        this.configurationStack.setInStack({ context: this, id, value });
    }

    configurationsHeightStack = new Stack({ name: 'configurationsHeightStack' });

    setStateConfigurationsHeight({ id, value }) {
        return new Promise((resolve) => {
            this.configurationsHeightStack.setInStack({ context: this, id, value }).then(() => {
                setTimeout(() => {
                    document.dispatchEvent(
                        new CustomEvent('updateListAbsolute', {
                            detail: {
                                id: 'configurations',
                                props: {
                                    clearStyleElems: ['.manualTariffsConfigurationItems__cards'],
                                },
                            },
                        }),
                    );
                }, 10);

                resolve();
            });
        });
    }

    configurationItemsStack = new Stack({ name: 'configurationItemsStack' });

    setStateConfigurationItems({ id, value }) {
        this.configurationItemsStack.setInStack({ context: this, id, value });
    }

    handlerStateServices({ id, isShow }) {
        const { scrollToConfig } = this.props;

        if (isShow === undefined) {
            isShow = !this.state.servicesState[id];
        }

        this.setState(
            (state) => {
                const newState = { ...state };
                const servicesState = { ...newState.servicesState };

                servicesState[id] = isShow;

                newState.servicesState = servicesState;

                return newState;
            },
            () => {
                setTimeout(() => {
                    document.dispatchEvent(
                        new CustomEvent('updateListAbsolute', {
                            detail: {
                                id: `configuration-${id}`,
                                callback: () => {
                                    setTimeout(() => {
                                        document.dispatchEvent(
                                            new CustomEvent('updateListAbsolute', {
                                                detail: {
                                                    id: 'configurations',
                                                    props: {
                                                        clearStyleElems: [
                                                            '.manualTariffsConfigurationItems__cards',
                                                        ],
                                                    },
                                                },
                                            }),
                                        );
                                        setTimeout(() => {
                                            scrollToConfig();
                                        }, 1);
                                    }, 1);
                                },
                            },
                        }),
                    );
                }, 10);
            },
        );
    }

    addConfiguration() {
        const { addConfiguration } = this.props;

        addConfiguration().then(() => {
            this.setTab({ key: 'all', isNotScroll: true });
        });
    }

    componentDidMount() {
        document.addEventListener('addTariffConfig', this.addConfiguration);
    }

    componentWillUnmount() {
        document.removeEventListener('addTariffConfig', this.addConfiguration);
    }

    render() {
        const { isFixHead, editName } = this.props;
        const configurations = this.getConfigs();
        const lenItems = configurations.reduce((prev, cur) => prev + cur.items.length, 0);

        return (
            <div
                ref={this.parent}
                className={`manualTariffsConfigurations ${isFixHead ? '_fix' : ''} ${
                    editName ? '_edit' : ''
                }`}
            >
                <div className="manualTariffsConfigurations__head">
                    <div className="manualTariffsConfigurations__headInner _row">
                        <div className="manualTariffsConfigurations__headTitle _row">
                            Конфигурации:
                            <AnimateChange
                                className="manualTariffsConfigurations__headTitleInner"
                                prop={lenItems}
                                type="_translateMedium"
                            >
                                {`${lenItems} штук`}
                            </AnimateChange>
                        </div>
                        {this.renderTabs()}
                    </div>
                </div>
                <div className="manualTariffsConfigurations__content">{this.renderConfigs()}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(Configurations);

Configurations.propTypes = {
    serverData: PropTypes.object,
    editName: PropTypes.string,
    handlerEditmode: PropTypes.func,
    tariff: PropTypes.object,
    setHeightPage: PropTypes.func,
    parent: PropTypes.object,
    parentScroll: PropTypes.object,
    initTariff: PropTypes.func,
    isInit: PropTypes.bool,
    scrollToConfig: PropTypes.func,
    isFixHead: PropTypes.bool,
    handlerConfigurationItem: PropTypes.func,
    handlerConfigurationItems: PropTypes.func,
    addConfiguration: PropTypes.func,
    handlerConfiguration: PropTypes.func,
    setCurrentItem: PropTypes.func,
    infoConfigs: PropTypes.object,
    isLoadingKey: PropTypes.string,
    deleteConfiguration: PropTypes.func,
    isShowFull: PropTypes.bool,
    handlerLoaderList: PropTypes.func,
    checkRights: PropTypes.func,
    errors: PropTypes.object,
};
