import React from 'react';

import setSpacesInText from '@functions/setSpacesInText.js';

import BusinessAdvantagesI from '../types.ts';

const renderBlocks: BusinessAdvantagesI['renderBlocks'] = function () {
    return (
        <div className="publicBusinessAdvantages__blocks">
            {this.blocks.map((block, blockKey) => {
                const { icon, title, content, decor } = block;

                return (
                    <div
                        className={`publicBusinessAdvantages__block _ROW _${blockKey + 1}`}
                        key={blockKey}
                    >
                        <div className="publicBusinessAdvantages__blockDecor">{decor()}</div>
                        <div className="publicBusinessAdvantages__blockContent">
                            <img
                                src={require(
                                    `../../../../../../../img/crm/public/business/${icon}`,
                                )}
                                alt=""
                                className="publicBusinessAdvantages__blockIcon"
                            />
                            <div
                                className="publicBusinessAdvantages__blockTitle"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                            ></div>
                            <div
                                className="publicBusinessAdvantages__blockText"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(content) }}
                            ></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default renderBlocks;
