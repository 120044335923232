import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Orders from '../../components/crm/Orders.jsx';
import Joins from './Joins.jsx';
import setPermissions from '../../functions/crm/setPermissions';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        setPermissions.call(this);
    }

    render() {
        const { user } = this.props;

        return (
            <div className="index">
                <div className="index__items">
                    {false && (
                        <div className="index__item">
                            <Orders type="preview" />
                        </div>
                    )}
                    {this.getPermissions?.({
                        user,
                        key: 'joins',
                        items: [{ key: 'invites', rules: ['read'] }],
                    }) && (
                        <div className="index__item">
                            <Joins main={true} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
};
