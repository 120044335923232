import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import MvdPopup from './MvdPopup.jsx';

class MvdPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { mvdPopup } = this.props;

        return (
            <Animate className="body__mvdPopup" isShow={mvdPopup?.isShow}>
                <MvdPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        mvdPopup: state.mvdPopup,
    };
}

export default connect(mapStateToProps)(MvdPopupWrapper);

MvdPopupWrapper.propTypes = {
    mvdPopup: PropTypes.object,
};
