import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getExecutors from '../../../../requests/getExecutors';
import createId from '../../../../requests/createId';
import getModel from '../../../../requests/getModel';

import Main from './inner/Main.jsx';
import Cars from './inner/Cars.jsx';
import LogsBalance from './inner/LogsBalance.jsx';
import Docs from './inner/Docs.jsx';
import Pays from './inner/Pays.jsx';
import Wallets from './inner/Wallets.jsx';
import Logs from './inner/Logs.jsx';
import Contracts from '../../joins/contracts/Main.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Inner from '../../../../components/crm/manual/Inner.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getDateFromString from '../../../../functions/getDateFromString';
import getFormatedNumber from '../../../../functions/getFormatedNumber.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';
import setPermissions from '../../../../functions/crm/setPermissions';
import setContractsParent from '../../../../functions/crm/setContractsParent';

class ManualExecutorsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {
            filesDelete: [],
            isShowLoaderList: false,
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.changeExecutor = this.changeExecutor.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
        this.updateExecutor = this.updateExecutor.bind(this);
        this.handlerServices = this.handlerServices.bind(this);
        this.handlerTags = this.handlerTags.bind(this);
        this.addComment = this.addComment.bind(this);
        this.changeComment = this.changeComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getExecutor = this.getExecutor.bind(this);
        this.changePage = this.changePage.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.setExecutor = this.setExecutor.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);

        setPermissions.call(this);
        setContractsParent.call(this);

        this.parent = React.createRef();
    }

    mainPage = 'manual-executors';

    targetName = 'executor';

    targetNameSave = 'executorSave';

    pages = {
        'manual-executors-inner-main': {
            render() {
                const { isInit, executor, executorSave, filesDelete } = this.state;
                const { checkNew } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Main
                                isInit={isInit}
                                executor={executor}
                                executorSave={executorSave}
                                changeExecutor={this.changeExecutor}
                                uploadFile={this.uploadFile}
                                deleteFile={this.deleteFile}
                                formDataFiles={this.formDataFiles}
                                filesDelete={filesDelete}
                                clearFiles={this.clearFiles}
                                handlerServices={this.handlerServices}
                                handlerTags={this.handlerTags}
                                addComment={this.addComment}
                                changeComment={this.changeComment}
                                deleteComment={this.deleteComment}
                                setHeightPage={this.setHeightPage}
                                backToSave={this.backToSave}
                                isNew={checkNew()}
                                getExecutor={this.getExecutor}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-executors-inner-main',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                                setExecutor={this.setExecutor}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-executors-inner-cars': {
            render() {
                const { isShowLoaderList, isInit, executor } = this.state;
                const { getParent, scrollToTop } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Cars
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    executor={executor}
                                    getParent={getParent}
                                    scrollToTop={scrollToTop}
                                    handlerLoaderList={this.handlerLoaderList}
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._deep2._manual-executors-inner-cars',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'manual-executors-inner-balance': {
            render() {
                const { isShowLoaderList, isInit, executor } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <LogsBalance
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    executor={executor}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'manual-executors-inner-docs': {
            render() {
                const { isInit, executor, executorSave, filesDocsDelete } = this.state;
                const { getParent } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Docs
                                isInit={isInit}
                                setHeightPage={this.setHeightPage}
                                executor={executor}
                                executorSave={executorSave}
                                formDataDocs={this.formDataDocs}
                                changeDoc={this.changeDoc}
                                addDoc={this.addDoc}
                                uploadFile={this.uploadFileDoc}
                                deleteFile={this.deleteFileDoc}
                                deleteDoc={this.deleteDoc}
                                handlerError={this.handlerErrorDoc}
                                backToSaveDocs={this.backToSaveDocs}
                                clearFormData={this.clearFormDataDocs}
                                filesDocsDelete={filesDocsDelete}
                                getParent={getParent}
                                updateExecutor={this.updateExecutor}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-executors-inner-docs',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                                changeExecutor={this.changeExecutor}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-executors-inner-pays': {
            render() {
                const { executor } = this.state;
                const { getParent, initCallbackPaysFilter } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Pays
                                getParent={getParent}
                                executorId={executor?._id}
                                initCallbackPaysFilter={initCallbackPaysFilter}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-executors-inner-wallets': {
            render() {
                const { executor } = this.state;
                const { getParent } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Wallets
                                executor={executor}
                                getParent={getParent}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-executors-inner-organization',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                                getExecutor={this.getExecutor}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-executors-inner-contracts': {
            render() {
                const { executor } = this.state;
                const { getParent, initCallbackFilter } = this.props;

                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner">
                            <Contracts
                                getParent={getParent}
                                setHeightPage={this.setHeightPage}
                                initCallbackFilter={initCallbackFilter}
                                executorId={executor?._id}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-executors-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, executor } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    executor={executor}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    checkErrorPassportDateGet(executor) {
        const birthdayObject = getDateFromString({
            date: executor.passport.birthday,
        });
        const dateGetObject = getDateFromString({
            date: executor.passport.dateGet,
        });

        if (birthdayObject && dateGetObject) {
            const birthdayTime = birthdayObject.getTime();
            const dateGetTime = dateGetObject.getTime();

            if (birthdayTime > dateGetTime) {
                return true;
            }
        }

        return false;
    }

    checkErrorDriverPassportDate(executor, type) {
        const dateGetObject = getDateFromString({
            date: executor.driverPassport.dateGet,
        });
        const dateObject = getDateFromString({
            date: executor.driverPassport[type],
        });

        if (dateGetObject && dateObject) {
            const dateGetTime = dateGetObject.getTime();
            const dateTime = dateObject.getTime();

            if (dateGetTime > dateTime) {
                return true;
            }
        }

        return false;
    }

    getDateAction(executor) {
        const birthdayObject = getDateFromString({
            date: executor.passport.birthday,
        });
        const nowDateObject = new Date();

        if (birthdayObject) {
            let age = nowDateObject.getFullYear() - birthdayObject.getFullYear();

            if (nowDateObject.setFullYear(1970) < new Date(birthdayObject).setFullYear(1970)) {
                age -= 1;
            }

            if (age >= 45) {
                return `01.01.${birthdayObject.getFullYear() + 150}`;
            }

            if (age >= 20) {
                return `${getFormatedNumber(birthdayObject.getDate())}.${getFormatedNumber(
                    birthdayObject.getMonth() + 1,
                )}.${birthdayObject.getFullYear() + 45}`;
            }

            return `${getFormatedNumber(birthdayObject.getDate())}.${getFormatedNumber(
                birthdayObject.getMonth() + 1,
            )}.${birthdayObject.getFullYear() + 20}`;
        }

        return null;
    }

    changeExecutor({ type, action, name, value, otherData = {} }) {
        return new Promise((resolve) => {
            if (action === 'change' || !action) {
                this.setState((state) => {
                    const newState = { ...state };
                    const executor = JSON.parse(JSON.stringify(newState.executor));

                    Object.keys(otherData).forEach((key) => {
                        executor[key] = otherData[key];
                    });

                    if (!type) {
                        executor[name] = value;
                    } else if (executor[type]) {
                        if (type === 'driverPassport') {
                            if (name === 'categories') {
                                const indexItem = executor[type][name].findIndex(
                                    (item) => item.name === value,
                                );

                                if (indexItem === -1) {
                                    executor[type][name].push({ name: value });
                                } else {
                                    executor[type][name].splice(indexItem, 1);
                                }
                            } else {
                                executor[type][name] = value;

                                if (
                                    name === 'dateGet' ||
                                    name === 'dateAction' ||
                                    name === 'dateExp'
                                ) {
                                    if (this.checkErrorDriverPassportDate(executor, 'dateAction')) {
                                        executor.driverPassport.dateAction =
                                            executor.driverPassport.dateGet;
                                    }
                                    if (this.checkErrorDriverPassportDate(executor, 'dateExp')) {
                                        executor.driverPassport.dateExp =
                                            executor.driverPassport.dateGet;
                                    }
                                }
                            }
                        } else {
                            executor[type][name] = value;

                            if (
                                type === 'passport' &&
                                (name === 'birthday' || name === 'dateGet' || name === 'country')
                            ) {
                                if (name === 'birthday' && executor?.passport.country === 'ru') {
                                    executor.passport.dateAction =
                                        this.getDateAction(executor) || null;
                                }
                                if (name === 'country' && executor?.passport.country === 'ru') {
                                    executor.passport.dateAction =
                                        this.getDateAction(executor) || null;
                                }
                                if (this.checkErrorPassportDateGet(executor)) {
                                    executor.passport.dateGet = executor.passport.birthday;
                                }
                            }
                        }
                    }

                    newState.executor = executor;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    formDataFiles = new FormData();

    uploadFile({ type, name, files }) {
        const [file] = files;
        const { object } = file;

        this.formDataFiles.set(`${type}-${name}`, object);

        this.setState((state) => {
            const newState = { ...state };
            const executor = JSON.parse(JSON.stringify(newState.executor));

            const indexFile = executor[type].images.files.findIndex((item) => item.name === name);

            if (indexFile !== -1) {
                executor[type].images.files[indexFile].isLocal = true;
                executor[type].images.files[indexFile].file = file;
                executor[type].images.files[indexFile].file.pathLocal = file.path;
            }

            newState.executor = executor;

            return newState;
        });
    }

    clearFiles() {
        this.formDataFiles = new FormData();
        this.setState({ filesDelete: [] });
    }

    deleteFile({ type, name }) {
        this.formDataFiles.delete(`${type}-${name}`);

        this.setState((state) => {
            const newState = { ...state };
            const executor = JSON.parse(JSON.stringify(newState.executor));

            const indexFile = executor[type].images.files.findIndex((item) => item.name === name);

            if (indexFile !== -1) {
                newState.filesDelete.push(`${type}-${name}`);
                executor[type].images.files[indexFile].file = {};
            }

            newState.executor = executor;

            return newState;
        });
    }

    checkPermissions(actions) {
        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'executors',
                    rules: ['update'],
                    actions,
                },
            ],
        });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            }
        }
    }

    handlerServices({ name }) {
        this.setState((state) => {
            const newState = { ...state };
            const executor = JSON.parse(JSON.stringify(newState.executor));
            const indexService = executor.services.findIndex((service) => service.name === name);

            if (indexService === -1) {
                executor.services.push({ name });
            } else {
                executor.services.splice(indexService, 1);
            }

            newState.executor = executor;

            return newState;
        });
    }

    handlerTags({ tags }) {
        this.setState((state) => {
            const newState = { ...state };
            const executor = JSON.parse(JSON.stringify(newState.executor));

            executor.tags = tags.map((tag) => ({
                id: tag._id,
                colorOfGroup: tag.colorOfGroup,
                name: tag.name,
            }));

            newState.executor = executor;

            return newState;
        });
    }

    deleteComment({ id }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const executor = JSON.parse(JSON.stringify(newState.executor));
                const indexComment = executor.comments.findIndex((comment) => comment._id === id);

                if (indexComment !== -1) {
                    executor.comments.splice(indexComment, 1);
                }

                newState.executor = executor;

                return newState;
            }, resolve);
        });
    }

    addComment() {
        return new Promise((resolve) => {
            if (!this.state.executor.comments.find((comment) => !comment.content)) {
                createId().then(
                    ({ id }) => {
                        this.setState((state) => {
                            const newState = { ...state };
                            const executor = JSON.parse(JSON.stringify(newState.executor));

                            executor.comments.push({
                                _id: id,
                                content: '',
                            });

                            newState.executor = executor;

                            return newState;
                        }, resolve);
                    },
                    () => null,
                );
            } else {
                resolve();
            }
        });
    }

    changeComment({ _id, props }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const executor = JSON.parse(JSON.stringify(newState.executor));
                const indexComment = executor.comments.findIndex((comment) => comment._id === _id);

                if (indexComment !== -1) {
                    Object.keys(props).forEach((keyProp) => {
                        executor.comments[indexComment][keyProp] = props[keyProp];
                    });
                }

                newState.executor = executor;

                return newState;
            }, resolve);
        });
    }

    updateExecutor({ fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const executor = JSON.parse(JSON.stringify(newState.executor));

            Object.keys(fields).forEach((prop) => {
                if (typeof fields[prop] === 'object' && fields[prop] !== null) {
                    if (Array.isArray(fields[prop])) {
                        executor[prop] = fields[prop];
                    } else {
                        executor[prop] = { ...executor[prop], ...fields[prop] };
                    }
                } else {
                    executor[prop] = fields[prop];
                }
            });

            newState.executor = executor;
            newState.executorSave = JSON.parse(JSON.stringify(executor));

            return newState;
        });
    }

    setExecutor(executor) {
        return new Promise((resolve) => {
            if (executor.passport.country === 'ru') {
                const dateAction = this.getDateAction(executor);

                executor.passport.dateAction = dateAction;
            }

            this.setState(
                {
                    executor,
                    executorSave: JSON.parse(JSON.stringify(executor)),
                },
                () => {
                    this.setState({ isInit: true }, () => {
                        this.setHeightPage();
                    });

                    resolve();
                },
            );
        });
    }

    getExecutor(idOfExecutor = this.props.levels[3]) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            if (idOfExecutor === 'new') {
                getModel({ type: 'model', name: 'executor' }).then(
                    ({ model: executor }) => {
                        const resultExecutor = executor;

                        if (process.env.REACT_APP_ENV === 'local') {
                            resultExecutor.firstName = 'Иван';
                            resultExecutor.secondName = 'Иванов';
                            resultExecutor.thirdName = 'Иванович';
                            resultExecutor.type = 'driver';
                            resultExecutor.status = 'active';
                            resultExecutor.organization = 'SMZ';
                            resultExecutor.passport.country = 'ru';
                            resultExecutor.passport.view = 'rf';
                            resultExecutor.passport.series = '1234';
                            resultExecutor.passport.number = '1234';
                            resultExecutor.passport.dateGet = '07.07.2023';
                            resultExecutor.passport.birthday = '07.07.1999';
                            resultExecutor.passport.code = '123-123';
                            resultExecutor.passport.nameGet = 'г. Москва';
                            resultExecutor.passport.addressReg = 'улица 800-летия Москвы, Москва';
                            resultExecutor.passport.addressFact =
                                'подъезд 12, Олонецкая улица, 4, Москва';

                            resultExecutor.coords = [55.852293, 37.620672];

                            resultExecutor.driverPassport.country = 'ru';
                            resultExecutor.driverPassport.view = 'national';
                            resultExecutor.driverPassport.series = '1232';
                            resultExecutor.driverPassport.number = '123123';
                            resultExecutor.driverPassport.dateGet = '24.02.2023';
                            resultExecutor.driverPassport.dateAction = '12.12.2312';
                            resultExecutor.driverPassport.dateExp = '12.12.2023';
                            resultExecutor.driverPassport.categories = [{ name: 'A' }];
                        }

                        setTimeout(() => {
                            this.setExecutor(resultExecutor).then(() => {
                                setInfoHead({ key: 'executor', value: { isNew: true } });
                                resolve();
                            });
                        }, 300);
                    },
                    () => null,
                );
            } else {
                getExecutors({ id: idOfExecutor }).then(({ executor }) => {
                    setTimeout(() => {
                        this.setExecutor(executor).then(() => {
                            setInfoHead({ key: 'executor', value: executor });
                            resolve();
                        });
                    }, 300);
                }, this.handlerErrorGetModel);
            }
        });
    }

    changePage({ detail: { name, id } }) {
        if (name === 'manual-executors-inner-main' && this.state.executor?._id !== id) {
            this.getExecutor(id);
        }
    }

    checkChangeId(isStart) {
        const { levels } = this.props;

        if (
            levels[2] === 'executors' &&
            levels[3] &&
            levels[3] !== 'main' &&
            levels[3] !== this.executorId
        ) {
            this.executorId = levels[3];

            if (!isStart) {
                this.getExecutor();
            }
        }
    }

    handlerSocket({ detail }) {
        const { executor } = this.state;
        const { name, data } = detail;

        if (executor && name === 'executor') {
            const { idOfExecutor, fields } = data;

            if (executor._id === idOfExecutor) {
                this.updateExecutor({ fields });
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getExecutor();
        this.checkChangeId(true);

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentDidUpdate() {
        this.checkChangeId();
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('changePage', this.changePage);
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'executor', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    [
                                        'manual-executors-inner-logs',
                                        'manual-executors-inner-balance',
                                    ].includes(pageName)
                                        ? '_full'
                                        : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-executors-inner' && page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualExecutorsInner);

ManualExecutorsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    scrollToTop: PropTypes.func,
    initCallbackPaysFilter: PropTypes.func,
};
