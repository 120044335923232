import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import UploadMain from './UploadMain.jsx';
import UploadCheck from './UploadCheck.jsx';

import handlerPopup from '../../../functions/crm/handlerPopup';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';

const orderSystemTypes = require('../../../infos/crm/orderSystemTypes.json');

class UploadOrdersPopupStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'main',
        };

        this.getSteps = this.getSteps.bind(this);
        this.renderStep = this.renderStep.bind(this);
        this.setStep = this.setStep.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.filterType = this.filterType.bind(this);
    }

    typesOrder = ['service', 'crm'];

    stepsOrder = ['main', 'check'];

    getSteps() {
        const { currentStep } = this.state;

        return [{ key: currentStep }];
    }

    renderStep({ prop: key }) {
        const { type, file, loadingKey, errors, uploadCorrectDoc } = this.props;

        return (
            <div className={`uploadOrdersPopupUpload__contentStep ${loadingKey ? '_loading' : ''}`}>
                {key === 'main' && (
                    <UploadMain
                        type={type}
                        setStep={this.setStep}
                        uploadFile={this.uploadFile}
                        loadingKey={loadingKey}
                    />
                )}
                {key === 'check' && (
                    <UploadCheck
                        file={file}
                        uploadFile={this.uploadFile}
                        setStep={this.setStep}
                        loadingKey={loadingKey}
                        errors={errors}
                        uploadCorrectDoc={uploadCorrectDoc}
                    />
                )}
            </div>
        );
    }

    uploadFile({ target }) {
        const { uploadFile, loadingKey } = this.props;

        return new Promise((resolve, reject) => {
            if (loadingKey) {
                reject();
            } else {
                this.setStep('check');

                uploadFile({ target }).then(resolve, reject);
            }
        });
    }

    setStep(currentStep, isForce) {
        const { clearForm, loadingKey } = this.props;

        if (!loadingKey || isForce) {
            this.setState({ currentStep }, () => {
                if (currentStep === 'main') {
                    setTimeout(() => {
                        clearForm();
                    }, 300);
                }
            });
        }
    }

    checkCorporationIsTest() {
        const { user } = this.props;
        const corporation = getCurrentCorporation({ user });

        return corporation?.isTest;
    }

    filterType(key) {
        return key === 'crm' || !this.checkCorporationIsTest();
    }

    componentDidMount() {
        const { setStep, setType } = this.props;

        if (this.checkCorporationIsTest()) {
            setType('crm');
        }

        setStep(this.setStep);
    }

    render() {
        const { currentStep } = this.state;
        const { setType, type, updateList } = this.props;

        return (
            <div className="uploadOrdersPopupUpload">
                <div className="uploadOrdersPopupUpload__inner">
                    <div className="uploadOrdersPopupUpload__head _row">
                        {this.typesOrder.filter(this.filterType).map((name) => {
                            const typeInfo = orderSystemTypes[name];
                            const { icon, fullname } = typeInfo;

                            return (
                                <div
                                    className={`uploadOrdersPopupUpload__headLink _col ${
                                        type === name ? '_current' : ''
                                    }`}
                                    key={name}
                                >
                                    <div
                                        className="uploadOrdersPopupUpload__headLinkInner _row _click"
                                        onClick={() => setType(name)}
                                    >
                                        <i className="uploadOrdersPopupUpload__headLinkIcon">
                                            <Icon name={icon} />
                                        </i>
                                        <div className="uploadOrdersPopupUpload__headLinkContent">
                                            {fullname}
                                            <div className="uploadOrdersPopupUpload__headLinkContentCurrent">
                                                {fullname}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className="uploadOrdersPopupUpload__headClose _click"
                            onClick={() => {
                                handlerPopup({ name: 'isUploadOrdersPopupShow', isShow: false });
                            }}
                        >
                            <Icon name="close-circle" />
                        </div>
                    </div>
                    <div className="uploadOrdersPopupUpload__content">
                        <div className="uploadOrdersPopupUpload__contentHead _row">
                            <p className="uploadOrdersPopupUpload__contentHeadTitle">
                                Оформление пачки заказов
                                <br />
                                загрузкой Excel-файла
                            </p>
                            <div className="uploadOrdersPopupUpload__contentHeadPoints _row">
                                {this.stepsOrder.map((key) => (
                                    <div
                                        className={`uploadOrdersPopupUpload__contentHeadPoint ${
                                            currentStep === key ? '_current' : ''
                                        }`}
                                        key={key}
                                    ></div>
                                ))}
                            </div>
                        </div>
                        <ListAbsoluteMain
                            className="uploadOrdersPopupUpload__contentSteps"
                            items={this.getSteps()}
                            renderItem={this.renderStep}
                            classNameItem="uploadOrdersPopupUpload__contentStep"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                            callback={() => {
                                updateList();
                            }}
                            allItems={this.stepsOrder}
                            currentItemKey={currentStep}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopupStep);

UploadOrdersPopupStep.propTypes = {
    setType: PropTypes.func,
    type: PropTypes.string,
    updateList: PropTypes.func,
    uploadFile: PropTypes.func,
    file: PropTypes.object,
    loadingKey: PropTypes.string,
    errors: PropTypes.array,
    clearForm: PropTypes.func,
    uploadCorrectDoc: PropTypes.func,
    setStep: PropTypes.func,
    user: PropTypes.object,
};
