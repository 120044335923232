import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getCorporation from '../../../requests/getCorporation';

import TableInfo from '../../../components/crm/manual/TableInfo.jsx';
import Table from '../../../components/crm/manual/Table.jsx';

import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';

class CorporationsIndex extends Table {
    constructor(props) {
        super(props);
        this.state = {};
    }

    name = 'corporations';

    getItems() {
        const { setInfoHead } = this.props;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'list' });

        return new Promise((resolve) => {
            getCorporation(query).then(
                ({ corporations, isLimit, counter }) => {
                    this.setItems(corporations, false, isLimit, counter).then(() => {
                        setInfoHead({ key: 'counter', value: counter });

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    render() {
        const { items = [], isDisabledScroll, isLimit, isReady, isLoadingFilter } = this.state;

        const { filter = [], handlerFilter, handlerLoaderList } = this.props;
        const isActiveFilter = handlerFilter && !!filter.filter(handlerFilter?.checkEmpty).length;

        return (
            <div ref={this.parent} className={`widget _notPadding ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="corporations"
                                setHeightPage={this.setHeightPage}
                                isActiveFilter={isActiveFilter}
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={isLoadingFilter}
                                handlerLoaderList={handlerLoaderList}
                                isWrap={true}
                                isRowHeight={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(CorporationsIndex);

CorporationsIndex.propTypes = {
    setHeightPage: PropTypes.func,
    filter: PropTypes.array,
    initFilter: PropTypes.func,
    setInfoHead: PropTypes.func,
};
