import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getExecutors from '../../../../requests/getExecutors';

import Table from '../../../../components/crm/manual/Table.jsx';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import TableInfo from '../../../../components/crm/manual/TableInfo.jsx';

class Main extends Table {
    constructor(props) {
        super(props);
        this.state = {};
    }

    name = 'executors';

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getExecutors(query).then(
                ({ executors, isLimit, counter }) => {
                    this.setItems(executors, false, isLimit, counter).then(resolve);
                },
                () => null,
            );
        });
    }

    render() {
        const { items = [], isDisabledScroll, isLimit, isReady, isLoadingFilter } = this.state;

        const { filter = [], handlerFilter, handlerLoaderList } = this.props;
        const isActiveFilter = handlerFilter && !!filter.filter(handlerFilter?.checkEmpty).length;

        return (
            <div ref={this.parent} className={`widget _notPadding ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="executors"
                                setHeightPage={this.setHeightPage}
                                isActiveFilter={isActiveFilter}
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={isLoadingFilter}
                                handlerLoaderList={handlerLoaderList}
                                isBlockSkinny={true}
                                isBlock={true}
                                isNotLink={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Main);

Main.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initFilter: PropTypes.func,
};
