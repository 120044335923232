import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../../../functions/getPage';
import logout from '../../../functions/logout';

import Link from '../../../components/Link.jsx';

import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);

        this.group = React.createRef();
    }

    orderLinks = [{ key: 'main' }, { key: 'proxy' }];

    links = {
        main: {
            key: 'profile-main',
        },
        proxy: {
            key: 'profile-proxy',
        },
    };

    filterLink({ key }) {
        const { user } = this.props;

        return key !== 'proxy' || user?.idOfCurrentCorporation !== 'admin';
    }

    renderLink({ prop: key }) {
        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headLink">
                <div className="widget__headLinkInner _click">{contentOfLink}</div>
            </Link>
        );
    }

    render() {
        const items = this.orderLinks.filter(this.filterLink);

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                <div className="widget__headActionsGroup">
                    <div
                        className="widget__headLink _delete"
                        onClick={() => {
                            logout();
                        }}
                    >
                        <div className="widget__headLinkInner _click">Выйти</div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    typeOrganization: PropTypes.string,
    pagesStore: PropTypes.object,
    user: PropTypes.object,
};
