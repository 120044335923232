import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getModel from '../../../../requests/getModel';
import getCars from '../../../../requests/getCars';

import Main from './inner/Main.jsx';
import Executors from './inner/Executors.jsx';
import Trailers from './inner/Trailers.jsx';
import Docs from './inner/Docs.jsx';
import Logs from './inner/Logs.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Inner from '../../../../components/crm/manual/Inner.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';
import { dispatcher } from '../../../../redux/redux';
import setPermissions from '../../../../functions/crm/setPermissions';

class ManualCarsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {
            filesDelete: [],
            isShowLoaderList: false,
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
        this.changeCar = this.changeCar.bind(this);
        this.updateCar = this.updateCar.bind(this);
        this.backToSave = this.backToSave.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getCar = this.getCar.bind(this);
        this.handlerServices = this.handlerServices.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    mainPage = 'manual-cars';

    targetName = 'car';

    targetNameSave = 'carSave';

    pages = {
        'manual-cars-inner-main': {
            render() {
                const { isInit, car, carSave, filesDelete } = this.state;
                const { checkNew, getParent } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Main
                                isInit={isInit}
                                car={car}
                                carSave={carSave}
                                changeCar={this.changeCar}
                                uploadFile={this.uploadFile}
                                deleteFile={this.deleteFile}
                                formDataFiles={this.formDataFiles}
                                filesDelete={filesDelete}
                                clearFiles={this.clearFiles}
                                setHeightPage={this.setHeightPage}
                                backToSave={this.backToSave}
                                isNew={checkNew()}
                                getCar={this.getCar}
                                getParent={getParent}
                                handlerServices={this.handlerServices}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-cars-inner-main',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-cars-inner-executors': {
            render() {
                const { isInit, car, carSave, isShowLoaderList } = this.state;
                const { getParent } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Executors
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    car={car}
                                    carSave={carSave}
                                    getParent={getParent}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'manual-cars-inner-trailers': {
            render() {
                const { isInit, car, isShowLoaderList } = this.state;
                const { getParent, scrollToTop } = this.props;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner">
                                <Trailers
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    car={car}
                                    getParent={getParent}
                                    scrollToTop={scrollToTop}
                                    handlerLoaderList={this.handlerLoaderList}
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._deep2._manual-cars-inner-trailers',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'manual-cars-inner-docs': {
            render() {
                const { isInit, car, carSave, filesDocsDelete } = this.state;
                const { getParent } = this.props;

                return (
                    <div className="widget__pageBox _scroll">
                        <div className="widget__pageInner">
                            <Docs
                                isInit={isInit}
                                setHeightPage={this.setHeightPage}
                                car={car}
                                carSave={carSave}
                                formDataDocs={this.formDataDocs}
                                changeDoc={this.changeDoc}
                                addDoc={this.addDoc}
                                uploadFile={this.uploadFileDoc}
                                deleteFile={this.deleteFileDoc}
                                deleteDoc={this.deleteDoc}
                                handlerError={this.handlerErrorDoc}
                                backToSaveDocs={this.backToSaveDocs}
                                clearFormData={this.clearFormDataDocs}
                                filesDocsDelete={filesDocsDelete}
                                getParent={getParent}
                                parentScroll={(() =>
                                    this.parent.current
                                        ?.querySelector(
                                            '.widget__page._deep2._manual-cars-inner-docs',
                                        )
                                        ?.querySelector('.widget__pageBox'))()}
                                checkRights={this.checkPermissions}
                            />
                        </div>
                    </div>
                );
            },
        },
        'manual-cars-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, car } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    car={car}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    formDataFiles = new FormData();

    deleteFile({ name }) {
        this.formDataFiles.delete(name);

        this.setState((state) => {
            const newState = { ...state };
            const car = JSON.parse(JSON.stringify(newState.car));

            const indexFile = car.images.files.findIndex((item) => item.name === name);

            if (indexFile !== -1) {
                newState.filesDelete.push(name);
                car.images.files[indexFile].file = {};
            }

            newState.car = car;

            return newState;
        });
    }

    uploadFile({ name, files }) {
        const [file] = files;
        const { object } = file;

        this.formDataFiles.set(name, object);

        this.setState((state) => {
            const newState = { ...state };
            const car = JSON.parse(JSON.stringify(newState.car));

            const indexFile = car.images.files.findIndex((item) => item.name === name);

            if (indexFile !== -1) {
                car.images.files[indexFile].isLocal = true;
                car.images.files[indexFile].file = file;
                car.images.files[indexFile].file.pathLocal = file.path;
            }

            newState.car = car;

            return newState;
        });
    }

    clearFiles() {
        this.formDataFiles = new FormData();
        this.setState({ filesDelete: [] });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            }
        }
    }

    handlerServices({ key, values }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const car = JSON.parse(JSON.stringify(newState.car));
                let indexService = car.services.findIndex((service) => service.key === key);

                if (indexService === -1) {
                    car.services.push({ key });
                    indexService = car.services.length - 1;
                } else if (!values) {
                    car.services.splice(indexService, 1);
                }

                if (values) {
                    values.forEach(({ prop, value }) => {
                        car.services[indexService][prop] = value;

                        if (prop === 'value' && value !== 'dateAction') {
                            car.services[indexService].date = null;
                        }
                        if (prop === 'value' && value === 'no' && indexService !== -1) {
                            car.services.splice(indexService, 1);
                        }
                    });
                }

                newState.car = car;

                return newState;
            }, resolve);
        });
    }

    changeCar({ action, type, name, value, modelsOfCar }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const car = JSON.parse(JSON.stringify(newState.car));

                    if (action === 'change' || !action) {
                        if (!type) {
                            if (name === 'loadings') {
                                const indexItem = car[name].findIndex(
                                    (item) => item.name === value,
                                );

                                if (indexItem === -1) {
                                    car[name].push({ name: value });
                                } else {
                                    car[name].splice(indexItem, 1);
                                }
                            } else {
                                car[name] = value;

                                if (name === 'mark') {
                                    car.model = null;
                                }
                                if (name === 'model' && !car.mark) {
                                    const model = modelsOfCar.find(
                                        (modelLoop) => modelLoop._id === car.model,
                                    );

                                    car.mark = model.idOfMark;
                                }
                            }
                        } else if (type === 'images') {
                            car.images[name] = value;
                        }
                    }

                    newState.car = car;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    updateCar({ fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const car = JSON.parse(JSON.stringify(newState.car));

            Object.keys(fields).forEach((prop) => {
                if (typeof fields[prop] === 'object') {
                    if (Array.isArray(fields[prop])) {
                        car[prop] = fields[prop];
                    } else if (fields[prop] === null) {
                        car[prop] = null;
                    } else {
                        car[prop] = { ...car[prop], ...fields[prop] };
                    }
                } else {
                    car[prop] = fields[prop];
                }
            });

            newState.car = car;
            newState.carSave = JSON.parse(JSON.stringify(car));

            return newState;
        });
    }

    setCar(car) {
        return new Promise((resolve) => {
            this.setState(
                {
                    car,
                    carSave: JSON.parse(JSON.stringify(car)),
                },
                () => {
                    this.setState({ isInit: true }, () => {
                        this.setHeightPage();
                    });

                    resolve();
                },
            );
        });
    }

    getCar(idOfCar = this.props.levels[3]) {
        const { setInfoHead, forCarExecutorId, forTrailerCarId } = this.props;

        return new Promise((resolve) => {
            setTimeout(() => {
                if (idOfCar === 'new') {
                    getModel({ type: 'model', name: 'car' }).then(
                        ({ model: car }) => {
                            const resultCar = car;

                            if (process.env.REACT_APP_ENV === 'local') {
                                resultCar.mark = '61f8ca019865e51a8e9c87ec';
                                resultCar.model = '61f8ca019865e51a8e9c87eb';
                                resultCar.status = 'active';
                                resultCar.sts = '1234';
                                resultCar.vin = '1234';
                                resultCar['perm-mass'] = 5000;
                                resultCar['full-mass'] = 4000;
                                resultCar.pallets = 10;
                                resultCar.tonnage = 2000;
                                resultCar.long = 200;
                                resultCar.width = 200;
                                resultCar.height = 200;
                                resultCar.heightWithFloor = 200;
                                resultCar.heightCargo = 200;
                                resultCar.loadings = [{ name: 'back' }];
                                resultCar.bodywork = 'furgon';
                                resultCar.images.period = 'day';
                                resultCar.number = new Date().getTime().toString();
                            }

                            if (forCarExecutorId) {
                                resultCar.type = 'car';
                            }

                            if (forTrailerCarId) {
                                resultCar.type = 'trailer';
                            }

                            this.setCar(resultCar).then(
                                () => {
                                    setInfoHead({ key: 'car', value: { isNew: true } });

                                    resolve();
                                },
                                () => null,
                            );
                        },
                        () => null,
                    );
                } else {
                    getCars({ id: idOfCar }).then(({ car }) => {
                        this.setCar(car).then(() => {
                            setInfoHead({ key: 'car', value: car });

                            resolve();
                        });
                    }, this.handlerErrorGetModel);
                }
            }, 300);
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'cars',
                    rules: ['update'],
                },
            ],
        });
    }

    changePage({ detail: { name, id } }) {
        if (name === 'manual-cars-inner-main' && this.state.car?._id !== id) {
            this.getCar(id);
        }
    }

    checkChangeId(isStart) {
        const { levels } = this.props;

        if (levels[2] === 'cars' && levels[3] && levels[3] !== 'main' && levels[3] !== this.carId) {
            this.carId = levels[3];

            if (!isStart) {
                this.getCar();
            }
        }
    }

    handlerSocket({ detail }) {
        const { car } = this.state;
        const { name, data } = detail;
        const { idOfCar, fields } = data;

        if (car && name === 'car' && car._id === idOfCar) {
            this.updateCar({ fields });
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getCar();
        this.checkChangeId(true);

        document.addEventListener('changePage', this.changePage);
        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentDidUpdate() {
        this.checkChangeId();
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('changePage', this.changePage);
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'car', value: undefined });

        dispatcher({ type: 'forCarExecutorId', data: null });
        dispatcher({ type: 'forTrailerCarId', data: null });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    ['manual-cars-inner-logs'].includes(pageName) ? '_full' : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-cars-inner' && page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        forCarExecutorId: state.forCarExecutorId,
        forTrailerCarId: state.forTrailerCarId,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualCarsInner);

ManualCarsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    forCarExecutorId: PropTypes.string,
    forTrailerCarId: PropTypes.string,
};
