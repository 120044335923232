import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import File from '../card/File.jsx';
import AnimateChange from '../../../AnimateChange.jsx';

import getFormatedDate from '../../../../functions/getFormatedDate';

const blocksManual = require('../../../../infos/crm/blocksManual.json');

class ManualExecutorsFiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    getInfo() {
        const { type } = this.props;

        if (type === 'passport') {
            return {
                images: [
                    {
                        name: 'forward',
                        description: (
                            <>
                                Сторона <span>лицевая</span>
                            </>
                        ),
                    },
                    {
                        name: 'back',
                        description: (
                            <>
                                Сторона <span>прописки</span>
                            </>
                        ),
                    },
                ],
            };
        }

        if (type === 'driverPassport') {
            return {
                images: [
                    {
                        name: 'forward',
                        description: (
                            <>
                                Сторона <span>лицевая</span>
                            </>
                        ),
                    },
                    {
                        name: 'back',
                        description: (
                            <>
                                Сторона <span>обратная</span>
                            </>
                        ),
                    },
                ],
            };
        }

        return {};
    }

    render() {
        const {
            title,
            images = {},
            uploadFile,
            deleteFile,
            type,
            typeFiles,
            checkEditmode,
        } = this.props;
        const files = images?.files || [];
        const { date } = images;
        const dateString = date ? getFormatedDate({ date: new Date(date) }) : '–';

        const { images: templateImages } = this.getInfo();

        const { length: len } = files.filter((item) => item.file?.path);
        const description = `${len}/2`;

        return (
            <div className="manualCard _executorImages">
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__files">
                        <div className="manualFiles _col _executor">
                            <div className="manualFiles__inner">
                                {templateImages.map((image) => {
                                    const fileParent = files.find(
                                        (item) => item.name === image.name,
                                    );
                                    const file = fileParent?.file;
                                    const isLocal = fileParent?.isLocal;

                                    return (
                                        <div className="manualFiles__item" key={image.name}>
                                            <File
                                                _id={fileParent?._id}
                                                files={files
                                                    .filter((fileLoop) => fileLoop.file.path)
                                                    .map((fileLoop) => ({
                                                        _id: fileLoop._id,
                                                        path: fileLoop.file.path,
                                                        type: fileLoop.file.type,
                                                    }))}
                                                file={file}
                                                type={type}
                                                title={blocksManual.executor[typeFiles].title}
                                                folder="executors"
                                                name={image.name}
                                                uploadFile={uploadFile}
                                                deleteFile={deleteFile}
                                                isDisabled={!checkEditmode()}
                                                isLocal={isLocal}
                                            >
                                                {image.description}
                                            </File>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="manualFiles__info _row">
                                Последняя загрузка:{' '}
                                <AnimateChange
                                    className="manualFiles__infoItem"
                                    prop={dateString}
                                    type="_translateMedium"
                                >
                                    {dateString}
                                </AnimateChange>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsFiles);

ManualExecutorsFiles.propTypes = {
    type: PropTypes.string,
    typeFiles: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.object,
    checkEditmode: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
};
