import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../Animate.jsx';
import Icon from '../../../Icon.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';

class ManualList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterItem = this.filterItem.bind(this);
        this.getParams = this.getParams.bind(this);

        this.parent = React.createRef();
    }

    orderActions = ['add', 'delete'];

    getParams() {
        const { keyProp, items } = this.props;

        return new Promise((resolve) => {
            const page = this.parent.current;
            const resultParams = this.state.resultParams || {};

            if (page) {
                const { offsetWidth: widthPage } = page;

                const elems = items
                    .filter(this.filterItem)
                    .map((item) => ({
                        className: `.manualList__item[data-id="${item[keyProp]}"]`,
                        id: item[keyProp],
                    }))
                    .concat(...[{ className: '.manualList__items', id: 'items' }]);

                const params = getRealParams({
                    parent: page,
                    elems,
                    width: widthPage,
                    classNames: ['_static'],
                    isClearStyleParent: true,
                    clearStyleElems: ['.manualList__items'],
                });

                items.forEach((item) => {
                    if (params[item[keyProp]]) {
                        const { offsetTop, width, offsetLeft } = params[item[keyProp]];

                        resultParams[item[keyProp]] = {
                            width,
                            offsetTop,
                            offsetLeft,
                        };
                    }
                });

                this.setState({ resultParams, heightItems: params.items.height }, () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    getItemsSort() {
        const { keyProp, items = [], checkActive } = this.props;

        return JSON.parse(JSON.stringify(items)).sort((a, b) => {
            const weightA = checkActive({ [keyProp]: a[keyProp] }) ? 1 : 0;
            const weightB = checkActive({ [keyProp]: b[keyProp] }) ? 1 : 0;

            return weightB - weightA;
        });
    }

    filterItem(item) {
        const { isDisabled, checkActive, keyProp } = this.props;

        return isDisabled ? checkActive({ [keyProp]: item[keyProp] }) : true;
    }

    checkChange() {
        const { prop } = this.props;

        if (prop !== this.prop) {
            const inner = this.parent.current.querySelector('.manualList__inner');
            this.prop = prop;

            this.getParams();

            inner.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    componentDidMount() {
        this.checkChange();
    }

    componentDidUpdate() {
        this.checkChange();
    }

    render() {
        const { resultParams, heightItems = 0 } = this.state;
        const { items = [], checkActive, handler, isDisabled, keyProp, keyContent } = this.props;
        const itemsSort = this.getItemsSort();
        return (
            <div ref={this.parent} className="manualList">
                <div className="manualList__inner">
                    <div className="manualList__items" style={{ height: `${heightItems}px` }}>
                        {items.map((item) => {
                            const { [keyProp]: name, [keyContent]: content } = item;
                            const resultParam = resultParams?.[name];
                            const indexSort = itemsSort.findIndex(
                                (itemSort) => itemSort[keyProp] === name,
                            );
                            const isActive = checkActive({ [keyProp]: name });

                            const stateAction = isActive ? 'delete' : 'add';

                            return (
                                <Animate
                                    className={`manualList__item _row ${
                                        indexSort === items.length - 1 ? '_last' : ''
                                    } ${isActive ? '_active' : ''}`}
                                    key={name}
                                    data-id={name}
                                    isShow={this.filterItem(item)}
                                    actionInit={this.getParams}
                                    actionPrevRemove={this.getParams}
                                    style={{
                                        transform: `translate(${resultParam?.offsetLeft}px,${resultParam?.offsetTop}px)`,
                                        order: indexSort,
                                    }}
                                >
                                    <div className="manualList__itemContent">{content}</div>
                                    <div className="manualList__itemValue">
                                        <Animate
                                            className="manualList__itemValueInner _row"
                                            isShow={isDisabled && isActive}
                                        >
                                            Да
                                        </Animate>
                                        <Animate
                                            className="manualList__itemAction"
                                            onClick={() => handler({ [keyProp]: name })}
                                            isShow={!isDisabled}
                                            isSmoothShow={true}
                                        >
                                            <div className={`action _col _animate _${stateAction}`}>
                                                {this.orderActions.map((action) => (
                                                    <Animate
                                                        key={action}
                                                        className={`action__icon _${action}`}
                                                        isShow={action === stateAction}
                                                    >
                                                        <Icon name={action} />
                                                    </Animate>
                                                ))}
                                            </div>
                                        </Animate>
                                    </div>
                                </Animate>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualList);

ManualList.propTypes = {
    items: PropTypes.array,
    keyProp: PropTypes.string,
    checkActive: PropTypes.func,
    handler: PropTypes.func,
    prop: PropTypes.any,
    isDisabled: PropTypes.bool,
    keyContent: PropTypes.string,
};
