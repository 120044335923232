import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsolute from '../../../../ListAbsolute.jsx';
import Select from '../../../../Select.jsx';
import AnimateChange from '../../../../AnimateChange.jsx';
import Animate from '../../../../Animate.jsx';
import Icon from '../../../../Icon.jsx';

import getWidthText from '../../../../../functions/getWidthText';
import removeTransition from '../../../../../functions/removeTransition.ts';
import handlerSupportCursor from '../../../../../functions/handlerSupportCursor';

class HeadTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setWidthCategory = this.setWidthCategory.bind(this);

        this.renderCategory = this.renderCategory.bind(this);
        this.renderName = this.renderName.bind(this);

        this.parent = React.createRef();
    }

    getNameInputs() {
        const { type, name } = this.props;

        return [{ key: type, name }];
    }

    getItemCategory() {
        const { isNew } = this.props;

        return isNew ? [{ key: 'category' }] : [];
    }

    setWidthCategory({ width: widthCategory }) {
        if (widthCategory !== this.state.widthCategory) {
            this.setState({ widthCategory });
        }
    }

    filterCarName(name) {
        const { category } = this.props;
        const nameNumber = +name;

        if (name.length > 1 && !Number.isNaN(nameNumber) && name[name.length - 1] !== '.') {
            if (category === 'cars-less3') {
                if (name === '00' || nameNumber < 0) {
                    return '0.0';
                }
                if (nameNumber > 2.5) {
                    return '2.5';
                }
            }

            if (category === 'cars-less5') {
                if (nameNumber < 3) {
                    return '3.0';
                }
                if (nameNumber > 5) {
                    return '5.0';
                }
            }

            if (category === 'cars-more5') {
                if (nameNumber < 7) {
                    return '7.0';
                }
                if (nameNumber > 50) {
                    return '50.0';
                }
            }
        }

        return name;
    }

    renderCategory({ prop: key, isShow }) {
        const { categories, category, handlerConfiguration, idOfConfig, errors } = this.props;
        const items = categories;

        return (
            <div
                className={`manualTariffsConfigurationHeadTitle__categoryItem ${
                    isShow ? '_show' : ''
                }`}
                key={key}
                data-key={key}
            >
                <Select
                    support="Выберите категорию"
                    name="category"
                    value={category}
                    callback={({ value }) =>
                        new Promise((resolve) => {
                            handlerConfiguration({
                                action: 'change',
                                name: 'category',
                                value,
                            }).then(resolve);
                        })
                    }
                    className="_manualTariff _fontMedium"
                    isDisabled={false}
                    isEditmode={true}
                    items={items}
                    error={errors?.[idOfConfig]?.category}
                />
            </div>
        );
    }

    getCondForEdit({ type = this.props.type }) {
        const { isNew, isEdit } = this.props;

        return (isEdit || isNew) && type === 'car';
    }

    getName({ name = this.props.name, type = this.props.type }) {
        const nameResult = type === 'car' ? `${name || ''}т` : name;

        return nameResult;
    }

    getWidthName({ type }) {
        const name = this.getName({ type });
        const isEdit = this.getCondForEdit({ type });

        return (
            getWidthText({
                text: name,
                weight: 900,
            }) + (isEdit ? 12 : 0)
        );
    }

    handlerName({ value }) {
        const { type, handlerConfiguration } = this.props;
        let valueResult = value;

        if (type === 'car') {
            valueResult = '';

            const valueFilter = value.replace(/[^0-9.]/g, '');
            let indexDot = -1;

            valueFilter.split('').forEach((item, key) => {
                if (item === '.') {
                    if (indexDot === -1) {
                        indexDot = key;
                        valueResult += '.';
                    }
                } else if (indexDot === -1 || indexDot + 2 >= key) {
                    valueResult += indexDot === -1 ? item.replace(/[^0-9]/g, '') : item;
                }
            });

            valueResult = this.filterCarName(valueResult);

            const valueItems = valueResult.split('.');

            if (valueItems.length === 2 && valueItems.every((item) => item)) {
                valueResult = valueItems.map((item) => +item).join('.');
            }

            // if (valueResult.length === (+valueResult).toString().length) {
            //     valueResult = +(+valueResult).toFixed(2);
            // }

            // console.log(+valueResult)
        }

        removeTransition({
            item: '.manualTariffsConfigurationHeadTitle__infoName',
        });

        return new Promise((resolve) => {
            handlerConfiguration({
                action: 'change',
                name: 'name',
                value: valueResult,
            }).then(resolve);
        });
    }

    renderName({ item: itemCome, prop: key, isShow }) {
        const { idOfConfig, errors } = this.props;
        const name = this.getName({ name: itemCome.name, type: itemCome.key });
        const widthName = this.getWidthName({ type: itemCome.key });
        const isEdit = this.getCondForEdit({ type: itemCome.key });
        const isError = errors?.[idOfConfig]?.name;

        return (
            <div
                className={`manualTariffsConfigurationHeadTitle__infoNameItem ${
                    isShow ? '_show' : ''
                } _${key} ${isEdit ? '_edit' : ''} ${isError ? '_error' : ''}`}
                data-key={key}
                key={key}
            >
                <input
                    type="text"
                    className="manualTariffsConfigurationHeadTitle__infoNameInput"
                    value={name}
                    style={{
                        width: `${widthName}px`,
                    }}
                    onChange={({ target }) => {
                        this.handlerName({ value: target.value }).then(() => {
                            target.setSelectionRange(
                                this.props.name.length,
                                this.props.name.length,
                            );
                        });
                    }}
                />
            </div>
        );
    }

    description = '–';

    render() {
        const { widthCategory = 0 } = this.state;
        const { isNew, item, category, isDisabled } = this.props;
        const isEdit = this.getCondForEdit({});

        if (item) {
            const { parameters } = item;
            const { long, width, height } = parameters;

            const toFormat = (num) => +(+num / 100).toFixed(1);

            const volume = +(toFormat(long) * toFormat(width) * toFormat(height)).toFixed(1);

            this.description = `${toFormat(long)}x${toFormat(width)}x${toFormat(
                height,
            )}м (${volume}м³)`;
        }

        const description = this.description;

        return (
            <div
                ref={this.parent}
                className={`manualTariffsConfigurationHeadTitle _row ${isEdit ? '_edit' : ''}`}
            >
                {isDisabled && (
                    <i
                        className="manualTariffsConfigurationHeadTitle__lock"
                        onMouseEnter={(e) =>
                            handlerSupportCursor({
                                action: 'enter',
                                content:
                                    'Базовый тариф можно менять<br/>только со страницы основного тарифа',
                                e,
                                data: { className: '_normalCase _center' },
                            })
                        }
                        onMouseLeave={(e) =>
                            handlerSupportCursor({
                                action: 'leave',
                                e,
                            })
                        }
                    >
                        <Icon name="lock" />
                    </i>
                )}
                <Animate
                    className="manualTariffsConfigurationHeadTitle__category"
                    style={{ width: `${widthCategory}px` }}
                    isShow={!!isNew}
                >
                    <ListAbsolute
                        parent={(() =>
                            this.parent.current?.querySelector(
                                '.manualTariffsConfigurationHeadTitle__category',
                            ))()}
                        items={this.getItemCategory()}
                        renderItem={this.renderCategory}
                        classNameItem="manualTariffsConfigurationHeadTitle__categoryItem"
                        prop="key"
                        setParamsParent={this.setWidthCategory}
                        paramsParent={{ width: 'auto' }}
                        // name="test"
                        callback={() => {
                            if (!this.isInitCategory) {
                                this.setState({ isInitCategory: true });
                            }
                        }}
                    />
                </Animate>
                <Animate className="manualTariffsConfigurationHeadTitle__info _row" isShow={!!category}>
                    <div
                        className="manualTariffsConfigurationHeadTitle__infoName"
                        style={{ width: `${this.getWidthName({})}px` }}
                    >
                        <ListAbsolute
                            parent={(() =>
                                this.parent.current?.querySelector(
                                    '.manualTariffsConfigurationHeadTitle__infoName',
                                ))()}
                            items={this.getNameInputs()}
                            renderItem={this.renderName}
                            classNameItem="manualTariffsConfigurationHeadTitle__infoNameItem"
                            prop="key"
                            paramsParent={{ width: 'auto' }}
                            // name="test"
                            propsForUpdate={['name']}
                            callback={() => {
                                if (!this.isInitName) {
                                    this.setState({ isInitName: true });
                                }
                            }}
                        />
                    </div>
                    <div className="manualTariffsConfigurationHeadTitle__infoDel">:</div>
                    <div className="manualTariffsConfigurationHeadTitle__infoDescription">
                        <AnimateChange
                            className="manualTariffsConfigurationHeadTitle__infoDescriptionItem"
                            prop={description}
                            type="_translateMedium"
                        >
                            {`${description}`}
                        </AnimateChange>
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(HeadTitle);

HeadTitle.propTypes = {
    serverData: PropTypes.object,
    categories: PropTypes.array,
    isNew: PropTypes.bool,
    category: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    isEdit: PropTypes.bool,
    isDisabled: PropTypes.bool,
    item: PropTypes.object,
    handlerConfiguration: PropTypes.func,
    errors: PropTypes.object,
    idOfConfig: PropTypes.string,
};
