import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Pages from '../../components/Pages.jsx';

import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import Back from '../../components/crm/widget/Back.jsx';

import AnimateChange from '../../components/AnimateChange.jsx';
import Index from './corporations/Index.jsx';
import Inner from './Settings.jsx';
import HeadInner from './settings/HeadInner.jsx';

class Corporations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setInfoHead = this.setInfoHead.bind(this);

        this.parent = React.createRef();
    }

    headPages = {
        'corporations-index': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Всего
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                        >
                            <>({counter})</>
                        </AnimateChange>
                    </div>
                );
            },
        },
        'corporations-inner': {
            render() {
                const { infoHead } = this.state;
                const { corporation } = infoHead;
                const name = corporation?.shortName || corporation?.opfName;

                return (
                    <div className="widget__headNameItemContent">
                        <AnimateChange
                            prop={name}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner _notMargin"
                        >
                            <>{name}</>
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    headActions = {
        'corporations-index': {
            render() {
                return <></>;
            },
        },
        'corporations-inner': {
            render() {
                const { infoHead } = this.state;
                const { corporation } = infoHead;

                return <HeadInner inner={true} corporation={corporation} />;
            },
        },
    };

    pages = {
        'corporations-index': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Index setInfoHead={this.setInfoHead} />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'corporations-inner': {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Inner setInfoHead={this.setInfoHead} inner={true} />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    render() {
        const { counterChangePage } = this.props;
        let { heightPage = 0 } = this.state;

        if (!heightPage) {
            heightPage = 500;
        }

        return (
            <div ref={this.parent} className={`widget _fix _parent _ready`}>
                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Корпорации"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) => page.parentName === 'corporations'}
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) => page.parentName === 'corporations'}
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _scroll _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox" style={{ height: `${heightPage}px` }}>
                            <Pages
                                classNamePage="widget__page _deep1"
                                filter={(page) => page.parentName === 'corporations'}
                                pages={this.pages}
                                context={this}
                                getClassName={(name) =>
                                    name === 'corporations-index' ? '_wide' : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
        serverData: state.serverData,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Corporations);

Corporations.propTypes = {
    user: PropTypes.object,
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    serverData: PropTypes.object,
    counterChangePage: PropTypes.number,
};
