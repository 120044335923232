import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from '../../order-details/Main.jsx';

class OrderDetailsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            isReady,
            order,
            orderSave,
            points,
            isInitOfMap,
            mapInfo,
            ymaps,
            map,
            newOrder,
            counterSort,
            infoRoute,
            handlerRoute,
            changeOrder,
            setOrder,
            handlerServices,
            checkServices,
            getCondForChange,
            cargoList,
            setHeightPage,
            getParent,
            parentScroll,
            checkNew,
            handlerDate,
            date,
            times,
            getOrder,
            isProccessOptimization,
            isOptimize,
            dateOfOrder,
            checkRights,
            handlerSmsService,
        } = this.props;

        const props = {
            isReady,
            handlerRoute,
            order,
            orderSave,
            points,
            mapInfo,
            isInitOfMap,
            ymaps,
            map,
            newOrder,
            counterSort,
            changeOrder,
            setOrder,
            handlerServices,
            checkServices,
            infoRoute,
            getCondForChange,
            cargoList,
            setHeightPage,
            getParent,
            parentScroll,
            checkNew,
            handlerDate,
            date,
            times,
            getOrder,
            isProccessOptimization,
            isOptimize,
            dateOfOrder,
            checkRights,
            handlerSmsService,
        };

        return <Main {...props} />;
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderDetailsMain);

OrderDetailsMain.propTypes = {
    isReady: PropTypes.bool,
    order: PropTypes.object,
    orderSave: PropTypes.object,
    points: PropTypes.array,
    isInitOfMap: PropTypes.bool,
    mapInfo: PropTypes.object,
    ymaps: PropTypes.object,
    map: PropTypes.object,
    newOrder: PropTypes.object,
    counterSort: PropTypes.number,
    infoRoute: PropTypes.object,
    handlerFilesOfDoc: PropTypes.func,
    docsFormData: PropTypes.object,
    handlerDocs: PropTypes.func,
    handlerRoute: PropTypes.object,
    changeOrder: PropTypes.func,
    setOrder: PropTypes.func,
    handlerServices: PropTypes.func,
    checkServices: PropTypes.func,
    getCondForChange: PropTypes.func,
    clearDocsForm: PropTypes.object,
    cargoList: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    parentScroll: PropTypes.object,
    checkNew: PropTypes.func,
    handlerDate: PropTypes.func,
    date: PropTypes.string,
    times: PropTypes.object,
    getOrder: PropTypes.func,
    isProccessOptimization: PropTypes.bool,
    isOptimize: PropTypes.bool,
    dateOfOrder: PropTypes.string,
    checkRights: PropTypes.func,
    handlerSmsService: PropTypes.func,
};
