import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFinal from '../../classes/order/Final';
import HandlerTariff from '../../classes/order/Tariffs';
import HandlerServices from '../../classes/order/Services';

import setServerData from '../../functions/setServerData';
import stopPropagationClick from '../../functions/stopPropagationClick';

import Icon from '../Icon.jsx';
import InfoAmount from './InfoAmount.jsx';
import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import TariffsList from './TariffsList.jsx';
import TariffsServiceList from './TariffsServiceList.jsx';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import Field from '../Field.jsx';
import getWidthInput from '../../functions/getWidthInput';

class DetailsTariff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowInfo: false,
            isShowList: false,
        };

        this.changeTariff = this.changeTariff.bind(this);
        this.handlerList = this.handlerList.bind(this);
        this.handlerMissClickInfo = this.handlerMissClickInfo.bind(this);
        this.handlerMissClickList = this.handlerMissClickList.bind(this);

        this.parent = React.createRef();
    }

    getNameOfTariff() {
        const { order } = this.props;

        if (order) {
            const { tariffInfo } = order;

            if (tariffInfo) {
                const { name } = tariffInfo;

                if (name !== this.state.nameOfTariff) {
                    this.setState({ nameOfTariff: name });
                }
            }
        }
    }

    handlerInfo(isShowInfo = !this.state.isShowInfo) {
        const { editmode } = this.props;

        return new Promise((resolve) => {
            editmode
                .handlerEdit({
                    editCurrent: isShowInfo ? 'tariff' : null,
                })
                .then(() => {
                    this.setState({ isShowInfo }, resolve);
                });
        });
    }

    handlerList(isShowList = !this.state.isShowList) {
        const { editmode } = this.props;

        return new Promise((resolve) => {
            editmode
                .handlerEdit({
                    editCurrent: isShowList ? 'tariff' : null,
                })
                .then(() => {
                    this.setState({ isShowList }, resolve);
                });
        });
    }

    checkList() {
        const { isShowList } = this.state;

        if (this.props.editName !== this.editName) {
            this.editName = this.props.editName;

            if (isShowList && this.editName === null) {
                this.setState({ isShowList: false });
            }
        }
    }

    changeTariff({ tariff, id, idOfConfig, idOfItem, isNds, customPrice }) {
        return new Promise((resolve) => {
            const { order, changeOrder, isNew } = this.props;

            const props =
                order.systemType === 'crm'
                    ? {
                          tariff: {
                              ...order.tariff,
                              customPrice,
                              ...(id ? { id } : {}),
                              ...(idOfConfig ? { idOfConfig } : {}),
                              ...(idOfItem ? { idOfItem } : {}),
                          },
                          ...(isNew
                              ? {
                                    ...(tariff ? { tariffInfo: tariff } : {}),
                                    services: order.services.filter(
                                        (servive) => servive.key === 'forcePointsOrder',
                                    ),
                                }
                              : {}),
                      }
                    : {
                          tariff: {
                              ...order.tariff,
                              ...(id ? { id } : {}),
                              ...(checkValueOfEmpty(isNds) ? { isNds } : {}),
                          },
                      };

            changeOrder({
                props,
            }).then(() => {
                // if (isHide) {
                //     this.handlerList(false);
                // }

                resolve();
            });
        });
    }

    handlerTariffs = new HandlerTariff({ context: this });

    handlerServices = new HandlerServices({ context: this });

    handlerFinal = new HandlerFinal({ context: this });

    getTotalInfo() {
        const { order, points, infoRoute, isNew } = this.props;

        if (order && isNew) {
            const settings = {
                type: order.type,
                typeOfTariff: order.tariffInfo?.config?.type,
                ...(order.tariffInfo
                    ? {
                          currentItem: {
                              parameters: order.tariffInfo?.parameters,
                              services: order.tariffInfo?.services,
                              key: order.tariffInfo?.key,
                              _id: order.tariffInfo?._id,
                              customPrice: order.tariff?.customPrice,
                          },
                      }
                    : {}),
                configurationWorker: order?.tariffInfo?.configurationWorker,
                points,
                infoRoute,
                services: order.services,
                onlyIsActive: true,
                counterTime: this.getCounterTime(),
                systemType: order.systemType,
                corporationTariff: order.corporationTariff,
                serviceCompany: order.serviceCompany,
            };

            return this.handlerFinal.getItems(settings);
        }

        return order?.infoAmount;
    }

    getTotalAmount() {
        const { order, isNew } = this.props;

        if (order) {
            if (isNew) {
                const total = this.getTotalInfo().reduce(
                    (sum, item) => sum + (typeof item.price === 'number' ? +item.price : 0),
                    0,
                );

                return total;
            }
        }

        return order?.amount || 0;
    }

    getCounterTime() {
        const { order, infoRoute } = this.props;
        const minTime = Math.ceil((order?.tariffInfo?.parameters?.minTime || 0) / 60);
        const duration = Math.ceil((infoRoute?.duration || 0) / 60);

        return duration > minTime ? duration : minTime;
    }

    checkPrice() {
        const { handlerChangedPrice } = this.props;
        const price = this.getTotalAmount();

        if (price !== this.savePrice) {
            this.savePrice = price;

            handlerChangedPrice({ action: 'change', value: price });
        }
    }

    handlerMissClickInfo({ target, detail }) {
        const { isShowInfo } = this.state;
        const window = this.parent.current.querySelector('.orderDetailsCard__tariffInfoWindow');
        const resultTarget = detail?.target || target;

        if (isShowInfo && resultTarget !== window && !window.contains(resultTarget)) {
            this.handlerInfo(false);
        }
    }

    handlerMissClickList({ target }) {
        const { isShowList } = this.state;
        const list = this.parent.current.querySelector('.orderDetailsCard__tariffList');

        if (isShowList && target !== list && !list.contains(target)) {
            this.handlerList(false);
        }
    }

    componentDidMount() {
        this.getNameOfTariff();
        this.checkList();
        this.checkPrice();

        setServerData('orderServices');
        setServerData('orderServiceTariffs');

        document.addEventListener('click', this.handlerMissClickInfo);
        document.addEventListener('continuePropagationClick', this.handlerMissClickInfo);

        document.addEventListener('click', this.handlerMissClickList);
        document.addEventListener('continuePropagationClick', this.handlerMissClickList);
    }

    componentDidUpdate() {
        this.getNameOfTariff();
        this.checkList();
        this.checkPrice();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClickInfo);
        document.removeEventListener('continuePropagationClick', this.handlerMissClickInfo);

        document.removeEventListener('click', this.handlerMissClickList);
        document.removeEventListener('continuePropagationClick', this.handlerMissClickList);
    }

    render() {
        const { isShowInfo, isShowList } = this.state;
        let { nameOfTariff } = this.state;
        const {
            editName,
            order,
            infoRoute,
            errors,
            serverData,
            handlerChangedPrice,
            changedPrice,
        } = this.props;

        if (!nameOfTariff || !order?.tariffInfo) {
            nameOfTariff = 'Не выбран';
        }

        if (order?.systemType === 'service') {
            const { orderServiceTariffs } = serverData;
            const tariffs = orderServiceTariffs?.tariffs || [];
            const tariff = tariffs.find((item) => item.key === order.tariff.id);

            if (tariff) {
                nameOfTariff = tariff.name;

                if (tariff.shortName) {
                    nameOfTariff += ', ';
                    nameOfTariff += tariff.shortName;
                }
            }
        }

        const counterTime = this.getCounterTime();
        const condForPrice = order?.status === 'complete' && editName === 'tariff';

        return (
            <div
                ref={this.parent}
                className={`orderDetailsCard _tariff _${order?.systemType} ${
                    editName === 'tariff' ? '_edit' : ''
                }`}
            >
                <div className="orderDetailsCard__inner">
                    <div className="orderDetailsCard__head _col">
                        {order?.systemType === 'crm' ? (
                            <>
                                <Animate
                                    className="orderDetailsCard__tariffList"
                                    isShow={isShowList}
                                >
                                    {order && (
                                        <TariffsList
                                            newHolder={order?.newHolder}
                                            isNds={order.isNds}
                                            type={order.type}
                                            holder={order.holder}
                                            tariff={order.tariffFull}
                                            tariffData={order.tariff}
                                            tariffInfo={order.tariffInfo}
                                            changeTariff={this.changeTariff}
                                        />
                                    )}
                                </Animate>
                            </>
                        ) : (
                            <>
                                <Animate
                                    className="orderDetailsCard__tariffList"
                                    isShow={isShowList}
                                >
                                    {order && (
                                        <TariffsServiceList
                                            order={order}
                                            changeTariff={this.changeTariff}
                                        />
                                    )}
                                </Animate>
                            </>
                        )}
                        <h5 className="orderDetailsCard__title">Тариф</h5>
                        <div
                            className={`orderDetailsCard__description _row ${
                                errors?.indexOf('tariff') !== -1 ? '_error' : ''
                            } ${order?.status === 'complete' ? '_disabled' : ''}`}
                            onClick={(e) => {
                                if (order?.status !== 'complete' && editName === 'tariff') {
                                    this.handlerList();

                                    stopPropagationClick(e);
                                }
                            }}
                        >
                            <AnimateChange
                                className="orderDetailsCard__descriptionItem"
                                classNameChild="_child"
                                prop={nameOfTariff}
                                type="_translateMedium"
                            >
                                {`${nameOfTariff}`}
                            </AnimateChange>
                        </div>
                    </div>
                    <div className="orderDetailsCard__content">
                        <div className="orderDetailsCard__tariff _row">
                            <div className="orderDetailsCard__tariffInner _row">
                                <span>Общая сумма:</span>
                                <AnimateChange
                                    className="orderDetailsCard__tariffPrice"
                                    classNameParent="_parent"
                                    prop={this.getTotalAmount()}
                                    type="_translateMedium"
                                >
                                    <div
                                        className="orderDetailsCard__tariffPriceInput"
                                        style={{
                                            width: `${
                                                getWidthInput({
                                                    classNames: [
                                                        'orderDetailsCard__tariffPriceInput',
                                                    ],
                                                    value: `${changedPrice}₽`,
                                                }) + (condForPrice ? 12 : 0)
                                            }px`,
                                        }}
                                    >
                                        <Field
                                            className="_manualCard"
                                            keyName="price"
                                            name="price"
                                            callback={handlerChangedPrice}
                                            value={changedPrice}
                                            isDisabled={
                                                order?.systemType === 'service' ||
                                                order?.status !== 'complete'
                                                    ? true
                                                    : !condForPrice
                                            }
                                            isEditmode={true}
                                        />
                                    </div>
                                </AnimateChange>
                            </div>
                            <div className="orderDetailsCard__tariffInfo">
                                <div
                                    className="orderDetailsCard__tariffInfoIcon _click"
                                    onClick={(e) => {
                                        this.handlerInfo();

                                        stopPropagationClick(e);
                                    }}
                                >
                                    <Icon name="info" />
                                </div>
                                <Animate
                                    className="orderDetailsCard__tariffInfoWindow"
                                    isShow={isShowInfo}
                                >
                                    <InfoAmount
                                        order={order}
                                        totalAmount={this.getTotalAmount()}
                                        infoAmount={this.getTotalInfo()}
                                        mkad={infoRoute?.mkad || order?.mkad}
                                        mkadToPoints={
                                            infoRoute?.mkadToPoints || order?.mkadToPoints
                                        }
                                        counterTime={
                                            order?.isNew ? counterTime : order?.counterTime
                                        }
                                        nameOfTariff={nameOfTariff}
                                    />
                                </Animate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(DetailsTariff);

DetailsTariff.propTypes = {
    order: PropTypes.object,
    editName: PropTypes.string,
    isDisabled: PropTypes.bool,
    editmode: PropTypes.object,
    changeOrder: PropTypes.func,
    isNew: PropTypes.bool,
    points: PropTypes.array,
    infoRoute: PropTypes.object,
    errors: PropTypes.array,
    serverData: PropTypes.object,
    windowIsReady: PropTypes.bool,
    handlerChangedPrice: PropTypes.func,
    changedPrice: PropTypes.string,
};
