import React from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

import getJoinScripts from '../../../requests/getJoinScripts';

import Field from '../manual/card/Field.jsx';

import { inputValidate } from '../../../functions/inputValidate';
import getHeaders from '../../../functions/getHeaders';

import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';

import handlerPopup from '../../../functions/handlerPopup';
import setNotification from '../../../functions/setNotification';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';

import copyInBuffer from '../../../functions/copyInBuffer';
import changePage from '../../../functions/changePage';
import getPageLink from '../../../functions/getPageLink';

const selects = require('../../../infos/selects.json');

class PopupExecutorInvite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerField = this.handlerField.bind(this);
        this.save = this.save.bind(this);
    }

    orderFields = {
        main: ['secondName', 'firstName', 'thirdName', 'phone'],
        sub: ['type', 'joinScriptId'],
    };

    getAllFields() {
        return Object.values(this.orderFields).reduce((prev, cur) => prev.concat(...cur), []);
        // .filter((name) => this.orderFields.main.indexOf(name) !== -1);
    }

    initFields() {
        const { executor } = this.props;

        this.setState((state) => {
            const newState = { ...state };
            const fields = {};

            this.getAllFields().forEach((nameField) => {
                fields[nameField] = {
                    value: executor?.[nameField] || '',
                    error: null,
                    isLoading: false,
                };
            });

            newState.fields = fields;

            return newState;
        });
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = JSON.parse(JSON.stringify(newState.fields));

                    if (action === 'change') {
                        fields[name].value = value;
                        fields[name].error = null;
                    }

                    if (action === 'error') {
                        fields[name].error = value;
                    }

                    if (action === 'focus') {
                        fields[name].isFocus = true;
                    }

                    if (action === 'blur') {
                        fields[name].isFocus = false;
                    }

                    if (action === 'loading') {
                        fields[name].isLoading = value;
                    }

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    checkError(name) {
        const { fields } = this.state;
        const field = fields?.[name];

        return (
            !!field?.error ||
            (field?.value &&
                !inputValidate({
                    name,
                    value: field?.value,
                }) &&
                !field?.isFocus)
        );
    }

    renderFields({ name, fieldsOrder }) {
        const { fields, editName, joinScripts = [] } = this.state;
        const orderFields = fieldsOrder || this.orderFields[name];

        return (
            <div className={`popupCorporation__fields _row _${name} ${name === 'sub' ? '_border' : ''}`}>
                {orderFields.map((nameField) => {
                    const field = fields?.[nameField];
                    let items;
                    const isReadOnly =
                        this.name === 'settingsMain'
                            ? editName
                                ? !this.fieldsGroups[editName]?.includes(nameField)
                                : true
                            : false;

                    if (nameField === 'type') {
                        items = selects.executorTypes.items.filter(
                            (item) => !['driver', 'worker'].includes(item.key),
                        );
                    }

                    if (nameField === 'joinScriptId') {
                        items = joinScripts.map((item) => ({ key: item._id, content: item.name }));
                    }

                    return (
                        <div
                            className={`popupCorporation__field _${nameField} ${
                                field?.isLoading ? '_isLoading' : ''
                            }`}
                            key={nameField}
                        >
                            <div className="popupCorporation__fieldInner">
                                <Field
                                    // className="_heightSize"
                                    value={field?.value}
                                    type="popupExecutorInvite"
                                    name={nameField}
                                    handler={this.handlerField}
                                    group="popupExecutorInvite"
                                    classNameField="_popupCorporation"
                                    isReadOnly={isReadOnly}
                                    isDisabled={isReadOnly}
                                    isError={this.checkError(nameField)}
                                    isLoading={field?.isLoading}
                                    id={`input-popupExecutorInvite-${nameField}`}
                                    items={items}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    checkValidateFields(allFields = this.getAllFields()) {
        const { fields } = this.state;
        const notRequiredFields = ['type', 'thirdName'];
        const validateFields = allFields.filter((nameField) =>
            inputValidate({
                name: nameField,
                value: fields[nameField].value,
                isRequire: notRequiredFields.indexOf(nameField) === -1,
            }),
        );
        const notValidateFields = allFields.filter(
            (nameField) =>
                !inputValidate({
                    name: nameField,
                    value: fields[nameField].value,
                    isRequire: notRequiredFields.indexOf(nameField) === -1,
                }),
        );
        let errorFieldName = null;
        const { length: lengthValidateFields } = validateFields;

        errorFieldName = notValidateFields[0];

        return {
            isSuccess: lengthValidateFields === allFields.length,
            errorFieldName,
            errors: notValidateFields,
        };
    }

    handlerLoading(loadingKey = null) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerErrorRequest({ message }) {
        if (message === 'Executor already register') {
            setNotification({
                notification: 'executor-already-reg',
            });

            this.handlerField({
                action: 'error',
                name: 'phone',
                value: 'validate',
            });
        } else if (message === 'Executor cannot be invited') {
            setNotification({
                notification: 'executor-cannot-be-invited',
            });
        }
    }

    setErrors(errors) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = JSON.parse(JSON.stringify(newState.fields));

            errors.forEach((key) => {
                fields[key].error = 'validate';
            });

            newState.fields = fields;

            return newState;
        });

        setNotification({ notification: 'required-fields-not-complete' });
    }

    save() {
        const { fields, loadingKey } = this.state;
        const { callback } = this.props;
        const { isSuccess, errors } = this.checkValidateFields();

        if (!loadingKey) {
            if (!isSuccess) {
                this.setErrors(errors);
            } else {
                const resultFields = {};

                Object.keys(fields).forEach((nameField) => {
                    resultFields[nameField] = fields[nameField].value;
                });

                this.handlerLoading('save').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/executor`,
                            { executor: JSON.stringify(resultFields), isInvite: true },
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    handlerPopup({
                                        action: 'hide',
                                        name: 'popupExecutorInvite',
                                    });

                                    setNotification({
                                        notification: 'success-invite-executor',
                                    });

                                    document.dispatchEvent(
                                        new CustomEvent('updateManual', {
                                            detail: { id: 'joins' },
                                        }),
                                    );

                                    if (callback) {
                                        callback();
                                    }

                                    this.handlerLoading(null);
                                } else {
                                    this.handlerErrorRequest(data);

                                    handlerErrorRequest(res);

                                    this.handlerLoading(null);
                                }
                            },
                            () => {
                                this.handlerLoading(null);
                            },
                        );
                });
            }
        }
    }

    hide() {
        handlerPopup({
            action: 'hide',
            name: 'popupExecutorInvite',
        });
    }

    getJoinScripts() {
        getJoinScripts({ params: [{ key: 'forInvite', value: true }] }).then(({ joinScripts }) => {
            this.setState({ joinScripts });
        });
    }

    getLink() {
        const { fields, joinScripts = [] } = this.state;
        const joinScript = joinScripts.find((item) => item._id === fields.joinScriptId?.value);

        if (joinScript) {
            return {
                link: `${process.env.REACT_APP_APP_DOMEN}/join/${joinScript.link}`,
            };
        }

        return {
            link: 'Выберите сценарий',
            isEmpty: true,
        };
    }

    componentDidMount() {
        this.initFields();
        this.getJoinScripts();
    }

    render() {
        const { loadingKey } = this.state;
        const { link, isEmpty: isLinkEmpty } = this.getLink();

        return (
            <div ref={this.parent} className="popupCorporation _col _executorInvite">
                <div className="popupCorporation__inner">
                    <div className="popupCorporation__head">
                        <div className="popupCorporation__headInner _row">
                            <div className="popupCorporation__headTitle">
                                Приглашение нового исполнителя:
                            </div>
                            <div className="popupCorporation__headActions _row">
                                <div
                                    className="popupCorporation__headAction _click _save"
                                    onClick={this.save}
                                >
                                    <Animate
                                        className="popupCorporation__headActionLoader _loader"
                                        isShow={loadingKey === 'save'}
                                    >
                                        <i className="popupCorporation__headActionLoaderItem _loaderItem">
                                            <Loader className="" />
                                        </i>
                                    </Animate>
                                    Пригласить
                                </div>
                                <div
                                    className="popupCorporation__headAction _click _close"
                                    onClick={() => {
                                        this.hide();
                                    }}
                                >
                                    Закрыть
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popupCorporation__content">
                        <Animate
                            className="popupCorporation__loader _loader"
                            isShow={!this.state.joinScripts}
                        >
                            <div className="popupCorporation__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <Animate
                            className="popupCorporation__alert"
                            isShow={this.state.joinScripts?.length === 0}
                        >
                            <div className="empty _col _block _notBack">
                                <div className="empty__inner">
                                    <div className="empty__title">Сценариев пока нет</div>
                                    <p className="empty__content">
                                        Чтобы пригласить исполнителя,
                                        <br />
                                        необходимо создать сценарий
                                    </p>
                                    <div className="empty__button _show">
                                        <Button
                                            className="_mediumSize _mainNotBorder"
                                            onClick={() => {
                                                changePage({
                                                    href: getPageLink({
                                                        name: 'joins-scripts-inner',
                                                        ids: { 3: 'new' },
                                                    }),
                                                });

                                                this.hide();
                                            }}
                                        >
                                            Создать сценарий
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Animate>
                        <div className="popupCorporation__contentInner">
                            {this.renderFields({ name: 'main' })}
                            {this.renderFields({ name: 'sub' })}
                        </div>
                    </div>
                    <div className="popupCorporation__foot _row">
                        <div className="popupCorporation__executorInfo _col">
                            <p className="popupCorporation__executorInfoDescription">
                                Мы отправим СМС со ссылкой на приглашение
                            </p>
                            <div
                                className="popupCorporation__executorInfoLinkWrapper"
                                onClick={() => {
                                    copyInBuffer(link);
                                }}
                            >
                                <AnimateChangeUp
                                    className={`popupCorporation__executorInfoLink _col ${
                                        isLinkEmpty ? '_empty' : ''
                                    }`}
                                    renderKey={link}
                                    parentStyles={['height']}
                                >
                                    <div className="popupCorporation__executorInfoLinkInner _click">
                                        {link}
                                    </div>
                                </AnimateChangeUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PopupExecutorInvite;

PopupExecutorInvite.propTypes = {
    _id: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    user: PropTypes.object,
    corporation: PropTypes.object,
    callback: PropTypes.func,
    executor: PropTypes.object,
};
