import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Passport from '../../manual/executor/Passport.jsx';
import ProfileMain from '../../../../views/crm/profile/Main.jsx';

class SettingsDocsPassport extends ProfileMain {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { errors } = this.state;
        const { user } = this.props;

        return (
            <>
                <Passport
                    title="Паспорт директора"
                    executor={user}
                    changeExecutor={({ ...props }) =>
                        new Promise((resolve) => {
                            this.changeUser({ block: 'passport', ...props }).then(resolve);
                        })
                    }
                    checkEditmode={() => this.checkEditmode('passport')}
                    errors={errors?.passport}
                />
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsDocsPassport);

SettingsDocsPassport.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    setHeightPage: PropTypes.func,
    inner: PropTypes.bool,
    user: PropTypes.object,
    setEditName: PropTypes.func,
    setHandlerEditmode: PropTypes.func,
    setLoadingKey: PropTypes.func,
};
