import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Link from '../../Link.jsx';

import handlerPopup from '../../../functions/crm/handlerPopup';
import setAnimate from '../../../functions/setAnimate';
import { getCookie } from '../../../functions/handlerCookies';
import { addRoute } from '../../../functions/handlerYmap';

class UploadOrdersPopupStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderFootItem = this.renderFootItem.bind(this);

        this.parent = React.createRef();
    }

    getFootItems() {
        const { orderStack } = this.props;

        return orderStack?.isComplete ? [{ key: 'complete' }] : [{ key: 'progress' }];
    }

    renderFootItem({ prop: key }) {
        const { setStart } = this.props;

        return (
            <div className="uploadOrdersPopupProgress__footItem">
                {key === 'progress' && (
                    <>
                        <div className="uploadOrdersPopupProgress__info _row">
                            <i className="uploadOrdersPopupProgress__infoIcon">
                                <Icon name="upload-order-info" />
                            </i>
                            Не закрывайте это окно до окончания загрузки
                        </div>
                    </>
                )}
                {key === 'complete' && (
                    <>
                        <div className="uploadOrdersPopupProgress__buttons _row">
                            <div className="uploadOrdersPopupProgress__button _more">
                                <div className="uploadOrdersPopup__button _row _click" onClick={setStart}>
                                    Загрузить ещё
                                </div>
                            </div>
                            <div className="uploadOrdersPopupProgress__button _close">
                                <Link
                                    className="uploadOrdersPopup__button _row _main"
                                    pageName="orders"
                                    prevPromise={() =>
                                        new Promise((resolve) => {
                                            document.dispatchEvent(new CustomEvent('getOrders'));
                                            handlerPopup({
                                                name: 'isUploadOrdersPopupShow',
                                                isShow: false,
                                            });

                                            resolve();
                                        })
                                    }
                                >
                                    Перейти к загруженным заказам
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    getProgress() {
        const { orderStack } = this.props;
        const orders = orderStack?.orders || [];

        const completeLen =
            orders.filter((order) => order.isComplete || order.isError).length +
            (orderStack?.systemType === 'service'
                ? 0
                : orders.filter((order) => order.isGetRouteInfo).length);

        const len = orders.length * (orderStack?.systemType === 'service' ? 1 : 2);
        const progress = +(len === 0 ? 0 : (completeLen / len) * 100).toFixed(0);

        return progress;
    }

    getRouteInfo() {
        const { orderStack, ymaps, map } = this.props;
        const { orders } = orderStack;
        const index = this.activeOrderIndex;
        const route = orders[index]?.content;

        return new Promise((resolve) => {
            if (orderStack.systemType === 'service') {
                resolve();
            } else {
                addRoute({
                    ymaps,
                    map,
                    points: route.points.map((point) => point.coords),
                    isNew: true,
                }).then(
                    (infoRoute) => {
                        const info = {
                            distance: infoRoute.distance,
                            duration: infoRoute.duration,
                            inMoscow: infoRoute.inMoscow,
                            mkad: infoRoute.mkad,
                            mkadToPoints: infoRoute.mkadToPoints,
                        };

                        resolve(info);
                    },
                    () => {
                        setTimeout(() => {
                            this.getRouteInfo();
                        }, 5000);
                    },
                );
            }
        });
    }

    createOrder() {
        const { socket, updateOrders } = this.props;
        const hash = getCookie('hashOrderStack');
        const index = this.activeOrderIndex;

        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.getRouteInfo().then((routeInfo) => {
            updateOrders({ key: index, name: 'isGetRouteInfo', value: true }).then(() => {
                this.timerId = setTimeout(() => {
                    this.checkChange();
                }, 5000);

                socket.emit('createOrderStack', {
                    hashOrderStack: hash,
                    index,
                    routeInfo,
                });
            });
        });
    }

    progress = 0;

    checkChange(isStart) {
        const { ymaps, map, orderStack, isSocketConnect, socket } = this.props;
        const orders = orderStack?.orders || [];

        const activeOrderIndex = orders.findIndex((order) => !order.isComplete && !order.isError);

        const newProgress = this.getProgress();

        if (orderStack.isComplete && this.timerId) {
            clearTimeout(this.timerId);
        }

        if (
            !orderStack.isProccess &&
            activeOrderIndex !== -1 &&
            this.activeOrderIndex !== activeOrderIndex &&
            isSocketConnect &&
            socket &&
            ymaps &&
            map &&
            activeOrderIndex < orders.length
        ) {
            this.activeOrderIndex = activeOrderIndex;

            this.createOrder();
        }

        const percentDom = this.parent.current?.querySelector(
            '.uploadOrdersPopupProgress__progressPercent',
        );

        if (this.animateId) {
            cancelAnimationFrame(this.animateId);
        }

        const oldProgress = this.progress;

        if (newProgress !== this.progress) {
            const progressDelta = newProgress - this.progress;

            if (isStart) {
                if (percentDom) {
                    percentDom.innerHTML = `${newProgress}%`;
                }
            } else {
                setAnimate({
                    timing: (time) => time,
                    draw: (progress) => {
                        this.progress = +(oldProgress + progressDelta * progress).toFixed(0);

                        if (percentDom) {
                            percentDom.innerHTML = `${this.progress}%`;
                        }
                    },
                    duration: 300,
                    getId: (animateId) => {
                        this.animateId = animateId;
                    },
                });
            }
        }
    }

    componentDidMount() {
        const { updateList } = this.props;

        updateList();

        this.checkChange(true);
    }

    componentDidUpdate() {
        this.checkChange();
    }

    render() {
        const { orderStack, updateList } = this.props;
        const orders = orderStack?.orders || [];
        const completeLen = orders.filter((order) => order.isComplete).length;
        const len = orders.length;
        const progress = +this.getProgress();

        return (
            <div
                ref={this.parent}
                className={`uploadOrdersPopupProgress ${orderStack?.isComplete ? '_complete' : ''}`}
            >
                <div className="uploadOrdersPopupProgress__inner">
                    <div className="uploadOrdersPopupProgress__title">
                        Идёт загрузка файла,
                        <br />
                        пожалуйста, подождите
                    </div>
                    <div className="uploadOrdersPopupProgress__progress _row">
                        <div
                            className="uploadOrdersPopupProgress__progressBack"
                            style={{ width: `${progress}%` }}
                        ></div>
                        <i className="uploadOrdersPopupProgress__progressIcon">
                            <Icon name="file-type-doc" />
                        </i>
                        <div className="uploadOrdersPopupProgress__progressFile">
                            {orderStack?.fileName}
                        </div>
                        <div className="uploadOrdersPopupProgress__progressPercent">0%</div>
                    </div>
                    <div className="uploadOrdersPopupProgress__result">
                        <b>Загружено заказов:</b> {completeLen} из {len}
                    </div>
                    <div className="uploadOrdersPopupProgress__table">
                        <div className="uploadOrdersPopupProgress__tableInner">
                            {orders.map((order, key) => {
                                const { isComplete = false, isError = false } = order;

                                return (
                                    <div className="uploadOrdersPopupProgress__tableRow _row" key={key}>
                                        <div className="uploadOrdersPopupProgress__tableCol">
                                            Строка {key + 1}
                                        </div>
                                        <div className="uploadOrdersPopupProgress__tableCol">
                                            <div
                                                className={`uploadOrdersPopupProgress__tableStatus ${
                                                    isComplete ? '_complete' : ''
                                                } ${isError ? '_error' : ''}`}
                                            >
                                                <AnimateChange
                                                    className="uploadOrdersPopupProgress__tableStatusInner"
                                                    prop={`${isComplete}${isError}`}
                                                    type="_translateMedium"
                                                >
                                                    {isComplete ? (
                                                        <>Загружено</>
                                                    ) : isError ? (
                                                        <>Ошибка сервера</>
                                                    ) : (
                                                        <>Загрузка</>
                                                    )}
                                                </AnimateChange>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <ListAbsoluteMain
                        className="uploadOrdersPopupProgress__foot"
                        items={this.getFootItems()}
                        renderItem={this.renderFootItem}
                        classNameItem="uploadOrdersPopupProgress__footItem"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        callback={updateList}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        isSocketConnect: state.isSocketConnect,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopupStep);

UploadOrdersPopupStep.propTypes = {
    orderStack: PropTypes.object,
    updateList: PropTypes.func,
    setStart: PropTypes.func,
    socket: PropTypes.object,
    isSocketConnect: PropTypes.bool,
    ymaps: PropTypes.object,
    map: PropTypes.object,
    routes: PropTypes.array,
    updateOrders: PropTypes.func,
};
