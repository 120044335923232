import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import UploadPaysPopup from './UploadPaysPopup.jsx';

class UploadOrdersPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isUploadPaysPopupShow } = this.props;

        return (
            <Animate className="body__uploadOrdersPopup" isShow={isUploadPaysPopupShow}>
                <UploadPaysPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        isUploadPaysPopupShow: state.isUploadPaysPopupShow,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopupWrapper);

UploadOrdersPopupWrapper.propTypes = {
    isUploadPaysPopupShow: PropTypes.bool,
};
