export default function shortText({ type, str, stop }) {
    let newStr = '';

    if (type === 'word') {
        const words = str.split(' ');

        if (words.length > stop) {
            for (let i = 0; i < stop; i++) {
                newStr += words[i];
                newStr += ' ';
            }

            newStr += '...';
        } else {
            newStr = str;
        }

        return newStr;
    }

    if (str.length > stop) {
        for (let i = 0; i < stop; i++) {
            newStr += str[i];
        }
        newStr += '...';
    } else {
        newStr = str;
    }

    return newStr;
}
