const sortOrdersForDate = (a, b) =>
    new Date(a.dateOfOrder).getTime() - new Date(b.dateOfOrder).getTime();

const sortOrdersForDateComplete = (a, b) => {
    const weightA = ['complete', 'cancel'].includes(a.status)
        ? new Date(a.dateOfOrder).getTime()
        : -1;
    const weightB = ['complete', 'cancel'].includes(a.status)
        ? new Date(b.dateOfOrder).getTime()
        : -1;

    return weightB - weightA;
};

const getWeight = (order) =>
    ['wait-pay', 'new'].indexOf(order.status) !== -1
        ? 5
        : order.status === 'start-work'
        ? 4
        : order.status === 'choice-crew'
        ? 3
        : order.status === 'in-proccess'
        ? 2
        : 1;

const sortOrdersForStatus = (a, b) => {
    const weightA = getWeight(a);
    const weightB = getWeight(b);

    return weightB - weightA;
};

export default function sortOrders(orders) {
    const ordersUpdate = JSON.parse(JSON.stringify(orders));

    return ordersUpdate
        .sort(sortOrdersForDate)
        .sort(sortOrdersForDateComplete)
        .sort(sortOrdersForStatus);
}
