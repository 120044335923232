import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';
import Link from '../../../Link.jsx';
import Icon from '../../../Icon.jsx';
import ListAbsolute from '../../../ListAbsolute.jsx';

import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import getHeaders from '../../../../functions/getHeaders';
import setNotification from '../../../../functions/setNotification';
import { inputValidate } from '../../../../functions/inputValidate';
import handlerSupportCursor from '../../../../functions/handlerSupportCursor';
import getFormatedDate from '../../../../functions/getFormatedDate';

const selects = require('../../../../infos/selects.json');

class ManualOrganizationsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterAction = this.filterAction.bind(this);
        this.getSortActions = this.getSortActions.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.changeOrganization = this.changeOrganization.bind(this);
        this.checkSmz = this.checkSmz.bind(this);

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { organization } = this.props;

        if (organization) {
            if (organization.jurType === 'juristic') {
                return {
                    description: 'Юридическое лицо',
                };
            }

            if (organization.jurType === 'physical') {
                if (organization.organization === 'SMZ') {
                    return {
                        description: 'Самозанятый',
                    };
                }

                return {
                    description: 'Физическое лицо',
                };
            }
        }

        return {
            description: '–',
        };
    }

    fields = {
        physical: [
            { name: 'secondName' },
            { name: 'firstName' },
            { name: 'thirdName' },
            { name: 'type', isReadOnly: true },
            { name: 'phone' },
            { name: 'inn', action: 'checkSmzInn' },
        ],
        juristic: [
            {
                name: 'inn',
                isReadOnly() {
                    return !this.props.isNew;
                },
            },
            { name: 'shortName' },
            { name: 'website' },
            { name: 'kpp', isReadOnly: true },
            { name: 'nds' },
            {
                name: 'type',
                isReadOnly() {
                    return !this.props.isNew;
                },
            },
            { name: 'address', isReadOnly: true },
        ],
    };

    checkSmz() {
        const { organization } = this.props;

        return new Promise((resolve) => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/organization`,
                    {
                        id: organization._id,
                        type: organization.type,
                        action: 'check-inn',
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { innActive } = data;

                            setNotification({ notification: `success-check-${innActive}-inn` });
                        } else {
                            const { message } = data;

                            if (message === 'Service unavailable') {
                                setNotification({ notification: 'error-check-inn' });
                            }

                            handlerErrorRequest(res);
                        }

                        resolve();
                    },
                    () => null,
                );
        });
    }

    getFieldsOrder() {
        const { organization, type } = this.props;
        const { jurType } = organization;

        return this.fields[jurType].filter(({ name }) => type !== 'preview' || name !== 'type');
    }

    actions = [
        {
            key: 'delete',
            order: 1,
        },
        {
            key: 'link',
            order: 0,
        },
    ];

    filterAction({ key }) {
        const { checkEditmode } = this.props;

        return checkEditmode() ? key === 'delete' : key === 'link';
    }

    setParamsParent(params = {}) {
        const { width: widthActions } = params;

        if (widthActions !== this.state.widthActions) {
            this.setState({ widthActions });
        }
    }

    sortActions(a, b) {
        return a.order - b.order;
    }

    getSortActions(actions) {
        return JSON.parse(JSON.stringify(actions)).sort(this.sortActions);
    }

    renderAction({ prop: key, key: order, isShow, params }) {
        const { organization, deleteCompany } = this.props;

        return (
            <div
                className={`manualCard__headAction ${isShow ? '_show' : ''}`}
                data-key={key}
                key={key}
                style={{
                    transform: `translate(${-params?.offsetRight}px,0)`,
                    order,
                }}
            >
                {key === 'link' && (
                    <Link
                        pageName="manual-organizations-inner"
                        className="manualCard__headLink _row"
                        ids={{ 2: organization._id }}
                    >
                        На страницу
                        <i className="manualCard__headLinkIcon">
                            <Icon name="arrow-next" />
                        </i>
                    </Link>
                )}
                {key === 'delete' && (
                    <div
                        className="manualCard__headLink _row _click _alert"
                        onClick={deleteCompany}
                    >
                        Отвязать компанию
                    </div>
                )}
            </div>
        );
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    changeOrganization({ ...props }) {
        const { changeOrganization, getCompanyInfo, organization } = this.props;

        return new Promise((resolve) => {
            if (
                props.name === 'inn' &&
                props.action === 'change' &&
                organization.jurType === 'juristic' &&
                inputValidate({ name: 'inn', value: props.value })
            ) {
                this.handlerLoadingKey('inn').then(() => {
                    getCompanyInfo({ inn: props.value }).then(
                        () => {
                            this.handlerLoadingKey(null);
                        },
                        () => null,
                    );
                });
            }

            changeOrganization({ ...props }).then(resolve);
        });
    }

    renderContractStatus() {
        const { organization } = this.props;
        const status = organization.contract?.statusText;
        let statusClassName = '';
        const statusDate = organization.contract?.statusDate;

        if (organization.contract) {
            if (organization.contract.status === 'corporationSign') {
                statusClassName = '';
            } else if (organization.contract.status === 'executorSign') {
                statusClassName = '';
            } else if (organization.contract.status === 'complete') {
                statusClassName = '_active';
            } else if (organization.contract.status === 'inactive') {
                statusClassName = '_alert';
            } else if (organization.contract.status === 'none') {
                statusClassName = '_alert';
            } else {
                statusClassName = '_wait';
            }
        } else {
            statusClassName = '_alert';
        }

        return (
            <div className={`manualCard__headStatus _row ${statusClassName}`}>
                {status}
                {statusDate && (
                    <i
                        className="manualCard__headStatusInfo _click"
                        onMouseEnter={(e) => {
                            handlerSupportCursor({
                                action: 'enter',
                                content: getFormatedDate({
                                    date: new Date(statusDate),
                                    type: 'full',
                                }),
                                e,
                                data: { className: '_normalCase _center' },
                            });
                        }}
                        onMouseLeave={(e) => {
                            handlerSupportCursor({ action: 'leave', e });
                        }}
                    >
                        <Icon name="info" />
                    </i>
                )}
            </div>
        );
    }

    render() {
        const { widthActions, loadingKey } = this.state;
        const {
            type,
            organization,
            checkEditmode = () => false,
            errors = [],
            forCompanyExecutorId,
        } = this.props;
        const { description } = this.getDescriptionInfo();
        const { jurType } = organization;
        const parentList = this.parent.current?.querySelector('.manualCard__headActions');
        const actionsFilter = this.actions.filter(this.filterAction);

        return (
            <div
                ref={this.parent}
                className={`manualCard _organizationMain _${jurType} ${type ? `_${type}` : ''}`}
            >
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={description}>
                        {organization?.type === 'executor' &&
                            organization.jurType === 'physical' &&
                            this.renderContractStatus()}
                    </Head>
                    {type === 'preview' && organization?.isCorporation !== true && (
                        <div className="manualCard__headInfo">
                            <div
                                className="manualCard__headActions"
                                style={{ width: `${widthActions}px` }}
                            >
                                <ListAbsolute
                                    parent={parentList}
                                    items={actionsFilter}
                                    renderItem={this.renderAction}
                                    classNameItem="manualCard__headAction"
                                    prop="key"
                                    setParamsParent={this.setParamsParent}
                                    sort={this.getSortActions}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.getFieldsOrder().map((field) => {
                            const { name } = field;
                            let { isReadOnly } = field;
                            const { [name]: value } = organization;
                            let items;

                            if (typeof isReadOnly === 'function') {
                                isReadOnly = isReadOnly.call(this);
                            }

                            if (name === 'type' || name === 'viewType') {
                                items = selects.organizationTypes.items;
                            }

                            if (
                                organization.type === 'executor' &&
                                organization.jurType === 'physical' &&
                                name !== 'inn'
                            ) {
                                isReadOnly = true;
                            }

                            if (forCompanyExecutorId && name === 'type') {
                                isReadOnly = true;
                            }

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="organization"
                                        name={name}
                                        handler={this.changeOrganization}
                                        group="organizationMain"
                                        isDisabled={!checkEditmode() || type === 'preview'}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                        items={items}
                                        isLoading={name === 'inn' && loadingKey === 'inn'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        forCompanyExecutorId: state.forCompanyExecutorId,
    };
}

export default connect(mapStateToProps)(ManualOrganizationsMain);

ManualOrganizationsMain.propTypes = {
    organization: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeOrganization: PropTypes.func,
    type: PropTypes.string,
    deleteCompany: PropTypes.func,
    getCompanyInfo: PropTypes.func,
    forCompanyExecutorId: PropTypes.string,
};
