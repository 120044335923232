import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../Icon.jsx';

class OrderFeedbackStars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { rate = 0, className = '' } = this.props;

        return (
            <div className={`orderFeedbackStars _row ${className}`}>
                {[1, 2, 3, 4, 5].map((key) => {
                    const isActive = key <= rate;

                    return (
                        <div
                            className={`orderFeedbackStars__item ${isActive ? '_active' : ''} ${
                                rate > 3 ? '_good' : '_bad'
                            }`}
                            key={key}
                        >
                            <Icon name="star" />
                        </div>
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderFeedbackStars);

OrderFeedbackStars.propTypes = {
    rate: PropTypes.number,
    className: PropTypes.string,
};
