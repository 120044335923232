export default function getWidthInput({ classNames = [], value, withPaddings }) {
    const newInput = document.createElement('input');

    newInput.style.display = 'block';
    newInput.style.margin = '0';

    if (!withPaddings) {
        newInput.style.padding = '0';
    }

    newInput.style.border = '0';
    newInput.style.outline = 'none';
    newInput.style.boxSizing = 'border-box';

    classNames.forEach((className) => {
        newInput.classList.add(className);
    });

    newInput.value = value;

    newInput.style.width = '0px';

    document.body.appendChild(newInput);

    const { scrollWidth } = newInput;

    newInput.parentNode.removeChild(newInput);

    return scrollWidth;
}
