import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../scss/crm/main.scss';

import { dispatcher } from '../../redux/redux';

import checkHasCorporation from '../../functions/crm/checkHasCorporation';
import logout from '../../functions/logout';
import setServerData from '../../functions/setServerData';

import SideBarWrapper from '../../components/crm/sideBar/Wrapper.jsx';
import NotificationsBarWrapper from '../../components/crm/notificationsBar/Wrapper.jsx';

import PopupCorporation from '../../components/crm/popups/PopupCorporation.jsx';

import Pages from '../../components/Pages.jsx';

import Auth from './Auth.jsx';
import Orders from './Orders.jsx';
import Index from './Index.jsx';
import Chat from './Chat.jsx';
import OrderDetails from '../OrderDetails.jsx';
import Manual from './Manual.jsx';
import Content from './Content.jsx';
import Profile from './Profile.jsx';
import Reports from './Reports.jsx';
import Settings from './Settings.jsx';
import Pays from './Pays.jsx';
import Pdf from './Pdf.jsx';
import Joins from './Joins.jsx';
import ChatTemplates from './ChatTemplates.jsx';
import Public from './Public.jsx';

import CorporationTariffPopupWrapper from '../../components/crm/popups/CorporationTariffPopupWrapper.jsx';
import CorporationLimitsPopupWrapper from '../../components/crm/popups/CorporationLimitsPopupWrapper.jsx';

import CorporationPayPopupWrapper from '../../components/crm/popups/CorporationPayPopupWrapper.jsx';
import UploadOrdersPopupWrapper from '../../components/crm/popups/UploadOrdersPopupWrapper.jsx';
import PopupExecutorInvite from '../../components/crm/popups/PopupExecutorInvite.jsx';
import ExportPopupWrapper from '../../components/crm/popups/ExportPopupWrapper.jsx';
import Cookie from '../../components/Cookie.jsx';
import CustomHead from '../../components/CustomHead.jsx';
import getCurrentPage from '../../functions/getCurrentPage';
import BonusPopupWrapper from '../../components/crm/popups/BonusPopupWrapper.jsx';
import UploadPaysPopupWrapper from '../../components/crm/popups/UploadPaysPopupWrapper.jsx';
import PaysPopupWrapper from '../../components/crm/popups/PaysPopupWrapper.jsx';
import ChatTemplatesPopupWrapper from '../../components/crm/popups/ChatTemplatesPopupWrapper.jsx';
import PayPopupWrapper from '../../components/crm/pays/PayPopupWrapper.jsx';
import SignContractPopupWrapper from '../../components/crm/popups/SignContractPopupWrapper.jsx';

import ChatExecutorDocPopupWrapper from '../../components/crm/popups/ChatExecutorDocPopupWrapper.jsx';
import getUser from '../../functions/getUser';
import { getCookie } from '../../functions/handlerCookies';
import Windows from '../../components/Windows.jsx';
import WindowActions from '../../components/WindowActions.jsx';
import handlerWindow from '../../functions/handlerWindow';
import copyInBuffer from '../../functions/copyInBuffer';
import InfoPopupWrapper from '../../components/app/infoPopup/InfoPopupWrapper.jsx';
import resize from '../../functions/handlerSize';
import JoinContractPopupWrapper from '../../components/crm/popups/JoinContractPopupWrapper.jsx';
import NewVersionPopupWrapper from '../../components/crm/popups/NewVersionPopupWrapper.jsx';
import MvdPopupWrapper from '../../components/crm/popups/MvdPopupWrapper.jsx';
import ServerNotAvailable from '../../components/ServerNotAvailable.jsx';
import ServerNotAvailablePopupWrapper from '../../components/crm/popups/ServerNotAvailablePopupWrapper.jsx';
import VerificationPopupWrapper from '../../components/crm/popups/VerificationPopupWrapper.jsx';
import LogsPopupWrapper from '../../components/crm/popups/LogsPopupWrapper.jsx';
import RolePopupWrapper from '../../components/crm/popups/RolePopupWrapper.jsx';
import UserPopupWrapper from '../../components/crm/popups/UserPopupWrapper.jsx';
import InfoPublic from './content/InfoPublic.jsx';
import getPage from '../../functions/getPage';
import Animate from '../../components/Animate.jsx';
import Pep from './Pep.jsx';
import Corporations from './Corporations.jsx';
import SupportChatBtn from '../../components/crm/SupportChatBtn.jsx';
import ExecutorLimitsPopupWrapper from '../../components/crm/popups/ExecutorLimitsPopupWrapper.jsx';
import ListPopupWrapper from '../../components/crm/popups/ListPopupWrapper.jsx';
import TagsPopupWrapper from '../../components/crm/popups/TagsPopupWrapper.jsx';
import MailingPopup from '../../components/crm/popups/MailingPopup.jsx';
import Mailings from './Mailings.jsx';
import ChatTemplatePopup from '../../components/crm/popups/ChatTemplatePopup.jsx';
import WalletsPopupWrapper from '../../components/crm/popups/WalletsPopupWrapper.jsx';
import UploadJoinsPopupWrapper from '../../components/crm/popups/UploadJoinsPopupWrapper.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerSocket = this.handlerSocket.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.renderWindowContextMenu = this.renderWindowContextMenu.bind(this);
    }

    pages = {
        'corporation-index': {
            render() {
                return (
                    <div className="body__pageInner">
                        <Index />
                    </div>
                );
            },
        },
        corporations: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Corporations />
                    </div>
                );
            },
        },
        auth: {
            render() {
                return <Auth />;
            },
        },
        orders: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Orders />
                    </div>
                );
            },
        },
        chat: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Chat />
                    </div>
                );
            },
        },
        'order-details': {
            render() {
                return (
                    <div className="body__pageInner">
                        <OrderDetails />
                    </div>
                );
            },
        },
        manual: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Manual />
                    </div>
                );
            },
        },
        'content-infoPublic': {
            render() {
                return (
                    <div className="body__pageInner">
                        <InfoPublic />
                    </div>
                );
            },
        },
        content: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Content />
                    </div>
                );
            },
        },
        profile: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Profile />
                    </div>
                );
            },
        },
        reports: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Reports />
                    </div>
                );
            },
        },
        settings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Settings />
                    </div>
                );
            },
        },
        joins: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Joins />
                    </div>
                );
            },
        },
        pays: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Pays />
                    </div>
                );
            },
        },
        'chat-templates': {
            render() {
                return (
                    <div className="body__pageInner">
                        <ChatTemplates />
                    </div>
                );
            },
        },
        'chat-template': {
            render() {
                return (
                    <div className="body__pageInner">
                        <ChatTemplatePopup />
                    </div>
                );
            },
        },
        mailings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Mailings />
                    </div>
                );
            },
        },
        mailing: {
            render() {
                return (
                    <div className="body__pageInner">
                        <MailingPopup />
                    </div>
                );
            },
        },
        pdf: {
            render() {
                return <Pdf />;
            },
        },
        public: {
            render() {
                return <Public />;
            },
        },
        pep: {
            render() {
                return <Pep />;
            },
        },
    };

    handlerSocket({ detail }) {
        const { user } = this.props;
        const { name, data } = detail;

        if (name === 'logout') {
            dispatcher({ type: 'isProccessLogout', data: true }).then(() => {
                logout(true);
            });
        } else if (name === 'system') {
            const { version } = data;

            if (version !== +process.env.REACT_APP_CRM_VERSION) {
                dispatcher({ type: 'user', data: { ...user, systemVersion: version } }).then(() => {
                    dispatcher({ type: 'newVersionShow', data: true });
                });
            }
        } else {
            const { type, fields, idOfCorporation } = data;

            if (name === 'user') {
                const { idOfUser } = data;

                if (idOfUser === user._id) {
                    dispatcher({ type: 'user', data: { ...user, ...fields } }).then(() => {
                        document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
                    });
                }
            }

            if (type === 'changeInfo' && name === 'corporation') {
                const corporationIndex = user.corporationsInfo.findIndex(
                    (corporation) => corporation._id === idOfCorporation,
                );

                if (corporationIndex !== -1) {
                    const corporationsInfo = JSON.parse(JSON.stringify(user.corporationsInfo));
                    const corporation = corporationsInfo[corporationIndex];

                    ['logo', 'balance', 'fullName', 'shortName', 'opfName', 'serviceOrder'].forEach(
                        (key) => {
                            if (fields[key]) {
                                corporation[key] = fields[key];
                            }
                        },
                    );

                    user.corporationsInfo = corporationsInfo;

                    dispatcher({ type: 'user', data: { ...user } }).then(() => {
                        document.dispatchEvent(
                            new CustomEvent('changeUser', {
                                detail: { fields: user.corporations },
                            }),
                        );
                    });
                }
            }
        }
    }

    renderWindowContextMenu({ link }) {
        const handler = ({ key }) =>
            new Promise((resolve) => {
                const resultLink = `${process.env.REACT_APP_CRM_DOMEN}/${link}`;

                if (key === 'targetBlank') {
                    window.open(resultLink, '_blank');

                    resolve();
                } else if (key === 'copyLink') {
                    copyInBuffer(resultLink);

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={['contextTargetBlank', 'contextCopyLink']}
                callback={handler}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'contextMenu',
                    });
                }}
            />
        );
    }

    visibilityDocChange({ detail: { isActive } }) {
        const { user } = this.props;

        if (isActive && user) {
            getUser(getCookie(process.env.REACT_APP_HASH), true, true).then(
                () => null,
                () => null,
            );

            resize();

            setTimeout(() => {
                resize();
            }, 10);
        }
    }

    componentDidMount() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({ page: pagesStore, filter: (page) => !page.level });

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        if (currentPage !== 'pdf') {
            ['export', 'executorPassportCountries', 'executorTowns', 'joinStatuses'].forEach(
                (name) => {
                    setServerData(name);
                },
            );
        }

        document.dispatchEvent(new CustomEvent('click'));
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    render() {
        const {
            user,
            popupCorporation,
            isHideSideBar,
            isHideNotificationsBar,
            popupExecutorInvite,
            pagesStore,
            isProccessLogout,
        } = this.props;

        const currentPageName = getCurrentPage({
            pages: pagesStore,
            filter: (page) => !page.level,
        });
        const currentPage = getPage({ name: currentPageName });

        return (
            <>
                <CustomHead
                    title="CRM LIVECARGO"
                    description="Служба доставки для бизнеса &#9989; Машины в любом количестве &#9989; Экспресс-доставка вашего груза &#128222; +7 495 665-82-72"
                >
                    <link rel="icon" href={require('../../img/favicon.ico')} />
                    <meta name="yandex-verification" content="0dcdf70d2b548de3" />
                </CustomHead>
                <InfoPopupWrapper />
                {currentPageName !== 'pdf' && (
                    <>
                        {(user || isProccessLogout) && (
                            <>
                                <SideBarWrapper isShow={currentPage?.group === 'cabinet' && user} />
                                <NotificationsBarWrapper
                                    isShow={currentPage?.group === 'cabinet' && user}
                                />
                            </>
                        )}

                        <Animate className="body__waitBack" isShow={!checkHasCorporation({ user })}>
                            <img
                                src={require('../../img/crm/back-page.png')}
                                alt=""
                                className="body__waitBackImage"
                            />
                        </Animate>
                        {popupCorporation.state > -1 && (
                            <div
                                className={`body__popup _createCorporation ${
                                    popupCorporation.state === 1 ? '_show' : ''
                                }`}
                            >
                                <PopupCorporation user={user} />
                            </div>
                        )}
                        {popupExecutorInvite.state > -1 && (
                            <div
                                className={`body__popup _executorInvite ${
                                    popupExecutorInvite.state === 1 ? '_show' : ''
                                }`}
                            >
                                <PopupExecutorInvite
                                    user={user}
                                    executor={popupExecutorInvite.executor}
                                    callback={popupExecutorInvite.callback}
                                />
                            </div>
                        )}
                        <CorporationTariffPopupWrapper />
                        <CorporationLimitsPopupWrapper />
                        <ExecutorLimitsPopupWrapper />

                        <CorporationPayPopupWrapper />
                        <UploadOrdersPopupWrapper />
                        <UploadPaysPopupWrapper />
                        <ExportPopupWrapper />
                        <Cookie />
                        <BonusPopupWrapper />
                        <PaysPopupWrapper />
                        <SignContractPopupWrapper />
                        <PayPopupWrapper />
                        <ChatExecutorDocPopupWrapper />
                        <JoinContractPopupWrapper />
                        <NewVersionPopupWrapper />
                        <MvdPopupWrapper />
                        <LogsPopupWrapper />
                        <RolePopupWrapper />
                        <ServerNotAvailablePopupWrapper />
                        <UserPopupWrapper />
                        <WalletsPopupWrapper />
                        <UploadJoinsPopupWrapper />

                        <ServerNotAvailable
                            isHide={currentPageName === 'public' || currentPageName === 'pdf'}
                        />

                        <ChatTemplatesPopupWrapper />
                        <ListPopupWrapper />
                        <TagsPopupWrapper />
                        <SupportChatBtn />

                        <VerificationPopupWrapper />

                        <Windows name="contextMenu" renderContent={this.renderWindowContextMenu} />
                    </>
                )}

                <Pages
                    classNamePage={`JSTransVertical body__page _col _crm ${
                        user && !checkHasCorporation({ user }) ? '_waitCorporation' : ''
                    } ${isHideSideBar ? '_hideSideBar' : ''} ${
                        isHideNotificationsBar ? '_hideNotificationsBar' : ''
                    }`}
                    filter={(page) =>
                        page.level === 1 ||
                        ['profile', 'auth', 'content-infoPublic', 'pep', 'pdf'].includes(
                            page.name,
                        ) ||
                        page.group === 'public'
                    }
                    pages={this.pages}
                    context={this}
                    fullHeight={true}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        popupCorporation: state.popupCorporation,
        dateOfLogout: state.dateOfLogout,
        isHideSideBar: state.isHideSideBar,
        isHideNotificationsBar: state.isHideNotificationsBar,
        popupExecutorInvite: state.popupExecutorInvite,
        pagesStore: state.pages,
        isHideAudioNotification: state.isHideAudioNotification,
        isProccessLogout: state.isProccessLogout,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    user: PropTypes.object,
    popupCorporation: PropTypes.object,
    dateOfLogout: PropTypes.string,
    isHideSideBar: PropTypes.bool,
    isHideNotificationsBar: PropTypes.bool,
    popupExecutorInvite: PropTypes.object,
    pagesStore: PropTypes.object,
    isHideAudioNotification: PropTypes.bool,
    isProccessLogout: PropTypes.bool,
};
