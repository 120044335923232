import React from 'react';

import WindowActions from '../../components/WindowActions.jsx';
import WindowPrompt from '../../components/WindowPrompt.jsx';
import Filter from '../../components/Filter.jsx';

import handlerPopup from '../app/handlerPopup';
import download from '../download';
import refreshJoinContracts from './refreshJoinContracts';
import handlerWindow from '../handlerWindow';

export default function setContractsParent() {
    this.renderContractActions = ({ parent, parentScroll, target, contract, actions }) => {
        const { fileName, executorName, type } = contract;

        const handlerRole = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'download') {
                    download({
                        files: [
                            {
                                folder: 'corporations-docs',
                                src: fileName,
                                name: `Договор_${executorName?.split(' ').join('_') || ''}.pdf`,
                            },
                        ],
                    }).then(resolve);
                } else if (key === 'refresh') {
                    refreshJoinContracts({ itemsIds: [contract._id], type }).then(resolve, resolve);
                } else if (key === 'open') {
                    window.open(
                        `${process.env.REACT_APP_STATIC}/corporations-docs/${fileName}`,
                        '_blank',
                    );

                    resolve();
                } else if (key === 'delete') {
                    refreshJoinContracts({
                        itemsIds: [contract._id],
                        type,
                        isDelete: true,
                    }).then(resolve, ({ message }) => {
                        if (message === 'Contract have mvd') {
                            handlerWindow({
                                parent,
                                parentResize: parent,
                                parentScroll,
                                target,
                                action: 'show',
                                name: 'contractPrompt',
                                className: '_prompt _contract',
                                uniqKey: `contractPrompt-${contract._id}`,
                                contract,
                                isCheckScrollPosition: false,
                                isHideFromScroll: true,
                                centers: {
                                    left: 0,
                                    top: 0.5,
                                },
                            });
                        }

                        resolve();
                    });
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'joinContract',
                        modelId: contract._id,
                    });

                    resolve();
                }
            });

        return (
            <WindowActions
                items={actions}
                callback={handlerRole}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'userActions',
                    });
                }}
                otherData={{ type }}
            />
        );
    };

    this.initCallbackFilter = (callbackFilter) => {
        this.callbackFilter = callbackFilter;
    };

    this.initCallbackFilter = this.initCallbackFilter.bind(this);

    this.renderFilter = ({ filter, handlerFilter }) => (
        <Filter
            className="_right"
            filter={filter}
            handlerFilter={handlerFilter}
            hideFilter={() => {
                handlerWindow({
                    action: 'hide',
                    name: 'filter',
                });
            }}
            callback={this.callbackFilter}
        />
    );

    this.renderFilter = this.renderFilter.bind(this);

    this.renderContractPrompt = ({ contract }) => {
        const callback = ({ hide, handlerLoader }) => {
            refreshJoinContracts({
                itemsIds: [contract._id],
                type: contract.type,
                isDelete: true,
                isForce: true,
            }).then(
                () => {
                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return (
            <WindowPrompt className="_centerRight" callback={callback} name="contractInactive" />
        );
    };

    this.renderContractPrompt = this.renderContractPrompt.bind(this);
}
