import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

class ManualOrganizationsAddressMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getDescriptionInfo() {
        const { organization } = this.props;

        return {
            description: organization?.addressMail
                ? organization?.addressMail
                      .split(',')
                      .filter((item, key) => key < 3)
                      .join(', ')
                : '–',
        };
    }

    fields = [{ name: 'addressMail' }];

    render() {
        const {
            organization,
            checkEditmode = () => false,
            errors = [],
            changeOrganization,
        } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div className="manualCard _organizationAddressMail">
                <div className="manualCard__head _row">
                    <Head title="Почтовый адрес" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            const { [name]: value } = organization;

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="organization"
                                        name={name}
                                        handler={changeOrganization}
                                        group="organizationMain"
                                        isDisabled={!checkEditmode()}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualOrganizationsAddressMail);

ManualOrganizationsAddressMail.propTypes = {
    organization: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeOrganization: PropTypes.func,
};
