import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HandlerFilter from '../../../classes/Filter';

import LogsTable from '../LogsTable.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import WidgetFilter from '../widget/Filter.jsx';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';
import getFilter from '../../../requests/getFilter';
import Windows from '../../Windows.jsx';
import Filter from '../../Filter.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class LogsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setCounter = this.setCounter.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);

        this.parent = React.createRef();
    }

    getTitle() {
        const { modelName } = this.state;

        if (modelName === 'pay') {
            return 'акта';
        }

        if (modelName === 'join') {
            return 'подключения';
        }

        if (modelName === 'executorMvd') {
            return 'МВД уведомления';
        }

        if (modelName === 'executorTax') {
            return 'налоговой копилки';
        }

        if (modelName === 'joinContract') {
            return 'договора';
        }

        if (modelName === 'chat') {
            return 'чата';
        }

        if (modelName === 'role') {
            return 'роли';
        }

        if (modelName === 'user') {
            return 'сотрудника';
        }

        if (modelName === 'corporationVerification') {
            return 'верификации корпорации';
        }

        if (modelName === 'executorLimits') {
            return 'лимитов';
        }

        if (modelName === 'mailing') {
            return 'рассылки';
        }

        if (modelName === 'chatTemplate') {
            return 'шаблона чата';
        }

        return '';
    }

    getFilter() {
        getFilter({ name: 'modelLogs' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter.bind(this);
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setCounter(counter) {
        this.setState({ counter });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    componentDidMount() {
        const { logsPopup } = this.props;
        const { modelName, modelId, models } = logsPopup;

        this.setState({ modelName, modelId, models });

        this.getFilter();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });
    }

    render() {
        const {
            counter = 0,
            isShowLoaderList = false,
            filter,
            modelName,
            modelId,
            models,
        } = this.state;

        return (
            <div ref={this.parent} className="logsPopup _col">
                <div className="logsPopup__inner">
                    <Windows name="logsPopupFilter" renderContent={this.renderFilter} />
                    <div className="logsPopup__innerBox _col">
                        <div className="logsPopup__head _row">
                            <div className="logsPopup__title">
                                Логи {this.getTitle()}:{' '}
                                <AnimateChangeUp
                                    className="logsPopup__titleItem"
                                    renderKey={counter}
                                >
                                    <>({counter})</>
                                </AnimateChangeUp>
                            </div>
                            <div className="logsPopup__headFilter">
                                <WidgetFilter
                                    className="_grey"
                                    // name={name}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    getParent={() =>
                                        this.parent.current?.querySelector('.logsPopup__inner')
                                    }
                                    windowName="logsPopupFilter"
                                />
                            </div>
                            <i
                                className="logsPopup__headClose _click"
                                onClick={() => {
                                    handlerPopup({
                                        name: 'logsPopup',
                                        isShow: false,
                                        modelName: undefined,
                                        modelId: undefined,
                                        models: undefined,
                                    });
                                }}
                            >
                                <Icon name="close-circle" />
                            </i>
                        </div>
                        <div className="logsPopup__content">
                            {modelName && (
                                <LogsTable
                                    filter={filter}
                                    modelName={modelName}
                                    modelId={modelId}
                                    models={models}
                                    setCounter={this.setCounter}
                                    handlerLoaderList={this.handlerLoaderList}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            )}

                            <Animate
                                className="logsPopup__scrollLoader _loaderScroll"
                                isShow={isShowLoaderList}
                            >
                                <div className="logsPopup__scrollLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        logsPopup: state.logsPopup,
    };
}

export default connect(mapStateToProps)(LogsPopup);

LogsPopup.propTypes = {
    logsPopup: PropTypes.object,
};
