import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getWidthInput from '../../../functions/getWidthInput';

import File from './card/File.jsx';

import InputRunning from '../../InputRunning.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import Field from '../../Field.jsx';
import Select from '../../Select.jsx';
import Icon from '../../Icon.jsx';
import Animate from '../../Animate.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class Doc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeadParams = this.setHeadParams.bind(this);
        this.checkEdit = this.checkEdit.bind(this);

        this.parent = React.createRef();
    }

    paddingsInput = 12;

    orderFiles = ['forward', 'back'];

    headNameInfo = {
        name: {
            classNames: ['manualDoc__headNameItem'],
            widthParam: 3,
        },
        description: {
            classNames: ['manualDoc__headNameItem', '_description'],
            widthParam: 2,
        },
    };

    files = {
        forward: {
            name: (
                <>
                    Сторона
                    <br />
                    <span>лицевая</span>
                </>
            ),
        },
        back: {
            name: (
                <>
                    Сторона
                    <br />
                    <span>оборотная</span>
                </>
            ),
        },
    };

    getWidthInput({ name }) {
        const info = this.headNameInfo[name];
        const { classNames } = info;
        let width = getWidthInput({
            classNames,
            value: this.props[name],
        });

        if (!this.props[name]) {
            width = 50;
        }

        return width;
    }

    setHeadParams() {
        const { isEdit } = this.props;

        return new Promise((resolve) => {
            const resultParams = this.state.resultParams || {};

            if (this.parent.current) {
                const headName = this.parent.current.querySelector('.manualDoc__headName');
                const innerWidth =
                    this.getWidthInput({ name: 'name' }) +
                    this.getWidthInput({ name: 'description' }) +
                    8 +
                    (isEdit ? this.paddingsInput * 2 : 0);

                if (
                    resultParams.widthName !== headName.offsetWidth ||
                    resultParams.widthNameInner !== innerWidth
                ) {
                    resultParams.widthName = headName.offsetWidth;

                    resultParams.widthNameInner = innerWidth;

                    this.setState({ resultParams }, () => {
                        resolve();
                    });
                }
            } else {
                resolve();
            }
        });
    }

    renderHeadInput({ name }) {
        const { _id, isEdit, change, errors = [] } = this.props;

        const error = errors.indexOf(name) !== -1;

        return (
            <InputRunning
                type="text"
                className={`manualDoc__headNameItem _${name} ${error ? '_error' : ''}`}
                value={this.props[name]}
                readOnly={!isEdit}
                id={`${name}-${_id}`}
                onChange={({ target: { value } }) => {
                    change({ _id, action: 'change', name, value });
                }}
                onFocus={(e) => {
                    if (!isEdit) {
                        e.target.blur();
                    }
                }}
                isRun={!isEdit}
                autoComplete="off"
            />
        );
    }

    checkEdit() {
        const { isEdit, name, description } = this.props;

        if (isEdit !== this.isEdit || name !== this.name || description !== this.description) {
            this.isEdit = isEdit;
            this.name = name;
            this.description = description;

            this.setHeadParams();
        }
    }

    componentDidMount() {
        this.setHeadParams();
    }

    componentDidUpdate() {
        this.checkEdit();
        this.setHeadParams();
    }

    render() {
        const {
            _id,
            isEdit,
            name,
            folder,
            change,
            upload,
            files = [],
            deleteFile,
            deleteDoc,
            dateGet,
            dateAction,
            dateActionString,
            parent,
            isNew,
            errors = [],
            windowIsReady,
            parentScroll,
        } = this.props;
        const { length: lenFiles } = files.filter((fileLoop) => fileLoop.file.path);

        return (
            <div ref={this.parent} className={`manualDoc ${isEdit ? '_isEdit' : ''}`}>
                <div className="manualDoc__head _row">
                    <div className="manualDoc__headName _col">
                        <div className="manualDoc__headNameInner _row">
                            {this.renderHeadInput({ name: 'name', windowIsReady })}
                        </div>
                    </div>
                    <div className="manualDoc__headCounter">
                        <AnimateChange
                            className="manualDoc__headCounterInner"
                            prop={lenFiles}
                            type="_translateMedium"
                        >
                            {`${lenFiles}/${files.length}`}
                        </AnimateChange>
                    </div>
                </div>
                <div className="manualDoc__content">
                    <div className="manualDoc__files _row">
                        {this.orderFiles
                            .filter((nameOfFile) =>
                                files.find((fileLoop) => fileLoop.name === nameOfFile),
                            )
                            .map((nameOfFile) => {
                                const fileInfo = this.files[nameOfFile];
                                const file = files.find((fileLoop) => fileLoop.name === nameOfFile);

                                return (
                                    <div
                                        className={`manualDoc__file ${
                                            files.length === 1 ? '_full' : ''
                                        }`}
                                        key={nameOfFile}
                                    >
                                        <File
                                            _id={file._id}
                                            files={files
                                                .filter((fileLoop) => fileLoop.file.path)
                                                .map((fileLoop) => ({
                                                    _id: fileLoop._id,
                                                    path: fileLoop.file.path,
                                                    type: fileLoop.file.type,
                                                }))}
                                            folder={folder}
                                            type={_id}
                                            title={name}
                                            name={nameOfFile}
                                            isLocal={file.isLocal}
                                            file={file.file}
                                            isDisabled={!isEdit}
                                            uploadFile={({ ...props }) =>
                                                upload({ ...props, _id: file._id })
                                            }
                                            deleteFile={({ ...props }) =>
                                                deleteFile({ ...props, _id: file._id })
                                            }
                                        >
                                            {fileInfo.name}
                                        </File>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="manualDoc__info _row">
                        <div className="manualDoc__infoItem _row">
                            <p className="manualDoc__infoSupport">Выдан:</p>
                            <div className="manualDoc__infoBox">
                                <Field
                                    support="–"
                                    name="dateGet"
                                    id={`dateGet-${_id}`}
                                    keyName="date"
                                    value={dateGet}
                                    callback={async ({ ...props }) => {
                                        change({ _id, ...props }).then(() => true);
                                    }}
                                    className="_manualDoc"
                                    isDisabled={!isEdit}
                                    max={new Date()}
                                    error={errors.indexOf('dateGet') !== -1}
                                    isEditmode={true}
                                />
                            </div>
                        </div>
                        <div className="manualDoc__infoItem _row _select">
                            <p className="manualDoc__infoSupport">Годен до:</p>
                            <div className="manualDoc__infoBox">
                                <Select
                                    support="–"
                                    type="date"
                                    name="dateAction"
                                    id={`dateAction-${_id}`}
                                    value={dateAction}
                                    className="_manualDoc _notBack _reverse"
                                    callback={async ({ ...props }) => {
                                        change({ _id, ...props }).then(() => true);
                                    }}
                                    otherField={{
                                        keyInput: 'dateActionString',
                                        date: dateActionString,
                                    }}
                                    isDisabled={!isEdit}
                                    setStateComplete={true}
                                    isEditmode={true}
                                />
                            </div>
                        </div>
                        <Animate
                            className="manualDoc__infoDelete _click"
                            isShow={isEdit}
                            onClick={({ target }) => {
                                if (isNew) {
                                    deleteDoc({ _id });
                                } else {
                                    handlerWindow({
                                        parent,
                                        parentResize: parent,
                                        parentScroll,
                                        target: target.closest('.manualDoc__infoDelete'),
                                        action: 'show',
                                        name: 'promptDeleteDoc',
                                        className: '_prompt _center',
                                        uniqKey: `delete-doc-${_id}`,
                                        watchesProps: { left: true },
                                        deleteDoc: async () => {
                                            deleteDoc({ _id }).then(() => true);
                                        },
                                        isHideFromScroll: true,
                                        isCheckScrollPosition:false,
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }
                            }}
                        >
                            <Icon name="actions-delete" />
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(Doc);

Doc.propTypes = {
    windowIsReady: PropTypes.any,
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    folder: PropTypes.string,
    isEdit: PropTypes.bool,
    change: PropTypes.func,
    files: PropTypes.array,
    upload: PropTypes.func,
    deleteFile: PropTypes.func,
    dateGet: PropTypes.string,
    dateAction: PropTypes.string,
    dateActionString: PropTypes.string,
    deleteDoc: PropTypes.func,
    parent: PropTypes.object,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    parentScroll: PropTypes.object,
};
