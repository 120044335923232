import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getEndText from '../../../functions/getEndText';

import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import Field from '../../Field.jsx';
import handlerWindow from '../../../functions/handlerWindow';
import Animate from '../../Animate.jsx';

class SettingsRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.deleteRole = this.deleteRole.bind(this);
        this.handlerMore = this.handlerMore.bind(this);

        this.parent = React.createRef();
    }

    deleteRole({ target, resultTarget }) {
        const { _id, getParent, isNew, hideDeleteRole } = this.props;

        if (isNew) {
            hideDeleteRole({ id: _id });
        } else {
            handlerWindow({
                parent: getParent(),
                parentResize: getParent(),
                target: resultTarget || target.closest('.settingsRole__delete'),
                action: 'show',
                name: 'promptDeleteRole',
                className: '_prompt',
                uniqKey: `prompt-${_id}`,
                roleId: _id,
                centers: {
                    left: 0.5,
                    top: 1,
                },
            });
        }
    }

    handlerMore({ target }) {
        const { _id, getParent, getParentForScroll } = this.props;
        const parent = getParent();
        const actionsItems = ['deleteRole', 'logs'];
        const uniqKey = `role-${_id}`;

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: getParentForScroll(),
            target: target.closest('.settingsRole__more'),
            action: 'show',
            name: 'roleActions',
            className: '_actions _right',
            uniqKey,
            items: actionsItems,
            roleId: _id,
            centers: {
                left: 1,
                top: 0,
            },
            deleteRole: this.deleteRole.bind(this),
            isHideFromScroll: true,
            isCheckScrollPosition: false,
        });
    }

    render() {
        const {
            _id,
            name,
            color,
            usersCounter,
            isEdit,
            handlerRole,
            errors = [],
            isNew,
            isAdmin,
        } = this.props;
        const counter = `${usersCounter} ${getEndText(usersCounter, [
            'человек',
            'человека',
            'человек',
        ])}`;

        return (
            <div
                ref={this.parent}
                className={`settingsRole _col ${isEdit ? '_edit' : ''} ${isNew ? '_new' : ''}`}
            >
                <div className="settingsRole__color" style={{ background: color }} />
                <Animate
                    className="settingsRole__more _click"
                    isShow={!isAdmin && !isNew}
                    onClick={this.handlerMore}
                >
                    <Icon name="more-vertical" />
                </Animate>
                <div className="settingsRole__content">
                    <div className="settingsRole__name">
                        <Field
                            type="role"
                            // keyName=""
                            name="name"
                            value={name}
                            callback={handlerRole}
                            isDisabled={!isEdit}
                            isEditmode={true}
                            id={_id}
                            error={errors.indexOf('name') !== -1}
                        />
                    </div>
                    <div className="settingsRole__description">
                        <AnimateChange
                            className="settingsRole__descriptionInner"
                            prop={counter}
                            type="_translateMedium"
                        >
                            <>Назначено: {counter}</>
                        </AnimateChange>
                    </div>
                </div>

                <div className="settingsRole__foot _row">
                    {!isAdmin ? (
                        <>
                            <Link
                                pageName="settings-roles-inner"
                                ids={{ 3: _id }}
                                className="settingsRole__link"
                            >
                                Редактировать роль
                                <i className="settingsRole__linkIcon">
                                    <Icon name="arrow-next" />
                                </i>
                            </Link>
                            <Animate
                                className="settingsRole__delete _click"
                                onClick={this.deleteRole}
                                isShow={isNew}
                            >
                                <Icon name="actions-delete" />
                            </Animate>
                        </>
                    ) : (
                        <>
                            <p className="settingsRole__support">Системная роль</p>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsRole);

SettingsRole.propTypes = {
    _id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    usersCounter: PropTypes.number,
    isEdit: PropTypes.bool,
    handlerRole: PropTypes.func,
    errors: PropTypes.array,
    hideDeleteRole: PropTypes.func,
    isNew: PropTypes.bool,
    isAdmin: PropTypes.bool,
    getParent: PropTypes.func,
    getParentForScroll: PropTypes.func,
};
