import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from '../../../../components/Link.jsx';
import More from '../../../../components/crm/widget/More.jsx';
import AnimateChange from '../../../../components/AnimateChange.jsx';
import mainCheckRights from '../../../../functions/crm/checkRights';

import getFormatPrice from '../../../../functions/getFormatPrice';
import getPage from '../../../../functions/getPage';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

const pages = require('../../../../redux/pages').default.filter(
    (page) => page.parentName === 'manual-executors-inner' && page.level === 4,
);

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);
        this.delete = this.delete.bind(this);

        setPermissions.call(this);
    }

    orderLinks = [{ key: 'main' }, { key: 'docs' }, { key: 'wallets' }, { key: 'more' }];

    links = {
        main: {
            key: 'manual-executors-inner-main',
        },
        wallets: {
            key: 'manual-executors-inner-wallets',
        },
        docs: {
            key: 'manual-executors-inner-docs',
        },
    };

    filterLink({ key }) {
        const { isNew, type } = this.props;

        return !isNew && (['driver', 'auto-courier'].includes(type) || key !== 'cars');
    }

    renderLink({ prop: key }) {
        const { user, levels, executor } = this.props;

        if (key === 'more') {
            let subPages = pages.filter((page) =>
                [
                    'manual-executors-inner-balance',
                    'manual-executors-inner-contracts',
                    'manual-executors-inner-logs',
                ].includes(page.name),
            );

            if (mainCheckRights({ user, key: 'pays' })) {
                subPages.unshift(pages.find((page) => page.name === 'manual-executors-inner-pays'));
            }

            if (
                !this.getPermissions({
                    key: 'manual',
                    items: [
                        {
                            key: 'executors',
                            rules: ['update'],
                        },
                    ],
                })
            ) {
                subPages = pages.filter((page) =>
                    ['manual-executors-inner-wallets'].includes(page.name),
                );
            }

            return (
                <More
                    otherData={{ executor }}
                    pages={subPages}
                    ids={{ 3: executor._id }}
                    organizationId={executor.idOfCompany}
                />
            );
        }

        const { balance = 0 } = this.props;
        const link = this.links[key];
        const { key: namePage } = link;
        const page = getPage({ name: namePage });
        const { name, contentOfLink } = page;

        let className = '';

        if (key === 'docs') {
            className = '_active';

            if (executor.verification?.status === 'active') {
                className += ' _complete';
            }
        }

        return (
            <Link pageName={name} className={`widget__headLink ${className}`} ids={{ 3: levels[3] }}>
                <div className="widget__headLinkInner _click">
                    {name === 'manual-executors-inner-balance' && (
                        <div
                            className={`widget__headLinkCounter _balance ${
                                +balance < 0 ? '_minus' : ''
                            }`}
                        >
                            <AnimateChange
                                className="widget__headLinkCounterInner"
                                prop={balance}
                                type="_translateMedium"
                            >
                                {`${getFormatPrice(+balance.toFixed(1))}₽`}
                            </AnimateChange>
                        </div>
                    )}
                    {contentOfLink}
                </div>
            </Link>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;
        const { executor } = this.props;
        let items = this.orderLinks.filter(this.filterLink);

        if (executor?.status === 'app-invite') {
            items = [{ key: 'main' }];
        }

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                {this.getPermissions({
                    key: 'manual',
                    items: [
                        {
                            key: 'executors',
                            rules: ['delete'],
                        },
                    ],
                }) && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    balance: PropTypes.number,
    isNew: PropTypes.bool,
    type: PropTypes.string,
    levels: PropTypes.array,
    executor: PropTypes.object,
    user: PropTypes.object,
    delete: PropTypes.func,
};
