import React from 'react';
import { connect } from 'react-redux';

import Icon from '@components/Icon.jsx';

import BusinessHeaderI from './types.ts';

import renderBoard from './renders/renderBoard.tsx';
import cards from './static/cards.ts';

class BusinessHeader
    extends React.Component<BusinessHeaderI['props'], BusinessHeaderI['state']>
    implements BusinessHeaderI
{
    constructor(props: BusinessHeaderI['props']) {
        super(props);
        this.state = {};
    }

    cards = cards;

    renderBoard = renderBoard;

    render() {
        return (
            <div className="publicBusinessHeader _SECTION">
                <div className="publicBusinessHeader__inner _INNER _PUBLIC">
                    <h1 className="publicBusinessHeader__title">
                        <b>Цифровизация вашего бизнеса:</b>
                        <br />
                        от найма — <br className='_DESKTOP' />
                        до выплат
                    </h1>
                    <div className="publicBusinessHeader__more">
                        <i className="publicBusinessHeader__moreIcon">
                            <Icon name="info-more" />
                        </i>
                        Узнать
                        <br />
                        больше
                    </div>
                </div>
                {this.renderBoard()}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(BusinessHeader);
