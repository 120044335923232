export default function getCorporationCommission({ tariff, name, amount }) {
    const currentTariff = tariff[name];
    let commission = currentTariff.amount || 0;

    if (currentTariff.current === 'percent') {
        commission = Math.ceil((currentTariff.percent / 100) * amount);

        if (commission < currentTariff.min) {
            commission = currentTariff.min;
        }
    }

    return commission;
}
