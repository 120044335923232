import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsolute from '../../../../../components/ListAbsolute.jsx';

class ManualOrganizationsInnerUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.setHeightCards = this.setHeightCards.bind(this);
        this.handlerForm = this.handlerForm.bind(this);

        this.parent = React.createRef();
    }

    getCards() {
        const { organization, checkRights } = this.props;
        const { otherInfo } = organization;
        const { users = [] } = otherInfo;

        let cards = users;

        if (checkRights()) {
            cards = users.concat(...[{ _id: 'add', isAdd: true }]);
        }

        return cards;
    }

    setHeightCards({ height }) {
        const { setHeightPage } = this.props;

        this.setState({ heightCards: height }, () => {
            setHeightPage();
        });
    }

    handlerForm(isShowAddForm) {
        return new Promise((resolve) => {
            this.setState({ isShowAddForm }, () => {
                resolve();
            });
        });
    }

    renderCard({ item, prop: id, key: order, isShow, params }) {
        const cards = this.getCards();
        const card = cards.find((cardLoop) => cardLoop._id === id) || item;

        if (card.isAdd) {
            return null;
        }

        return (
            <div
                className={`manualOrganizations__card _parentForEdit _editBack ${isShow ? '_show' : ''}`}
                key={card._id}
                data-_id={card._id}
                style={{
                    transform: `translate(${params?.offsetLeft}px,${params?.offsetTop}px)`,
                    order,
                }}
            >
                123
            </div>
        );
    }

    componentDidMount() {
        this.setState({ isInit: true });
    }

    render() {
        const { heightCards = 0, isShowAddForm } = this.state;
        const { setHeightPage } = this.props;
        const cards = this.getCards();

        return (
            <div ref={this.parent} className="manualOrganizations">
                <div className="manualOrganizations__inner">
                    <div
                        className="manualOrganizations__cards _col"
                        style={{ height: `${heightCards}px` }}
                    >
                        <div className="manualOrganizations__cardsInner">
                            <ListAbsolute
                                parent={(() =>
                                    this.parent.current?.querySelector(
                                        '.manualOrganizations__cards',
                                    ))()}
                                items={cards}
                                renderItem={this.renderCard}
                                classNameItem="manualOrganizations__card"
                                prop="_id"
                                setParamsParent={this.setHeightCards}
                                paramsParent={{ width: true }}
                                callback={() => setHeightPage()}
                                keyRender={isShowAddForm}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualOrganizationsInnerUsers);

ManualOrganizationsInnerUsers.propTypes = {
    serverData: PropTypes.object,
    organization: PropTypes.object,
    organizationSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    isInit: PropTypes.bool,
    getParent: PropTypes.func,
    checkRights: PropTypes.func,
};
