import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function getExecutors({ id, params = [] }) {
    let query = '';

    if (id) {
        query += `id=${id}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/executor?${query}`, { headers: getHeaders() })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success) {
                        const { executors = [], executor, isLimit, counter } = data;

                        resolve({ executors, executor, isLimit, counter });
                    } else {
                        handlerErrorRequest(res);

                        reject(res.data);
                    }
                } else {
                    reject(res.data);
                }
            }, reject);
    });
}
