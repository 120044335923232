import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import {
    addRoute,
    findPointWords,
    geocode,
    getHouse,
    optimizationRoutes,
    pointInArea,
} from '../../functions/handlerYmap';
import getInfoRoute from '../../functions/order/getInfoRoute';
import createId from '../../requests/createId';

export default class Route {
    constructor({ context, type, mapDisabled }) {
        this.context = context;
        this.type = type;
        this.mapDisabled = mapDisabled;

        this.handlerApiAvaliable = this.handlerApiAvaliable.bind(this);
        this.mapInit = this.mapInit.bind(this);
        this.fitToMap = this.fitToMap.bind(this);
        this.setOrderPoints = this.setOrderPoints.bind(this);
        this.setProccess = this.setProccess.bind(this);
        this.init = this.init.bind(this);
        this.checkCondForDelete = this.checkCondForDelete.bind(this);
        this.checkCondForAdd = this.checkCondForAdd.bind(this);
        this.setDate = this.setDate.bind(this);
        this.handlerAmount = this.handlerAmount.bind(this);
        this.updateTariffs = this.updateTariffs.bind(this);
        this.getNewOrder = this.getNewOrder.bind(this);
        this.setAreaOfPoints = this.setAreaOfPoints.bind(this);
        this.optimization = this.optimization.bind(this);
        this.bootstrap = this.bootstrap.bind(this);
    }

    item = {
        name: '',
        long: null,
        width: null,
        height: null,
        weight: null,
        counter: 1,
    };

    cargo = {
        type: 'delivery',
        number: null,
        counterPlace: null,
        comment: null,
        items: [],
        images: [],
    };

    point = {
        coords: null,
        address: '',
        statuses: [],
        status: 'wait',
        state: 1,
        stateOfComplete: -1,
        showDrop: false,
        // heightDrop: 220,
        list: {
            state: -1,
            items: [],
        },
        addressatInfo: {
            name: {
                value: '',
            },
            phone: {
                value: '',
            },
            time: {
                value: '',
            },
            timeStart: {
                value: '',
            },
            timeEnd: {
                value: '',
            },
            entrance: {
                value: '',
            },
            floor: {
                value: '',
            },
            room: {
                value: '',
            },
        },
        cargo: [
            {
                ...JSON.parse(JSON.stringify({ ...this.cargo, id: new Date().getTime() })),
                stateOfShow: 1,
            },
        ],
    };

    setPoints(points) {
        return new Promise((resolve) =>
            this.context.setState(
                (state) => {
                    const newState = { ...state };

                    newState.points = points;

                    return newState;
                },
                () => {
                    this.fitToMap();
                    resolve();
                },
            ),
        );
    }

    checkCondForDrag() {
        const { order, points } = this.context.state;
        const idOfCurrentPoint = order?.idOfCurrentPoint;
        const indexCurrentItem =
            this.type === 'details'
                ? order &&
                  ['new', 'start-work', 'choice-crew'].indexOf(order.status) === -1 &&
                  points?.findIndex((point) => point._id === idOfCurrentPoint)
                : undefined;

        const pointsFilters = points?.filter(
            (point, key) =>
                !point.isDelete &&
                ((checkValueOfEmpty(indexCurrentItem) && key > indexCurrentItem) ||
                    !checkValueOfEmpty(indexCurrentItem)),
        );

        return !pointsFilters?.find((point) => !point.isComplete) && pointsFilters?.length >= 2;
    }

    checkCondForAdd() {
        const { order, points } = this.context.state;
        const idOfCurrentPoint = order?.idOfCurrentPoint;
        const indexCurrentItem =
            this.type === 'details' &&
            order &&
            ['new', 'start-work', 'choice-crew'].indexOf(order.status) === -1 &&
            points?.findIndex((point) => point._id === idOfCurrentPoint);

        const pointsFilters = points?.filter(
            (point, key) =>
                !point.isDelete &&
                ((checkValueOfEmpty(indexCurrentItem) && key > indexCurrentItem) ||
                    !checkValueOfEmpty(indexCurrentItem)),
        );

        return pointsFilters?.every((point) => point.isComplete);
    }

    checkCondForDelete() {
        const { order, points } = this.context.state;
        const pointsFilters = points?.filter((item) => !item.isDelete);

        return (
            pointsFilters?.length >
            (order?.systemType === 'service' || process.env.REACT_APP_SYSTEM === 'site' ? 2 : 1)
        );
    }

    addPoint({ key = this.context.state.points.length, ...props }) {
        return new Promise((resolve) => {
            createId().then(
                ({ id }) => {
                    const points = JSON.parse(JSON.stringify(this.context.state.points));
                    const newPoint = JSON.parse(JSON.stringify(this.point));

                    newPoint.state = 0;
                    newPoint.uniqKey = id;
                    newPoint._id = id;

                    Object.keys(props).forEach((prop) => {
                        newPoint[prop] = props[prop];
                    });

                    points.splice(key, 0, newPoint);

                    this.setPoints(points).then(
                        () => {
                            setTimeout(() => {
                                const pointsThis = JSON.parse(
                                    JSON.stringify(this.context.state.points),
                                );

                                pointsThis[key].state = 1;

                                this.setPoints(pointsThis).then(
                                    () => {
                                        resolve(key);
                                    },
                                    () => null,
                                );
                            }, 100);
                        },
                        () => null,
                    );
                },
                () => null,
            );
        });
    }

    deletePoint({ key }) {
        if (this.checkCondForDelete() && !this.context.state.isProccessSetRoute) {
            const points = JSON.parse(JSON.stringify(this.context.state.points));

            points[key].isDelete = true;
            points[key].state = 0;

            const images = points[key].cargo.reduce((prev, cur) => prev.concat(...cur.images), []);

            images.forEach((image) => {
                this.context.formDataImages.delete(`${points[key].uniqKey}-${image.name}`);
            });

            const handlerStart = () =>
                new Promise((resolve) => {
                    if (points[key].isComplete) {
                        this.setProccess(true).then(resolve, () => null);
                    } else {
                        resolve();
                    }
                });

            const handlerEnd = () =>
                new Promise((resolve) => {
                    if (points[key].isComplete) {
                        this.setRoute({ isNew: true }).then(
                            () => {
                                this.setProccess(false).then(resolve, () => null);
                            },
                            () => null,
                        );
                    } else {
                        resolve();
                    }
                });

            handlerStart().then(
                () => {
                    this.setPoints(points).then(
                        () => {
                            setTimeout(() => {
                                const pointsThis = JSON.parse(
                                    JSON.stringify(this.context.state.points),
                                );

                                pointsThis.splice(key, 1);

                                this.setPoints(pointsThis).then(
                                    () => {
                                        handlerEnd().then(
                                            () => {
                                                this.context.setState((state) => {
                                                    const newState = { ...state };

                                                    newState.counterSort += 1;

                                                    return newState;
                                                });
                                            },
                                            () => null,
                                        );
                                    },
                                    () => null,
                                );
                            }, 500);
                        },
                        () => null,
                    );
                },
                () => null,
            );
        }
    }

    getDrop({ address }) {
        return new Promise((resolve) => {
            if (address && address.length > 2) {
                findPointWords(this.context.state.ymaps, address).then(
                    (response) => {
                        const items = response.map((item) => item.displayName);

                        resolve(items);
                    },
                    () => null,
                );
            } else {
                resolve([]);
            }
        });
    }

    isProccessDrop = false;

    dropTimerId = null;

    setDrop({ key, address, isHide = false }) {
        const currentPoint = this.context.state.points[key];

        const setStateDrop = (values) =>
            new Promise((resolve) => {
                this.context.setState((state) => {
                    const newState = { ...state };
                    const points = JSON.parse(JSON.stringify(newState.points));

                    Object.keys(values).forEach((prop) => {
                        points[key].list[prop] = values[prop];
                    });

                    newState.points = points;

                    return newState;
                }, resolve);
            });

        this.getDrop({ address }).then(
            (items) => {
                if (this.dropTimerId) {
                    clearTimeout(this.dropTimerId);
                }

                if (items.length && !isHide) {
                    const values = { items };

                    if (!this.isProccessDrop && currentPoint.list.state === -1) {
                        values.state = 0;
                    }

                    this.isProccessDrop = true;

                    setStateDrop(values).then(
                        () => {
                            this.dropTimerId = setTimeout(() => {
                                setStateDrop({ state: 1 }).then(
                                    () => {
                                        this.isProccessDrop = false;
                                    },
                                    () => null,
                                );
                            }, 10);
                        },
                        () => null,
                    );
                } else {
                    this.isProccessDrop = true;

                    setStateDrop({ state: 0 }).then(
                        () => {
                            this.dropTimerId = setTimeout(() => {
                                setStateDrop({ state: -1, items: [] }).then(
                                    () => {
                                        this.isProccessDrop = false;
                                    },
                                    () => null,
                                );
                            }, 500);
                        },
                        () => null,
                    );
                }
            },
            () => null,
        );
    }

    fitToMap() {
        if (this.context.state.map) {
            this.context.state.map.container.fitToViewport();
        }
    }

    checkStateOfMap() {
        if (!this.alwaysShowMap) {
            const { points } = this.context.state;
            const init =
                points.filter((point) => point.address !== '' || point.isComplete === true).length >
                0;

            this.handlerViewMap({ init });
        }
    }

    getCoords() {
        return new Promise((resolve) => {
            const { points } = this.context.state;

            const getCoords = points
                .filter((point) => point.isComplete)
                .map(
                    (point) =>
                        new Promise((resolvePoint) => {
                            if (point.coords) {
                                resolvePoint(point.coords);
                            } else {
                                geocode(this.context.state.ymaps, point.address).then(
                                    (response) => {
                                        if (this.context.state.map) {
                                            const { _coordinates: coords } = response.geometry;

                                            resolvePoint(coords);
                                        } else {
                                            resolvePoint(null);
                                        }
                                    },
                                    () => null,
                                );
                            }
                        }),
                );

            Promise.all(getCoords).then(
                (resultCoords) => {
                    resolve(resultCoords);
                },
                () => null,
            );
        });
    }

    setProccess(isProccessSetRoute) {
        return new Promise((resolve, reject) => {
            if (
                (isProccessSetRoute && !this.context.state.isProccessSetRoute) ||
                !isProccessSetRoute
            ) {
                this.context.setState((state) => {
                    const newState = { ...state };

                    newState.isProccessSetRoute = isProccessSetRoute;

                    return newState;
                }, resolve);
            } else {
                reject();
            }
        });
    }

    setRoute({ isNew, afterOptimization = false }) {
        return new Promise((resolve) => {
            const {
                ymaps,
                map,
                infoRoute: { routePrev },
            } = this.context.state;

            if (!ymaps || !map) {
                resolve();
            } else {
                this.getCoords().then(
                    (coords) => {
                        addRoute({ ymaps, map, points: coords, routePrev, isNew }).then(
                            (infoRoute) => {
                                this.context.setState(
                                    (state) => {
                                        const newState = { ...state };

                                        newState.infoRoute = {
                                            ...newState.infoRoute,
                                            ...infoRoute,
                                        };

                                        if (
                                            coords.length > 2 &&
                                            !newState.isShowWindowOptimization
                                        ) {
                                            newState.isShowWindowOptimization = true;
                                        }

                                        if (afterOptimization === false) {
                                            newState.isOptimize = false;
                                            newState.pointsOld = null;
                                        }

                                        return newState;
                                    },
                                    () => {
                                        const { handlerTariffs } = this.context;

                                        if (handlerTariffs) {
                                            handlerTariffs.setMinTime(null, true);
                                        }

                                        resolve(infoRoute);
                                    },
                                );
                            },
                            () => null,
                        );
                    },
                    () => null,
                );
            }
        });
    }

    getNewOrder({ newOrder }) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = { ...state };

                newState.newOrder = newOrder;

                return newState;
            }, resolve);
        });
    }

    setOrderPoints({ newOrder, afterOptimization = false, isSaveOld = false }) {
        if (newOrder.every((item, key) => item === key)) {
            return new Promise((resolve) => {
                const { points } = this.context.state;

                this.context.setState((state) => {
                    const newState = { ...state };

                    newState.pointsOld = isSaveOld ? JSON.parse(JSON.stringify(points)) : null;

                    return newState;
                }, resolve);
            });
        }

        return new Promise((resolve) => {
            this.setProccess(true).then(
                () => {
                    const { points } = this.context.state;
                    const newPoints = [];

                    newOrder.forEach((number, key) => {
                        newPoints[number] = { ...points[key] };
                    });

                    this.context.setState(
                        (state) => {
                            const newState = { ...state };

                            newState.pointsOld = isSaveOld
                                ? JSON.parse(JSON.stringify(points))
                                : null;

                            newState.points = newPoints;

                            newState.counterSort += 1;
                            newState.newOrder = null;

                            return newState;
                        },
                        () => {
                            this.setRoute({ afterOptimization }).then(
                                () => {
                                    this.setProccess(false).then(resolve, () => null);
                                },
                                () => null,
                            );
                        },
                    );
                },
                () => null,
            );
        });
    }

    changePoint({ key, values = {}, isComplete }) {
        let changeValue = false;

        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };
                    const points = JSON.parse(JSON.stringify(newState.points));

                    if (values.address && points[key].isComplete && !isComplete) {
                        changeValue = true;

                        points[key].isComplete = false;
                        points[key].ttk = false;
                        points[key].sk = false;
                        points[key].coords = null;
                    }

                    Object.keys(values).forEach((prop) => {
                        points[key][prop] = values[prop];
                    });

                    newState.points = points;

                    return newState;
                },
                () => {
                    if (isComplete) {
                        this.setDrop({ key, address: values.address, isHide: true });
                    }
                    if (changeValue) {
                        this.setRoute({ isNew: true }).then(resolve, () => null);
                    } else {
                        if (values.hasOwnProperty('address') && !isComplete) {
                            this.setDrop({ key, address: values.address });
                        }

                        if (isComplete) {
                            this.changePoint({
                                key,
                                values: { isComplete: true },
                            });
                        }

                        this.checkStateOfMap();

                        resolve();
                    }
                },
            );
        });
    }

    optimization(isOptimize = this.context.state.isOptimize) {
        const setOptimization = (isOptimizeRes) =>
            new Promise((resolve) => {
                this.context.setState((state) => {
                    const newState = { ...state };

                    newState.isOptimize = isOptimizeRes;

                    return newState;
                }, resolve);
            });

        const setProccessOptimization = (isProccessOptimization) =>
            new Promise((resolve) => {
                this.context.setState((state) => {
                    const newState = { ...state };

                    newState.isProccessOptimization = isProccessOptimization;

                    return newState;
                }, resolve);
            });

        return new Promise((resolve, reject) => {
            if (!this.context.state.isProccessOptimization) {
                setProccessOptimization(true).then(
                    () => {
                        if (!isOptimize) {
                            const { ymaps, map, points } = this.context.state;
                            const newOrder = [];

                            optimizationRoutes(ymaps, map, points).then(
                                ({ points: pointsUpdate }) => {
                                    points.forEach((point, key) => {
                                        newOrder[key] = pointsUpdate.findIndex(
                                            (item) => item.uniqKey === point.uniqKey,
                                        );
                                    });

                                    this.getNewOrder({ newOrder }).then(
                                        () => {
                                            setTimeout(() => {
                                                this.setOrderPoints({
                                                    newOrder,
                                                    afterOptimization: true,
                                                    isSaveOld: true,
                                                }).then(
                                                    () => {
                                                        setOptimization(true).then(
                                                            () => {
                                                                setProccessOptimization(false).then(
                                                                    resolve,
                                                                    () => null,
                                                                );
                                                            },
                                                            () => null,
                                                        );
                                                    },
                                                    () => null,
                                                );
                                            }, 300);
                                        },
                                        () => null,
                                    );
                                },
                            );
                        } else {
                            const { pointsOld, points } = this.context.state;
                            const newOrder = [];
                            pointsOld.forEach((point, key) => {
                                newOrder[key] = points.findIndex(
                                    (item) => item.uniqKey === point.uniqKey,
                                );
                            });

                            this.getNewOrder({ newOrder }).then(
                                () => {
                                    setTimeout(() => {
                                        this.setOrderPoints({ newOrder }).then(
                                            () => {
                                                setOptimization(false).then(
                                                    () => {
                                                        setProccessOptimization(false).then(
                                                            resolve,
                                                            () => null,
                                                        );
                                                    },
                                                    () => null,
                                                );
                                            },
                                            () => null,
                                        );
                                    }, 300);
                                },
                                () => null,
                            );
                        }
                    },
                    () => null,
                );
            } else {
                reject();
            }
        });
    }

    timerViewMap = null;

    handlerViewMap({ init }) {
        return new Promise((resolve) => {
            let delay = 0;

            if (init === false) {
                delay = 500;
            }

            this.context.setState((state) => {
                const newState = { ...state };

                newState.isShowMap = init;

                return newState;
            });

            if (this.timerViewMap) {
                clearTimeout(this.timerViewMap);
            }

            this.timerViewMap = setTimeout(() => {
                this.context.setState(
                    (state) => {
                        const newState = { ...state };

                        newState.isInitOfMap = init;

                        return newState;
                    },
                    () => {
                        resolve(true);
                    },
                );
            }, delay);
        });
    }

    choiceFromList({ key, address, callback }) {
        const { ymaps, map } = this.context.state;

        this.setProccess(true).then(
            () => {
                geocode(ymaps, address).then((response) => {
                    if (map) {
                        const house = getHouse(response);

                        const { _coordinates: coords } = response.geometry;
                        const infoArea = pointInArea(ymaps, map, coords);

                        this.changePoint({
                            key,
                            values: { address, house, coords, ...infoArea },
                            isComplete: true,
                        }).then(
                            () => {
                                this.setRoute({ isNew: true }).then(
                                    () => {
                                        if (callback) {
                                            callback();
                                        }

                                        this.setProccess(false);
                                    },
                                    () => null,
                                );
                            },
                            () => null,
                        );
                    }
                });
            },
            () => null,
        );
    }

    updateTariffs() {
        const { handlerTariffs } = this.context;

        if (handlerTariffs) {
            let tariffs = handlerTariffs.getTariffs();
            if (!tariffs.length) {
                tariffs = handlerTariffs.getTariffs();
            }

            if (tariffs.length) {
                handlerTariffs.setCurrent(tariffs[0]._id);
                handlerTariffs.checkParamsOfItems();
            } else {
                handlerTariffs.setCurrent(null);
            }
        }
    }

    handlerCargo({ action, keyOfPoint, keyOfCargo, handlerDrop, props }) {
        return new Promise((resolve) => {
            const points = [...this.context.state.points];
            const currentPoint = points[keyOfPoint];

            if (action === 'add') {
                const { cargo } = currentPoint;

                cargo.push({
                    ...JSON.parse(JSON.stringify(this.cargo)),
                    stateOfShow: 0,
                    id: new Date().getTime(),
                });

                this.changePoint({
                    key: keyOfPoint,
                    values: { cargo },
                }).then(
                    () => {
                        handlerDrop(true);

                        setTimeout(() => {
                            const { cargo: cargoThis } = currentPoint;
                            const indexCargo = cargoThis.length - 1;

                            cargoThis[indexCargo].stateOfShow = 1;

                            this.changePoint({
                                key: keyOfPoint,
                                values: { cargo: cargoThis },
                            }).then(
                                () => {
                                    this.updateTariffs();

                                    resolve();
                                },
                                () => null,
                            );
                        }, 10);
                    },
                    () => null,
                );
            }

            if (action === 'delete') {
                const { cargo } = currentPoint;

                cargo[keyOfCargo].isDelete = true;

                cargo[keyOfCargo].images.forEach((image) => {
                    this.context.formDataImages.delete(`${currentPoint.uniqKey}-${image.name}`);
                });

                this.changePoint({
                    key: keyOfPoint,
                    values: { cargo },
                }).then(
                    () => {
                        handlerDrop(true);

                        setTimeout(() => {
                            const { cargo: cargoThis } = currentPoint;

                            cargoThis.splice(keyOfCargo, 1);

                            this.changePoint({
                                key: keyOfPoint,
                                values: { cargo: cargoThis },
                            }).then(
                                () => {
                                    this.updateTariffs();

                                    resolve();
                                },
                                () => null,
                            );
                        }, 300);
                    },
                    () => null,
                );
            }

            if (action === 'change') {
                const { cargo } = currentPoint;

                Object.keys(props).forEach((prop) => {
                    cargo[keyOfCargo][prop] = props[prop];
                });

                this.changePoint({
                    key: keyOfPoint,
                    values: { cargo },
                }).then(
                    () => {
                        this.updateTariffs();

                        resolve();
                    },
                    () => null,
                );
            }
        });
    }

    checkCompleteCargoItem(item) {
        const { name, long, width, height, weight } = item;

        return checkValueOfEmpty(name) && +long && +width && +height && +weight;
    }

    handlerCargoItem({
        keyOfPoint,
        keyOfCargo,
        key,
        action,
        name,
        value,
        handlerDrop,
        itemCome = {},
        ...props
    }) {
        return new Promise((resolve) => {
            const points = [...this.context.state.points];
            const currentPoint = points[keyOfPoint];
            const currentCargo = currentPoint.cargo;
            const currentItem = currentCargo[keyOfCargo].items[key];
            const { nameOfItem, items, images = [] } = props;

            if (action === 'add' && (checkValueOfEmpty(nameOfItem) || items)) {
                const lenItems = currentCargo[keyOfCargo].items.length;

                if (!items) {
                    currentCargo[keyOfCargo].items.push({
                        ...JSON.parse(JSON.stringify(this.item)),
                        name: nameOfItem,
                        ...itemCome,
                        stateOfShow: 0,
                        id: new Date().getTime(),
                    });
                } else {
                    items.forEach((item, keyItem) => {
                        currentCargo[keyOfCargo].items.push({
                            ...JSON.parse(JSON.stringify(this.item)),
                            ...item,
                            stateOfShow: 0,
                            id: new Date().getTime() + keyItem,
                        });
                    });

                    currentCargo[keyOfCargo].images = currentCargo[keyOfCargo].images.concat(
                        ...images,
                    );
                }

                this.changePoint({
                    key: keyOfPoint,
                    values: {
                        cargo: currentCargo,
                    },
                }).then(
                    () => {
                        if (handlerDrop) {
                            handlerDrop(true);
                        }

                        setTimeout(() => {
                            const { cargo: cargoThis } = currentPoint;

                            if (!items) {
                                const indexItem = cargoThis[keyOfCargo].items.length - 1;

                                cargoThis[keyOfCargo].items[indexItem].stateOfShow = 1;
                            } else {
                                items.forEach((item, keyItem) => {
                                    cargoThis[keyOfCargo].items[keyItem + lenItems].stateOfShow = 1;
                                });
                            }

                            this.changePoint({
                                key: keyOfPoint,
                                values: { cargo: cargoThis },
                            }).then(
                                () => {
                                    this.updateTariffs();

                                    resolve();
                                },
                                () => null,
                            );
                        }, 10);
                    },
                    () => null,
                );
            }

            if (action === 'change') {
                currentItem[name] = value;

                currentCargo[keyOfCargo].items[key] = { ...currentItem };

                this.changePoint({
                    key: keyOfPoint,
                    values: { cargo: currentCargo },
                }).then(
                    () => {
                        if (name === 'counter' && value === 0) {
                            this.handlerCargoItem({
                                keyOfPoint,
                                keyOfCargo,
                                key,
                                action: 'delete',
                                handlerDrop,
                            });
                        }
                        this.updateTariffs();
                        resolve();
                    },
                    () => null,
                );
            }

            if (action === 'delete') {
                currentCargo[keyOfCargo].items[key].isDelete = true;

                this.changePoint({
                    key: keyOfPoint,
                    values: { cargo: currentCargo },
                }).then(
                    () => {
                        if (handlerDrop) {
                            handlerDrop(true);
                        }

                        setTimeout(() => {
                            const { cargo: cargoThis } = currentPoint;

                            cargoThis[keyOfCargo].items.splice(key, 1);

                            this.changePoint({
                                key: keyOfPoint,
                                values: { cargo: cargoThis },
                            }).then(resolve);
                        }, 500);
                    },
                    () => null,
                );
            }

            if (action === 'deleteImage') {
                const { nameOfImage } = props;
                const { indexDelete } = currentCargo[keyOfCargo].images.findIndex(
                    (image) => image.name === nameOfImage,
                );

                if (indexDelete !== -1) {
                    currentCargo[keyOfCargo].images.splice(indexDelete, 1);

                    this.changePoint({
                        key: keyOfPoint,
                        values: { cargo: currentCargo },
                    });
                }
            }
        });
    }

    addEvents() {
        this.context.state.map.events.add('click', (e) => {
            const { ymaps, map } = this.context.state;
            const coords = e.get('coords');

            const points = [...this.context.state.points];
            const indexCurrentItem = points.findIndex((point) => point.isComplete !== true);

            const getCurrentPoint = () =>
                new Promise((resolve) => {
                    if (indexCurrentItem !== -1) {
                        resolve(indexCurrentItem);
                    } else {
                        this.addPoint({ isComplete: true }).then(
                            (key) => {
                                resolve(key);
                            },
                            () => null,
                        );
                    }
                });

            if (!this.context.state.isProccessSetRoute) {
                this.setProccess(true).then(() => {
                    geocode(ymaps, coords).then(
                        (response) => {
                            let address = response.properties._data.text.trim();

                            const house = getHouse(response);

                            if (address[0] === ' ') {
                                address = address.slice(1);
                            }

                            getCurrentPoint().then((keyPoint) => {
                                const infoArea = pointInArea(ymaps, map, coords);

                                this.changePoint({
                                    key: keyPoint,
                                    values: { address, house, coords, ...infoArea },
                                    isComplete: true,
                                }).then(() => {
                                    this.setRoute({ isNew: true }).then(() => {
                                        document.dispatchEvent(
                                            new CustomEvent('setPointAddress', {
                                                detail: { key: keyPoint },
                                            }),
                                        );

                                        this.setProccess(false);
                                    });
                                });
                            });
                        },
                        () => null,
                    );
                });
            }
        });
    }

    handlerAmount(value) {
        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };

                    newState.amountInItems = value;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    setDate({ date, times }) {
        this.context.setState((state) => {
            const newState = { ...state };

            newState.date = date;
            newState.times = times;

            return newState;
        });
    }

    setAreaOfPoints() {
        return new Promise((resolve) => {
            this.getCoords().then((coords) => {
                this.context.setState((state) => {
                    const newState = { ...state };
                    const { ymaps, map } = newState;

                    const points = JSON.parse(JSON.stringify(newState.points));

                    points.forEach((point, key) => {
                        point.coords = coords[key];

                        const infoArea = pointInArea(ymaps, map, point.coords);

                        Object.keys(infoArea).forEach((prop) => {
                            points[key][prop] = infoArea[prop];
                        });
                    });

                    newState.points = points;

                    return newState;
                }, resolve);
            });
        });
    }

    clear() {
        this.context.setState((state) => {
            const newState = { ...state };

            newState.map = null;
            newState.ymaps = null;

            return newState;
        });
    }

    bootstrap({ ymaps, map, routeCallback }) {
        // const address = 'г. Москва';

        // ymaps.geocode(address).then((result) => {
        //     console.log(result.geoObjects.get(0))
        // });

        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };

                    newState.ymaps = ymaps;
                    newState.map = map;

                    return newState;
                },
                () => {
                    if (this.context.state.map) {
                        this.fitToMap();

                        this.context.state.map.setCenter(this.context.state.mapInfo.center, 10, {
                            checkZoomRange: true,
                        });

                        if (!this.mapDisabled) {
                            this.addEvents();
                        }

                        if (
                            this.context.state.points?.filter((point) => point.isComplete === true)
                                .length !== 0
                        ) {
                            this.setRoute({}).then(routeCallback, () => null);
                        }
                    }

                    resolve();
                },
            );
        });
    }

    mapInit(map) {
        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };

                    newState.map = map;

                    return newState;
                },
                () => {
                    if (this.context.state.map) {
                        this.fitToMap();

                        this.context.state.map.setCenter(this.context.state.mapInfo.center, 10, {
                            checkZoomRange: true,
                        });

                        if (!this.mapDisabled) {
                            this.addEvents();
                        }

                        if (
                            this.context.state.points?.filter((point) => point.isComplete === true)
                                .length !== 0
                        ) {
                            this.setRoute({});
                        }
                    }

                    resolve();
                },
            );
        });
    }

    handlerApiAvaliable(ymaps) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = { ...state };

                newState.ymaps = ymaps;

                return newState;
            }, resolve);
        });
    }

    init({ mapInfo, alwaysShowMap = true, ...props }) {
        const { points, isUpdate = false, isSetRoute, isComplete } = props;
        const resultPoints = (points &&
            points.map((point) => ({
                ...JSON.parse(JSON.stringify(this.point)),
                ...point,
                address: (isComplete && point.address) || point.isComplete ? point.address : '',
                isComplete: (isComplete && !!point.address) || point.isComplete,
                uniqKey: point.uniqKey || point._id,
                cargo: [
                    ...(point.cargo || []).map((cargoItem) => ({
                        ...cargoItem,
                        stateOfShow: 1,
                        items: (cargoItem.items || []).map((item) => ({ ...item, stateOfShow: 1 })),
                    })),
                ],
            }))) || [
            { ...JSON.parse(JSON.stringify(this.point)), uniqKey: '1' },
            { ...JSON.parse(JSON.stringify(this.point)), uniqKey: '2' },
        ];

        this.alwaysShowMap = alwaysShowMap;

        return new Promise((resolve) =>
            this.context.setState(
                (state) => {
                    const newState = { ...state };

                    newState.points = resultPoints;

                    if (!isUpdate) {
                        newState.ymaps = null;
                        newState.map = null;
                        newState.amountInItems = '';
                        newState.isInitOfMap = true;
                    }

                    newState.mapInfo = mapInfo;
                    newState.counterSort = 0;
                    newState.infoRoute = { ...newState.infoRoute, ...getInfoRoute(0, 0), mkad: 0 };

                    ['date', 'times'].forEach((prop) => {
                        if (props[prop]) {
                            newState[prop] = props[prop];
                        }
                    });

                    return newState;
                },
                () => {
                    if (isSetRoute) {
                        this.setAreaOfPoints().then(() => {
                            this.setRoute({}).then(resolve);
                        });
                    } else {
                        resolve();
                    }
                },
            ),
        );
    }
}
