import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPdf from '../../requests/getPdf';

import CorporationAct from '../../components/pdf/CorporationAct.jsx';
import OrdersReport from '../../components/pdf/OrdersReport.jsx';
import JoinsTemplate from '../../components/pdf/JoinsTemplate.jsx';
import Template from '../../components/pdf/Template.jsx';
import PayAct from '../../components/pdf/PayAct.jsx';
import PayReceipt from '../../components/pdf/PayReceipt.jsx';
import FnsReceipt from '../../components/pdf/FnsReceipt.jsx';
import UserProxy from '../../components/pdf/UserProxy.jsx';

class Pdf extends Template {
    constructor(props) {
        super(props);
        this.state = {};

        this.init = this.init.bind(this);
        this.getProps = this.getProps.bind(this);

        this.parent = React.createRef();
    }

    init() {
        super.init();
    }

    getProps() {
        const { pdf, pageCounter } = this.state;

        return {
            pdf,
            pageCounter,
            getPages: this.getPages,
            init: this.init,
            renderPagination: this.renderPagination,
            renderFoot: this.renderFoot,
            setReadyCallback: this.setReadyCallback,
        };
    }

    pdfs = {
        corporationReport: {
            paddingTop: 12,
            paddingBottom: 12,
            render() {
                return <CorporationAct {...this.getProps()} />;
            },
        },
        ordersReport: {
            paddingTop: 0,
            paddingBottom: 4.5,
            pagination: 'top',
            orientation: 'landscape',
            paginationFull: true,
            render() {
                return <OrdersReport {...this.getProps()} />;
            },
        },
        joinsTemplate: {
            paddingTop: 12,
            paddingBottom: 12,
            pagination: 'bottom',
            render() {
                return <JoinsTemplate {...this.getProps()} />;
            },
        },
        userProxy: {
            paddingTop: 12,
            paddingBottom: 12,
            pagination: 'bottom',
            render() {
                return <UserProxy {...this.getProps()} />;
            },
        },
        payAct: {
            paddingTop: 12,
            paddingBottom: 12,
            render() {
                return <PayAct {...this.getProps()} />;
            },
        },
        payReceipt: {
            paddingTop: 12,
            paddingBottom: 12,
            render() {
                return <PayReceipt {...this.getProps()} />;
            },
        },
        fnsReceipt: {
            paddingTop: 6,
            paddingBottom: 6,
            isImage: true,
            render() {
                return <FnsReceipt {...this.getProps()} />;
            },
        },
    };

    setPagesCounter() {
        const query = window.location.search.slice(1);
        const queryArr = query.split('&');
        let counter;
        let pageStep;

        queryArr.forEach((item) => {
            const itemArr = item.split('=');

            if (itemArr[0] === 'pages') {
                counter = +itemArr[1];
            }

            if (itemArr[0] === 'step') {
                pageStep = +itemArr[1];
            }
        });

        return new Promise((resolve) => {
            this.setState({ currentPagesCounter: counter, pageStep }, resolve);
        });
    }

    getPdf() {
        getPdf().then(
            (pdf) => {
                this.setState({ pdf });
            },
            () => null,
        );
    }

    checkReady() {
        if (this.props.windowIsReady && !this.windowIsReady) {
            this.windowIsReady = true;

            this.getPdf();
        }
    }

    componentDidMount() {
        this.setPagesCounter();

        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { pdf } = this.state;
        const resultPdf = pdf ? this.pdfs[pdf.key] : null;

        return (
            <div
                ref={this.parent}
                className={`pdf _${pdf?.key} _${resultPdf?.orientation || 'portrait'}`}
            >
                {resultPdf?.render?.call(this)}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(Pdf);

Pdf.propTypes = {
    windowIsReady: PropTypes.bool,
};
