import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Upload from '../uploadOrdersPopup/Upload.jsx';
import Progress from '../uploadOrdersPopup/Progress.jsx';

import Ymaps from '../../Ymaps.jsx';

import setNotification from '../../../functions/setNotification';
import getHeaders from '../../../functions/getHeaders';
import getUpdateFormData from '../../../functions/getUpdateFormData';
import downloadFile from '../../../functions/downloadFile';
import { geocode, getHouse, pointInArea } from '../../../functions/handlerYmap';

import { deleteCookie, getCookie, setCookie } from '../../../functions/handlerCookies';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';
import handlerPopup from '../../../functions/crm/handlerPopup';
import changePage from '../../../functions/changePage';
import getPageLink from '../../../functions/getPageLink';

class UploadOrdersPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'service',
            currentStep: 'upload',
        };

        this.setType = this.setType.bind(this);
        this.renderStep = this.renderStep.bind(this);
        this.updateList = this.updateList.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.checkFile = this.checkFile.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.uploadCorrectDoc = this.uploadCorrectDoc.bind(this);
        this.setStep = this.setStep.bind(this);
        this.setStart = this.setStart.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.updateOrders = this.updateOrders.bind(this);
    }

    getSteps() {
        const { currentStep } = this.state;

        return [{ key: currentStep }];
    }

    renderStep({ prop: key }) {
        const { type, file, loadingKey, errors = [], orderStack, ymaps, map, routes } = this.state;

        return (
            <div className="uploadOrdersPopup__step">
                {key === 'upload' && (
                    <Upload
                        uploadFile={this.uploadFile}
                        updateList={this.updateList}
                        setType={this.setType}
                        type={type}
                        file={file}
                        loadingKey={loadingKey}
                        errors={errors}
                        clearForm={this.clearForm}
                        uploadCorrectDoc={this.uploadCorrectDoc}
                        setStep={(setUploadStep) => {
                            this.setUploadStep = setUploadStep;
                        }}
                    />
                )}
                {key === 'progress' && (
                    <Progress
                        loadingKey={loadingKey}
                        orderStack={orderStack}
                        updateList={this.updateList}
                        setStart={this.setStart}
                        routes={routes}
                        ymaps={ymaps}
                        map={map}
                        type={type}
                        updateOrders={this.updateOrders}
                    />
                )}
            </div>
        );
    }

    updateList() {
        this.setState({ updateKey: new Date().getTime() });
    }

    setStart() {
        this.setState({ currentStep: 'upload' }, () => {
            this.clearForm();
        });

        this.setUploadStep('main', true);
    }

    updateOrders({ key, name, value }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const orderStack = JSON.parse(JSON.stringify(newState.orderStack));

                orderStack.orders[key][name] = value;

                newState.orderStack = orderStack;

                return newState;
            }, resolve);
        });
    }

    saveOrders() {
        const { routes, points, orders, resultOrders, type, file } = this.state;
        const ordersInfo = resultOrders.map((order) => {
            const route =
                routes?.find((innerRoute) => innerRoute.key === this.getRouteKey(order.points)) ||
                {};

            return {
                ...order,
                ...route,
                points: order.points.map((point) => {
                    const innerPoint = points.find(
                        (checkPoint) => checkPoint.address === point.address,
                    );

                    return {
                        ...point,
                        coords: innerPoint?.coords,
                        house: innerPoint?.house,
                        ttk: innerPoint?.ttk,
                        sk: innerPoint?.sk,
                    };
                }),
            };
        });

        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${process.env.REACT_APP_API}/order-stack`,
                    {
                        orders,
                        ordersInfo,
                        systemType: type,
                        fileName: file.name,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { hash, orderStack } = data;

                            setCookie('hashOrderStack', hash);

                            this.setState({ orderStack });

                            resolve();
                        } else {
                            reject();
                        }

                        this.handlerLoading(null);
                    },
                    () => null,
                );
        });

        // console.log(resultOrders);
    }

    setStep(currentStep) {
        const prevActions = () =>
            new Promise((resolve, reject) => {
                if (currentStep === 'progress') {
                    // if (0) {
                    this.saveOrders().then(resolve, reject);
                    // }
                } else {
                    resolve();
                }
            });

        return new Promise((resolve, reject) => {
            prevActions().then(() => {
                this.setState({ currentStep }, resolve);
            }, reject);
        });
    }

    setType(type) {
        this.setState({ type });
    }

    formData = new FormData();

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    uploadCorrectDoc() {
        const { type, loadingKey, file, errors } = this.state;

        if (!loadingKey) {
            const formData = new FormData();

            formData.set('errors', JSON.stringify(errors));
            this.formData.set('systemType', type);

            this.handlerLoading('download-correct').then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/download-order-stack-errors`,
                        getUpdateFormData(this.formData, formData),
                        { responseType: 'blob', headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            res.data.text().then(
                                (result) => {
                                    try {
                                        const { success } = JSON.parse(result);

                                        if (success === false) {
                                            console.log(result);
                                        }
                                    } catch (error) {
                                        downloadFile({ filename: file.name, data: res.data });
                                    }

                                    this.handlerLoading(null);
                                },
                                () => null,
                            );
                        },
                        () => null,
                    );
            });
        }
    }

    getRouteKey(points) {
        return points.map((point) => point.address).join('?');
    }

    checkAddresses({ orders }) {
        const { ymaps, map } = this.state;
        const points = [];
        const routes = [];

        return new Promise((resolve) => {
            orders.forEach((order) => {
                order.points.forEach((point) => {
                    if (!points.includes(point.address)) {
                        points.push(point.address);
                    }
                });

                const routeKey = this.getRouteKey(order.points);

                if (!routes.find((route) => route.key === routeKey)) {
                    routes.push({ key: routeKey, points: [] });
                }
            });

            Promise.all(
                points.map(
                    (address) =>
                        new Promise((pointResolve) => {
                            geocode(ymaps, address).then(
                                (result) => {
                                    if (!result) {
                                        pointResolve({ address, coords: null });
                                    } else {
                                        const coords = result.geometry._coordinates;
                                        const house = getHouse(result);

                                        pointResolve({
                                            address,
                                            coords,
                                            house,
                                            ...pointInArea(ymaps, map, coords),
                                        });
                                    }
                                },
                                () => {
                                    pointResolve({ address, coords: null });
                                },
                            );
                        }),
                ),
            ).then((resultPoints) => {
                const errors = {};

                orders.forEach((order, orderKey) => {
                    order.points.forEach((item, key) => {
                        if (
                            resultPoints.find(
                                (point) => point.address === item.address && !point.coords,
                            )
                        ) {
                            if (!errors[orderKey]) {
                                errors[orderKey] = [];
                            }

                            const errorName = `address-${key}`;

                            if (!errors[orderKey].includes(errorName)) {
                                errors[orderKey].push(errorName);
                            }
                        }
                    });
                });

                routes.forEach((route) => {
                    const { key } = route;
                    const keySplit = key.split('?');

                    keySplit.forEach((keyItem, keyIndex) => {
                        const point = resultPoints.find(
                            ({ address, coords }) => address === keyItem && coords,
                        );

                        route.points[keyIndex] = point?.coords;
                    });
                });

                // const checkRoutes = () =>
                //     new Promise((routesResolve) => {
                //         if (Object.keys(errors).length > 0 || type === 'service') {
                //             routesResolve();
                //         } else {
                //             Promise.all(
                //                 routes.map(
                //                     (route) =>
                //                         new Promise((routeResolve) => {
                //                             addRoute({
                //                                 ymaps,
                //                                 map,
                //                                 points: route.points,
                //                                 isNew: true,
                //                             }).then((infoRoute) => {
                //                                 routeResolve({
                //                                     key: route.key,
                //                                     distance: infoRoute.distance,
                //                                     duration: infoRoute.duration,
                //                                     inMoscow: infoRoute.inMoscow,
                //                                     mkad: infoRoute.mkad,
                //                                     mkadToPoints: infoRoute.mkadToPoints,
                //                                 });
                //                             }, routeResolve);
                //                         }),
                //                 ),
                //             ).then(routesResolve);
                //         }
                //     });

                // checkRoutes().then((resultRoutes) => {
                //     resolve({ routes: resultRoutes, points: resultPoints, errors });
                // });

                resolve({ routes, points: resultPoints, errors });
            });
        });
    }

    clearForm() {
        this.formData = new FormData();

        deleteCookie('hashOrderStack');

        this.setState({
            orders: undefined,
            errors: undefined,
        });
    }

    checkFile() {
        const { type } = this.state;

        return new Promise((resolve) => {
            this.handlerLoading('check').then(() => {
                this.formData.set('systemType', type);

                axios
                    .post(`${process.env.REACT_APP_API}/order-stack`, this.formData, {
                        headers: getHeaders(),
                    })
                    .then((res) => {
                        const { data } = res.data;
                        const { orders, resultOrders, errors = [], message } = data;

                        if (!message) {
                            this.checkAddresses({ orders: resultOrders }).then(
                                ({ routes, points, errors: pointsErrors }) => {
                                    const resultErrors = resultOrders
                                        .map((order, orderKey) => {
                                            const reqErrors =
                                                errors.find((item) => item.number === orderKey)
                                                    ?.errors || [];

                                            return {
                                                number: orderKey,
                                                errors: reqErrors.concat(
                                                    ...(pointsErrors[orderKey] || []),
                                                ),
                                            };
                                        })
                                        .filter((error) => error.errors.length > 0);

                                    const maxError = errors.find((error) => error.number === 'max');

                                    if (maxError) {
                                        resultErrors.push(maxError);
                                    }

                                    this.setState(
                                        {
                                            routes,
                                            points,
                                            orders,
                                            resultOrders,
                                            errors: resultErrors,
                                        },
                                        () => {
                                            if (resultErrors.length === 0) {
                                                this.setStep('progress');
                                            } else {
                                                setTimeout(() => {
                                                    this.handlerLoading(null);
                                                }, 300);
                                            }

                                            resolve();
                                        },
                                    );
                                },
                            );
                        } else if (message) {
                            if (message === 'Parsing error') {
                                setNotification({ notification: 'error-order-stack-parsing' });
                            }

                            if (message === 'Empty file') {
                                setNotification({ notification: 'error-order-stack-empty-file' });
                            }

                            setTimeout(() => {
                                this.setStart();

                                setTimeout(() => {
                                    this.handlerLoading(null);
                                }, 300);

                                resolve();
                            }, 300);
                        }
                    });
            });
        });
    }

    uploadFile({ target }) {
        const { files } = target;
        const [file] = files;
        const { type, name } = file;

        return new Promise((resolve, reject) => {
            target.value = null;

            if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setNotification({ notification: 'error-types-file' });

                reject();
            } else {
                this.formData.set('file', file);

                this.setState({ file: { name } }, () => {
                    this.checkFile().then(resolve, reject);
                });
            }
        });
    }

    getOrderStack(isStart = true) {
        const hashOrderStack = getCookie('hashOrderStack');

        if (!hashOrderStack) {
            this.setState({ isInit: true });
        } else {
            axios
                .get(`${process.env.REACT_APP_API}/order-stack?hash=${hashOrderStack}`, {
                    headers: getHeaders(),
                })
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        const { orderStack } = data;

                        this.setState({
                            orderStack,
                            ...(isStart ? { isInit: true, currentStep: 'progress' } : {}),
                        });
                    } else {
                        deleteCookie('hashOrderStack');

                        handlerPopup({ name: 'isUploadOrdersPopupShow', isShow: false });

                        changePage({
                            href: getPageLink({
                                name: 'orders',
                            }),
                        });

                        document.dispatchEvent(new CustomEvent('getOrders'));
                    }
                });
        }
    }

    timerRequestId;

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'order-stack') {
            const { orderStack } = data;

            this.setState({ orderStack });

            if (this.timerRequestId) {
                clearTimeout(this.timerRequestId);
            }

            if (orderStack.isComplete) {
                deleteCookie('hashOrderStack');
            } else {
                this.timerRequestId = setTimeout(() => {
                    this.getOrderStack(false);
                }, 3000);
            }
        }
    }

    componentDidMount() {
        this.getOrderStack();

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { updateKey, isInit } = this.state;

        return (
            <div className="uploadOrdersPopup _col">
                <div className="uploadOrdersPopup__inner">
                    <Animate className="uploadOrdersPopup__loader _loader" isShow={!isInit}>
                        <div className="uploadOrdersPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <Ymaps
                        forCalc={true}
                        callback={({ ymaps, map }) => this.setState({ ymaps, map })}
                    />
                    <ListAbsoluteMain
                        className="uploadOrdersPopup__steps"
                        items={this.getSteps()}
                        renderItem={this.renderStep}
                        classNameItem="uploadOrdersPopup__step"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        keyRender={updateKey}
                        isNotNullParentSize={true}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isUploadOrdersPopupShow: state.isUploadOrdersPopupShow,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopup);

UploadOrdersPopup.propTypes = {
    isUploadOrdersPopupShow: PropTypes.bool,
};
