import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import setPdf from '../../../requests/setPdf';

import WindowPrompt from '../../WindowPrompt.jsx';

import deletePaysItems from '../../../requests/deletePaysItems';
import handlerPopup from '../../../functions/app/handlerPopup';
import WindowActions from '../../WindowActions.jsx';
import handlerWindow from '../../../functions/handlerWindow';
import getHeaders from '../../../functions/getHeaders';
import download from '../../../functions/download';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderWindowsPromptPays = this.renderWindowsPromptPays.bind(this);
        this.renderWindowsEditActions = this.renderWindowsEditActions.bind(this);

        this.parent = React.createRef();
    }

    renderWindowsEditActions({ items, groupId, pay, updatePay }) {
        const callback = ({ key }) =>
            new Promise((resolve, reject) => {
                if (key === 'edit') {
                    handlerPopup({
                        name: 'payPopup',
                        isShow: true,
                        groupId,
                        pay,
                        isEdit: true,
                    });

                    resolve();
                } else if (key === 'repeat') {
                    handlerPopup({
                        name: 'payPopup',
                        isShow: true,
                        groupId,
                        pay,
                    });

                    resolve();
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'pay',
                        modelId: pay._id,
                    });

                    resolve();
                } else if (key === 'checkError') {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/pays`,
                            {
                                action: 'check-error',
                                id: pay._id,
                            },
                            { headers: getHeaders() },
                        )
                        .then((res) => {
                            resolve();

                            const { success, data } = res.data;

                            if (success) {
                                const { pay: updatedPay } = data;

                                updatePay({ pay: updatedPay });
                            }
                        });
                } else if (key === 'download') {
                    setPdf({
                        id: `payAct-${new Date().getTime()}`,
                        key: 'payAct',
                        name: `Договор-счёт-акт.pdf`,
                        otherData: {
                            payId: pay._id,
                            isNonResident: true,
                        },
                        inMoment: true,
                    }).then(({ fileName }) => {
                        if (fileName) {
                            download({
                                files: [
                                    {
                                        src: fileName,
                                        folder: 'corporations-docs',
                                        name: 'Договор-счёт-акт.pdf',
                                        isDelete: true,
                                    },
                                ],
                            });
                        }

                        resolve();
                    }, reject);
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'userActions',
                    });
                }}
            />
        );
    }

    renderWindowsPromptPays({ payId, items, successDelete }) {
        const deleteItems = ({ hide, handlerLoader }) => {
            deletePaysItems({ id: payId, items }).then(
                () => {
                    if (successDelete) {
                        successDelete();
                    }

                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return <WindowPrompt className="_topCenter" callback={deleteItems} name="delete" />;
    }
}

export default Main;

Main.propTypes = {
    setHeightPage: PropTypes.func,
};
