import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getEndText from '../../functions/getEndText';

import Icon from '../Icon.jsx';
import ChatFix from '../ChatFix.jsx';
import Animate from '../Animate.jsx';

import CounterNotRead from '../chat/CounterNotRead.jsx';
import AnimateChange from '../AnimateChange.jsx';
import getEnvParams from '../../functions/getEnvParams';

class DetailsChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowChat: false,
        };

        this.setCounter = this.setCounter.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    setCounter(counter) {
        this.setState({ counter });
    }

    renderStatus() {
        const { counter } = this.state;

        return !counter
            ? 'Нет новых сообщений'
            : `${counter} ${getEndText(counter, ['Новое', 'Новых', 'Новых'])} ${getEndText(
                  counter,
                  ['сообщение', 'сообщения', 'сообщений'],
              )}<span>*</span>`;
    }

    handlerChat(isShowChat = !this.state.isShowChat) {
        const { editmode } = this.props;

        return new Promise((resolve) => {
            editmode.handlerEdit({ editCurrent: isShowChat ? 'chat' : null }).then(() => {
                this.setState({ isShowChat }, resolve);
            });
        });
    }

    handlerMissClick({ target }) {
        const { isShowChat } = this.state;
        const window = this.parent.current.querySelector('.orderDetailsCard__chatWindow');

        if (isShowChat && target !== window && !window.contains(target)) {
            this.handlerChat(false);
        }
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handlerMissClick);
    }

    render() {
        const { isShowChat } = this.state;
        const { user, order } = this.props;

        return (
            <div ref={this.parent} className={`orderDetailsCard _chat`}>
                <div className="orderDetailsCard__inner">
                    <div className="orderDetailsCard__chat _col">
                        <Animate className="orderDetailsCard__chatWindow" isShow={isShowChat}>
                            <ChatFix
                                type="separate"
                                idOfOrder={order?._id}
                                idOfChat={order?.idOfChat}
                                getParent={() =>
                                    this.parent.current?.querySelector(
                                        '.orderDetailsCard__chatWindow',
                                    )
                                }
                            />
                        </Animate>

                        <div className="orderDetailsCard__chatHead">
                            <i className="orderDetailsCard__chatIcon">
                                <Icon name="message" />
                            </i>
                        </div>
                        <div className="orderDetailsCard__chatStatus">
                            <AnimateChange
                                className="orderDetailsCard__chatStatusBox"
                                prop={this.renderStatus()}
                                type="_translateMedium"
                            >
                                <div
                                    className="orderDetailsCard__chatStatusInner"
                                    dangerouslySetInnerHTML={{ __html: this.renderStatus() }}
                                ></div>
                            </AnimateChange>
                            {order?.idOfChat && (
                                <CounterNotRead
                                    id={order?.idOfChat}
                                    isHide={true}
                                    callback={this.setCounter}
                                />
                            )}
                        </div>
                        <div
                            className="orderDetailsCard__chatLink"
                            onMouseUp={(e) => e.stopPropagation()}
                            onClick={() => {
                                if (
                                    process.env.REACT_APP_SYSTEM === 'site' ||
                                    user?._id === order?.idOfResponsible
                                ) {
                                    this.handlerChat();
                                }
                            }}
                        >
                            <div className="linkBorder _click">
                                {getEnvParams().textLinkChatInOrderDetails}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(DetailsChat);

DetailsChat.propTypes = {
    user: PropTypes.object,
    order: PropTypes.object,
    editName: PropTypes.string,
    isDisabled: PropTypes.bool,
    handlerEditmode: PropTypes.func,
    editmode: PropTypes.object,
    fakeCallback: PropTypes.func,
};
