import createId from '../../requests/createId';

export default class Comments {
    constructor({ name, context }) {
        this.name = name;
        this.context = context;

        this.deleteComment = this.deleteComment.bind(this);
        this.addComment = this.addComment.bind(this);
        this.changeComment = this.changeComment.bind(this);
    }

    deleteComment({ id }) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = {...state };
                const target = JSON.parse(JSON.stringify(newState[this.name]));
                const indexComment = target.comments.findIndex((comment) => comment._id === id);

                if (indexComment !== -1) {
                    target.comments.splice(indexComment, 1);
                }

                newState[this.name] = target;

                return newState;
            }, resolve);
        });
    }

    addComment() {
        return new Promise((resolve) => {
            if (!this.context.state[this.name].comments.find((comment) => !comment.content)) {
                createId().then(
                    ({ id }) => {
                        this.context.setState((state) => {
                            const newState = {...state };
                            const target = JSON.parse(JSON.stringify(newState[this.name]));

                            target.comments.push({
                                _id: id,
                                content: '',
                            });

                            newState[this.name] = target;

                            return newState;
                        }, resolve);
                    },
                    () => null,
                );
            } else {
                resolve();
            }
        });
    }

    changeComment({ _id, props }) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = {...state };
                const target = JSON.parse(JSON.stringify(newState[this.name]));
                const indexComment = target.comments.findIndex((comment) => comment._id === _id);

                if (indexComment !== -1) {
                    Object.keys(props).forEach((keyProp) => {
                        target.comments[indexComment][keyProp] = props[keyProp];
                    });
                }

                newState[this.name] = target;

                return newState;
            }, resolve);
        });
    }
}