import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../../../classes/Editmode';

import scrollToPosition from '../../../../../functions/scrollToPosition';
import getHeaders from '../../../../../functions/getHeaders';
import setNotification from '../../../../../functions/setNotification';

import Main from '../../../../../components/crm/manual/organization/Main.jsx';
import Pay from '../../../../../components/crm/manual/organization/Pay.jsx';
import AddressMail from '../../../../../components/crm/manual/organization/AddressMail.jsx';
import OrganizationInfo from '../../../../../components/crm/manual/organization/OrganizationInfo.jsx';
import Comments from '../../../../../components/crm/manual/organization/Comments.jsx';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import Edit from '../../../../../components/Edit.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Link from '../../../../../components/Link.jsx';
import Button from '../../../../../components/Button.jsx';
import changePage from '../../../../../functions/changePage';
import setAnimate from '../../../../../functions/setAnimate';
import handlerErrorRequest from '../../../../../functions/handlerErrorRequest';
import { dispatcher } from '../../../../../redux/redux';
import getPageLink from '../../../../../functions/getPageLink';

class ManualOrganizationsInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.getOrderCards = this.getOrderCards.bind(this);
        this.scrollToConfig = this.scrollToConfig.bind(this);
        this.createOrganization = this.createOrganization.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.getCompanyInfo = this.getCompanyInfo.bind(this);
    }

    name = 'organization';

    editmode = new Editmode({
        context: this,
    });

    orderCards = {
        physical: ['main', 'organizationInfo', 'comments'],
        juristic: ['main', 'pay', 'addressMail', 'comments'],
    };

    getOrderCards() {
        const { organization = {} } = this.props;
        const jurType = organization.jurType || 'physical';

        return this.orderCards[jurType];
    }

    cards = {
        main: {
            getChangedProps() {
                const { organization } = this.props;

                if (organization.jurType === 'physical') {
                    if (organization.type === 'executor') {
                        // return [].concat(...(organization.organization === 'SMZ' ? ['inn'] : []));
                        return ['inn'];
                    }

                    return ['secondName', 'firstName', 'thirdName', 'phone', 'type'];
                }

                if (organization.jurType === 'juristic') {
                    return ['shortName', 'website', 'inn', 'nds'];
                }

                return [];
            },
            getRequiredProps() {
                const { organization } = this.props;

                if (organization.jurType === 'physical') {
                    if (organization.type === 'executor') {
                        return [].concat(...(organization.organization === 'SMZ' ? ['inn'] : []));
                    }

                    return ['secondName', 'firstName', 'thirdName', 'phone'];
                }

                if (organization.jurType === 'juristic') {
                    return ['inn', 'type'];
                }

                return [];
            },
            render() {
                const { errors } = this.state;
                const { organization, isNew } = this.props;

                return (
                    <Main
                        organization={organization}
                        checkEditmode={() => this.checkEditmode('main')}
                        changeOrganization={({ ...props }) =>
                            this.changeOrganization({ ...props, block: 'main' })
                        }
                        errors={errors.main}
                        isNew={isNew}
                        getCompanyInfo={this.getCompanyInfo}
                    />
                );
            },
        },
        pay: {
            getChangedProps() {
                const { organization } = this.props;

                if (organization.jurType === 'juristic') {
                    return ['bic', 'bankAccount'];
                }

                return [];
            },
            getRequiredProps() {
                return ['bic'];
            },
            render() {
                const { errors } = this.state;
                const { organization, isNew } = this.props;

                return (
                    <Pay
                        organization={organization}
                        checkEditmode={() => this.checkEditmode('pay')}
                        changeOrganization={({ ...props }) =>
                            this.changeOrganization({ ...props, block: 'pay' })
                        }
                        isNew={isNew}
                        errors={errors.pay}
                        getCompanyInfo={this.getCompanyInfo}
                    />
                );
            },
        },
        addressMail: {
            getChangedProps() {
                return ['addressMail'];
            },
            render() {
                const { organization, isNew } = this.props;

                return (
                    <AddressMail
                        organization={organization}
                        checkEditmode={() => this.checkEditmode('addressMail')}
                        isNew={isNew}
                        changeOrganization={({ ...props }) =>
                            this.changeOrganization({ ...props, block: 'addressMail' })
                        }
                    />
                );
            },
        },
        organizationInfo: {
            getChangedProps() {
                return ['bic', 'bankAccount', 'addressatName', 'bankName'];
            },
            getRequiredProps() {
                return ['bic', 'bankAccount'];
            },
            render() {
                const { errors } = this.state;
                const { organization, isNew } = this.props;

                return (
                    <OrganizationInfo
                        isNew={isNew}
                        organization={organization}
                        checkEditmode={() => this.checkEditmode('organizationInfo')}
                        changeOrganization={({ ...props }) =>
                            this.changeOrganization({ ...props, block: 'organizationInfo' })
                        }
                        errors={errors.organizationInfo}
                        getCompanyInfo={this.getCompanyInfo}
                    />
                );
            },
        },
        comments: {
            getChangedProps() {
                return ['comments'];
            },
            render() {
                const { organization, addComment, changeComment, deleteComment } = this.props;

                return (
                    <Comments
                        organization={organization}
                        checkEditmode={() => this.checkEditmode('comments')}
                        addComment={addComment}
                        changeComment={changeComment}
                        deleteComment={deleteComment}
                    />
                );
            },
        },
    };

    scrollToConfig(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.manualTariffsConfigurations__card[data-_id="${id}"]`,
            });
        }
    }

    changeOrganization({ block, ...props }) {
        const { changeOrganization } = this.props;

        return new Promise((resolve) => {
            changeOrganization({ ...props, block }).then(() => {
                if (props.action === 'change' || !props.action) {
                    this.handlerErrors({ action: 'delete', error: props.name, block });
                }

                resolve();
            });
        });
    }

    updateOrganization({ info }) {
        const { organization, setOrganization } = this.props;
        const newOrganization = JSON.parse(JSON.stringify(organization));

        if (newOrganization.jurType === 'physical') {
            ['bankName'].forEach((key) => {
                if (info[key]) {
                    if (key === 'corrAccount') {
                        newOrganization.bankAccount = info[key];
                    } else {
                        newOrganization[key] = info[key];
                    }
                }
            });
        } else {
            ['kpp', 'address', 'fullName', 'shortName', 'bankName', 'corrAccount'].forEach(
                (key) => {
                    if (info[key]) {
                        newOrganization[key] = info[key];
                    }
                },
            );
        }

        setOrganization({ organization: { ...newOrganization }, withSave: false });
    }

    getCompanyInfo({ inn, bic }) {
        let query = '';

        if (inn) {
            query += `inn=${inn}&`;
        }

        if (bic) {
            query += `bic=${bic}&`;
        }

        query = query.slice(0, -1);

        return new Promise((resolve) => {
            axios
                .get(`${process.env.REACT_APP_API}/organization?${query}&checkDaData=true`, {
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { info } = data;

                            this.updateOrganization({ info });

                            resolve(info);
                        } else {
                            // const { errors, message } = data;

                            handlerErrorRequest(res);

                            resolve();
                        }
                    },
                    () => null,
                );
        });
    }

    checkChange(name) {
        const { organization, organizationSave } = this.props;
        const changedProps = this.cards[name].getChangedProps.call(this);
        let isChange = false;
        const fields = {};
        const target = organization;
        const targetSave = organizationSave;
        const targetFields = fields;

        const setFields = (prop) => {
            isChange = true;
            targetFields[prop] = target[prop];
        };

        changedProps.forEach((prop) => {
            if (prop === 'comments') {
                if (organization.comments.length !== organizationSave.comments.length) {
                    setFields(prop);
                }

                organization.comments.forEach((comment) => {
                    const commentSave = organizationSave.comments.find(
                        (commentInner) => commentInner._id === comment._id,
                    );
                    if (!commentSave) {
                        setFields(prop);
                    } else if (commentSave.content !== comment.content) {
                        setFields(prop);
                    }
                });

                organizationSave.comments.forEach((comment) => {
                    if (
                        !organization.comments.find(
                            (commentInner) => commentInner._id === comment._id,
                        )
                    ) {
                        setFields(prop);
                    }
                });
            } else if (target[prop] !== targetSave[prop]) {
                setFields(prop);
            }
        });

        return { isChange, fields };
    }

    handlerEditmode({ editName, isNotScroll = false }) {
        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    if (!isNotScroll) {
                        this.scrollToConfig(editName);
                    }

                    resolve();
                } else {
                    const { organization } = this.props;
                    const { jurType } = organization;
                    const { isChange, fields } = this.checkChange(this.state.editName);

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors: validateErrors } = this.validate({
                            props: this.cards[this.state.editName].getChangedProps.call(this),
                            nameProp: this.state.editName,
                        });
                        const resultErrors = validateErrors[this.state.editName];

                        if (!isSuccess) {
                            this.handlerErrors({
                                action: 'add',
                                block: this.state.editName,
                                errors: resultErrors,
                            });
                        } else {
                            this.handlerLoadingBlock(this.state.editName).then(() => {
                                axios
                                    .patch(
                                        `${process.env.REACT_APP_API}/organization`,
                                        {
                                            type: jurType,
                                            action: 'changeInfo',
                                            id: organization._id,
                                            fields,
                                        },
                                        { headers: getHeaders() },
                                    )
                                    .then(
                                        (res) => {
                                            const { success } = res.data;

                                            if (success) {
                                                setNotification({
                                                    notification: 'success-update-organization',
                                                });

                                                resolve();
                                            } else {
                                                const { data } = res.data;
                                                const { message, errors } = data;

                                                if (message === 'Company already register') {
                                                    this.handlerErrors({
                                                        action: 'add',
                                                        error: 'inn',
                                                        block: 'main',
                                                    });

                                                    setNotification({
                                                        notification:
                                                            'error-registration-already-company',
                                                    });
                                                } else if (message === 'Inn already use') {
                                                    this.handlerErrors({
                                                        action: 'add',
                                                        error: 'inn',
                                                        block: 'main',
                                                    });

                                                    setNotification({
                                                        notification: 'inn-already-use',
                                                    });
                                                } else if (errors && errors.length) {
                                                    errors.forEach((error) => {
                                                        if (error.message === 'Inn is incorrect') {
                                                            this.handlerErrors({
                                                                action: 'add',
                                                                error: 'inn',
                                                                block: 'main',
                                                            });

                                                            setNotification({
                                                                notification: 'inn-is-error',
                                                            });
                                                        }

                                                        if (error.message === 'Bic is incorrect') {
                                                            const keyBlock = Object.keys(
                                                                this.cards,
                                                            ).find(
                                                                (keyCard) =>
                                                                    this.cards[
                                                                        keyCard
                                                                    ].getChangedProps
                                                                        .call(this)
                                                                        .indexOf('bic') !== -1,
                                                            );

                                                            this.handlerErrors({
                                                                action: 'add',
                                                                error: 'bic',
                                                                block: keyBlock,
                                                            });

                                                            setNotification({
                                                                notification: 'bic-is-error',
                                                            });
                                                        }

                                                        if (error.key === 'phone') {
                                                            this.handlerErrors({
                                                                action: 'add',
                                                                error: 'phone',
                                                                block: 'main',
                                                            });

                                                            setNotification({
                                                                notification:
                                                                    'error-registration-already-phone',
                                                            });
                                                        }
                                                    });
                                                }

                                                handlerErrorRequest(res);
                                            }

                                            this.handlerLoadingBlock(null);
                                        },
                                        () => null,
                                    );
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    createOrganization() {
        const { organization, getOrganization, forCompanyExecutorId } = this.props;
        const { jurType } = organization;

        const { isSuccess, errors } = this.validate({});

        if (!isSuccess) {
            this.handlerErrors({ action: 'set', errors });
        } else {
            const fields = { ...organization };

            fields.jurType = jurType;

            this.handlerLoadingSave(true).then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/organization`,
                        {
                            fields,
                            executorId: forCompanyExecutorId,
                        },
                        { headers: getHeaders() },
                    )
                    .then((res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { id } = data;

                            changePage({
                                href: getPageLink({
                                    name: 'manual-organizations-inner',
                                    ids: { 3: id },
                                }),
                            });

                            setNotification({ notification: 'success-create-organization' });

                            const parentScroll = document.querySelector(
                                '.widget__page._manual-organizations-inner-main .widget__pageBox',
                            );
                            const { scrollTop: scrollParent } = parentScroll;

                            setAnimate({
                                draw: (progress) => {
                                    parentScroll.scrollTo({
                                        top: scrollParent - progress * scrollParent,
                                    });
                                },
                                duration: 300,
                            });

                            dispatcher({ type: 'forCompanyExecutorId', data: null });

                            getOrganization();
                        } else {
                            const { message } = data;

                            if (message === 'Inn already use') {
                                setNotification({
                                    notification: 'error-registration-already-company',
                                });

                                this.handlerErrors({
                                    action: 'add',
                                    error: 'inn',
                                    block: 'main',
                                });
                            }

                            if (data.errors) {
                                data.errors.forEach((error) => {
                                    const keyBlock = Object.keys(this.cards).find(
                                        (keyCard) =>
                                            this.cards[keyCard].getChangedProps
                                                .call(this)
                                                .indexOf(error.key) !== -1,
                                    );

                                    setNotification({
                                        notification: `${error.key}-is-error`,
                                    });

                                    this.handlerErrors({
                                        action: 'add',
                                        error: error.key,
                                        block: keyBlock,
                                    });
                                });
                            }

                            handlerErrorRequest(res);
                        }

                        this.handlerLoadingSave(false);
                    });
            });
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        backToSave();
    }

    render() {
        const { editName, loadingKey, isLoadingSave } = this.state;
        const { organization, isNew, setHeightPage, checkRights } = this.props;
        const { jurType } = organization;

        return (
            <div
                className={`manualContent _parentForEdits _organization ${editName ? '_edit' : ''}`}
            >
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div className="manualContent__cards _row">
                            {this.getOrderCards().map((name) => {
                                const card = this.cards[name];

                                return (
                                    <div
                                        className={`manualContent__card _parentForEdit _editBack ${
                                            editName === name ? '_current' : ''
                                        } _${name} _${jurType}`}
                                        key={name}
                                    >
                                        {!isNew && checkRights(name) && (
                                            <Edit
                                                name={name}
                                                className="manualContent__cardEdit"
                                                editName={editName}
                                                handlerEditmode={this.handlerEditmode}
                                                isLoader={loadingKey === name}
                                            />
                                        )}

                                        {card.render.call(this)}
                                    </div>
                                );
                            })}
                        </div>
                        <Animate
                            className="manualContent__actions"
                            isShow={isNew}
                            actionInit={setHeightPage}
                            actionPrevRemove={setHeightPage}
                        >
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="manual-organizations-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <div className="manualContent__actionsButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.createOrganization}
                                        showLoader={isLoadingSave}
                                    >
                                        Сохранить данные
                                    </Button>
                                </div>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        forCompanyExecutorId: state.forCompanyExecutorId,
    };
}

export default connect(mapStateToProps)(ManualOrganizationsInnerMain);

ManualOrganizationsInnerMain.propTypes = {
    serverData: PropTypes.object,
    organization: PropTypes.object,
    organizationSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    isInit: PropTypes.bool,
    isNew: PropTypes.bool,
    changeOrganization: PropTypes.func,
    addComment: PropTypes.func,
    changeComment: PropTypes.func,
    deleteComment: PropTypes.func,
    getOrganization: PropTypes.func,
    backToSave: PropTypes.func,
    checkRights: PropTypes.func,
    setOrganization: PropTypes.func,
    forCompanyExecutorId: PropTypes.string,
};
