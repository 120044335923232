import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ManualExecutors from './manual/ManualExecutors.jsx';
import ManualCars from './manual/ManualCars.jsx';
import ManualTariffs from './manual/ManualTariffs.jsx';
import ManualOrganizations from './manual/ManualOrganizations.jsx';

import Pages from '../../components/Pages.jsx';

import getRealParams from '../../functions/getRealParams.ts';
import ManualTags from './manual/ManualTags.jsx';
import ManualModelsOfCar from './manual/ManualModelsOfCar.jsx';

class Manual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'manual-executors': {
            render() {
                return <ManualExecutors setHeightPage={this.setHeightPage} />;
            },
        },
        'manual-cars': {
            render() {
                return <ManualCars setHeightPage={this.setHeightPage} />;
            },
        },
        'manual-tariffs': {
            render() {
                return <ManualTariffs setHeightPage={this.setHeightPage} />;
            },
        },
        'manual-organizations': {
            render() {
                return <ManualOrganizations setHeightPage={this.setHeightPage} />;
            },
        },
        'manual-tags': {
            render() {
                return <ManualTags setHeightPage={this.setHeightPage} />;
            },
        },
        'manual-modelsOfCar': {
            render() {
                return <ManualModelsOfCar setHeightPage={this.setHeightPage} />;
            },
        },
    };

    setHeightPage() {
        if (this.parent.current) {
            const page = this.parent.current;

            const { height: heightPage } = getRealParams({
                parent: page,
                elem: '.manual__page._current',
                width: page.offsetWidth,
            });

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
    }

    componentDidUpdate() {
        this.setHeightPage();
    }

    render() {
        const { heightPage = 0 } = this.state;

        return (
            <div ref={this.parent} className="manual">
                <div className="manual__inner" style={{ height: `${heightPage}px` }}>
                    <Pages
                        classNamePage="manual__page"
                        filter={(page) => page.parentName === 'manual' && page.level === 2}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Manual);

Manual.propTypes = {
    user: PropTypes.object,
};
