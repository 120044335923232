import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilterOrder from '../../../classes/Filter';

import Main from './organizations/Main.jsx';
import HeadInner from './organizations/HeadInner.jsx';
import Inner from './organizations/Inner.jsx';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import getQueryFilter from '../../../functions/getQueryFilter';
import setAnimate from '../../../functions/setAnimate';

class ManualOrganizations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);

        this.parent = React.createRef();
    }

    headPages = {
        'manual-organizations-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Контрагенты{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'manual-organizations-inner': {
            render() {
                const { infoHead } = this.state;
                const { organization = {} } = infoHead;
                const { id, isNew } = organization;

                return (
                    <div className="widget__headNameItemContent">
                        Контрагент
                        <AnimateChange
                            prop={id}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {id ? `ID${id}` : isNew ? ' новый' : '–'}
                        </AnimateChange>
                    </div>
                );
            },
        },
    };

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfOrganization = levels[3];

        if (
            levels[1] === 'manual' &&
            levels[2] === 'organizations' &&
            idOfOrganization !== 'main'
        ) {
            this.isNew = ['physical', 'juristic'].indexOf(idOfOrganization) !== -1;
        }

        return this.isNew;
    }

    headActions = {
        'manual-organizations-main': {
            render() {
                const { filter, infoHead } = this.state;
                const { counter = 0 } = infoHead;
                const filterQuery = getQueryFilter({ filter });

                return (
                    <TableHead
                        name="organizations"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        counter={counter}
                        filterQuery={filterQuery}
                    />
                );
            },
        },
        'manual-organizations-inner': {
            render() {
                const { infoHead } = this.state;

                const { organization } = infoHead;

                return (
                    <HeadInner
                        _id={organization?._id}
                        organization={organization}
                        isNew={this.checkNew()}
                    />
                );
            },
        },
    };

    pages = {
        'manual-organizations-main': {
            render() {
                const { filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-organizations-inner': {
            render() {
                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner _notPadding">
                            <Inner
                                setHeightPage={this.setHeightPage}
                                setInfoHead={this.setInfoHead}
                                getParent={() => this.parent.current}
                                checkNew={this.checkNew}
                                scrollToTop={this.scrollToTop}
                            />
                        </div>
                    </div>
                );
            },
        },
    };

    scrollToTop() {
        const pageCurrent = this.parent.current.querySelector('.widget__page._show');
        const pageBoxCurrent = pageCurrent.querySelector('.widget__pageBox._scroll');
        const { scrollTop } = pageBoxCurrent;

        if (pageBoxCurrent) {
            setAnimate({
                draw: (progress) => {
                    pageBoxCurrent.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'organizations' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderWindowCancelEdo({ cancelEdo }) {
        return (
            <WindowPrompt
                className="_bottomCenter"
                name="cancelEdo"
                callback={({ hide, valueArea }) => {
                    cancelEdo({ reason: valueArea }).then(hide, () => null);
                }}
            />
        );
    }

    renderWindowsContentUserAdd({ addUser }) {
        const callback = ({ hide }) =>
            new Promise((resolve) => {
                addUser().then(
                    () => {
                        hide();
                        resolve();
                    },
                    () => null,
                );
            });

        return <WindowPrompt className="_topCenter" callback={callback} name="userAdd" />;
    }

    renderWindowsContentUserDelete({ deleteUser }) {
        const callback = ({ hide }) =>
            new Promise((resolve) => {
                deleteUser().then(
                    () => {
                        hide();
                        resolve();
                    },
                    () => null,
                );
            });

        return <WindowPrompt className="_topCenter" callback={callback} name="delete" />;
    }

    renderWindowsContentBankCardDelete({ deleteCard }) {
        const callback = ({ hide, handlerLoader }) =>
            new Promise((resolve) => {
                deleteCard().then(
                    () => {
                        hide();
                        resolve();
                    },
                    () => handlerLoader(false),
                );
            });

        return <WindowPrompt className="_topCenter" callback={callback} name="delete" />;
    }

    renderWindowsPromptDeleteDoc({ deleteDoc }) {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteDoc"
                callback={({ hide, handlerLoader }) => {
                    deleteDoc();
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: 'filter-tariffs', filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="cancelEdo" renderContent={this.renderWindowCancelEdo} />
                <Windows name="promptUserAdd" renderContent={this.renderWindowsContentUserAdd} />
                <Windows
                    name="promptUserDelete"
                    renderContent={this.renderWindowsContentUserDelete}
                />
                <Windows
                    name="promptBankCardDelete"
                    renderContent={this.renderWindowsContentBankCardDelete}
                />
                <Windows name="promptDeleteDoc" renderContent={this.renderWindowsPromptDeleteDoc} />
                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Справочник"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-organizations' &&
                                        page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'manual-organizations' &&
                                        page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === 'manual-organizations' && page.level === 3
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(ManualOrganizations);

ManualOrganizations.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
};
