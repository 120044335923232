import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Icon from '../Icon.jsx';
import AnimateChange from '../AnimateChange.jsx';

import setServerData from '../../functions/setServerData';
import getFormatedDate from '../../functions/getFormatedDate';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';

class StatusPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        setServerData('pointStatuses');
    }

    render() {
        const { serverData, status, lastStatus, size, isActive, className = '' } = this.props;
        const { pointStatuses } = serverData;

        const content = pointStatuses && pointStatuses[status].content;

        return (
            <div
                className={`orderStatusPoint _${status} ${(size && `_${size}`) || ``} ${
                    checkValueOfEmpty(isActive) ? '_withActive _click' : ''
                } ${isActive ? '_active' : ''} ${className}`}
            >
                <AnimateChange
                    className="orderStatusPoint__inner"
                    type="_translateMedium"
                    prop={status}
                >
                    <div className={`orderStatusPoint__innerContent _row _${status}`}>
                        {size !== 'medium' && (
                            <i className="orderStatusPoint__icon">
                                <Icon name={`pointStatus-${status}`} />
                            </i>
                        )}
                        {content}&nbsp;
                        {lastStatus &&
                            status !== 'wait' &&
                            `(${getFormatedDate({
                                date: new Date(lastStatus.date),
                                type: 'time',
                            })})`}
                    </div>
                </AnimateChange>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(StatusPoint);

StatusPoint.propTypes = {
    serverData: PropTypes.object,
    status: PropTypes.string,
    lastStatus: PropTypes.object,
    size: PropTypes.string,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};
