import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';

import getHeaders from '../../../functions/getHeaders';
import getFormatedDate from '../../../functions/getFormatedDate';
import handlerPopup from '../../../functions/crm/handlerPopup';
import setNotification from '../../../functions/setNotification';
import downloadFile from '../../../functions/downloadFile';

class CorporationPayPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
        };

        this.handlerInput = this.handlerInput.bind(this);
        this.handlerOutClick = this.handlerOutClick.bind(this);
        this.downloadDoc = this.downloadDoc.bind(this);

        this.parent = React.createRef();
    }

    handlerInput({ action, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState({ amount: value }, resolve);
            } else {
                resolve();
            }
        });
    }

    downloadDoc() {
        const { amount } = this.state;
        const { user } = this.props;
        const { idOfCurrentCorporation } = user;
        const handlerLoader = (isLoading) =>
            new Promise((resolve) => {
                this.setState({ isLoading }, resolve);
            });

        if (amount) {
            this.setState({ amount: +amount.toString() }, () => {
                if (+this.state.amount > 0) {
                    handlerLoader(true).then(() => {
                        axios
                            .post(
                                `${process.env.REACT_APP_API}/corporation-amount-doc`,
                                {
                                    id: idOfCurrentCorporation,
                                    amount: +amount,
                                },
                                {
                                    responseType: 'blob',
                                    headers: getHeaders(),
                                },
                            )
                            .then(
                                (res) => {
                                    res.data.text().then(
                                        (result) => {
                                            try {
                                                const { success } = JSON.parse(result);

                                                if (success === false) {
                                                    console.log(result);
                                                }
                                            } catch (error) {
                                                const filename = `Счет на оплату от ${getFormatedDate(
                                                    {
                                                        date: new Date(),
                                                    },
                                                )}.docx`;

                                                downloadFile({ filename, data: res.data });

                                                handlerPopup({
                                                    name: 'isCorporationPayPopupShow',
                                                    isShow: false,
                                                });

                                                setNotification({
                                                    notification: 'success-download-amount-doc',
                                                });
                                            }

                                            handlerLoader(false);
                                        },
                                        () => null,
                                    );
                                },
                                () => null,
                            );
                    });
                }
            });
        }
    }

    handlerOutClick({ target }) {
        const innerDom = this.parent.current?.querySelector('.corporationPayPopup__inner');

        if (innerDom !== target && !innerDom.contains(target)) {
            handlerPopup({ name: 'isCorporationPayPopupShow', isShow: false });
        }
    }

    componentDidMount() {
        setTimeout(() => {
            document.addEventListener('click', this.handlerOutClick);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerOutClick);
    }

    render() {
        const { amount, isLoading } = this.state;

        return (
            <div ref={this.parent} className="corporationPayPopup _col">
                <div className="corporationPayPopup__inner">
                    <div className="corporationPayPopup__head">
                        <div className="corporationPayPopup__title">
                            <b>Пополнить</b> баланс
                        </div>
                        <i
                            className="corporationPayPopup__close _click"
                            onClick={() => {
                                handlerPopup({ name: 'isCorporationPayPopupShow', isShow: false });
                            }}
                        >
                            <Icon name="close" />
                        </i>
                    </div>
                    <div className="corporationPayPopup__content">
                        <div className="corporationPayPopup__field">
                            <p className="corporationPayPopup__fieldSupport">
                                Введите сумму пополнения
                            </p>
                            <div className="corporationPayPopup__fieldBox">
                                <Field
                                    // type={fieldType}
                                    support="0₽"
                                    keyName="price"
                                    name="amount"
                                    value={amount}
                                    id="amount"
                                    // error={resultError}
                                    callback={this.handlerInput}
                                    className="_mediumSize _grey _fontMedium"
                                />
                            </div>
                            <p className="corporationPayPopup__fieldDescription">
                                <b>Важно:</b> пополнить можно только со счёта вашей организации,
                                иначе банк не сможет зачислить деньги
                            </p>
                        </div>
                        <div className="corporationPayPopup__button">
                            <Button
                                className="_crm _normalSize _mainNotBorder"
                                onClick={this.downloadDoc}
                                showLoader={isLoading}
                            >
                                Скачать счёт на оплату
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        user: state.user,
    };
}

export default connect(mapStateToProps)(CorporationPayPopup);

CorporationPayPopup.propTypes = {
    serverData: PropTypes.object,
    user: PropTypes.object,
};
