import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ImageLazy from '../ImageLazy.jsx';
import Icon from '../Icon.jsx';

import getFormatPrice from '../../functions/getFormatPrice';
import getEndText from '../../functions/getEndText';
import handlerSupportCursor from '../../functions/handlerSupportCursor';

class DetailsTariffsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }

    render() {
        const { tariff, isCurrent, changeTariff, isNds, scrollTopList } = this.props;
        const { parameters } = tariff;
        const { priceOfMinute, overThanMin } = parameters;
        let { minPrice } = parameters;
        const minTime = Math.ceil(parameters.minTime / 60);
        const long = +(parameters.long / 100).toFixed(1);
        const width = +(parameters.width / 100).toFixed(1);
        const height = +(parameters.height / 100).toFixed(1);
        let extraPrice = +(priceOfMinute * (60 / overThanMin)).toFixed(0);

        if (isNds) {
            extraPrice = +extraPrice * 1.2;
            minPrice = +minPrice * 1.2;
        }

        return (
            <div
                className={`orderDetailsTariffsCard _col _click ${isCurrent ? '_current' : ''}`}
                onClick={() => {
                    // console.log(tariff);
                    changeTariff({
                        tariff,
                        id: tariff.idOfTariff,
                        idOfConfig: tariff.idOfConfig,
                        idOfItem: tariff.id,
                    }).then(() => {
                        scrollTopList();
                    });
                }}
            >
                <div className="orderDetailsTariffsCard__inner">
                    <div className="orderDetailsTariffsCard__action _col">
                        <i className="orderDetailsTariffsCard__actionIcon">
                            <Icon name="done" />
                        </i>
                    </div>
                    <div className="orderDetailsTariffsCard__head _row">
                        <div className={`orderDetailsTariffsCard__preview _col _${tariff.typeConfig}`}>
                            {tariff.icon && (
                                <ImageLazy
                                    src={`${process.env.REACT_APP_STATIC}/${tariff.icon}`}
                                    className="orderDetailsTariffsCard__previewImage"
                                />
                            )}
                        </div>
                        <div className="orderDetailsTariffsCard__headContent _col">
                            <div
                                className={`orderDetailsTariffsCard__name ${
                                    tariff.isPersonal ? '_isPersonal' : ''
                                }`}
                            >
                                {tariff.isPersonal && (
                                    <div
                                        className="orderDetailsTariffsCard__namePersonal _col"
                                        onMouseEnter={(e) => {
                                            handlerSupportCursor({
                                                action: 'enter',
                                                content: 'Персональный тариф',
                                                e,
                                                data: { className: '_normalCase _center' },
                                            });
                                        }}
                                        onMouseLeave={(e) => {
                                            handlerSupportCursor({ action: 'leave', e });
                                        }}
                                    >
                                        <div className="personalTariff _col">
                                            <i className="personalTariff__icon">
                                                <Icon name="personal-tariff" />
                                            </i>
                                        </div>
                                    </div>
                                )}
                                {tariff.name}
                            </div>
                            <p className="orderDetailsTariffsCard__price">
                                {getFormatPrice(minPrice)}₽
                                {tariff.amount > 0 && (
                                    <>
                                        , за заказ: <span>{getFormatPrice(tariff.amount)}₽</span>
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="orderDetailsTariffsCard__content _col">
                        <div className="orderDetailsTariffsCard__params _row">
                            <div className="orderDetailsTariffsCard__param _col">
                                <p className="orderDetailsTariffsCard__paramSupport">Объём</p>
                                <div className="orderDetailsTariffsCard__paramValue">
                                    {+(long * width * height).toFixed(1)}м³
                                </div>
                            </div>
                            <div className="orderDetailsTariffsCard__param _col">
                                <p className="orderDetailsTariffsCard__paramSupport">Габариты</p>
                                <div className="orderDetailsTariffsCard__paramValue">
                                    {long}х{width}х{height}м
                                </div>
                            </div>
                            <div className="orderDetailsTariffsCard__param _col">
                                <p className="orderDetailsTariffsCard__paramSupport">Мин. время</p>
                                <div className="orderDetailsTariffsCard__paramValue">
                                    {minTime} {getEndText(minTime, ['час', 'часа', 'часов'])}
                                </div>
                            </div>
                            <div className="orderDetailsTariffsCard__param _col">
                                <p className="orderDetailsTariffsCard__paramSupport">Доп. час</p>
                                <div className="orderDetailsTariffsCard__paramValue">
                                    {getFormatPrice(extraPrice)}₽
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsTariffsCard);

DetailsTariffsCard.propTypes = {
    type: PropTypes.string,
    tariff: PropTypes.object,
    isCurrent: PropTypes.bool,
    changeTariff: PropTypes.func,
    isNds: PropTypes.bool,
    scrollTopList: PropTypes.func,
};
