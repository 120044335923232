import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './inner/Main.jsx';
import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import getJoinScripts from '../../../../requests/getJoinScripts';
import Logs from './inner/Logs.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class JoinsScriptsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getJoinScript = this.getJoinScript.bind(this);
        this.setJoinScript = this.setJoinScript.bind(this);
        this.changeJoinScript = this.changeJoinScript.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'joinScript';

    targetNameSave = 'joinScriptSave';

    pages = {
        'joins-scripts-inner-main': {
            render() {
                const { joinScript, joinScriptSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._joins-scripts-inner-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    joinScript={joinScript}
                                    joinScriptSave={joinScriptSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeJoinScript={this.changeJoinScript}
                                    setJoinScript={this.setJoinScript}
                                    isNew={checkNew()}
                                    getJoinScript={this.getJoinScript}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'joins-scripts-inner-logs': {
            render() {
                const { joinScript, isInit } = this.state;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    joinScript={joinScript}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeJoinScript({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const joinScript = JSON.parse(JSON.stringify(newState.joinScript));
                    const target = joinScript;

                    target[name] = value;

                    newState.joinScript = joinScript;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    setJoinScript({ joinScript, withSave = true }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.joinScript = joinScript;

                    if (withSave) {
                        newState.joinScriptSave = JSON.parse(JSON.stringify(joinScript));
                    }

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'joinScript', value: joinScript });

                    resolve();
                },
            );
        });
    }

    updateJoinScript({ fields }) {
        return new Promise((resolve) => {
            this.setJoinScript(
                {
                    joinScript: {
                        ...this.state.joinScript,
                        ...fields,
                    },
                },
                resolve,
            );
        });
    }

    getJoinScript() {
        const { levels } = this.props;
        const id = levels[3];

        return new Promise((resolve) => {
            getJoinScripts({ id }).then(
                ({ joinScript }) => {
                    this.setJoinScript({ joinScript }).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'joins',
            items: [
                {
                    key: 'scripts',
                    rules: ['update'],
                },
            ],
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getJoinScript();

        // document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        // document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'joinScript', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget  ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2 _full"
                                filter={(page) => page.parentName === 'joins-scripts-inner'}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinsScriptsInner);

JoinsScriptsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    checkRights: PropTypes.func,
};
