import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import UserPopup from './UserPopup.jsx';

class UserPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { userPopup } = this.props;

        return (
            <Animate className="body__userPopup" isShow={userPopup?.isShow}>
                <UserPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        userPopup: state.userPopup,
    };
}

export default connect(mapStateToProps)(UserPopupWrapper);

UserPopupWrapper.propTypes = {
    userPopup: PropTypes.object,
};
