import React from 'react';
import PropTypes from 'prop-types';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow from '../../../functions/handlerWindow';
import checkPaysErrors from '../../../functions/crm/checkPaysErrors';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import LikeExecutorWindow from '../../../components/crm/pays/groups/LikeExecutorWindow.jsx';
import Main from './groups/inner/Main.jsx';
import getPays from '../../../requests/getPays';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import PaysMainTemplate from '../../../components/crm/pays/Main.jsx';

class PaysMain extends PaysMainTemplate {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderPaysFilter = this.renderPaysFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderLikeExecutorWindow = this.renderLikeExecutorWindow.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.setPayGroup = this.setPayGroup.bind(this);

        this.parent = React.createRef();
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'paysGroups' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    handlerEditName({ name }) {
        this.setState((state) => {
            const newState = { ...state };

            newState.editName = name;

            return newState;
        });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            if (key === 'pay') {
                newState.editName = value?.name || '';
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderPaysFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderLikeExecutorWindow({ executor, itemId, payId }) {
        return <LikeExecutorWindow executor={executor} itemId={itemId} payId={payId} />;
    }

  

    getPay({ executorId }) {
        const params = [];

        if (executorId) {
            params.push({ key: 'executorId', value: executorId });
        }

        getPays({ id: 'main', params }).then(({ pay }) => {
            setTimeout(() => {
                this.setState({ pay, isInit: true });
            }, 100);
        });
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    setPayGroup({ payGroup }) {
        this.setState({ pay: payGroup });
    }

    updatePay({ fields }) {
        const { executorId } = this.props;

        this.setState((state) => {
            const newState = { ...state };

            if (newState.pay) {
                const pay = JSON.parse(JSON.stringify(newState.pay));
                const { deletedPays } = fields;

                if (deletedPays) {
                    const filteredDeletedPays = deletedPays.filter(
                        (item) => !executorId || item.executorId === executorId,
                    );

                    pay.counter -= filteredDeletedPays.length;
                    pay.errorCounter -= filteredDeletedPays.filter((item) =>
                        checkPaysErrors({ pay: item }),
                    ).length;
                    pay.amount -= filteredDeletedPays.reduce((prev, cur) => prev + cur.amount, 0);
                } else if (fields.item && (!executorId || fields.item.executorId === executorId)) {
                    const { item } = fields;

                    if (item.isNew) {
                        pay.counter += 1;
                        pay.amount += item.amount;
                    }

                    if (item.status === 'completed') {
                        pay.completedCounter += 1;

                        if (item.commission) {
                            pay.commission += item.commission;
                        }
                    }

                    if (checkPaysErrors({ pay: item }) && !item.isNotAddError) {
                        pay.errorCounter += 1;
                    }

                    if (item.isRemoveError) {
                        pay.errorCounter -= 1;
                    }
                }

                newState.pay = pay;
            }

            return newState;
        });
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'pays') {
            const { fields } = data;

            this.updatePay({ fields });
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();
        this.getPay({});

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
        });

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { pay, isInit } = this.state;
        const { counterChangePage, checkRights } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="paysFilter" renderContent={this.renderPaysFilter} />
                <Windows name="likeExecutor" renderContent={this.renderLikeExecutorWindow} />
                <Windows name="promptDeletePays" renderContent={this.renderWindowsPromptPays} />
                <Windows name="editActions" renderContent={this.renderWindowsEditActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Выплаты"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <div className="widget__headNameItemContent">Акты</div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="pays"
                                    getParent={() => this.parent.current}
                                    windowName="paysFilter"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__pageBox">
                        <div className="widget__pageInner">
                            <Main
                                isInit={isInit}
                                getParent={() => this.parent.current}
                                initCallbackFilter={this.initCallbackFilter}
                                pay={pay}
                                isMain={true}
                                checkRights={checkRights}
                                setPayGroup={this.setPayGroup}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaysMain;

PaysMain.propTypes = {
    setHeightPage: PropTypes.func,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    executorId: PropTypes.string,
};
