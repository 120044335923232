import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilter from '../../../classes/Filter';

import Icon from '../../../components/Icon.jsx';
import Link from '../../../components/Link.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';

import getPage from '../../../functions/getPage';
import Pages from '../../../components/Pages.jsx';
import Inner from './registry/Inner.jsx';

import WidgetFilter from '../../../components/crm/widget/Filter.jsx';
import Filter from '../../../components/Filter.jsx';

import Windows from '../../../components/Windows.jsx';
import handlerWindow from '../../../functions/handlerWindow';
import getFilter from '../../../requests/getFilter';
import getNotPopupPage from '../../../functions/getNotPopupPage';
import ColsList from '../../../components/crm/reports/ColsList.jsx';
import getCurrentPage from '../../../functions/getCurrentPage';
import Animate from '../../../components/Animate.jsx';
import stopPropagationClick from '../../../functions/stopPropagationClick';
import setPdf from '../../../requests/setPdf';
import getQueryFilter from '../../../functions/getQueryFilter';
import Loader from '../../../components/Loader.jsx';
import exportItems from '../../../requests/exportItems';
import setPermissions from '../../../functions/crm/setPermissions';

const pages = require('../../../redux/pages').default;

class Registry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counterOrders: 0,
            isColsListShow: false,
        };

        this.setCounterOrders = this.setCounterOrders.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.callbackFilter = this.callbackFilter.bind(this);
        this.setRequestFilterItems = this.setRequestFilterItems.bind(this);
        this.handlerCol = this.handlerCol.bind(this);
        this.handlerColsList = this.handlerColsList.bind(this);
        this.createPdf = this.createPdf.bind(this);
        this.handlerExport = this.handlerExport.bind(this);

        setPermissions.call(this);

        this.headPages = {};

        this.pages = {};

        const { keys, parentKey } = this.props;

        this.keys = keys;

        pages
            .filter((page) => page.level && page.parentName === parentKey)
            .forEach((page) => {
                const pageKey = this.keys[page.name];
                const counterKey = `counter-${pageKey}`;

                this.headPages[page.name] = {
                    render() {
                        return (
                            <>
                                {page.contentOfLink}
                                <AnimateChange
                                    className="reportsRegistry__headNameItemInner"
                                    prop={this.state[counterKey]}
                                    type="_translateMedium"
                                >
                                    <>({this.state[counterKey] || 0})</>
                                </AnimateChange>
                            </>
                        );
                    },
                };

                this.pages[page.name] = {
                    render() {
                        const { filter, activeOrder } = this.state;

                        return (
                            <Inner
                                name={pageKey}
                                setInfoHead={({ value }) => {
                                    this.setState({
                                        [counterKey]: value,
                                    });
                                }}
                                filter={filter}
                                setRequestFilterItems={this.setRequestFilterItems}
                                colsOrder={this.colsOrder}
                                cols={this.cols}
                                activeOrder={activeOrder}
                            />
                        );
                    },
                };
            });

        this.parent = React.createRef();
    }

    colsOrder = {
        all: [
            'tariffType',
            'bodywork',
            'numberOfOrder',
            'dateOfStart',
            'timeOfStart',
            'dateOfEnd',
            'timeOfEnd',
            'counterWork',
            'clientAndExecutor',
            'tariffAmount',
            'tariffExtraHour',
            'services',
            'mkad',
            'priceOfMkad',
            'changedPrice',
            'finalAmount',
            'amountDifference',
            'typeOfPay',
        ],
        clients: [
            'nameOfCompany',
            'tariffType',
            'bodywork',
            'numberOfOrder',
            'userName',
            'carMark',
            'carModel',
            'carNumber',
            'dateOfStart',
            'timeOfStart',
            'dateOfEnd',
            'timeOfEnd',
            'counterWork',
            'tariffAmount',
            'tariffExtraHour',
            'services',
            'mkad',
            'priceOfMkad',
            'changedPrice',
            'finalAmount',
            'typeOfPay',
        ],
        executors: [
            'nameOfOrganization',
            'tariffType',
            'bodywork',
            'numberOfOrder',
            'userName',
            'carMark',
            'carModel',
            'carNumber',
            'dateOfStart',
            'timeOfStart',
            'dateOfEnd',
            'timeOfEnd',
            'counterWork',
            'tariffAmount',
            'tariffExtraHour',
            'services',
            'mkad',
            'priceOfMkad',
            'changedPrice',
            'finalAmount',
            'typeOfPay',
        ],
        services: [
            'numberOfOrder',
            'tariffType',
            'userName',
            'serviceName',
            'carMark',
            'carModel',
            'carNumber',
            'dateOfStart',
            'timeOfStart',
            'dateOfEnd',
            'timeOfEnd',
            'commission',
            'serviceAmount',
            'finalAmount',
            'savedAmount',
        ],
    };

    cols = {
        nameOfCompany: 'Клиент',
        nameOfOrganization: 'Компания исполнителя',
        tariffType: 'Тип заказа',
        bodywork: 'Тип кузова',
        numberOfOrder: '№ заказа',
        userName: 'ФИО',
        carMark: 'Марка тс',
        carModel: 'Модель тс',
        carNumber: 'Гос.номер',
        dateOfStart: 'Дата подачи',
        timeOfStart: 'Время подачи',
        dateOfEnd: 'Дата конца',
        timeOfEnd: 'Время конца',
        counterWork: 'В работе',
        tariffAmount: 'Тариф (подача)',
        tariffExtraHour: 'Тариф (почас.)',
        services: 'Доп. услуги',
        mkad: 'Км за МКАД',
        priceOfMkad: '₽/км',
        changedPrice: 'Корректировка',
        finalAmount: 'Итого (сумма)',
        typeOfPay: 'Тип оплаты',
        address: 'Адрес',
        addresses: 'Адреса',
        clientAndExecutor: 'Клиент | Исполнитель',
        amountDifference: 'Разница',
        serviceName: 'Служба доставки',
        commission: 'Комиссия LIVECARGO',
        serviceAmount: 'Стоимость заказа',
        savedAmount: 'Ваша экономия',
    };

    exportActions = {
        excel: {
            content: 'Цифровой (.xls)',
            icon: 'file-excel',
        },
        pdf: {
            content: 'Печатный (.pdf)',
            icon: 'file-pdf',
        },
    };

    getExportActions() {
        const currentPage = this.getCurrentPage();
        const currentPageKey = this.keys[currentPage];

        if (currentPageKey === 'all') {
            return ['pdf'];
        }

        return Object.keys(this.exportActions);
    }

    setCounterOrders(counterOrders) {
        this.setState((state) => {
            const newState = { ...state };

            newState.counterOrders = counterOrders;

            return newState;
        });
    }

    callbackFilter({ filter, isChange }) {
        return new Promise((resolve) => {
            if (!isChange) {
                resolve();
            } else {
                this.setState({ filter }, () => {
                    this.getFilterItems();

                    resolve();
                });
            }
        });
    }

    setRequestFilterItems(getFilterItems) {
        this.getFilterItems = getFilterItems;
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filterReports',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    getFilter() {
        const { name } = this.props;

        getFilter({
            name: name === 'servicesRegistry' ? 'reportsServicesRegistry' : 'reportsRegistry',
        }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    getCurrentPageKey() {
        const currentPage = this.getCurrentPage();
        const key = this.keys[currentPage];

        if (key) {
            this.key = key;
        }

        return this.key;
    }

    handlerColsList(isColsListShow = !this.state.isColsListShow) {
        this.setState({ isColsListShow });
    }

    renderColsList() {
        const { activeOrder, isColsListShow } = this.state;
        const key = this.getCurrentPageKey();

        return (
            <Animate className="reportsRegistry__headColsList" isShow={isColsListShow}>
                <ColsList
                    order={this.colsOrder[key]}
                    otherOrder={['addresses']}
                    cols={this.cols}
                    activeOrder={activeOrder}
                    handler={this.handlerCol}
                    stateHandler={this.handlerColsList}
                />
            </Animate>
        );
    }

    getCurrentPage() {
        const { pagesStore, parentKey } = this.props;

        return getCurrentPage({
            pages: pagesStore,
            filter: (page) => page.parentName === parentKey,
        });
    }

    setOrderCols() {
        const currentPageKey = this.getCurrentPageKey();

        if (currentPageKey && currentPageKey !== this.currentPageKey) {
            this.currentPageKey = currentPageKey;
            const isStart = !this.state.activeOrder;

            this.setState(
                {
                    activeOrder: JSON.parse(JSON.stringify(this.colsOrder[currentPageKey])).concat(
                        ...['addresses'],
                    ),
                },
                () => {
                    if (!isStart) {
                        document.dispatchEvent(
                            new CustomEvent('changeRegistryCols', {
                                detail: { isChangePage: true, key: currentPageKey },
                            }),
                        );
                    }
                },
            );
        }
    }

    handlerCol(name) {
        this.setState(
            (state) => {
                const newState = { ...state };
                const activeOrder = JSON.parse(JSON.stringify(newState.activeOrder));
                const orderIndex = activeOrder.findIndex((item) => item === name);

                if (orderIndex === -1) {
                    activeOrder.push(name);
                } else {
                    activeOrder.splice(orderIndex, 1);
                }

                newState.activeOrder = activeOrder;

                return newState;
            },
            () => {
                document.dispatchEvent(
                    new CustomEvent('changeRegistryCols', {
                        detail: { key: this.getCurrentPageKey() },
                    }),
                );
            },
        );
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerExport(key) {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            if (key === 'pdf') {
                this.createPdf();
            }
            if (key === 'excel') {
                this.createExcel();
            }
        }
    }

    createPdf() {
        const { filter } = this.state;
        const currentPageKey = this.getCurrentPageKey();
        const query = getQueryFilter({ filter });

        this.handerLoading('pdf').then(() => {
            setPdf({
                id: `ordersReport-${currentPageKey}-${new Date().getTime()}`,
                key: 'ordersReport',
                pageName: currentPageKey,
                name: `Реестр №{number}.pdf`,
                otherData: { type: currentPageKey, query },
            }).then(
                () => {
                    this.handerLoading(null);
                },
                () => {
                    this.handerLoading(null);
                },
            );
        });
    }

    createExcel() {
        const { filter } = this.state;
        const currentPageKey = this.getCurrentPageKey();
        const query = getQueryFilter({ filter });

        if (currentPageKey !== 'all') {
            let strQuery = '';

            query.forEach(({ key, value }) => {
                strQuery += `${key}=${value}&`;
            });

            this.handerLoading('excel').then(() => {
                exportItems({
                    query: strQuery,
                    pageName: currentPageKey,
                    type: `ordersReport-${currentPageKey}`,
                    filename: 'Реестр',
                }).then(() => {
                    this.handerLoading(null);
                });
            });
        }
    }

    componentDidMount() {
        this.handlerFilter = new HandlerFilter({
            context: this,
            callback: () => null,
        });

        this.getFilter();

        this.setOrderCols();
    }

    componentDidUpdate() {
        this.setOrderCols();
    }

    render() {
        const { filter, loadingKey } = this.state;
        const { parentKey } = this.props;

        return (
            <div ref={this.parent} className="reportsRegistry">
                <Windows name="filterReports" renderContent={this.renderFilter} />
                <div className="reportsRegistry__head _row">
                    <div className="reportsRegistry__headItem _row">
                        <Link className="reportsRegistry__headBack _col _click" {...getNotPopupPage()}>
                            <i className="reportsRegistry__headBackIcon">
                                <Icon name="arrow-back" />
                            </i>
                        </Link>
                        <div className="reportsRegistry__headName _row">
                            Реестры:{' '}
                            <div className="reportsRegistry__headNameInner">
                                <Pages
                                    classNamePage="reportsRegistry__headNameItem _row"
                                    filter={(page) => page.parentName === parentKey}
                                    pages={this.headPages}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="reportsRegistry__headItem _row _actions">
                        {Object.keys(this.pages).length > 1 && (
                            <div className="widget__headActionsGroup">
                                <div className="reportsRegistry__headLinks _row">
                                    {Object.keys(this.pages).map((pageName) => {
                                        const page = getPage({ name: pageName });

                                        return (
                                            <Link
                                                className="reportsRegistry__headLink"
                                                key={pageName}
                                                pageName={pageName}
                                            >
                                                <div className="reportsRegistry__headLinkView _col">
                                                    {page.contentOfLink}
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        <div className="widget__headActionsGroup">
                            <WidgetFilter
                                name="registryReports"
                                windowName="filterReports"
                                className="_reportsRegistry _mediumHeight"
                                filter={filter}
                                handlerFilter={this.handlerFilter}
                                getParent={() => this.parent.current}
                                centers={{
                                    top: 1,
                                    left: 0.5,
                                }}
                            >
                                Фильтры
                            </WidgetFilter>
                            <div className="widget__headActionsElem widget__headAction _withChildren _mediumHeight">
                                <div
                                    className="widget__headActionInner _col _click"
                                    onClick={() => {
                                        this.handlerColsList();
                                    }}
                                    onMouseDown={(e) => {
                                        stopPropagationClick(e, 'registryColsList');
                                    }}
                                >
                                    Колонки
                                    <i className="widget__headActionIcon">
                                        <Icon name="cols" />
                                    </i>
                                </div>
                                {this.renderColsList()}
                            </div>
                            {this.getPermissions({
                                key: 'reports',
                                items: [
                                    {
                                        key:
                                            this.getCurrentPageKey() === 'services'
                                                ? 'service-orders'
                                                : 'system-orders',
                                        rules: [],
                                        actions: ['export'],
                                    },
                                ],
                            }) && (
                                <div className="widget__headActionsElem _withList widget__headLink widget__headAction _withChildren _main _mediumHeight">
                                    <div className="widget__headActionInner _col _click">
                                        Сформировать
                                        <i className="widget__headActionIcon">
                                            <Icon name="widget-export" />
                                        </i>
                                    </div>
                                    <div
                                        className={`widget__headLinkActions ${
                                            loadingKey ? '_active' : ''
                                        }`}
                                    >
                                        <div className="widget__headLinkActionsInner">
                                            {this.getExportActions().map((key) => (
                                                <div
                                                    className="widget__headLinkActionsItem _click"
                                                    key={key}
                                                    onClick={() => this.handlerExport(key)}
                                                >
                                                    <Animate
                                                        className="widget__headLinkActionsItemLoader _loader"
                                                        isShow={loadingKey === key}
                                                    >
                                                        <div className="widget__headLinkActionsItemLoaderItem _loaderItem">
                                                            <Loader className="_main" />
                                                        </div>
                                                    </Animate>
                                                    <div className="widget__headLinkActionsItemInner">
                                                        <div className="widget__headLinkActionsItemIcon _file">
                                                            <i className="widget__headLinkActionsItemIconInner">
                                                                <Icon
                                                                    name={
                                                                        this.exportActions[key].icon
                                                                    }
                                                                />
                                                            </i>
                                                        </div>
                                                        {this.exportActions[key].content}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="reportsRegistry__content">
                    <Pages
                        classNamePage="reportsRegistry__page"
                        filter={(page) => page.parentName === parentKey}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        pagesStore: state.pages,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Registry);

Registry.propTypes = {
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    pagesStore: PropTypes.object,
    parentKey: PropTypes.string,
    keys: PropTypes.object,
    name: PropTypes.string,
};
