import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from '../../../pays/groups/inner/Main.jsx';
import MainPays from '../../../pays/Main.jsx';

class PaysMain extends MainPays {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { executorId } = this.props;

        this.getPay({ executorId });

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { pay } = this.state;
        const { executorId, getParent, initCallbackPaysFilter } = this.props;

        return (
            <>
                <Main
                    isInit={!!pay}
                    getParent={getParent}
                    initCallbackFilter={initCallbackPaysFilter}
                    pay={pay}
                    isMain={true}
                    checkRights={() => false}
                    executorId={executorId}
                    setPayGroup={this.setPayGroup}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PaysMain);

PaysMain.propTypes = {
    executorId: PropTypes.string,
    getParent: PropTypes.func,
    initCallbackPaysFilter: PropTypes.func,
};
