import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import setNotification from '../functions/setNotification';

import downloadReq from '../functions/download';

export default function setPdf({ id, key, name, pageName, otherData, inMoment }) {
    return new Promise((resolve) => {
        if (id && key) {
            axios
                .post(
                    `${process.env.REACT_APP_API}/pdf`, { id, key, name, pageName, otherData, inMoment }, { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            if (!inMoment) {
                                setNotification({ notification: 'success-pdf-wait' });
                            }

                            resolve(data);
                        } else {
                            const { message } = data;

                            if (message === 'Pdf in process') {
                                setNotification({ notification: 'error-pdf-wait' });
                            }

                            if (message === 'Pdf is ready') {
                                const { fileName, notificationId, pdfId } = data;

                                downloadReq({
                                    files: [{
                                        src: fileName,
                                        folder: 'corporations-docs',
                                        notificationId,
                                        pdfId,
                                        isDelete: true,
                                    }, ],
                                }).then(
                                    () => null,
                                    () => null,
                                );
                            }
                        }
                    },
                    () => null,
                );
        }
    });
}