import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerRoute from '../../../../classes/order/Route';

import getUserInfo from '../../../../functions/getUserInfo';
import getEndText from '../../../../functions/getEndText';
import changePage from '../../../../functions/changePage';
import removeTransition from '../../../../functions/removeTransition.ts';
import setAnimate from '../../../../functions/setAnimate';

import WidgetFilter from '../../../../components/crm/widget/Filter.jsx';

import ExecutorInList from '../../../../components/crm/order-details/ExecutorInList.jsx';
import Animate from '../../../../components/Animate.jsx';
import AnimateChange from '../../../../components/AnimateChange.jsx';
import Button from '../../../../components/Button.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Ymaps from '../../../../components/Ymaps.jsx';
import getPageLink from '../../../../functions/getPageLink';

class OrderDetailsCrewMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterExecutor = this.filterExecutor.bind(this);
        this.getExecutors = this.getExecutors.bind(this);
        this.getExecutorsSort = this.getExecutorsSort.bind(this);
        this.renderExecutor = this.renderExecutor.bind(this);
        this.save = this.save.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.changeList = this.changeList.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);

        this.parent = React.createRef();
    }

    filterExecutor(executor) {
        const { search = '' } = this.state;

        return (
            !executor.isDelete &&
            (!search ||
                executor.isActive ||
                getUserInfo({ type: 'doubleName', user: executor })
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1)
        );
    }

    sort(a, b) {
        const weightA = a.isActive ? 1 : 0;
        const weightB = b.isActive ? 1 : 0;

        return weightB - weightA;
    }

    getInfo() {
        const { executors = [], order } = this.props;

        if (executors.length && executors.filter(this.filterExecutor).length === 0) {
            return {
                title: 'Исполнитель не найден',
                description: 'По вашему запросу исполнителей нет',
            };
        }

        if (order?.deliveryType === 'market') {
            return {
                title: `Откликнулось исполнителей: ${executors.length}`,
                description: 'Найденные исполнители<br/>будут появляться в этом окне',
            };
        }

        return {
            title: 'Это все исполнители',
            description: 'Вы можете добавить новых',
        };
    }

    getHeightEmpty() {
        const { heightItems = 0 } = this.state;

        if (this.parent.current) {
            const content = this.parent.current.querySelector('.orderDetailsCrewMap__listContent');

            return content.offsetHeight - heightItems;
        }

        return 0;
    }

    getCondForInfo() {
        return this.props.executors && this.getHeightEmpty() > 150;
    }

    getCondForActions() {
        const { executors = [] } = this.props;

        return executors.filter((executor) => executor.isActive && !executor.isDelete).length;
    }

    save() {
        const { order } = this.props;

        changePage({
            href: getPageLink({ name: 'order-details-crew-final', ids: { 2: order._id } }),
        });
    }

    getId({ _id, type }) {
        return `${_id}-${type}`;
    }

    getExecutors() {
        const { executors = [] } = this.props;

        return executors.map((executor) => ({ ...executor, idUpdate: this.getId(executor) }));
    }

    getExecutorsSort(executors) {
        return JSON.parse(JSON.stringify(executors)).sort(this.sort);
    }

    renderExecutor({ item, prop: id, key, isLast }) {
        const {
            handlerChoice,
            getCondForChange,
            addWorkerRoleForDriver,
            getCondForWorkerRoleForExecutor,
            order,
            setExecutorMark,
            executorsInfo,
        } = this.props;
        const executors = this.getExecutors();
        const executor = executors.find((executorLoop) => executorLoop.idUpdate === id) || item;
        const isDriverForWorkerRole = executors.find(
            (itemLoop) => itemLoop._id === executor._id && itemLoop.isDriver && !itemLoop.isDelete,
        );

        return (
            <div
                className={`orderDetailsCrewMap__listItem ${executor.isActive ? '_active' : ''} ${
                    this.getHeightEmpty() > 0 && isLast ? '_border' : ''
                }`}
                data-id={id}
                style={{
                    zIndex: executor.isActive ? 1000 : executors.length - key,
                }}
            >
                <div className="orderDetailsCrewMap__listItemInner">
                    <ExecutorInList
                        {...executor}
                        handlerChoice={handlerChoice}
                        getCondForChange={getCondForChange}
                        addWorkerRoleForDriver={addWorkerRoleForDriver}
                        isDriverForWorkerRole={isDriverForWorkerRole}
                        getCondForWorkerRoleForExecutor={getCondForWorkerRoleForExecutor}
                        order={order}
                        setExecutorMark={setExecutorMark}
                        mapInfo={executorsInfo?.[executor._id]}
                    />
                </div>
            </div>
        );
    }

    checkOrder() {
        const { order } = this.props;

        if (order && !this.isInit) {
            this.isInit = true;
        }
    }

    changeList({ isChangeOrder }) {
        if (isChangeOrder) {
            const scrollArea = this.parent.current.querySelector(
                '.orderDetailsCrewMap__listContent',
            );
            const { scrollTop } = scrollArea;

            setAnimate({
                draw: (progress) => {
                    scrollArea.scrollTo({
                        top: scrollTop - progress * scrollTop,
                    });
                },
                duration: 300,
            });
        }
    }

    setParamsParent(params = {}) {
        const { height: heightItems } = params;
        const executors = this.getExecutors();

        if (heightItems !== this.state.heightItems) {
            this.setState({ heightItems }, () => {
                if (executors.length > 0 && !this.isInit) {
                    this.isInit = true;
                    // removeTransition({ item: '.orderDetailsCrewMap__listItem', isCurrent: true });
                }
            });
        }
    }

    executors = [];

    checkChange() {
        const { executors = [] } = this.props;

        if (executors.length !== this.executors.length) {
            this.executors = executors;
        }

        if (
            executors.filter((executor) => executor.isActive).length !==
            this.executors.filter((executor) => executor.isActive).length
        ) {
            this.executors = executors;
        }

        if (
            executors
                .filter((executor) => executor.isActive)
                .find(
                    (executor) =>
                        !this.executors
                            .filter((executorSave) => executorSave.isActive)
                            .find((executorSave) => executorSave._id === executor._id),
                )
        ) {
            this.executors = executors;
        }

        if (this.props.executors && !this.isGetExecutors) {
            this.isGetExecutors = true;
            removeTransition({ item: '.orderDetailsCrewMap__listInfo' });
            removeTransition({ item: '.orderDetailsCrewMap__listItem', isCurrent: true });
        }
    }

    handlerRoute = new HandlerRoute({
        context: this,
        mapDisabled: true,
    });

    initMap() {
        const { order } = this.props;

        if (order && order.route && !this.isInitMap) {
            this.isInitMap = true;
            const { route } = order;

            this.handlerRoute.init({
                mapInfo: {
                    center: [55.755819, 37.617644],
                    zoom: 10,
                    controls: [],
                },
                type: 'details',
                points: route,
                isComplete: true,
            });
        }
    }

    handlerLoaderList(isScrollLoading) {
        return new Promise((resolve) => {
            this.setState({ isScrollLoading }, resolve);
        });
    }

    componentDidMount() {
        this.initMap();
        this.checkChange();
        this.checkOrder();
        this.setState({ isUpdate: true });
    }

    componentDidUpdate() {
        this.initMap();
        this.checkChange();
        this.checkOrder();
    }

    componentWillUnmount() {
        const { clearMarks } = this.props;

        clearMarks();
    }

    render() {
        const { isScrollLoading = false } = this.state;
        const {
            order,
            mapInfo,
            getMoreExecutors,
            stepCounter,
            isLimit,
            isDisabledScroll,
            isInit,
            setMap,
            handlerFilter,
            filter,
            getParent,
            isFilterLoading = false,
            updateKey,
            routeCallback,
        } = this.props;
        const executors = this.getExecutors();
        const executorsFilter = this.getExecutors().filter(this.filterExecutor);
        const info = this.getInfo();
        const { length: counterChoice } = executors.filter((executor) => executor.isActive);

        return (
            <div ref={this.parent} className="orderDetailsCrewMap">
                <div className="orderDetailsCrewMap__inner _row">
                    <div className="orderDetailsCrewMap__map">
                        {order && (
                            <Ymaps
                                {...mapInfo}
                                callback={({ ymaps, map }) => {
                                    this.handlerRoute
                                        .bootstrap({ ymaps, map, routeCallback })
                                        .then(() => {
                                            setMap({ ymaps, map });
                                        });
                                }}
                            />
                        )}
                    </div>
                    <div
                        className={`orderDetailsCrewMap__list ${
                            this.getCondForActions() ? '_withActions' : ''
                        }`}
                    >
                        <div className="orderDetailsCrewMap__listHead _row">
                            <h4 className="orderDetailsCrewMap__listTitle">
                                {order?.deliveryType === 'market'
                                    ? 'Поиск исполнителя…'
                                    : 'Исполнители'}
                            </h4>
                            <div className="orderDetailsCrewMap__listFilter">
                                <div className="widget__headAction">
                                    <WidgetFilter
                                        name="order"
                                        filter={filter}
                                        handlerFilter={handlerFilter}
                                        getParent={getParent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="orderDetailsCrewMap__listContent">
                            <Animate
                                className="orderDetailsCrewMap__listLoader _loader"
                                isShow={!this.props.executors || isFilterLoading}
                            >
                                <div className="orderDetailsCrewMap__listLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <div className="orderDetailsCrewMap__listContentInner" key={updateKey}>
                                <ListScroll
                                    getParent={() =>
                                        this.parent.current?.querySelector(
                                            '.orderDetailsCrewMap__listContentInner',
                                        )
                                    }
                                    callback={getMoreExecutors}
                                    startCounter={stepCounter}
                                    stepCounter={stepCounter}
                                    maxCounter={Infinity}
                                    lengthCurrent={executorsFilter.length}
                                    handlerLoaderList={this.handlerLoaderList}
                                    isLimit={isLimit}
                                    isDisabledScroll={
                                        isDisabledScroll || !isInit || isFilterLoading
                                    }
                                >
                                    <ListAbsoluteMain
                                        className={`orderDetailsCrewMap__listItems ${
                                            this.props.executors && !isFilterLoading ? '_show' : ''
                                        }`}
                                        items={executorsFilter}
                                        renderItem={this.renderExecutor}
                                        classNameItem="orderDetailsCrewMap__listItem"
                                        prop="idUpdate"
                                        paramsParent={{ width: true }}
                                        style={['height']}
                                        propsForUpdate={['isActive']}
                                        keyUpdate={`${executorsFilter.length}${counterChoice}`}
                                        sort={this.getExecutorsSort}
                                        callback={({ type, params, isChangeOrder }) => {
                                            this.changeList({ isChangeOrder });

                                            if (type === 'parent') {
                                                this.setState({ heightItems: params.height });
                                            }
                                        }}
                                        // name="test"
                                    />
                                </ListScroll>
                                <Animate
                                    className="orderDetailsCrewMap__listInfo"
                                    isShow={this.getCondForInfo()}
                                    style={{ height: `${this.getHeightEmpty()}px` }}
                                >
                                    <div className={`empty _col _block _notBack`}>
                                        <AnimateChange
                                            className="empty__inner"
                                            type="_translate _transFast"
                                            prop={info.title}
                                        >
                                            <div className="empty__innerItem">
                                                {order?.deliveryType === 'market' && (
                                                    <div className="empty__icon">
                                                        <Loader className="_main" />
                                                    </div>
                                                )}
                                                <div className="empty__title">{info.title}</div>
                                                <p
                                                    className="empty__content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: info.description,
                                                    }}
                                                ></p>
                                            </div>
                                        </AnimateChange>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                        <Animate
                            className="orderDetailsCrewMap__listActions _col"
                            isShow={this.getCondForActions()}
                        >
                            <div className="orderDetailsCrewMap__listButton">
                                <Button
                                    className="_mainNotBorder"
                                    icon={{ name: 'arrow-next', type: 'end' }}
                                    onClick={this.save}
                                >
                                    Продолжить
                                </Button>
                            </div>
                            <div className="orderDetailsCrewMap__listActionsInfo _row">
                                Выбрано:
                                <AnimateChange
                                    className="orderDetailsCrewMap__listActionsInfoCounter"
                                    type="_translateMedium"
                                    isDisabled={!this.getCondForActions()}
                                    prop={counterChoice}
                                >
                                    {`${counterChoice} ${getEndText(counterChoice, [
                                        'исполнитель',
                                        'исполнителя',
                                        'исполнителей',
                                    ])}`}
                                </AnimateChange>
                                из {order?.crewTemplate.length}
                            </div>
                        </Animate>
                        <Animate
                            className="orderDetailsCrewMap__listScrollLoader _loaderScroll"
                            isShow={isScrollLoading}
                        >
                            <div className="orderDetailsCrewMap__listScrollLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderDetailsCrewMap);

OrderDetailsCrewMap.propTypes = {
    executors: PropTypes.array,
    order: PropTypes.object,
    mapInfo: PropTypes.object,
    handlerChoice: PropTypes.func,
    getCondForChange: PropTypes.func,
    addWorkerRoleForDriver: PropTypes.func,
    getCondForWorkerRoleForExecutor: PropTypes.func,
    setExecutorMark: PropTypes.func,
    clearMarks: PropTypes.func,
    getMoreExecutors: PropTypes.func,
    isLimit: PropTypes.bool,
    isDisabledScroll: PropTypes.bool,
    stepCounter: PropTypes.number,
    isInit: PropTypes.bool,
    setMap: PropTypes.func,
    executorsInfo: PropTypes.object,
    handlerFilter: PropTypes.object,
    filter: PropTypes.object,
    getParent: PropTypes.func,
    isFilterLoading: PropTypes.bool,
    updateKey: PropTypes.any,
    routeCallback: PropTypes.func,
};
