import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';
// import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import WindowPrompt from '../../../components/WindowPrompt.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import PaysTaxMain from './tax/Main.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import WindowActions from '../../../components/WindowActions.jsx';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

class PaysTax extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderWindowsTaxPrompt = this.renderWindowsTaxPrompt.bind(this);

        this.parent = React.createRef();
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'taxes' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            if (key === 'pay') {
                newState.editName = value?.name || '';
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderWindowsTaxPrompt({ inactiveType, executorId, taxCallback }) {
        const deleteItems = ({ hide, handlerLoader }) => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/executor`,
                    { id: executorId, action: 'handler-tax', type: inactiveType || 'inactive' },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        hide();
                        taxCallback();
                    } else {
                        const { message } = data;

                        if (message === 'Executor has tax pay') {
                            setNotification({ notification: 'executor-has-tax-pay' });
                        } else if (message === 'Nothing for withdraw') {
                            setNotification({ notification: 'executor-tax-nothing-withdraw' });
                        } else if (message === 'Tax not withdraw') {
                            setNotification({ notification: 'executor-tax-not-withdraw' });
                        }
                    }

                    handlerLoader(false);
                });
        };

        return (
            <WindowPrompt
                className="_centerRight"
                callback={deleteItems}
                name={inactiveType ? 'withdrawTax' : 'removeTax'}
            />
        );
    }

    renderWindowsEditActions({ parent, parentScroll, target, items, executorId, taxCallback }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'executorTax',
                        modelId: executorId,
                    });

                    resolve();
                } else if (key === 'remove') {
                    handlerWindow({
                        parent,
                        parentResize: parent,
                        parentScroll,
                        target,
                        action: 'show',
                        name: 'taxPrompt',
                        className: '_prompt _tax',
                        uniqKey: `taxPrompt-${executorId}`,
                        executorId,
                        centers: {
                            left: 0,
                            top: 0.5,
                        },
                        isHideFromScroll: true,
                        isCheckScrollPosition: false,
                        taxCallback,
                    });
                } else if (key === 'withdraw') {
                    handlerWindow({
                        parent,
                        parentResize: parent,
                        parentScroll,
                        target,
                        action: 'show',
                        name: 'taxPrompt',
                        className: '_prompt _tax',
                        uniqKey: `withdrawPrompt-${executorId}`,
                        executorId,
                        centers: {
                            left: 0,
                            top: 0.5,
                        },
                        inactiveType: 'withdraw',
                        isHideFromScroll: true,
                        isCheckScrollPosition: false,
                        taxCallback,
                    });
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'taxActions',
                    });
                }}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
        });
    }

    render() {
        const { filter, infoHead } = this.state;
        const { counterChangePage } = this.props;
        const counter = infoHead?.counter || 0;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="taxActions" renderContent={this.renderWindowsEditActions} />
                <Windows name="taxPrompt" renderContent={this.renderWindowsTaxPrompt} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Выплаты"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <div className="widget__headNameItemContent">
                                    Налоговая копилка
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                        classNameParent="_parent"
                                    >
                                        <>({counter})</>
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="paysTax"
                                    getParent={() => this.parent.current}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__page _wide _show _full">
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <PaysTaxMain
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    setInfoHead={this.setInfoHead}
                                    getParent={() => this.parent.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaysTax;

PaysTax.propTypes = {
    setHeightPage: PropTypes.func,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    executorId: PropTypes.string,
};
