import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import Docs from '../../../../../components/crm/manual/Docs.jsx';
import Head from '../../../../../components/crm/manual/card/Head.jsx';
import Animate from '../../../../../components/Animate.jsx';
import Field from '../../../../../components/Field.jsx';
import Button from '../../../../../components/Button.jsx';
import Icon from '../../../../../components/Icon.jsx';
import getFormatedDate from '../../../../../functions/getFormatedDate';
import AnimateChangeUp from '../../../../../components/AnimateChangeUp.jsx';
import setSpacesInText from '../../../../../functions/setSpacesInText';
import Edit from '../../../../../components/Edit.jsx';
import Passport from '../../../../../components/crm/manual/executor/Passport.jsx';
import handlerLoading from '../../../../../functions/handlerLoading';
import getHeaders from '../../../../../functions/getHeaders';
import setNotification from '../../../../../functions/setNotification';
import Editmode from '../../../../../classes/Editmode';

const blocksManual = require('../../../../../infos/crm/blocksManual.json');

class ManualExecutorsInnerDocs extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerEditmode = this.handlerEditmode.bind(this);

        this.parent = React.createRef();
    }

    name = 'executor';

    orderCards = ['passport'];

    cards = {
        passport: {
            keyTarget: 'passport',
            changedProps: [
                'country',
                'series',
                'number',
                'view',
                'dateAction',
                'dateGet',
                'code',
                'birthday',
                'nameGet',
                'addressReg',
                'addressFact',
            ],
        },
    };

    async handlerVerificationRequest({ status, comment }) {
        const { executor } = this.props;

        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API}/executor`,
                { id: executor._id, action: 'handler-verification', status, comment },
                {
                    headers: getHeaders(),
                },
            );
            const { success, data } = response.data;

            if (success) {
                return data;
            }

            return null;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    handlerVerification(status) {
        const { rejectComment } = this.state;
        const { executor, updateExecutor } = this.props;

        handlerLoading.call(this, status).then(() => {
            this.handlerVerificationRequest({
                status,
                comment: rejectComment,
            }).then(
                (verificationData) => {
                    const verification = { ...executor.verification, ...verificationData };

                    if (status === 'active') {
                        verification.comment = null;
                    }

                    updateExecutor({ fields: { verification } });

                    handlerLoading.call(this, null, {
                        verificationRejectShow: false,
                        ...(status === 'active' ? { rejectComment: null } : {}),
                    });
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    checkChange(name) {
        const { executor, executorSave } = this.props;
        const changedProps = [
            'country',
            'series',
            'number',
            'view',
            'dateAction',
            'dateGet',
            'code',
            'birthday',
            'nameGet',
            'addressReg',
            'addressFact',
        ];
        let isChange = false;
        const fields = {};

        changedProps.forEach((prop) => {
            const target = executor.passport;
            const targetWas = executorSave.passport;

            if (target[prop] !== targetWas[prop]) {
                isChange = true;
                fields[prop] = target[prop];

                if (name === 'passport' && prop === 'addressFact') {
                    fields.coords = executor.coords;
                }
            }
        });

        return { isChange, fields };
    }

    changeRejectComment({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ rejectComment: value });
            }
        });
    }

    formData = new FormData();

    handlerEditmode({ editName }) {
        const { executor } = this.props;
        const id = executor._id;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else {
                    const { isChange, fields } = this.checkChange(this.state.editName);
                    const requiredProps = [];

                    const body = {
                        id,
                        prop: this.state.editName,
                        fields,
                    };

                    if (!isChange) {
                        resolve();
                    } else {
                        const { isSuccess, errors } = this.validate({
                            props: requiredProps,
                            nameProp: this.state.editName,
                        });

                        if (!isSuccess) {
                            this.handlerErrors({ action: 'set', errors });
                        } else {
                            this.handlerLoadingBlock(this.state.editName).then(() => {
                                axios
                                    .patch(`${process.env.REACT_APP_API}/executor`, body, {
                                        headers: getHeaders(),
                                    })
                                    .then(
                                        (res) => {
                                            const { success } = res.data;

                                            if (success) {
                                                setNotification({
                                                    notification: 'success-update-executor',
                                                });

                                                resolve();
                                            }

                                            this.handlerLoadingBlock(null);
                                        },
                                        () => null,
                                    );
                            });
                        }
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    setInnerEditmode({ editName }) {
        this.editmode.handlerEdit({ editName });
    }

    changeExecutor({ block, ...props }) {
        const { changeExecutor } = this.props;

        return new Promise((resolve) => {
            changeExecutor({ ...props }).then(() => {
                if (props.action === 'change') {
                    this.handlerErrors({ action: 'delete', error: props.name, block });
                }

                resolve();
            });
        });
    }

    docsScroll({ target }) {
        if (target.scrollLeft >= 6 && !this.state.isDocsScroll) {
            this.setState({ isDocsScroll: true });
        }

        if (target.scrollLeft < 6 && this.state.isDocsScroll) {
            this.setState({ isDocsScroll: false });
        }
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });

        this.editmode = new Editmode({
            context: this,
        });
    }

    render() {
        const {
            rejectComment = '',
            verificationRejectShow = false,
            editName,
            loadingKey,
            editLoadingKey,
            user,
            isDocsScroll,
        } = this.state;
        const { executor } = this.props;
        const status = executor.verification.status;
        const activeNotAdmin = status === 'active' && user?.idOfCurrentCorporation !== 'admin';

        const {
            isInit,
            executorSave = {},
            formDataDocs,
            changeDoc,
            uploadFile,
            deleteFile,
            filesDocsDelete,
            setHeightPage,
            addDoc,
            deleteDoc,
            getParent,
            handlerError,
            backToSaveDocs,
            clearFormData,
            checkRights,
        } = this.props;
        const { docs = [] } = executor;
        const { docs: docsSave = [] } = executorSave;
        let isDisabled;

        if (executor.verification.status === 'active' && user?.idOfCurrentCorporation !== 'admin') {
            isDisabled = true;
        }

        return (
            <>
                <div
                    ref={this.parent}
                    className={`settingsDocs _parentForEdits ${editName ? '_edit' : ''}`}
                >
                    <div className="settingsDocs__inner">
                        <div className="settingsDocs__cards _row">
                            <div className="settingsDocs__card _editBack _verification">
                                <div className="manualCard _hidden">
                                    {!activeNotAdmin && (
                                        <div className="manualCard__head _row">
                                            <Head title="Верификация" description=" " />
                                        </div>
                                    )}
                                    <div
                                        className={`manualCard__content ${
                                            activeNotAdmin ? '_fullHeight' : ''
                                        }`}
                                    >
                                        <div
                                            className={`settingsDocs__verification _col _${status} ${
                                                verificationRejectShow ? '_showReject' : ''
                                            }`}
                                        >
                                            <Animate
                                                className="settingsDocs__verificationReject _col"
                                                isShow={verificationRejectShow}
                                            >
                                                <div className="settingsDocs__verificationRejectArea">
                                                    <Field
                                                        value={rejectComment}
                                                        type="verification"
                                                        name="comments"
                                                        keyName="comments"
                                                        className="_grey"
                                                        callback={this.changeRejectComment.bind(
                                                            this,
                                                        )}
                                                    />
                                                </div>
                                                <div className="settingsDocs__verificationRejectButtons _row">
                                                    <div className="settingsDocs__verificationRejectButton">
                                                        <Button
                                                            className="_mediumSize"
                                                            onClick={this.handlerVerification.bind(
                                                                this,
                                                                'reject',
                                                            )}
                                                            showLoader={loadingKey === 'reject'}
                                                        >
                                                            Отправить
                                                        </Button>
                                                    </div>
                                                    <div
                                                        className="settingsDocs__verificationRejectButton _col _click _close"
                                                        onClick={() => {
                                                            this.setState({
                                                                verificationRejectShow: false,
                                                            });
                                                        }}
                                                    >
                                                        <i className="settingsDocs__verificationRejectButtonIcon">
                                                            <Icon name="close" />
                                                        </i>
                                                    </div>
                                                </div>
                                            </Animate>
                                            <div className="settingsDocs__verificationInner _col">
                                                {activeNotAdmin ? (
                                                    <>
                                                        <div className="settingsDocs__verificationResult _col">
                                                            <i className="settingsDocs__verificationIcon">
                                                                <Icon name="corporation-verify" />
                                                            </i>
                                                            <div className="settingsDocs__verificationStatus">
                                                                Верификация
                                                                <br />
                                                                пройдена
                                                            </div>
                                                            <div className="settingsDocs__verificationDate">
                                                                {getFormatedDate({
                                                                    date: new Date(
                                                                        executor.verification.date,
                                                                    ),
                                                                })}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="settingsDocs__verificationHead">
                                                            <i className="settingsDocs__verificationIcon">
                                                                <Icon name="corporation-verify" />
                                                            </i>
                                                            <div className="settingsDocs__verificationTitle">
                                                                Статус
                                                            </div>
                                                            <AnimateChangeUp
                                                                className="settingsDocs__verificationStatus"
                                                                renderKey={status}
                                                            >
                                                                {executor?.verification.statusText}
                                                            </AnimateChangeUp>
                                                        </div>
                                                        <div className="settingsDocs__verificationContent">
                                                            {user?.idOfCurrentCorporation ===
                                                            'admin' ? (
                                                                <>
                                                                    <Animate
                                                                        className="settingsDocs__verificationButton _set"
                                                                        isShow={status !== 'active'}
                                                                        onClick={this.handlerVerification.bind(
                                                                            this,
                                                                            'active',
                                                                        )}
                                                                    >
                                                                        <Button
                                                                            className="_mediumSize"
                                                                            showLoader={
                                                                                loadingKey ===
                                                                                'active'
                                                                            }
                                                                        >
                                                                            Верифицировать
                                                                        </Button>
                                                                    </Animate>
                                                                    <div className="settingsDocs__verificationButton">
                                                                        <Button
                                                                            className="_mediumSize _alertLight"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    verificationRejectShow:
                                                                                        true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            На доработку
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            ) : executor.verification.comment ? (
                                                                <div className="settingsDocs__verificationComment">
                                                                    <div
                                                                        className="settingsDocs__verificationCommentInner"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: setSpacesInText(
                                                                                executor
                                                                                    .verification
                                                                                    .comment,
                                                                            ),
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`settingsDocs__card _editBack _docs _scroll ${
                                    editName === 'docs' ? '_current' : ''
                                }`}
                            >
                                <div
                                    className={`settingsDocs__cardDocs ${
                                        isDocsScroll ? '_showScroll' : ''
                                    }`}
                                >
                                    <div
                                        className="settingsDocs__cardDocsScroll _col"
                                        onScroll={this.docsScroll.bind(this)}
                                    >
                                        <Docs
                                            type="executor"
                                            isInit={isInit}
                                            docs={docs}
                                            docsSave={docsSave}
                                            formDataDocs={formDataDocs}
                                            changeDoc={changeDoc}
                                            uploadFile={uploadFile}
                                            deleteFile={deleteFile}
                                            filesDocsDelete={filesDocsDelete}
                                            setHeightPage={setHeightPage}
                                            addDoc={addDoc}
                                            deleteDoc={deleteDoc}
                                            getParent={getParent}
                                            handlerError={handlerError}
                                            backToSaveDocs={backToSaveDocs}
                                            clearFormData={clearFormData}
                                            parentScroll={this.parent.current?.querySelector(
                                                '.settingsDocs__cardDocsScroll',
                                            )}
                                            checkRights={checkRights}
                                            setInnerEditmode={this.setInnerEditmode.bind(this)}
                                            isDisabled={isDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`settingsDocs__card _parentForEdit _editBack ${
                                    editName === 'passport' ? '_current' : ''
                                }`}
                            >
                                {!isDisabled && (
                                    <Edit
                                        name="passport"
                                        className="manualContent__cardEdit"
                                        editName={editName}
                                        handlerEditmode={this.handlerEditmode}
                                        isLoader={editLoadingKey === 'passport'}
                                    />
                                )}

                                <Passport
                                    title={blocksManual.executor.passport.title}
                                    executor={executor}
                                    changeExecutor={({ ...props }) =>
                                        this.changeExecutor({ ...props, block: 'passport' })
                                    }
                                    checkEditmode={() => this.checkEditmode('passport')}
                                    // errors={errors.passport}
                                    // isNew={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualExecutorsInnerDocs);

ManualExecutorsInnerDocs.propTypes = {
    isInit: PropTypes.bool,
    executor: PropTypes.object,
    executorSave: PropTypes.object,
    setHeightPage: PropTypes.func,
    changeDoc: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataDocs: PropTypes.object,
    filesDocsDelete: PropTypes.array,
    addDoc: PropTypes.func,
    deleteDoc: PropTypes.func,
    getParent: PropTypes.func,
    handlerError: PropTypes.func,
    backToSaveDocs: PropTypes.func,
    clearFormData: PropTypes.func,
    parentScroll: PropTypes.object,
    checkRights: PropTypes.func,
    user: PropTypes.object,
    updateExecutor: PropTypes.func,
};
