export default function getCargoInfo({ points }) {
    const items = points?.reduce(
        (prevPoint, curPoint) =>
            prevPoint.concat(
                ...curPoint.cargo.reduce(
                    (prevCargo, curCargo) => prevCargo.concat(...curCargo.items),
                    [],
                ),
            ),
        [],
    );

    const weight =
        +items
            ?.reduce((prevItem, curItem) => prevItem + +curItem.weight * +curItem.counter, 0)
            .toFixed(1) || 0;
    const volume =
        +items
            ?.reduce(
                (prevItem, curItem) =>
                    prevItem +
                    +(
                        (+curItem.long * +curItem.width * +curItem.height * +curItem.counter) /
                        1000000
                    ).toFixed(1),
                0,
            )
            .toFixed(1) || 0;

    return { weight, volume };
}
