import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';

class FeedbackCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    items = {
        clean: {
            icon: 'clean-car.png',
            content: (
                <>
                    <span>Чистый</span>
                    <br />
                    автомобиль
                </>
            ),
        },
        help: {
            icon: 'help-gruz.png',
            content: (
                <>
                    <span>Помощь</span>
                    <br />в выгрузке
                </>
            ),
        },
        best: {
            icon: 'best-driver.png',
            content: (
                <>
                    <span>Лучший</span>
                    <br />
                    исполнитель
                </>
            ),
        },
        angry: {
            icon: 'angry-driver.png',
            content: (
                <>
                    <span>Грубый</span>
                    <br />
                    водитель
                </>
            ),
        },
        damage: {
            icon: 'damage-gruz.png',
            content: (
                <>
                    <span>Груз</span>
                    <br />
                    повреждён
                </>
            ),
        },
        things: {
            icon: 'things-in-car.png',
            content: (
                <>
                    <span>
                        Посторонние
                        <br />
                        предметы
                    </span>{' '}
                    в кузове
                </>
            ),
        },
    };

    render() {
        const { name, isComment, isLoading, className = '' } = this.props;
        const info = this.items[name];

        return (
            <div
                className={`orderFeedbackCard _row _${name} ${isComment ? '_comment' : ''} ${className}`}
            >
                <Animate className="orderFeedbackCard__loader _loader" isShow={isLoading}>
                    <i className="orderFeedbackCard__loaderIcon _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                {!isComment && (
                    <img
                        src={require(`../../img/order-info/${info.icon}`)}
                        alt=""
                        className="orderFeedbackCard__icon"
                    />
                )}

                <div className="orderFeedbackCard__content">
                    {isComment ? (
                        <>
                            Оставить
                            <br />
                            <span>комментарий</span>
                        </>
                    ) : (
                        info.content
                    )}
                </div>
            </div>
        );
    }
}

export default FeedbackCard;

FeedbackCard.propTypes = {
    name: PropTypes.string,
    isComment: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
};
