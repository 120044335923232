import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import LogsPopup from './LogsPopup.jsx';

class LogsPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { logsPopup } = this.props;

        return (
            <Animate className="body__logsPopup" isShow={logsPopup?.isShow}>
                <LogsPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        logsPopup: state.logsPopup,
    };
}

export default connect(mapStateToProps)(LogsPopupWrapper);

LogsPopupWrapper.propTypes = {
    logsPopup: PropTypes.object,
};
