import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from '../../../../AnimateChange.jsx';
import ImageLazy from '../../../../ImageLazy.jsx';
import Field from '../../../../Field.jsx';
import getExecutorIcon from '../../../../../functions/getExecutorIcon';

class MainPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    orderFields = ['secondName', 'firstName', 'thirdName'];

    getSrcIcon() {
        const { executor } = this.props;

        if (executor) {
            let src = process.env.REACT_APP_STATIC;

            src += '/images/';

            src += getExecutorIcon({
                type: executor.type,
            });

            return src;
        }

        return '';
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });
    }

    render() {
        const { user } = this.state;
        const { executor = {}, handler, errors, isNew } = this.props;
        const src = this.getSrcIcon();
        const isError = errors.find(
            (error) => ['firstName', 'secondName', 'thirdName'].indexOf(error) !== -1,
        );
        let isDisabled =
            this.props.isDisabled || (!isNew && ['IP', 'SMZ'].includes(executor.organization));

        if (executor.verification.status === 'active' && user?.idOfCurrentCorporation !== 'admin') {
            isDisabled = true;
        }

        return (
            <div
                className={`manualCardMainPreview _row ${isError ? '_error' : ''} _executor ${
                    !isDisabled ? '_edit' : ''
                }`}
            >
                <div className="manualCardMainPreview__item _col _image">
                    <AnimateChange
                        className="manualCardMainPreview__image _col"
                        prop={this.props.executor?.type}
                        type="_translateMedium"
                        isNotParams={true}
                    >
                        {this.props.executor?.type ? (
                            <>
                                <ImageLazy src={src} className="manualCardMainPreview__imageItem" />
                            </>
                        ) : (
                            <>
                                <div className="manualCardMainPreview__imageSupport">
                                    Выберите категорию
                                </div>
                            </>
                        )}
                    </AnimateChange>
                </div>
                <div className="manualCardMainPreview__item _col _content">
                    {this.orderFields.map((name) => {
                        const { [name]: value } = executor;
                        let className = '';

                        if (name === 'secondName') {
                            className = '_bigSize _fontMedium';
                        }

                        if (name === 'firstName') {
                            className = '_fontMedium';
                        }

                        const resultDisabled =
                            isNew || !['IP', 'SMZ'].includes(executor.organization)
                                ? isDisabled
                                : true;

                        return (
                            <div className="manualCardMainPreview__field" key={name}>
                                <Field
                                    keyName={name}
                                    name={name}
                                    value={value}
                                    callback={handler}
                                    className={`_manualMain ${className} _whiteBack ${
                                        resultDisabled ? '_notBack' : ''
                                    }`}
                                    isDisabled={resultDisabled}
                                    isEditmode={true}
                                    error={errors.indexOf(name) !== -1}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(MainPreview);

MainPreview.propTypes = {
    executor: PropTypes.object,
    handler: PropTypes.func,
    isDisabled: PropTypes.bool,
    errors: PropTypes.array,
    isNew: PropTypes.bool,
    user: PropTypes.object,
};
