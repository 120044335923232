import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilter from '../../classes/Filter';

import Back from '../../components/crm/widget/Back.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';
import TableHead from '../../components/crm/manual/TableHead.jsx';
import TableDefault from '../../components/crm/manual/Table.jsx';
// import Main from './invites/Main.jsx';
import Windows from '../../components/Windows.jsx';
import Filter from '../../components/Filter.jsx';
import handlerWindow from '../../functions/handlerWindow';
import getFilter from '../../requests/getFilter';
import WindowActions from '../../components/WindowActions.jsx';

import getChatTemplates from '../../requests/getChatTemplates';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

import AnimateChange from '../../components/AnimateChange.jsx';
import TableInfo from '../../components/crm/manual/TableInfo.jsx';
import handlerPopup from '../../functions/app/handlerPopup';

class ChatTemplates extends TableDefault {
    constructor(props) {
        super(props);
        this.state = {};

        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderWindowsEditActions = this.renderWindowsEditActions.bind(this);
        this.getItems = this.getItems.bind(this);
        this.handlerMore = this.handlerMore.bind(this);

        this.parent = React.createRef();
    }

    name = 'chatTemplates';

    stepCounter = 15;

    getItems(isForce = false) {
        const query = this.getQueryForRequest();

        if (isForce === true) {
            query.params.find((item) => item.key === 'limit').value =
                this.state.items?.length || this.stepCounter;
            query.params.find((item) => item.key === 'skip').value = 0;
        }

        return new Promise((resolve) => {
            getChatTemplates(query).then(
                ({ chatTemplates, isLimit, counter }) => {
                    this.setItems(chatTemplates, isForce, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    getFilter() {
        getFilter({ name: 'chatTemplates' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter.bind(this);
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    renderWindowsEditActions({ items, template }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'chatTemplate',
                        modelId: template._id,
                    });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                    });
                }}
            />
        );
    }

    getParent() {
        return this.parent.current;
    }

    getParentForScroll() {
        return this.parent.current?.querySelector('.tableInfo__scroll');
    }

    getActionsItems() {
        const items = ['logs'];

        return items;
    }

    handlerMore(template, { target }) {
        const parent = this.getParent();
        const actionsItems = this.getActionsItems();

        const uniqKey = `chatTemplates-${template._id}`;

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: this.getParentForScroll(),
            target: target.closest('.tableInfo__more'),
            action: 'show',
            name: 'chatTemplates',
            className: '_actions _right',
            uniqKey,
            items: actionsItems,
            template,
            centers: {
                left: 1,
                top: 0,
            },
            isHideFromScroll: true,
            isCheckScrollPosition: false,
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.getFilter();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        document.addEventListener('updateChatTemplate', this.updateItems);
    }

    componentWillUnmount() {
        document.removeEventListener('updateChatTemplate', this.updateItems);
    }

    render() {
        const {
            filter,
            items = [],
            isLimit,
            isReady,
            isShowLoaderList,
            isLoadingFilter = false,
            counter = 0,
            isDisabledScroll,
            updatedItemKey,
        } = this.state;
        const { counterChangePage, checkRights } = this.props;

        return (
            <div ref={this.parent} className={`widget _fix _parent ${isReady ? '_ready' : ''}`}>
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="chatTemplates" renderContent={this.renderWindowsEditActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Чат"
                                className={`${counterChangePage > 0 ? '_withBack' : ''} _forceShow`}
                            >
                                <div className="widget__headNameItemContent">
                                    Шаблоны{' '}
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                    >
                                        <>({counter})</>
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="chat-templates"
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    getParent={() => this.parent.current}
                                    checkRights={checkRights}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Animate
                        className="widget__loader _loader"
                        isShow={!isReady || isLoadingFilter}
                    >
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        <div className="widget__page _deep1 _full _show _wide">
                            <div className="widget__pageBox">
                                <div className="widget__pageInner _notPadding">
                                    <TableInfo
                                        isReady={isReady}
                                        items={items}
                                        name="chatTemplates"
                                        getMoreItems={this.getMoreItems}
                                        isLimit={isLimit}
                                        stepCounter={this.stepCounter}
                                        isDisabledScroll={isDisabledScroll}
                                        isLoading={!isReady || isLoadingFilter}
                                        isWrap={true}
                                        isRowHeight={true}
                                        keyUpdateItem={updatedItemKey}
                                        otherData={{ start: this.start }}
                                        handlerMore={this.handlerMore}
                                        getActionsItems={this.getActionsItems}
                                    />
                                </div>
                            </div>
                            <Animate
                                className="widget__pageLoader _loaderScroll"
                                isShow={isShowLoaderList}
                            >
                                <div className="widget__pageLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(ChatTemplates);

ChatTemplates.propTypes = {
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
};
