import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import setNotification from '../../../../../functions/setNotification';
import AnimateChangeUp from '../../../../../components/AnimateChangeUp.jsx';
import Checkbox from '../../../../../components/Checkbox.jsx';

import ManualField from '../../../../../components/crm/manual/card/Field.jsx';
import Button from '../../../../../components/Button.jsx';
import Animate from '../../../../../components/Animate.jsx';
import ActionChange from '../../../../../components/ActionChange.jsx';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import getEndText from '../../../../../functions/getEndText';

import getHeaders from '../../../../../functions/getHeaders';
import changePage from '../../../../../functions/changePage';
import sortForAlphabet from '../../../../../functions/sortForAlphabet';
import Icon from '../../../../../components/Icon.jsx';
import Select from '../../../../../components/Select.jsx';
import getPageLink from '../../../../../functions/getPageLink';
import handlerPopup from '../../../../../functions/app/handlerPopup';

class JoinsScriptsInnerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeJoinScript = this.changeJoinScript.bind(this);
        this.renderDoc = this.renderDoc.bind(this);
        this.getSortedDocs = this.getSortedDocs.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.setTags = this.setTags.bind(this);
        this.renderTag = this.renderTag.bind(this);
        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    name = 'joinScript';

    countriesItems = [
        {
            key: 'all',
            content: 'Все',
        },
        {
            key: 'RF',
            content: 'Российское',
        },
        {
            key: 'EAES',
            content: 'Страны ЕАС',
        },
        {
            key: 'other',
            content: 'Другие гр-ва',
        },
    ];

    changeJoinScript({ ...props }) {
        const { changeJoinScript } = this.props;

        return new Promise((resolve) => {
            changeJoinScript({ ...props }).then(
                () => {
                    if (props.action === 'change' || !props.action) {
                        this.handlerErrors({ action: 'delete', errors: [props.name] });
                    }

                    resolve();
                },
                () => null,
            );
        });
    }

    handlerErrors({ action, errors }) {
        this.setState((state) => {
            const newState = { ...state };
            const resultErrors = JSON.parse(JSON.stringify(newState.errors || []));

            errors.forEach((error) => {
                const index = resultErrors.indexOf(error);

                if (action === 'delete') {
                    if (index !== -1) {
                        resultErrors.splice(index, 1);
                    }
                } else if (index === -1) {
                    resultErrors.push(error);
                }
            });

            newState.errors = resultErrors;

            return newState;
        });
    }

    fields = {
        name: {
            support: 'Введите название здесь',
        },
    };

    renderField({ name }) {
        const { errors = [] } = this.state;
        const { joinScript, isNew, checkRights } = this.props;
        const field = this.fields[name];
        let items;
        let isDisabled;

        if (name === 'templateId') {
            items = joinScript?.templates.map((item) => ({ key: item._id, content: item.name }));

            if (!isNew) {
                isDisabled = true;
            }
        }

        if (!isNew && !checkRights()) {
            isDisabled = true;
        }

        return (
            <div className={`joinsScripts__field _row _block _${name}`}>
                <ManualField
                    support={field?.support || '–'}
                    className="_heightSize"
                    value={joinScript?.[name]}
                    type="joinScripts"
                    name={name}
                    handler={this.changeJoinScript}
                    group="joinScripts"
                    isEditmode={false}
                    items={items || []}
                    isError={errors.includes(name)}
                    isDisabled={isDisabled}
                />
            </div>
        );
    }

    checkboxes = {
        isOnlyInvite: {
            content: 'Регистрация доступна только по приглашению',
            description:
                'Исполнители без приглашения не смогут пройти<br/>регистрацию по данному сценарию',
        },
    };

    contractSign = {
        all: {
            content: 'Обе стороны',
        },
        corporation: {
            content: 'Только корпорация',
        },
    };

    executorOrganization = {
        SMZ: {
            content: 'Самозанятый',
            description: 'Действующий или хочет стать самозанятым',
        },
        IP: {
            content: 'Индивидуальный предприниматель',
            description: 'Есть действующий статус индивидуального предпринимателя',
        },
        FL: {
            content: 'Физическое лицо (договор ГПХ)',
            description: 'Будет работать в качестве физ.лица по договору ГПХ',
        },
        'FL-A': {
            content: 'Физическое лицо (Агентский договор)',
            description: 'Будет работать в качестве физ.лица по агентскому договору',
        },
    };

    renderCheckbox({ info, name }) {
        const { errors = [] } = this.state;
        const { joinScript, isNew, checkRights } = this.props;
        const checkbox = info || this.checkboxes[name];
        const value = info ? info?.value : joinScript?.[name];
        const id = `${name}-${info?.name}`;
        const isError = errors.includes(name);
        let isDisabled;

        if (!isNew) {
            isDisabled = true;
        }

        if (!checkRights()) {
            isDisabled = true;
        }

        return (
            <div
                className={`joinsScripts__checkbox _${name} ${
                    checkbox.description ? '_withDescription' : ''
                } ${isError ? '_error' : ''}`}
            >
                <div className="joinsScripts__checkboxField">
                    <Checkbox
                        name={name}
                        className="_square _blue"
                        value={value}
                        returnValue={info?.name}
                        handler={this.changeJoinScript}
                        id={id}
                        isError={isError}
                        isDisabled={isDisabled}
                    />
                </div>
                <label className="joinsScripts__checkboxContent _click" htmlFor={id}>
                    {checkbox.content}
                </label>
                {checkbox.description && (
                    <div
                        className="joinsScripts__checkboxDescription"
                        dangerouslySetInnerHTML={{ __html: checkbox.description }}
                    ></div>
                )}
            </div>
        );
    }

    getDocs() {
        const { joinScript } = this.props;

        if (joinScript?.allDocs?.length === 0) {
            return [{ _id: 'info' }];
        }

        return this.getSortedDocs(joinScript?.allDocs || []);
    }

    handlerDoc({ id }) {
        const { joinScript, changeJoinScript } = this.props;
        const { docs } = joinScript;
        const resultDocs = JSON.parse(JSON.stringify(docs));
        const docIndex = resultDocs.findIndex((doc) => doc.id === id);

        if (docIndex === -1) {
            resultDocs.push({ id, isRequire: true, countries: [] });
        } else {
            resultDocs.splice(docIndex, 1);
        }

        changeJoinScript({ action: 'change', name: 'docs', value: resultDocs });
    }

    getSortedDocs(docs) {
        const { joinScript } = this.props;

        return JSON.parse(JSON.stringify(docs)).sort((a, b) => {
            const aWeight = joinScript?.docs.find((doc) => doc.id === a._id) ? 1 : 0;
            const bWeight = joinScript?.docs.find((doc) => doc.id === b._id) ? 1 : 0;

            return bWeight - aWeight;
        });
    }

    handlerCountries(id, key, { value }) {
        const { joinScript, changeJoinScript } = this.props;
        const { docs } = joinScript;
        const resultDocs = JSON.parse(JSON.stringify(docs));
        const docIndex = resultDocs.findIndex((doc) => doc.id === id);

        if (docIndex !== -1) {
            if (key === 'countries') {
                const { countries } = resultDocs[docIndex];
                const countryIndex = countries.indexOf(value);

                if (countryIndex !== -1) {
                    countries.splice(countryIndex, 1);
                } else {
                    countries.push(value);
                }
            }
            if (key === 'isRequire') {
                resultDocs[docIndex].isRequire = value;
            }
        }

        return new Promise((resolve) => {
            changeJoinScript({ action: 'change', name: 'docs', value: resultDocs }).then(resolve);
        });
    }

    renderDoc({ item: doc, prop: id, key, saveItems, isLast }) {
        if (id === 'info') {
            return (
                <div className="joinsScripts__docsItem _info">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">Документов пока нет</div>
                            <p className="empty__content">Создайте первый документ</p>
                            <div className="empty__button _show">
                                <Button
                                    className="_mediumSize _mainNotBorder"
                                    onClick={() => {
                                        changePage({
                                            href: getPageLink({
                                                name: 'joins-docs-inner',
                                                ids: { 3: 'new' },
                                            }),
                                        });
                                    }}
                                >
                                    Создать документ
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const { currentCountriesActive } = this.state;
        const { joinScript, checkRights } = this.props;
        const { docs } = joinScript;
        const joinScriptDoc = docs.find((innerDoc) => innerDoc.id === id);
        const isActive = !!joinScriptDoc;
        let countries = joinScriptDoc?.countries.map((country) => ({
            name: country,
        }));

        if (countries?.length === 0) {
            countries = [{ name: 'all' }];
        }

        return (
            <div
                className={`joinsScripts__docsItem ${isActive ? '_active' : ''}`}
                style={{
                    zIndex:
                        currentCountriesActive === id ? saveItems.length : saveItems.length - key,
                }}
            >
                <div className="joinsScripts__doc _col">
                    <div className="joinsScripts__docTitle">{doc.name}</div>
                    <div className="joinsScripts__docDescription _row">
                        {doc.filesCounter}{' '}
                        {getEndText(doc.filesCounter, ['файл', 'файла', 'файлов'])}
                        <Animate
                            className="joinsScripts__docRequire _click"
                            isShow={isActive}
                            Tag="label"
                        >
                            <div className="joinsScripts__docRequireCheckbox">
                                <Checkbox
                                    className="_square _blue"
                                    value={joinScriptDoc?.isRequire}
                                    handler={this.handlerCountries.bind(this, id, 'isRequire')}
                                />
                            </div>
                            Обязательный
                        </Animate>
                    </div>
                    <AnimateChangeUp
                        className="joinsScripts__docCountries _row"
                        callback={({ isChangeLen }) => {
                            if (isChangeLen) {
                                this.setState({ updatedRenderDocKey: new Date().getTime() });
                            }
                        }}
                        renderKey={isActive}
                        parentStyles={['height']}
                    >
                        {isActive && (
                            <>
                                <div className="joinsScripts__docCountriesSupport">
                                    Гражданство:
                                </div>
                                <div className="joinsScripts__docCountriesInner">
                                    <Select
                                        className={`_fullWidthList _manualField ${
                                            isLast ? '_reverse' : ''
                                        }`}
                                        items={this.countriesItems}
                                        isMulti={true}
                                        value={countries}
                                        isEditmode={true}
                                        isDisabled={!checkRights()}
                                        support="–"
                                        notItems={[{ key: 'all' }]}
                                        callback={this.handlerCountries.bind(this, id, 'countries')}
                                        dropCallback={({ isActive: dropActive }) => {
                                            if (
                                                dropActive ||
                                                this.state.currentCountriesActive === id
                                            ) {
                                                this.setState({
                                                    currentCountriesActive: dropActive ? id : null,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </AnimateChangeUp>
                    {checkRights() && (
                        <ActionChange
                            className="joinsScripts__docAction"
                            isShow={true}
                            isSmoothShow={true}
                            isHas={isActive}
                            onClick={() => {
                                this.handlerDoc({ id });
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    getTags() {
        const { joinScript } = this.props;

        if (joinScript.tags.length === 0) {
            return [{ _id: 'empty' }];
        }

        return sortForAlphabet({ arr: joinScript.tags, prop: 'name' });
    }

    renderTag({ item: tag, prop: id }) {
        if (id === 'empty') {
            return <div className="joinsScripts__tagsItem">Не выбраны</div>;
        }

        return (
            <div className="joinsScripts__tagsItem">
                <div
                    className="tag"
                    style={{ background: tag.colorOfGroup, borderColor: tag.colorOfGroup }}
                >
                    {tag.name}
                </div>
            </div>
        );
    }

    setTags({ tags }) {
        const { changeJoinScript } = this.props;
        const resultTags = tags.map((tag) => ({
            _id: tag._id,
            id: tag._id,
            colorOfGroup: tag.colorOfGroup,
            name: tag.name,
        }));

        return new Promise((resolve) => {
            changeJoinScript({
                action: 'change',
                name: 'tags',
                value: resultTags,
            }).then(resolve);
        });
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    checkChange() {
        const { joinScript, joinScriptSave } = this.props;
        const fields = {};

        [
            'name',
            'link',
            'isOnlyInvite',
            'templateId',
            'contractSign',
            'executorOrganization',
        ].forEach((key) => {
            if (joinScript.isNew || joinScript[key] !== joinScriptSave[key]) {
                fields[key] = joinScript[key];
            }
        });

        if (
            joinScript.docs.length !== joinScriptSave.docs.length ||
            joinScript.docs.find(
                (doc) => !joinScriptSave.docs.find((saveDoc) => saveDoc.id === doc.id),
            )
        ) {
            fields.docs = joinScript.docs;
        } else {
            joinScript.docs.forEach((doc) => {
                const savedDoc = joinScriptSave.docs.find((saveDoc) => saveDoc.id === doc.id);

                if (
                    doc.isRequire !== savedDoc.isRequire ||
                    doc.countries.length !== savedDoc.countries.length ||
                    doc.countries.find(
                        (country) =>
                            !savedDoc.countries.find((savedCountry) => savedCountry === country),
                    )
                ) {
                    fields.docs = joinScript.docs;
                }
            });
        }

        if (
            joinScript.tags.length !== joinScriptSave.tags.length ||
            joinScript.tags.find(
                (tag) => !joinScriptSave.tags.find((saveTag) => saveTag.id === tag.id),
            )
        ) {
            fields.tags = joinScript.tags;
        }

        return { fields, isChange: Object.keys(fields).length > 0 };
    }

    validate() {
        const { joinScript } = this.props;
        const errors = [];

        ['name', 'link', 'templateId', 'contractSign', 'executorOrganization'].forEach((key) => {
            if (!joinScript[key]) {
                errors.push(key);
            }
        });

        if (joinScript.docs?.length === 0) {
            errors.push('docs');
        }

        return { errors };
    }

    handlerReqErrors({ message }) {
        if (message === 'Join script already create') {
            setNotification({
                notification: 'joinScript-already-reg',
            });

            this.handlerErrors({
                errors: ['link'],
            });
        }
    }

    save() {
        const { joinScript, setJoinScript, getJoinScript } = this.props;
        const { _id: id } = joinScript;
        const { fields, isChange } = this.checkChange();
        const { errors } = this.validate();

        if (errors.length) {
            this.handlerErrors({ errors });

            if (errors.includes('docs')) {
                setNotification({ notification: 'join-script-docs-empty' });
            }

            setNotification({ notification: 'required-fields-not-complete' });
        } else if (isChange) {
            this.handerLoading('save').then(() => {
                axios[joinScript.isNew ? 'post' : 'patch'](
                    `${process.env.REACT_APP_API}/join-script`,
                    { id, fields },
                    { headers: getHeaders() },
                ).then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { id: createdId } = data;

                            if (createdId) {
                                changePage({
                                    href: getPageLink({
                                        name: 'joins-scripts-inner',
                                        ids: { 3: createdId },
                                    }),
                                });

                                setNotification({ notification: 'success-create-joinScript' });

                                getJoinScript();
                            } else {
                                setNotification({ notification: 'success-change-info' });

                                setJoinScript({
                                    joinScript: {
                                        ...joinScript,
                                        isShowDisabled: undefined,
                                    },
                                });
                            }
                        } else {
                            const { message } = data;

                            if (message === 'Join script is disabled') {
                                setJoinScript({
                                    joinScript: {
                                        ...joinScript,
                                        isShowDisabled: true,
                                    },
                                    withSave: false,
                                });
                            }

                            this.handlerReqErrors(data);
                        }

                        this.handerLoading(null);
                    },
                    () => null,
                );
            });
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();
    }

    componentWillUnmount() {
        const { backToSave } = this.props;

        if (backToSave) {
            backToSave();
        }
    }

    render() {
        const { loadingKey, updatedRenderDocKey } = this.state;
        const { joinScript, checkRights } = this.props;

        return (
            <div ref={this.parent} className="joinsScripts">
                <div className="joinsScripts__blocks _row">
                    <div className="joinsScripts__block _info">
                        <div className="joinsScripts__blockInner">
                            <div className="joinsScripts__blockHead">
                                <div className="joinsScripts__blockHeadTitle">
                                    Общая информация о сценарие
                                </div>
                                <AnimateChangeUp
                                    className={`joinsScripts__blockHeadDescription ${
                                        joinScript?.name ? '_active' : ''
                                    }`}
                                    renderKey={joinScript?.name || true}
                                    parentStyles={['height']}
                                >
                                    {joinScript?.name || 'Не заполнено'}
                                </AnimateChangeUp>
                            </div>
                            <div className="joinsScripts__blockContent">
                                <Animate
                                    className="joinsScripts__blockAlert"
                                    isShow={joinScript?.templates?.length === 0}
                                >
                                    <div className="empty _col _block _notBack">
                                        <div className="empty__inner">
                                            <div className="empty__title">
                                                Шаблонов договора пока нет
                                            </div>
                                            <p className="empty__content">Создайте первый шаблон</p>
                                            <div className="empty__button _show">
                                                <Button
                                                    className="_mediumSize _mainNotBorder"
                                                    onClick={() => {
                                                        changePage({
                                                            href: getPageLink({
                                                                name: 'joins-templates-inner',
                                                                ids: { 3: 'new' },
                                                            }),
                                                        });
                                                    }}
                                                >
                                                    Создать шаблон
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Animate>
                                <div className="joinsScripts__blockContentInner">
                                    <div className="joinsScripts__section">
                                        {this.renderField({ name: 'name' })}
                                    </div>
                                    <div className="joinsScripts__section">
                                        {this.renderField({ name: 'link' })}
                                        {this.renderCheckbox({ name: 'isOnlyInvite' })}
                                    </div>
                                    <div className="joinsScripts__section">
                                        {this.renderField({ name: 'templateId' })}
                                    </div>
                                    <div className="joinsScripts__section">
                                        <div className="joinsScripts__field _row">
                                            <div className="joinsScripts__fieldSupport">
                                                Подписание договора:
                                            </div>
                                            <div className="joinsScripts__fieldContent">
                                                <div className="joinsScripts__checkboxes _row">
                                                    {Object.keys(this.contractSign).map((key) => (
                                                        <div
                                                            className="joinsScripts__checkboxesItem"
                                                            key={key}
                                                        >
                                                            {this.renderCheckbox({
                                                                name: 'contractSign',
                                                                info: {
                                                                    ...this.contractSign[key],
                                                                    name: key,
                                                                    value:
                                                                        joinScript?.contractSign ===
                                                                        key,
                                                                },
                                                            })}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="joinsScripts__section">
                                        <div className="joinsScripts__field _row _tags">
                                            <div className="joinsScripts__fieldSupport">Теги:</div>
                                            <div className="joinsScripts__fieldContent">
                                                <div className="joinsScripts__tags">
                                                    <ListAbsoluteMain
                                                        className="joinsScripts__tagsItems"
                                                        items={this.getTags()}
                                                        renderItem={this.renderTag}
                                                        classNameItem="joinsScripts__tagsItem"
                                                        prop="_id"
                                                        paramsParent={{ width: true }}
                                                        styles={['height']}
                                                        // itemParams={['offsetTop']}
                                                        sort={this.getSortedDocs}
                                                    />
                                                    <div className="joinsScripts__tagsButton">
                                                        {checkRights() && (
                                                            <Button
                                                                className="_mediumSize _blue"
                                                                onClick={() => {
                                                                    handlerPopup({
                                                                        name: 'tagsPopup',
                                                                        isShow: true,
                                                                        ids: joinScript.tags.map(
                                                                            (tag) => tag.id,
                                                                        ),
                                                                        callback: (newTags) => {
                                                                            this.setTags({
                                                                                tags: newTags,
                                                                            });
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                Выбрать теги
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="joinsScripts__section">
                                        <div className="joinsScripts__field _row _block _executorOrganization">
                                            <div className="joinsScripts__fieldSupport">
                                                В каком статусе может регистрироваться исполнитель:
                                            </div>
                                            <div className="joinsScripts__fieldContent">
                                                {Object.keys(this.executorOrganization).map((key) =>
                                                    this.renderCheckbox({
                                                        name: 'executorOrganization',
                                                        info: {
                                                            ...this.executorOrganization[key],
                                                            name: key,
                                                            value:
                                                                joinScript?.executorOrganization ===
                                                                key,
                                                        },
                                                    }),
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="joinsScripts__block _docs">
                        <div className="joinsScripts__blockInner">
                            <div className="joinsScripts__blockHead">
                                <div className="joinsScripts__blockHeadTitle">Сбор документов</div>
                                <AnimateChangeUp
                                    className={`joinsScripts__blockHeadDescription ${
                                        joinScript?.docs.length > 0 ? '_active' : ''
                                    }`}
                                    renderKey={joinScript?.docs.length}
                                    parentStyles={['height']}
                                >
                                    Выбрано: {joinScript?.docs.length}{' '}
                                    {getEndText(joinScript?.docs.length, [
                                        'документ',
                                        'документа',
                                        'документов',
                                    ])}
                                </AnimateChangeUp>
                            </div>
                            <div className="joinsScripts__blockContent">
                                <div className="joinsScripts__blockContentInner">
                                    <ListAbsoluteMain
                                        className="joinsScripts__docs _col"
                                        items={this.getDocs()}
                                        renderItem={this.renderDoc}
                                        classNameItem="joinsScripts__docsItem"
                                        prop="_id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                        itemParams={['offsetTop']}
                                        sort={this.getSortedDocs}
                                        keyRender={updatedRenderDocKey}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="joinsScripts__blockButton">
                            <Animate
                                className="joinsScripts__blockInfo _col _windowWithCursor _bottomRight"
                                isShow={!!joinScript.isShowDisabled}
                            >
                                <i className="joinsScripts__blockInfoIcon">
                                    <Icon name="alert" />
                                </i>
                                <div className="joinsScripts__blockInfoTitle">
                                    Сценарий изменить нельзя
                                </div>
                                <div className="joinsScripts__blockInfoDescription">
                                    По данному сценарию в&nbsp;настоящий момент проводятся
                                    подключения. Изменять ссылку сценария можно только, когда он
                                    не&nbsp;используется.
                                </div>
                            </Animate>
                            <Button
                                className="_mainNotBorder _medium2Size _disabledWhite"
                                onClick={this.save}
                                isDisabled={
                                    !this.checkChange().isChange ||
                                    joinScript?.allDocs?.length === 0 ||
                                    joinScript?.templates?.length === 0
                                }
                                showLoader={loadingKey === 'save'}
                            >
                                Сохранить сценарий
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(JoinsScriptsInnerMain);

JoinsScriptsInnerMain.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    joinScript: PropTypes.object,
    joinScriptSave: PropTypes.object,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataFiles: PropTypes.object,
    filesDelete: PropTypes.array,
    clearFiles: PropTypes.func,
    isInit: PropTypes.bool,
    backToSave: PropTypes.func,
    isNew: PropTypes.bool,
    getParent: PropTypes.func,
    changeJoinScript: PropTypes.func,
    handlerServices: PropTypes.func,
    checkRights: PropTypes.func,
    setJoinScript: PropTypes.func,
    getJoinScript: PropTypes.func,
};
