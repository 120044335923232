import React from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

import File from '../../../classes/File';

import Field from '../manual/card/Field.jsx';
import MainDefault from '../manual/Main.jsx';

import Icon from '../../Icon.jsx';

import { inputValidate } from '../../../functions/inputValidate';
import getHeaders from '../../../functions/getHeaders';
import getUpdateFormData from '../../../functions/getUpdateFormData';
import getFormatedDate from '../../../functions/getFormatedDate';
import getUser from '../../../functions/getUser';

import Animate from '../../Animate.jsx';
import ImageLazy from '../../ImageLazy.jsx';
import AnimateChange from '../../AnimateChange.jsx';
import Loader from '../../Loader.jsx';
import handlerPopup from '../../../functions/handlerPopup';
import setNotification from '../../../functions/setNotification';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';

import getClassTypeImage from '../../../functions/getClassTypeImage';
import Checkbox from '../../Checkbox.jsx';
import setSpacesInText from '../../../functions/setSpacesInText';
import changePage from '../../../functions/changePage';
import getPageLink from '../../../functions/getPageLink';

class PopupCorporation extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            isAcceptPolicy: true,
        };

        this.handlerField = this.handlerField.bind(this);
        this.save = this.save.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.handlerPolicy = this.handlerPolicy.bind(this);

        this.logo = React.createRef();
        this.parent = React.createRef();
    }

    orderFields = {
        main: ['inn', 'bic'],
        sub: ['kpp', 'fullName', 'bankName', 'nds', 'corrAccount', 'bankAccount'],
        foot: ['website', 'shortName'],
    };

    mainFieldsChilds = {
        inn: ['fullName', 'shortName'],
        bic: ['kpp', 'bankName', 'corrAccount'],
    };

    HandlerFiles = new File({});

    getAllFields() {
        const { isApiError } = this.state;

        return Object.values(this.orderFields)
            .reduce((prev, cur) => prev.concat(...cur), [])
            .filter((name) => !isApiError || this.orderFields.main.indexOf(name) !== -1);
    }

    initFields() {
        const { corporation } = this.props;

        this.formData = new FormData();

        this.setState((state) => {
            const newState = { ...state };
            const fields = {};

            this.getAllFields().forEach((nameField) => {
                let value = nameField === 'nds' ? false : '';

                if (corporation) {
                    value = corporation[nameField];
                }

                fields[nameField] = {
                    value,
                    error: null,
                    isLoading: false,
                };
            });

            newState.logo = {};

            if (corporation) {
                newState.logo = corporation.logo;
            }

            newState.fields = fields;

            return newState;
        });
    }

    getCompanyInfo({ inn, bic }) {
        let query = '';

        if (inn) {
            query += `inn=${inn}&`;
        }

        if (bic) {
            query += `bic=${bic}&`;
        }

        query = query.slice(0, -1);

        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API}/corporation?${query}`, { headers: getHeaders() })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            this.setState((state) => {
                                const newState = { ...state };
                                const fields = JSON.parse(JSON.stringify(newState.fields));

                                if (data.data && typeof data.data === 'object') {
                                    Object.keys(data.data).forEach((key) => {
                                        if (this.getAllFields().includes(key)) {
                                            fields[key].value = data.data[key];
                                            fields[key].error = null;
                                        }
                                    });
                                }

                                if (inn) {
                                    newState.opfKey = data.data.opfKey;
                                }

                                newState.isApiError = false;

                                newState.fields = fields;

                                return newState;
                            });

                            resolve();
                        } else {
                            const { errors, message } = data;

                            if (message === 'Api error') {
                                this.setState({ isApiError: true });

                                setNotification({ notification: 'api-error' });
                            } else if (errors && Array.isArray(errors)) {
                                errors.forEach((error) => {
                                    if (['inn', 'bic'].indexOf(error.key) !== -1) {
                                        this.setState((state) => {
                                            const newState = { ...state };
                                            const fields = JSON.parse(
                                                JSON.stringify(newState.fields),
                                            );

                                            this.mainFieldsChilds[error.key].forEach((key) => {
                                                fields[key].value = '';
                                            });

                                            newState.fields = fields;

                                            return newState;
                                        });
                                    }
                                });
                            }

                            handlerErrorRequest(res);

                            reject();
                        }
                    },
                    () => null,
                );
        });
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = JSON.parse(JSON.stringify(newState.fields));

                    if (action === 'change') {
                        fields[name].value = value;
                        fields[name].error = null;
                    }

                    if (action === 'error') {
                        fields[name].error = value;
                    }

                    if (action === 'focus') {
                        fields[name].isFocus = true;
                    }

                    if (action === 'blur') {
                        fields[name].isFocus = false;
                    }

                    if (action === 'loading') {
                        fields[name].isLoading = value;
                    }

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    if (action === 'change') {
                        if (
                            ['inn', 'bic'].indexOf(name) !== -1 &&
                            inputValidate({
                                name,
                                value,
                            })
                        ) {
                            this.handlerField({ action: 'loading', name, value: true }).then(() => {
                                if (this.name !== 'settingsMain' || name !== 'inn') {
                                    this.getCompanyInfo({ [name]: value }).then(
                                        () => {
                                            this.handlerField({
                                                action: 'loading',
                                                name,
                                                value: false,
                                            });
                                        },
                                        () => {
                                            this.handlerField({
                                                action: 'loading',
                                                name,
                                                value: false,
                                            });
                                        },
                                    );
                                }
                            });

                            window.getSelection().removeAllRanges();
                        }
                    }

                    resolve();
                },
            );
        });
    }

    checkError(name) {
        const { fields } = this.state;
        const field = fields?.[name];

        return (
            !!field?.error ||
            (field?.value &&
                !inputValidate({
                    name,
                    value: field?.value,
                }) &&
                !field?.isFocus)
        );
    }

    renderFields({ name, fieldsOrder }) {
        const { fields, editName } = this.state;
        const orderFields = fieldsOrder || this.orderFields[name];
        let classNameField = '';

        if (name === 'main') {
            classNameField = '_white';
        }

        if (this.name !== 'settingsMain') {
            classNameField += ' _center';
        }

        return (
            <div className={`popupCorporation__fields _row _${name}`}>
                {orderFields.map((nameField) => {
                    const field = fields?.[nameField];
                    let support;
                    let isReadOnly =
                        this.name === 'settingsMain'
                            ? editName
                                ? !this.fieldsGroups[editName]?.includes(nameField)
                                : true
                            : false;

                    if (this.name === 'settingsMain') {
                        if (nameField === 'inn') {
                            isReadOnly = true;
                        }

                        if (nameField === 'website') {
                            support = 'Сайт';
                        }

                        if (nameField === 'shortName') {
                            support = 'Название';
                        }
                    }

                    return (
                        <div
                            className={`popupCorporation__field _${nameField} ${
                                field?.isLoading ? '_isLoading' : ''
                            }`}
                            key={nameField}
                        >
                            <div className="popupCorporation__fieldInner">
                                <Field
                                    support={support}
                                    value={field?.value}
                                    type="popupCorporation"
                                    name={nameField}
                                    handler={this.handlerField}
                                    group="popupCorporation"
                                    classNameField={`${classNameField} _popupCorporation`}
                                    isReadOnly={isReadOnly}
                                    isDisabled={isReadOnly}
                                    isError={this.checkError(nameField)}
                                    isLoading={field?.isLoading}
                                    id={`input-popupCorporation-${nameField}`}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    checkValidateFields(allFields = this.getAllFields()) {
        const { fields, opfKey } = this.state;
        const notRequiredFields = ['nds', 'website', 'shortName', 'bankAccount'];

        if (opfKey === 'IP') {
            notRequiredFields.push('kpp');
        }

        const validateFields = allFields.filter((nameField) =>
            inputValidate({
                name: nameField,
                value: fields[nameField].value,
                isRequire: !notRequiredFields.includes(nameField),
            }),
        );
        const notValidateFields = allFields.filter(
            (nameField) =>
                !notRequiredFields.includes(nameField) &&
                !inputValidate({
                    name: nameField,
                    value: fields[nameField].value,
                    isRequire: !notRequiredFields.includes(nameField),
                }),
        );
        let errorFieldName = null;
        const { length: lengthValidateFields } = validateFields;

        errorFieldName = notValidateFields[0];

        return {
            isSuccess: lengthValidateFields === allFields.length,
            errorFieldName,
            errors: notValidateFields,
        };
    }

    handlerLoading(loadingKey = null) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerErrorRequest({ message, errors }) {
        if (message === 'Corporation already create') {
            setNotification({
                notification: 'corporation-already-register',
            });

            this.handlerField({
                action: 'error',
                name: 'inn',
                value: 'validate',
            });
        } else if (errors) {
            errors.forEach((error) => {
                this.handlerField({
                    action: 'error',
                    name: error.key,
                    value: 'validate',
                });

                if (['inn', 'bic'].includes(error.key)) {
                    setNotification({
                        notification: `${error.key}-is-error`,
                    });
                }
            });
        }
    }

    setErrors(errors) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = JSON.parse(JSON.stringify(newState.fields));

            errors.forEach((key) => {
                fields[key].error = 'validate';
            });

            newState.fields = fields;

            return newState;
        });

        setNotification({ notification: 'required-fields-not-complete' });
    }

    save() {
        const { fields, loadingKey, isApiError, isAcceptPolicy, opfKey } = this.state;
        const validateFields = this.checkValidateFields();
        const { errors } = validateFields;
        let { isSuccess } = validateFields;

        if (!loadingKey && !fields.inn.isLoading && !fields.bic.isLoading) {
            if (!isAcceptPolicy) {
                isSuccess = false;

                this.setState({ isAcceptPolicyError: true });

                setNotification({ notification: 'required-accept-policy' });
            }

            if (!isSuccess) {
                this.setErrors(errors);
            } else {
                const resultFields = {};

                Object.keys(fields).forEach((nameField) => {
                    resultFields[nameField] = fields[nameField].value;
                });

                this.formData.set('fields', JSON.stringify(resultFields));

                if (isApiError) {
                    this.formData.set('isApiError', true);
                }

                this.formData.set('opfKey', opfKey);

                this.handlerLoading('save').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/corporation`,
                            getUpdateFormData(this.formData),
                            {
                                headers: getHeaders(),
                            },
                        )
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    getUser(null, true).then(
                                        () => {
                                            document.dispatchEvent(
                                                new CustomEvent('changeUser', {}),
                                            );

                                            changePage({
                                                href: getPageLink({ name: 'corporation' }),
                                            });

                                            handlerPopup({
                                                action: 'hide',
                                                name: 'popupCorporation',
                                            });

                                            setNotification({
                                                notification: 'corporation-create',
                                            });

                                            this.handlerLoading(null);
                                        },
                                        () => null,
                                    );
                                } else {
                                    this.handlerErrorRequest(data);

                                    handlerErrorRequest(res);

                                    this.handlerLoading(null);
                                }
                            },
                            () => {
                                this.handlerLoading(null);
                            },
                        );
                });
            }
        }
    }

    uploadLogo({ target }) {
        this.HandlerFiles.uploadFiles({ target, formData: this.formData }).then(
            ({ resultFiles }) => {
                const [file] = resultFiles;

                this.setState({ logo: file });
            },
            () => null,
        );
    }

    getInfoLogo() {
        const { logo, isLogoOver } = this.state;

        if (isLogoOver) {
            if (this.name === 'settingsMain') {
                return {
                    description: `Отпустите курсор`,
                };
            }

            return {
                description: `Отпустите курсор<br/>для загрузки файла`,
            };
        }

        if (logo?.path) {
            const date = getFormatedDate({
                date: logo.dateOfUpload ? new Date(logo.dateOfUpload) : new Date(),
                isShortYear: true,
            });

            if (this.name === 'settingsMain') {
                return {
                    description: `Загружен: ${date}`,
                };
            }

            return {
                description: `Загружен: ${date}<br/>Вес файла: ${this.HandlerFiles.getSize(
                    logo.size,
                )}`,
            };
        }

        if (this.name === 'settingsMain') {
            return {
                description: `Загрузите png/jpg/svg-файл`,
            };
        }

        return {
            description: `Загрузите или перетащите<br/>сюда png/jpg/svg-файл`,
        };
    }

    renderMain() {
        const { editName } = this.state;

        return (
            <div
                className={`popupCorporation__main _row ${
                    this.name === 'settingsMain' && editName === 'main' ? '_edit' : ''
                }`}
            >
                <div
                    className={`popupCorporation__mainFields ${
                        this.orderFields.main.some((nameField) => this.checkError(nameField))
                            ? '_error'
                            : ''
                    }`}
                >
                    {this.renderFields({ name: 'main' })}
                </div>

                <div className="popupCorporation__mainInfo">
                    Введите ИНН и БИК — другие поля <b>заполнятся автоматически</b>
                </div>
            </div>
        );
    }

    renderSub(data = {}) {
        const { fieldsOrder } = data;

        return (
            <div className="popupCorporation__sub">
                <div className="popupCorporation__subFields">
                    {this.renderFields({ name: 'sub', fieldsOrder })}
                </div>
            </div>
        );
    }

    renderFoot() {
        const { logo, isLogoOver, editName } = this.state;
        const logoSrc = logo?.isLocal
            ? logo?.path
            : `${process.env.REACT_APP_STATIC}/corporations/${logo?.path}`;
        const isReadOnly = this.name === 'settingsMain' ? editName !== 'foot' : false;

        return (
            <div
                className={`popupCorporation__foot _row ${
                    this.name === 'settingsMain' ? '_settingsMain' : ''
                } ${editName === 'foot' ? '_edit' : ''}`}
            >
                <label
                    ref={this.logo}
                    className={`popupCorporation__footLogo _row ${logo?.path ? '_upload' : ''}`}
                >
                    <input
                        type="file"
                        className="popupCorporation__footLogoInput"
                        onChange={this.uploadLogo}
                        disabled={isReadOnly}
                    />
                    <div className="popupCorporation__footLogoPreview _col">
                        <i className="popupCorporation__footLogoPreviewIcon">
                            <Icon name="plus" />
                        </i>
                        <Animate
                            className="popupCorporation__footLogoPreviewImage"
                            isShow={!!logo?.path}
                        >
                            <AnimateChange
                                className="popupCorporation__footLogoPreviewImageInner"
                                prop={logoSrc}
                                isNotParams={true}
                            >
                                <ImageLazy
                                    src={logoSrc}
                                    className={`popupCorporation__footLogoPreviewImageItem ${getClassTypeImage(
                                        logo?.type,
                                    )}`}
                                />
                            </AnimateChange>
                        </Animate>
                    </div>
                    <div className="popupCorporation__footLogoInfo">
                        <p className="popupCorporation__footLogoInfoTitle">
                            {this.name === 'settingsMain' ? 'Логотип' : 'Логотип компании'}
                        </p>
                        <div className="popupCorporation__footLogoInfoDescription">
                            <AnimateChange
                                className="popupCorporation__footLogoInfoDescriptionItems"
                                prop={`${!!logo?.path}${logo?.size}${isLogoOver}`}
                                type="_translateMedium"
                                isNotParams={true}
                            >
                                <div
                                    className="popupCorporation__footLogoInfoDescriptionItem"
                                    dangerouslySetInnerHTML={{
                                        __html: this.getInfoLogo().description,
                                    }}
                                ></div>
                            </AnimateChange>
                        </div>
                    </div>
                </label>
                <div className="popupCorporation__footContent">
                    <div className="popupCorporation__footFields">
                        {this.renderFields({ name: 'foot' })}
                    </div>
                    {this.name !== 'settingsMain' && (
                        <p className="popupCorporation__footSupport">
                            Не обязательные для заполнения поля
                        </p>
                    )}
                </div>
            </div>
        );
    }

    handlerPolicy({ value }) {
        this.setState({ isAcceptPolicy: value, isAcceptPolicyError: null });
    }

    componentDidMount() {
        const { user } = this.props;

        if (user?.corporations.length === 0) {
            this.setState({ isDisabledClose: true });
        }

        this.initFields();

        if (this.logo.current) {
            this.HandlerFiles.setDrag({
                area: this.logo.current,
                handlerOver: () => {
                    this.setState({ isLogoOver: true });
                },
                handlerLeave: () => {
                    this.setState({ isLogoOver: false });
                },
                handlerDrop: (files) => {
                    this.uploadLogo({ target: { files } });

                    this.setState({ isLogoOver: false });
                },
            });
        }
    }

    getMainInfo() {
        const { corporation } = this.props;

        let resultString = '';

        if (corporation.inn) {
            resultString += `ИНН ${corporation.inn}`;
        }

        if (corporation.bic) {
            if (corporation.inn) {
                resultString += ', ';
            }

            resultString += `БИК ${corporation.bic}`;
        }

        if (!resultString) {
            resultString = '–';
        }

        return resultString;
    }

    render() {
        const { loadingKey, isAcceptPolicy, isAcceptPolicyError } = this.state;

        return (
            <div ref={this.parent} className="popupCorporation _col">
                <div className="popupCorporation__inner">
                    <div className="popupCorporation__head">
                        <div className="popupCorporation__headInner _row">
                            <div className="popupCorporation__headTitle">
                                Создание новой компании:
                            </div>
                            <div className="popupCorporation__headActions _row">
                                <div
                                    className="popupCorporation__headAction _click _save"
                                    onClick={this.save}
                                >
                                    <Animate
                                        className="popupCorporation__headActionLoader _loader"
                                        isShow={loadingKey === 'save'}
                                    >
                                        <i className="popupCorporation__headActionLoaderItem _loaderItem">
                                            <Loader className="" />
                                        </i>
                                    </Animate>
                                    Сохранить данные
                                </div>

                                <div
                                    className="popupCorporation__headAction _click _close"
                                    onClick={() => {
                                        handlerPopup({
                                            action: 'hide',
                                            name: 'popupCorporation',
                                        });
                                    }}
                                >
                                    Закрыть
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popupCorporation__content">
                        {this.renderMain()}
                        {this.renderSub()}
                    </div>
                    {this.renderFoot()}
                    <div
                        className={`popupCorporation__policy ${
                            isAcceptPolicyError ? '_error' : ''
                        }`}
                    >
                        <div className="popupCorporation__policyInner">
                            <label className="popupCorporation__policyContent _row">
                                <div className="popupCorporation__policyCheckbox">
                                    <Checkbox
                                        handler={this.handlerPolicy}
                                        className="_square _empty _mediumSize"
                                        value={isAcceptPolicy}
                                    />
                                </div>
                                <div
                                    className="popupCorporation__policyText"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(
                                            'Я подтверждаю своё согласие и согласие третьих лиц, в интересах которых я действую, на направление мне и им информационных и рекламных смс-сообщений, уведомлений, писем на указанный мной адрес электронной почты и/или номер мобильного телефона, в том числе с использованием сторонних сервисов.',
                                        ),
                                    }}
                                ></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PopupCorporation;

PopupCorporation.propTypes = {
    _id: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    thirdName: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    user: PropTypes.object,
    corporation: PropTypes.object,
};
