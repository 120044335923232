import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../card/Head.jsx';
import Field from '../card/Field.jsx';

import setServerData from '../../../../functions/setServerData';
import ParamsPreview from '../card/car/ParamsPreview.jsx';

class ManualCarsParams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getDescriptionInfo() {
        const { car } = this.props;

        if (car) {
            if (car.long && car.width && car.height) {
                return {
                    description: `${car.long}*${car.width}*${car.height}см`,
                };
            }
        }

        return {
            description: '–',
        };
    }

    fields = [
        { name: 'long' },
        { name: 'heightWithFloor' },
        { name: 'bodywork' },
        { name: 'width' },
        { name: 'heightCargo' },
        { name: 'passengers' },
        { name: 'height' },
        { name: 'loadings' },
    ];

    componentDidMount() {
        setServerData('carBodyworks');
    }

    render() {
        const { serverData, title, car = {}, checkEditmode, changeCar, errors = [] } = this.props;
        const { carBodyworks = {} } = serverData;
        const { description } = this.getDescriptionInfo();

        return (
            <div className="manualCard _carParams">
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__params _row">
                        <div className="manualCard__paramsItem _preview">
                            <ParamsPreview
                                car={car}
                                isDisabled={!checkEditmode()}
                                handler={changeCar}
                                errors={errors}
                            />
                        </div>
                        <div className="manualCard__paramsItem _fields">
                            <div className="manualCard__fields _row">
                                {this.fields.map((field) => {
                                    const { name } = field;
                                    let { isReadOnly } = field;
                                    const { [name]: value } = car;
                                    let items;

                                    if (
                                        car.type === 'car' &&
                                        car.bodywork === 'tyagch_pricep' &&
                                        [
                                            'long',
                                            'width',
                                            'height',
                                            'heightWithFloor',
                                            'heightCargo',
                                            'loadings',
                                            'passengers',
                                        ].includes(name)
                                    ) {
                                        isReadOnly = true;
                                    }

                                    if (name === 'bodywork') {
                                        items = Object.keys(carBodyworks).map((key) => ({
                                            key,
                                            content: carBodyworks[key].content,
                                        }));
                                    }

                                    return (
                                        <div className={`manualCard__field _${name}`} key={name}>
                                            <Field
                                                value={value}
                                                type="car"
                                                name={name}
                                                handler={changeCar}
                                                group="carMain"
                                                isDisabled={!checkEditmode()}
                                                isReadOnly={isReadOnly}
                                                items={items}
                                                isError={errors.indexOf(name) !== -1}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ManualCarsParams);

ManualCarsParams.propTypes = {
    serverData: PropTypes.object,
    car: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    changeCar: PropTypes.func,
    errors: PropTypes.array,
};
