import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Animate from '../../../../components/Animate.jsx';
import MainDefault from '../../../../components/crm/manual/Main.jsx';
import Loader from '../../../../components/Loader.jsx';
import getGoogleVisionGroups from '../../../../requests/getGoogleVisionGroups';
import Head from '../../../../components/crm/manual/card/Head.jsx';
import getEndText from '../../../../functions/getEndText';
import hexToRgb from '../../../../functions/hexToRgb';

import ImageLazy from '../../../../components/ImageLazy.jsx';
import Field from '../../../../components/Field.jsx';
import Button from '../../../../components/Button.jsx';
import getHeaders from '../../../../functions/getHeaders';
import setNotification from '../../../../functions/setNotification';

class ContentGoogleVisionInner extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {};

        this.updateGroup = this.updateGroup.bind(this);

        this.parent = React.createRef();
    }

    cols = {
        key: {
            content: 'Слово (английский)',
        },
        translation: {
            content: 'Перевод (русский)',
        },
        long: {
            content: 'Длина',
            keyName: 'numberSize',
            support: 'Длина',
        },
        width: {
            content: 'Ширина',
            keyName: 'numberSize',
            support: 'Ширина',
        },
        height: {
            content: 'Высота',
            keyName: 'numberSize',
            support: 'Высота',
        },
        weight: {
            content: 'Вес',
            keyName: 'numberWeight',
            support: 'Вес',
        },
        temperature: {
            content: 'Режим t°',
        },
    };

    renderWord({ word, key }) {
        const { errors } = this.state;
        const { wordProps, checkRights } = this.props;

        return (
            <div className="contentGoogleVision__word" key={word._id}>
                <div className="contentGoogleVision__wordKey" style={{ color: word.color }}>
                    {key + 1}.
                </div>
                <div className="contentGoogleVision__wordFields _row">
                    {wordProps.map((name) => {
                        const { [name]: value } = word;
                        const rgb = hexToRgb(word.color);

                        return (
                            <div className={`contentGoogleVision__wordField _${name}`} key={name}>
                                <Field
                                    type="contentGoogleVision"
                                    support={this.cols[name].support}
                                    keyName={this.cols[name].keyName || name}
                                    name={name}
                                    value={value}
                                    className={`_grey _googleVision ${
                                        ['long', 'width', 'height', 'weight'].includes(name)
                                            ? '_mediumSize'
                                            : ''
                                    }`}
                                    callback={({ ...props }) =>
                                        new Promise((resolve) => {
                                            this.changeWord({ id: word._id, ...props }).then(
                                                resolve,
                                            );
                                        })
                                    }
                                    isEditmode={true}
                                    id={`${word._id}-${name}`}
                                    error={errors?.[word._id]?.includes(key)}
                                    style={
                                        name === 'key'
                                            ? {
                                                  background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .075)`,
                                              }
                                            : {}
                                    }
                                    styleText={name === 'key' ? { color: word.color } : {}}
                                    isDisabled={name === 'key' || !checkRights()}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    changeWord({ id, action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const group = JSON.parse(JSON.stringify(newState.group));
                        const word = group.words.find((innerWord) => innerWord._id === id);

                        word[name] = value;

                        newState.group = group;

                        return newState;
                    },
                    () => {
                        this.handlerErrors({
                            action: 'delete',
                            error: name,
                            block: id,
                        });

                        resolve();
                    },
                );
            }
        });
    }

    getChangedFields() {
        const { group, saveGroup } = this.state;
        const { wordProps } = this.props;
        const fields = {};

        if (group) {
            group.words.forEach((word) => {
                const saveWord = saveGroup.words.find((innerWord) => innerWord._id === word._id);

                if (word && saveWord) {
                    wordProps.forEach((key) => {
                        if (word[key] !== saveWord[key]) {
                            fields[key] = word[key];
                        }
                    });
                }
            });
        }

        return { isSuccess: Object.keys(fields).length > 0, fields };
    }

    updateGroup() {
        const { group } = this.state;
        const { isSuccess } = this.getChangedFields();

        if (isSuccess) {
            this.handlerLoadingSave(true).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/google-items`,
                        { id: group._id, words: group.words },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                this.setGroup({ group: this.state.group });

                                setNotification({ notification: 'success-change-info' });
                            }

                            this.handlerLoadingSave(false);
                        },
                        () => null,
                    );
            });
        }
    }

    setGroup({ group }) {
        const { setInfoHead } = this.props;

        this.setState({ group, saveGroup: JSON.parse(JSON.stringify(group)) }, () => {
            setTimeout(() => {
                this.setState({ isReady: true });
            }, 300);

            setInfoHead({ key: 'group', value: group });
        });
    }

    getGroup() {
        const { levels } = this.props;
        const id = levels[3];

        getGoogleVisionGroups({ id }).then(
            ({ group }) => {
                this.setGroup({ group });
            },
            () => null,
        );
    }

    getDescriptionInfo() {
        const { group } = this.state;

        if (group) {
            return {
                description: `${group.words.length} ${getEndText(group.words.length, [
                    'объект',
                    'объекта',
                    'объектов',
                ])}`,
            };
        }

        return {
            description: '–',
        };
    }

    componentDidMount() {
        this.getGroup();
    }

    render() {
        const { isReady, group, isLoadingSave } = this.state;
        const { checkRights } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`widget ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _inner _full _scroll">
                    <div className="widget__contentBox _padding">
                        <div className="contentGoogleVision">
                            <div className="contentGoogleVision__inner">
                                <div ref={this.parent} className="manualCard">
                                    <div className="manualCard__head _row">
                                        <Head title="Общая информация" description={description} />
                                    </div>
                                    <div className="manualCard__content">
                                        <div className="contentGoogleVision__blocks _row">
                                            <div className="contentGoogleVision__block _preview">
                                                <ImageLazy
                                                    src={`${process.env.REACT_APP_STATIC}/google-items/${group?.preview.path}`}
                                                    className="contentGoogleVision__image"
                                                />
                                            </div>
                                            <div className="contentGoogleVision__block _fields">
                                                {group?.words.map((word, key) =>
                                                    this.renderWord({ word, key }),
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contentGoogleVision__foot _row">
                                    <div className="contentGoogleVision__footButton">
                                        <Button
                                            className="_mainNotBorder _medium2Size _disabledDark"
                                            onClick={this.updateGroup}
                                            showLoader={isLoadingSave}
                                            isDisabled={
                                                !this.getChangedFields().isSuccess || !checkRights()
                                            }
                                        >
                                            Сохранить данные
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ContentGoogleVisionInner);

ContentGoogleVisionInner.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    levels: PropTypes.array,
    wordProps: PropTypes.array,
    checkRights: PropTypes.func,
};
