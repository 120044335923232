import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import PayPopup from './PayPopup.jsx';

class PayPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { payPopup } = this.props;

        return (
            <Animate className="body__payPopup" isShow={payPopup.isShow}>
                <PayPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        payPopup: state.payPopup,
    };
}

export default connect(mapStateToProps)(PayPopupWrapper);

PayPopupWrapper.propTypes = {
    payPopup: PropTypes.object,
};
