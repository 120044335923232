import axios from 'axios';
import getHeaders from '../functions/getHeaders';

export default async function uploadFile({ formData }) {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API}/upload-file`, formData, {
            headers: getHeaders(),
        });

        const { success, data } = res.data;

        if (success) {
            return data;
        }

        return Promise.reject(data);
    } catch (error) {
        return Promise.reject(error);
    }
}
