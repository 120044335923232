import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { renderToStaticMarkup } from 'react-dom/server';

import getHeaders from '../../../../functions/getHeaders';
import handlerErrorRequest from '../../../../functions/handlerErrorRequest';
import getFormatPrice from '../../../../functions/getFormatPrice';
import getUserInfo from '../../../../functions/getUserInfo';
import getFormatedDate from '../../../../functions/getFormatedDate';
import getEndText from '../../../../functions/getEndText';
import handlerSupportCursor from '../../../../functions/handlerSupportCursor';

import Link from '../../../../components/Link.jsx';
import Table from '../../../../components/crm/manual/Table.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Icon from '../../../../components/Icon.jsx';

const orderPays = require('../../../../infos/order/pays.json');
const orderServiceCompanies = require('../../../../infos/crm/orderServiceCompanies.json');

class RegistryInner extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderRow = this.renderRow.bind(this);
        this.getParentForScroll = this.getParentForScroll.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getFilterItems = this.getFilterItems.bind(this);
        this.changeRegistryCols = this.changeRegistryCols.bind(this);

        this.setHeightPage = null;

        this.name = `ordersRegistry-${props.name}`;

        this.parent = React.createRef();
    }

    classNameItem = '.reportsRegistryTable__row';

    stepCounter = 20;

    colsMany = {
        all: [
            'clientAndExecutor',
            'tariffAmount',
            'tariffExtraHour',
            'services',
            'mkad',
            'priceOfMkad',
            'finalAmount',
            'changedPrice',
            'typeOfPay',
        ],
        clients: [],
        executors: [],
        services: [],
    };

    renderInfo({ services = [] }) {
        let content = '<div class="reportsRegistryTable__colInfoItems">';
        const sortedServices = JSON.parse(JSON.stringify(services)).sort(
            (a, b) => b.price - a.price,
        );

        sortedServices.forEach((service) => {
            content += '<div class="reportsRegistryTable__colInfoItem _row">';

            content += '<div class="reportsRegistryTable__colInfoContent _name">';

            content += service.name;

            content += '</div>';

            content += '<div class="reportsRegistryTable__colInfoContent">';

            content += `${getFormatPrice(service.price)} ₽`;

            content += '</div>';

            content += '</div>';
        });

        content += '</div>';

        return services.length > 0 ? (
            <div
                className="reportsRegistryTable__colInfo"
                onMouseEnter={(e) => {
                    handlerSupportCursor({
                        action: 'enter',
                        content,
                        e,
                        data: { className: '_info' },
                    });
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                <i className="reportsRegistryTable__colInfoIcon _click">
                    <Icon name="info-2" />
                </i>
            </div>
        ) : null;
    }

    renderHeadCol({ name, counter, key }) {
        const { cols } = this.props;

        return (
            <div className={`reportsRegistryTable__col _col _${name}`} key={`${name}${key}`}>
                {cols[name]}
                {name === 'address' ? ` ${counter}` : ``}
            </div>
        );
    }

    setHeightManyCols() {
        this.parent.current
            .querySelectorAll('.reportsRegistryTable__row:not(._head)')
            .forEach((row) => {
                const manyCols = row.querySelectorAll('.reportsRegistryTable__col');
                const maxHeights = [-Infinity, -Infinity];

                manyCols.forEach((manyCol) => {
                    manyCol
                        .querySelectorAll('.reportsRegistryTable__colInner')
                        .forEach((colInner, key) => {
                            colInner.style.height = null;

                            const { offsetHeight } = colInner;

                            if (offsetHeight > maxHeights[key]) {
                                maxHeights[key] = offsetHeight;
                            }
                        });
                });

                manyCols.forEach((manyCol) => {
                    manyCol
                        .querySelectorAll('.reportsRegistryTable__colInner')
                        .forEach((colInner, key) => {
                            colInner.style.height = `${maxHeights[key]}px`;
                        });
                });
            });
    }

    renderCol({ name: colName, order, key }) {
        const { name } = this.props;
        let inner;
        let className = '';

        switch (colName) {
            case 'nameOfCompany':
                inner = (
                    <div className="reportsRegistryTable__text">{order.nameOfCompany || '–'}</div>
                );
                break;
            case 'nameOfOrganization':
                inner = (
                    <p className="reportsRegistryTable__text">
                        {order.executor.companyName || `–`}
                        {order.executor.withCompany && (
                            <>
                                <span className="_noWrap">
                                    {getUserInfo({
                                        type: 'fullnameShort',
                                        user: order.executor,
                                    })}
                                </span>
                            </>
                        )}
                    </p>
                );
                break;
            case 'tariffType':
                inner = <div className="reportsRegistryTable__text">{order.tariffName}</div>;
                break;
            case 'bodywork':
                inner = (
                    <div className="reportsRegistryTable__text">{order.bodyworkName || '–'}</div>
                );
                break;
            case 'numberOfOrder':
                inner = (
                    <Link
                        pageName="order-details-main"
                        className="reportsRegistryTable__link"
                        ids={{ 2: order._id }}
                    >
                        {order.numberOfOrder}
                    </Link>
                );
                break;
            case 'userName':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getUserInfo({ type: 'name', user: order.user }) || '–'}
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <p className="reportsRegistryTable__text">
                                {getUserInfo({ type: 'name', user: order.executor }) || '–'}
                                {order.executor.type === 'driver' &&
                                order.executor.role === 'worker' ? (
                                    <> (Грузчик)</>
                                ) : (
                                    <></>
                                )}
                            </p>
                        );
                        break;
                    case 'services':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.userName || '–'}
                            </div>
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'carMark':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.mark.name || `–`}
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.executor.carInfo?.mark.name || `–`}
                            </div>
                        );
                        break;
                    case 'services':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.mark || `–`}
                            </div>
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'carModel':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.model.name || `–`}
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.executor.carInfo?.model.name || `–`}
                            </div>
                        );
                        break;
                    case 'services':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.model || `–`}
                            </div>
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'carNumber':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.number || `–`}
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.executor.carInfo?.number || `–`}
                            </div>
                        );
                        break;
                    case 'services':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.carInfo?.number || `–`}
                            </div>
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'dateOfStart':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatedDate({ date: new Date(order.timeStart), type: 'date' })}
                    </div>
                );
                break;
            case 'timeOfStart':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatedDate({ date: new Date(order.timeStart), type: 'time' })}
                    </div>
                );
                break;
            case 'dateOfEnd':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatedDate({ date: new Date(order.timeEnd), type: 'date' })}
                    </div>
                );
                break;
            case 'timeOfEnd':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatedDate({ date: new Date(order.timeEnd), type: 'time' })}
                    </div>
                );
                break;
            case 'counterWork':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {order.counterWork} час{getEndText(order.counterWork, ['', 'а', 'ов'])}
                    </div>
                );
                break;
            case 'tariffAmount':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.tariffAmount)} ₽
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.executor.tariffAmount)} ₽
                            </div>
                        );
                        break;
                    case 'services':
                        inner = <div className="reportsRegistryTable__text">–</div>;
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.tariffAmount)} ₽
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.executor.tariffAmount)} ₽
                                        {order.executor.workerInfo ? (
                                            <>
                                                {' + '}
                                                {getFormatPrice(
                                                    order.executor.workerInfo.tariffAmount,
                                                )}
                                                ₽
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'tariffExtraHour':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.tariffExtraHour)} ₽
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.executor.tariffExtraHour)} ₽
                            </div>
                        );
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.tariffExtraHour)} ₽
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.executor.tariffExtraHour)} ₽
                                        {order.executor.workerInfo ? (
                                            <>
                                                {' + '}
                                                {getFormatPrice(
                                                    order.executor.workerInfo.tariffExtraHour,
                                                )}
                                                ₽
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'services':
                switch (name) {
                    case 'clients':
                        {
                            const value = order.services.reduce((prev, cur) => prev + cur.price, 0);

                            inner = (
                                <>
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(value)} ₽
                                    </div>
                                    {this.renderInfo({ services: order.services })}
                                </>
                            );
                        }
                        break;
                    case 'executors':
                        {
                            const value = order.executor.services.reduce(
                                (prev, cur) => prev + cur.price,
                                0,
                            );

                            inner = (
                                <>
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(value)} ₽
                                    </div>
                                    {this.renderInfo({ services: order.executor.services })}
                                </>
                            );
                        }
                        break;
                    default:
                        {
                            const servicesOrder = order.services.reduce(
                                (prev, cur) => prev + cur.price,
                                0,
                            );
                            const servicesExecutor = order.executor.services.reduce(
                                (prev, cur) => prev + cur.price,
                                0,
                            );
                            const servicesExecutorWorker =
                                (order.executor.workerInfo &&
                                    order.executor.workerInfo.services.reduce(
                                        (prev, cur) => prev + cur.price,
                                        0,
                                    )) ||
                                0;

                            inner = (
                                <div className="reportsRegistryTable__colWrap">
                                    <div className="reportsRegistryTable__colInner">
                                        <div className="reportsRegistryTable__text">
                                            {getFormatPrice(servicesOrder)} ₽
                                        </div>
                                        {this.renderInfo({ services: order.services })}
                                    </div>
                                    <div className="reportsRegistryTable__colInner">
                                        <div className="reportsRegistryTable__text">
                                            <>
                                                {getFormatPrice(servicesExecutor)} ₽
                                                {servicesExecutorWorker > 0 ? (
                                                    <>
                                                        {' + '}
                                                        {getFormatPrice(servicesExecutorWorker)} ₽
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        </div>
                                        {this.renderInfo({ services: order.executor.services })}
                                    </div>
                                </div>
                            );
                        }
                        break;
                }
                break;
            case 'mkad':
                switch (name) {
                    case 'clients':
                        inner = <div className="reportsRegistryTable__text">{order.mkad}</div>;
                        break;
                    case 'executors':
                        inner = <div className="reportsRegistryTable__text">{order.mkad}</div>;
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {order.mkad || `–`}
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {order.executor.mkad || `–`}
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'priceOfMkad':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.priceOfMkad || '–'}
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {order.executor.priceOfMkad || '–'}
                            </div>
                        );
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {order.priceOfMkad || '–'}
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {order.executor.priceOfMkad || '–'}
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'changedPrice':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.changedPrice)} ₽
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.executor.changedPrice)} ₽
                            </div>
                        );
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.changedPrice)} ₽
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.executor.changedPrice)} ₽
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'finalAmount':
                switch (name) {
                    case 'clients':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.amount)} ₽
                            </div>
                        );
                        break;
                    case 'executors':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.executor.amount)} ₽
                            </div>
                        );
                        break;
                    case 'services':
                        inner = (
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(order.amount)} ₽
                            </div>
                        );
                        break;
                    default:
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.amount)} ₽
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getFormatPrice(order.executor.amount)} ₽
                                    </div>
                                </div>
                            </div>
                        );
                        break;
                }
                break;
            case 'typeOfPay':
                {
                    const getPayInfo = (type) => {
                        if (type === 'executor') {
                            return orderPays.find((item) => item.key === order.executor.typeOfPay)
                                ?.content;
                        }

                        return orderPays.find((item) => item.key === order.typeOfPay)?.content;
                    };

                    if (name === 'all') {
                        inner = (
                            <div className="reportsRegistryTable__colWrap">
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getPayInfo() || '–'}
                                    </div>
                                </div>
                                <div className="reportsRegistryTable__colInner">
                                    <div className="reportsRegistryTable__text">
                                        {getPayInfo('executor') || `–`}
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        inner = (
                            <div className="reportsRegistryTable__text">{getPayInfo() || '–'}</div>
                        );
                    }
                }
                break;
            case 'address':
                inner = <div className="reportsRegistryTable__text">{order.address}</div>;
                break;
            case 'clientAndExecutor':
                inner = (
                    <div className="reportsRegistryTable__colWrap">
                        <div className="reportsRegistryTable__colInner">
                            <div className="reportsRegistryTable__text">{order.nameOfCompany}</div>
                        </div>
                        <div className="reportsRegistryTable__colInner">
                            <div className="reportsRegistryTable__text">
                                {order.executor.companyName || `–`}
                                {order.executor.withCompany && (
                                    <>
                                        {' '}
                                        <span className="_noWrap">
                                            {getUserInfo({
                                                type: 'fullnameShort',
                                                user: order.executor,
                                            })}
                                        </span>
                                    </>
                                )}
                                {order.executor.type === 'driver' &&
                                order.executor.role === 'worker' ? (
                                    <> (Грузчик)</>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'amountDifference':
                {
                    const difference = order.amount - order.executor.amount;

                    if (difference < 1500) {
                        className = '_status1';
                    } else if (difference < 3000) {
                        className = '_status2';
                    } else if (difference < 4500) {
                        className = '_status3';
                    } else if (difference < 6000) {
                        className = '_status4';
                    } else if (difference < 7500) {
                        className = '_status5';
                    } else {
                        className = '_status6';
                    }

                    inner = (
                        <div className="reportsRegistryTable__text">
                            {getFormatPrice(difference)} ₽
                        </div>
                    );
                }
                break;
            case 'serviceName':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {order.serviceCompanyName || '–'}
                    </div>
                );
                break;
            case 'commission':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatPrice(order.commissionAmount)} ₽
                    </div>
                );
                break;
            case 'serviceAmount':
                inner = (
                    <div className="reportsRegistryTable__text">
                        {getFormatPrice(order.tariffAmount)} ₽
                    </div>
                );
                break;
            case 'savedAmount':
                {
                    const savedAmount =
                        order.savedAmount.max.price - order.savedAmount.current.price;

                    const info = (
                        <>
                            <div className="reportsRegistryTable__savedInfo">
                                <p className="reportsRegistryTable__savedInfoNumber">
                                    Заказ №{order.numberOfOrder}
                                </p>
                                <div className="reportsRegistryTable__savedInfoTitle">
                                    Вы сэкономили {getFormatPrice(savedAmount)} ₽
                                </div>
                                <div className="reportsRegistryTable__savedInfoItems _row">
                                    <div className="reportsRegistryTable__savedInfoItem _col _current">
                                        <div className="reportsRegistryTable__savedInfoItemTitle">
                                            {getFormatPrice(order.savedAmount.max.price)} ₽,{' '}
                                            {
                                                orderServiceCompanies.find(
                                                    (item) =>
                                                        item.key === order.savedAmount.max.key,
                                                )?.name
                                            }
                                        </div>
                                        <p className="reportsRegistryTable__savedInfoItemDescription">
                                            Максимальная цена
                                            <br />
                                            за ваш заказ
                                        </p>
                                    </div>
                                    <div className="reportsRegistryTable__savedInfoItem _col _max">
                                        <div className="reportsRegistryTable__savedInfoItemTitle">
                                            {getFormatPrice(order.savedAmount.current.price)} ₽,{' '}
                                            {
                                                orderServiceCompanies.find(
                                                    (item) =>
                                                        item.key === order.savedAmount.current.key,
                                                )?.name
                                            }
                                        </div>
                                        <p className="reportsRegistryTable__savedInfoItemDescription">
                                            Выбранная служба
                                            <br />
                                            доставки на ваш заказ
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    );

                    const stringInfo = renderToStaticMarkup(info);

                    // console.log(stringInfo);

                    inner = (
                        <>
                            <div className="reportsRegistryTable__text">
                                {getFormatPrice(savedAmount)} ₽
                            </div>
                            {savedAmount > 0 && (
                                <div
                                    className="reportsRegistryTable__colInfo"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: stringInfo,
                                            e,
                                            data: { className: '_info _normalCase' },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }}
                                >
                                    <i className="reportsRegistryTable__colInfoIcon _click">
                                        <Icon name="info-2" />
                                    </i>
                                </div>
                            )}
                        </>
                    );
                }
                break;
            default:
                break;
        }

        return (
            <div
                className={`reportsRegistryTable__col _col ${className} _${colName} ${
                    this.colsMany[name].indexOf(colName) !== -1 ? '_many' : ''
                }`}
                key={`${name}${colName}${key}`}
            >
                {inner}
            </div>
        );
    }

    getHeadRowHeight() {
        const headRowDom = this.parent.current?.querySelector('.reportsRegistryTable__row._head');

        return headRowDom?.offsetHeight || 0;
    }

    getInfoHeight() {
        const { contentHeight = 0 } = this.state;
        const contentDom = this.parent.current;

        if (contentDom) {
            const resultHeight = contentDom.offsetHeight - this.getHeadRowHeight() - contentHeight;

            return resultHeight;
        }

        return 0;
    }

    renderRow({ item: order, key }) {
        const { items: orders = [], activeOrder = [] } = this.state;

        const maxPoints = Math.max(...orders.map((orderLoop) => orderLoop.route.length)) || 0;
        const addressesThis = [];
        const resultColsOrder = this.getColsOrder();

        for (let i = order.route.length; i < maxPoints; i++) {
            addressesThis.push(i + 1);
        }

        return (
            <div
                className={`reportsRegistryTable__row _content ${key % 2 === 0 ? '_odd' : ''}`}
                style={{ zIndex: orders.length - key }}
            >
                <div className="reportsRegistryTable__rowInner">
                    {resultColsOrder.map((colName, innerKey) =>
                        this.renderCol({ name: colName, order, key: innerKey }),
                    )}
                    {(activeOrder.includes('addresses') ? order.route : []).map((point, innerKey) =>
                        this.renderCol({
                            name: 'address',
                            order: {
                                address: point.address,
                            },
                            key: innerKey,
                        }),
                    )}
                    {(activeOrder.includes('addresses') ? addressesThis : []).map(
                        (item, innerKey) =>
                            this.renderCol({
                                name: 'address',
                                order: { address: `–` },
                                key: innerKey,
                            }),
                    )}
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getAddresses() {
        const { items: orders = [], activeOrder = [] } = this.state;
        const maxPoints = Math.max(...orders.map((order) => order.route.length)) || 0;
        const addresses = [];

        if (!activeOrder.includes('addresses')) {
            return [];
        }

        for (let i = 0; i < maxPoints; i++) {
            addresses.push(i + 1);
        }

        return addresses;
    }

    getParentForScroll() {
        return this.parent.current?.querySelector('.reportsRegistryTable__inner');
    }

    getItems(isFilter = false) {
        const { name } = this.props;
        const query = this.getQueryForRequest();
        let stringQuery = '';

        query.params.forEach((param) => {
            stringQuery += param.key;
            stringQuery += '=';
            stringQuery += param.value;
            stringQuery += '&';
        });

        return new Promise((resolve) => {
            axios
                .get(`${process.env.REACT_APP_API}/registry?type=${name}&${stringQuery}`, {
                    headers: getHeaders(),
                })
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        const { orders, isLimit, counter } = data;

                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 10);

                        this.setItems(orders, isFilter, isLimit, counter).then(resolve);
                    } else {
                        resolve();

                        handlerErrorRequest(res);
                    }
                });
        });
    }

    getFilterItems() {
        const handlerFilterLoading = (isFilterLoading) =>
            new Promise((resolve) => {
                this.setState(
                    { isFilterLoading, isDisabledScroll: isFilterLoading, counterScroll: 0 },
                    resolve,
                );
            });

        handlerFilterLoading(true).then(() => {
            setTimeout(() => {
                this.getItems(true).then(() => {
                    setTimeout(() => {
                        handlerFilterLoading(false);
                    }, 100);
                });
            }, 300);
        });
    }

    updateCols() {
        const { activeOrder = [] } = this.props;

        this.setState({ activeOrder }, () => {
            this.changeRegistryColsAfterTimerId = setTimeout(() => {
                this.setState({ isChangeCols: false });
            }, 300);
        });
    }

    changeRegistryColsTimerId;

    changeRegistryColsAfterTimerId;

    changeRegistryCols({ detail: { isChangePage, key } }) {
        const { name } = this.props;

        if (key === name) {
            if (isChangePage) {
                const { activeOrder = [] } = this.props;

                this.setState({ activeOrder });
            } else {
                if (this.changeRegistryColsTimerId) {
                    clearTimeout(this.changeRegistryColsTimerId);
                }

                if (this.changeRegistryColsAfterTimerId) {
                    clearTimeout(this.changeRegistryColsAfterTimerId);
                }

                this.setState({ isChangeCols: true }, () => {
                    this.changeRegistryColsTimerId = setTimeout(() => {
                        this.updateCols();
                    }, 300);
                });
            }
        }
    }

    initCols() {
        const { activeOrder } = this.props;

        if (activeOrder?.length > 0 && !this.state.activeOrder) {
            this.setState({ activeOrder });
        }
    }

    getColsOrder() {
        const { activeOrder = [] } = this.state;
        const { name, colsOrder } = this.props;

        return colsOrder[name].filter((item) => activeOrder.includes(item));
    }

    componentDidMount() {
        const { setRequestFilterItems } = this.props;

        super.componentDidMount();

        setRequestFilterItems(this.getFilterItems);

        this.initCols();

        document.addEventListener('changeRegistryCols', this.changeRegistryCols);
    }

    componentDidUpdate() {
        this.initCols();
    }

    componentWillUnmount() {
        document.removeEventListener('changeRegistryCols', this.changeRegistryCols);
    }

    render() {
        const {
            items: orders = [],
            isLimit,
            isDisabledScroll,
            isShowLoaderList,
            isInit,
            isFilterLoading = false,
            updateKey,
            isChangeCols = false,
            activeOrder = [],
        } = this.state;
        const { name } = this.props;
        const addresses = this.getAddresses();
        const resultColsOrder = this.getColsOrder();
        const infoHeight = this.getInfoHeight();

        return (
            <div
                ref={this.parent}
                className={`reportsRegistryTable _${name} ${
                    isInit && !isFilterLoading && !isChangeCols ? '_isInit' : ''
                }`}
            >
                <Animate
                    className="reportsRegistryTable__loader _loader"
                    isShow={!isInit || isFilterLoading || isChangeCols}
                >
                    <div className="reportsRegistryTable__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="reportsRegistryTable__inner">
                    <div
                        className={`reportsRegistryTable__row _head ${isChangeCols ? '_hide' : ''}`}
                    >
                        {resultColsOrder.map((colName, key) =>
                            this.renderHeadCol({ name: colName, key }),
                        )}
                        {addresses.map((item, key) =>
                            this.renderHeadCol({ name: 'address', counter: item, key }),
                        )}
                    </div>
                    <div className="reportsRegistryTable__rowsWrap" key={updateKey}>
                        <ListScroll
                            isInit={isInit}
                            getParent={this.getParentForScroll}
                            callback={this.getMoreItems}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={Infinity}
                            lengthCurrent={orders.length}
                            handlerLoaderList={this.handlerLoaderList}
                            isLimit={isLimit}
                            keyUpdate={orders.length}
                            isDisabledScroll={isDisabledScroll || !isInit}
                        >
                            <ListAbsoluteMain
                                className="reportsRegistryTable__rows"
                                items={orders}
                                renderItem={this.renderRow}
                                classNameItem="reportsRegistryTable__row"
                                prop="resultId"
                                paramsParent={{ width: true }}
                                callback={({ type, params }) => {
                                    if (type === 'parent') {
                                        this.setState({ contentHeight: params.height });
                                    }

                                    this.setHeightManyCols();
                                }}
                                clearStyleElems={[
                                    {
                                        className: '.reportsRegistryTable__colInner',
                                        params: ['height'],
                                    },
                                ]}
                                keyRender={activeOrder.length}
                            />
                        </ListScroll>
                    </div>
                </div>
                <Animate
                    className="reportsRegistryTable__info JSTransVertical"
                    style={{
                        height: infoHeight,
                    }}
                    isShow={
                        isInit && (infoHeight >= 150 || orders.length === 0) && !isFilterLoading
                    }
                    isSmoothShow={true}
                >
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">
                                Это все записи
                                <br />
                                на данный момент
                            </div>
                        </div>
                    </div>
                </Animate>
                <Animate className="reportsRegistryTable__scrollLoader _loaderScroll" isShow={!!isShowLoaderList}>
                    <div className="reportsRegistryTable__scrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(RegistryInner);

RegistryInner.propTypes = {
    name: PropTypes.string,
    setCounterOrders: PropTypes.func,
    filter: PropTypes.object,
    setRequestFilterItems: PropTypes.func,
    activeOrder: PropTypes.array,
};
