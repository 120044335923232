import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import { checkValidate, inputValidate } from '../../functions/inputValidate';

import Editmode from '../../classes/Editmode';
import HandlerFinal from '../../classes/order/Final';
import HandlerServices from '../../classes/order/Services';
import HandlerTariffs from '../../classes/order/Tariffs';

import getHeaders from '../../functions/getHeaders';
import setNotification from '../../functions/setNotification';
import setServerData from '../../functions/setServerData';
import scrollToPosition from '../../functions/scrollToPosition';
import handlerErrorRequest from '../../functions/handlerErrorRequest';

import Edit from '../../components/Edit.jsx';

import Map from '../../components/order-details/Map.jsx';
import Route from '../../components/order-details/Route.jsx';
import Services from '../../components/order-details/Services.jsx';
import Holder from '../../components/order-details/Holder.jsx';
import Other from '../../components/order-details/Other.jsx';
import ServiceCompanies from '../../components/order-details/ServiceCompanies.jsx';

import Animate from '../../components/Animate.jsx';
import Button from '../../components/Button.jsx';
import getUpdateFormData from '../../functions/getUpdateFormData';
import changePage from '../../functions/changePage';
import removeTransition from '../../functions/removeTransition.ts';
import getPageLink from '../../functions/getPageLink';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
        };

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.scrollToCard = this.scrollToCard.bind(this);
        this.handlerErrors = this.handlerErrors.bind(this);
        this.handlerChangedPrice = this.handlerChangedPrice.bind(this);

        this.parent = React.createRef();
    }

    orderItems = ['map', 'route', 'services', 'holder', 'other', 'servicesCompanies'];

    items = {
        map: {
            changedProps: ['pay', 'timeStart', 'timeEnd'],
            render() {
                const { editName } = this.state;
                const {
                    order,
                    handlerRoute,
                    mapInfo,
                    isInitOfMap,
                    ymaps,
                    map,
                    checkNew,
                    infoRoute,
                } = this.props;

                return (
                    <Map
                        order={order}
                        handlerRoute={handlerRoute}
                        mapInfo={mapInfo}
                        isInitOfMap={isInitOfMap}
                        ymaps={ymaps}
                        map={map}
                        editName={checkNew?.() ? 'map' : editName}
                        changeOrder={this.changeOrder}
                        infoRoute={infoRoute}
                    />
                );
            },
        },
        route: {
            changedProps: ['address', 'sk', 'coords', 'ttk', 'addressatInfo', 'code', 'withSms'],
            render() {
                const { editName, errors } = this.state;

                const {
                    order,
                    points,
                    handlerRoute,
                    ymaps,
                    map,
                    isProccessSetRoute,
                    isProccessOptimization,
                    counterSort,
                    newOrder,
                    fakeCallback,
                    cargoList,
                    setHeightPage,
                    getParent,
                    checkNew,
                    isOptimize,
                    getCondForChange,
                } = this.props;

                return (
                    <Route
                        order={order}
                        points={points}
                        handlerRoute={handlerRoute}
                        ymaps={ymaps}
                        map={map}
                        isProccessSetRoute={isProccessSetRoute}
                        isOptimize={isOptimize}
                        isProccessOptimization={isProccessOptimization}
                        counterSort={counterSort}
                        newOrder={newOrder}
                        editName={editName}
                        isDisabled={editName !== 'route' && !checkNew?.()}
                        fakeCallback={fakeCallback}
                        cargoList={cargoList}
                        setHeightPage={setHeightPage}
                        getParent={getParent}
                        checkNew={checkNew}
                        formDataImages={this.formDataImages}
                        handlerEditmode={this.handlerEditmode}
                        getCondForChange={getCondForChange}
                        errors={errors}
                        handlerErrors={this.handlerErrors}
                    />
                );
            },
        },
        services: {
            changedProps: ['services'],
            render() {
                const { editName } = this.state;
                const { order, points, handlerServices, checkNew, handlerSmsService } = this.props;

                return (
                    <Services
                        order={order}
                        points={points}
                        editName={checkNew?.() ? 'services' : editName}
                        isDisabled={editName !== 'services' && !checkNew?.()}
                        handlerServices={handlerServices}
                        isNew={checkNew?.()}
                        handlerSmsService={handlerSmsService}
                    />
                );
            },
        },
        holder: {
            changedProps: ['holder'],
            render() {
                const { editName, errors } = this.state;
                const { order, checkNew } = this.props;

                return (
                    <Holder
                        order={order}
                        editName={checkNew?.() ? 'holder' : editName}
                        isDisabled={editName !== 'holder' && !checkNew?.()}
                        changeOrder={this.changeOrder}
                        isNew={checkNew?.()}
                        errors={errors}
                    />
                );
            },
        },
        other: {
            changedProps: [],
            render() {
                const { editName, editCurrent, isLoad, errors, changedPrice } = this.state;

                const {
                    order,
                    getCondForChange,
                    fakeCallback,
                    checkNew,
                    points,
                    infoRoute,
                    handlerDate,
                    dateOfOrder,
                    times,
                    checkRights,
                } = this.props;

                return (
                    <Other
                        order={order}
                        points={points}
                        editName={editName}
                        editmode={this.editmode}
                        editCurrent={editCurrent}
                        changeOrder={this.changeOrder}
                        handlerEditmode={this.handlerEditmode}
                        isLoad={isLoad}
                        getCondForChange={getCondForChange}
                        fakeCallback={fakeCallback}
                        isNew={checkNew?.()}
                        infoRoute={infoRoute}
                        handlerDate={handlerDate}
                        date={dateOfOrder}
                        times={times}
                        errors={errors}
                        checkRights={checkRights}
                        handlerChangedPrice={this.handlerChangedPrice}
                        changedPrice={changedPrice}
                    />
                );
            },
        },
        tariff: {
            changedProps: ['tariff'],
        },
        chat: {
            changedProps: [],
        },
        servicesCompanies: {
            changedProps: [],
            render() {
                const { editName } = this.state;
                const { order, points, infoRoute } = this.props;

                return (
                    <ServiceCompanies
                        order={order}
                        points={points}
                        handlerEditmode={this.handlerEditmode}
                        editName={editName}
                        changeOrder={this.changeOrder}
                        scrollToCard={this.scrollToCard}
                        infoRoute={infoRoute}
                    />
                );
            },
        },
    };

    formDataImages = new FormData();

    handlerLoad({ isLoad }) {
        return new Promise((resolve) => {
            this.setState({ isLoad }, resolve);
        });
    }

    checkPrevEdit() {
        const { editName } = this.state;
        const { points } = this.props;
        const resultErrors = [];

        if (editName === 'route') {
            points.forEach((point) => {
                if (!point.isComplete) {
                    resultErrors.push(`route-${point._id}-address`);
                }

                ['phone'].forEach((name) => {
                    const value = point.addressatInfo[name]?.value;

                    if (!value || !inputValidate({ name, value })) {
                        resultErrors.push(`route-${point._id}-addressatInfo-${name}`);
                    }
                });
            });

            if (resultErrors.find((error) => error.split('-')[2] === 'address')) {
                setNotification({ notification: 'error-order-empty-routes' });
            }

            if (resultErrors.find((error) => error.indexOf('addressatInfo') !== -1)) {
                setNotification({ notification: 'error-order-empty-addressatInfo' });
            }

            this.handlerErrors({ errors: resultErrors, isSet: true });
        }

        return resultErrors.length === 0;
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id && parentScroll) {
            scrollToPosition({
                position: 'center',
                parent: parentScroll,
                classNameElem: `.orderDetailsMain__itemWrap[data-id="${id}"]`,
            });
        }
    }

    handlerChangedPrice({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ changedPrice: value }, () => {
                    removeTransition({ item: '.orderDetailsCard__tariffPriceInput' });

                    resolve();
                });
            }
        });
    }

    handlerEditmode({ editName }) {
        const { changedPrice } = this.state;
        const { isProccessSetRoute, order, orderSave, setOrder, points, checkServices, infoRoute } =
            this.props;
        const orderUpdate = JSON.parse(JSON.stringify(order));
        const orderData = {};

        const promiseActions = ({ changedProps }) =>
            new Promise((resolveActions) => {
                if (this.state.editName === 'route') {
                    const route = points.map((point) => ({
                        _id: point._id,
                        addressatInfo: {},
                        cargo: point.cargo.map(() => ({
                            items: [],
                        })),
                    }));

                    points.forEach((point, keyPoint) => {
                        changedProps.forEach((prop) => {
                            if (typeof point[prop] === 'object' && !Array.isArray(point[prop])) {
                                Object.keys(point[prop]).forEach((propInner) => {
                                    route[keyPoint][prop][propInner] =
                                        points[keyPoint][prop][propInner];
                                });
                            } else {
                                route[keyPoint][prop] = points[keyPoint][prop];
                            }
                        });

                        point.cargo.forEach((itemCargo, keyCargo) => {
                            ['_id', 'type', 'number', 'counterPlace', 'items', 'comment'].forEach(
                                (propCargo) => {
                                    route[keyPoint].cargo[keyCargo][propCargo] =
                                        points[keyPoint].cargo[keyCargo][propCargo];
                                },
                            );
                        });
                    });

                    orderData.route = route;

                    ['mkad', 'mkadToPoints', 'distance', 'duration'].forEach((propInfo) => {
                        orderData[propInfo] = infoRoute[propInfo];
                    });

                    orderUpdate.route = points;

                    resolveActions();
                } else if (this.state.editName === 'services') {
                    checkServices({
                        services: order.services,
                    }).then((servicesUpdate) => {
                        orderData.services = servicesUpdate;
                        orderUpdate.services = servicesUpdate;

                        resolveActions();
                    });
                } else {
                    changedProps.forEach((prop) => {
                        orderData[prop] = order[prop];
                    });

                    resolveActions();
                }
            });

        return new Promise((resolve) => {
            if (isProccessSetRoute) {
                console.log('proc');
            } else {
                this.handlerLoad({ isLoad: true }).then(() => {
                    if (editName) {
                        this.editmode.handlerEdit({ editName }).then(() => {
                            this.scrollToCard(editName);

                            this.handlerLoad({ isLoad: false }).then(resolve);
                        });
                    } else if (!this.checkPrevEdit()) {
                        this.handlerLoad({ isLoad: false });
                    } else {
                        const currentItem = this.items[this.state.editName];
                        const { changedProps } = currentItem;

                        promiseActions({ changedProps }).then(() => {
                            let isChanged = false;

                            if (this.state.editName === 'route') {
                                if (orderSave.route.length !== points.length) {
                                    isChanged = true;
                                } else {
                                    orderSave.route.forEach((point, keyPoint) => {
                                        changedProps.forEach((propRoute) => {
                                            if (
                                                JSON.stringify(
                                                    orderSave.route[keyPoint][propRoute],
                                                ) !== JSON.stringify(points[keyPoint][propRoute])
                                            ) {
                                                isChanged = true;
                                            }
                                        });

                                        if (point._id !== points[keyPoint]._id) {
                                            isChanged = true;
                                        }

                                        if (point.cargo.length !== points[keyPoint].cargo.length) {
                                            isChanged = true;
                                        }

                                        if (point.withSms !== points[keyPoint].withSms) {
                                            isChanged = true;
                                        }

                                        point.cargo.forEach((itemCargo, keyCargo) => {
                                            [
                                                'type',
                                                'number',
                                                'counterPlace',
                                                'items',
                                                'comment',
                                            ].forEach((propCargo) => {
                                                if (propCargo === 'items') {
                                                    if (
                                                        !points[keyPoint].cargo[keyCargo] ||
                                                        point.cargo[keyCargo].items.length !==
                                                            points[keyPoint].cargo[keyCargo].items
                                                                .length
                                                    ) {
                                                        isChanged = true;
                                                    }

                                                    itemCargo.items.forEach((item, keyItem) => {
                                                        [
                                                            'name',
                                                            'long',
                                                            'width',
                                                            'height',
                                                            'weight',
                                                            'counter',
                                                        ].forEach((propItem) => {
                                                            if (
                                                                item[propItem].toString() !==
                                                                points[keyPoint].cargo[
                                                                    keyCargo
                                                                ].items[keyItem]?.[
                                                                    propItem
                                                                ].toString()
                                                            ) {
                                                                isChanged = true;
                                                            }
                                                        });
                                                    });
                                                } else if (
                                                    !points[keyPoint].cargo[keyCargo] ||
                                                    JSON.stringify(itemCargo[propCargo]) !==
                                                        JSON.stringify(
                                                            points[keyPoint].cargo[keyCargo][
                                                                propCargo
                                                            ],
                                                        )
                                                ) {
                                                    isChanged = true;
                                                }
                                            });
                                        });
                                    });
                                }
                            } else {
                                changedProps.forEach((prop) => {
                                    if (
                                        JSON.stringify(orderSave[prop]) !==
                                        JSON.stringify(orderUpdate[prop])
                                    ) {
                                        isChanged = true;
                                    }
                                });
                            }

                            if (this.state.editName === 'services' && order.systemType === 'crm') {
                                let isSmsChanged = false;

                                points.forEach((point) => {
                                    const savePoint = orderSave.route.find(
                                        (innerPoint) => innerPoint._id === point._id,
                                    );

                                    if (savePoint && point.withSms !== savePoint.withSms) {
                                        isSmsChanged = true;
                                    }
                                });

                                if (isSmsChanged) {
                                    orderData.services = orderData.services.concat(
                                        ...(points.every((innerPoint) => innerPoint.withSms)
                                            ? [{ key: 'sms', value: true }]
                                            : [{ key: 'sms', value: false }]),
                                    );

                                    isChanged = true;
                                }
                            }

                            if (
                                this.state.editName === 'tariff' &&
                                order?.amount !== +changedPrice
                            ) {
                                orderData.changedPrice = changedPrice;

                                isChanged = true;
                            }

                            if (!isChanged) {
                                this.editmode.handlerEdit({ editName }).then(() => {
                                    this.handlerLoad({ isLoad: false }).then(resolve);
                                });
                            } else {
                                const formData = new FormData();

                                formData.set('id', order._id);
                                formData.set('orderData', JSON.stringify(orderData));

                                axios
                                    .patch(`${process.env.REACT_APP_API}/order`, formData, {
                                        headers: getHeaders(),
                                    })
                                    .then((res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({ notification: 'change-order' });

                                            this.editmode.handlerEdit({ editName }).then(() => {
                                                setOrder({
                                                    order: orderUpdate,
                                                    isStatic: true,
                                                }).then(() => {
                                                    this.handlerLoad({ isLoad: false }).then(
                                                        resolve,
                                                    );
                                                });
                                            });
                                        } else {
                                            const { message } = data;

                                            if (message === 'Date is not valide') {
                                                setNotification({
                                                    notification: 'order-date-not-valide',
                                                });
                                            }

                                            this.handlerLoad({ isLoad: false });

                                            handlerErrorRequest(res);
                                        }
                                    });
                            }
                        });
                    }
                });
            }
        });
    }

    handlerServices = new HandlerServices({ context: this });

    handlerFinal = new HandlerFinal({ context: this });

    handlerTariffs = new HandlerTariffs({ context: this });

    getDataOrder() {
        const { order, dateOfOrder, times, points, infoRoute } = this.props;
        const { type, services, tariff, tariffInfo, newHolder } = order;
        const isNds = false;
        const counterTime = Math.ceil(infoRoute.duration / 60);
        const counterWorker = 0;

        const data = {
            ...infoRoute,
            systemType: order.systemType,
            pay: order.pay,
            deliveryType: order.deliveryType,
            times,
            type,
            currentTariff: tariff.id,
            currentConfig: tariff.idOfConfig,
            currentBodywork: tariff.idOfItem,
            customPrice: tariff.customPrice,
            isNds,
            counterTime,
            counterWorkers: counterWorker,
            holder: order.holder,
            comments: order.comments,
            serviceCompany: order.serviceCompany,
            serviceOtherInfo: order.serviceOtherInfo,
            newHolder,
        };

        delete data.routePrev;

        data.route = points.filter((item) => item.isComplete === true);
        data.dateOfOrder = (dateOfOrder && dateOfOrder.toString()) || null;

        data.route.forEach((point, key) => {
            point.cargo.forEach((cargo, keyCargo) => {
                delete data.route[key].cargo[keyCargo].images;
            });
        });

        const settings = {
            systemType: order?.systemType,
            currentConfig: tariff.idOfConfig,
            counterTime,
            counterWorker,
            isNds,
            services,
            typeOfTariff: tariffInfo?.typeConfig,
            type,
            points,
            currentBodywork: tariff.idOfItem,
            onlyIsActive: true,
            currentItem: tariffInfo,
            configurationWorker: tariffInfo?.configurationWorker,
        };

        data.services = [...this.handlerFinal.getInfoServices(settings)];

        data.services.forEach((item, key) => {
            const currentService = services.find((service) => service.key === item.key);

            delete data.services[key].isDelete;
            delete data.services[key].name;

            if (currentService) {
                data.services[key].value = currentService.value;

                if (typeof item.price !== 'number') {
                    data.services[key].price = 0;
                }
            }
        });

        return data;
    }

    setProccessCreate(isProccessCreate) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.isProccessCreate = isProccessCreate;

                return newState;
            }, resolve);
        });
    }

    changeOrder({ props, ...other }) {
        const { changeOrder } = this.props;

        return new Promise((resolve) => {
            changeOrder({ props, ...other }).then(() => {
                this.handlerErrors({ errors: Object.keys(props), isRemove: true }).then(resolve);
            });
        });
    }

    handlerErrors({ errors, isRemove, isSet }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                let resultErrors = JSON.parse(JSON.stringify(newState.errors));

                if (isSet) {
                    resultErrors = errors;
                } else {
                    errors.forEach((error) => {
                        const index = resultErrors.indexOf(error);

                        if (index === -1) {
                            if (!isRemove) {
                                resultErrors.push(error);
                            }
                        } else {
                            resultErrors.splice(index, 1);
                        }
                    });
                }

                newState.errors = resultErrors;

                return newState;
            }, resolve);
        });
    }

    checkOrder() {
        const { errors } = this.state;
        const { points } = this.props;

        return new Promise((resolve) => {
            const order = this.getDataOrder();
            let isSuccess = true;

            this.handlerErrors({ errors }).then(() => {
                const resultErrors = [];

                if (order.newHolder) {
                    const userErrors = [];

                    (order.type === 'juristic' ? ['inn', 'bic'] : ['fullname', 'phone']).forEach(
                        (key) => {
                            const value = order.newHolder[key];

                            if (!value || !checkValidate({ value, type: key })) {
                                userErrors.push(key);

                                setNotification({ notification: `error-${key}-validate` });

                                isSuccess = false;
                            }
                        },
                    );

                    this.handlerErrors({ errors: userErrors });
                } else if (!order.holder) {
                    isSuccess = false;

                    setNotification({ notification: 'error-order-empty-holder' });

                    resultErrors.push('holder');
                }

                if (order.systemType === 'crm' ? !order.currentBodywork : !order.currentTariff) {
                    isSuccess = false;

                    setNotification({ notification: 'error-order-empty-tariff' });

                    resultErrors.push('tariff');
                } else if (!order.dateOfOrder && order.systemType !== 'service') {
                    isSuccess = false;

                    setNotification({ notification: 'error-order-empty-date' });

                    setTimeout(() => {
                        document.dispatchEvent(
                            new CustomEvent('setStateOrderDate', { detail: { isShowDate: true } }),
                        );
                    }, 10);
                }

                if (points.find((point) => !point.isComplete)) {
                    const pointIndex = points.findIndex((point) => !point.isComplete);

                    if (pointIndex !== -1) {
                        if (isSuccess) {
                            document.querySelector(`#input-${pointIndex}-points`).focus();
                        }

                        setNotification({ notification: 'error-order-empty-routes' });
                    }

                    isSuccess = false;
                }

                points.forEach((point) => {
                    if (!point.isComplete) {
                        resultErrors.push(`route-${point._id}-address`);
                    }

                    ['phone'].forEach((name) => {
                        const value = point.addressatInfo[name]?.value;

                        if (!value || !inputValidate({ name, value })) {
                            isSuccess = false;

                            resultErrors.push(`route-${point._id}-addressatInfo-${name}`);
                        }
                    });
                });

                if (resultErrors.find((error) => error.indexOf('addressatInfo') !== -1)) {
                    setNotification({ notification: 'error-order-empty-addressatInfo' });
                }

                this.handlerErrors({ errors: resultErrors });

                resolve(isSuccess);
            });
        });
    }

    createOrder() {
        const { parentScroll, getOrder } = this.props;
        const order = this.getDataOrder();

        const formData = new FormData();

        formData.set('order', JSON.stringify(order));

        // console.log(order);

        this.checkOrder().then((isComplete) => {
            if (isComplete) {
                this.setProccessCreate(true).then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/order`,
                            getUpdateFormData(formData, this.formDataImages),
                            { headers: getHeaders() },
                        )
                        .then((res) => {
                            const { success, data } = res.data;

                            if (success) {
                                const { id } = data;

                                setNotification({ notification: 'create-order' });

                                scrollToPosition({
                                    position: 'top',
                                    parent: parentScroll,
                                });

                                getOrder(id);

                                changePage({
                                    href: getPageLink({
                                        name: 'order-details-main',
                                        ids: { 2: id },
                                    }),
                                });
                            } else {
                                const { message } = data;

                                if (message === 'Not enough amount') {
                                    setNotification({ notification: 'error-corporation-balance' });
                                }

                                if (message === 'Phone already use') {
                                    setNotification({
                                        notification:
                                            'error-registration-already-organization-phone',
                                    });

                                    this.handlerErrors({
                                        errors: ['phone'],
                                    });
                                }

                                if (message === 'Inn already use') {
                                    setNotification({
                                        notification: 'inn-already-use',
                                    });

                                    this.handlerErrors({
                                        errors: ['inn'],
                                    });
                                }

                                handlerErrorRequest(res);
                            }

                            this.setProccessCreate(false);
                        });
                });
            }
        });
    }

    startScroll() {
        const { order, parentScroll } = this.props;

        if (parentScroll && order?.isNew && !this.isStartScroll) {
            this.isStartScroll = true;

            parentScroll.scrollTop = parentScroll.scrollHeight;
        }
    }

    componentDidMount() {
        ['orderServices'].forEach((name) => {
            setServerData(name);
        });

        this.editmode = new Editmode({
            context: this,
        });

        this.startScroll();
    }

    componentDidUpdate() {
        this.startScroll();
    }

    render() {
        const { editName, editCurrent, isLoad, isProccessCreate } = this.state;
        const { getCondForChange, checkNew, order, checkRights } = this.props;
        const isNew = checkNew?.();

        return (
            <div
                ref={this.parent}
                className={`orderDetailsMain _col _parentForEdits ${
                    editName || editCurrent || isNew ? `_edit` : ''
                }`}
            >
                <div className="orderDetailsMain__items _row">
                    {this.orderItems
                        .filter((name) =>
                            order?.systemType === 'service'
                                ? isNew || name !== 'servicesCompanies'
                                : name !== 'servicesCompanies',
                        )
                        .map((name) => (
                            <div
                                className={`orderDetailsMain__itemWrap orderDetailsMain__item _${name}`}
                                key={name}
                                data-id={name}
                            >
                                <div
                                    className={`orderDetailsMain__itemInner _col ${
                                        name !== 'other' ? '_parentForEdit' : ''
                                    } ${!['other', 'route'].includes(name) ? '_editBack' : ''} ${
                                        editName === name || (isNew && getCondForChange({ name }))
                                            ? '_current'
                                            : ''
                                    }`}
                                >
                                    {!isNew &&
                                        checkRights &&
                                        checkRights() &&
                                        name !== 'other' &&
                                        getCondForChange({ name }) && (
                                            <Edit
                                                className="orderDetailsMain__itemEdit"
                                                handlerEditmode={this.handlerEditmode}
                                                editName={editName}
                                                name={name}
                                                isLoader={isLoad}
                                            />
                                        )}
                                    {this.items[name].render.call(this)}
                                </div>
                            </div>
                        ))}
                </div>
                <Animate className="orderDetailsMain__actions" isShow={!!isNew}>
                    <div className="orderDetailsMain__actionsInner _row">
                        <div className="orderDetailsMain__actionsButton">
                            <Button
                                className="_mainNotBorder _medium2Size"
                                onClick={this.createOrder}
                                showLoader={isProccessCreate}
                            >
                                Сохранить заказ
                            </Button>
                        </div>
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Main);

Main.propTypes = {
    order: PropTypes.object,
    orderSave: PropTypes.object,
    points: PropTypes.array,
    mapInfo: PropTypes.object,
    ymaps: PropTypes.object,
    map: PropTypes.object,
    isInitOfMap: PropTypes.bool,
    handlerRoute: PropTypes.object,
    isProccessSetRoute: PropTypes.bool,
    isProccessOptimization: PropTypes.bool,
    counterSort: PropTypes.number,
    newOrder: PropTypes.array,
    changeOrder: PropTypes.func,
    setOrder: PropTypes.func,
    handlerServices: PropTypes.func,
    checkServices: PropTypes.func,
    infoRoute: PropTypes.object,
    getCondForChange: PropTypes.func,
    fakeCallback: PropTypes.func,
    cargoList: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    parentScroll: PropTypes.object,
    levels: PropTypes.array,
    checkNew: PropTypes.func,
    handlerDate: PropTypes.func,
    dateOfOrder: PropTypes.string,
    times: PropTypes.object,
    getOrder: PropTypes.func,
    isOptimize: PropTypes.bool,
    checkRights: PropTypes.func,
    handlerSmsService: PropTypes.func,
};
