import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LogsTable from '../../../../../components/crm/LogsTable.jsx';

class ManualCarsInnerLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    checkInit() {
        const { setHeightPage, isInit } = this.props;

        if (!this.isInit && isInit) {
            this.isInit = true;

            setHeightPage();
        }
    }

    componentDidMount() {
        this.checkInit();
    }

    componentDidUpdate() {
        this.checkInit();
    }

    render() {
        const { car, handlerLoaderList } = this.props;

        return (
            <div className="manualContent _full _isInit _notPadding">
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        {car && (
                            <LogsTable
                                modelName="car"
                                modelId={car._id}
                                handlerLoaderList={handlerLoaderList}
                                inPage={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualCarsInnerLogs);

ManualCarsInnerLogs.propTypes = {
    isInit: PropTypes.bool,
    car: PropTypes.object,
    setHeightPage: PropTypes.func,
    handlerLoaderList: PropTypes.func,
};
