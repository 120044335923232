import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handler(value) {
        const { name, handler, min = 0, max = Infinity, isDisabled } = this.props;

        const clearValue = +value.toString().replace(/[^0-9-]/g, '');

        if (!isDisabled && clearValue >= min && clearValue <= max) {
            handler({ action: 'change', name, value: +clearValue });
        }
    }

    render() {
        const { value, className, isDisabled } = this.props;

        return (
            <div
                className={`counter _row ${className || ''} ${
                    isDisabled !== undefined && !isDisabled ? '_isEdit' : ''
                }`}
            >
                <div className="counter__btn _col _click _prev" onClick={() => this.handler(+value - 1)}>
                    -
                </div>
                <input
                    type="text"
                    className="counter__result"
                    value={value}
                    onChange={({ target }) => this.handler(target.value)}
                    disabled={isDisabled}
                />
                <div className="counter__btn _col _click _next" onClick={() => this.handler(+value + 1)}>
                    +
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Counter);

Counter.propTypes = {
    value: PropTypes.number,
    name: PropTypes.string,
    className: PropTypes.string,
    handler: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    isDisabled: PropTypes.bool,
};
