import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Field from '../../Field.jsx';
import Button from '../../Button.jsx';
import handlerLoading from '../../../functions/handlerLoading';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

class CorporationLimitsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'month',
        };

        this.renderBlock = this.renderBlock.bind(this);
        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    blocksOrder = ['month', 'year'];

    blocks = {
        month: {
            icon: 'corporation-limits-period',
            name: 'В месяц',
        },
        year: {
            icon: 'corporation-limits-period',
            name: 'В год',
        },
    };

    fieldsOrder = ['all', 'SMZ', 'FL', 'IP'];

    fields = {
        all: {
            name: 'ЛИМИТ Общий',
        },
        SMZ: {
            name: 'ЛИМИТ для САМОЗАНЯТЫХ',
        },
        FL: {
            name: 'ЛИМИТ для физ. лиц',
        },
        IP: {
            name: 'ЛИМИТ для ИП',
        },
    };

    handlerLimits({ name, values = [] }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const limits = { ...newState.limits };

                values.forEach(({ value, key }) => {
                    limits[name][key] = value;
                });

                newState.limits = limits;

                return newState;
            }, resolve);
        });
    }

    handlerField(name, { action, name: key, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.handlerLimits({ name, values: [{ key, value }] }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    renderBlock({ prop: key }) {
        const { limits } = this.state;
        const currentItem = limits?.[key];

        return (
            <div className="corporationTariffPopup__contentBlock _row">
                <div className={`corporationTariffPopup__block _full`}>
                    {this.fieldsOrder.map((fieldName) => {
                        const fieldInfo = this.fields[fieldName];

                        return (
                            <div className="corporationTariffPopup__blockField" key={fieldName}>
                                <p className="corporationTariffPopup__blockFieldSupport">
                                    {fieldInfo.name}
                                </p>
                                <div
                                    className="corporationTariffPopup__blockFieldBox"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Field
                                        type="corporationTariff"
                                        keyName="price"
                                        name={fieldName}
                                        value={currentItem?.[fieldName]}
                                        callback={this.handlerField.bind(this, key)}
                                        className="_grey"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    checkChange() {
        const { limits, savedLimits } = this.state;
        let isChange = false;

        if (savedLimits) {
            ['month', 'year'].forEach((name) => {
                const item = limits[name];
                const savedItem = savedLimits[name];

                Object.keys(item).forEach((key) => {
                    if (item[key] !== savedItem[key]) {
                        isChange = true;
                    }
                });
            });
        }

        return isChange;
    }

    save() {
        const { limits, corporationId } = this.state;

        if (this.checkChange()) {
            handlerLoading.call(this, 'save').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/corporation`,
                        { id: corporationId, action: 'handler-limits', limits },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            const { success } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-change-info' });

                                this.setState({ savedLimits: JSON.parse(JSON.stringify(limits)) });

                                this.state.callback();

                                this.hide();
                            }
                        },
                        () => {
                            handlerLoading.call(this, null);
                        },
                    );
            });
        }
    }

    hide() {
        handlerPopup({ name: 'corporationLimitsPopup', isShow: false });
    }

    componentDidMount() {
        const { corporationLimitsPopup } = this.props;
        const limits = JSON.parse(JSON.stringify(corporationLimitsPopup.limits));

        ['month', 'year'].forEach((name) => {
            const item = limits[name];

            Object.keys(item).forEach((key) => {
                if (item[key]) {
                    item[key] = item[key]?.toString().replace(/\./gi, ',');
                }
            });
        });

        this.setState({
            ...corporationLimitsPopup,
            limits,
            savedLimits: JSON.parse(JSON.stringify(limits)),
        });
    }

    render() {
        const { currentBlock, corporationName, loadingKey } = this.state;

        return (
            <div ref={this.parent} className="corporationTariffPopup _limits">
                <div className="corporationTariffPopup__inner">
                    <i
                        className="corporationTariffPopup__close _click"
                        onClick={() => {
                            this.hide();
                        }}
                    >
                        <Icon name="close-circle" />
                    </i>
                    <div className="corporationTariffPopup__head">
                        <div className="corporationTariffPopup__title">Редактировать лимиты</div>
                        <p className="corporationTariffPopup__description">
                            Корпорация {corporationName}
                        </p>
                        <div className="corporationTariffPopup__tabs _row">
                            {this.blocksOrder.map((blockName) => {
                                const block = this.blocks[blockName];

                                return (
                                    <label className="corporationTariffPopup__tab" key={blockName}>
                                        <input
                                            type="radio"
                                            className="corporationTariffPopup__tabInput"
                                            checked={currentBlock === blockName}
                                            onChange={() => {
                                                this.setState({ currentBlock: blockName });
                                            }}
                                        />
                                        <div className="corporationTariffPopup__tabView">
                                            <i className="corporationTariffPopup__tabIcon">
                                                <Icon name={block.icon} />
                                            </i>
                                            {block.name}
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                    <div className="corporationTariffPopup__content">
                        <ListAbsoluteMain
                            className="corporationTariffPopup__contentBlocks"
                            items={[{ key: currentBlock }]}
                            renderItem={this.renderBlock}
                            classNameItem="corporationTariffPopup__contentBlock"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                            isNotParamsItem={true}
                            allItems={this.blocksOrder}
                            currentItemKey={currentBlock}
                            classNames={['_limits']}
                        />
                    </div>
                    <div className="corporationTariffPopup__foot _col">
                        <div className="corporationTariffPopup__button">
                            <Button
                                className="_main _mainNotBorder"
                                onClick={this.save}
                                showLoader={!!loadingKey}
                                isDisabled={!this.checkChange()}
                            >
                                Сохранить изменения
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        corporationLimitsPopup: state.corporationLimitsPopup,
    };
}

export default connect(mapStateToProps)(CorporationLimitsPopup);

CorporationLimitsPopup.propTypes = {
    corporationLimitsPopup: PropTypes.object,
};
