import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import removeTransition from '../../functions/removeTransition.ts';

import PointCargo from './PointCargo.jsx';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';

class PointCargos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeight = this.setHeight.bind(this);

        this.parent = React.createRef();
    }

    setHeight() {
        return new Promise((resolve) => {
            const { cargo } = this.props;
            const heights = [];

            if (cargo) {
                cargo.forEach((item, key) => {
                    const cargoElem = this.parent.current.querySelector(
                        `.orderPoint__cargoItem[data-key="${key}"]`,
                    );
                    const { clientHeight: heightElem } = cargoElem;

                    heights.push(heightElem);
                });

                this.setState((state) => {
                    const newState = { ...state };

                    newState.heights = heights;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    checkCompleteCargoItems() {
        const { cargo, handlerRoute } = this.props;

        return (
            cargo.every(
                (item) => item.items.filter((elem) => !elem.isDelete).length || item.isDelete,
            ) &&
            cargo
                .reduce(
                    (prev, cur) => prev.concat(...cur.items.filter((elem) => !elem.isDelete)),
                    [],
                )
                .every(handlerRoute.checkCompleteCargoItem)
        );
    }

    componentDidMount() {
        this.setHeight();
        removeTransition({ item: '.orderPoint__cargoItems' });
        removeTransition({ item: '.orderPoint__cargoItem' });

        const observer = new MutationObserver(() => {
            this.setHeight();
        });

        observer.observe(this.parent.current, {
            childList: true,
            subtree: true,
            characterDataOldValue: true,
        });
    }

    componentDidUpdate() {
        const { point, handlerDrop } = this.props;
        const itemsIsComplete = this.checkCompleteCargoItems();

        if (itemsIsComplete !== this.itemsIsComplete) {
            this.itemsIsComplete = itemsIsComplete;

            this.setHeight().then(() => {
                handlerDrop(point.showDrop);
            });
        }
    }

    render() {
        const { heights } = this.state;
        const {
            cargo,
            point,
            points,
            numberOfPoint,
            handler,
            handlerRoute,
            handlerDrop,
            isDisabled,
            cargoList,
            formDataImages,
            device,
            type,
            isEditmode,
            isNew,
            order,
        } = this.props;
        const heightAll =
            heights
                ?.filter((item, key) => !cargo[key]?.isDelete)
                .reduce((prev, cur) => prev + cur, 0) || 0;
        const heightAddButton =
            this.parent.current?.querySelector('.orderPoint__cargoAdd')?.clientHeight || 0;
        const itemsIsComplete = !isDisabled && this.checkCompleteCargoItems();

        const heightAddButtonResult = itemsIsComplete ? heightAddButton : 0;

        return (
            <div ref={this.parent} className="orderPoint__cargo">
                <div
                    className="orderPoint__cargoInner"
                    style={{ height: `${heightAll + heightAddButtonResult}px` }}
                >
                    <div className="orderPoint__cargoItems" style={{ height: `${heightAll}px` }}>
                        {cargo.map((itemCargo, keyCargo) => {
                            const findIndexOfDelete = cargo.findIndex((item) => item.isDelete);
                            const topItem = heights
                                ?.filter(
                                    (item, keyThis) =>
                                        !cargo[keyThis]?.isDelete && keyThis < keyCargo,
                                )
                                .reduce((prev, cur) => prev + cur, 0);

                            const deltaNumber =
                                findIndexOfDelete !== -1 && findIndexOfDelete < keyCargo ? 1 : 0;

                            return (
                                <div
                                    className={`orderPoint__cargoItem ${
                                        itemCargo.stateOfShow === 1 ? '_show' : ''
                                    } ${itemCargo.isDelete ? '_delete' : ''}`}
                                    key={itemCargo.id}
                                    data-key={keyCargo}
                                    style={{ top: `${topItem}px` }}
                                >
                                    <PointCargo
                                        points={points}
                                        point={point}
                                        cargo={itemCargo}
                                        numberOfPoint={numberOfPoint}
                                        number={keyCargo - deltaNumber}
                                        handler={(res) => handler({ ...res, key: keyCargo })}
                                        setHeightCargo={this.setHeight}
                                        handlerRoute={handlerRoute}
                                        handlerDrop={handlerDrop}
                                        isDisabled={isDisabled}
                                        cargoList={cargoList}
                                        formDataImages={formDataImages}
                                        type={type}
                                        isEditmode={isEditmode}
                                        isNew={isNew}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {device !== 'mobile' && order?.systemType !== 'service' && (
                        <Animate className="orderPoint__cargoAdd _col" isShow={itemsIsComplete}>
                            <div className="orderPoint__cargoAddButton">
                                <div
                                    className="orderPointAdd _row _click"
                                    onClick={() => {
                                        handlerRoute.handlerCargo({
                                            action: 'add',
                                            keyOfPoint: numberOfPoint,
                                            handlerDrop,
                                        });
                                    }}
                                >
                                    <i className="orderPointAdd__icon">
                                        <Icon name="add-circle" />
                                    </i>
                                    Добавить груз
                                </div>
                            </div>
                        </Animate>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PointCargos);

PointCargos.propTypes = {
    points: PropTypes.array,
    point: PropTypes.object,
    cargo: PropTypes.array,
    numberOfPoint: PropTypes.number,
    handler: PropTypes.func,
    handlerRoute: PropTypes.object,
    handlerDrop: PropTypes.func,
    setHeightItems: PropTypes.func,
    isDisabled: PropTypes.bool,
    cargoList: PropTypes.array,
    formDataImages: PropTypes.object,
    device: PropTypes.string,
    type: PropTypes.string,
    isEditmode: PropTypes.bool,
    isNew: PropTypes.bool,
    order: PropTypes.object,
};
