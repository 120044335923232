import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getCargoInfo from '../../functions/order/getCargoInfo';

import Points from '../order/Points.jsx';

import AnimateChange from '../AnimateChange.jsx';
import Icon from '../Icon.jsx';
import OptimizationBtn from '../order/OptimizationBtn.jsx';

class DetailsRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            order,
            points,
            handlerRoute,
            ymaps,
            map,
            isProccessSetRoute,
            counterSort,
            isOptimize,
            isProccessOptimization,
            newOrder,
            isDisabled,
            fakeCallback,
            cargoList,
            setHeightPage,
            getParent,
            checkNew,
            formDataImages,
            handlerEditmode,
            getCondForChange,
            errors,
            handlerErrors,
        } = this.props;

        const { weight, volume } = getCargoInfo({ points });

        return (
            <div
                className={`orderDetailsRoute ${
                    !getCondForChange({ name: 'route' }) ? '_notRights' : ''
                }`}
            >
                <div className="orderDetailsRoute__inner">
                    <Points
                        order={order}
                        handlerRoute={handlerRoute}
                        ymaps={ymaps}
                        map={map}
                        points={points}
                        isProccessSetRoute={isProccessSetRoute}
                        counterSort={counterSort}
                        isProccessOptimization={isProccessOptimization}
                        newOrder={newOrder}
                        isDisabled={isDisabled}
                        type="details"
                        fakeCallback={fakeCallback}
                        cargoList={cargoList}
                        setHeightPage={setHeightPage}
                        getParent={getParent}
                        isNew={checkNew?.()}
                        formDataImages={formDataImages}
                        errors={errors}
                        handlerErrors={handlerErrors}
                    />
                </div>
                <div className="orderDetailsRoute__info _editBack _row">
                    <div className="orderDetailsRoute__infoBlock _row _cargo">
                        <div className="orderDetailsRoute__infoCargo _row">
                            <div className="orderDetailsRoute__infoCargoItem _row">
                                Общий вес:
                                <AnimateChange
                                    className="orderDetailsRoute__infoCargoItemInner"
                                    prop={weight}
                                    type="_translateMedium"
                                >
                                    <div className="orderDetailsRoute__infoCargoItemValue">
                                        {weight}кг
                                    </div>
                                </AnimateChange>
                            </div>
                            <div className="orderDetailsRoute__infoCargoItem _row">
                                Общий объём:{' '}
                                <AnimateChange
                                    className="orderDetailsRoute__infoCargoItemInner"
                                    prop={volume}
                                    type="_translateMedium"
                                >
                                    <div className="orderDetailsRoute__infoCargoItemValue">
                                        {volume}м³
                                    </div>
                                </AnimateChange>
                            </div>
                        </div>
                    </div>
                    <div className="orderDetailsRoute__infoBlock _row _optimize">
                        <div className="orderDetailsRoute__infoOptimization">
                            <OptimizationBtn
                                className="_details"
                                points={points}
                                isOptimize={isOptimize}
                                isProccessOptimization={isProccessOptimization}
                                handler={() => {
                                    if (getCondForChange({ name: 'route' })) {
                                        if (!checkNew?.()) {
                                            handlerEditmode({ editName: 'route' });
                                        }

                                        handlerRoute?.optimization();
                                    }
                                }}
                                isDisabled={[
                                    'in-proccess',
                                    'wait-close',
                                    'complete',
                                    'cancel',
                                ].includes(order.status)}
                            />
                        </div>
                    </div>
                    <div className="orderDetailsRoute__infoBlock _row _actions">
                        <div className="orderDetailsRoute__infoAdd">
                            <div
                                className="orderPointAdd _row _details _click"
                                onClick={() => {
                                    if (getCondForChange({ name: 'route' })) {
                                        if (!checkNew?.()) {
                                            handlerEditmode({ editName: 'route' });
                                        }

                                        handlerRoute.addPoint({}).then(() => null);
                                    }
                                }}
                            >
                                <i className="orderPointAdd__icon">
                                    <Icon name="add-circle" />
                                </i>
                                Добавить адрес
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DetailsRoute);

DetailsRoute.propTypes = {
    order: PropTypes.object,
    points: PropTypes.array,
    handlerRoute: PropTypes.object,
    ymaps: PropTypes.object,
    map: PropTypes.object,
    isProccessSetRoute: PropTypes.bool,
    counterSort: PropTypes.number,
    isOptimize: PropTypes.bool,
    isProccessOptimization: PropTypes.bool,
    newOrder: PropTypes.array,
    isDisabled: PropTypes.bool,
    fakeCallback: PropTypes.func,
    cargoList: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    formDataImages: PropTypes.object,
    handlerEditmode: PropTypes.func,
    getCondForChange: PropTypes.func,
    errors: PropTypes.array,
    handlerErrors: PropTypes.func,
};
