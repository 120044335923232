import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../../manual/card/Head.jsx';
import Field from '../../manual/card/Field.jsx';

class JoinsDocsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { joinDoc } = this.props;

        if (joinDoc) {
            return {
                description: joinDoc.name,
            };
        }

        return {
            description: '–',
        };
    }

    fields = [{ name: 'name' }, { name: 'filesCounter' }];

    render() {
        const {
            type,
            joinDoc,
            checkEditmode = () => false,
            errors = [],
            changeJoinDoc,
        } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`manualCard _joinDocsMain`}>
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields.map((field) => {
                            const { name, isReadOnly } = field;
                            const { [name]: value } = joinDoc;

                            return (
                                <div className={`manualCard__field _${name}`} key={name}>
                                    <Field
                                        value={value}
                                        type="joinDoc"
                                        name={name}
                                        handler={changeJoinDoc}
                                        group="joinDocs"
                                        isDisabled={!checkEditmode() || type === 'preview'}
                                        isReadOnly={isReadOnly}
                                        isError={errors.indexOf(name) !== -1}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(JoinsDocsMain);

JoinsDocsMain.propTypes = {
    joinDoc: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    changeJoinDoc: PropTypes.func,
    type: PropTypes.string,
};
