import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../manual/card/Head.jsx';
import Field from '../manual/card/Field.jsx';
import getUserInfo from '../../../functions/getUserInfo';

class ProfileMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getDescriptionInfo() {
        const { user } = this.props;

        if (user) {
            return {
                description: getUserInfo({ type: 'name', user }),
            };
        }

        return {
            description: '–',
        };
    }

    fields = [
        { name: 'secondName' },
        { name: 'firstName' },
        { name: 'thirdName' },
        { name: 'email', isReadOnly: true },
        { name: 'phone' },
        { name: 'password' },
    ];

    render() {
        const {
            type,
            user,
            checkEditmode = () => false,
            errors = [],
            changeUser,
            inner,
        } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className={`manualCard _profile`}>
                <div className="manualCard__head _row">
                    <Head title="Личные данные" description={description} />
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__fields _row">
                        {this.fields
                            .filter((field) => !inner || field.name !== 'password')
                            .map((field) => {
                                const { name, isReadOnly } = field;
                                const { [name]: value } = user;

                                return (
                                    <div className={`manualCard__field _${name}`} key={name}>
                                        <Field
                                            value={value}
                                            type="profile"
                                            name={name}
                                            handler={changeUser}
                                            group="profile"
                                            isDisabled={!checkEditmode() || type === 'preview'}
                                            isReadOnly={isReadOnly}
                                            isError={errors.indexOf(name) !== -1}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ProfileMain);

ProfileMain.propTypes = {
    user: PropTypes.object,
    title: PropTypes.string,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    changeUser: PropTypes.func,
    type: PropTypes.string,
    deleteCompany: PropTypes.func,
    inner: PropTypes.bool,
};
