export default function checkPaysErrors({ pay }) {
    return (
        (pay.errors?.length ||
            pay.transactionErrors?.length ||
            pay.status === 'error' ||
            pay.isError ||
            pay.status === 'failed') &&
        pay.status !== 'completed'
    );
}
