import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';
// import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import TableHead from '../../../components/crm/manual/TableHead.jsx';
import MvdMain from './mvd/Main.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import WindowActions from '../../../components/WindowActions.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

import getHeaders from '../../../functions/getHeaders';
import downloadFile from '../../../functions/downloadFile';

class JoinsMvd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderWindowsEditActions = this.renderWindowsEditActions.bind(this);

        this.parent = React.createRef();
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'executorsMvds' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            if (key === 'pay') {
                newState.editName = value?.name || '';
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    renderWindowsEditActions({ items, executorId, executorName, popupCallback }) {
        const callback = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'edit') {
                    handlerPopup({
                        name: 'mvdPopup',
                        isShow: true,
                        executorId,
                        callback: popupCallback,
                    });

                    resolve();
                } else if (key === 'download') {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/download-executor-mvd`,
                            { executorId },
                            { responseType: 'blob', headers: getHeaders() },
                        )
                        .then((res) => {
                            res.data.text().then(
                                (result) => {
                                    try {
                                        const { success } = JSON.parse(result);

                                        resolve(success);
                                    } catch (error) {
                                        downloadFile({
                                            filename: `Уведомление_МВД_${executorName}.xlsx`,
                                            data: res.data,
                                        });

                                        resolve();
                                    }
                                },
                                () => null,
                            );
                        });
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'executorMvd',
                        modelId: executorId,
                    });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'mvdActions',
                    });
                }}
            />
        );
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
        });
    }

    render() {
        const { filter, infoHead } = this.state;
        const { counterChangePage, disabled } = this.props;
        const counter = infoHead?.counter || 0;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="mvdActions" renderContent={this.renderWindowsEditActions} />
                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Выплаты"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <div className="widget__headNameItemContent">
                                    Уведомления МВД
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                        classNameParent="_parent"
                                    >
                                        <>({counter})</>
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="joinsMvd"
                                    getParent={() => this.parent.current}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__page _wide _show _full">
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <MvdMain
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    setInfoHead={this.setInfoHead}
                                    getParent={() => this.parent.current}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JoinsMvd;

JoinsMvd.propTypes = {
    setHeightPage: PropTypes.func,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    executorId: PropTypes.string,
    disabled: PropTypes.bool,
};
