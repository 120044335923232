import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPays from '../../../../requests/getPays';

import TableInfo from '../../../../components/crm/manual/TableInfo.jsx';
import Table from '../../../../components/crm/manual/Table.jsx';

class ManualTagsMain extends Table {
    constructor(props) {
        super(props);
        this.state = {};
    }

    name = 'payGroups';

    stepCounter = 20;

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getPays(query).then(({ pays, isLimit, counter }) => {
                this.setItems(pays, false, isLimit, counter).then(() => {
                    resolve();
                });
            });
        });
    }

    render() {
        const { isReady, items, isLoadingFilter = false, isLimit, isDisabledScroll } = this.state;

        return (
            <div ref={this.parent} className={`widget _notPadding _ready`}>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="payGroups"
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={!isReady || isLoadingFilter}
                                isWrap={true}
                                isRowHeight={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualTagsMain);

ManualTagsMain.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
};
