import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setServerData from '../../../../../functions/setServerData';

import CardField from '../../card/Field.jsx';
import ParamsPreview from '../../card/tariff/ParamsPreview.jsx';
import AnimateChange from '../../../../AnimateChange.jsx';
import Animate from '../../../../Animate.jsx';
import Field from '../../../../Field.jsx';
import ActionChange from '../../../../ActionChange.jsx';

const tariffConfigFields = require('../../../../../infos/crm/tariffConfigFields.json');

class Item extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderService = this.renderService.bind(this);

        this.parent = React.createRef();
    }

    getServicesInfo() {
        const { type, serverData } = this.props;
        const { tariffs } = serverData;

        return tariffs ? Object.keys(tariffs.services[type]) : [];
    }

    valuesServices = {};

    renderService({ key }) {
        const { _id, type, serverData, services, isEdit, handlerConfigurationItem } = this.props;
        const service = services.find((serviceLoop) => serviceLoop.key === key);
        const { tariffs } = serverData;
        const serviceInfo = tariffs?.services[type][key];
        let value = service?.value ?? '';

        if (service) {
            this.valuesServices[key] = value;
        } else {
            value = this.valuesServices[key];
        }

        return (
            <div
                className={`manualTariffsConfigurationItem__service _row _${key} _${serviceInfo?.type} ${
                    !service && !isEdit ? '_disabled' : ''
                } ${isEdit ? '_edit' : ''}`}
            >
                <p className="manualTariffsConfigurationItem__serviceSupport">
                    {serviceInfo?.support}
                </p>
                <div className="manualTariffsConfigurationItem__serviceBox">
                    <ActionChange
                        className="manualTariffsConfigurationItem__serviceAction"
                        isShow={isEdit}
                        isSmoothShow={true}
                        isHas={!!service}
                        onClick={() => {
                            handlerConfigurationItem({
                                type: 'services',
                                action: service ? 'delete' : 'add',
                                name: key,
                                value: serviceInfo?.type === 'text' ? 0 : null,
                            });
                        }}
                    />
                    <Animate
                        className="manualTariffsConfigurationItem__serviceValue _row _disabled"
                        isShow={!service && !isEdit}
                    >
                        Нет
                    </Animate>
                    <Animate
                        className="manualTariffsConfigurationItem__serviceValue _row "
                        isShow={!!service && (!isEdit || serviceInfo?.type === 'text')}
                    >
                        {serviceInfo?.type === 'radio' && 'Да'}
                        {serviceInfo?.type === 'text' && (
                            <Field
                                id={`${_id}-${key}`}
                                name={key}
                                keyName="price"
                                support=""
                                value={value}
                                callback={(props) =>
                                    new Promise((resolve) => {
                                        handlerConfigurationItem({
                                            type: 'services',
                                            ...props,
                                        }).then(resolve);
                                    })
                                }
                                className={`_notBack _center _colorDark2 _notPadding`}
                                isDisabled={!isEdit}
                                isEditmode={true}
                            />
                        )}
                    </Animate>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { setStateConfiguration } = this.props;

        setServerData('tariffs').then(
            () => {
                setStateConfiguration();
            },
            () => null,
        );
    }

    render() {
        const {
            _id,
            parameters,
            type,
            isShowServices,
            handlerServices,
            services,
            isEdit,
            handlerConfigurationItem,
            errors,
        } = this.props;
        const servicesInfo = this.getServicesInfo();

        // console.log(isInit)

        return (
            <div
                ref={this.parent}
                className={`manualTariffsConfigurationItem ${isEdit ? '_isEdit' : ''}`}
            >
                <div className="manualTariffsConfigurationItem__content">
                    <div className={`manualCard _tariffParams _${type}`}>
                        <div className="manualCard__content">
                            <div className="manualCard__params _row">
                                <div className="manualCard__paramsItem _preview">
                                    <ParamsPreview
                                        id={_id}
                                        type={type}
                                        parameters={parameters}
                                        isDisabled={!isEdit}
                                        handler={(props) =>
                                            new Promise((resolve) => {
                                                handlerConfigurationItem({
                                                    type: 'parameters',
                                                    ...props,
                                                }).then(resolve);
                                            })
                                        }
                                        errors={errors}
                                    />
                                </div>
                                <div className="manualCard__paramsItem _fields">
                                    <div className="manualCard__fields _row">
                                        {tariffConfigFields[type].items.map((field) => {
                                            const { name, keyField, isReadOnly } = field;
                                            const { [name]: value } = parameters;
                                            let items;

                                            return (
                                                <div
                                                    className={`manualCard__field _${name}`}
                                                    key={name}
                                                >
                                                    <CardField
                                                        value={value}
                                                        type="tariff"
                                                        name={name}
                                                        keyField={keyField}
                                                        handler={(props) =>
                                                            new Promise((resolve) => {
                                                                handlerConfigurationItem({
                                                                    type: 'parameters',
                                                                    ...props,
                                                                }).then(resolve);
                                                            })
                                                        }
                                                        group={`tariffConfigItem-${_id}`}
                                                        isDisabled={!isEdit}
                                                        isReadOnly={isReadOnly}
                                                        items={items}
                                                        classNameField="_center"
                                                        isError={errors?.[name]}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`manualTariffsConfigurationItem__services ${
                        isShowServices ? '_show' : ''
                    }`}
                >
                    <div className="manualTariffsConfigurationItem__servicesHead _col">
                        <div
                            className="manualTariffsConfigurationItem__servicesHeadInner _row _click"
                            onClick={() => handlerServices()}
                        >
                            Доп. услуги:
                            <AnimateChange
                                className="manualTariffsConfigurationItem__servicesHeadInnerItem"
                                type="_translateMedium"
                                prop={services.length}
                            >
                                {`${services.length}`}
                            </AnimateChange>
                            шт.
                        </div>
                    </div>
                    <div className="manualTariffsConfigurationItem__servicesContent">
                        <div className="manualTariffsConfigurationItem__servicesContentInner">
                            <div className="manualTariffsConfigurationItem__servicesContentBox _row">
                                {servicesInfo.map((key) => this.renderService({ key }))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(Item);

Item.propTypes = {
    _id: PropTypes.string,
    parameters: PropTypes.object,
    type: PropTypes.string,
    serverData: PropTypes.object,
    setStateConfiguration: PropTypes.func,
    handlerServices: PropTypes.func,
    isShowServices: PropTypes.bool,
    services: PropTypes.array,
    isEdit: PropTypes.bool,
    handlerConfigurationItem: PropTypes.func,
    parentScroll: PropTypes.object,
    errors: PropTypes.object,
};
