import checkAdminCorporation from '../functions/crm/checkAdminCorporation';
import getCurrentCorporation from '../functions/crm/getCurrentCorporation';

import getPermissions from '../functions/crm/getPermissions';
import { getCookie } from '../functions/handlerCookies';

const pages = {
    crm: [
        {
            links: [],
            name: 'corporation',
            group: 'cabinet',
            getRedirect() {
                return {
                    name: 'auth',
                };
            },
            getCond({ user }) {
                return {
                    condition: user || getCookie(process.env.REACT_APP_HASH),
                };
            },
        },
        {
            links: [undefined, ''],
            name: 'corporation-index',
            level: 1,
            parentName: 'corporation',
        },
        {
            links: ['corporations'],
            name: 'corporations',
            level: 1,
            parentName: 'corporation',
            contentOfLink: 'Корпорации',
            getCond({ user }) {
                return {
                    condition:
                        checkAdminCorporation({ user }) &&
                        getPermissions(user, {
                            key: 'corporations',
                            items: [{ key: 'main', rules: ['read'] }],
                        }),
                };
            },
        },
        {
            links: ['', undefined],
            name: 'corporations-index',
            level: 2,
            parentName: 'corporations',
        },
        {
            links: [],
            name: 'corporations-inner',
            level: 2,
            parentName: 'corporations',
        },
        {
            links: ['', undefined],
            name: 'corporations-inner-main',
            level: 3,
            parentName: 'corporations-inner',
            contentOfLink: 'Детали',
        },
        {
            links: ['operations'],
            name: 'corporations-inner-operations',
            level: 3,
            parentName: 'corporations-inner',
            contentOfLink: 'Операции',
        },
        {
            links: ['docs'],
            name: 'corporations-inner-docs',
            level: 3,
            parentName: 'corporations-inner',
            contentOfLink: 'Документы',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'docs', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['users'],
            name: 'corporations-inner-users',
            level: 3,
            parentName: 'corporations-inner',
            contentOfLink: 'Сотрудники',
        },
        {
            links: ['logs'],
            name: 'corporations-inner-logs',
            level: 3,
            parentName: 'corporations-inner',
            contentOfLink: 'Логи',
            getCond({ user }) {
                return {
                    condition: checkAdminCorporation({ user }),
                };
            },
        },
        {
            links: ['joins'],
            name: 'joins',
            contentOfLink: 'Подключение',
            level: 1,
            parentName: 'corporation',
            getRedirect({ user }) {
                if (!user) {
                    return { name: 'auth' };
                }

                return { name: 'corporation' };
            },
            getCond({ user }) {
                return {
                    condition: getPermissions(user, { key: 'joins' }),
                };
            },
        },
        {
            links: ['invites'],
            name: 'joins-invites',
            contentOfLink: 'Приглашения',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'invites', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['scripts'],
            name: 'joins-scripts',
            contentOfLink: 'Сценарии',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'scripts', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'joins-scripts-main',
            className: '_wide',
            level: 3,
            parentName: 'joins-scripts',
        },
        {
            links: [],
            name: 'joins-scripts-inner',
            contentOfLink: '+ Новый сценарий',
            level: 3,
            parentName: 'joins-scripts',
        },
        {
            links: ['main', '', undefined],
            name: 'joins-scripts-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'joins-scripts-inner',
        },
        {
            links: ['logs'],
            name: 'joins-scripts-inner-logs',
            contentOfLink: 'Логи',
            className: '_wide',
            level: 4,
            parentName: 'joins-scripts-inner',
        },
        {
            links: ['templates'],
            name: 'joins-templates',
            contentOfLink: 'Шаблоны',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'templates', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'joins-templates-main',
            className: '_wide',
            level: 3,
            parentName: 'joins-templates',
        },
        {
            links: [],
            name: 'joins-templates-inner',
            contentOfLink: '+ Добавить шаблон',
            level: 3,
            parentName: 'joins-templates',
        },
        {
            links: ['main', '', undefined],
            name: 'joins-templates-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'joins-templates-inner',
        },
        {
            links: ['contracts'],
            name: 'joins-contracts',
            contentOfLink: 'Договоры',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'contracts', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'joins-contracts-main',
            level: 3,
            parentName: 'joins-contracts',
        },
        {
            links: ['docs'],
            name: 'joins-docs',
            contentOfLink: 'Документы',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'docs', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'joins-docs-main',
            className: '_wide',
            level: 3,
            parentName: 'joins-docs',
        },
        {
            links: [],
            name: 'joins-docs-inner',
            contentOfLink: '+ Добавить документ',
            level: 3,
            parentName: 'joins-docs',
        },
        {
            links: ['main', '', undefined],
            name: 'joins-docs-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'joins-docs-inner',
        },
        {
            links: ['mvd'],
            name: 'joins-mvd',
            contentOfLink: 'Уведомления МВД',
            level: 2,
            parentName: 'joins',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'joins',
                        items: [{ key: 'mvd', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['pays'],
            name: 'pays',
            contentOfLink: 'Выплаты',
            level: 1,
            parentName: 'corporation',
            getRedirect({ user }) {
                if (!user) {
                    return { name: 'auth' };
                }

                return { name: 'corporation' };
            },
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                    }),
                };
            },
        },
        {
            links: ['main'],
            name: 'pays-main',
            contentOfLink: 'Акты',
            level: 2,
            parentName: 'pays',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                        items: [{ key: 'acts', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: [],
            name: 'pays-main-inner',
            level: 3,
            parentName: 'pays-main',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                        items: [{ key: 'acts', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['groups'],
            name: 'pays-groups',
            contentOfLink: 'Реестр актов',
            level: 2,
            parentName: 'pays',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                        items: [{ key: 'acts', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'pays-groups-main',
            className: '_wide',
            level: 3,
            parentName: 'pays-groups',
        },
        {
            links: [],
            name: 'pays-groups-inner',
            level: 3,
            parentName: 'pays-groups',
        },
        {
            links: ['main', '', undefined],
            name: 'pays-groups-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'pays-groups-inner',
        },
        {
            links: ['logs'],
            name: 'pays-groups-inner-logs',
            contentOfLink: 'Логи',
            className: '_wide',
            level: 4,
            parentName: 'pays-groups-inner',
        },
        {
            links: ['tax'],
            name: 'pays-tax',
            contentOfLink: 'Налоговая копилка',
            className: '_wide',
            level: 2,
            parentName: 'pays',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                        items: [{ key: 'tax', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['limits'],
            name: 'pays-limits',
            contentOfLink: 'Лимиты',
            className: '_wide',
            level: 2,
            parentName: 'pays',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'pays',
                        items: [{ key: 'limits', rules: ['read'] }],
                    }),
                };
            },
        },
        // {
        //     links: ['orders'],
        //     name: 'orders',
        //     contentOfLink: 'Все заказы',
        //     level: 1,
        //     parentName: 'corporation',
        //     getRedirect({ user }) {
        //         if (!user) {
        //             return { name: 'auth' };
        //         }

        //         return { name: 'corporation' };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'orders',
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['new'],
        //     name: 'orders-new',
        //     contentOfLink: 'Новые',
        //     isGroupOrders: true,
        //     level: 2,
        //     parentName: 'orders',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'orders',
        //                 items: [{ key: 'main', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['in-proccess'],
        //     name: 'orders-in-proccess',
        //     contentOfLink: 'В работе',
        //     isGroupOrders: true,
        //     level: 2,
        //     parentName: 'orders',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'orders',
        //                 items: [{ key: 'main', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['complete'],
        //     name: 'orders-complete',
        //     contentOfLink: 'Закрытые',
        //     isGroupOrders: true,
        //     level: 2,
        //     parentName: 'orders',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'orders',
        //                 items: [{ key: 'main', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['monitor'],
        //     name: 'monitor',
        //     contentOfLink: 'Мониторинг',
        //
        //     getRedirect({ user }) {
        //         if (!user) {
        //             return { name: 'auth' };
        //         }

        //         return { name: 'corporation' };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: user || getCookie(process.env.REACT_APP_HASH),
        //         };
        //     },
        // },
        // {
        //     links: ['map'],
        //     name: 'monitor-map',
        //     contentOfLink: 'Карта водителей',
        //     level: 2,
        // parentName: 'monitor',
        // },
        // {
        //     links: ['schedule'],
        //     name: 'monitor-schedule',
        //     contentOfLink: 'График работы',
        //     level: 2,
        // parentName: 'monitor',
        // },
        // {
        //     links: ['reports'],
        //     name: 'reports',
        //     contentOfLink: 'Отчёты',
        //     isPopup: true,
        //     level: 1,
        //     parentName: 'corporation',
        //     getRedirect({ user }) {
        //         if (!user) {
        //             return { name: 'auth' };
        //         }

        //         return { name: 'corporation' };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'reports',
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['registry'],
        //     name: 'reports-registry',
        //     contentOfLink: 'Реестры',
        //     level: 2,
        //     parentName: 'reports',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'reports',
        //                 items: [{ key: 'system-orders', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['', undefined],
        //     name: 'reports-registry-index',
        //     contentOfLink: 'Все',
        //     level: 3,
        //     parentName: 'reports-registry',
        // },
        // {
        //     links: ['clients'],
        //     name: 'reports-registry-clients',
        //     contentOfLink: 'Клиенты',
        //     level: 3,
        //     parentName: 'reports-registry',
        // },
        // {
        //     links: ['executors'],
        //     name: 'reports-registry-executors',
        //     contentOfLink: 'Исполнители',
        //     level: 3,
        //     parentName: 'reports-registry',
        // },
        // {
        //     links: ['servicesRegistry'],
        //     name: 'reports-servicesRegistry',
        //     contentOfLink: 'Реестр служб доставки',
        //     level: 2,
        //     parentName: 'reports',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'reports',
        //                 items: [{ key: 'service-orders', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['', undefined],
        //     name: 'reports-servicesRegistry-index',
        //     contentOfLink: 'Сторонние заказы',
        //     level: 3,
        //     parentName: 'reports-servicesRegistry',
        // },
        {
            links: ['manual'],
            name: 'manual',
            contentOfLink: 'Справочник',
            level: 1,
            parentName: 'corporation',
            getRedirect({ user }) {
                if (!user) {
                    return { name: 'auth' };
                }

                return { name: 'corporation' };
            },
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'manual',
                    }),
                };
            },
        },
        {
            links: ['executors'],
            name: 'manual-executors',
            contentOfLink: 'Исполнители',
            level: 2,
            parentName: 'manual',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'manual',
                        items: [{ key: 'executors', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'manual-executors-main',
            className: '_wide',
            level: 3,
            parentName: 'manual-executors',
        },
        {
            links: [],
            name: 'manual-executors-inner',
            contentOfLink: '+ Добавить исполнителя',
            level: 3,
            parentName: 'manual-executors',
        },
        {
            links: ['main', '', undefined],
            name: 'manual-executors-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        // {
        //     links: ['cars'],
        //     name: 'manual-executors-inner-cars',
        //     contentOfLink: 'Автомобили',
        //     level: 4,
        //     parentName: 'manual-executors-inner',
        // },
        {
            links: ['docs'],
            name: 'manual-executors-inner-docs',
            contentOfLink: 'Документы',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        {
            links: ['wallets'],
            name: 'manual-executors-inner-wallets',
            contentOfLink: 'Реквизиты',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        {
            links: ['pays'],
            name: 'manual-executors-inner-pays',
            contentOfLink: 'Выплаты',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        {
            links: ['balance'],
            name: 'manual-executors-inner-balance',
            contentOfLink: 'Баланс',
            className: '_wide',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        {
            links: ['contracts'],
            name: 'manual-executors-inner-contracts',
            contentOfLink: 'Договоры',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        {
            links: ['logs'],
            name: 'manual-executors-inner-logs',
            contentOfLink: 'Логи',
            className: '_wide',
            level: 4,
            parentName: 'manual-executors-inner',
        },
        // {
        //     links: ['cars'],
        //     name: 'manual-cars',
        //     contentOfLink: 'Автомобили',
        //     level: 2,
        //     parentName: 'manual',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'manual',
        //                 items: [{ key: 'cars', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'manual-cars-main',
        //     className: '_wide',
        //     level: 3,
        //     parentName: 'manual-cars',
        // },
        // {
        //     links: [],
        //     name: 'manual-cars-inner',
        //     contentOfLink: '+ Добавить автомобиль',
        //     level: 3,
        //     parentName: 'manual-cars',
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'manual-cars-inner-main',
        //     contentOfLink: 'Детали',
        //     level: 4,
        //     parentName: 'manual-cars-inner',
        // },
        // {
        //     links: ['executors'],
        //     name: 'manual-cars-inner-executors',
        //     contentOfLink: 'Водители',
        //     level: 4,
        //     parentName: 'manual-cars-inner',
        // },
        // {
        //     links: ['trailers'],
        //     name: 'manual-cars-inner-trailers',
        //     contentOfLink: 'Прицепы',
        //     level: 4,
        //     parentName: 'manual-cars-inner',
        // },
        // {
        //     links: ['docs'],
        //     name: 'manual-cars-inner-docs',
        //     contentOfLink: 'Документы',
        //     level: 4,
        //     parentName: 'manual-cars-inner',
        // },
        // {
        //     links: ['logs'],
        //     name: 'manual-cars-inner-logs',
        //     contentOfLink: 'Логи',
        //     className: '_wide',
        //     level: 4,
        //     parentName: 'manual-cars-inner',
        // },
        // {
        //     links: ['tariffs'],
        //     name: 'manual-tariffs',
        //     contentOfLink: 'Тарифы',
        //     level: 2,
        //     parentName: 'manual',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'manual',
        //                 items: [{ key: 'tariffs', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'manual-tariffs-main',
        //     className: '_wide',
        //     level: 3,
        //     parentName: 'manual-tariffs',
        // },
        // {
        //     links: [],
        //     name: 'manual-tariffs-inner',
        //     level: 3,
        //     parentName: 'manual-tariffs',
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'manual-tariffs-inner-main',
        //     contentOfLink: 'Детали',
        //     level: 4,
        //     parentName: 'manual-tariffs-inner',
        // },
        // {
        //     links: ['logs'],
        //     name: 'manual-tariffs-inner-logs',
        //     contentOfLink: 'Логи',
        //     className: '_wide',
        //     level: 4,
        //     parentName: 'manual-tariffs-inner',
        // },
        {
            links: ['tags'],
            name: 'manual-tags',
            contentOfLink: 'Теги',
            level: 2,
            parentName: 'manual',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'manual',
                        items: [{ key: 'tags', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['main', '', undefined],
            name: 'manual-tags-main',
            className: '_wide',
            level: 3,
            parentName: 'manual-tags',
        },
        {
            links: [],
            name: 'manual-tags-inner',
            contentOfLink: '+ Добавить тег',
            level: 3,
            parentName: 'manual-tags',
        },
        {
            links: ['main', undefined, ''],
            name: 'manual-tags-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'manual-tags-inner',
        },
        {
            links: ['logs'],
            name: 'manual-tags-inner-logs',
            contentOfLink: 'Логи',
            className: '_wide',
            level: 4,
            parentName: 'manual-tags-inner',
            getRedirect() {
                return {
                    name: 'auth',
                };
            },
            getCond({ user }) {
                return {
                    condition: user || getCookie(process.env.REACT_APP_HASH),
                };
            },
        },
        // {
        //     links: ['modelsOfCar'],
        //     name: 'manual-modelsOfCar',
        //     contentOfLink: 'Модели машин',
        //     level: 2,
        //     parentName: 'manual',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'manual',
        //                 items: [{ key: 'modelsOfCar', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'manual-modelsOfCar-main',
        //     className: '_wide',
        //     level: 3,
        //     parentName: 'manual-modelsOfCar',
        // },
        // {
        //     links: [],
        //     name: 'manual-modelsOfCar-inner',
        //     contentOfLink: '+ Добавить модель',
        //     level: 3,
        //     parentName: 'manual-modelsOfCar',
        // },
        // {
        //     links: ['main', undefined, ''],
        //     name: 'manual-modelsOfCar-inner-main',
        //     contentOfLink: 'Детали',
        //     level: 4,
        //     parentName: 'manual-modelsOfCar-inner',
        // },
        // {
        //     links: ['logs'],
        //     name: 'manual-modelsOfCar-inner-logs',
        //     contentOfLink: 'Логи',
        //     className: '_wide',
        //     level: 4,
        //     parentName: 'manual-modelsOfCar-inner',
        //     getRedirect() {
        //         return {
        //             name: 'auth',
        //         };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: user || getCookie(process.env.REACT_APP_HASH),
        //         };
        //     },
        // },
        {
            links: ['auth'],
            name: 'auth',
            isPopup: true,
            getRedirect() {
                return { name: 'corporation' };
            },
            getCond({ user, isProccessLogin, isProccessLogout }) {
                return {
                    condition:
                        (!user && !getCookie(process.env.REACT_APP_HASH)) ||
                        isProccessLogin ||
                        isProccessLogout,
                };
            },
            contentOfLink: 'Главная',
        },
        {
            links: ['', undefined, 'login'],
            name: 'login',
            level: 1,
            parentName: 'auth',
        },
        {
            links: ['registration'],
            name: 'registration',
            level: 1,
            parentName: 'auth',
        },
        {
            links: ['forget'],
            name: 'forget',
            level: 1,
            parentName: 'auth',
        },
        {
            links: ['chat'],
            name: 'chat',
            contentOfLink: 'Все чаты',
            level: 1,
            parentName: 'corporation',
            getRedirect({ user }) {
                if (!user) {
                    return { name: 'auth' };
                }

                return { name: 'corporation' };
            },
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'chat',
                    }),
                };
            },
        },
        {
            links: ['all', '', undefined],
            name: 'chat-all',
            contentOfLink: 'Все',
            level: 2,
            parentName: 'chat',
        },
        {
            links: ['proccess'],
            name: 'chat-proccess',
            contentOfLink: 'В обработке',
            level: 2,
            parentName: 'chat',
        },
        {
            links: ['important'],
            name: 'chat-important',
            contentOfLink: 'Срочные',
            level: 2,
            parentName: 'chat',
        },
        {
            links: ['complete'],
            name: 'chat-complete',
            contentOfLink: 'Закрытые',
            level: 2,
            parentName: 'chat',
        },
        {
            links: [],
            name: 'chat-inner',
            contentOfLink: '',
            level: 3,
            parentName: 'chat-all',
        },
        {
            links: ['chat-templates'],
            name: 'chat-templates',
            contentOfLink: 'Шаблоны',
            level: 1,
            parentName: 'corporation',
            group: 'chat',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'chat',
                        items: [{ key: 'templates', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['chat-template'],
            name: 'chat-template',
            level: 1,
            isPopup: true,
            mainPage: 'chat-templates',
            parentName: 'corporation',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'chat',
                        items: [{ key: 'templates', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: [],
            name: 'chat-template-inner',
            level: 2,
            parentName: 'chat-template',
        },
        {
            links: ['profile'],
            name: 'profile',
            group: 'cabinet',
        },
        {
            links: ['', undefined, 'main'],
            name: 'profile-main',
            contentOfLink: 'Детали',
            level: 1,
            parentName: 'profile',
        },
        {
            links: ['proxy'],
            name: 'profile-proxy',
            contentOfLink: 'Доверенности',
            level: 1,
            parentName: 'profile',
            getCond({ user }) {
                return {
                    condition: user?.idOfCurrentCorporation !== 'admin',
                };
            },
        },
        // {
        //     links: ['order-details'],
        //     name: 'order-details',
        //     level: 1,
        //     parentName: 'corporation',
        //     getCond({ user }) {
        //         return {
        //             condition: getPermissions(user, {
        //                 key: 'orders',
        //                 items: [{ key: 'main', rules: ['read'] }],
        //             }),
        //         };
        //     },
        // },
        // {
        //     links: [],
        //     name: 'order-details-inner',
        //     contentOfLink: 'Детали',
        //     level: 2,
        //     parentName: 'order-details',
        // },
        // {
        //     links: ['', 'main', undefined],
        //     name: 'order-details-main',
        //     contentOfLink: 'Детали',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        // {
        //     links: ['serviceCompanies'],
        //     name: 'order-details-serviceCompanies',
        //     contentOfLink: 'Служба доставки',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        // {
        //     links: ['crew'],
        //     name: 'order-details-crew',
        //     contentOfLink: 'Экипаж',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        // {
        //     links: ['map', '', undefined],
        //     name: 'order-details-crew-map',
        //     level: 4,
        //     parentName: 'order-details-crew',
        // },
        // {
        //     links: ['final'],
        //     name: 'order-details-crew-final',
        //     level: 4,
        //     parentName: 'order-details-crew',
        // },
        // {
        //     links: ['docs'],
        //     name: 'order-details-docs',
        //     contentOfLink: 'Документы',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        // {
        //     links: ['gps'],
        //     name: 'order-details-gps',
        //     contentOfLink: 'GPS',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        // {
        //     links: ['logs'],
        //     name: 'order-details-logs',
        //     contentOfLink: 'Логи',
        //     className: '_wide',
        //     level: 3,
        //     parentName: 'order-details-inner',
        // },
        {
            links: ['support'],
            name: 'content-infoPublic',
            contentOfLink: 'База знаний',
            group: 'cabinet',
        },
        {
            links: ['main', '', undefined],
            name: 'content-infoPublic-main',
            level: 1,
            parentName: 'content-infoPublic',
        },
        {
            links: [],
            name: 'content-infoPublic-inner',
            contentOfLink: '+ Добавить статью',
            level: 1,
            parentName: 'content-infoPublic',
        },
        {
            links: ['content'],
            name: 'content',
            level: 1,
            parentName: 'corporation',
        },
        {
            links: ['info-system'],
            name: 'content-info',
            contentOfLink: 'База знаний',
            getCond({ user }) {
                return {
                    condition:
                        checkAdminCorporation({ user }) &&
                        getPermissions(user, {
                            key: 'content',
                            items: [{ key: 'info', rules: ['read'] }],
                        }),
                };
            },
            level: 2,
            parentName: 'content',
        },
        {
            links: ['main', '', undefined],
            name: 'content-info-main',
            level: 3,
            parentName: 'content-info',
        },
        {
            links: [],
            name: 'content-info-inner',
            contentOfLink: '+ Добавить статью',
            level: 3,
            parentName: 'content-info',
        },
        {
            links: ['', undefined],
            name: 'content-info-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'content-info-inner',
        },
        {
            links: ['content'],
            name: 'content-info-inner-content',
            contentOfLink: 'Контент',
            level: 4,
            parentName: 'content-info-inner',
        },
        {
            links: ['blog'],
            name: 'content-blog',
            contentOfLink: 'Блог',
            getCond({ user }) {
                return {
                    condition:
                        checkAdminCorporation({ user }) &&
                        getPermissions(user, {
                            key: 'content',
                            items: [{ key: 'blog', rules: ['read'] }],
                        }),
                };
            },
            level: 2,
            parentName: 'content',
        },
        {
            links: ['main', '', undefined],
            name: 'content-blog-main',
            level: 3,
            parentName: 'content-blog',
        },
        {
            links: [],
            name: 'content-blog-inner',
            contentOfLink: '+ Добавить статью',
            level: 3,
            parentName: 'content-blog',
        },
        {
            links: ['', undefined],
            name: 'content-blog-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'content-blog-inner',
        },
        {
            links: ['content'],
            name: 'content-blog-inner-content',
            contentOfLink: 'Контент',
            level: 4,
            parentName: 'content-blog-inner',
        },
        {
            links: ['announce'],
            name: 'content-announce',
            contentOfLink: 'Анонсы',
            getCond({ user }) {
                return {
                    condition:
                        checkAdminCorporation({ user }) &&
                        getPermissions(user, {
                            key: 'content',
                            items: [{ key: 'announce', rules: ['read'] }],
                        }),
                };
            },
            level: 2,
            parentName: 'content',
        },
        {
            links: ['main', '', undefined],
            name: 'content-announce-main',
            level: 3,
            parentName: 'content-announce',
        },
        {
            links: [],
            name: 'content-announce-inner',
            contentOfLink: '+ Добавить статью',
            level: 3,
            parentName: 'content-announce',
        },
        {
            links: ['', undefined],
            name: 'content-announce-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'content-announce-inner',
        },
        {
            links: ['content'],
            name: 'content-announce-inner-content',
            contentOfLink: 'Контент',
            level: 4,
            parentName: 'content-announce-inner',
        },
        // {
        //     links: ['google-vision'],
        //     name: 'content-googleVision',
        //     contentOfLink: 'Google Vision',
        //     level: 2,
        //     parentName: 'content',
        //     getCond({ user }) {
        //         return {
        //             condition:
        //                 checkAdminCorporation({ user }) &&
        //                 getPermissions(user, {
        //                     key: 'content',
        //                     items: [{ key: 'google', rules: ['read'] }],
        //                 }),
        //         };
        //     },
        // },
        // {
        //     links: ['main', '', undefined],
        //     name: 'content-googleVision-main',
        //     level: 3,
        //     parentName: 'content-googleVision',
        // },
        // {
        //     links: ['list'],
        //     name: 'content-googleVision-list',
        //     contentOfLink: '+ Добавить слово',
        //     level: 3,
        //     parentName: 'content-googleVision',
        // },
        // {
        //     links: [],
        //     name: 'content-googleVision-inner',
        //     level: 3,
        //     parentName: 'content-googleVision',
        // },
        {
            links: ['legal'],
            name: 'content-legal',
            contentOfLink: 'Правовая информация',
            getCond({ user }) {
                return {
                    condition:
                        checkAdminCorporation({ user }) &&
                        getPermissions(user, {
                            key: 'content',
                            items: [{ key: 'legal', rules: ['read'] }],
                        }),
                };
            },
            level: 2,
            parentName: 'content',
        },
        {
            links: ['main', '', undefined],
            name: 'content-legal-main',
            level: 3,
            parentName: 'content-legal',
        },
        {
            links: [],
            name: 'content-legal-inner',
            contentOfLink: '+ Добавить статью',
            level: 3,
            parentName: 'content-legal',
        },
        {
            links: ['', undefined],
            name: 'content-legal-inner-main',
            contentOfLink: 'Детали',
            level: 4,
            parentName: 'content-legal-inner',
        },
        {
            links: ['content'],
            name: 'content-legal-inner-content',
            contentOfLink: 'Контент',
            level: 4,
            parentName: 'content-legal-inner',
        },
        {
            links: ['settings'],
            name: 'settings',
            level: 1,
            parentName: 'corporation',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                    }),
                };
            },
        },
        {
            links: ['', undefined, 'main'],
            name: 'settings-main',
            contentOfLink: 'Данные компании',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'main', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['operations'],
            name: 'settings-operations',
            contentOfLink: 'Операции',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'balance', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['docs'],
            name: 'settings-docs',
            contentOfLink: 'Документы',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition:
                        user?.idOfCurrentCorporation !== 'admin' &&
                        getPermissions(user, {
                            key: 'settings',
                            items: [{ key: 'docs', rules: ['read'] }],
                        }),
                };
            },
        },
        {
            links: ['users'],
            name: 'settings-users',
            contentOfLink: 'Сотрудники',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'users', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['reports'],
            name: 'settings-reports',
            contentOfLink: 'Отчёты',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'reports', rules: ['read'] }],
                    }),
                };
            },
        },

        {
            links: ['roles'],
            name: 'settings-roles',
            contentOfLink: 'Роли',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getCurrentCorporation({ user, isInfo: false })?.role === 'holder',
                };
            },
        },
        {
            links: ['', undefined, 'main'],
            name: 'settings-roles-main',
            level: 3,
            parentName: 'settings-roles',
        },
        {
            links: [],
            name: 'settings-roles-inner',
            level: 3,
            parentName: 'settings-roles',
        },
        {
            links: ['proxy'],
            name: 'settings-proxy',
            contentOfLink: 'Доверенности',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getCurrentCorporation({ user, isInfo: false })?.role === 'holder',
                };
            },
        },
        {
            links: ['integrations'],
            name: 'settings-integrations',
            contentOfLink: 'Интеграции',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'integrations', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['logs'],
            name: 'settings-logs',
            contentOfLink: 'Логи',
            level: 2,
            parentName: 'settings',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'logs', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['join'],
            name: 'join',
            getRedirect() {
                return { name: 'corporation' };
            },
            getCond({ user, isProccessLogin, isProccessLogout }) {
                return {
                    condition:
                        (!user && !getCookie(process.env.REACT_APP_HASH)) ||
                        isProccessLogin ||
                        isProccessLogout,
                };
            },
        },
        {
            links: [],
            name: 'join-inner',
            level: 2,
            parentName: 'join',
        },
        {
            links: ['pdf'],
            name: 'pdf',
        },
        {
            links: ['pep'],
            name: 'pep',
            isPopup: true,
            mainPage({ user }) {
                return user ? 'corporation' : 'public';
            },
        },
        {
            links: ['', undefined],
            name: 'public',
            group: 'public',
        },
        {
            links: ['mailing'],
            name: 'mailing',
            level: 1,
            isPopup: true,
            mainPage: 'mailings',
            parentName: 'corporation',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'chat',
                        items: [{ key: 'mailing', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: [],
            name: 'mailing-inner',
            level: 2,
            parentName: 'mailing',
        },
        {
            links: ['mailings'],
            name: 'mailings',
            level: 1,
            parentName: 'corporation',
            group: 'chat',
            contentOfLink: 'Рассылки',
            getCond({ user }) {
                return {
                    condition: getPermissions(user, {
                        key: 'chat',
                        items: [{ key: 'mailing', rules: ['read'] }],
                    }),
                };
            },
        },
        {
            links: ['business'],
            name: 'public-business',
            contentOfLink: 'Решения для бизнеса',
            level: 1,
            parentName: 'public',
        },
        {
            links: ['executors', undefined],
            name: 'public-executors',
            contentOfLink: 'Исполнителям',
            level: 1,
            parentName: 'public',
        },
        {
            links: ['blog'],
            name: 'public-blog',
            contentOfLink: 'Блог',
            level: 1,
            parentName: 'public',
        },
        {
            links: [],
            name: 'public-blog-inner',
            contentOfLink: 'Блог',
            level: 2,
            parentName: 'public-blog',
        },
        {
            links: ['legal'],
            name: 'public-legal',
            contentOfLink: 'Правовая информация',
            level: 1,
            parentName: 'public',
        },
        {
            links: [],
            name: 'public-legal-inner',
            level: 2,
            parentName: 'public-legal',
        },
    ],
    app: [
        {
            links: ['start'],
            name: 'start',
            getRedirect() {
                return { name: 'index' };
            },
            getCond({ user, isProccessLogin, isProccessLogout }) {
                return {
                    condition: !user || isProccessLogin || isProccessLogout,
                };
            },
        },
        {
            links: ['auth'],
            name: 'auth',
            getRedirect() {
                return { name: 'index' };
            },
            getCond({ user, isProccessLogin, isProccessLogout }) {
                return {
                    condition: !user || isProccessLogin || isProccessLogout,
                };
            },
        },
        {
            links: ['', undefined],
            name: 'index',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['corporations-list'],
            name: 'corporations-list',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['balance'],
            name: 'balance',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['settings'],
            name: 'settings',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['', undefined],
            name: 'settings-index',
            contentOfLink: 'Настройки',
            level: 1,
            parentName: 'settings',
        },
        {
            links: ['profile'],
            name: 'settings-profile',
            contentOfLink: 'Данные аккаунта',
            level: 1,
            parentName: 'settings',
        },
        {
            links: ['legal'],
            name: 'settings-legal',
            contentOfLink: 'О приложении',
            level: 1,
            parentName: 'settings',
        },
        {
            links: ['', undefined],
            name: 'settings-legal-index',
            level: 2,
            parentName: 'settings-legal',
        },
        {
            links: [],
            name: 'settings-legal-inner',
            level: 2,
            parentName: 'settings-legal',
        },
        {
            links: ['wallet'],
            name: 'settings-wallet',
            contentOfLink: 'Реквизиты',
            level: 1,
            parentName: 'settings',
            getRedirect() {
                return {
                    name: 'settings',
                };
            },
        },
        {
            links: ['tax'],
            name: 'settings-tax',
            contentOfLink: 'Налоговая копилка',
            level: 1,
            parentName: 'settings',
            getRedirect() {
                return {
                    name: 'settings',
                };
            },
            getCond({ user }) {
                return {
                    condition: user?.organization === 'SMZ',
                };
            },
        },
        {
            links: ['fns'],
            name: 'settings-fns',
            contentOfLink: 'Мой налог',
            level: 1,
            parentName: 'settings',
            getRedirect() {
                return {
                    name: 'settings',
                };
            },
            getCond({ user }) {
                return {
                    condition: user?.organization === 'SMZ',
                };
            },
        },
        {
            links: ['chat'],
            name: 'chat',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['', undefined],
            name: 'chat-index',
            contentOfLink: 'Поддержка',
            level: 1,
            parentName: 'chat',
        },
        {
            links: [],
            name: 'chat-inner',
            contentOfLink: 'Чат поддержки',
            level: 1,
            parentName: 'chat',
        },
        // {
        //     links: ['orders'],
        //     name: 'orders',
        //     getRedirect({ store }) {
        //         if (store?.gpsDisabled === true) {
        //             handlerPopup({ name: 'appGpsPopup', isShow: true });

        //             return {
        //                 name: 'index',
        //             };
        //         }

        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user, store }) {
        //         return {
        //             condition:
        //                 (user || getCookie(process.env.REACT_APP_HASH)) &&
        //                 store?.gpsDisabled !== true,
        //         };
        //     },
        // },
        // {
        //     links: ['', undefined, 'wait'],
        //     name: 'orders-wait',
        //     contentOfLink: 'Отложенные',
        //     level: 1,
        //     parentName: 'orders',
        // },
        // {
        //     links: ['free'],
        //     name: 'orders-free',
        //     contentOfLink: 'Доступные',
        //     level: 1,
        //     parentName: 'orders',
        // },
        // {
        //     links: ['completed'],
        //     name: 'orders-completed',
        //     contentOfLink: 'Завершённые',
        //     level: 1,
        //     parentName: 'orders',
        // },
        // {
        //     links: ['markets'],
        //     name: 'markets',
        //     getRedirect({ store }) {
        //         if (store?.gpsDisabled === true) {
        //             handlerPopup({ name: 'appGpsPopup', isShow: true });

        //             return {
        //                 name: 'index',
        //             };
        //         }

        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user, store }) {
        //         return {
        //             condition:
        //                 (user || getCookie(process.env.REACT_APP_HASH)) &&
        //                 store?.gpsDisabled !== true,
        //         };
        //     },
        // },
        // {
        //     links: ['', undefined, 'wait'],
        //     name: 'markets-wait',
        //     contentOfLink: 'Доступные',
        //     level: 1,
        //     parentName: 'markets',
        // },
        // {
        //     links: ['completed'],
        //     name: 'markets-completed',
        //     contentOfLink: 'Завершённые',
        //     level: 1,
        //     parentName: 'markets',
        // },
        // {
        //     links: ['order-details'],
        //     name: 'order-details',
        //     mainPage: 'orders-completed',
        //     isPopup: true,
        //     getRedirect({ store }) {
        //         if (store?.gpsDisabled === true) {
        //             handlerPopup({ name: 'appGpsPopup', isShow: true });

        //             return {
        //                 name: 'index',
        //             };
        //         }

        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user, store }) {
        //         return {
        //             condition:
        //                 (user || getCookie(process.env.REACT_APP_HASH)) &&
        //                 store?.gpsDisabled !== true,
        //         };
        //     },
        // },
        // {
        //     links: [],
        //     name: 'order-details-inner',
        //     level: 1,
        //     parentName: 'order-details',
        // },
        // {
        //     links: ['order'],
        //     name: 'order',
        //     getRedirect({ store }) {
        //         if (store?.gpsDisabled === true) {
        //             handlerPopup({ name: 'appGpsPopup', isShow: true });

        //             return {
        //                 name: 'index',
        //             };
        //         }

        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user, store }) {
        //         return {
        //             condition:
        //                 (user || getCookie(process.env.REACT_APP_HASH)) &&
        //                 store?.gpsDisabled !== true,
        //         };
        //     },
        // },
        // {
        //     links: [],
        //     name: 'order-inner',
        //     level: 1,
        //     parentName: 'order',
        // },
        // {
        //     links: ['order-chat'],
        //     name: 'order-chat',
        //     isPopup: true,
        //     getRedirect() {
        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: user || getCookie(process.env.REACT_APP_HASH),
        //         };
        //     },
        // },
        // {
        //     links: [],
        //     name: 'order-chat-inner',
        //     contentOfLink: 'Чат поддержки',
        //     level: 1,
        //     parentName: 'order-chat',
        // },
        {
            links: ['pays'],
            name: 'pays',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['', undefined, 'all'],
            name: 'pays-all',
            contentOfLink: 'Все',
            level: 1,
            parentName: 'pays',
        },
        {
            links: ['process'],
            name: 'pays-process',
            contentOfLink: 'На подпись',
            level: 1,
            parentName: 'pays',
        },
        {
            links: ['completed'],
            name: 'pays-completed',
            contentOfLink: 'Оплаченные',
            level: 1,
            parentName: 'pays',
        },
        {
            links: ['notifications'],
            name: 'notifications',
            getRedirect() {
                return {
                    name: 'start',
                };
            },
            getCond({ user }) {
                return {
                    condition: user,
                };
            },
        },
        {
            links: ['', undefined, 'new'],
            name: 'notifications-new',
            level: 1,
            parentName: 'notifications',
        },
        {
            links: ['completed'],
            name: 'notifications-completed',
            level: 1,
            parentName: 'notifications',
        },
        {
            links: ['join'],
            name: 'join',
            getRedirect() {
                return { name: 'index' };
            },
            getCond({ user, isProccessLogin, isProccessLogout }) {
                return {
                    condition: !user || isProccessLogin || isProccessLogout,
                };
            },
        },
        {
            links: [],
            name: 'join-inner',
            level: 1,
            parentName: 'join',
        },
        // {
        //     links: ['corporations'],
        //     name: 'corporations',
        //     getRedirect() {
        //         return {
        //             name: 'start',
        //         };
        //     },
        //     getCond({ user }) {
        //         return {
        //             condition: user || getCookie(process.env.REACT_APP_HASH),
        //         };
        //     },
        // },
        // {
        //     links: ['', undefined, 'main'],
        //     name: 'corporations-main',
        //     level: 1,
        //     parentName: 'corporations',
        // },
        // {
        //     links: ['free'],
        //     name: 'corporations-free',
        //     level: 1,
        //     parentName: 'corporations',
        // },
    ],
};

const currentPages = pages[process.env.REACT_APP_SYSTEM];

export default currentPages;
