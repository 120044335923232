import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';
import getUserInfo from '../../../functions/getUserInfo';
import getFormatedDate from '../../../functions/getFormatedDate';
import getEndText from '../../../functions/getEndText';
import shortText from '../../../functions/shortText';

import downloadReq from '../../../functions/download';

import Button from '../../Button.jsx';
import changePage from '../../../functions/changePage';
import deleteNotifications from '../../../functions/crm/deleteNotifications';
import Link from '../../Link.jsx';
import getPageLink from '../../../functions/getPageLink';

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    startNotifications = {
        startTariffs: {
            title: 'Добавлены тестовые тарифы',
            description: 'Ознакомьтесь или замените их на собственные',
            button: {
                content: 'Ознакомиться с тарифами',
                pageName: 'manual-tariffs',
            },
        },
        startExecutors: {
            title: 'Добавьте первого исполнителя',
            description: 'Для начала работы с CRM-системой добавьте исполнителей',
            button: {
                content: 'Добавить исполнителя',
                pageName: 'manual-executors-inner',
                ids: { 3: 'new' },
            },
        },
        startCars: {
            title: 'Добавьте первый автомобиль',
            description: 'Для начала работы с CRM-системой добавьте автомобили',
            button: {
                content: 'Добавить автомобиль',
                pageName: 'manual-cars-inner',
                ids: { 3: 'new' },
            },
        },
        startClients: {
            title: 'Добавьте первого клиента',
            description: 'Для начала работы с CRM-системой добавьте клиентов',
            button: {
                content: 'Добавить клиента',
                pageName: 'manual-organizations-inner',
                ids: { 3: 'juristic' },
            },
        },
        checkJoinExecutor: {
            title: 'Подключен новый исполнитель',
            button: {
                content: 'Подключить',
                pageName: 'manual-executors-inner',
            },
        },
        checkCarForExecutor: {
            title: 'Добавьте автомобиль исполнителю',
            button: {
                content: 'Добавить автомобиль',
                pageName: 'manual-executors-inner-cars',
            },
        },
        checkOrderCrew: {
            title: 'Назначьте экипаж на заказ',
            button: {
                content: 'Назначить экипаж',
                pageName: 'order-details-crew-map',
            },
        },
        newChatMessage: {
            title: 'Новое сообщение!',
            button: {
                content: 'Ответить',
                pageName: 'chat-inner',
            },
        },
        pdfReady: {
            title: 'Генерация завершена',
            button: {
                content: 'Скачать файл',
            },
        },
        pdfError: {
            title: 'Ошибка генерации файла',
            button: {
                content: 'Попробовать позже',
            },
        },
        setChatResponsible: {
            title() {
                const { data } = this.props;

                return (
                    <>
                        Вас назначили ответственным
                        <br />
                        на&nbsp;
                        <Link
                            className="notification__titleLink"
                            pageName="chat-inner"
                            ids={{ 3: data?.chatId }}
                        >
                            чат
                        </Link>
                    </>
                );
            },
            button: {
                content: 'Взять в работу 🤝',
            },
        },
    };

    getNotificationInfo() {
        const { name, data } = this.props;
        const notification = { ...this.startNotifications[name] };

        if (notification) {
            if (name === 'checkJoinExecutor') {
                notification.description = `<b>${getUserInfo({
                    user: data.executorInfo,
                    type: 'name',
                })}</b>, <span class="_noWrap">${data.executorInfo?.phone}</span>`;

                notification.button.ids = { 3: data.executorId };
            }

            if (name === 'checkCarForExecutor') {
                notification.description = `Исполнитель <b>${getUserInfo({
                    user: data.executorInfo,
                    type: 'name',
                })}</b> не&nbsp;имеет автомобиля`;

                notification.button.ids = { 3: data.executorId };
            }

            if (name === 'checkOrderCrew') {
                notification.description = `Заказ <b>№${data.orderInfo?.number}</b>`;

                notification.button.ids = { 3: data.orderId };
            }

            if (name === 'newChatMessage') {
                const content = data.chatInfo?.content
                    ? shortText({ type: 'word', str: data.chatInfo.content, stop: 12 })
                    : data.chatInfo.files
                    ? `${data.chatInfo.files} ${getEndText(data.chatInfo.files, [
                          'файл',
                          'файла',
                          'файлов',
                      ])}`
                    : '';
                notification.description = `Обращение: ${content}`;

                notification.button.ids = { 3: data.chatId };
            }

            if (name === 'pdfReady') {
                notification.description = data.name;
                notification.button.downloadLink = data.fileName;
            }

            if (name === 'pdfError') {
                notification.description = 'Попробуйте ещё раз';
                notification.button.downloadLink = true;
                notification.className = '_alert';
            }

            return notification;
        }

        return {};
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerButton() {
        const { _id, name, data, canUserDelete } = this.props;
        const notificationInfo = this.getNotificationInfo();

        if (canUserDelete) {
            this.handerLoading('loading').then(() => {
                const { id } = this.props;

                deleteNotifications({ id }).then(() => {
                    if (name === 'setChatResponsible') {
                        changePage({
                            href: getPageLink({
                                name: 'chat-inner',
                                ids: { 3: data.chatId },
                            }),
                        });
                    }

                    this.handerLoading(null);
                });
            });
        } else if (notificationInfo.button?.pageName) {
            const { pageName, ids } = notificationInfo.button;

            changePage({ href: getPageLink({ name: pageName, ids }) });
        } else if (notificationInfo.button?.downloadLink) {
            this.handerLoading('loading').then(() => {
                downloadReq({
                    files: [
                        {
                            src: data.fileName,
                            folder: 'corporations-docs',
                            name: data.name,
                            notificationId: _id,
                            pdfId: data.pdfId,
                            isCheckNotification: notificationInfo.button?.downloadLink === true,
                        },
                    ],
                }).then(
                    () => {
                        this.handerLoading(null);
                    },
                    () => null,
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;
        const { createdDate, group, startIndex } = this.props;
        const notificationInfo = this.getNotificationInfo();
        const isStart = group === 'start';

        return (
            <div
                ref={this.parent}
                className={`notification ${isStart ? '_forStart' : ''} ${
                    notificationInfo.className || ''
                }`}
            >
                <div className="notification__inner _col">
                    <p className="notification__support">
                        {isStart ? (
                            <>Шаг {startIndex}</>
                        ) : (
                            <>{getFormatedDate({ date: new Date(createdDate), type: 'full' })}</>
                        )}
                    </p>
                    {typeof notificationInfo.title === 'function' ? (
                        <>
                            <div className="notification__title">
                                {notificationInfo.title.call(this)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="notification__title"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(notificationInfo.title),
                                }}
                            ></div>
                        </>
                    )}

                    <p
                        className="notification__description"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(notificationInfo.description),
                        }}
                    ></p>
                    <div className="notification__button" onClick={() => this.handlerButton()}>
                        <Button className="_crm _blue _normalSizeLoader" showLoader={!!loadingKey}>
                            {notificationInfo.button.content}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Notification);

Notification.propTypes = {
    user: PropTypes.object,
    _id: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    createdDate: PropTypes.string,
    data: PropTypes.object,
    group: PropTypes.string,
    startIndex: PropTypes.number,
    canUserDelete: PropTypes.bool,
};
