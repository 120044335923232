import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import JoinContractPopup from './JoinContractPopup.jsx';

class JoinContractPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { joinContractPopup } = this.props;

        return (
            <Animate className="body__payPopup" isShow={joinContractPopup.isShow}>
                <JoinContractPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        joinContractPopup: state.joinContractPopup,
    };
}

export default connect(mapStateToProps)(JoinContractPopupWrapper);

JoinContractPopupWrapper.propTypes = {
    joinContractPopup: PropTypes.object,
};
