import axios from 'axios';

import { deleteCookie, getCookie } from './handlerCookies';
import { dispatcher, store } from '../redux/redux';

import connectSockets from './connectSockets';
import changePage from './changePage';
import setNotification from './setNotification';
import getHeaders from './getHeaders';
import handlerErrorRequest from './handlerErrorRequest';

export default function logout(isChangePage = false) {
    const startPage = process.env.REACT_APP_SYSTEM === 'site' ? 'forClients' : 'auth';

    if (isChangePage === true) {
        return new Promise((resolve) => {
            changePage({ href: startPage }).then(
                () => {
                    dispatcher({ type: 'user', data: null }).then(() => {
                        dispatcher({ type: 'isProccessLogin', data: false });
                        dispatcher({ type: 'isSocketConnect', data: false });
                        dispatcher({ type: 'userWas', data: false });
                        dispatcher({ type: 'idOfPage', data: null });

                        deleteCookie(process.env.REACT_APP_HASH);

                        localStorage.removeItem('user');
                        localStorage.removeItem('hidePopupInstruction');
                        localStorage.removeItem('saveNotAdminCorporationId');

                        dispatcher({ type: 'dateOfLogout', data: new Date().getTime() });

                        connectSockets(true);

                        setTimeout(() => {
                            const storePages = {...store.getState().pages };

                            storePages.corporation.id = null;
                            storePages.corporation.isShow = false;
                            storePages['corporation-index'].isShow = false;

                            storePages.public.isShow = true;
                            storePages['public-executors'].isShow = true;

                            dispatcher({ type: 'pages', data: storePages });
                            dispatcher({ type: 'isProccessLogout', data: false });
                        }, 300);

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    return new Promise((resolve, reject) => {
        const hashLivecargo = getCookie(process.env.REACT_APP_HASH);

        dispatcher({ type: 'isProccessLogout', data: true }).then(() => {
            axios
                .put(
                    `${process.env.REACT_APP_API}/login`, {}, {
                        headers: {...getHeaders(), hash: hashLivecargo },
                    },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            dispatcher({ type: 'isSuccessLogout', data: true }).then(() => {
                                setNotification({
                                    notification: 'success-logout',
                                });

                                logout(true).then(resolve);
                            });
                        } else {
                            handlerErrorRequest(res);

                            const { message } = data;

                            if (message === 'You have unpaid order') {
                                setNotification({ notification: 'order-wait-pay' });
                            }

                            reject();
                        }
                    },
                    () => {
                        reject();
                    },
                );
        });
    });
}