import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFilter from '../../../../classes/Filter';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import getJoinContracts from '../../../../requests/getJoinContracts';
import AnimateChangeUp from '../../../../components/AnimateChangeUp.jsx';
import WidgetFilter from '../../../../components/crm/widget/Filter.jsx';
import Button from '../../../../components/Button.jsx';
import Checkbox from '../../../../components/Checkbox.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';
import Table from '../../../../components/crm/manual/Table.jsx';
import getFormatedDate from '../../../../functions/getFormatedDate';

import handlerPopup from '../../../../functions/app/handlerPopup';
import getFilter from '../../../../requests/getFilter';
import Link from '../../../../components/Link.jsx';
import Icon from '../../../../components/Icon.jsx';
import handlerWindow from '../../../../functions/handlerWindow';
import refreshJoinContracts from '../../../../functions/crm/refreshJoinContracts';
import CorporationInfo from '../../../../components/crm/manual/CorporationInfo.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';
import checkHolder from '../../../../functions/crm/checkHolder';
import getCurrentCorporation from '../../../../functions/crm/getCurrentCorporation';

class JoinsContractsMain extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentItems: [],
        };

        this.updateContract = this.updateContract.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.handlerCurrent = this.handlerCurrent.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.setSign = this.setSign.bind(this);
        this.filterCallback = this.filterCallback.bind(this);
        this.refreshContracts = this.refreshContracts.bind(this);
        this.renderStatus = this.renderStatus.bind(this);

        setPermissions.call(this);
    }

    handlerCurrent(id) {
        const { allJoinContracts = [] } = this.state;

        this.setState((state) => {
            const newState = { ...state };
            let currentItems = [...newState.currentItems];

            if (id === 'all') {
                const allWaitContracts = allJoinContracts.filter((item) =>
                    ['wait', 'executorSign'].includes(item.status),
                );
                const notProcessContracts = allJoinContracts.filter(
                    (item) => !['process', 'inactive'].includes(item.status),
                );

                const targetContracts = allWaitContracts.length
                    ? allWaitContracts
                    : notProcessContracts;

                currentItems = targetContracts.every(({ _id: innerId }) =>
                    currentItems?.includes(innerId),
                )
                    ? []
                    : targetContracts.map(({ _id }) => _id);
            } else {
                const index = currentItems.indexOf(id);

                if (index === -1) {
                    currentItems.push(id);
                } else {
                    currentItems.splice(index, 1);
                }
            }

            newState.currentItems = currentItems;

            return newState;
        });
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    renderStatus({ item, isStatic }) {
        const { type } = this.props;
        const { status: itemStatus } = item;
        let status = 'wait';
        let statusContent;
        const statusEnd = type === 'userProxy' ? 'а' : '';

        if (itemStatus === 'wait') {
            statusContent = `Не подписан${statusEnd}`;
        } else if (itemStatus === 'corporationSign') {
            statusContent = `Подписан${statusEnd}<br/>корпорацией`;
            status = 'middle';
        } else if (itemStatus === 'executorSign') {
            if (!item.type) {
                statusContent = `Подписан${statusEnd}<br/>исполнителем`;
            } else {
                statusContent = `Подписан${statusEnd}<br/>сотрудником`;
            }

            status = 'middle';
        } else {
            statusContent = `Подписан${statusEnd}`;
            status = 'completed';
        }

        if (itemStatus === 'process') {
            statusContent = 'В процессе';
            status = 'process';
        }

        if (item.inactive) {
            statusContent = `Расторгнут${statusEnd}`;
            status = 'inactive';
        }

        const renderKey = statusContent;

        if (isStatic) {
            return (
                <div className="settingsUsersTable__paysStatus _col">
                    <div className={`settingsUsersTable__paysStatusInner _row _${status}`}>
                        <div
                            className="settingsUsersTable__paysStatusInnerItem"
                            dangerouslySetInnerHTML={{ __html: statusContent }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <AnimateChangeUp className="settingsUsersTable__paysStatus _col" renderKey={renderKey}>
                <>
                    <div className={`settingsUsersTable__paysStatusInner _row _${status}`}>
                        <div
                            className="settingsUsersTable__paysStatusInnerItem"
                            dangerouslySetInnerHTML={{ __html: statusContent }}
                        />
                    </div>
                </>
            </AnimateChangeUp>
        );
    }

    handlerExport() {
        const { counter } = this.state;
        const query = this.getQueryForRequest();

        if (counter > 0) {
            handlerPopup({
                name: 'exportPopup',
                isShow: true,
                counter,
                filterQuery: query.params,
                type: 'contracts',
            });
        }
    }

    getActionsItems({ item }) {
        const { user } = this.state;
        const { type } = this.props;
        const { fileName, inactive } = item;
        const actions = [];

        if (fileName) {
            actions.push(...['openContract', 'downloadContract']);
        }

        if (
            !inactive &&
            (this.getPermissions({
                notAdminCorporation: true,
                key: 'joins',
                items: [
                    {
                        key: 'contracts',
                        rules: ['sign'],
                    },
                ],
            }) ||
                (type === 'userProxy' &&
                    checkHolder({ user }) &&
                    user.idOfCurrentCorporation !== 'admin'))
        ) {
            actions.push('inactiveContract');
        }

        if (
            !inactive &&
            (this.getPermissions({
                adminCorporation: true,
                key: 'joins',
                items: [
                    {
                        key: 'contracts',
                        rules: [],
                        actions: ['refresh'],
                    },
                ],
            }) ||
                (type === 'userProxy' &&
                    checkHolder({ user }) &&
                    user.idOfCurrentCorporation !== 'admin'))
        ) {
            actions.push('refreshContract');
        }

        actions.push('logs');

        return actions;
    }

    renderRow({ item }) {
        const { currentItems } = this.state;
        const { getParent, type } = this.props;
        const { _id, number, name, executorName, createdDate, actionDate, fileName, inactive } =
            item;
        const parent = getParent();
        const actions = this.getActionsItems({ item });
        const contractName = type === 'userProxy' ? 'Доверенность' : 'Договор';
        let actionName;

        if (inactive) {
            actionName = (
                <>
                    {contractName} {type === 'userProxy' ? 'расторгнута' : 'расторгнут'}
                </>
            );
        } else if (fileName) {
            actionName = (
                <>
                    {contractName} {type === 'userProxy' ? 'готова' : 'готов'}
                </>
            );
        } else {
            actionName = <>{contractName} генерируется</>;
        }

        return (
            <div
                className={`settingsUsersTable__row ${currentItems.includes(_id) ? '_active' : ''}`}
                key={_id}
                data-key={_id}
            >
                <div className="settingsUsersTable__rowInner _row">
                    <div className="settingsUsersTable__col _name">
                        <div className="settingsUsersTable__text _active">
                            <div className="settingsUsersTable__textInner">
                                <div className="settingsUsersTable__checkbox _row">
                                    <div className="settingsUsersTable__checkboxInner">
                                        <Checkbox
                                            className="_square _blue"
                                            name={_id}
                                            value={currentItems.includes(_id)}
                                            handler={() => this.handlerCurrent(_id)}
                                        />
                                    </div>
                                    <div className="settingsUsersTable__checkboxItem">
                                        {name}, №{number}
                                        <div className="settingsUsersTable__checkboxDescription _col">
                                            <AnimateChangeUp
                                                className="settingsUsersTable__contractFile"
                                                renderKey={`${!!fileName}${inactive}`}
                                            >
                                                {actionName}
                                                {!inactive && !fileName && (
                                                    <>
                                                        {' '}
                                                        <i className="settingsUsersTable__contractFileLoader">
                                                            <Loader className="_grey" />
                                                        </i>
                                                    </>
                                                )}
                                            </AnimateChangeUp>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.user?.idOfCurrentCorporation === 'admin' && (
                        <div className="settingsUsersTable__col _corporationInfo">
                            <CorporationInfo {...(item.corporationInfo?.[0] || [])} />
                        </div>
                    )}
                    <div className="settingsUsersTable__col _executorName">
                        <div className="settingsUsersTable__text _active">
                            {type === 'userProxy' ? (
                                <>
                                    <div className="settingsUsersTable__textInner _border">
                                        <span>{executorName}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Link
                                        className="settingsUsersTable__textInner _link _border"
                                        pageName="manual-executors-inner"
                                        ids={{ 3: item.executorId }}
                                    >
                                        <span>{executorName}</span>
                                    </Link>
                                </>
                            )}
                        </div>
                        {this.state.user?.idOfCurrentCorporation === 'admin' &&
                            this.renderStatus({ item })}
                    </div>
                    {this.state.user?.idOfCurrentCorporation !== 'admin' && (
                        <div className="settingsUsersTable__col _status">
                            {this.renderStatus({ item })}
                        </div>
                    )}
                    <div className="settingsUsersTable__col _createdDate">
                        <div className="settingsUsersTable__text _active">
                            <div className="settingsUsersTable__textInner">
                                {getFormatedDate({ date: new Date(createdDate) })}
                            </div>
                        </div>
                    </div>
                    <div className="settingsUsersTable__col _endDate">
                        <div className="settingsUsersTable__text _active">
                            <div className="settingsUsersTable__textInner">
                                {getFormatedDate({ date: new Date(actionDate) })}
                            </div>
                        </div>
                        {actions.length > 0 && (
                            <div
                                className="settingsUsersTable__colMore _click"
                                onClick={({ target }) => {
                                    handlerWindow({
                                        parent,
                                        parentResize: parent,
                                        parentScroll: parent.querySelector(
                                            '.settingsUsersTable__content',
                                        ),
                                        target: target.closest('.settingsUsersTable__colMore'),
                                        action: 'show',
                                        name: 'contractActions',
                                        className: '_actions _right',
                                        uniqKey: `contract-${_id}`,
                                        contract: item,
                                        actions,
                                        isCheckScrollPosition: false,
                                        isHideFromScroll: true,
                                        centers: {
                                            left: 0.5,
                                            top: 0,
                                        },
                                    });
                                }}
                            >
                                <Icon name="more-vertical" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    updateContract({ id, fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const items = JSON.parse(JSON.stringify(newState.items || []));

            if (fields.joinContracts) {
                fields.joinContracts.forEach((joinContract) => {
                    const itemIndex = items.findIndex((item) => item._id === joinContract._id);

                    if (itemIndex !== -1) {
                        Object.keys(joinContract).forEach((key) => {
                            items[itemIndex][key] = joinContract[key];
                        });
                    }
                });
            } else {
                const itemIndex = items.findIndex((item) => item._id === id);

                if (itemIndex !== -1) {
                    Object.keys(fields).forEach((key) => {
                        items[itemIndex][key] = fields[key];
                    });
                }
            }

            newState.items = items;

            return newState;
        });
    }

    getQueryForRequest() {
        const { type, executorId } = this.props;
        const query = super.getQueryForRequest();

        if (type) {
            query.params.push({ key: 'type', value: type });
        }

        if (executorId) {
            query.params.push({ key: 'executorId', value: executorId });
        }

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getJoinContracts(query).then(
                ({ joinContracts, isLimit, counter, completedCounter, waitedCounter }) => {
                    this.setItems(joinContracts, false, isLimit, counter).then(() => {
                        this.setState({ completedCounter, waitedCounter }, resolve);
                    });
                },
                () => null,
            );
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setSign(all) {
        const { type, executorId } = this.props;
        const resultItems = all === 'all' ? ['all'] : this.state.currentItems;

        handlerPopup({
            name: 'signContractPopup',
            isShow: true,
            items: resultItems,
            renderStatus: this.renderStatus,
            successCallback: () => {
                this.setState({ currentItems: [] });
            },
            type,
            executorId,
        });
    }

    refreshContracts() {
        const { type } = this.props;
        const { currentItems = [] } = this.state;

        const complete = () => {
            this.handerLoading(null);

            this.setState({ currentItems: [] });
        };

        this.handerLoading('refresh').then(() => {
            refreshJoinContracts({ itemsIds: currentItems, type }).then(complete, complete);
        });
    }

    filterCallback({ filter, isChange }) {
        return new Promise((resolve) => {
            resolve();

            if (isChange) {
                this.setState({ filter }, () => {
                    this.updateItems();
                });
            }
        });
    }

    getFilter() {
        const { user } = this.state;
        const { type, executorId } = this.props;

        getFilter({ name: 'joinContracts' }).then(
            ({ blocks }) => {
                blocks = blocks.filter(
                    (block) =>
                        !type ||
                        type !== 'userProxy' ||
                        checkHolder({ user }) ||
                        block.key !== 'searchForName',
                );

                blocks = blocks.filter((block) => !executorId || block.key !== 'searchForName');

                this.initFilter({
                    blocks,
                });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        const { initCallbackFilter } = this.props;

        this.handlerFilter.init({ blocks });

        initCallbackFilter(this.filterCallback);
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'joinContracts') {
            const { id, fields } = data;

            if (fields) {
                this.updateContract({ id, fields });
            }
        }
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });

        super.componentDidMount();

        this.getFilter();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        document.addEventListener('newJoinContract', this.updateItems);
        document.addEventListener('getSocketData', this.handlerSocket);

        document.dispatchEvent(
            new CustomEvent('changeNavCounter', { detail: { name: 'joins-contracts' } }),
        );
    }

    componentWillUnmount() {
        document.removeEventListener('newJoinContract', this.updateItems);
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const {
            isReady,
            items = [],
            isDisabledScroll,
            isLoadingFilter,
            isLimit,
            isShowLoaderList,
            currentItems,
            filterUpdateKey,
            loadingKey,
            filter,
            counter = 0,
            completedCounter = 0,
            waitedCounter = 0,
            user,
        } = this.state;
        const { getParent, type } = this.props;
        const itemsIndexes = {};

        items.forEach((item) => {
            itemsIndexes[item._id] = item;
        });

        const currentCounter = currentItems.length;
        const condForFresh = currentItems.every(
            (id) => !['process', 'inactive'].includes(itemsIndexes[id]?.status),
        );
        let condForSign = currentItems.every((id) =>
            ['wait', 'executorSign'].includes(itemsIndexes[id]?.status),
        );

        if (type === 'userProxy' && getCurrentCorporation({ user })?.role !== 'holder') {
            condForSign = currentItems.every((id) =>
                ['wait', 'corporationSign'].includes(itemsIndexes[id]?.status),
            );
        }

        return (
            <div ref={this.parent} className={`widget _notPadding ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <div
                                className={`settingsUsersTable _joinContracts ${
                                    isReady ? '_init' : ''
                                } ${isLoadingFilter ? '_processFilter' : ''}`}
                            >
                                <div className="settingsUsersTable__inner">
                                    <Animate
                                        className="settingsUsersTable__loader _loader _full"
                                        isShow={!isReady}
                                    >
                                        <div className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <Animate
                                        className="settingsUsersTable__loader _loader"
                                        isShow={isLoadingFilter}
                                    >
                                        <div className="settingsUsersTable__loaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <div className="settingsUsersTable__wrapper">
                                        <div className="settingsUsersTable__head _static">
                                            <div className="settingsUsersTable__headInner _row">
                                                <div className="settingsUsersTable__headPaysBlock _col">
                                                    <div className="settingsUsersTable__headPaysTitle">
                                                        <>
                                                            <b>
                                                                Всего{' '}
                                                                {type === 'userProxy'
                                                                    ? 'доверенностей'
                                                                    : 'договоров'}
                                                                :
                                                            </b>
                                                            <AnimateChangeUp
                                                                className="settingsUsersTable__headPaysTitleItem"
                                                                renderKey={counter}
                                                            >
                                                                {counter}
                                                            </AnimateChangeUp>
                                                        </>
                                                    </div>
                                                    <div className="settingsUsersTable__headPaysDescription _row">
                                                        <div className="settingsUsersTable__headPaysDescriptionItem _completed">
                                                            Подписано: {completedCounter}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="settingsUsersTable__headPaysBlock _col _actions">
                                                    <div className="settingsUsersTable__headPaysCurrent _row">
                                                        Выбрано:{' '}
                                                        <AnimateChangeUp
                                                            className="settingsUsersTable__headPaysCurrentItem"
                                                            renderKey={currentCounter}
                                                        >
                                                            {currentCounter}
                                                        </AnimateChangeUp>
                                                    </div>
                                                    {this.getPermissions({
                                                        adminCorporation: true,
                                                        key: 'joins',
                                                        items: [
                                                            {
                                                                key: 'contracts',
                                                                rules: [],
                                                                actions: ['refresh'],
                                                            },
                                                        ],
                                                    }) &&
                                                        (!type || checkHolder({ user })) && (
                                                            <>
                                                                <div className="settingsUsersTable__headPaysButton">
                                                                    <Button
                                                                        className="_crm _mainNotBorder"
                                                                        isDisabled={
                                                                            currentCounter === 0 ||
                                                                            !condForFresh
                                                                        }
                                                                        onClick={
                                                                            this.refreshContracts
                                                                        }
                                                                        showLoader={
                                                                            loadingKey === 'refresh'
                                                                        }
                                                                    >
                                                                        Обновить
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    {(this.getPermissions({
                                                        notAdminCorporation: true,
                                                        key: 'joins',
                                                        items: [
                                                            {
                                                                key: 'contracts',
                                                                rules: ['sign'],
                                                            },
                                                        ],
                                                    }) ||
                                                        type === 'userProxy') && (
                                                        <>
                                                            <div className="settingsUsersTable__headPaysButton">
                                                                <Button
                                                                    className="_crm _mainNotBorder"
                                                                    isDisabled={
                                                                        currentCounter === 0 ||
                                                                        !condForSign
                                                                    }
                                                                    onClick={this.setSign}
                                                                    showLoader={
                                                                        loadingKey === 'setSign'
                                                                    }
                                                                >
                                                                    Подписать
                                                                </Button>
                                                            </div>

                                                            <div className="settingsUsersTable__headPaysButton">
                                                                <Button
                                                                    className="_crm _mainNotBorder"
                                                                    isDisabled={waitedCounter === 0}
                                                                    onClick={this.setSign.bind(
                                                                        this,
                                                                        'all',
                                                                    )}
                                                                    showLoader={
                                                                        loadingKey === 'setSign'
                                                                    }
                                                                >
                                                                    Подписать все
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )}
                                                    {type === 'userProxy' &&
                                                        checkHolder({ user }) &&
                                                        user.idOfCurrentCorporation !== 'admin' && (
                                                            <>
                                                                <div className="settingsUsersTable__headPaysButton">
                                                                    <Button
                                                                        className="_crm _mainNotBorder"
                                                                        onClick={() => {
                                                                            handlerPopup({
                                                                                name: 'joinContractPopup',
                                                                                isShow: true,
                                                                                type,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Создать
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    {user?.idOfCurrentCorporation !== 'admin' && (
                                                        <div
                                                            className="widget__headAction settingsUsersTable__headPaysButton _grey"
                                                            onClick={this.handlerExport.bind(this)}
                                                        >
                                                            <div className="widget__headActionInner _col _click">
                                                                <i className="widget__headActionIcon">
                                                                    <Icon name="widget-export" />
                                                                </i>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="settingsUsersTable__headPaysButton">
                                                        <WidgetFilter
                                                            name="joinContracts"
                                                            filter={filter}
                                                            handlerFilter={this.handlerFilter}
                                                            getParent={getParent}
                                                            className="_grey"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settingsUsersTable__contentWrapper">
                                            <Animate
                                                className="settingsUsersTable__empty"
                                                isShow={items.length === 0 && !isLoadingFilter}
                                            >
                                                <div className="empty _col _notBack _block">
                                                    <div className="empty__inner">
                                                        <div className="empty__title">
                                                            На данный момент{' '}
                                                            {type === 'userProxy'
                                                                ? 'доверенностей'
                                                                : 'договоров'}{' '}
                                                            нет
                                                        </div>
                                                        <p className="empty__content">
                                                            Они будут отображаться здесь в виде
                                                            карточек
                                                        </p>
                                                    </div>
                                                </div>
                                            </Animate>
                                            <div
                                                className="settingsUsersTable__content JSTransVertical"
                                                onScroll={({ target }) => {
                                                    localStorage.setItem(
                                                        'paysGroupsScroll',
                                                        target.scrollTop,
                                                    );
                                                }}
                                            >
                                                <div className="settingsUsersTable__row _head">
                                                    <div className="settingsUsersTable__col _name">
                                                        Название
                                                    </div>
                                                    {user?.idOfCurrentCorporation === 'admin' && (
                                                        <div className="settingsUsersTable__col _corporationInfo">
                                                            Корпорация
                                                        </div>
                                                    )}
                                                    <div className="settingsUsersTable__col _executorName">
                                                        ФИО
                                                    </div>
                                                    {user?.idOfCurrentCorporation !== 'admin' && (
                                                        <div className="settingsUsersTable__col _status">
                                                            Статус
                                                        </div>
                                                    )}
                                                    <div className="settingsUsersTable__col _createdDate">
                                                        Создан
                                                    </div>
                                                    <div className="settingsUsersTable__col _endDate">
                                                        Окончание
                                                    </div>
                                                </div>
                                                <div className="settingsUsersTable__contentInner">
                                                    <div
                                                        className="settingsUsersTable__rows _col"
                                                        key={filterUpdateKey}
                                                    >
                                                        {isReady && (
                                                            <ListScroll
                                                                getParent={() =>
                                                                    this.parent.current?.querySelector(
                                                                        '.settingsUsersTable__content',
                                                                    )
                                                                }
                                                                callback={this.getMoreItems}
                                                                startCounter={this.stepCounter}
                                                                stepCounter={this.stepCounter}
                                                                maxCounter={Infinity}
                                                                lengthCurrent={items.length}
                                                                keyUpdate={items.length}
                                                                isDisabledScroll={
                                                                    isDisabledScroll ||
                                                                    isLoadingFilter
                                                                }
                                                                isLimit={isLimit}
                                                                handlerLoaderList={
                                                                    this.handlerLoaderList
                                                                }
                                                            >
                                                                {items.map((item, key) =>
                                                                    this.renderRow({ item, key }),
                                                                )}
                                                            </ListScroll>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <Animate
                                                className="settingsUsersTable__listLoader _loaderScroll _white"
                                                isShow={isShowLoaderList}
                                            >
                                                <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                                    <Loader className="_main" />
                                                </div>
                                            </Animate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinsContractsMain);

JoinsContractsMain.propTypes = {
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    user: PropTypes.object,
    getParent: PropTypes.func,
    type: PropTypes.string,
    executorId: PropTypes.string,
};
