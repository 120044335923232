import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';

import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';

class LinkActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterItem = this.filterItem.bind(this);

        this.parent = React.createRef();
    }

    actions = {
        'new-order': {
            content: '+ Создать заказ',
            href: 'order-details-main',
            list: [
                {
                    key: 'crm',
                    icon: 'order-system-type-crm',
                    content: 'Через сервис',
                },
                {
                    key: 'service',
                    icon: 'order-system-type-service',
                    content: 'Через службы доставок',
                },
            ],
        },
    };

    filterItem({ key }) {
        const { name, user } = this.props;

        if (name === 'new-order') {
            const corporation = getCurrentCorporation({ user });

            return key !== 'service' || !corporation.isTest;
        }

        return true;
    }

    render() {
        const { name, className = '' } = this.props;
        const action = this.actions[name];

        return (
            <div className={`widget__headLink _withList _main ${className}`}>
                <div className="widget__headLinkInner _click">
                    <div className="widget__headLinkContent">{action?.content}</div>
                    <div className="widget__headLinkIcon _col" />
                </div>
                <div className="widget__headLinkActions">
                    <div className="widget__headLinkActionsInner">
                        {action?.list.filter(this.filterItem).map((item) => {
                            const { key, content, icon } = item;

                            return (
                                <div className="widget__headLinkActionsItem _click" key={key}>
                                    <Link
                                        className="widget__headLinkActionsItemInner"
                                        pageName={action.href}
                                        ids={{ 2: key }}
                                    >
                                        <div className="widget__headLinkActionsItemIcon">
                                            <i className="widget__headLinkActionsItemIconInner">
                                                <Icon name={icon} />
                                            </i>
                                        </div>
                                        {content}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(LinkActions);

LinkActions.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    user: PropTypes.object,
};
