import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../Icon.jsx';
import getFormatedDate from '../../../../functions/getFormatedDate';

class ManualExecutorsVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { executor } = this.props;
        const verification = executor.verification;

        return (
            <div ref={this.parent} className={`manualCard _executorVerification`}>
                <div className="manualCard__content _fullHeight">
                    <div className={`settingsMain__verification _col _${verification.status}`}>
                        <div className="settingsMain__verificationIcon">
                            <Icon name="corporation-verify" />
                        </div>
                        <div className="settingsMain__verificationTitle _parent">
                            {verification.status === 'active' ? (
                                <>Верификация пройдена</>
                            ) : (
                                <>Верификация не пройдена</>
                            )}
                        </div>
                        <div className="settingsMain__verificationDescription">
                            {verification.status === 'active' ? (
                                <>
                                    {getFormatedDate({
                                        date: new Date(verification.date),
                                        type: 'full',
                                    })}
                                </>
                            ) : verification.status === 'wait' ? (
                                <>Исполнитель на проверке</>
                            ) : (
                                <>Исполнитель на доработке</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsVerification);

ManualExecutorsVerification.propTypes = {
    executor: PropTypes.object,
};
