export default function getExecutorIcon({ type }) {
    if (type === 'executor') {
        return 'courier.png';
    }

    if (type === 'driver') {
        return 'driver.png';
    }

    if (type === 'worker') {
        return 'worker.png';
    }

    if (type === 'builder') {
        return 'builder.png';
    }

    if (type === 'courier') {
        return 'courier.png';
    }

    if (type === 'auto-courier') {
        return 'auto-courier.png';
    }

    return null;
}
