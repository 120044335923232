import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../../../components/crm/manual/Table.jsx';
import getTaxes from '../../../../requests/getTaxes';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import handlerWindow from '../../../../functions/handlerWindow';
import TableInfo from '../../../../components/crm/manual/TableInfo.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class PaysTaxMain extends Table {
    constructor(props) {
        super(props);

        const nowDate = new Date();

        nowDate.setUTCHours(nowDate.getUTCHours() + 3);

        let month = nowDate.getUTCMonth() - 1;

        if (month === -1) {
            month = 11;
        }

        this.state = {
            month,
        };

        this.handlerMore = this.handlerMore.bind(this);
        this.getActionsItems = this.getActionsItems.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    monthNames = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];

    getActionsItems({ item }) {
        const items = ['logs'];

        if (
            item?.fnsTax === 'active' &&
            this.getPermissions({
                adminCorporation: true,
                key: 'pays',
                items: [{ key: 'tax', rules: [], actions: ['setInactive'] }],
            })
        ) {
            items.unshift('removeTax');
        }

        if (
            item.totalAmount > 0 &&
            !['withdraw-process', 'inactive-process'].includes(item.fnsTax) &&
            this.getPermissions({
                adminCorporation: true,
                key: 'pays',
                items: [{ key: 'tax', rules: [], actions: ['withdrawAmount'] }],
            })
        ) {
            items.unshift('withdrawAmount');
        }

        return items;
    }

    handlerMore({ _id }, e) {
        const { items = [] } = this.state;
        const { getParent, windows } = this.props;
        const parent = getParent();
        const item = items.find((innerItem) => innerItem._id === _id);
        const actionsItems = this.getActionsItems({ item });
        const { target } = e;

        e.stopPropagation();

        const key = `tax-${_id}`;

        if (windows[key]) {
            handlerWindow({
                action: 'hide',
            });
        } else {
            handlerWindow({
                parent,
                parentResize: parent,
                parentScroll: this.parent.current?.querySelector('.tableInfo__scroll'),
                target: target.closest('.tableInfo__more'),
                action: 'show',
                name: 'taxActions',
                className: '_actions _right',
                uniqKey: key,
                executorId: _id,
                items: actionsItems,
                centers: {
                    left: 1,
                    top: 0,
                },
                isHideFromScroll: true,
                isCheckScrollPosition: false,
                taxCallback: () => this.updateItems(),
            });
        }
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getTaxes(query).then(({ taxes, isLimit, month, counter }) => {
                this.setItems(taxes, false, isLimit, counter).then(() => {
                    this.setState({ month });

                    resolve();
                });
            });
        });
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });

        super.componentDidMount();
    }

    render() {
        const {
            isReady,
            items = [],
            isLimit,
            isDisabledScroll,
            month = 0,
            isLoadingFilter = false,
        } = this.state;

        return (
            <div ref={this.parent} className={`widget _notPadding ${isReady ? '_ready' : ''}`}>
                <Animate className="widget__loader _loader" isShow={!isReady}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox">
                            <TableInfo
                                isReady={isReady}
                                items={items}
                                name="paysTax"
                                setHeightPage={this.setHeightPage}
                                getMoreItems={this.getMoreItems}
                                isLimit={isLimit}
                                stepCounter={this.stepCounter}
                                isDisabledScroll={isDisabledScroll}
                                isLoading={isLoadingFilter}
                                isWrap={true}
                                isBlock={true}
                                isNotLink={true}
                                popupCallback={this.popupCallback}
                                getActionsItems={this.getActionsItems}
                                handlerMore={this.handlerMore}
                                otherData={{ monthName: this.monthNames[month] }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        heightWindow: state.heightWindow,
        user: state.user,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(PaysTaxMain);

PaysTaxMain.propTypes = {
    user: PropTypes.object,
    setHeightPage: PropTypes.func,
    heightWindow: PropTypes.number,
    windows: PropTypes.object,
};
