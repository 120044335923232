import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Filter from '../../../components/Filter.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import Windows from '../../../components/Windows.jsx';

import handlerWindow from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import Main from './blog/Main.jsx';
import Inner from './blog/Inner.jsx';
import HeadInner from './blog/inner/HeadInner.jsx';

import EditPanel from '../../../components/crm/content/EditPanel.jsx';
import shortText from '../../../functions/shortText';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';
import getHeaders from '../../../functions/getHeaders';
import getPageLink from '../../../functions/getPageLink';
import setPermissions from '../../../functions/crm/setPermissions';

class ContentBlog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.renderEditPanel = this.renderEditPanel.bind(this);

        this.setFilter = this.setFilter.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.checkNew = this.checkNew.bind(this);
        this.renderWindowsPromptDelete = this.renderWindowsPromptDelete.bind(this);
        this.checkRights = this.checkRights.bind(this);
        this.delete = this.delete.bind(this);

        this.parent = React.createRef();

        setPermissions.call(this);
    }

    getInfo() {
        const { name } = this.props;

        if (name === 'announce') {
            return {
                name: 'announce',
                pageName: 'announce',
                pageText: 'Анонсы',
            };
        }

        if (name === 'expressBlog') {
            return {
                name: 'expressBlog',
                pageName: 'expressBlog',
                pageText: 'Блог Livecargo',
            };
        }

        if (name === 'legal') {
            return {
                name: 'legal',
                pageName: 'legal',
                pageText: 'Правовая информация',
            };
        }

        return {
            name: 'blog',
            pageName: 'blog',
            pageText: 'Блог',
        };
    }

    headPages = {
        [`content-${this.getInfo().pageName}-main`]: {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        {this.getInfo().pageText}{' '}
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        [`content-${this.getInfo().pageName}-inner`]: {
            render() {
                const { infoHead } = this.state;
                const { article } = infoHead;
                const name = this.checkNew()
                    ? 'Новая статья'
                    : shortText({ str: article?.title || '', stop: 32 });

                return (
                    <div className="widget__headNameItemContent">
                        {this.getInfo().pageText} – {name}
                    </div>
                );
            },
        },
    };

    headActions = {
        [`content-${this.getInfo().pageName}-main`]: {
            render() {
                const { filter } = this.state;

                return (
                    <TableHead
                        name={`content-${this.getInfo().pageName}`}
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        [`content-${this.getInfo().pageName}-inner`]: {
            render() {
                const { infoHead } = this.state;
                const { article } = infoHead;

                return (
                    <HeadInner
                        name={this.getInfo().name}
                        article={article}
                        isNew={this.checkNew()}
                        checkRights={this.checkRights}
                        delete={this.delete}
                    />
                );
            },
        },
    };

    pages = {
        [`content-${this.getInfo().pageName}-main`]: {
            render() {
                const { isShowLoaderList, filter } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                `.widget__page._content-${
                                                    this.getInfo().pageName
                                                }-main`,
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    handlerLoaderList={this.handlerLoaderList}
                                    setInfoHead={this.setInfoHead}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    checkRights={this.checkRights}
                                    name={this.getInfo().name}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        [`content-${this.getInfo().pageName}-inner`]: {
            render() {
                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Inner
                                    setInfoHead={this.setInfoHead}
                                    checkNew={this.checkNew}
                                    getParent={() => this.parent.current}
                                    checkRights={this.checkRights}
                                    name={this.getInfo().name}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    checkRights() {
        return this.getPermissions({
            key: 'content',
            items: [{ key: this.getInfo().name, rules: ['update'] }],
        });
    }

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const idOfArticle = levels[3];

        if (
            levels[1] === 'content' &&
            levels[2] === this.getInfo().pageName &&
            idOfArticle !== 'main' &&
            idOfArticle
        ) {
            this.isNew = idOfArticle === 'new';
        }

        return this.isNew;
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'articles' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderEditPanel({ selection, handlerEditText }) {
        return <EditPanel selection={selection} callback={handlerEditText} />;
    }

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { article } = infoHead;
        const id = article?._id;
        const windowId = `delete-article-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/article?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (!isForce) {
                                if (success) {
                                    handlerWindow({
                                        parent: this.parent.current,
                                        parentResize: this.parent.current,
                                        target: this.parent.current.querySelector(
                                            '.widget__headLink._delete',
                                        ),
                                        action: 'show',
                                        name: 'promptDelete',
                                        className: '_prompt _center',
                                        uniqKey: windowId,
                                        watchesProps: { left: true },
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }

                                resolve();
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });

                                if (success) {
                                    setNotification({ notification: 'success-change-info' });

                                    resolve();
                                } else {
                                    reject();
                                }
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            changePage({
                                href: getPageLink({
                                    name: `content-${this.getInfo().pageName}-main`,
                                }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    componentDidMount() {
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="editPanel" renderContent={this.renderEditPanel} />
                <Windows name="promptDelete" renderContent={this.renderWindowsPromptDelete} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Контент"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === `content-${this.getInfo().pageName}` &&
                                        page.level === 3
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === `content-${this.getInfo().pageName}` &&
                                        page.level === 3
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === `content-${this.getInfo().pageName}` &&
                            page.level === 3
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        windows: state.windows,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ContentBlog);

ContentBlog.propTypes = {
    name: PropTypes.string,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    windows: PropTypes.object,
    user: PropTypes.object,
};
