import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerServices from '../../classes/order/Services';

import setServerData from '../../functions/setServerData';

import Animate from '../Animate.jsx';
import Icon from '../Icon.jsx';
import AnimateChange from '../AnimateChange.jsx';
import Field from '../Field.jsx';
import Loader from '../Loader.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Switch from '../Switch.jsx';

const temperaturesContent = require('../../infos/order/temperatures.json');

class DetailsServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.sortServices = this.sortServices.bind(this);
        this.filterService = this.filterService.bind(this);
        this.renderService = this.renderService.bind(this);
        this.getSortServices = this.getSortServices.bind(this);
        this.setParamsServices = this.setParamsServices.bind(this);

        this.parent = React.createRef();
    }

    heightItem = 32;

    orderActions = ['add', 'delete'];

    defaultServices = ['workers', 'passengers'];

    handlerServices = new HandlerServices({
        context: this,
    });

    renderService({ item: service, prop: id }) {
        const { order, points, serverData, handlerServices, isDisabled, isNew, handlerSmsService } =
            this.props;
        const { orderServices, orderServicesInfo, orderServiceTariffs } = serverData;
        const currentService = order.services.find((item) => item.key === service.key);
        const { tariffInfo } = order;
        let max = 2;
        let isProccessDisabled = false;

        if (order.status === 'in-proccess' && !this.defaultServices.includes(service.key)) {
            isProccessDisabled = true;
        }

        if (service.key === 'passengers') {
            max = tariffInfo?.parameters?.passengers;
        }

        if (service.key === 'workers' && order.status === 'in-proccess') {
            max = 1;
        }

        let serviceInfo =
            orderServicesInfo && orderServicesInfo.find((item) => item.key === service.key);
        let serviceData = orderServices && orderServices.find((item) => item.key === service.key);

        if (order?.systemType === 'service') {
            serviceData = orderServiceTariffs?.services?.find((item) => item.key === service.key);
        }

        if (service.key === 'sms' && order?.systemType === 'crm') {
            serviceInfo = { name: 'Смс-уведомление' };
            serviceData = { type: 'switch' };
        }

        const handlerField = ({ action, name, value }) =>
            new Promise((resolve) => {
                const values = [];

                if (service.type === 'temperature') {
                    const valueTemperature = { ...currentService.otherData, [name]: value };

                    values.push({ name: service.key, key: 'otherData', value: valueTemperature });
                } else {
                    values.push({ name: service.key, value });
                }

                if (action === 'change') {
                    handlerServices({ action, values }).then(resolve);
                }
            });

        let content;
        let value = !!currentService;

        if (serviceData.type === 'switch') {
            if (service.key === 'sms') {
                value = points?.every((point) => point.withSms);
            }
        }

        if (serviceData.type === 'counter' || serviceData.type === 'number') {
            content = (
                <div className="orderDetailsCard__serviceCounter">
                    <Field
                        keyName={service.key === 'cargoAmount' ? 'price' : 'counterServices'}
                        name="counter"
                        max={serviceData.type === 'number' ? Infinity : max}
                        value={currentService?.value}
                        callback={handlerField}
                        className={`_orderService _notBack`}
                        group={service.key}
                        isDisabled={!isNew && isDisabled}
                        isEditmode={isNew}
                    />
                </div>
            );
        } else if (serviceData.type === 'temperature') {
            content = (
                <div className="orderDetailsCard__serviceTemperatures _row">
                    {temperaturesContent.map((item) => (
                        <div className="orderDetailsCard__serviceTemperature _row" key={item.key}>
                            <p className="orderDetailsCard__serviceTemperatureSupport">
                                {item.support}
                            </p>
                            <div className="orderDetailsCard__serviceTemperatureField">
                                <Field
                                    keyName={item.name}
                                    name={item.key}
                                    value={currentService?.otherData?.[item.key] || ''}
                                    callback={handlerField}
                                    className={`_mediumPadding _notBack _mediumSize`}
                                    group="services-temperatures"
                                    isDisabled={!isNew && isDisabled}
                                    isEditmode={isNew}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            content = <div className="orderDetailsCard__serviceValue _row">Да</div>;
        }

        return (
            <div className={`orderDetailsCard__service _${id} _${serviceData.type}`}>
                <div className="orderDetailsCard__serviceInner _row">
                    <div className="orderDetailsCard__serviceName">{serviceInfo?.name}</div>
                    <div className="orderDetailsCard__serviceContent _row">
                        {serviceData.type !== 'switch' && (
                            <Animate
                                isShow={
                                    !!currentService &&
                                    (isDisabled ||
                                        serviceData.type !== 'checkbox' ||
                                        isProccessDisabled)
                                }
                                className={`orderDetailsCard__serviceBox _row ${
                                    serviceData.type !== 'checkbox' && !isDisabled ? '_active' : ''
                                }`}
                            >
                                {content}
                            </Animate>
                        )}

                        {serviceData.type === 'switch' ? (
                            <>
                                <div className="orderDetailsCard__serviceSwitch">
                                    <Switch
                                        value={value}
                                        name="withSms"
                                        className="_size3"
                                        isDisabled={isDisabled || isProccessDisabled}
                                        handler={() => {
                                            if (!isDisabled) {
                                                if (service.key === 'sms') {
                                                    handlerSmsService();
                                                } else {
                                                    handlerServices({
                                                        action: 'toggle',
                                                        values: [
                                                            { name: service.key, value: true },
                                                        ],
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <Animate
                                    isShow={!isDisabled && !isProccessDisabled}
                                    isSmoothShow={true}
                                    className="orderDetailsCard__serviceAction"
                                >
                                    <div
                                        className={`action _col _animate _mediumSize ${
                                            currentService ? '_delete' : '_add'
                                        }`}
                                        onClick={() => {
                                            if (!isDisabled) {
                                                handlerServices({
                                                    action: 'toggle',
                                                    values: [{ name: service.key, value: true }],
                                                });
                                            }
                                        }}
                                    >
                                        {this.orderActions.map((action) => (
                                            <Animate
                                                key={action}
                                                className={`action__icon _${action}`}
                                                isShow={
                                                    action === 'add'
                                                        ? !currentService
                                                        : !!currentService
                                                }
                                            >
                                                <Icon name={action} />
                                            </Animate>
                                        ))}
                                    </div>
                                </Animate>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    sortServices(a, b) {
        const services = this.getOrderServices();
        const weightA = services.find((service) => service.key === a.key) ? 1 : 0;
        const weightB = services.find((service) => service.key === b.key) ? 1 : 0;

        return weightB - weightA;
    }

    filterService(key) {
        const { order, isDisabled } = this.props;

        return isDisabled
            ? order.services.findIndex((service) => service.key === key) !== -1
            : true;
    }

    setParamsServices(params = {}) {
        const { height: servicesHeight } = params;

        if (servicesHeight !== this.state.servicesHeight) {
            this.setState({ servicesHeight });
        }
    }

    getSortServices(items = []) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortServices);
    }

    getServices() {
        const { serverData, order, isDisabled, points } = this.props;
        const { orderServices, orderServiceTariffs } = serverData;

        let services = (
            (orderServices &&
                order &&
                order.tariffInfo &&
                orderServices.filter(
                    (item) =>
                        (item.forTariffs.indexOf(order.tariffInfo.typeConfig) !== -1 &&
                            item.forTypes.indexOf(order.type) !== -1) ||
                        (item.isAlways === true &&
                            order.tariffInfo.services?.map((el) => el.name).indexOf(item.key) !==
                                -1),
                )) ||
            []
        )
            .filter((service) =>
                this.handlerServices.filterService({
                    service,
                    currentItem: order?.tariffInfo,
                    typeOfTariff: order?.tariffInfo.typeConfig,
                    points: order?.route,
                    type: order.type,
                    services: orderServices,
                }),
            )
            .filter(
                (service) =>
                    order.status !== 'in-proccess' ||
                    this.defaultServices.includes(service.key) ||
                    order.services.find((orderService) => orderService.key === service.key),
            );

        if (order?.systemType === 'service') {
            services = orderServiceTariffs?.services;
        }

        services = services?.filter(
            (service) =>
                !isDisabled ||
                order.services.find((serviceItem) => serviceItem.key === service.key),
        );

        if (
            process.env.REACT_APP_SYSTEM === 'crm' &&
            order?.systemType === 'crm' &&
            (!isDisabled || points?.every((point) => point.withSms)) &&
            order?.deliveryType === 'delivery'
        ) {
            services.push({
                key: 'sms',
            });
        }

        return services;
    }

    componentDidMount() {
        const promises = ['orderServices', 'orderServicesInfo', 'orderServiceTariffs'].map(
            (name) =>
                new Promise((resolve) => {
                    setServerData(name).then(resolve, () => null);
                }),
        );

        Promise.all(promises).then(
            () => {
                this.setState({ isGetServices: true });
            },
            () => null,
        );
    }

    getOrderServices() {
        const { order, points } = this.props;

        const services = JSON.parse(JSON.stringify(order?.services || []));

        if (
            process.env.REACT_APP_SYSTEM === 'crm' &&
            order?.systemType === 'crm' &&
            points?.every((point) => point.withSms)
        ) {
            services.push({ key: 'sms' });
        }

        return services;
    }

    render() {
        const { isGetServices, updateServices } = this.state;
        const { isDisabled, isNew } = this.props;
        const orderServices = this.getOrderServices();
        const services = this.getServices();
        const servicesActive =
            services?.filter((service) => orderServices.find((item) => item.key === service.key)) ||
            [];

        return (
            <div ref={this.parent} className={`orderDetailsCard _services ${isNew ? '_edit' : ''}`}>
                <div className="orderDetailsCard__inner">
                    <div className="orderDetailsCard__head _col">
                        <h5 className="orderDetailsCard__title">Услуги</h5>
                        <div className="orderDetailsCard__description _row">
                            Выбрано:{' '}
                            <AnimateChange
                                className="orderDetailsCard__descriptionItem"
                                classNameParent="_parent"
                                prop={servicesActive?.length}
                                type="_translateMedium"
                            >
                                {`${servicesActive?.length}`}
                            </AnimateChange>{' '}
                            доп. услуги
                        </div>
                    </div>
                    <div className="orderDetailsCard__content">
                        <Animate
                            className="orderDetailsCard__contentLoader _loader"
                            isShow={!isGetServices}
                        >
                            <div className="orderDetailsCard__contentLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <Animate
                            className="orderDetailsCard__contentEmpty"
                            isShow={isDisabled && isGetServices && servicesActive.length === 0}
                        >
                            <div className="empty _col _block _notBack">
                                <div className="empty__inner">
                                    <div className="empty__title">Услуги не выбраны</div>
                                    <p className="empty__content">
                                        Вы можете добавить их перейдя <br />в режим редактирования
                                    </p>
                                </div>
                            </div>
                        </Animate>
                        <Animate
                            className="orderDetailsCard__services"
                            isShow={isGetServices}
                            actionInit={() => {
                                // this.setState({ updateServices: new Date().getTime() });
                            }}
                        >
                            <ListAbsoluteMain
                                className="orderDetailsCard__servicesInner"
                                items={this.getSortServices(services)}
                                renderItem={this.renderService}
                                classNameItem="orderDetailsCard__service"
                                prop="key"
                                paramsParent={{ width: true }}
                                sort={this.getSortServices}
                                keyRender={`${updateServices}${servicesActive?.length}`}
                            />
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(DetailsServices);

DetailsServices.propTypes = {
    order: PropTypes.object,
    serverData: PropTypes.object,
    handlerServices: PropTypes.func,
    isDisabled: PropTypes.bool,
    counterUpdate: PropTypes.number,
    isNew: PropTypes.bool,
    points: PropTypes.array,
    handlerSmsService: PropTypes.func,
};
