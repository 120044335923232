import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import handlerLoading from '../../../functions/handlerLoading';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import Switch from '../../Switch.jsx';

class TagsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentType: 'card',
            fields: {},
        };

        this.save = this.save.bind(this);
        this.renderBlock = this.renderBlock.bind(this);
    }

    getTypes() {
        const { executorOrganization } = this.state;
        const typesOrder = ['card', 'account'];

        if (executorOrganization === 'FL') {
            typesOrder.splice(1, 1);
        }

        return typesOrder;
    }

    types = {
        card: {
            content: 'Банковская карта',
            fieldsOrder: ['cardNumber'],
            fields: {
                cardNumber: {
                    keyName: 'numberCard',
                    support: 'Номер карты',
                },
            },
        },
        account: {
            content: 'Реквизиты',
            fieldsOrder: ['bic', 'bankAccount', 'addressatName'],
            fields: {
                bic: {
                    keyName: 'bic',
                    support: 'БИК',
                },
                bankAccount: {
                    keyName: 'bankAccount',
                    support: 'Банк. счёт',
                },
                addressatName: {
                    keyName: 'addressatName',
                    support: 'ФИО получателя',
                },
            },
        },
    };

    handlerInput({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    fields[name] = value;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    renderBlock({ prop: key }) {
        const { fields, setCurrent, walletIsCurrent } = this.state;
        const type = this.types[key];
        const { fieldsOrder, fields: fieldsInfo } = type;

        return (
            <div className="crmPopup__block">
                {fieldsOrder.map((name) => {
                    const field = fieldsInfo[name];

                    return (
                        <div
                            className={`crmPopup__field _notBorder _height _col _${name}`}
                            key={name}
                        >
                            <div className="crmPopup__fieldSupport _upper">{field.support}:</div>
                            <div className="crmPopup__fieldBox">
                                <div className="crmPopup__input _content">
                                    <Field
                                        type="wallets"
                                        support="–"
                                        keyName={field.keyName}
                                        name={name}
                                        value={fields?.[name]}
                                        callback={this.handlerInput.bind(this)}
                                        className="_grey"
                                        stopPropagation={true}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                {!walletIsCurrent && (
                    <div className="crmPopup__field _notBorder _col">
                        <div className="crmPopup__fieldSupport _upper">Сделать основным:</div>
                        <div className="crmPopup__fieldBox _padding">
                            <div className="crmPopup__switch">
                                <Switch
                                    className="_size3"
                                    value={!!setCurrent}
                                    handler={() => {
                                        this.setState({ setCurrent: !setCurrent });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    checkChange() {
        const { currentType, wallet, fields, setCurrent } = this.state;
        // const type = this.types[currentType];
        let isChange = false;

        const clearValue = (value) => value?.replace(/[^\d]/gi, '');

        if (currentType === 'card') {
            if (clearValue(fields.cardNumber) && fields.cardNumber !== wallet?.number) {
                isChange = true;
            }
        }

        if (currentType === 'account') {
            if (clearValue(fields.bankAccount) && fields.bankAccount !== wallet?.number) {
                isChange = true;
            }

            if (clearValue(fields.bic) && fields.bic !== wallet?.bic) {
                isChange = true;
            }

            if (fields.addressatName && fields.addressatName !== wallet?.addressatName) {
                isChange = true;
            }
        }

        if (setCurrent) {
            isChange = true;
        }

        return isChange;
    }

    save() {
        const { fields, currentType, executorId, wallet, setCurrent } = this.state;
        const { callback } = this.props;
        const bodyFields = {};

        if (currentType === 'card') {
            bodyFields.number = fields.cardNumber;
        }

        if (currentType === 'account') {
            bodyFields.number = fields.bankAccount;
            bodyFields.bic = fields.bic;
            bodyFields.addressatName = fields.addressatName;
        }

        bodyFields.type = currentType;

        handlerLoading.call(this, 'save').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/executor`,
                    {
                        action: 'handler-wallet',
                        id: executorId,
                        fields: bodyFields,
                        walletId: wallet?._id || 'new',
                        setCurrent,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            setNotification({ notification: 'success-change-info' });

                            if (callback) {
                                callback().then(() => {
                                    handlerLoading.call(this, null);

                                    this.hide();
                                });
                            } else {
                                handlerLoading.call(this, null);
                            }
                        } else {
                            handlerLoading.call(this, null);

                            const { message } = data;

                            if (message === 'Card number already use') {
                                setNotification({ notification: 'bank-card-already-create' });
                            } else if (message === 'Card number is incorrect') {
                                setNotification({ notification: 'bank-card-error' });
                            } else if (message === 'Bank account already use') {
                                setNotification({ notification: 'bank-account-already-create' });
                            } else if (message === 'Bic is incorrect') {
                                setNotification({ notification: 'bic-is-error' });
                            }
                        }
                    },
                    () => {
                        handlerLoading.call(this, null);
                    },
                );
        });
    }

    hide() {
        handlerPopup({
            name: 'walletsPopup',
            isShow: false,
            wallet: null,
            executorOrganization: null,
            executorId: null,
        });
    }

    init() {
        const { wallet, executorId, executorOrganization, walletIsCurrent } = this.props;

        this.setState((state) => {
            const newState = { ...state };
            const fields = {};

            if (wallet) {
                newState.currentType = wallet.type;
                newState.wallet = JSON.parse(JSON.stringify(wallet));

                fields.cardNumber = wallet.number;
                fields.bankAccount = wallet.number;
                fields.bic = wallet.bic;
                fields.addressatName = wallet.addressatName;
            } else {
                newState.currentType = this.getTypes()[0];
            }

            newState.walletIsCurrent = walletIsCurrent;
            newState.executorId = executorId;
            newState.executorOrganization = executorOrganization;
            newState.fields = fields;

            return newState;
        });
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const { currentType, wallet, loadingKey } = this.state;
        const types = this.getTypes();

        return (
            <div ref={this.parent} className="crmPopup _col _wallets">
                <div className="crmPopup__inner">
                    <div
                        className={`crmPopup__head ${
                            !wallet && types.length > 1 ? '_notBorder' : ''
                        }`}
                    >
                        {wallet ? (
                            <>
                                <div className="crmPopup__title">Измените способ оплаты</div>
                                <div className="crmPopup__description">
                                    Вы можете изменить данные{' '}
                                    {currentType === 'card'
                                        ? 'банковской карты'
                                        : 'платёжных реквизитов'}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="crmPopup__title">Добавьте способ оплаты</div>
                                <div className="crmPopup__description">
                                    Заполните данные ниже <br />и сохраните их
                                </div>
                            </>
                        )}

                        <i className="crmPopup__close _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="crmPopup__content _padding _hidden">
                        {!wallet && types.length > 1 && (
                            <div className="crmPopup__tabs _row">
                                {types.map((name) => {
                                    const type = this.types[name];

                                    return (
                                        <label className="crmPopup__tab" key={name}>
                                            <input
                                                type="radio"
                                                className="crmPopup__tabInput"
                                                checked={currentType === name}
                                                onChange={() => {
                                                    this.setState({ currentType: name });
                                                }}
                                            />
                                            <div className="crmPopup__tabView">{type.content}</div>
                                        </label>
                                    );
                                })}
                            </div>
                        )}
                        <ListAbsoluteMain
                            className="crmPopup__blocks"
                            items={[{ key: currentType }]}
                            renderItem={this.renderBlock}
                            classNameItem="crmPopup__block"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                            currentItemKey={currentType}
                            allItems={this.getTypes()}
                        />
                    </div>
                    <div className="crmPopup__foot">
                        <div className="crmPopup__footButton _full">
                            <Button
                                className="_main _mainNotBorder"
                                onClick={this.save}
                                showLoader={loadingKey === 'save'}
                                isDisabled={!this.checkChange()}
                            >
                                {wallet ? 'Сохранить способ оплаты' : 'Добавить способ оплаты'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tagsPopup: state.tagsPopup,
    };
}

export default connect(mapStateToProps)(TagsPopup);

TagsPopup.propTypes = {
    wallet: PropTypes.object,
    executorId: PropTypes.string,
    executorOrganization: PropTypes.string,
    callback: PropTypes.func,
    walletIsCurrent: PropTypes.bool,
};
