import getEndText from '../getEndText';

export default function getCrewInfo({ order, executorNames }) {
    if (order) {
        const { crew, crewTemplate } = order;

        let description = ``;

        if (executorNames) {
            ['driver', 'worker'].forEach((type) => {
                const { length: len } = crewTemplate.filter(
                    (itemTemplate) =>
                        itemTemplate === type &&
                        (!crew.length || crew.find((itemCrew) => itemCrew.role === type)),
                );

                if (len) {
                    switch (type) {
                        case 'driver':
                            description += executorNames[type].content.toLowerCase();

                            if (
                                crewTemplate.find((item) => item === 'worker') &&
                                (crew.find((itemCrew) => itemCrew.role === 'worker') || crew.length === 0)
                            ) {
                                description += ' и ';
                            }

                            break;
                        default:
                            description += len;
                            description += ' ';
                            description += executorNames[type].end.content.toLowerCase();
                            description += getEndText(len, executorNames[type].end.items);

                            break;
                    }
                }
            });
        }

        if (description && crew.length) {
            return {
                title: 'Это весь экипаж',
                description: `Назначены: ${description}`,
                descriptionShort: `${description[0].toUpperCase()}${description.slice(1)}`,
            };
        }

        return {
            title: 'Идёт поиск экипажа',
            description: `Поиск: ${description}`,
            descriptionShort: `${description}`,
        };
    }

    return {};
}
