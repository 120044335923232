import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getModelsOfCar from '../../../../requests/getModelsOfCar';

import Main from './inner/Main.jsx';
import Logs from './inner/Logs.jsx';

import Pages from '../../../../components/Pages.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import getRealParams from '../../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../../functions/crm/getMaxHeightContentWidget';
import removeTransition from '../../../../functions/removeTransition.ts';

import Inner from '../../../../components/crm/manual/Inner.jsx';
import getModel from '../../../../requests/getModel';
import setPermissions from '../../../../functions/crm/setPermissions';

class ManualModelOfCarsInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getModelOfCar = this.getModelOfCar.bind(this);
        this.setModelOfCar = this.setModelOfCar.bind(this);
        this.changeModelOfCar = this.changeModelOfCar.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.handlerSocket = this.handlerSocket.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'modelOfCar';

    targetNameSave = 'modelOfCarSave';

    pages = {
        'manual-modelsOfCar-inner-main': {
            render() {
                const { modelOfCar, modelOfCarSave, isInit } = this.state;
                const { checkNew } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner">
                                <Main
                                    parentScroll={(() =>
                                        this.parent.current
                                            ?.querySelector(
                                                '.widget__page._manual-modelsOfCar-inner-main',
                                            )
                                            ?.querySelector('.widget__pageBox'))()}
                                    modelOfCar={modelOfCar}
                                    modelOfCarSave={modelOfCarSave}
                                    setHeightPage={this.setHeightPage}
                                    isInit={isInit}
                                    changeModelOfCar={this.changeModelOfCar}
                                    isNew={checkNew()}
                                    getModelOfCar={this.getModelOfCar}
                                    backToSave={this.backToSave}
                                    checkRights={this.checkPermissions}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
        'manual-modelsOfCar-inner-logs': {
            render() {
                const { isShowLoaderList, isInit, modelOfCar } = this.state;

                return (
                    <>
                        <div className="widget__pageBox _scroll">
                            <div className="widget__pageInner _notPadding">
                                <Logs
                                    isInit={isInit}
                                    setHeightPage={this.setHeightPage}
                                    modelOfCar={modelOfCar}
                                    handlerLoaderList={this.handlerLoaderList}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    setHeightPage(e) {
        const { setHeightPage } = this.props;

        if (e) {
            removeTransition({ item: '.widget' });
        }

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep2._current .widget__pageInner',
                classNames: ['_static', '_parentForce'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, () => {
                    setHeightPage();
                });
            } else {
                setHeightPage();
            }
        }
    }

    changeModelOfCar({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const modelOfCar = JSON.parse(JSON.stringify(newState.modelOfCar));
                    const target = modelOfCar;

                    target[name] = value;

                    if (name === 'idOfMark') {
                        target.newMark = '';
                    }

                    if (name === 'newMark') {
                        target.idOfMark = null;
                    }

                    newState.modelOfCar = modelOfCar;

                    return newState;
                }, resolve);
            }
        });
    }

    setModelOfCar({ modelOfCar }) {
        const { setInfoHead } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.modelOfCar = modelOfCar;
                    newState.modelOfCarSave = JSON.parse(JSON.stringify(modelOfCar));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    setInfoHead({ key: 'modelOfCar', value: modelOfCar });

                    resolve();
                },
            );
        });
    }

    updateModelOfCar({ fields }) {
        return new Promise((resolve) => {
            this.setModelOfCar(
                {
                    modelOfCar: {
                        ...this.state.modelOfCar,
                        ...fields,
                        ...(fields.idOfGroup ? { newGroup: '' } : {}),
                    },
                },
                resolve,
            );
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'manual',
            items: [
                {
                    key: 'modelsOfCar',
                    rules: ['update'],
                },
            ],
        });
    }

    getModelOfCar() {
        const { levels, checkNew } = this.props;
        const idOfModelOfCar = levels[3];

        return new Promise((resolve) => {
            if (checkNew()) {
                getModel({ name: 'modelOfCar' }).then(
                    ({ model }) => {
                        setTimeout(() => {
                            this.setModelOfCar({
                                modelOfCar: {
                                    ...model,
                                    typeModelOfCar: idOfModelOfCar,
                                    isNew: true,
                                },
                            }).then(() => {
                                resolve();
                            });
                        }, 300);
                    },
                    () => null,
                );
            } else {
                getModelsOfCar({ id: idOfModelOfCar }).then(
                    ({ model }) => {
                        setTimeout(() => {
                            this.setModelOfCar({ modelOfCar: model }).then(() => {
                                resolve();
                            });
                        }, 300);
                    },
                    () => null,
                );
            }
        });
    }

    handlerSocket({ detail }) {
        const { modelOfCar } = this.state;
        const { name, data } = detail;

        if (modelOfCar) {
            const { type, fields } = data;

            if (type === 'changeInfo') {
                if (name === 'modelOfCar' && modelOfCar._id === data.idOfModel) {
                    this.updateModelOfCar({ fields });
                }
            }
        }
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        this.getModelOfCar();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.setHeightPage);
    }

    componentWillUnmount() {
        const { setInfoHead } = this.props;

        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.setHeightPage);

        setInfoHead({ key: 'modelOfCar', value: undefined });
    }

    render() {
        const { isInit } = this.state;

        return (
            <div ref={this.parent} className={`widget ${isInit ? '_ready' : ''}`}>
                <div ref={this.parent} className="widget__content _full">
                    <Animate className="widget__loader _loader" isShow={!isInit}>
                        <i className="widget__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </i>
                    </Animate>
                    <div className="widget__contentBox">
                        {isInit && (
                            <Pages
                                classNamePage="widget__page _deep2"
                                getClassName={(pageName) =>
                                    ['manual-modelsOfCar-inner-logs'].includes(pageName)
                                        ? '_full'
                                        : ''
                                }
                                filter={(page) =>
                                    page.parentName === 'manual-modelsOfCar-inner' &&
                                    page.level === 4
                                }
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeModelOfCar: state.typeModelOfCar,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualModelOfCarsInner);

ManualModelOfCarsInner.propTypes = {
    levels: PropTypes.array,
    setInfoHead: PropTypes.func,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    checkNew: PropTypes.func,
    serverData: PropTypes.object,
    typeModelOfCar: PropTypes.string,
};
