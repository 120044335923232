import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getEndText from '../../../../functions/getEndText';

// import getProjects from '../../../../requests/getProjects';

import Head from '../card/Head.jsx';
import Animate from '../../../Animate.jsx';
import ListAbsoluteMain from '../../../ListAbsoluteMain.jsx';
import handlerPopup from '../../../../functions/app/handlerPopup';

class ManualExecutorsTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderTag = this.renderTag.bind(this);

        this.parent = React.createRef();
    }

    renderTag({ item }) {
        if (item.id === 'add') {
            const { executor, handlerTags } = this.props;
            const { tags = [] } = executor;

            return (
                <div className="manualCard__tag _edit">
                    <div
                        className="tag _edit _click"
                        onClick={() => {
                            handlerPopup({
                                name: 'tagsPopup',
                                isShow: true,
                                ids: tags.map((tag) => tag.id),
                                callback: (newTags) => {
                                    handlerTags({ tags: newTags });
                                },
                            });
                        }}
                    >
                        Изменить теги
                    </div>
                </div>
            );
        }

        const { colorOfGroup, name } = item;

        return (
            <div className="manualCard__tag">
                <div className="tag" style={{ borderColor: colorOfGroup, color: colorOfGroup }}>
                    {name}
                </div>
            </div>
        );
    }

    render() {
        const { title, executor = {}, checkEditmode, isNew, isHover } = this.props;
        const { tags = [] } = executor;
        const description = tags.length
            ? `${tags.length} ${getEndText(tags.length, ['тег', 'тега', 'тегов'])}`
            : '–';
        const resultTags = [...tags];

        if (checkEditmode()) {
            resultTags.push({ id: 'add' });
        }

        return (
            <div
                ref={this.parent}
                className={`manualCard _executorTags ${isNew ? '_new' : ''} ${
                    isHover ? '_readyForHover' : ''
                }`}
            >
                <div className="manualCard__head _row">
                    <Head title={title} description={description} />
                </div>
                <div className="manualCard__content _fix">
                    <Animate
                        className="manualCard__contentEmpty"
                        isShow={tags.length === 0 && !checkEditmode()}
                    >
                        <div className="empty _col _block _notBack">
                            <div className="empty__inner">
                                <div className="empty__title">Теги не выбраны</div>
                                <p className="empty__content">
                                    Вы можете добавить их перейдя <br />в режим редактирования
                                </p>
                            </div>
                        </div>
                    </Animate>
                    <Animate className="manualCard__contentList" isShow={resultTags.length > 0}>
                        <ListAbsoluteMain
                            className="manualCard__tags"
                            items={resultTags}
                            renderItem={this.renderTag}
                            classNameItem="manualCard__tag"
                            prop="id"
                            paramsParent={{ width: true }}
                            keyRender={executor.type}
                            resizeParent={this.parent.current?.querySelector(
                                '.manualCard__contentList',
                            )}
                        />
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualExecutorsTags);

ManualExecutorsTags.propTypes = {
    executor: PropTypes.object,
    title: PropTypes.string,
    type: PropTypes.string,
    handlerTags: PropTypes.func,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    isHover: PropTypes.bool,
};
