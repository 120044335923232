import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getCorporationReports from '../../../requests/getCorporationReports';

import TableInfo from '../manual/TableInfo.jsx';
import Table from '../manual/Table.jsx';
import getHeaders from '../../../functions/getHeaders';
import Head from '../manual/card/Head.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class SettingsReportsTable extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.signReport = this.signReport.bind(this);
        this.handlerMore = this.handlerMore.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;

    signReport({ id }) {
        return new Promise((resolve) => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/corporation-report`,
                    { id },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    const { success } = res.data;

                    if (success) {
                        resolve();
                    }
                });
        });
    }

    getActionsItems() {
        const items = ['downloadCorporationReportList'];

        return items;
    }

    handlerMore(report, { target }) {
        const { getParent } = this.props;
        const parent = getParent();

        const actionsItems = this.getActionsItems();

        const uniqKey = `corporationReportsActions-${report._id}`;

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: this.getParentForScroll(),
            target: target.closest('.tableInfo__more'),
            action: 'show',
            name: 'corporationReportsActions',
            className: '_actions _right',
            uniqKey,
            items: actionsItems,
            report,
            centers: {
                left: 1,
                top: 0,
            },
            isHideFromScroll: true,
            isCheckScrollPosition: false,
            updateItems: () => {
                this.updateItems();
            },
        });
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getCorporationReports(query).then(({ reports, isLimit, counter }) => {
                this.setItems(reports, false, isLimit, counter).then(() => {
                    resolve();
                });
            });
        });
    }

    render() {
        const {
            isReady = false,
            items = [],
            counter = 0,
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
        } = this.state;

        return (
            <div ref={this.parent} className="manualCard">
                <div className="manualCard__head _row _notBorder">
                    <Head title="Отчёты" description={`(${counter})`} />
                </div>
                <div className="manualCard__content _full">
                    <TableInfo
                        isReady={isReady}
                        items={items}
                        name="corporationReports"
                        setHeightPage={this.setHeightPage}
                        getMoreItems={this.getMoreItems}
                        isLimit={isLimit}
                        stepCounter={this.stepCounter}
                        isDisabledScroll={isDisabledScroll}
                        isLoading={!isReady || isLoadingFilter}
                        handlerLoaderList={this.handlerLoaderList}
                        inTable={true}
                        isNotLink={true}
                        isRowHeight={true}
                        getActionsItems={this.getActionsItems}
                        handlerMore={this.handlerMore}
                        updateItems={this.updateItems}
                        otherData={{
                            signReport: this.signReport,
                        }}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(SettingsReportsTable);

SettingsReportsTable.propTypes = {
    withFilter: PropTypes.bool,
};
