import axios from 'axios';
import getHeaders from '../functions/getHeaders';
// import setNotification from '../functions/setNotification';
import handlerErrorRequest from '../functions/handlerErrorRequest';

export default function setCommentsChat({ id, comments }) {
    return new Promise((resolve) => {
        axios
            .put(
                `${process.env.REACT_APP_API}/chat`, { id, content: comments, type: 'comments' }, {
                    headers: getHeaders(),
                },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        resolve();
                    } else {
                        handlerErrorRequest(res);
                    }
                },
                () => null,
            );
    });
}