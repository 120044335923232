import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Editmode from '../../../classes/Editmode';

import Edit from '../../Edit.jsx';
import Button from '../../Button.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Doc from './Doc.jsx';

import getHeaders from '../../../functions/getHeaders';
import getUpdateFormData from '../../../functions/getUpdateFormData';
import { checkValidate } from '../../../functions/inputValidate';
import removeTransition from '../../../functions/removeTransition.ts';
import setNotification from '../../../functions/setNotification';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';
import setAnimate from '../../../functions/setAnimate';
import getRealParams from '../../../functions/getRealParams.ts';

class Docs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.renderDoc = this.renderDoc.bind(this);
        this.addDoc = this.addDoc.bind(this);

        this.parent = React.createRef();
    }

    settings = {
        executor: {
            folder: 'executor-docs',
            pathReq: 'executor',
        },
        car: {
            folder: 'car-docs',
            pathReq: 'car',
        },
        organization: {
            folder: 'organization-docs',
            pathReq: 'organization',
        },
    };

    getDocs() {
        const { docs = [], isDisabled } = this.props;

        return [...docs, ...(isDisabled ? [] : [{ _id: 'info' }])];
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;

        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    checkChangeProp({ prop, value, valueWas }) {
        switch (prop) {
            default:
                return value !== valueWas;
        }
    }

    changedProps = ['name', 'description', 'dateGet', 'dateAction', 'dateActionString'];

    checkChange(id) {
        const { docs, docsSave } = this.props;
        let isChange = false;
        const fields = {};
        const doc = docs.find((docLoop) => docLoop._id === id);
        const docSave = docsSave.find((docLoop) => docLoop._id === id);

        if (doc && docSave) {
            this.changedProps.forEach((prop) => {
                if (this.checkChangeProp({ prop, value: doc[prop], valueWas: docSave[prop] })) {
                    isChange = true;
                    fields[prop] = doc[prop];
                }
            });

            doc.files.forEach((file) => {
                const fileSave = docSave.files.find((fileLoop) => fileLoop._id === file._id);

                if (fileSave) {
                    if (fileSave.file.path !== file.file.path) {
                        isChange = true;
                    }
                }
            });
        } else {
            isChange = true;
        }

        return { isChange, fields };
    }

    setLoadingAdd(isLoadingAdd) {
        return new Promise((resolve) => {
            this.setState({ isLoadingAdd }, resolve);
        });
    }

    addDoc() {
        const { addDoc } = this.props;

        this.setLoadingAdd(true).then(() => {
            addDoc().then((id) => {
                setTimeout(() => {
                    this.handlerEditmode({ editName: id });
                }, 10);

                this.setLoadingAdd(false);
            });
        });
    }

    validateDoc() {
        const { editName: idOfCurrentDoc } = this.state;
        const { docs } = this.props;
        const docCurrent = docs.find((doc) => doc._id === idOfCurrentDoc);

        let isValidate = true;
        const fields = [];

        if (docCurrent) {
            if (!docCurrent.name) {
                isValidate = false;
                fields.push('name');
            }

            if (!docCurrent.description && 0) {
                isValidate = false;
                fields.push('description');
            }

            if (
                (!docCurrent.dateGet ||
                    !checkValidate({ value: docCurrent.dateGet, type: 'date' })) &&
                0
            ) {
                isValidate = false;
                fields.push('dateGet');
            }
        }

        return { isValidate, fields };
    }

    scrollToCard(id = this.state.editName) {
        const { parentScroll } = this.props;

        if (id) {
            const card = this.parent.current?.querySelector(`.manualDocs__card[data-_id="${id}"]`);

            if (card) {
                const { scrollLeft: scrollCards } = parentScroll;

                const params = getRealParams({
                    parent: parentScroll,
                    elem: `.manualDocs__card[data-_id="${id}"]`,
                    width: parentScroll.offsetWidth,
                });

                const scrollCard =
                    params.getBoundingClientRect.x - params.parent.getBoundingClientRect.x - 8;

                setAnimate({
                    draw: (progress) => {
                        parentScroll.scrollTo({
                            left: scrollCards + progress * scrollCard,
                        });
                    },
                    duration: 300,
                });
            }
        }
    }

    handlerEditmode({ editName, isDelete }) {
        const {
            levels,
            docs,
            formDataDocs,
            filesDocsDelete,
            handlerError,
            clearFormData,
            reqData = {},
            setInnerEditmode,
        } = this.props;
        const id = levels[3];

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    this.scrollToCard(editName);

                    resolve();
                } else {
                    const { editName: idOfDoc } = this.state;
                    const { pathReq } = this.getSettings();
                    const doc = docs.find((docLoop) => docLoop._id === idOfDoc);
                    const docCopy = JSON.parse(JSON.stringify(doc));
                    const { isChange, fields } = this.checkChange(idOfDoc);
                    const { isValidate, fields: fieldsError } = this.validateDoc();

                    if (isDelete) {
                        const { deleteDoc } = this.props;

                        if (doc.isNew) {
                            deleteDoc({ _id: idOfDoc }).then(() => {
                                resolve();
                            });
                        } else {
                            this.setLoading(true).then(() => {
                                const formDataDelete = new FormData();

                                formDataDelete.set('id', id);
                                formDataDelete.set('action', 'handler-docs');
                                formDataDelete.set('actionDoc', 'delete');
                                formDataDelete.set('idOfDoc', idOfDoc);

                                Object.keys(reqData).forEach((key) => {
                                    formDataDelete.set(key, reqData[key]);
                                });

                                axios
                                    .patch(
                                        `${process.env.REACT_APP_API}/${pathReq}`,
                                        formDataDelete,
                                        {
                                            headers: getHeaders(),
                                        },
                                    )
                                    .then(
                                        (res) => {
                                            const { success } = res.data;

                                            this.setLoading(false);

                                            if (success) {
                                                deleteDoc({ _id: idOfDoc }).then(() => {
                                                    setNotification({
                                                        notification: 'success-delete-doc',
                                                    });

                                                    resolve();
                                                });
                                            } else {
                                                handlerErrorRequest(res);

                                                resolve();
                                            }
                                        },
                                        () => null,
                                    );
                            });
                        }
                    } else if (isChange) {
                        if (!isValidate) {
                            handlerError({ _id: idOfDoc, errors: fieldsError });
                        } else {
                            this.setLoading(true).then(() => {
                                formDataDocs.set('id', id);

                                docCopy.files.forEach((file) => {
                                    delete file.file.pathLocal;
                                    delete file.file.path;
                                });

                                formDataDocs.set('action', 'handler-docs');
                                formDataDocs.set('actionDoc', docCopy.isNew ? 'add' : 'change');
                                formDataDocs.set('idOfDoc', idOfDoc);
                                formDataDocs.set(
                                    'fields',
                                    JSON.stringify(docCopy.isNew ? docCopy : fields),
                                );

                                if (filesDocsDelete) {
                                    formDataDocs.set(
                                        'filesDelete',
                                        JSON.stringify(filesDocsDelete),
                                    );
                                }

                                Object.keys(reqData).forEach((key) => {
                                    formDataDocs.set(key, reqData[key]);
                                });

                                axios
                                    .patch(
                                        `${process.env.REACT_APP_API}/${pathReq}`,
                                        getUpdateFormData(formDataDocs),
                                        {
                                            headers: getHeaders(),
                                        },
                                    )
                                    .then(
                                        (res) => {
                                            const { success } = res.data;
                                            this.setLoading(false);

                                            if (success) {
                                                clearFormData();
                                                if (docCopy.isNew) {
                                                    setNotification({
                                                        notification: 'success-create-doc',
                                                    });
                                                } else {
                                                    setNotification({
                                                        notification: 'success-update-doc',
                                                    });
                                                }
                                            } else {
                                                handlerErrorRequest(res);
                                            }

                                            resolve();
                                        },
                                        () => null,
                                    );
                            });
                        }
                    } else {
                        resolve();
                    }
                }
            });

        return new Promise((resolve, reject) => {
            if (this.isLoading) {
                reject();
            } else {
                checkEdit().then(() => {
                    if (typeof setInnerEditmode === 'function') {
                        setInnerEditmode({ editName: editName ? 'docs' : null });
                    }

                    this.editmode.handlerEdit({ editName }).then(resolve);
                });
            }
        });
    }

    checkInit() {
        if (this.props.isInit === true && !this.isInit) {
            removeTransition({ item: '.manualDocs__card' });
            removeTransition({ item: '.manualDocs__empty' });
            this.isInit = true;
        }
    }

    getSettings() {
        const { type } = this.props;

        return this.settings[type];
    }

    renderDoc({ item, prop: _id }) {
        const { isLoadingAdd } = this.state;
        const { checkRights } = this.props;

        if (_id === 'info') {
            return (
                <div className="manualDocs__card _info">
                    <div className="manualDocs__empty _editBack">
                        <div className="empty _col _block _whiteOpacity">
                            <div className="empty__inner">
                                <div className="empty__title">Больше документов нет</div>
                                {checkRights?.() && (
                                    <>
                                        <p className="empty__content">
                                            Но вы всегда можете добавить ещё:
                                        </p>
                                        <div className="empty__button _show" onClick={this.addDoc}>
                                            <Button
                                                showLoader={isLoadingAdd}
                                                className="_mediumSize _mainNotBorder"
                                            >
                                                Добавить документ
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const { editName, isLoading } = this.state;
        const { docs, changeDoc, uploadFile, deleteFile, getParent, parentScroll, isDisabled } =
            this.props;
        const doc = docs.find((docLoop) => docLoop._id === _id) || item;
        const { folder } = this.getSettings();

        return (
            <div className={`manualDocs__card ${doc.isEdo ? '_edo' : ''}`}>
                <div
                    className={`manualDocs__cardInner _parentForEdit _editBack ${
                        editName === _id ? '_current' : ''
                    }`}
                >
                    {!isDisabled && (
                        <Edit
                            name={_id}
                            className="manualDocs__cardEdit"
                            editName={editName}
                            handlerEditmode={this.handlerEditmode}
                            isLoader={isLoading}
                        />
                    )}

                    <Doc
                        {...doc}
                        folder={folder}
                        isEdit={editName === _id}
                        change={changeDoc}
                        upload={uploadFile}
                        deleteFile={deleteFile}
                        deleteDoc={async () => {
                            this.handlerEditmode({ editName: null, isDelete: true }).then(
                                () => true,
                            );
                        }}
                        parent={getParent()}
                        parentScroll={parentScroll}
                    />
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.editmode = new Editmode({
            context: this,
        });
    }

    componentDidUpdate() {
        this.checkInit();
    }

    componentWillUnmount() {
        const { backToSaveDocs } = this.props;

        if (backToSaveDocs) {
            backToSaveDocs();
        }
    }

    render() {
        const { editName } = this.state;

        return (
            <div
                ref={this.parent}
                className={`manualDocs _parentForEdits ${this.state.isInit ? '_isInit' : ''} ${
                    editName ? '_edit' : ''
                }`}
            >
                <ListAbsoluteMain
                    className="manualDocs__cards"
                    classNameItem="manualDocs__card"
                    items={this.getDocs()}
                    renderItem={this.renderDoc}
                    // paramsParent={{ width: true }}
                    // styles={['height']}
                    prop="_id"
                    itemParams={['offsetLeft', 'offsetTop', 'width', 'height']}
                    propsForUpdate={[
                        'name',
                        'description',
                        'dateGet',
                        'dateAction',
                        'dateActionString',
                    ]}
                    callback={() => {
                        if (!this.state.isInit) {
                            this.setState({ isInit: true });
                        }
                    }}
                    // name="test"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Docs);

Docs.propTypes = {
    type: PropTypes.string,
    levels: PropTypes.array,
    docs: PropTypes.array,
    docsSave: PropTypes.array,
    folder: PropTypes.string,
    changeDoc: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    formDataDocs: PropTypes.object,
    filesDocsDelete: PropTypes.array,
    setHeightPage: PropTypes.func,
    addDoc: PropTypes.func,
    isInit: PropTypes.bool,
    deleteDoc: PropTypes.func,
    getParent: PropTypes.func,
    handlerError: PropTypes.func,
    backToSaveDocs: PropTypes.func,
    clearFormData: PropTypes.func,
    parentScroll: PropTypes.object,
    reqData: PropTypes.object,
    checkRights: PropTypes.func,
    setInnerEditmode: PropTypes.func,
    isDisabled: PropTypes.bool,
};
