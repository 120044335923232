import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Avatar from './Avatar.jsx';

class AuthSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    content = {
        site: {
            description: (
                <>
                    ДОБРО ПОЖАЛОВАТЬ
                    <br />В LIVECARGO!
                </>
            ),
        },
        crm: {
            description: (
                <>
                    ДОБРО ПОЖАЛОВАТЬ
                    <br />В LIVECARGO CRM!
                </>
            ),
        },
    };

    render() {
        const { user  } = this.props;
        const { firstName: name } = user || {};

        return (
            <div className={`authSuccess _col _${process.env.REACT_APP_SYSTEM}`}>
                <div className="authSuccess__content _col">
                    <div className="authSuccess__preview _col">
                        <Avatar
                            className="authSuccess__previewIcon"
                            holder={{ ...user }}
                            type={process.env.REACT_APP_SYSTEM === 'site' ? 'client' : 'user'}
                        />
                    </div>
                    <h3 className="authSuccess__name">{name}</h3>
                    <p className="authSuccess__description">
                        {this.content[process.env.REACT_APP_SYSTEM].description}
                    </p>
                    <img
                        src={require('../img/rocket.gif')}
                        alt=""
                        className="authSuccess__rocket"
                    />
                    <p className="authSuccess__status">ВХОДИМ В СИСТЕМУ...</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(AuthSuccess);

AuthSuccess.propTypes = {
    user: PropTypes.object,
};
