import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import File from '../../../../classes/File';

import Icon from '../../../Icon.jsx';
import BorderDashed from '../../../BorderDashed.jsx';
import Animate from '../../../Animate.jsx';
import ImageLazy from '../../../ImageLazy.jsx';
import AnimateChange from '../../../AnimateChange.jsx';

import handlerPopup from '../../../../functions/handlerPopup';
import checkImageFile from '../../../../functions/checkImageFile';

class ManualFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.upload = this.upload.bind(this);
        this.getSrc = this.getSrc.bind(this);
        this.rotateCallback = this.rotateCallback.bind(this);

        this.input = React.createRef();
    }

    upload({ target }) {
        const { type, name, uploadFile } = this.props;

        this.handlerFile.uploadFiles({ target }).then(
            ({ resultFiles: files }) => {
                uploadFile({ type, name, files });
            },
            () => null,
        );
    }

    getSrc(file) {
        const { rotateKey } = this.state;
        const { folder } = this.props;

        return file.isLocal
            ? file.path
            : `${process.env.REACT_APP_STATIC}/${folder}/${file.path}?${rotateKey}`;
    }

    rotateCallback() {
        this.setState({ rotateKey: new Date().getTime() });
    }

    componentDidMount() {
        const { typeOfFiles = 'docs' } = this.props;

        this.handlerFile = new File({ input: this.input.current, typeOfFiles });
    }

    render() {
        const { rotateKey } = this.state;
        const {
            _id,
            files = [],
            file = {},
            children,
            isDisabled,
            deleteFile,
            type,
            name,
            // isLocal,
            title,
            className = '',
            folder,
        } = this.props;
        const path = this.getSrc({ ...file });
        const currentIndex = files.findIndex((fileLoop) => fileLoop._id === _id);

        return (
            <label
                ref={this.parent}
                className={`manualFile ${isDisabled ? '_isDisabled' : '_click'} ${className}`}
                onClick={() => {
                    if (isDisabled && files.length && path) {
                        handlerPopup({
                            action: 'show',
                            name: 'popupGalery',
                            modelName: 'executor-docs',
                            images: files.map((fileLoop) => ({
                                type: fileLoop.type,
                                path: this.getSrc(fileLoop),
                                folder,
                                src: fileLoop.path,
                                id: fileLoop._id,
                            })),
                            rotateCallback: this.rotateCallback,
                            title,
                            currentIndex,
                        });
                    }
                }}
            >
                <input
                    ref={this.input}
                    type="file"
                    className="manualFile__input"
                    onChange={this.upload}
                    disabled={isDisabled}
                />
                <Animate className="manualFile__preview _col _click" isShow={!!file.path} isSmoothShow={true}>
                    {deleteFile && (
                        <Animate
                            className="manualFile__previewDelete"
                            isShow={!isDisabled}
                            onClick={(e) => {
                                e.preventDefault();

                                deleteFile({ type, name });
                            }}
                        >
                            <div className="action _col _click _delete">
                                <i className="action__icon">
                                    <Icon name="delete" />
                                </i>
                            </div>
                        </Animate>
                    )}

                    {checkImageFile(file) ? (
                        <AnimateChange
                            className="manualFile__previewImage"
                            prop={path}
                            type=""
                            isNotParams={true}
                            key={rotateKey}
                        >
                            <ImageLazy src={path} className="manualFile__previewImageItem" />
                        </AnimateChange>
                    ) : (
                        <>
                            <i className="manualFile__previewIcon">
                                <Icon name="file-type-doc" />
                            </i>
                        </>
                    )}
                </Animate>

                <div className="manualFile__content _col">
                    <div className="manualFile__border">
                        <BorderDashed className="_upload" />
                    </div>
                    <i className="manualFile__icon">
                        <Icon name="add" />
                    </i>
                    <div className="manualFile__description">{children}</div>
                </div>
            </label>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualFile);

ManualFile.propTypes = {
    _id: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    typeFiles: PropTypes.string,
    name: PropTypes.string,
    folder: PropTypes.string,
    uploadFile: PropTypes.func,
    deleteFile: PropTypes.func,
    file: PropTypes.object,
    isDisabled: PropTypes.bool,
    isLocal: PropTypes.bool,
    files: PropTypes.array,
    title: PropTypes.string,
    className: PropTypes.string,
    typeOfFiles: PropTypes.string,
};
