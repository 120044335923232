import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import HandlerFilterOrder from '../../../classes/Filter';

import Main from './groups/Main.jsx';
import HeadInner from './groups/HeadInner.jsx';
import Inner from './groups/Inner.jsx';

import getFilter from '../../../requests/getFilter';

import Pages from '../../../components/Pages.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import Windows from '../../../components/Windows.jsx';
import Filter from '../../../components/Filter.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import Edit from '../../../components/Edit.jsx';

import getRealParams from '../../../functions/getRealParams.ts';
import getMaxHeightContentWidget from '../../../functions/crm/getMaxHeightContentWidget';
import handlerWindow, { updateWindow } from '../../../functions/handlerWindow';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import getWidthInput from '../../../functions/getWidthInput';
import removeTransition from '../../../functions/removeTransition.ts';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import WindowPrompt from '../../../components/WindowPrompt.jsx';
import changePage from '../../../functions/changePage';
import PaysMainTemplate from '../../../components/crm/pays/Main.jsx';
import setPermissions from '../../../functions/crm/setPermissions';
import getPageLink from '../../../functions/getPageLink';

class PaysGroups extends PaysMainTemplate {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setHeightPage = this.setHeightPage.bind(this);
        this.setInfoHead = this.setInfoHead.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.handlerEditName = this.handlerEditName.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.delete = this.delete.bind(this);
        this.renderWindowsPromptDelete = this.renderWindowsPromptDelete.bind(this);
        this.renderWindowsDeleteInfo = this.renderWindowsDeleteInfo.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    headPages = {
        'pays-groups-main': {
            render() {
                const { infoHead } = this.state;
                const { counter = 0 } = infoHead;

                return (
                    <div className="widget__headNameItemContent">
                        Реестр актов
                        <AnimateChange
                            prop={counter}
                            type="_translateMedium"
                            className="widget__headNameItemContentInner"
                            classNameParent="_parent"
                        >
                            {`(${counter})`}
                        </AnimateChange>
                    </div>
                );
            },
        },
        'pays-groups-inner': {
            render() {
                const { infoHead, editName = '', isNameEditMode, loadingKey } = this.state;
                const { pay = {} } = infoHead;
                const { name } = pay;

                return (
                    <div className="widget__headNameItemContent">
                        Реестр —
                        <AnimateChangeUp
                            className="widget__headNameEdit _row"
                            renderKey={name}
                            parentStyles={['width']}
                        >
                            <input
                                type="text"
                                className={`widget__headNameEditInput ${
                                    isNameEditMode ? '_edit' : ''
                                }`}
                                onChange={({ target }) => {
                                    removeTransition({ item: '.widget__headNameEditInput' });

                                    this.handlerEditName({ name: target.value });
                                }}
                                value={editName}
                                style={{
                                    width: editName
                                        ? `${getWidthInput({
                                              classNames: [
                                                  'widget__headNameEditInput',
                                                  ...(isNameEditMode ? ['_edit'] : []),
                                              ],
                                              value: editName,
                                              withPaddings: true,
                                          })}px`
                                        : '20px',
                                }}
                                readOnly={!isNameEditMode}
                            />
                            {this.getPermissions({
                                key: 'pays',
                                items: [{ key: 'acts', rules: ['update'] }],
                            }) && (
                                <Edit
                                    className="widget__headNameEditAction"
                                    editName={isNameEditMode ? 'edit' : null}
                                    name="edit"
                                    handlerEditmode={this.handlerEditmode}
                                    isLoader={!!loadingKey}
                                />
                            )}
                        </AnimateChangeUp>
                    </div>
                );
            },
        },
    };

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerEditmode({ editName: updEditName }) {
        const { infoHead } = this.state;
        const { pay } = infoHead;

        const prevActions = () =>
            new Promise((resolve, reject) => {
                if (updEditName) {
                    this.parent.current.querySelector('.widget__headNameEditInput').focus();

                    resolve();
                } else {
                    const { editName } = this.state;

                    if (!editName) {
                        reject();
                    } else if (pay.name === editName) {
                        resolve();
                    } else {
                        this.handlerLoading('editName').then(() => {
                            axios
                                .patch(
                                    `${process.env.REACT_APP_HARD_API}/pays`,
                                    { id: pay?._id, action: 'set-name', name: editName },
                                    { headers: getHeaders() },
                                )
                                .then((res) => {
                                    const { success } = res.data;

                                    if (success) {
                                        setNotification({ notification: 'success-change-info' });

                                        this.setInfoHead({
                                            key: 'pay',
                                            value: {
                                                ...pay,
                                                name: editName,
                                            },
                                        });

                                        resolve();
                                    } else {
                                        reject();
                                    }

                                    this.handlerLoading(null);
                                });
                        });
                    }
                }
            });

        return new Promise((resolve) => {
            prevActions().then(() => {
                this.setState({ isNameEditMode: !!updEditName }, resolve);
            });
        });
    }

    headActions = {
        'pays-groups-main': {
            render() {
                const { filter, infoHead } = this.state;
                const { checkRights } = this.props;
                const { counter = 0 } = infoHead;

                return (
                    <TableHead
                        name="paysGroups"
                        filter={filter}
                        handlerFilter={this.handlerFilter}
                        getParent={() => this.parent.current}
                        checkRights={checkRights}
                        windowName="paysFilter"
                        counter={counter}
                    />
                );
            },
        },
        'pays-groups-inner': {
            render() {
                const { infoHead } = this.state;
                const { checkRights } = this.props;
                const { pay } = infoHead;

                return (
                    <HeadInner checkRights={checkRights} groupId={pay?._id} delete={this.delete} />
                );
            },
        },
    };

    pages = {
        'pays-groups-main': {
            render() {
                const { filter, isShowLoaderList } = this.state;
                const { checkRights } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setHeightPage={this.setHeightPage}
                                    setInfoHead={this.setInfoHead}
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    handlerLoaderList={this.handlerLoaderList}
                                    checkRights={checkRights}
                                />
                            </div>
                        </div>
                        <Animate
                            className="widget__pageLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'pays-groups-inner': {
            render() {
                const { checkRights } = this.props;

                return (
                    <div className="widget__pageBox">
                        <div className="widget__pageInner _notPadding">
                            <Inner
                                setHeightPage={this.setHeightPage}
                                setInfoHead={this.setInfoHead}
                                getParent={() => this.parent.current}
                                initCallbackFilter={this.initCallbackFilter}
                                checkRights={checkRights}
                            />
                        </div>
                    </div>
                );
            },
        },
    };

    delete({ isForce }) {
        const { infoHead } = this.state;
        const { windows } = this.props;
        const { pay } = infoHead;
        const id = pay?._id;
        const windowId = `delete-payGroup-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_HARD_API}/pays?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then((res) => {
                        const { success } = res.data;

                        const windowName = !success ? 'deletePayInfo' : 'promptDelete';

                        if (!isForce) {
                            handlerWindow({
                                parent: this.parent.current,
                                parentResize: this.parent.current,
                                target: this.parent.current.querySelector(
                                    '.widget__headLink._delete',
                                ),
                                action: 'show',
                                name: windowName,
                                className: '_prompt _center',
                                uniqKey: windowId,
                                watchesProps: { left: true },
                                centers: {
                                    left: 0.5,
                                    top: 1,
                                },
                            });
                        } else {
                            handlerWindow({
                                action: 'hide',
                                name: windowId,
                            });
                        }

                        if (success) {
                            if (isForce) {
                                setNotification({ notification: 'success-change-info' });
                            }

                            resolve();
                        } else {
                            reject();
                        }
                    });
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            changePage({
                                href: getPageLink({
                                    name: 'pays-groups',
                                }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    renderWindowsDeleteInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deletePayInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    getFilter() {
        getFilter({ name: 'paysGroups' }).then(({ blocks }) => {
            this.initFilter({ blocks });
        });
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    handlerEditName({ name }) {
        this.setState((state) => {
            const newState = { ...state };

            newState.editName = name;

            return newState;
        });
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            if (key === 'pay') {
                newState.editName = value?.name || '';
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            let { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__page._deep1._current .widget__pageInner',
                classNames: ['_static'],
                width: page.offsetWidth,
                // isNotRemove: true,
            });

            if (heightPage > getMaxHeightContentWidget()) {
                heightPage = getMaxHeightContentWidget();
            }

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    componentDidMount() {
        this.setHeightPage();
        this.getFilter();

        this.handlerFilter = new HandlerFilterOrder({
            context: this,
            callback: () => {
                const { filter } = this.state;

                updateWindow({ key: 'filter-tags', filter });
            },
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="paysFilter" renderContent={this.renderFilter} />
                <Windows name="promptDeletePays" renderContent={this.renderWindowsPromptPays} />
                <Windows name="promptDelete" renderContent={this.renderWindowsPromptDelete} />
                <Windows name="deletePayInfo" renderContent={this.renderWindowsDeleteInfo} />
                <Windows name="editActions" renderContent={this.renderWindowsEditActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Выплаты"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) => page.parentName === 'pays-groups'}
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) => page.parentName === 'pays-groups'}
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) => page.parentName === 'pays-groups'}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
        user: state.user,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(PaysGroups);

PaysGroups.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    user: PropTypes.object,
    windows: PropTypes.object,
};
