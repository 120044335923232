import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Icon from '../../Icon.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import Field from '../../Field.jsx';
import Button from '../../Button.jsx';
import handlerLoading from '../../../functions/handlerLoading';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

class ExecutorLimitsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = ['month', 'year'];

    fields = {
        month: {
            name: 'ЛИМИТ В МЕСЯЦ',
        },
        year: {
            name: 'ЛИМИТ В ГОД',
        },
    };

    handlerLimits({ values = [] }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const limits = { ...newState.limits };

                values.forEach(({ value, key }) => {
                    limits[key] = value;
                });

                newState.limits = limits;

                return newState;
            }, resolve);
        });
    }

    handlerField({ action, name: key, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.handlerLimits({ values: [{ key, value }] }).then(resolve);
            } else {
                resolve();
            }
        });
    }

    checkChange() {
        const { limits, savedLimits } = this.state;
        let isChange = false;

        if (savedLimits) {
            this.fieldsOrder.forEach((name) => {
                if (limits[name] !== savedLimits[name]) {
                    isChange = true;
                }
            });
        }

        return isChange;
    }

    save() {
        const { limits, executorId, corporationId } = this.state;

        if (this.checkChange()) {
            handlerLoading.call(this, 'save').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/executor-limit`,
                        {
                            id: executorId,
                            corporationId,
                            limits,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            const { success, data } = res.data;

                            if (success) {
                                setNotification({ notification: 'success-change-info' });

                                this.setState({ savedLimits: JSON.parse(JSON.stringify(limits)) });

                                this.state.callback();

                                this.hide();
                            } else {
                                const { message } = data;

                                if (message === 'Year limit excess') {
                                    setNotification({
                                        notification: 'executor-limits-year-excess',
                                    });
                                } else if (message === 'Month limit excess') {
                                    setNotification({
                                        notification: 'executor-limits-month-excess',
                                    });
                                }
                            }
                        },
                        () => {
                            handlerLoading.call(this, null);
                        },
                    );
            });
        }
    }

    hide() {
        handlerPopup({ name: 'executorLimitsPopup', isShow: false });
    }

    componentDidMount() {
        const { executorLimitsPopup } = this.props;
        const limits = JSON.parse(JSON.stringify(executorLimitsPopup.limits));

        this.fieldsOrder.forEach((name) => {
            if (limits[name]) {
                limits[name] = limits[name]?.toString().replace(/\./gi, ',');
            }
        });

        this.setState({
            ...executorLimitsPopup,
            limits,
            savedLimits: JSON.parse(JSON.stringify(limits)),
        });
    }

    render() {
        const { corporationName, loadingKey, executorName, limits } = this.state;

        return (
            <div ref={this.parent} className="corporationTariffPopup _limits">
                <div className="corporationTariffPopup__inner">
                    <i
                        className="corporationTariffPopup__close _click"
                        onClick={() => {
                            this.hide();
                        }}
                    >
                        <Icon name="close-circle" />
                    </i>
                    <div className="corporationTariffPopup__head">
                        <div className="corporationTariffPopup__title">
                            Изменить лимиты {corporationName}
                        </div>
                        <p className="corporationTariffPopup__description">{executorName}</p>
                    </div>
                    <div className="corporationTariffPopup__content">
                        <div className="corporationTariffPopup__contentBlocks _static">
                            <div className="corporationTariffPopup__contentBlock _show">
                                <div className={`corporationTariffPopup__block _full`}>
                                    {this.fieldsOrder.map((fieldName) => {
                                        const fieldInfo = this.fields[fieldName];

                                        return (
                                            <div
                                                className="corporationTariffPopup__blockField"
                                                key={fieldName}
                                            >
                                                <p className="corporationTariffPopup__blockFieldSupport">
                                                    {fieldInfo.name}
                                                </p>
                                                <div
                                                    className="corporationTariffPopup__blockFieldBox"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <Field
                                                        type="corporationTariff"
                                                        keyName="price"
                                                        name={fieldName}
                                                        value={limits?.[fieldName]}
                                                        callback={this.handlerField.bind(this)}
                                                        className="_grey"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="corporationTariffPopup__foot _col">
                        <div className="corporationTariffPopup__button">
                            <Button
                                className="_main _mainNotBorder"
                                onClick={this.save}
                                showLoader={!!loadingKey}
                                isDisabled={!this.checkChange()}
                            >
                                Сохранить изменения
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        executorLimitsPopup: state.executorLimitsPopup,
    };
}

export default connect(mapStateToProps)(ExecutorLimitsPopup);

ExecutorLimitsPopup.propTypes = {
    executorLimitsPopup: PropTypes.object,
};
