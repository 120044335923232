import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Animate from '../../Animate.jsx';
import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import Search from '../../Search.jsx';
import ActionChange from '../../ActionChange.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Field from '../manual/card/Field.jsx';
import getUserInfo from '../../../functions/getUserInfo';
import scrollToPosition from '../../../functions/scrollToPosition';
import getFormatedDate from '../../../functions/getFormatedDate';

import Avatar from '../../Avatar.jsx';
import Table from '../manual/Table.jsx';
import getExecutors from '../../../requests/getExecutors';
import getUsers from '../../../requests/getUsers';

import getJoinTemplates from '../../../requests/getJoinTemplates';

import ListScroll from '../../ListScroll.jsx';
import Loader from '../../Loader.jsx';
import getHeaders from '../../../functions/getHeaders';
import handlerPopup from '../../../functions/app/handlerPopup';
import ExecutorOrg from '../ExecutorOrg.jsx';
import setNotification from '../../../functions/setNotification';
import Calendar from '../../Calendar.jsx';
import WindowPrompt from '../../WindowPrompt.jsx';
import Windows from '../../Windows.jsx';
import handlerWindow from '../../../functions/handlerWindow';

class JoinContractPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.renderList = this.renderList.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.hideListCallback = this.hideListCallback.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerField = this.handlerField.bind(this);
        this.renderDatesItem = this.renderDatesItem.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerDate = this.handlerDate.bind(this);
        this.saveDate = this.saveDate.bind(this);
        this.renderContractPrompt = this.renderContractPrompt.bind(this);
        this.hide = this.hide.bind(this);

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.payPopup__listItem';

    getFieldsOrder(key) {
        const { type } = this.state;

        if (key === 'info') {
            return ['userName', 'templateId'].filter((name) => !type || name !== 'templateId');
        }

        return ['createdDate', 'actionDate'];
    }

    fields = {
        userName: {
            keyName: 'fullname',
        },
        templateId: {},
        createdDate: {
            keyName: 'date',
        },
        actionDate: {
            keyName: 'date',
        },
    };

    getContentBlocks() {
        const { isListShow } = this.state;

        return isListShow ? [{ key: 'content' }] : [];
    }

    renderListItem({ item, prop: _id }) {
        const { type } = this.state;

        if (_id === 'empty') {
            return (
                <div className="payPopup__listItem">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">По вашему фильтру ничего не найдено</div>
                            <p className="empty__content">Попробуйте изменить критерии поиска</p>
                        </div>
                    </div>
                </div>
            );
        }

        const isCurrent = this.state.currentItem?._id === _id;

        return (
            <div className="payPopup__listItem">
                <div className="payPopup__listExecutor _row">
                    <div className="payPopup__listExecutorAvatar _col">
                        <Avatar
                            className="payPopup__listExecutorAvatarInner"
                            holder={{ ...item }}
                        />
                    </div>

                    <div className="payPopup__listExecutorName _col">
                        <b>{getUserInfo({ type: 'name', user: item })},&nbsp;</b>
                        <span className="_noWrap">{item.phone}</span>
                        {item.roleName && <>{item.roleName}</>}
                        {!type && (
                            <div className="payPopup__listExecutorOrg">
                                <ExecutorOrg name={item.organization} />
                            </div>
                        )}
                    </div>

                    <ActionChange
                        className="payPopup__listExecutorAction"
                        isShow={true}
                        isSmoothShow={true}
                        isHas={isCurrent}
                        onClick={() => {
                            this.setState(
                                {
                                    currentItem:
                                        this.state.currentItem?._id === item._id ? null : item,
                                },
                                () => {
                                    scrollToPosition({
                                        position: 'top',
                                        parent: this.parent.current?.querySelector(
                                            '.payPopup__listItemsWrapper',
                                        ),
                                    });

                                    this.handlerErrors({
                                        errors: ['userName', 'name'],
                                        action: 'delete',
                                    });

                                    this.handlerField({
                                        action: 'change',
                                        name: 'name',
                                        value: this.state.currentItem
                                            ? `Платеж по агентскому договору №${
                                                  item.contractNumber || '–'
                                              }`
                                            : '',
                                    });
                                },
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                clearTimeout(this.searchTimerId);

                this.setState({ search: value, isSearchProcess: true }, () => {
                    this.searchTimerId = setTimeout(() => {
                        this.updateItems(true).then(() => {
                            this.setState({ isSearchProcess: false });
                        });
                    }, 300);

                    resolve();
                });
            }
        });
    }

    getItemsList() {
        const { search, items = [], currentItem, isSearchProcess } = this.state;

        if (search && items.length === 0 && !isSearchProcess) {
            return [{ _id: 'empty' }];
        }

        return JSON.parse(
            JSON.stringify(
                items
                    .filter((item) => !currentItem || item._id !== currentItem._id)
                    .concat(...(currentItem ? [currentItem] : [])),
            ),
        ).sort((a, b) => {
            const weightA = currentItem?._id === a._id ? 1 : 0;
            const weightB = currentItem?._id === b._id ? 1 : 0;

            return weightB - weightA;
        });
    }

    hideListCallback() {
        const items = this.getItemsList().filter((item, key) => key < this.stepCounter);

        document.dispatchEvent(
            new CustomEvent('handlerListDynamic', {
                detail: {
                    name: 'payPopupList',
                    items,
                    callback: () => {
                        this.setState(
                            {
                                items,
                                counterScroll: this.stepCounter,
                                isListHide: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ isListHide: false });
                                }, 300);
                            },
                        );
                    },
                },
            }),
        );
    }

    renderList() {
        const {
            isReady,
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
            isStateListProcess = false,
            isShowLoaderList = false,
            isListHide = false,
            isSearchProcess = false,
            search = '',
            currentItem,
        } = this.state;
        const items = this.getItemsList();
        const minHeight = items.length === 0 || isSearchProcess ? 100 : 0;

        return (
            <div className="payPopup__listContentInner">
                <div className="payPopup__listSearch">
                    <Search
                        value={search}
                        support="Введите ФИО исполнителя"
                        className="_filter _grey"
                        callback={this.handlerSearch}
                    />
                </div>
                <div className="payPopup__listItemsBox">
                    <div
                        className={`payPopup__listItemsWrapper ${
                            isReady && !isLoadingFilter && !isSearchProcess ? '_ready' : ''
                        }`}
                    >
                        <ListScroll
                            getParent={() =>
                                this.parent.current?.querySelector('.payPopup__listItemsWrapper')
                            }
                            callback={this.getMoreItems}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={Infinity}
                            lengthCurrent={items.length}
                            keyUpdate={items.length}
                            isDisabledScroll={
                                isDisabledScroll ||
                                isSearchProcess ||
                                isStateListProcess ||
                                isListHide
                            }
                            isLimit={isLimit}
                            handlerLoaderList={this.handlerLoaderList}
                            hideCallback={this.hideListCallback}
                        >
                            <ListAbsoluteMain
                                name="payPopupList"
                                className="payPopup__listItems _col"
                                items={items}
                                renderItem={this.renderListItem}
                                classNameItem="payPopup__listItem"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        this.setState({ updatedKey: new Date().getTime() });
                                    }
                                }}
                                minHeight={minHeight}
                                maxHeight={150}
                                keyRender={currentItem?._id}
                            />
                        </ListScroll>
                    </div>
                    <Animate
                        className="payPopup__listLoader _loader"
                        isShow={!isReady || isLoadingFilter || isSearchProcess}
                    >
                        <div className="payPopup__listLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <Animate
                        className="payPopup__listScrollLoader _loaderScroll _white"
                        isShow={isReady && !isLoadingFilter && !isSearchProcess && isShowLoaderList}
                    >
                        <div className="payPopup__listScrollLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }

    getItems(isStart, isFilter = false) {
        const { search, type } = this.state;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'joinList' });
        query.params.push({ key: 'dataType', value: 'list' });

        if (search?.length > 2) {
            query.params.push({ key: 'nameOrPhone', value: search });
        }

        return new Promise((resolve) => {
            if (type === 'userProxy') {
                getUsers(query).then(
                    ({ users, isLimit }) => {
                        this.setItems(users, isFilter, isLimit).then(() => {
                            resolve();
                        });
                    },
                    () => null,
                );
            } else {
                getExecutors(query).then(
                    ({ executors, isLimit }) => {
                        this.setItems(executors, isFilter, isLimit).then(() => {
                            resolve();
                        });
                    },
                    () => null,
                );
            }
        });
    }

    handlerList(isListShow = !this.state.isListShow) {
        clearTimeout(this.stateListProcessTimerId);

        this.setState({ isListShow, isStateListProcess: true }, async () => {
            if (isListShow === true) {
                await this.getItems(true, true);
            }

            this.stateListProcessTimerId = setTimeout(() => {
                this.setState({ isStateListProcess: false }, () => {
                    if (this.state.isListShow === false) {
                        this.setState({
                            isLimit: !(this.state.counterScroll > this.stepCounter),
                            counterScroll: this.stepCounter,
                        });
                    }
                });
            }, 300);
        });
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    if (!fields[name]) {
                        fields[name] = {};
                    }

                    fields[name].value = value;
                    fields[name].error = null;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getValue({ name }) {
        const { fields, currentItem } = this.state;
        let value = fields[name]?.value;

        if (currentItem) {
            if (name === 'userName') {
                value = getUserInfo({
                    type: 'name',
                    user: currentItem,
                });
            }
        }

        return value;
    }

    handlerErrors({ errors, action }) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = { ...newState.fields };

            errors.forEach((name) => {
                if (!fields[name]) {
                    fields[name] = {};
                }

                fields[name].error = action ? null : true;
            });

            newState.fields = fields;

            return newState;
        });
    }

    getDatesItems() {
        const { dateKey } = this.state;

        return dateKey ? [{ key: dateKey }] : [];
    }

    dates = {};

    handlerDate(type, { currentDate, currentDateDouble }) {
        if (!this.dates[type]) {
            this.dates[type] = {};
        }

        if (currentDate) {
            this.dates[type].currentDate = currentDate;
        }

        if (currentDateDouble) {
            this.dates[type].currentDateDouble = currentDateDouble;
        }
    }

    saveDate(type) {
        const { dateKey } = this.state;

        if (this.dates[type]?.currentDate) {
            const startDateString = getFormatedDate({ date: this.dates[type].currentDate });

            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    ['createdDate', 'actionDate'].forEach((key) => {
                        if (!fields[key]) {
                            fields[key] = {};
                        }
                    });

                    fields[dateKey].value = startDateString;
                    fields[dateKey].error = null;

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    // this.currentDate = null;
                    // this.currentDateDouble = null;

                    this.handlerDateState({ dateKey: null });
                },
            );
        }
    }

    renderDatesItem({ prop: key }) {
        // console.log(key);
        return (
            <div className="payPopup__calendar _col">
                <div className="payPopup__calendarInner">
                    <div className="payPopup__calendarBox _col">
                        <Calendar
                            className="_pay"
                            withPast={true}
                            withFuture={key === 'actionDate'}
                            isAllDates={true}
                            handlerDate={this.handlerDate.bind(this, key)}
                            startDate={this.dates[key]?.currentDate}
                            startDateDouble={this.dates[key]?.currentDateDouble}
                            startYear={key === 'actionDate' ? 2025 : undefined}
                        />
                        <div className="payPopup__calendarButton">
                            <Button
                                className="_medium2Size"
                                onClick={this.saveDate.bind(this, key)}
                            >
                                Сохранить дату
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getDateObject({ date }) {
        const nowDate = new Date();
        const [day, month, year] = date.split('.');

        nowDate.setUTCFullYear(+year);
        nowDate.setUTCMonth(+month - 1);
        nowDate.setUTCDate(+day);
        nowDate.setUTCHours(0, 0, 0, 0);

        return nowDate;
    }

    save(isForce) {
        const { currentItem, type } = this.state;
        const { joinContractPopup } = this.props;
        const { successCallback } = joinContractPopup;
        const fields = {};
        const errors = [];
        const fieldsNames = ['templateId', 'createdDate', 'actionDate'].filter(
            (name) => !type || name !== 'templateId',
        );

        fieldsNames.forEach((name) => {
            const value = this.getValue({ name });

            if (!value) {
                errors.push(name);
            } else {
                fields[name] = value;
            }
        });

        if (!type) {
            if (!currentItem) {
                errors.push('userName');
            } else {
                fields.executorId = currentItem._id;
            }
        } else {
            fields.type = type;
            fields.executorId = joinContractPopup?.userId || currentItem?._id;
        }

        return new Promise((resolve) => {
            if (errors.length) {
                this.handlerErrors({ errors });

                setNotification({ notification: 'required-fields-not-complete' });

                resolve();
            } else {
                fields.createdDate = this.getDateObject({ date: fields.createdDate });
                fields.actionDate = this.getDateObject({ date: fields.actionDate });

                this.handlerLoading('save').then(() => {
                    axios
                        .post(
                            `${process.env.REACT_APP_API}/join-contract`,
                            {
                                fields,
                                ...(isForce === true ? { isForce } : {}),
                            },
                            { headers: getHeaders() },
                        )
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    if (typeof successCallback === 'function') {
                                        successCallback();
                                    }

                                    handlerPopup({
                                        name: 'joinContractPopup',
                                        isShow: false,
                                    });

                                    document.dispatchEvent(new CustomEvent('newJoinContract'));
                                } else {
                                    const { message } = data;

                                    if (message === 'Date is incorrect') {
                                        this.handlerErrors({ errors: ['createdDate'] });
                                    } else if (message === 'User proxy not required') {
                                        this.handlerErrors({ errors: ['userName'] });

                                        setNotification({
                                            notification: 'user-proxy-not-required',
                                        });
                                    } else if (message === 'User passport not complete') {
                                        setNotification({
                                            notification: 'user-passport-not-complete',
                                        });
                                    } else if (
                                        message === 'Contract have mvd' ||
                                        message === 'Contract already create'
                                    ) {
                                        const parent =
                                            this.parent.current.querySelector('.payPopup__inner');
                                        const target =
                                            this.parent.current.querySelector(
                                                '.payPopup__footButton',
                                            );
                                        const promptName =
                                            message === 'Contract have mvd'
                                                ? 'contractAlreadyCreateMvd'
                                                : 'contractAlreadyCreate';

                                        handlerWindow({
                                            parent,
                                            parentResize: parent,
                                            target,
                                            action: 'show',
                                            name: 'contractPromptAlready',
                                            className: '_prompt _contractAlready',
                                            uniqKey: `contractPromptAlready`,
                                            promptName,
                                            centers: {
                                                left: 0.5,
                                                top: 0,
                                            },
                                        });
                                    } else if (message === 'Corporation not verify') {
                                        handlerPopup({
                                            name: 'verificationPopup',
                                            callback: this.hide,
                                        });
                                    }
                                }

                                resolve();

                                this.handlerLoading(null);
                            },
                            () => {
                                resolve();
                            },
                        );
                });
            }
        });
    }

    handlerDateState({ dateKey }) {
        this.setState({ dateKey });
    }

    getJoinTemplates() {
        getJoinTemplates({ params: [{ key: 'type', value: 'joinContract' }] }).then(
            ({ joinTemplates }) => {
                this.setState({ joinTemplates });
            },
        );
    }

    handlerMissClick(e) {
        const { dateKey } = this.state;

        if (dateKey) {
            const calendar = this.parent.current.querySelector('.payPopup__calendarInner');

            if (calendar && e.target !== calendar && !calendar.contains(e.target)) {
                this.handlerDateState({ dateKey: null });
            }
        }
    }

    hide() {
        handlerPopup({
            name: 'joinContractPopup',
            isShow: false,
            type: null,
            userName: null,
        });
    }

    renderContractPrompt({ promptName }) {
        const callback = ({ hide, handlerLoader }) => {
            this.save(true).then(
                () => {
                    hide();
                },
                () => {
                    handlerLoader(false);
                },
            );
        };

        return <WindowPrompt className="_bottomCenter" callback={callback} name={promptName} />;
    }

    componentDidMount() {
        const { joinContractPopup } = this.props;
        const { type } = joinContractPopup;

        if (!type) {
            this.getJoinTemplates();
        } else {
            this.setState({
                type,
                fields: { userName: { value: joinContractPopup?.userName || '' } },
            });
        }

        document.addEventListener('click', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { updatedKey, isListShow, isSearchProcess, fields, loadingKey, joinTemplates, type } =
            this.state;
        const { joinContractPopup } = this.props;
        const amount = fields.amount?.value || 0;
        let commission = +(amount * 0.006).toFixed(2);

        if (commission < 22) {
            commission = 22;
        }

        return (
            <div ref={this.parent} className={`payPopup _col ${type ? `_${type}` : ''}`}>
                <div className="payPopup__inner _col">
                    <Windows
                        name="contractPromptAlready"
                        renderContent={this.renderContractPrompt}
                    />

                    <ListAbsoluteMain
                        className="payPopup__calendars"
                        items={this.getDatesItems()}
                        renderItem={this.renderDatesItem}
                        classNameItem="payPopup__calendar _col"
                        prop="key"
                        styles={[]}
                        isNotParamsItem={true}
                    />
                    <div className="payPopup__head">
                        <div className="payPopup__headTitle">
                            {type === 'userProxy'
                                ? 'Создание новой доверенности'
                                : 'Создание нового договора'}
                        </div>
                        {type === 'userProxy' && (
                            <div className="payPopup__headDescription">
                                {this.getValue({ name: 'userName' }) || '–'}
                            </div>
                        )}
                        <i
                            className="payPopup__headClose _click"
                            onClick={() => {
                                this.hide();
                            }}
                        >
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="payPopup__content">
                        {(!type || !joinContractPopup.userId) && (
                            <div className={`payPopup__list ${isListShow ? '_active' : ''}`}>
                                <div
                                    className="payPopup__listHead _click"
                                    onClick={() => {
                                        this.handlerList();
                                    }}
                                >
                                    Выбрать {type === 'userProxy' ? 'сотрудника' : 'исполнителя'} из
                                    списка:
                                </div>
                                <ListAbsoluteMain
                                    className="payPopup__listContent"
                                    items={this.getContentBlocks()}
                                    renderItem={this.renderList}
                                    classNameItem="payPopup__listContentInner"
                                    prop="key"
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    keyRender={`${updatedKey}${isSearchProcess}`}
                                />
                            </div>
                        )}
                        <div className="payPopup__form">
                            {['info', 'dates'].map((key) => (
                                <div
                                    className={`payPopup__formFields ${
                                        key === 'dates' ? '_last' : ''
                                    }`}
                                    key={key}
                                >
                                    {this.getFieldsOrder(key).map((name) => {
                                        const value = this.getValue({ name });
                                        let isDisabled = false;
                                        let items;

                                        if (name === 'userName') {
                                            isDisabled = !!type;
                                        }

                                        if (name === 'templateId') {
                                            isDisabled = false;

                                            items =
                                                joinTemplates?.map((joinTemplate) => ({
                                                    key: joinTemplate._id,
                                                    content: joinTemplate.name,
                                                })) || [];
                                        }

                                        return (
                                            <div
                                                className={`payPopup__formField _col ${
                                                    fields[name]?.error ? '_error' : ''
                                                }`}
                                                key={name}
                                            >
                                                <Field
                                                    className="_heightSize"
                                                    type="joinContractPopup"
                                                    value={value}
                                                    name={name}
                                                    keyName={this.fields[name].keyName}
                                                    isEditmode={false}
                                                    isError={!!fields[name]?.error}
                                                    group="joinContractPopup"
                                                    isDisabled={isDisabled}
                                                    handler={this.handlerField}
                                                    items={items}
                                                />
                                                {key === 'dates' && (
                                                    <div className="payPopup__formFieldDates">
                                                        <div
                                                            className="payPopup__formFieldDatesBtn _col _click"
                                                            onClick={(e) => {
                                                                e.stopPropagation();

                                                                this.handlerDateState({
                                                                    dateKey: name,
                                                                });
                                                            }}
                                                        >
                                                            <i className="payPopup__formFieldDatesIcon">
                                                                <Icon name="calendar-new" />
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="payPopup__foot _row">
                        <div className="payPopup__footButton _full">
                            <Button
                                className="_medium2Size"
                                onClick={this.save}
                                showLoader={loadingKey === 'save'}
                            >
                                {type === 'userProxy' ? 'Создать доверенность' : 'Создать договор'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        joinContractPopup: state.joinContractPopup,
    };
}

export default connect(mapStateToProps)(JoinContractPopup);

JoinContractPopup.propTypes = {
    joinContractPopup: PropTypes.object,
};
