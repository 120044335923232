import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getFilter from '../../../requests/getFilter';
import getUsers from '../../../requests/getUsers';

import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';

import HandlerFilter from '../../../classes/Filter';
import WidgetFilter from '../../../components/crm/widget/Filter.jsx';

import Table from '../../../components/crm/manual/Table.jsx';
import TableInfo from '../../../components/crm/manual/TableInfo.jsx';

import Button from '../../../components/Button.jsx';
import handlerErrorRequest from '../../../functions/handlerErrorRequest';
import handlerWindow from '../../../functions/handlerWindow';
import Loader from '../../../components/Loader.jsx';
import Animate from '../../../components/Animate.jsx';
import AnimateChange from '../../../components/AnimateChange.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';
import setPermissions from '../../../functions/crm/setPermissions';

class SettingsBalanceUsers extends Table {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.addUser = this.addUser.bind(this);
        this.deleteUserRequest = this.deleteUserRequest.bind(this);
        this.handlerMore = this.handlerMore.bind(this);
        this.getActionsItems = this.getActionsItems.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;

    classNameItem = '.tableInfo__row';

    addUser() {
        handlerPopup({
            name: 'userPopup',
            isShow: true,
            callback: () => {
                this.updateItems();
            },
        });
    }

    inviteUser() {
        const { inviteUserId, users = [] } = this.state;
        const user = users.find((innerUser) => innerUser.isNew);

        return new Promise((resolve) => {
            if (!inviteUserId) {
                resolve();
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/user`,
                        { id: inviteUserId, fields: { role: user.role }, idOfInvite: inviteUserId },
                        {
                            headers: getHeaders(),
                        },
                    )
                    .then((res) => {
                        const { success } = res.data;

                        if (success) {
                            setNotification({
                                notification: 'create-new-user',
                            });
                        }

                        resolve();
                    });
            }
        });
    }

    deleteUserRequest({ id, hide }) {
        axios
            .delete(`${process.env.REACT_APP_API}/user?id=${id}`, { headers: getHeaders() })
            .then((res) => {
                const { success } = res.data;

                if (success) {
                    setNotification({
                        notification: 'delete-user',
                    });

                    if (hide && typeof hide === 'function') {
                        hide();
                    }
                } else {
                    handlerErrorRequest(res);
                }
            });
    }

    getFilter() {
        getFilter({ name: 'users' }).then((filter) => {
            this.initFilter(filter);
        });
    }

    getActionsItems({ item: user }) {
        const { user: globalUser } = this.props;
        const items = ['logs'];
        const currentCorporation = user.corporations.find(
            (item) => item._id === globalUser.idOfCurrentCorporation,
        );
        // const hasHolder = !!user.corporations.find((item) => item.role === 'holder');
        // const globalUserCorporation = getCurrentCorporation({ user: globalUser });

        if (user._id !== globalUser._id) {
            const userCorporation = user.corporations.find(
                (item) => item.id === globalUser.idOfCurrentCorporation,
            );
            if (
                this.getPermissions({
                    key: 'settings',
                    items: [
                        {
                            key: 'users',
                            rules: ['delete'],
                        },
                    ],
                }) &&
                !!userCorporation &&
                userCorporation.role !== 'holder'
            ) {
                items.unshift('deleteUser');
            }

            if (
                currentCorporation &&
                this.getPermissions({
                    key: 'settings',
                    items: [
                        {
                            key: 'users',
                            rules: ['update'],
                        },
                    ],
                }) &&
                !!userCorporation &&
                userCorporation.role !== 'holder'
            ) {
                items.unshift('editUser');
            }
        }

        return items;
    }

    handlerMore(user, { target }) {
        const { getParent, user: globalUser } = this.props;
        const parent = getParent();

        const currentCorporation = user.corporations.find(
            (item) => item._id === globalUser.idOfCurrentCorporation,
        );

        const actionsItems = this.getActionsItems({ item: user });

        const uniqKey = `userActions-${user._id}`;

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: this.getParentForScroll(),
            target: target.closest('.tableInfo__more'),
            action: 'show',
            name: 'userActions',
            className: '_actions _right',
            uniqKey,
            items: actionsItems,
            userId: user._id,
            user: {
                ...user,
                role: currentCorporation?.role,
            },
            centers: {
                left: 1,
                top: 0,
            },
            isHideFromScroll: true,
            isCheckScrollPosition: false,
            updateItems: () => {
                this.updateItems();
            },
        });
    }

    getItems() {
        const { inner, corporation } = this.props;
        const query = this.getQueryForRequest();

        if (inner) {
            query.params.push({ key: 'adminCorporationId', value: corporation._id });
        }

        return new Promise((resolve) => {
            getUsers(query).then(({ users, isLimit, counter }) => {
                this.setItems(users, false, isLimit, counter).then(() => {
                    resolve();
                });
            });
        });
    }

    componentDidMount() {
        const { setHeightPage, setDeleteUserRequest, user } = this.props;

        setHeightPage();

        this.getFilter();

        setDeleteUserRequest(this.deleteUserRequest);

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        super.componentDidMount();

        this.setState({ user });
    }

    render() {
        const {
            loadingKey,
            filter,
            isReady,
            items = [],
            counter,
            isLimit,
            isDisabledScroll,
            isShowLoaderList,
            isLoadingFilter = false,
            user,
        } = this.state;
        const { getParent } = this.props;

        return (
            <div ref={this.parent} className="settingsUsers">
                <div className="settingsUsers__inner">
                    <div
                        className={`settingsUsersTable _users ${isReady ? '_init' : ''} ${
                            isLoadingFilter ? '_loadingFilter' : ''
                        } ${user?.idOfCurrentCorporation === 'admin' ? '_adminCorporation' : ''}`}
                    >
                        <div className="settingsUsersTable__inner">
                            <Animate
                                className="settingsUsersTable__loader _loader _full"
                                isShow={!isReady}
                            >
                                <div className="settingsUsersTable__loaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <div className="settingsUsersTable__wrapper">
                                <div className="settingsUsersTable__head _static">
                                    <div className="settingsUsersTable__headInner _row">
                                        <div className="settingsUsersTable__headTitle _row">
                                            Сотрудники:{' '}
                                            <AnimateChange
                                                className="settingsUsersTable__headTitleItem"
                                                prop={counter}
                                                type="_translateMedium"
                                            >
                                                <>({counter})</>
                                            </AnimateChange>
                                        </div>
                                        <div className="settingsUsersTable__headActions _row">
                                            <div className="settingsUsersTable__headFilter">
                                                <WidgetFilter
                                                    className="_grey"
                                                    name="users"
                                                    filter={filter}
                                                    getParent={getParent}
                                                    handlerFilter={this.handlerFilter}
                                                    windowName="usersFilter"
                                                />
                                            </div>
                                            {this.getPermissions({
                                                key: 'settings',
                                                items: [
                                                    {
                                                        key: 'users',
                                                        rules: ['create'],
                                                    },
                                                ],
                                            }) && (
                                                <div className="settingsUsersTable__headButton">
                                                    <Button
                                                        className="_mediumSize _mainNotBorder"
                                                        onClick={this.addUser}
                                                        showLoader={loadingKey === 'add'}
                                                    >
                                                        + Сотрудник
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="settingsUsersTable__content JSTransVertical _disabled">
                                    <TableInfo
                                        isReady={isReady}
                                        items={items}
                                        name="users"
                                        setHeightPage={this.setHeightPage}
                                        getMoreItems={this.getMoreItems}
                                        isLimit={isLimit}
                                        stepCounter={this.stepCounter}
                                        isDisabledScroll={isDisabledScroll}
                                        isLoading={isLoadingFilter}
                                        handlerLoaderList={this.handlerLoaderList}
                                        isBlock={true}
                                        inTable={true}
                                        isNotLink={true}
                                        getActionsItems={this.getActionsItems}
                                        handlerMore={this.handlerMore}
                                        updateItems={this.updateItems}
                                    />
                                </div>
                                <Animate
                                    className="settingsUsersTable__listLoader _loaderScroll _white"
                                    isShow={isShowLoaderList}
                                >
                                    <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsBalanceUsers);

SettingsBalanceUsers.propTypes = {
    corporation: PropTypes.object,
    setHeightPage: PropTypes.func,
    setDeleteUserRequest: PropTypes.func,
    user: PropTypes.object,
    updateCorporation: PropTypes.func,
    initCallbackFilter: PropTypes.func,
    inner: PropTypes.bool,
};
