import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Animate from '../../Animate.jsx';
import Icon from '../../Icon.jsx';
import Button from '../../Button.jsx';
import Search from '../../Search.jsx';
import ActionChange from '../../ActionChange.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Field from '../manual/card/Field.jsx';
import getUserInfo from '../../../functions/getUserInfo';
import scrollToPosition from '../../../functions/scrollToPosition';
import getFormatedDate from '../../../functions/getFormatedDate';

import Avatar from '../../Avatar.jsx';
import Table from '../manual/Table.jsx';
import getExecutors from '../../../requests/getExecutors';
import ListScroll from '../../ListScroll.jsx';
import Loader from '../../Loader.jsx';
import getFormatPrice from '../../../functions/getFormatPrice';
import getHeaders from '../../../functions/getHeaders';
import handlerPopup from '../../../functions/app/handlerPopup';
import ExecutorOrg from '../ExecutorOrg.jsx';
import setNotification from '../../../functions/setNotification';
import Calendar from '../../Calendar.jsx';
import Checkbox from '../../Checkbox.jsx';
import getFloatStr from '../../../functions/getFloatStr';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';

class PayPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            isNotExecutorSign: false,
        };

        this.renderList = this.renderList.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.hideListCallback = this.hideListCallback.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerField = this.handlerField.bind(this);
        this.renderDatesItem = this.renderDatesItem.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerDate = this.handlerDate.bind(this);
        this.saveDate = this.saveDate.bind(this);

        this.save = this.save.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.payPopup__listItem';

    fieldsOrder = {
        info: ['userName', 'name'],
        dates: ['period', 'date'],
    };

    fields = {
        userName: {
            keyName: 'fullname',
        },
        name: {
            keyName: 'russianTextWithSymbols',
        },
        period: {
            keyName: 'anyText',
        },
        date: {
            keyName: 'date',
        },
    };

    hide() {
        handlerPopup({
            name: 'payPopup',
            isShow: false,
            pay: null,
            isEdit: null,
        });
    }

    getContentBlocks() {
        const { isListShow } = this.state;

        return isListShow ? [{ key: 'content' }] : [];
    }

    renderListItem({ item, prop: _id }) {
        if (_id === 'empty') {
            return (
                <div className="payPopup__listItem">
                    <div className="empty _col _block _notBack">
                        <div className="empty__inner">
                            <div className="empty__title">По вашему фильтру ничего не найдено</div>
                            <p className="empty__content">Попробуйте изменить критерии поиска</p>
                        </div>
                    </div>
                </div>
            );
        }

        const isCurrent = this.state.currentItem?._id === _id;

        return (
            <div className="payPopup__listItem">
                <div className="payPopup__listExecutor _row">
                    <div className="payPopup__listExecutorAvatar _col">
                        <Avatar
                            className="payPopup__listExecutorAvatarInner"
                            holder={{ ...item }}
                        />
                    </div>
                    <div className="payPopup__listExecutorName _col">
                        <div className="payPopup__listExecutorNameInner">
                            <b>{getUserInfo({ type: 'name', user: item })},&nbsp;</b>
                            <span className="_noWrap">{item.phone}</span>
                            {item.contractNumber && (
                                <>
                                    <br />
                                    Договор <b>№{item.contractNumber}</b> от&nbsp;
                                    {getFormatedDate({
                                        date: new Date(item.contractDate),
                                        isShortYear: true,
                                    })}
                                </>
                            )}
                        </div>

                        <div className="payPopup__listExecutorOrg">
                            <ExecutorOrg name={item.organization} />
                        </div>
                    </div>
                    <ActionChange
                        className="payPopup__listExecutorAction"
                        isShow={true}
                        isSmoothShow={true}
                        isHas={isCurrent}
                        onClick={() => {
                            this.setState(
                                {
                                    currentItem:
                                        this.state.currentItem?._id === item._id ? null : item,
                                },
                                () => {
                                    scrollToPosition({
                                        position: 'top',
                                        parent: this.parent.current?.querySelector(
                                            '.payPopup__listItemsWrapper',
                                        ),
                                    });

                                    this.handlerErrors({
                                        errors: ['userName', 'name'],
                                        action: 'delete',
                                    });
                                },
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                clearTimeout(this.searchTimerId);

                this.setState({ search: value, isSearchProcess: true }, () => {
                    this.searchTimerId = setTimeout(() => {
                        this.updateItems(true).then(() => {
                            this.setState({ isSearchProcess: false });
                        });
                    }, 300);

                    resolve();
                });
            }
        });
    }

    getItemsList() {
        const { search, items = [], currentItem, isSearchProcess } = this.state;

        if (search && items.length === 0 && !isSearchProcess) {
            return [{ _id: 'empty' }];
        }

        return JSON.parse(
            JSON.stringify(
                items
                    .filter((item) => !currentItem || item._id !== currentItem._id)
                    .concat(...(currentItem ? [currentItem] : [])),
            ),
        ).sort((a, b) => {
            const weightA = currentItem?._id === a._id ? 1 : 0;
            const weightB = currentItem?._id === b._id ? 1 : 0;

            return weightB - weightA;
        });
    }

    hideListCallback() {
        const items = this.getItemsList().filter((item, key) => key < this.stepCounter);

        document.dispatchEvent(
            new CustomEvent('handlerListDynamic', {
                detail: {
                    name: 'payPopupList',
                    items,
                    callback: () => {
                        this.setState(
                            {
                                items,
                                counterScroll: this.stepCounter,
                                isListHide: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ isListHide: false });
                                }, 300);
                            },
                        );
                    },
                },
            }),
        );
    }

    renderList() {
        const {
            isReady,
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
            isStateListProcess = false,
            isShowLoaderList = false,
            isListHide = false,
            isSearchProcess = false,
            search = '',
            currentItem,
        } = this.state;
        const items = this.getItemsList();
        const minHeight = items.length === 0 || isSearchProcess ? 100 : 0;

        return (
            <div className="payPopup__listContentInner">
                <div className="payPopup__listSearch">
                    <Search
                        value={search}
                        support="Введите ФИО исполнителя или номер телефона"
                        className="_filter _grey"
                        callback={this.handlerSearch}
                    />
                </div>
                <div className="payPopup__listItemsBox">
                    <div
                        className={`payPopup__listItemsWrapper ${
                            isReady && !isLoadingFilter && !isSearchProcess ? '_ready' : ''
                        }`}
                    >
                        <ListScroll
                            getParent={() =>
                                this.parent.current?.querySelector('.payPopup__listItemsWrapper')
                            }
                            callback={this.getMoreItems}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={Infinity}
                            lengthCurrent={items.length}
                            keyUpdate={items.length}
                            isDisabledScroll={
                                isDisabledScroll ||
                                isSearchProcess ||
                                isStateListProcess ||
                                isListHide
                            }
                            isLimit={isLimit}
                            handlerLoaderList={this.handlerLoaderList}
                            hideCallback={this.hideListCallback}
                        >
                            <ListAbsoluteMain
                                name="payPopupList"
                                className="payPopup__listItems _col"
                                items={items}
                                renderItem={this.renderListItem}
                                classNameItem="payPopup__listItem"
                                prop="_id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        this.setState({ updatedKey: new Date().getTime() });
                                    }
                                }}
                                minHeight={minHeight}
                                maxHeight={150}
                                keyRender={currentItem?._id}
                            />
                        </ListScroll>
                    </div>
                    <Animate
                        className="payPopup__listLoader _loader"
                        isShow={!isReady || isLoadingFilter || isSearchProcess}
                    >
                        <div className="payPopup__listLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <Animate
                        className="payPopup__listScrollLoader _loaderScroll _white"
                        isShow={isReady && !isLoadingFilter && !isSearchProcess && isShowLoaderList}
                    >
                        <div className="payPopup__listScrollLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }

    getItems(isStart, isFilter = false) {
        const { search, executorId, currentItem } = this.state;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'pay' });
        query.params.push({ key: 'dataType', value: 'list' });

        if (search?.length > 2) {
            query.params.push({ key: 'nameOrPhone', value: search });
        }

        if (executorId || currentItem) {
            query.params.push({ key: 'currentId', value: executorId || currentItem._id });
        }

        return new Promise((resolve) => {
            getExecutors(query).then(
                ({ executors, isLimit }) => {
                    this.setItems(executors, isFilter, isLimit).then(() => {
                        if (executorId) {
                            this.setState({
                                currentItem: this.state.items.find(
                                    (item) => item._id === executorId,
                                ),
                                executorId: null,
                            });
                        }

                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    handlerList(isListShow = !this.state.isListShow) {
        clearTimeout(this.stateListProcessTimerId);

        this.setState({ isListShow, isStateListProcess: true }, async () => {
            if (isListShow === true) {
                await this.getItems(true, true);
            }

            this.stateListProcessTimerId = setTimeout(() => {
                this.setState({ isStateListProcess: false }, () => {
                    if (this.state.isListShow === false) {
                        this.setState({
                            isLimit: !(this.state.counterScroll > this.stepCounter),
                            counterScroll: this.stepCounter,
                        });
                    }
                });
            }, 300);
        });
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    if (!fields[name]) {
                        fields[name] = {};
                    }

                    fields[name].value = value;
                    fields[name].error = null;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getValue({ name }) {
        const { fields, currentItem } = this.state;
        let value = fields[name]?.value;

        if (currentItem) {
            if (name === 'userName') {
                value = getUserInfo({
                    type: 'name',
                    user: currentItem,
                });
            }
        }

        if (name === 'period' && fields.startPeriod?.value && fields.endPeriod?.value) {
            value = `${fields.startPeriod?.value} — ${fields.endPeriod?.value}`;
        }

        return value;
    }

    handlerErrors({ errors, action }) {
        this.setState((state) => {
            const newState = { ...state };
            const fields = { ...newState.fields };

            errors.forEach((name) => {
                if (!fields[name]) {
                    fields[name] = {};
                }

                fields[name].error = action ? null : true;
            });

            newState.fields = fields;

            return newState;
        });
    }

    getDatesItems() {
        const { dateKey } = this.state;

        return dateKey ? [{ key: dateKey }] : [];
    }

    dates = {};

    handlerDate(type, { currentDate, currentDateDouble }) {
        if (!this.dates[type]) {
            this.dates[type] = {};
        }

        if (currentDate) {
            this.dates[type].currentDate = currentDate;
        }

        if (currentDateDouble) {
            this.dates[type].currentDateDouble = currentDateDouble;
        }
    }

    saveDate(type) {
        const { dateKey } = this.state;

        if (
            this.dates[type]?.currentDate &&
            (type !== 'period' || this.dates[type]?.currentDateDouble)
        ) {
            const startDateString = getFormatedDate({ date: this.dates[type].currentDate });

            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    ['date', 'startPeriod', 'endPeriod', 'period'].forEach((key) => {
                        if (!fields[key]) {
                            fields[key] = {};
                        }
                    });

                    if (dateKey === 'date') {
                        fields.date.value = startDateString;
                        fields.date.error = null;
                    } else {
                        const endDateString = getFormatedDate({
                            date:
                                this.dates[type].currentDateDouble || this.dates[type].currentDate,
                        });

                        fields.startPeriod.value = startDateString;
                        fields.startPeriod.error = null;

                        fields.endPeriod.value = endDateString;
                        fields.endPeriod.error = null;

                        fields.period.error = null;
                    }

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    // this.currentDate = null;
                    // this.currentDateDouble = null;

                    this.handlerDateState({ dateKey: null });
                },
            );
        }
    }

    renderDatesItem({ prop: key }) {
        return (
            <div className="payPopup__calendar _col">
                <div className="payPopup__calendarInner">
                    <div className="payPopup__calendarBox _col">
                        <Calendar
                            className="_pay"
                            withPast={true}
                            // withFuture={false}
                            isDouble={key === 'period'}
                            isAllDates={true}
                            handlerDate={this.handlerDate.bind(this, key)}
                            startDate={this.dates[key]?.currentDate}
                            startDateDouble={this.dates[key]?.currentDateDouble}
                        />
                        <div className="payPopup__calendarButton">
                            <Button
                                className="_medium2Size"
                                onClick={this.saveDate.bind(this, key)}
                            >
                                Сохранить дату
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    save() {
        const { currentItem, isNotExecutorSign, pay: savedPay } = this.state;
        const { payPopup } = this.props;
        const pay = {};
        const { groupId, isEdit } = payPopup;
        const errors = [];

        ['name', 'amount', 'period', 'date'].forEach((name) => {
            const value = this.getValue({ name });

            if (!value) {
                errors.push(name);
            } else if (name === 'amount') {
                if (Number.isNaN(getFloatStr(value)) || getFloatStr(value) <= 0) {
                    errors.push(name);
                }
            }

            if (!['period', 'date'].includes(name)) {
                pay[name] = value;
            }
        });

        if (!currentItem && !isEdit) {
            errors.push('userName');
        }

        pay.userId = currentItem?._id;

        if (pay.amount) {
            pay.amount = getFloatStr(pay.amount);
        }

        pay.date = this.getValue({ name: 'date' });
        pay.startPeriod = this.getValue({ name: 'startPeriod' });
        pay.endPeriod = this.getValue({ name: 'endPeriod' });

        if (groupId) {
            pay.groupId = groupId;
        }

        pay.isNotExecutorSign = !!isNotExecutorSign;

        if (isEdit && savedPay) {
            pay._id = savedPay._id;
        }

        if (errors.length) {
            this.handlerErrors({ errors });

            setNotification({ notification: 'required-fields-not-complete' });
        } else {
            this.handlerLoading('save').then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/pays`,
                        {
                            pay,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                this.hide();

                                document.dispatchEvent(new CustomEvent('newPay'));
                            } else {
                                const { message } = data;

                                if (message === 'Corporation not verify') {
                                    handlerPopup({
                                        name: 'verificationPopup',
                                        callback: this.hide,
                                    });
                                }
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        }
    }

    handlerDateState({ dateKey }) {
        this.setState({ dateKey }, () => {
            if (!dateKey) {
                // this.currentDate = null;
                // this.currentDateDouble = null;
            }
        });
    }

    init() {
        const { payPopup } = this.props;
        const { isEdit, pay } = payPopup;

        if (pay) {
            this.setState(
                {
                    isEdit,
                    pay: JSON.parse(JSON.stringify(pay)),
                    fields: {
                        name: {
                            value: pay.name,
                        },
                        startPeriod: {
                            value: getFormatedDate({ date: new Date(pay.startPeriod) }),
                        },
                        endPeriod: {
                            value: getFormatedDate({ date: new Date(pay.endPeriod) }),
                        },
                        ...(isEdit
                            ? {
                                  date: {
                                      value: getFormatedDate({ date: new Date(pay.date) }),
                                  },
                                  amount: {
                                      value: getFormatPrice(pay.amount).replace(/ /gi, ''),
                                  },
                              }
                            : {}),
                    },
                    isNotExecutorSign: pay.executorSign.isNotSign,
                    executorId: pay.executorId,
                },
                () => {
                    this.dates = {
                        ...(isEdit
                            ? {
                                  date: {
                                      currentDate: pay.date,
                                  },
                              }
                            : {}),
                        period: {
                            currentDate: pay.startPeriod,
                            currentDateDouble: pay.endPeriod,
                        },
                    };

                    if (pay.executorId) {
                        this.handlerList(true);
                    }
                },
            );
        }
    }

    getCommission() {
        const { fields } = this.state;
        const { user } = this.props;
        const amount = +getFloatStr(fields.amount?.value || '0');
        const tariff = getCurrentCorporation({ user })?.tariff;

        let amountCommission = +(amount * (tariff.pay.percent / 100)).toFixed(2);

        if (amountCommission < tariff.pay.min) {
            amountCommission = tariff.pay.min;
        }

        return amountCommission;
    }

    handlerMissClick(e) {
        const { dateKey } = this.state;

        if (dateKey) {
            const calendar = this.parent.current.querySelector('.payPopup__calendarInner');

            if (calendar && e.target !== calendar && !calendar.contains(e.target)) {
                this.handlerDateState({ dateKey: null });
            }
        }
    }

    componentDidMount() {
        this.init();

        document.addEventListener('click', this.handlerMissClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const {
            updatedKey,
            isListShow,
            isSearchProcess,
            fields,
            loadingKey,
            isNotExecutorSign,
            pay,
            isEdit,
        } = this.state;
        const amount = fields.amount?.value || 0;
        const commission = this.getCommission();

        return (
            <div ref={this.parent} className="payPopup _col">
                <div className="payPopup__inner _col">
                    <ListAbsoluteMain
                        className="payPopup__calendars"
                        items={this.getDatesItems()}
                        renderItem={this.renderDatesItem}
                        classNameItem="payPopup__calendar _col"
                        prop="key"
                        styles={[]}
                        isNotParamsItem={true}
                    />
                    <div className="payPopup__head">
                        <div className="payPopup__headTitle">
                            {isEdit ? (
                                <>Редактирование акта №{pay?.number}</>
                            ) : (
                                <>Создание нового акта</>
                            )}
                        </div>
                        <i
                            className="payPopup__headClose _click"
                            onClick={() => {
                                this.hide();
                            }}
                        >
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="payPopup__content">
                        <div className={`payPopup__list ${isListShow ? '_active' : ''}`}>
                            <div
                                className="payPopup__listHead _click"
                                onClick={() => {
                                    this.handlerList();
                                }}
                            >
                                Выбрать исполнителя из списка:
                            </div>
                            <ListAbsoluteMain
                                className="payPopup__listContent"
                                items={this.getContentBlocks()}
                                renderItem={this.renderList}
                                classNameItem="payPopup__listContentInner"
                                prop="key"
                                styles={['height']}
                                isNotParamsItem={true}
                                keyRender={`${updatedKey}${isSearchProcess}`}
                            />
                        </div>
                        <div className="payPopup__form">
                            {['info', 'dates'].map((key) => (
                                <div className="payPopup__formFields" key={key}>
                                    {this.fieldsOrder[key].map((name) => {
                                        const value = this.getValue({ name });
                                        let isDisabled = false;

                                        if (name === 'userName' || name === 'period') {
                                            isDisabled = true;
                                        }

                                        return (
                                            <div
                                                className={`payPopup__formField _col ${
                                                    fields[name]?.error ? '_error' : ''
                                                }`}
                                                key={name}
                                            >
                                                <Field
                                                    className="_heightSize"
                                                    type="payPopup"
                                                    value={value}
                                                    name={name}
                                                    keyName={this.fields[name].keyName}
                                                    isEditmode={false}
                                                    isError={!!fields[name]?.error}
                                                    group="payPopup"
                                                    isDisabled={isDisabled}
                                                    handler={this.handlerField}
                                                />
                                                {key === 'dates' && (
                                                    <div className="payPopup__formFieldDates">
                                                        <div
                                                            className="payPopup__formFieldDatesBtn _col _click"
                                                            onClick={(e) => {
                                                                e.stopPropagation();

                                                                this.handlerDateState({
                                                                    dateKey: name,
                                                                });
                                                            }}
                                                        >
                                                            <i className="payPopup__formFieldDatesIcon">
                                                                <Icon name="calendar-new" />
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                            <div className="payPopup__formFields">
                                <div className="payPopup__formField _col _amount">
                                    <Field
                                        className="_heightSize"
                                        type="payPopup"
                                        value={amount || ''}
                                        name="amount"
                                        keyName="price"
                                        isEditmode={false}
                                        group="payPopup"
                                        support="0₽"
                                        handler={this.handlerField}
                                        isError={!!fields.amount?.error}
                                    />
                                </div>
                                <div className="payPopup__formField _col">
                                    <label className="payPopup__formCheckbox _row">
                                        <div className="payPopup__formCheckboxBox">
                                            <Checkbox
                                                className="_square _blue"
                                                value={isNotExecutorSign}
                                                handler={() => {
                                                    this.setState({
                                                        isNotExecutorSign: !isNotExecutorSign,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="payPopup__formCheckboxContent">
                                            <div className="payPopup__formCheckboxTitle">
                                                Односторонний акт
                                            </div>
                                            <div className="payPopup__formCheckboxDescription">
                                                Подпись со стороны исполнителя не требуется
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="payPopup__foot _row">
                        <div className="payPopup__footButton">
                            <Button
                                className="_medium2Size"
                                onClick={this.save}
                                showLoader={loadingKey === 'save'}
                            >
                                {isEdit ? <>Сохранить акт</> : <>Создать акт</>}
                            </Button>
                        </div>
                        <div className="payPopup__footInfo">
                            <div className="payPopup__footInfoTitle">
                                Итого: {getFormatPrice(amount)}₽
                            </div>
                            <p className="payPopup__footInfoDescription">
                                Комиссия: {getFormatPrice(commission)}₽
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        payPopup: state.payPopup,
        user: state.user,
    };
}

export default connect(mapStateToProps)(PayPopup);

PayPopup.propTypes = {
    payPopup: PropTypes.object,
    user: PropTypes.object,
};
