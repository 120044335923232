import axios from 'axios';
import getHeaders from '../functions/getHeaders';

export default function handlerCorporationVerification({ id, status, comment }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/corporation`,
                {
                    id,
                    action: 'handler-verification',
                    status,
                    comment,
                },
                { headers: getHeaders() },
            )
            .then((res) => {
                const { success, data } = res.data;

                if (success) {
                    resolve(data);
                } else {
                    reject();
                }
            }, reject);
    });
}
